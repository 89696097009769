import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
    Table,
    Popconfirm,
    notification,
    Button,
    Modal,
    Input,
    Form,
} from 'antd'

function WhatsAppTemplates(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.NOTIFICATION.GET_WHATSAPP_TEMPLATE_NAMES)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span>{record}</span>,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteTemplate(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showAddModal = () => {
        setAddModalVisible(true)
    }

    const hideAddModal = () => {
        setAddModalVisible(false)
    }

    const addTemplate = () => {
        form.validateFields(['addTemplateName'])
            .then((values) => {
                setLoading(true)

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.NOTIFICATION.ADD_WHATSAPP_TEMPLATE,
                    body
                )
                    .then((res) => {
                        notification['success']({
                            message: res.data,
                        })

                        hideAddModal()
                        getData()
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteTemplate = (templateName) => {
        setLoading(true)

        var payload = {}
        payload.templateName = templateName

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.NOTIFICATION.DELETE_WHATSAPP_TEMPLATE, body)
            .then((res) => {
                notification['success']({
                    message: res.data,
                })

                getData()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <Modal
                title={'WhatsApp Templates'}
                open={true}
                width={800}
                onCancel={props.hideModal}
                destroyOnClose={true}
                footer={null}
                confirmLoading={loading}
            >
                <div>
                    <Button type="primary" size="small" onClick={showAddModal}>
                        Add
                    </Button>
                </div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="name"
                    style={{ marginTop: 10 }}
                    scroll={{ y: 500 }}
                />
            </Modal>
            <Modal
                title={'Add WhatsApp Template'}
                open={addModalVisible}
                onOk={addTemplate}
                onCancel={hideAddModal}
                okText={'Add'}
                destroyOnClose={true}
                width={600}
                confirmLoading={loading}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Template Name"
                        style={{ marginBottom: 5 }}
                        name="addTemplateName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default WhatsAppTemplates
