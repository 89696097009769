import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    InputNumber,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'

function IlEmployeeRoles() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const inputRef = useRef()

    useEffect(() => {
        getRoles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [addEditModalVisible, inputRef])

    const getRoles = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.IL.GET_EMP_ROLES)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({
                    roleName: record.roleName,
                    leaveEligibilityPerMonth: record.leaveEligibilityPerMonth,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Role Name',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddEditModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 12, marginRight: 5 }} />
                    </Button>
                    <span>{record.roleName}</span>
                </>
            ),
        },
        {
            title: 'Leave Elibility',
            dataIndex: 'leaveEligibilityPerMonth',
            key: 'leaveEligibilityPerMonth',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}
                    <br />
                    <span style={{ fontSize: 10 }}>{record.updatedAt}</span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteRole(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateRole = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.IL.ADD_EMP_ROLE
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.IL.UPDATE_EMP_ROLE
                    values.uid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getRoles()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteRole = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.DELETE_EMP_ROLE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getRoles()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Employee Roles</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Role'}
                open={addEditModalVisible}
                onOk={addOrUpdateRole}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Role Name"
                        name="roleName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Leave Eligibility"
                        style={{ marginBottom: 0 }}
                        name="leaveEligibilityPerMonth"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} precision={2} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default IlEmployeeRoles
