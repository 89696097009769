import React, { useState, useEffect, useRef } from 'react'
import {
    Input,
    Modal,
    Table,
    Button,
    DatePicker,
    InputNumber,
    notification,
    Radio,
    Popconfirm,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

const dateFormat = 'DD-MMM-YYYY'

function BlogAuthors(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [validityString, setValidityString] = useState()
    const [filter, setFilter] = useState('Enabled')
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        getAttempts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getAttempts = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_ATTEMPTS_ADMIN + '?filter=' + filter)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        setValidityString()
        if (record.id) {
            setTimeout(() => {
                var validity = null

                if (record.validityString) {
                    setValidityString(record.validityString)
                    validity = dayjs(record.validityString, dateFormat)
                }

                form.setFieldsValue({
                    attempt: record.attempt,
                    displayName: record.displayName,
                    validity: validity,
                    allowedDays: record.allowedDays,
                })
            }, 100)
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const enableDisable = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_ATTEMPT
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_ATTEMPT
        }

        var payload = {}
        payload.id = record.id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getAttempts()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            key: 'enabled',
            width: 100,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                            display: 'block',
                            margin: 'auto',
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 150,
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddEditModal(record)
                        }}
                        style={{ padding: 0, marginRight: 5 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <span>{record.attempt}</span>
                </>
            ),
        },
        {
            title: 'DisplayName',
            dataIndex: 'displayName',
            key: 'displayName',
            width: 150,
        },
        {
            title: 'Validity',
            dataIndex: 'validityString',
            key: 'validityString',
            width: 150,
        },
        {
            title: 'Allowed Days',
            dataIndex: 'allowedDays',
            key: 'allowedDays',
            render: (text, record) => (
                <span>{record.allowedDays ? record.allowedDays : ''}</span>
            ),
        },
    ]

    const addOrUpdate = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.validity = validityString

                var url = GLOBAL.URL.ADD_ATTEMPT
                if (selectedRecord.id) {
                    url = GLOBAL.URL.UPDATE_ATTEMPT
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getAttempts()
                    })
                    .finally(() => setLoading(false))
            })
            .catch(() => {
                // Empty catch block
            })
    }

    const onChangeValidity = (value, dateString) => {
        setValidityString(dateString)
    }

    const onChangeFilter = (e) => {
        setFilter(e.target.value)
    }

    return (
        <div>
            <h2>Course Attempts</h2>
            <div>
                <Radio.Group onChange={onChangeFilter} value={filter}>
                    <Radio.Button value={'All'} key={'All'}>
                        All
                    </Radio.Button>
                    <Radio.Button value={'Enabled'} key={'Enabled'}>
                        Enabled
                    </Radio.Button>
                    <Radio.Button value={'Disabled'} key={'Disabled'}>
                        Disabled
                    </Radio.Button>
                </Radio.Group>
                <Button
                    type="primary"
                    onClick={showAddEditModal}
                    style={{ marginLeft: 10 }}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="key"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Attempt'}
                open={addEditModalVisible}
                onOk={addOrUpdate}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Attempt"
                        style={{ marginBottom: 5 }}
                        name="attempt"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Display Name"
                        style={{ marginBottom: 5 }}
                        name="displayName"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Valid Till"
                        style={{ marginBottom: 5 }}
                        name="validity"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            format={dateFormat}
                            onChange={onChangeValidity}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Allowed Days"
                        style={{ marginBottom: 5 }}
                        name="allowedDays"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default BlogAuthors
