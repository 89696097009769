import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Popconfirm, notification, Select, Form } from 'antd'

function UpdateTransactions() {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
            setCourseNames(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            var attemptsList = []
            res.data.forEach((item) => {
                attemptsList.push(item.attempt)
            })
            setAttempts(attemptsList)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getCourseOptions = () => {
        var courseOptions = []
        courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return courseOptions
    }

    const getAttemptOptions = () => {
        var attemptOptions = []
        attempts.forEach((item) => {
            attemptOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return attemptOptions
    }

    const updateTransactions = () => {
        form.validateFields(['courseIds', 'fromAttempt', 'toAttempt'])
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.UPDATE_TRANSACTIONS_BY_COURSE_AND_ATTEMPT,
                    formData
                )
                    .then((response) => {
                        form.resetFields()
                        notification['success']({
                            message: response.data,
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            <h2>Update Transactions</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Courses"
                    name="courseIds"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        mode="multiple"
                    >
                        {getCourseOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="From Attempt"
                    name="fromAttempt"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select placeholder="Select Attempt" showSearch>
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="To Attempt"
                    name="toAttempt"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select placeholder="Select Attempt" showSearch>
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
            </Form>
            <Popconfirm
                title="Are you sure?"
                onConfirm={updateTransactions}
                okText="Yes"
                cancelText="No"
            >
                <Button type="primary" loading={loading}>
                    Update Transactions
                </Button>
            </Popconfirm>
        </div>
    )
}

export default UpdateTransactions
