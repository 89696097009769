import React, { useEffect, useState } from 'react'
import { Input, Button, message, Select, Form } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import MetaFormItems from '../Common/MetaFormItems'

const { Option } = Select

const AddGroup = (props) => {
    const [loading, setLoading] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [level, setLevel] = useState('')
    const [isUpdate, setIsUpdate] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        getRequiredData()
        checkEdit()
        //eslint-disable-next-line
    }, [])

    const checkEdit = () => {
        const { record } = props.location.state || {}
        if (record) {
            setIsUpdate(true)
            setFormFieldValues(record)
            form.setFieldsValue(record)
            form.setFieldsValue({
                course: record.stream.course,
                level: record.stream.level,
            })
        }
    }

    const setFormFieldValues = (record) => {
        var metaArrayFields = []
        var meta = []
        var length = 0
        var seoObj = null
        if (record.seo) {
            seoObj = JSON.parse(record.seo)
            if (seoObj.meta) {
                var filtered = seoObj.meta.filter(function (el) {
                    return el != null
                })
                filtered = filtered.filter(function (el) {
                    return el.property
                })
                meta = filtered
                length = filtered.length

                for (var k = 0; k < length; k++) {
                    metaArrayFields.push(k)
                }
            }
        }
        if (metaArrayFields.length === 0) {
            metaArrayFields.push(0)
        }

        setTimeout(() => {
            form.setFieldsValue({
                meta: meta,
                title: seoObj ? seoObj.title : '',
            })
        }, 100)
    }

    const getRequiredData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE)
            .then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
                setLevelData(streamData[0].levels)
            })
            .catch((error) => {
                message.error(error)
            })
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setLevel('')
                return
            }
        })
    }
    const handleSubmit = (record) => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var filtered = values.meta.filter(function (el) {
                    return el != null
                })
                var seo = {}
                seo.title = values.title ? values.title : ''
                seo.meta = filtered

                values['seo'] = seo

                var url = GLOBAL.URL.ADD_COURSE_GROUP
                if (record) {
                    url = GLOBAL.URL.UPDATE_COURSE_GROUP
                    values['id'] = record.id
                }
                let formBodyNewsObject = new FormData()
                formBodyNewsObject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formBodyNewsObject)
                    .then((response) => {
                        message.success(response.data, 5)
                        props.navigate('/groups')
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const propsRecord = props.location.state ? props.location.state.record : {}
    var levelDetails = []
    levelData.forEach((item) => {
        levelDetails.push(<Option value={item}>{item}</Option>)
    })
    var streamDetails = []
    streamData.forEach((item) => {
        streamDetails.push(<Option value={item.course}>{item.course}</Option>)
    })

    const formItemLayout = {
        labelCol: {
            xs: { span: 20 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <div>
            <h2>{isUpdate ? 'Update' : 'Add'} Course Group</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Group Name"
                    style={{ marginBottom: 8 }}
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Group Name',
                        },
                    ]}
                >
                    <Input placeholder="Please enter Group Name" autoFocus />
                </Form.Item>
                <Form.Item
                    label="Picture Url"
                    style={{ marginBottom: 8 }}
                    name="pictureUrl"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Picture Url',
                        },
                        {
                            type: 'url',
                            message: 'Please enter valid url',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Please enter Picture Url"
                    />
                </Form.Item>
                <Form.Item
                    label="Select course"
                    style={{ marginBottom: 8 }}
                    name="course"
                    rules={[
                        {
                            required: true,
                            message: 'Please select course',
                        },
                    ]}
                >
                    <Select
                        placeholder="Please select course"
                        onChange={handleCourseChange}
                    >
                        {streamDetails}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select level"
                    name="level"
                    rules={[
                        {
                            required: true,
                            message: 'Please select level',
                        },
                    ]}
                    style={{ marginBottom: 8 }}
                >
                    <Select placeholder="Please select level" value={level}>
                        {levelDetails}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Duration"
                    name="duration"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    style={{ marginBottom: 8 }}
                >
                    <Input
                        style={{ width: 200 }}
                        placeholder="Please enter duration"
                        type="number"
                    />
                </Form.Item>
                <Form.Item
                    label="SEO: Title"
                    name="title"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                    style={{ marginBottom: 8 }}
                >
                    <Input placeholder="SEO: Title" />
                </Form.Item>
                <h3 style={{ marginTop: 10 }}>SEO: Meta</h3>
                <MetaFormItems />
            </Form>
            <div style={{ marginTop: 10 }}>
                {propsRecord ? (
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSubmit(propsRecord)
                        }}
                        loading={loading}
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSubmit()
                        }}
                        loading={loading}
                    >
                        Add
                    </Button>
                )}
            </div>
        </div>
    )
}

export default AddGroup
