import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Modal, Descriptions, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function UserAcquisitionInfo(props) {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState()
    const [leadExternalId, setLeadExternalId] = useState()
    const [fetchData, setFetchData] = useState(false)

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }

        if (props.leadExternalId) {
            setLeadExternalId(props.leadExternalId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        if (!fetchData) {
            return
        }

        if (data && Object.keys(data).length !== 0) {
            setIsModalVisible(true)
            return
        }

        if (!userId && !leadExternalId) {
            return
        }

        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, leadExternalId, fetchData])

    const getData = () => {
        setLoading(true)

        if (userId) {
            RestApi.doGet(GLOBAL.URL.USER.GET_USER_ACQUITION_INFO + userId)
                .then((res) => {
                    setData(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else if (leadExternalId) {
            RestApi.doGet(
                GLOBAL.URL.LEADS.GET_LEAD_EXTERNAL_ACQUISITION_INFO +
                    leadExternalId
            )
                .then((res) => {
                    setData(res.data)
                    setIsModalVisible(true)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const showModal = () => {
        setIsModalVisible(true)
        setFetchData(true)
    }

    const hideModal = () => {
        setIsModalVisible(false)
    }

    const onCopy = () => {
        message.success('Copied!')
    }

    return (
        <>
            <Button
                type={'default'}
                size="small"
                onClick={showModal}
                loading={loading}
            >
                {'Acquisition Info'}
            </Button>
            <Modal open={isModalVisible} onCancel={hideModal} footer={null}>
                <Descriptions
                    title="User Acquisition Info"
                    bordered
                    size="small"
                    width={'800'}
                >
                    <Descriptions.Item label="Date" span={3}>
                        {data.updatedAt}
                    </Descriptions.Item>
                    <Descriptions.Item label="OS" span={3}>
                        {data.os}
                    </Descriptions.Item>
                    <Descriptions.Item label="UTM Source" span={3}>
                        {data.utmSource}
                    </Descriptions.Item>
                    <Descriptions.Item label="UTM Medium" span={3}>
                        {data.utmMedium}
                    </Descriptions.Item>
                    <Descriptions.Item label="UTM Campaign" span={3}>
                        {data.utmCampaign}
                    </Descriptions.Item>
                    <Descriptions.Item label="UTM Term" span={3}>
                        {data.utmTerm}
                    </Descriptions.Item>
                    <Descriptions.Item label="GCLID" span={3}>
                        {data.gclid ? (
                            <CopyToClipboard text={data.gclid} onCopy={onCopy}>
                                <Button type="link" style={{ padding: 0 }}>
                                    Copy GCLID
                                </Button>
                            </CopyToClipboard>
                        ) : (
                            '-'
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Created At" span={3}>
                        {data.createdAt}
                    </Descriptions.Item>
                </Descriptions>
            </Modal>
        </>
    )
}

export default UserAcquisitionInfo
