import {
    message,
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    Form,
    Breadcrumb,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from '@mui/material'

const Sections = (props) => {
    const [sectionData, setSectionData] = useState([])
    const [addEditSectionModalVisible, setAddEditSectionModalVisible] =
        useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [editSectionRecord, setEditSectionRecord] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getSectionsData()
        //eslint-disable-next-line
    }, [])

    const getSectionsData = () => {
        const { subjectName, chapterName } = props.location.state
        RestApi.doGet(
            GLOBAL.URL.GET_SECTIONS +
                '?subject=' +
                encodeURIComponent(subjectName) +
                '&chapter=' +
                encodeURIComponent(chapterName)
        )
            .then((res) => {
                setSectionData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
    }

    const openAddEditSectionModal = (record) => {
        form.setFieldsValue(record)
        setAddEditSectionModalVisible(true)
        setEditSectionRecord(record)
    }

    const closeAddEditSectionModal = () => {
        form.resetFields()
        setAddEditSectionModalVisible(false)
        setEditSectionRecord(null)
    }

    const handleSubmit = (editRecord) => {
        const { subjectName, chapterName } = props.location.state

        form.validateFields()
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_SECTION
                values['subject'] = subjectName
                values['chapter'] = chapterName
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_SECTION
                    values['id'] = editRecord.id
                }
                let formbodySection = new FormData()
                formbodySection.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodySection)
                    .then((response) => {
                        var sectionJsonArrayObject = []
                        sectionJsonArrayObject.push(response.data)
                        if (editRecord) {
                            sectionData.forEach((item) => {
                                if (item.id === editRecord.id) {
                                    item.name = values.name
                                }
                            })
                        } else {
                            setSectionData([
                                ...sectionData,
                                ...sectionJsonArrayObject,
                            ])
                        }
                        closeAddEditSectionModal()
                        message.success(
                            editRecord
                                ? 'Section Edited Successfully'
                                : 'Section Added Successfully'
                        )
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_SECTION, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = sectionData
                    setSectionData(successObject.filter((key) => key.id !== id))
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        var payload = {}
        payload.subject = props.location.state.subjectName

        props.navigate('/reorder', {
            state: {
                reorderData: sectionData,
                updateUrl: GLOBAL.URL.REORDER_SECTIONS,
                columns: columns,
                payload: payload,
            },
        })
    }

    const { subjectName, chapterName } = props.location.state
    let columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            openAddEditSectionModal(record)
                        }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <Link
                        href={'/mcq/topics'}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/mcq/topics', {
                                state: {
                                    subjectName: subjectName,
                                    chapterName: chapterName,
                                    sectionName: record.name,
                                },
                            })
                        }}
                        underline="none"
                    >
                        <Button type="link">{record.name}</Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure want to delete ?"
                    onConfirm={() => {
                        deleteItem(record.id)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    return (
        <div>
            <h2>Sections</h2>
            <div>
                <Button
                    type="primary"
                    onClick={() => {
                        openAddEditSectionModal()
                    }}
                >
                    Add Section
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        reorder()
                    }}
                    style={{ marginLeft: 10 }}
                >
                    Reorder
                </Button>
            </div>
            <div>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link
                            href={'/mcq/chapters'}
                            onClick={(event) => {
                                event.preventDefault()
                                props.navigate('/mcq/chapters', {
                                    state: {
                                        subjectName: subjectName,
                                    },
                                })
                            }}
                            underline="none"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                {subjectName}
                            </Button>
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Button type="link">{chapterName}</Button>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Table
                columns={columns}
                dataSource={sectionData}
                pagination={false}
                key="id"
                size="small"
            />
            <Modal
                title={editSectionRecord ? 'Edit Section' : 'Add Section'}
                open={addEditSectionModalVisible}
                onOk={() => {
                    handleSubmit(editSectionRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={closeAddEditSectionModal}
                okText={editSectionRecord ? 'Edit Section' : 'Add Section'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Section Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Section Name',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Please enter Section Name"
                            autoFocus
                            onPressEnter={() => {
                                handleSubmit(editSectionRecord)
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Sections
