import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    DatePicker,
    Radio,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'

function FacultyInvoice() {
    const { Option } = Select
    const { RangePicker } = DatePicker
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [faculties, setFaculties] = useState([])
    const [invoices, setInvoices] = useState([])
    const [selectedFacultyId, setSelectedFacultyId] = useState(0)
    const [invoiceId, setInvoiceId] = useState('')
    const [markAsPaidModalVisible, setMarkAsPaidModalVisible] = useState(false)
    const [selectedInvoice, setSelectedInvoice] = useState({})
    const [showMoreButtonVisible, setShowMoreButtonVisible] = useState(false)
    const [page, setPage] = useState(1)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [generateInvoiceModalVisible, setGenerateInvoiceModalVisible] =
        useState(false)

    useEffect(() => {
        getFaculties()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacultyId, endDate])

    const getFaculties = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY)
            .then((res) => {
                setFaculties(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const facultyOptions = () => {
        var options = []
        faculties.forEach((item) => {
            options.push(
                <Option value={item.id} key={item.id}>
                    {item.firstName + ' ' + item.lastName}
                </Option>
            )
        })

        return options
    }

    const fetchInvoices = () => {
        setInvoices([])
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_FACULTY_INVOICES +
                selectedFacultyId +
                '&page=1' +
                '&startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
            .then((res) => {
                setInvoices(res.data)
                setShowMoreButtonVisible(true)
                setPage(2)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchMoreInvoices = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_FACULTY_INVOICES +
                selectedFacultyId +
                '&page=' +
                page +
                '&startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
            .then((res) => {
                setInvoices((preState) => {
                    return [...preState, ...res.data]
                })

                if (res.data.length === 0) {
                    setShowMoreButtonVisible(false)
                }

                setPage(page + 1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const downloadInvoices = () => {
        setLoading(true)

        RestApi.download(
            GLOBAL.URL.FACULTY.DOWNLOAD_FACULTY_INVOICES +
                selectedFacultyId +
                '&page=' +
                page +
                '&startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Faculty_Invoices.xlsx')
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const generateInvoice = () => {
        form.validateFields(['month', 'year'])
            .then((values) => {
                setLoading(true)

                values.facultyId = selectedFacultyId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.FACULTY.GENERATE_FACULTY_INVOICE,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        fetchInvoices()
                        hideGenerateInvoice()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const markPaidUnpaid = (record) => {
        var payload = {}
        payload.uid = record.uid

        let url = GLOBAL.URL.FACULTY.MARK_FACULTY_INVOICE_AS_PAID
        if (record.paid) {
            url = GLOBAL.URL.FACULTY.MARK_FACULTY_INVOICE_AS_UNPAID
        } else {
            if (!invoiceId) {
                notification['error']({
                    message: 'Enter invoice id!',
                })
                return
            }
            payload.invoiceId = invoiceId
        }

        setLoading(true)

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(invoices)
                fullData.forEach((item) => {
                    if (item.uid === record.uid) {
                        if (!item.paid) {
                            item.invoiceId = invoiceId
                        }
                        item.paid = !item.paid
                        return
                    }
                })

                setInvoices(fullData)

                if (!record.paid) {
                    hideMarkAsPaidModal()
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            render: (text, record) => (
                <span>
                    {record.paid ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => markPaidUnpaid(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{
                                    border: 'none',
                                    padding: 0,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            </Button>
                        </Popconfirm>
                    ) : (
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                padding: 0,
                            }}
                            onClick={() => showMarkAsPaidModal(record)}
                        >
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        </Button>
                    )}
                </span>
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'Faculty',
            dataIndex: 'faculty',
            key: 'faculty',
            render: (text, record) => <span>{record.faculty.firstName}</span>,
        },
        {
            title: 'Invoice Value',
            dataIndex: 'invoiceValue',
            key: 'invoiceValue',
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
        },
        {
            title: 'Previous Dues',
            dataIndex: 'previousDues',
            key: 'previousDues',
        },
        {
            title: 'Taxable Value',
            dataIndex: 'taxableValue',
            key: 'taxableValue',
        },
        {
            title: 'GST',
            dataIndex: 'gst',
            key: 'gst',
        },
        {
            title: 'Invoice Id',
            dataIndex: 'invoiceId',
            key: 'invoiceId',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.createdAt}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteInvoice(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteInvoice = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.FACULTY.DELETE_FACULTY_INVOICE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                fetchInvoices()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showMarkAsPaidModal = (record) => {
        setSelectedInvoice(record)
        setInvoiceId(record.invoiceId)
        setMarkAsPaidModalVisible(true)
    }

    const hideMarkAsPaidModal = () => {
        setSelectedInvoice({})
        setInvoiceId('')
        setMarkAsPaidModalVisible(false)
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const showGenerateInvoice = () => {
        setGenerateInvoiceModalVisible(true)
    }

    const hideGenerateInvoice = () => {
        setGenerateInvoiceModalVisible(false)
    }

    const monthOptions = () => {
        var options = []
        for (var i = 1; i <= 12; i++) {
            options.push(
                <Radio.Button value={i} key={i}>
                    {i}
                </Radio.Button>
            )
        }

        return options
    }

    const yearOptions = () => {
        var options = []
        var currentYear = new Date().getFullYear()
        while (currentYear >= 2020) {
            options.push(
                <Radio.Button value={currentYear} key={currentYear}>
                    {currentYear}
                </Radio.Button>
            )
            currentYear = currentYear - 1
        }

        return options
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    }

    return (
        <div>
            <h2>Faculty Invoice</h2>
            <div>
                <Select
                    placeholder="Select Faculty"
                    style={{ width: 300 }}
                    showSearch
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear={true}
                    onChange={(facultyId) => {
                        if (facultyId) {
                            setSelectedFacultyId(facultyId)
                        } else {
                            setSelectedFacultyId(0)
                        }
                    }}
                >
                    {facultyOptions()}
                </Select>
                <RangePicker
                    format="DD-MMM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateFilterChange}
                    style={{ marginLeft: 10 }}
                />
                <Button
                    type="default"
                    style={{ marginLeft: 10 }}
                    loading={loading}
                    onClick={downloadInvoices}
                >
                    Download
                </Button>
            </div>
            {selectedFacultyId ? (
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="default"
                        size="small"
                        onClick={showGenerateInvoice}
                    >
                        Generate Invoice
                    </Button>
                </div>
            ) : null}
            <Table
                columns={tableColumns}
                dataSource={invoices}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            {showMoreButtonVisible ? (
                <Button
                    type="primary"
                    size="small"
                    onClick={fetchMoreInvoices}
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Mark Invoice As Paid'}
                open={markAsPaidModalVisible}
                onOk={() => markPaidUnpaid(selectedInvoice)}
                confirmLoading={loading}
                onCancel={hideMarkAsPaidModal}
                okText={'Mark Paid'}
                destroyOnClose={true}
                width={800}
            >
                <p>Enter the invoice ID: </p>
                <Input
                    autoFocus
                    defaultValue={invoiceId}
                    onChange={(e) => {
                        setInvoiceId(e.target.value)
                    }}
                />
            </Modal>
            <Modal
                title={'Generate Invoice'}
                open={generateInvoiceModalVisible}
                onOk={generateInvoice}
                confirmLoading={loading}
                onCancel={hideGenerateInvoice}
                okText={'Generate Invoice'}
                destroyOnClose={true}
                width={800}
            >
                {generateInvoiceModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Month"
                            name="month"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group>{monthOptions()}</Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="Year"
                            name="year"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group>{yearOptions()}</Radio.Group>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default FacultyInvoice
