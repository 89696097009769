import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, message, Tag, Progress } from 'antd'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { goBack } from '../../Util/ILUtil'

function SurveyResponses(props) {
    const [loading, setLoading] = useState(false)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [data, setData] = useState({ questions: [] })
    const [surveyId, setSurveyId] = useState('')

    useEffect(() => {
        var surveyIdInParams = props.location.state.surveyId

        getSurveyResponses(surveyIdInParams)
        setSurveyId(surveyIdInParams)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        message.destroy()
        if (loading) {
            message.loading('Loading...', 0)
        }
    }, [loading])

    const getSurveyResponses = (surveyId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_SURVEY_RESPONSES + surveyId)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const downloadResponses = () => {
        setDownloadLoading(true)

        RestApi.doGet(GLOBAL.URL.DOWNLOAD_SURVEY_RESPONSES + surveyId)
            .then((res) => {
                message.success(res.data)
            })
            .finally(() => {
                setDownloadLoading(false)
            })
    }

    const getResultsCard = (record, index) => {
        return (
            <Card style={{ marginBottom: 15 }} variant="outlined">
                <CardContent style={{ padding: 8 }}>
                    <div>
                        <h3>
                            {index + 1}. {record.question}
                        </h3>
                        <span style={{ marginTop: 5 }}>
                            Responses: {record.responsesCount}
                        </span>
                        {record.options.length ? (
                            <Table
                                columns={optionsTableColumns}
                                dataSource={record.options}
                                pagination={false}
                                size="small"
                                rowKey="index"
                                style={{ marginTop: 10 }}
                            />
                        ) : null}
                        {record.nps ? (
                            <span>
                                <div style={{ marginTop: 10 }}>
                                    <Tag color="geekblue">
                                        NPS Score: {record.nps.score}
                                    </Tag>
                                    <Tag color="purple">
                                        Promoters (9 &amp; 10):{' '}
                                        {record.nps.promoters}
                                    </Tag>
                                    <Tag color="orange">
                                        Passives (7 &amp; 8):{' '}
                                        {record.nps.passives}
                                    </Tag>
                                    <Tag color="red">
                                        Detractors (0 - 6):{' '}
                                        {record.nps.detractors}
                                    </Tag>
                                </div>
                                <Table
                                    columns={npsTableColumns}
                                    dataSource={JSON.parse(record.nps.breakup)}
                                    pagination={false}
                                    size="small"
                                    rowKey="point"
                                    style={{ marginTop: 10, width: 200 }}
                                />
                                <div style={{ marginTop: 10 }}>
                                    <a
                                        href="https://www.surveymonkey.com/mp/net-promoter-score-calculation/#:~:text=Add%20up%20the%20total%20responses,this%20is%20your%20NPS%20score"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        How is NPS calculated?
                                    </a>
                                </div>
                            </span>
                        ) : null}
                    </div>
                </CardContent>
            </Card>
        )
    }

    const optionsTableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Option',
            dataIndex: 'option',
            key: 'option',
            render: (text, record, index) => (
                <span>
                    {record.option}
                    <div style={{ width: 200 }}>
                        <Progress percent={record.percent} />
                    </div>
                </span>
            ),
        },
    ]

    const npsTableColumns = [
        {
            title: 'Point',
            dataIndex: 'point',
            key: 'point',
            width: 100,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
    ]

    return (
        <div>
            <div>
                <Button
                    onClick={() => {
                        goBack(props.navigate)
                    }}
                    size="small"
                >
                    Go Back
                </Button>
                <Button
                    onClick={downloadResponses}
                    size="small"
                    type="primary"
                    loading={loading || downloadLoading}
                    style={{ marginLeft: 20 }}
                >
                    Download Responses
                </Button>
            </div>
            <h2 style={{ marginTop: 10 }}>{data.surveyTitle}</h2>
            {!loading && (
                <Tag
                    color="geekblue"
                    style={{ display: 'table', marginBottom: 10 }}
                >
                    Total Responses: {data.totalResponses}
                </Tag>
            )}
            {data.questions.map((record, index) =>
                getResultsCard(record, index)
            )}
        </div>
    )
}

export default SurveyResponses
