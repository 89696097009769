import {
    message,
    Table,
    Button,
    Popconfirm,
    Divider,
    Modal,
    Input,
    notification,
} from 'antd'
import { CopyOutlined, EditOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from '@mui/material'

var ENABLE_CONSTANT = 'enable'
class Pages extends React.Component {
    state = {
        pageData: [],
        loading: false,
        updateUrlNameModalVisible: false,
        selectedPage: {},
        urlName: '',
    }

    componentDidMount() {
        this.getPagesData()
    }

    getPagesData() {
        this.setState({ loading: true })
        RestApi.doGet(GLOBAL.URL.GET_LIST_OF_PAGES)
            .then((res) => {
                this.setState({ pageData: res.data })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }
    enableDisablePage(id, action) {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        let url = ''
        if (action === ENABLE_CONSTANT) {
            url = GLOBAL.URL.ENABLE_PAGE
        } else {
            url = GLOBAL.URL.DISABLE_PAGE
        }
        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = this.state.pageData
                    successObject.forEach((data) => {
                        if (id === data.id) {
                            data.enabled =
                                action === ENABLE_CONSTANT ? true : false
                            return
                        }
                    })
                    this.setState({ pageData: successObject })
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }
    deletePage = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_PAGE, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var existingData = this.state.pageData
                    this.setState({
                        pageData: existingData.filter((key) => key.id !== id),
                    })
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }

    onCopy = () => {
        message.success('Link copied!')
    }

    showUpdateUrlNameModal = (record = {}) => {
        this.setState({
            updateUrlNameModalVisible: true,
            selectedPage: record,
            urlName: record.titleLink,
        })
    }

    hideUpdateUrlNameModal = () => {
        this.setState({ updateUrlNameModalVisible: false })
    }

    updateUrlName = () => {
        if (!this.state.urlName) {
            message.error('Url name needed!')
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.id = this.state.selectedPage.id
        payload.titleLink = this.state.urlName

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_PAGE_TITLE_LINK, postBody)
            .then((response) => {
                this.hideUpdateUrlNameModal()
                message.destroy()
                message.success(response.data)
                this.getPagesData()
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    render() {
        const columns = [
            {
                title: 'Edit',
                key: 'id',
                width: '60px',
                render: (text, record) => (
                    <span>
                        <Link
                            href={'/page/addpage'}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.navigate('/page/addpage', {
                                    state: { record: record },
                                })
                            }}
                            underline="none"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 14 }}
                                />
                            </Button>
                        </Link>
                    </span>
                ),
            },
            {
                title: 'Titile',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (
                    <span>
                        {record.title}
                        <br />
                        <span style={{ fontSize: 11 }}>
                            <Button
                                type="link"
                                style={{ padding: 0 }}
                                onClick={() =>
                                    this.showUpdateUrlNameModal(record)
                                }
                            >
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 11 }}
                                />
                            </Button>
                            <span style={{ marginLeft: 3 }}>
                                Url name: {record.titleLink}
                            </span>
                        </span>
                    </span>
                ),
            },
            {
                title: 'Deep Link',
                dataIndex: 'deepLink',
                key: 'deepLink',
                render: (text, record) => (
                    <span>
                        {record.deepLink ? (
                            <CopyToClipboard
                                text={record.deepLink}
                                onCopy={() =>
                                    notification['success']({
                                        message: 'Link copied!',
                                    })
                                }
                            >
                                <span>
                                    <Button
                                        type="link"
                                        style={{ padding: 0, fontSize: 10 }}
                                    >
                                        <CopyOutlined />
                                        {'Copy Link'}
                                    </Button>
                                </span>
                            </CopyToClipboard>
                        ) : null}
                    </span>
                ),
            },
            {
                title: 'Action',
                key: 'active',
                render: (text, record) => (
                    <span>
                        {!record.enabled ? (
                            <Button
                                style={{
                                    background: 'red',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    this.enableDisablePage(
                                        record.id,
                                        ENABLE_CONSTANT
                                    )
                                }}
                                size="small"
                            >
                                Disabled
                            </Button>
                        ) : (
                            <Button
                                onClick={() => {
                                    this.enableDisablePage(record.id, 'disable')
                                }}
                                type="primary"
                                size="small"
                            >
                                Enabled
                            </Button>
                        )}
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure want to delete ?"
                            onConfirm={() => {
                                this.deletePage(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" onClick={() => {}}>
                                Delete
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]

        return (
            <div>
                <h2>Pages</h2>
                <Link
                    href={'/page/addpage'}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.navigate('/page/addpage', {
                            state: { record: null },
                        })
                    }}
                    underline="none"
                >
                    <Button type="primary" size="small">
                        Add Page
                    </Button>
                </Link>
                <Table
                    columns={columns}
                    dataSource={this.state.pageData}
                    pagination={false}
                    size="small"
                    loading={this.state.loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
                <Modal
                    title="Update Url Name"
                    open={this.state.updateUrlNameModalVisible}
                    onOk={() => {
                        this.updateUrlName()
                    }}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideUpdateUrlNameModal}
                    okText="Update"
                    destroyOnClose={true}
                    width={800}
                >
                    <h3>Update Url Name:</h3>
                    <Input
                        value={this.state.urlName}
                        autoFocus
                        onChange={(e) => {
                            this.setState({
                                urlName: e.target.value,
                            })
                        }}
                    ></Input>
                </Modal>
            </div>
        )
    }
}

export default Pages
