import { combineReducers } from 'redux'
import { siteSearchHistory, searchScrollPosition } from './ILSearchReducer'
import { userFavoriteMenus } from './SideBarFavoritesReducer'
import { isLoginModal } from './ILLoginModalReducer'
import { isLoginScreen } from './ILLoginScreenReducer'
import { isSideDrawerCollapsed } from './ILSideDrawerCollapsedReducer'

export default combineReducers({
    siteSearchHistory,
    searchScrollPosition,
    userFavoriteMenus,
    isLoginModal,
    isLoginScreen,
    isSideDrawerCollapsed,
})
