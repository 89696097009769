import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Select, Radio, DatePicker, message, Table, Modal } from 'antd'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const REPORT_TYPE_TOTAL_DURATION = 'Total_Duration'
const REPORT_TYPE_BY_COURSE = 'By_Course'

function TotalWatchedStats(props) {
    const { Option } = Select
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [course, setCourse] = useState('')
    const [level, setLevel] = useState('')
    const [indOrFullSubject, setIndOrFullSubject] = useState('both')
    const [freeOrPaid, setFreeOrPaid] = useState('both')
    const [os, setOs] = useState('all')
    const [data, setData] = useState(null)
    const [mapData, setMapData] = useState(StatsConstants.INITIAL_BAR_OPTIONS)
    const [options, setOptions] = useState({})
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [selectedCourseNames, setSelectedCourseNames] = useState([])
    const [selectedAttempts, setSelectedAttempts] = useState([])
    const [monthData, setMonthData] = useState(null)
    const [monthChartTitle, setMonthChartTitle] = useState()
    const [monthMapData, setMonthMapData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [monthOptions, setMonthOptions] = useState({})
    const [userJoiningStartDate, setUserJoiningStartDate] = useState('')
    const [userJoiningEndDate, setUserJoiningEndDate] = useState('')
    const [reportStartDate, setReportStartDate] = useState('')
    const [reportEndDate, setReportEndDate] = useState('')
    const [reportType, setReportType] = useState(REPORT_TYPE_TOTAL_DURATION)
    const [dataByCourse, setDataByCourse] = useState([])
    const [dataByCourseModules, setDataByCourseModules] = useState([])
    const [courseModulesModalVisible, setCourseModulesModalVisible] =
        useState(false)
    const [seletedCourseName, setSelectedCourseName] = useState('')
    const [loadingCourseModulesData, setloadingCourseModulesDatL] =
        useState(false)

    useEffect(() => {
        getStreamCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCourseNames()
        getAttempts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, level, indOrFullSubject])

    useEffect(() => {
        if (!data) {
            return
        }

        drawChart()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (!monthData) {
            return
        }

        drawMonthChart()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthData])

    const getAttempts = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttempts(res.data)
        })
    }

    const getStreamCourses = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            setStreamData(JSON.parse(res.data))
        })
    }

    const getCourseNames = () => {
        var url = GLOBAL.URL.GET_COURSE_ACCESS_COURSE_NAMES

        RestApi.doGet(url).then((res) => {
            setCourseNames(res.data)
        })
    }

    const getData = () => {
        if (!reportStartDate || !reportEndDate) {
            message.error('Report date range needed!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.course = course
        payload.level = level
        payload.indOrFullSubject = indOrFullSubject
        payload.courseNames = selectedCourseNames
        payload.attempts = selectedAttempts
        payload.freeOrPaid = freeOrPaid
        payload.os = os
        payload.userJoiningStartDate = userJoiningStartDate
        payload.userJoiningEndDate = userJoiningEndDate
        payload.reportStartDate = reportStartDate
        payload.reportEndDate = reportEndDate

        var url = GLOBAL.URL.GET_TOTAL_WATCHED_MONTH_WISE
        if (reportType === REPORT_TYPE_BY_COURSE) {
            url = GLOBAL.URL.GET_TOTAL_WATCHED_COURSE_WISE
        }
        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formData)
            .then((res) => {
                var response = JSON.parse(res.data)
                if (reportType === REPORT_TYPE_TOTAL_DURATION) {
                    setData(response)
                } else {
                    setDataByCourse(response)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getMonthSplit = (item, labels) => {
        setLoading(true)

        var payload = {}
        payload.monthData = labels[item[0].index]
        payload.course = course
        payload.level = level
        payload.indOrFullSubject = indOrFullSubject
        payload.courseNames = selectedCourseNames
        payload.attempts = selectedAttempts
        payload.freeOrPaid = freeOrPaid
        payload.os = os
        payload.userJoiningStartDate = userJoiningStartDate
        payload.userJoiningEndDate = userJoiningEndDate
        payload.reportStartDate = reportStartDate
        payload.reportEndDate = reportEndDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.GET_TOTAL_WATCHED_DAY_WISE, formData)
            .then((res) => {
                setMonthData(JSON.parse(res.data))
                setMonthChartTitle(labels[item[0].index])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const drawChart = () => {
        var hrs = []
        var labels = []
        var users = []

        data.forEach((row) => {
            labels.push(row.label)
            hrs.push(row.hrs)
            users.push(row.count)
        })

        var hrsDataSet = {}
        hrsDataSet.type = 'bar'
        hrsDataSet.data = hrs
        hrsDataSet.label = 'Hours'
        hrsDataSet.backgroundColor = '#4bc0c0'
        hrsDataSet.borderColor = '#4bc0c0'
        hrsDataSet.fill = true
        hrsDataSet.yAxisID = 'y-1'
        hrsDataSet.datalabels = { display: true }

        var usersDataSet = {}
        usersDataSet.type = 'line'
        usersDataSet.data = users
        usersDataSet.label = 'Users'
        usersDataSet.borderColor = '#396ab1'
        usersDataSet.fill = false
        usersDataSet.yAxisID = 'y-2'
        usersDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(usersDataSet)
        mapData.datasets.push(hrsDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'Hrs watched by Month'
        options.onClick = (evt, item) => {
            getMonthSplit(item, labels)
        }

        setMapData(mapData)
        setOptions(options)
    }

    const drawMonthChart = (title) => {
        var hrs = []
        var labels = []
        var users = []

        monthData.forEach((row) => {
            labels.push(row.label)
            hrs.push(row.hrs)
            users.push(row.count)
        })

        var hrsDataSet = {}
        hrsDataSet.type = 'bar'
        hrsDataSet.data = hrs
        hrsDataSet.label = 'Hours'
        hrsDataSet.backgroundColor = '#4bc0c0'
        hrsDataSet.borderColor = '#4bc0c0'
        hrsDataSet.fill = true
        hrsDataSet.yAxisID = 'y-1'
        hrsDataSet.datalabels = { display: true }

        var usersDataSet = {}
        usersDataSet.type = 'line'
        usersDataSet.data = users
        usersDataSet.label = 'Users'
        usersDataSet.borderColor = '#396ab1'
        usersDataSet.fill = false
        usersDataSet.yAxisID = 'y-2'
        usersDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(usersDataSet)
        mapData.datasets.push(hrsDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text =
            'Active Subscribers By Month (' + monthChartTitle + ')'

        setMonthMapData(mapData)
        setMonthOptions(options)

        document
            .getElementById('dayChart')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const onChangeStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setCourse(e.target.value)
        setLevelsData(levels)
        setLevel('')
    }

    const onChangeLevel = (e) => {
        setLevel(e.target.value)
    }

    const onChangeIndOrFullSubject = (e) => {
        setIndOrFullSubject(e.target.value)
    }

    const onChangeFreeOrPaid = (e) => {
        setFreeOrPaid(e.target.value)
    }

    const onChangeOs = (e) => {
        setOs(e.target.value)
    }

    const onChangeReportType = (e) => {
        setReportType(e.target.value)
    }

    const onChangeCourseName = (value) => {
        setSelectedCourseNames(value)
    }

    const onClearCourseName = () => {
        setSelectedCourseNames([])
    }

    const onChangeAttempts = (value) => {
        setSelectedAttempts(value)
    }

    const onClearAttempts = () => {
        setSelectedAttempts([])
    }

    const onUserJoiningDateChange = (value, dateString) => {
        setUserJoiningStartDate(dateString[0])
        setUserJoiningEndDate(dateString[1])
    }

    const onReportDateChange = (value, dateString) => {
        setReportStartDate(dateString[0])
        setReportEndDate(dateString[1])
    }

    const streamOptions = () => {
        var options = []

        options.push(
            <Radio.Button value="" key="All">
                {'All'}
            </Radio.Button>
        )

        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []

        options.push(
            <Radio.Button value="" key="All">
                {'All'}
            </Radio.Button>
        )

        levelsData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const courseOptions = () => {
        var options = []
        courseNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((attempt) => {
            options.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt}
                </Option>
            )
        })

        return options
    }

    const courseWiseColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Hours',
            dataIndex: 'hrs',
            key: 'hrs',
        },
        {
            title: 'Users',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Course Name',
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showCourseModuleSplitModal(record.label)}
                >
                    {record.label}
                </Button>
            ),
        },
    ]

    const courseModuleWiseColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Hours',
            dataIndex: 'hrs',
            key: 'hrs',
        },
        {
            title: 'Users',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Module Name',
            dataIndex: 'label',
            key: 'label',
        },
    ]

    const showCourseModuleSplitModal = (courseName) => {
        setSelectedCourseName(courseName)
        setCourseModulesModalVisible(true)
        getCourseModuleSplit(courseName)
    }

    const hideCourseModuleSplitModal = () => {
        setSelectedCourseName('')
        setCourseModulesModalVisible(false)
        setDataByCourseModules([])
    }

    const getCourseModuleSplit = (courseName) => {
        setloadingCourseModulesDatL(true)

        var payload = {}
        payload.courseName = courseName
        payload.attempts = selectedAttempts
        payload.freeOrPaid = freeOrPaid
        payload.os = os
        payload.userJoiningStartDate = userJoiningStartDate
        payload.userJoiningEndDate = userJoiningEndDate
        payload.reportStartDate = reportStartDate
        payload.reportEndDate = reportEndDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.GET_TOTAL_WATCHED_COURSE_MODULE_WISE,
            formData
        )
            .then((res) => {
                setDataByCourseModules(JSON.parse(res.data))
            })
            .finally(() => {
                setloadingCourseModulesDatL(false)
            })
    }

    return (
        <div>
            <h3>Total Duration Watched</h3>
            <div style={{ marginTop: 10 }}>
                <span>Report Range: </span>
                <RangePicker
                    format="DD-MMM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onReportDateChange}
                    style={{ marginLeft: 10 }}
                />
                <span style={{ marginLeft: 20 }}>User Joining: </span>
                <RangePicker
                    format="DD-MMM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onUserJoiningDateChange}
                    style={{ marginLeft: 10 }}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group onChange={onChangeStream} value={course}>
                    {streamOptions()}
                </Radio.Group>
                {course ? (
                    <Radio.Group
                        onChange={onChangeLevel}
                        value={level}
                        style={{ marginLeft: 10 }}
                    >
                        {levelOptions()}
                    </Radio.Group>
                ) : null}
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group
                    onChange={onChangeIndOrFullSubject}
                    value={indOrFullSubject}
                >
                    <Radio.Button value="both" key={'both'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value="full" key={'full'}>
                        {'Full Subjects'}
                    </Radio.Button>
                    <Radio.Button value="ind" key={'ind'}>
                        {'Individual Modules'}
                    </Radio.Button>
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeFreeOrPaid}
                    value={freeOrPaid}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value="both" key={'both'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value="paid" key={'paid'}>
                        {'Paid'}
                    </Radio.Button>
                    <Radio.Button value="free" key={'free'}>
                        {'Free'}
                    </Radio.Button>
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeOs}
                    value={os}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value="all" key={'all'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value="android" key={'android'}>
                        {'Android'}
                    </Radio.Button>
                    <Radio.Button value="web" key={'web'}>
                        {'Web'}
                    </Radio.Button>
                    <Radio.Button value="ios" key={'ios'}>
                        {'iOS'}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <Select
                    placeholder="Courses"
                    showSearch
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 490 }}
                    allowClear={true}
                    onChange={onChangeCourseName}
                    onClear={onClearCourseName}
                    mode="multiple"
                >
                    {courseOptions()}
                </Select>
                <Select
                    placeholder="Attempts"
                    showSearch
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear={true}
                    mode="multiple"
                    style={{ width: 450, marginLeft: 10 }}
                    onChange={onChangeAttempts}
                    onClear={onClearAttempts}
                >
                    {getAttemptOptions()}
                </Select>
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group onChange={onChangeReportType} value={reportType}>
                    <Radio.Button
                        value={REPORT_TYPE_TOTAL_DURATION}
                        key={REPORT_TYPE_TOTAL_DURATION}
                    >
                        {'Total Duration'}
                    </Radio.Button>
                    <Radio.Button
                        value={REPORT_TYPE_BY_COURSE}
                        key={REPORT_TYPE_BY_COURSE}
                    >
                        {'By Course'}
                    </Radio.Button>
                </Radio.Group>
                <Button
                    type="primary"
                    onClick={getData}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch Data
                </Button>
            </div>
            {reportType === REPORT_TYPE_TOTAL_DURATION ? (
                <>
                    <div>
                        <Bar
                            data={mapData}
                            width={800}
                            height={400}
                            options={options}
                        />
                    </div>
                    <div>
                        <Bar
                            id="dayChart"
                            data={monthMapData}
                            width={800}
                            height={400}
                            options={monthOptions}
                        />
                    </div>
                </>
            ) : (
                <Table
                    columns={courseWiseColumns}
                    dataSource={dataByCourse}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="label"
                    style={{ marginTop: 10 }}
                />
            )}
            <Modal
                title={'Course Modules Split'}
                open={courseModulesModalVisible}
                footer={null}
                onCancel={hideCourseModuleSplitModal}
                destroyOnClose={true}
                width={800}
            >
                <h3>{seletedCourseName}</h3>
                <Table
                    columns={courseModuleWiseColumns}
                    dataSource={dataByCourseModules}
                    pagination={false}
                    size="small"
                    loading={loadingCourseModulesData}
                    rowKey="label"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default TotalWatchedStats
