import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import { InputNumber, Button } from 'antd'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ChartDataLabels
)
const emptyBarData = {
    datasets: [{}],
}

function UserStreamSplitStats() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState({})
    const [chartData, setChartData] = useState(emptyBarData)
    const [options, setOptions] = useState({})
    const [limit, setLimit] = useState(6)
    const [selectedMonth, setSelectedMonth] = useState({})

    useEffect(() => {
        getStats()
        getTotalStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (data.length === 0) {
            return
        }

        formChartObject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const getStats = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USERS_BY_STREAM_SPLIT_BY_MONTH + limit)
            .then((res) => {
                setData(res.data)
                setSelectedMonth(res.data[res.data.length - 1])
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getTotalStats = () => {
        RestApi.doGet(GLOBAL.URL.GET_USERS_BY_STREAM_SPLIT_TOTAL).then(
            (res) => {
                setTotalData(res.data)
            }
        )
    }

    const formChartObject = () => {
        var labels = []
        data.forEach((dataRow) => {
            labels.push(dataRow.label)
        })

        var dataSetsData = {}
        data.forEach((dataRow) => {
            dataRow.stats.forEach((statsRow) => {
                if (statsRow.label in dataSetsData) {
                    dataSetsData[statsRow.label].push(statsRow.countPercent)
                } else {
                    dataSetsData[statsRow.label] = [statsRow.countPercent]
                }
            })
        })

        var cnt = 0
        var dataSets = []
        for (var key in dataSetsData) {
            if (dataSetsData.hasOwnProperty(key)) {
                var color =
                    StatsConstants.COLORS[
                        cnt % (StatsConstants.COLORS.length - 1)
                    ]

                var dataSet = {}
                dataSet.label = key
                dataSet.data = dataSetsData[key]
                dataSet.backgroundColor = color
                dataSet.borderColor = color
                dataSet.fill = true

                dataSets.push(dataSet)

                cnt++
            }
        }

        var chartData = {}
        chartData.labels = labels
        chartData.datasets = dataSets

        var options = {
            ...clone(StatsConstants.CHART_OPTIONS),
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        }
        options.plugins.datalabels.anchor = 'center'
        options.hover = {}
        options.tooltips = { mode: 'label' }
        options.onClick = (evt, item) => {
            showSplit(item, labels)
            document
                .getElementById('tableSplit')
                .scrollIntoView({ behavior: 'smooth', block: 'center' })
        }

        setChartData(chartData)
        setOptions(options)
    }

    const showSplit = (item, labels) => {
        if (item.length === 0) {
            return
        }

        var label
        if (labels[item[0].index]) {
            label = labels[item[0].index]
        } else if (labels[item[1].index]) {
            label = labels[item[1].index]
        } else {
            return
        }
        data.forEach((dataRow) => {
            if (dataRow.label === label) {
                setSelectedMonth(dataRow)
                return
            }
        })
    }

    const getStatsTable = (monthData) => {
        return (
            <table
                style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse',
                }}
            >
                <thead>
                    <tr
                        style={{
                            backgroundColor: '#32127A',
                            color: 'white',
                        }}
                    >
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                                textAlign: 'center',
                            }}
                            colSpan={3}
                        >
                            {monthData.label}
                        </th>
                    </tr>
                    <tr
                        style={{
                            backgroundColor: '#32127A',
                            color: 'white',
                        }}
                    >
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            Stream
                        </th>
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            Count %
                        </th>
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            Count
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {getSeletedMonthTableData(monthData)}
                    {getSeletedMonthFooterRow(monthData)}
                </tbody>
            </table>
        )
    }

    const getSeletedMonthTableData = (monthData) => {
        var rows = []
        monthData.stats.forEach((stat, index) => {
            rows.push(
                <tr key={index}>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                        }}
                    >
                        {stat.label}
                    </td>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'right',
                        }}
                    >
                        {stat.countPercent}
                    </td>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'right',
                        }}
                    >
                        {stat.count}
                    </td>
                </tr>
            )
        })

        return rows
    }

    const getSeletedMonthFooterRow = (monthData) => {
        return (
            <tr style={{ backgroundColor: '#107A73', color: 'white' }}>
                <td
                    style={{
                        padding: 5,
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                    }}
                >
                    {'Total'}
                </td>
                <td
                    style={{
                        padding: 5,
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                        textAlign: 'right',
                    }}
                >
                    {'100%'}
                </td>
                <td
                    style={{
                        padding: 5,
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                        textAlign: 'right',
                    }}
                >
                    {monthData.totalUsersCount}
                </td>
            </tr>
        )
    }

    return (
        <div>
            <h2>Users By Stream Split</h2>
            <div>
                <span># Months: </span>
                <InputNumber
                    min={1}
                    defaultValue={6}
                    onChange={(value) => setLimit(value)}
                />
                <Button
                    type="primary"
                    onClick={getStats}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch
                </Button>
            </div>
            <Bar data={chartData} width={800} height={400} options={options} />
            <div style={{ marginTop: 10, display: 'flex' }}>
                <span>{totalData.label ? getStatsTable(totalData) : null}</span>
                <span id="tableSplit" style={{ marginLeft: 20 }}>
                    {selectedMonth.label ? getStatsTable(selectedMonth) : null}
                </span>
            </div>
        </div>
    )
}

export default UserStreamSplitStats
