import { actionTypes } from '../actions'

export const siteSearchHistory = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_USER_SEARCH_HISTORY:
            const updatedHistory = action.payload.slice(0, 50)
            return updatedHistory
        default:
            return state
    }
}

export const searchScrollPosition = (state = 0, action) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_SCROLL_POSITION:
            return action.payload
        default:
            return state
    }
}
