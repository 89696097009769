import React, { useEffect } from 'react'
import { Button, Input, message, Checkbox, Form } from 'antd'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'

const AddFaculty = ({ navigate, location }) => {
    const [form] = Form.useForm()

    useEffect(() => {
        checkEdit()
        //eslint-disable-next-line
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const checkEdit = () => {
        const { record } = location.state || {}
        if (record) {
            form.setFieldsValue({
                firstName: record.firstName,
                lastName: record.lastName,
                linkedin: record.linkedin,
                pictureUrl: record.pictureUrl,
                description: record.description,
                gstApplicable: record.gstApplicable,
            })
        }
    }

    const onSubmit = (record) => {
        form.validateFields()
            .then((values) => {
                var url = GLOBAL.URL.FACULTY.ADD_FACULTY
                if (record) {
                    url = GLOBAL.URL.FACULTY.UPDATE_FACULTY
                    values['id'] = record.id
                }
                let formBodyNewsObject = new FormData()
                formBodyNewsObject.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formBodyNewsObject)
                    .then((response) => {
                        message.success(response.data, 5)
                        navigate('/faculty')
                    })
                    .catch((resp) => {
                        message.error(resp.data, 5)
                    })
            })
            .catch((error) => {
                //empty catch block
            })
    }

    return (
        <div>
            <h2>Faculty</h2>
            <Form
                initialValues={{
                    gstApplicable:
                        location.state && location.state.record
                            ? location.state.record.gstApplicable
                            : false,
                }}
                layout="horizontal"
                form={form}
                {...formItemLayout}
            >
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter First Name',
                        },
                    ]}
                >
                    <Input placeholder="Please enter First Name" />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Last Name',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Please enter last Name"
                    />
                </Form.Item>
                <Form.Item
                    label="Picture Url"
                    name="pictureUrl"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter picture url',
                        },
                        {
                            type: 'url',
                            message: 'Please enter valid url',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Please Enter Picture Url"
                    />
                </Form.Item>
                <Form.Item
                    label="GST Applicable"
                    name="gstApplicable"
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                            message: 'Required',
                        },
                    ]}
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="linkedIn Url"
                    name="linkedin"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter linkedIn Url',
                        },
                        {
                            type: 'url',
                            message: 'Please enter valid url',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Please Enter LinkedIn Url"
                    />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'please enter description',
                        },
                    ]}
                >
                    <Input.TextArea
                        rows={4}
                        placeholder="please enter description"
                    />
                </Form.Item>
                <Form.Item>
                    {location.state && location.state.record ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                onSubmit(location.state.record)
                            }}
                        >
                            Update
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                onSubmit()
                            }}
                        >
                            Submit
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddFaculty
