import { makeStyles } from 'tss-react/mui'

export const searchStyles = makeStyles()((theme) => ({
    searchBar: {
        borderRadius: '14px',
        padding: '4px 30px 4px 6px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        background: 'rgb(243, 246, 249)',
        color: '#32117A',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            borderRadius: '50%',
            padding: '6px',
            justifyContent: 'center',
            background: 'none',
        },
    },
    searchBarDark: {
        [theme.breakpoints.up('md')]: {
            background: 'rgb(243, 246, 249)',
            color: '#434d5b',
            border: '1px solid rgb(218, 226, 237)',
        },
    },
    programSearchIcn: {
        [theme.breakpoints.down('md')]: {
            color: '#2e2e80',
        },
    },
    darkSearchIcn: {
        color: '#2e2e80',
        [theme.breakpoints.down('md')]: {
            color: '#fff',
            width: '24px',
            height: '24px',
        },
    },
    modalSearchIcn: {
        color: '#2e2e80',
    },
    searchTxt: {
        fontSize: '14px',
    },
    searchDialog: {
        '& .MuiPaper-root': {
            borderRadius: '12px !important',
        },
    },
    input: {
        '& .MuiInputBase-input::placeholder': {
            color: '#000',
        },
    },
    modalBodyText: {
        fontSize: '18px',
        fontWeight: 700,
        marginBottom: '12px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    dialogHeadContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    searchTitle: {
        fontSize: '16px',
        fontWeight: 600,
    },
    customScrollbar: {
        '&.MuiDialogContent-root': {
            padding: '0px 0px 10px !important',
        },
        '&::-webkit-scrollbar': {
            width: 5,
            marginRight: '5px',
            [theme.breakpoints.down('sm')]: {
                width: 10,
            },
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#767676',
            borderRadius: 10,
        },
    },
    modalHeaderMobile: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '4px 0px 15px',
        position: 'relative',
    },
    backIcn: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
    },
    filtersSection: {
        marginTop: '8px',
    },
    headerFilterTxt: {
        marginLeft: '0px',
    },
    filtersTxt: {
        color: '#000',
        fontWeight: 600,
        fontSize: '13px',
        letterSpacing: '1.5px',
        marginLeft: '24px',
    },
    resultTitle: {
        color: '#15c',
        fontSize: '15px',
        fontWeight: 600,
    },
    snippetTxt: {
        fontSize: '14px',
        marginBottom: 0,
        color: '#000',
    },
    resultsContainer: {
        margin: '10px 0px',
        [theme.breakpoints.down('sm')]: {
            margin: '5px 0px 10px',
        },
    },
    resultItem: {
        borderRadius: '6px',
        padding: '10px 24px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        '&:hover': {
            background: '#f0f7ff',
        },
    },
    resultDivider: {
        margin: '0px 24px',
    },
    moreBtn: {
        background: '#f5f5f5',
        borderRadius: '12px',
        textTransform: 'none',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '15px',
            '&:hover': {
                background: '#f5f5f5',
            },
        },
    },
    historyContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '8px',
        margin: '0px 24px',
        background: '#f8f8f8',
        borderRadius: '6px',
        marginBottom: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        transition: 'all 0.3s',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '#32117A 0px 0px 0px 1px',
            background: '#f4f6fc',
            '& .MuiTypography-root': {
                color: '#32117A',
            },
            '& .MuiSvgIcon-root': {
                color: '#32117A',
            },
        },
    },
    historyTxtContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        flex: 1,
    },
    historyItem: {
        fontSize: '16px',
    },
    historyIcn: {
        width: '16px',
        height: '16px',
        color: '#545655',
    },
    clearIcn: {
        width: '18px',
        height: '18px',
        color: '#545655',
    },
    moreBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    filterOption: {
        '& .MuiTypography-root': {
            fontSize: '14px',
            whiteSpace: 'nowrap',
        },
        marginRight: '8px',
        [theme.breakpoints.up('md')]: {
            marginRight: '30px !important',
        },
    },
    filtersContainer: {
        margin: '4px 0px 0px',
        overflowX: 'auto',
        width: '100%',
        flexWrap: 'nowrap !important',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    newTabBtn: {
        textTransform: 'none',
        fontSize: '12px',
        display: 'inline',
        marginTop: '0px !important',
        padding: '4px 2px 4px 0px',
        transition: 'all 0.3s',
        '&:hover': {
            background: '#e9eefa',
            borderRadius: '5px',
        },
    },
    noDataErr: {
        fontSize: '12px',
        color: 'red',
        marginTop: '3px',
    },
    tabIcn: {
        width: '13px',
        height: '13px',
    },
    noHistoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '15px 0px 20px',
    },
}))
