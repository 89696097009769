import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, notification, Input, Descriptions, Radio, Form } from 'antd'
import RouteData from '../Common/RouteData'

function HomeTopBanner(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [content, setContent] = useState()
    const [buttonText, setButtonText] = useState()
    const [form] = Form.useForm()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.GET_HOME_TOP_BANNER)
            .then((res) => {
                setData(JSON.parse(res.data))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showUpdateModal = () => {
        setUpdateModalVisible(true)
        setContent(data.content)
        setButtonText(data.buttonText)

        setTimeout(() => {
            form.setFieldsValue({
                content: data.content,
                buttonText: data.buttonText,
                route: data.route,
                externalUrl: data.externalUrl,
                showInCatalog: data.showInCatalog,
                showInCart: data.showInCart,
            })
        }, 200)
    }

    const hideUpdateModal = () => {
        setUpdateModalVisible(false)
    }

    const updateContent = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_HOME_TOP_BANNER, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateModal()
                        getData()
                    })
                    .catch((error) => {})
                    .finally(() => setLoading(false))
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getShowInCatalogOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        return options
    }

    return (
        <div>
            {loading ? <div>{'Loading...'}</div> : null}
            <div>
                {!updateModalVisible ? (
                    <>
                        {data && data.content ? (
                            <div>
                                <Descriptions
                                    title="Header Top Banner"
                                    bordered
                                    size="small"
                                >
                                    <Descriptions.Item label="Content" span={3}>
                                        {data.content}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label="Show In Catalog"
                                        span={3}
                                    >
                                        {data.showInCatalog ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label="Show In Cart"
                                        span={3}
                                    >
                                        {data.showInCart ? 'Yes' : 'No'}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label="Buton Text"
                                        span={3}
                                    >
                                        {data.buttonText}
                                    </Descriptions.Item>
                                    {data.route ? (
                                        <Descriptions.Item
                                            label="Route"
                                            span={3}
                                        >
                                            {JSON.stringify(data.routeData)}
                                        </Descriptions.Item>
                                    ) : null}
                                    {data.externalUrl ? (
                                        <Descriptions.Item
                                            label="External URL"
                                            span={3}
                                        >
                                            {data.externalUrl}
                                        </Descriptions.Item>
                                    ) : null}
                                </Descriptions>
                            </div>
                        ) : (
                            <h3>---Nothing Set---</h3>
                        )}
                        <div style={{ marginTop: 20 }}>
                            <Button type="primary" onClick={showUpdateModal}>
                                Edit
                            </Button>
                        </div>
                    </>
                ) : (
                    <div>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="Content"
                                style={{ marginBottom: 8 }}
                                name="content"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Show In Catalog"
                                style={{ marginBottom: 8 }}
                                name="showInCatalog"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {getShowInCatalogOptions()}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Show In Cart"
                                style={{ marginBottom: 8 }}
                                name="showInCart"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {getShowInCatalogOptions()}
                                </Radio.Group>
                            </Form.Item>
                            {content ? (
                                <>
                                    <Form.Item
                                        label="Button Text"
                                        style={{ marginBottom: 8 }}
                                        name="buttonText"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input
                                            onChange={(e) =>
                                                setButtonText(e.target.value)
                                            }
                                        />
                                    </Form.Item>
                                    {buttonText ? (
                                        <>
                                            <Form.Item
                                                label="External URL"
                                                style={{
                                                    marginBottom: 8,
                                                }}
                                                name="externalUrl"
                                                rules={[
                                                    {
                                                        required: false,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <RouteData
                                                form={form}
                                                routeRequired={false}
                                                intialRoute={data.route}
                                            />
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                        </Form>
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="primary"
                                onClick={updateContent}
                                loading={loading}
                            >
                                Update
                            </Button>
                            <Button
                                type="default"
                                style={{ marginLeft: 20 }}
                                onClick={hideUpdateModal}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HomeTopBanner
