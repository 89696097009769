import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import { EditTwoTone } from '@ant-design/icons'
import { Link } from '@mui/material'

function ExternalSalesTeam(props) {
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [teams, setTeams] = useState([])
    const [addEditTeamModalVisible, setAddEditTeamModalVisible] =
        useState(false)
    const [selectedTeamRecord, setSelectedTeamRecord] = useState({})
    const [membersModalVisible, setMembersModalVisible] = useState(false)
    const [loadingMembers, setLoadingMembers] = useState(false)
    const [members, setMembers] = useState([])
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchUserData, setSearchUserData] = useState([])
    const [searchModalVisible, setSearchModalVisible] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => {
        getTeams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditTeamModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditTeamModalVisible, inputRef])

    const getTeams = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SALES.GET_EXTERNAL_SALES_COMPANY_LIST)
            .then((res) => {
                setTeams(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditTeamModal = (record = {}) => {
        setSelectedTeamRecord(record)
        setAddEditTeamModalVisible(true)
        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({
                    companyName: record.companyName,
                    allowedIpAddresses: record.allowedIpAddresses,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditTeamModal = () => {
        setAddEditTeamModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const deleteTeam = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SALES.DELETE_EXTERNAL_SALES_COMPANY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTeams()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const teamsTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditTeamModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'companyName',
            key: 'companyName',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showMembersModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    {record.companyName}
                </Button>
            ),
        },
        {
            title: 'Allowed IP Address',
            dataIndex: 'allowedIpAddresses',
            key: 'allowedIpAddresses',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteTeam(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const addOrUpdateTeam = () => {
        form.validateFields(['companyName', 'allowedIpAddresses'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.SALES.ADD_EXTERNAL_SALES_COMPANY
                if (selectedTeamRecord.uid) {
                    url = GLOBAL.URL.SALES.UPDATE_EXTERNAL_SALES_COMPANY
                    values.uid = selectedTeamRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditTeamModal()
                        getTeams()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showMembersModal = (record) => {
        setSelectedTeamRecord(record)
        setMembersModalVisible(true)
        getMembers(record.uid)
    }

    const hideMembersModal = () => {
        setSelectedTeamRecord({})
        setMembersModalVisible(false)
    }

    const getMembers = (uid) => {
        setLoadingMembers(true)

        RestApi.doGet(GLOBAL.URL.SALES.GET_EXTERNAL_SALES_COMPANY_MEMBERS + uid)
            .then((res) => {
                setMembers(res.data)
            })
            .finally(() => {
                setLoadingMembers(false)
            })
    }

    const enableDisableMember = (record) => {
        setLoadingMembers(true)

        let url = GLOBAL.URL.SALES.ENABLE_EXTERNAL_SALES_COMPANY_MEMBER
        if (record.enabled) {
            url = GLOBAL.URL.SALES.DISABLE_EXTERNAL_SALES_COMPANY_MEMBER
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(members)
                fullData.forEach((item) => {
                    if (item.uid === record.uid) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setMembers(fullData)
            })
            .finally(() => {
                setLoadingMembers(false)
            })
    }

    const deleteMember = (uid) => {
        setLoadingMembers(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.SALES.DELETE_EXTERNAL_SALES_COMPANY_MEMBER,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getMembers(selectedTeamRecord.uid)
            })
            .catch((error) => {
                setLoadingMembers(false)
            })
    }

    const membersTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableMember(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Link
                    href={'/userdetails/' + record.user.id}
                    onClick={(event) => {
                        event.preventDefault()
                        props.navigate('/userdetails/' + record.user.id)
                    }}
                    underline="none"
                >
                    <Button type="link" style={{ padding: 0 }}>
                        {record.user.firstName + ' ' + record.user.lastName}
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteMember(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const searchUser = (query) => {
        setSearchLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUserData(res.data)
            })
            .finally(() => {
                setSearchLoading(false)
            })
    }

    const showSearchUserModal = () => {
        setSearchModalVisible(true)
    }

    const hideSearchUserModal = () => {
        setSearchModalVisible(false)
    }

    const searchUserColumns = [
        {
            title: 'Add',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => addMember(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" size="small">
                        Add
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const addMember = (userId) => {
        setSearchLoading(true)

        var payload = {}
        payload.userId = userId
        payload.companyId = selectedTeamRecord.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.SALES.ADD_EXTERNAL_SALES_COMPANY_MEMBER,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideSearchUserModal()
                getMembers(selectedTeamRecord.uid)
            })
            .finally(() => {
                setSearchLoading(false)
            })
    }

    return (
        <div>
            <h2>External Sales Teams</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddEditTeamModal}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={teamsTableColumns}
                dataSource={teams}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Team'}
                open={addEditTeamModalVisible}
                onOk={addOrUpdateTeam}
                confirmLoading={loading}
                onCancel={hideAddEditTeamModal}
                okText={selectedTeamRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Team Name"
                        name="companyName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Allowed IP Address"
                        name="allowedIpAddresses"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <span style={{ fontSize: 12 }}>
                        {'**Allowed IP Addresses - comma separated'}
                    </span>
                </Form>
            </Modal>
            <Modal
                title={'Team Members'}
                open={membersModalVisible}
                onCancel={hideMembersModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedTeamRecord.companyName}</h3>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showSearchUserModal}
                    >
                        Add Member
                    </Button>
                </div>
                <Table
                    columns={membersTableColumns}
                    dataSource={members}
                    pagination={false}
                    size="small"
                    loading={loadingMembers}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Search User'}
                open={searchModalVisible}
                onCancel={hideSearchUserModal}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    autoFocus
                />
                <Table
                    columns={searchUserColumns}
                    dataSource={searchUserData}
                    pagination={false}
                    size="small"
                    loading={searchLoading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default ExternalSalesTeam
