import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    notification,
    Select,
    Radio,
    InputNumber,
    Popconfirm,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'

function FacultySalesSummary() {
    const { Option } = Select
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [faculties, setFaculties] = useState([])
    const [salesSummaryList, setSalesSummaryList] = useState([])
    const [fullDetailsModalVisible, setFullDetailsModalVisible] =
        useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [selectedFacultyId, setSelectedFacultyId] = useState(0)
    const [
        generateSalesReportModalVisible,
        setGenerateSalesReportModalVisible,
    ] = useState(false)
    const [salesSummaryVisible, setSalesSummaryVisible] = useState(false)
    const [couseSummaryVisible, setCourseSummaryVisible] = useState(false)
    const [courseSplitVisible, setCourseSplitVisible] = useState(false)
    const [courseSummaryData, setCourseSummaryData] = useState([])
    const [selectedCourseSummaryRecord, setSelectedCourseSummaryRecord] =
        useState({})
    const [
        courseSummaryFullDetailsModalVisible,
        setCourseSummaryFullDetailsModalVisible,
    ] = useState(false)
    const [courseSplitData, setCourseSplitData] = useState([])
    const [contentRecordingSplitVisible, setContentRecordingSplitVisible] =
        useState(false)
    const [contentRecordingSplitData, setContentRecordingSplitData] = useState(
        []
    )
    const [
        addUpdateContentRecordingModalVisible,
        setAddUpdateContentRecordingModalVisible,
    ] = useState(false)
    const [courses, setCourses] = useState([])
    const [selectedContentRecording, setSelectedContentRecording] = useState({})
    const [showMoreButtonVisible, setShowMoreButtonVisible] = useState(false)
    const [page, setPage] = useState(1)

    useEffect(() => {
        getFaculties()
        fetchSalesSummary(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFaculties = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY)
            .then((res) => {
                setFaculties(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const facultyOptions = () => {
        var options = []
        faculties.forEach((item) => {
            options.push(
                <Option value={item.id} key={item.id}>
                    {item.firstName + ' ' + item.lastName}
                </Option>
            )
        })

        return options
    }

    const fetchSalesSummary = (facultyId) => {
        setLoading(true)
        setSelectedFacultyId(facultyId)
        setSalesSummaryList([])

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_SALES_SUMMARY + facultyId + '&page=1'
        )
            .then((res) => {
                setSalesSummaryList(res.data)
                setSalesSummaryVisible(true)
                setCourseSummaryVisible(false)
                setCourseSplitVisible(false)
                setShowMoreButtonVisible(true)
                setPage(2)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetchMoreSalesSummary = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_SALES_SUMMARY +
                selectedFacultyId +
                '&page=' +
                page
        )
            .then((res) => {
                setSalesSummaryList((preState) => {
                    return [...preState, ...res.data]
                })
                setSalesSummaryVisible(true)
                setCourseSummaryVisible(false)
                setCourseSplitVisible(false)

                if (res.data.length === 0) {
                    setShowMoreButtonVisible(false)
                }

                setPage(page + 1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 100,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 100,
        },
        {
            title: 'Faculty',
            dataIndex: 'faculty',
            key: 'faculty',
            render: (text, record, index) => (
                <span>{record.faculty.firstName}</span>
            ),
        },
        {
            title: 'Total Commission',
            dataIndex: 'totalCommission',
            key: 'totalCommission',
            render: (text, record, index) => (
                <span>
                    {record.totalCommission}
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showfullDetailsModal(record)}
                    >
                        Full Details
                    </Button>
                </span>
            ),
        },
        {
            title: 'Sale Commission',
            dataIndex: 'saleCommission',
            key: 'saleCommission',
            render: (text, record, index) => (
                <span>
                    {record.saleCommission}
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showCourseSummary(record)}
                    >
                        Course Level Summary
                    </Button>
                </span>
            ),
        },
        {
            title: 'Content Commission',
            dataIndex: 'contentRecordedHrsPrice',
            key: 'contentRecordedHrsPrice',
            render: (text, record, index) => (
                <span>
                    {record.contentRecordedHrsPrice +
                        ' (' +
                        record.contentRecordedHrs +
                        ' hrs)'}
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showContentRecordingSplit(record)}
                    >
                        Show Split
                    </Button>
                </span>
            ),
        },
        {
            title: 'Report Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record, index) => (
                <span style={{ fontSize: 10 }}>
                    {'Created At: ' + record.createdAt}
                    <br />
                    {'Updated At: ' + record.updatedAt}
                </span>
            ),
        },
    ]

    const returnSalesTable = (record) => {
        return (
            <table
                style={{
                    border: '1px solid lightGray',
                    borderCollapse: 'collapse',
                    width: 'auto',
                }}
            >
                {returnSalesTableHeading()}
                <tbody>
                    {returnSalesTableRow('Total MRP', record.totalMrp)}
                    {returnSalesTableRow('Total Price', record.totalPrice)}
                    {returnSalesTableRow('Faculty MRP', record.facultyMrp)}
                    {returnSalesTableRow('Faculty Price', record.facultyPrice)}
                    {returnSalesTableRow('GST', record.gst)}
                    {returnSalesTableRow('Base Price', record.basePrice)}
                    {returnSalesTableRow(
                        'Sale Commission',
                        record.saleCommission
                    )}
                    {returnSalesTableRow(
                        'Content Recorded Price (' +
                            record.contentRecordedHrs +
                            ' hrs)',
                        record.contentRecordedHrsPrice
                    )}
                    {returnSalesTableRow(
                        'Total Commission',
                        record.totalCommission
                    )}
                </tbody>
            </table>
        )
    }

    const returnSalesTableHeading = () => {
        return (
            <thead>
                <tr>
                    <th
                        style={{
                            border: '1px solid lightGray',
                            borderCollapse: 'collapse',
                            padding: 5,
                        }}
                    >
                        Particular
                    </th>
                    <th
                        style={{
                            border: '1px solid lightGray',
                            borderCollapse: 'collapse',
                            padding: 5,
                            textAlign: 'right',
                        }}
                    >
                        Amount
                    </th>
                </tr>
            </thead>
        )
    }

    const returnSalesTableRow = (particular, amount) => {
        return (
            <tr>
                <td
                    style={{
                        border: '1px solid lightGray',
                        borderCollapse: 'collapse',
                        padding: 5,
                    }}
                >
                    {particular}
                </td>
                <td
                    style={{
                        border: '1px solid lightGray',
                        borderCollapse: 'collapse',
                        padding: 5,
                        textAlign: 'right',
                    }}
                >
                    {amount}
                </td>
            </tr>
        )
    }

    const showfullDetailsModal = (record) => {
        setSelectedRecord(record)
        setFullDetailsModalVisible(true)
    }

    const hidefullDetailsModal = () => {
        setFullDetailsModalVisible(false)
    }

    const showGenerateSalesReportModal = () => {
        setGenerateSalesReportModalVisible(true)
    }

    const hideGenerateSalesReportModal = () => {
        setGenerateSalesReportModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    }

    const monthOptions = () => {
        var options = []
        for (var i = 1; i <= 12; i++) {
            options.push(
                <Radio.Button value={i} key={i}>
                    {i}
                </Radio.Button>
            )
        }

        return options
    }

    const yearOptions = () => {
        var options = []
        var currentYear = new Date().getFullYear()
        while (currentYear >= 2020) {
            options.push(
                <Radio.Button value={currentYear} key={currentYear}>
                    {currentYear}
                </Radio.Button>
            )
            currentYear = currentYear - 1
        }

        return options
    }

    const generateSalesReport = () => {
        form.validateFields(['month', 'year'])
            .then((values) => {
                setLoading(true)

                values.facultyId = selectedFacultyId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.FACULTY.GENERATE_SALES_REPORT,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideGenerateSalesReportModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showCourseSummary = (record) => {
        setSelectedRecord(record)

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_SALE_COURSE_SUMMARY + record.uid)
            .then((res) => {
                setCourseSummaryData(res.data)

                setSalesSummaryVisible(false)
                setCourseSummaryVisible(true)
                setCourseSplitVisible(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideCourseSummary = () => {
        setSalesSummaryVisible(true)
        setCourseSummaryVisible(false)
        setCourseSplitVisible(false)
    }

    const courseSummaryTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
            render: (text, record, index) => (
                <span>
                    {record.commission}
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() =>
                            showfullDetailsCourseSummaryModal(record)
                        }
                    >
                        Full Details
                    </Button>
                </span>
            ),
        },
        {
            title: 'Sale Count',
            dataIndex: 'totalSaleCount',
            key: 'totalSaleCount',
        },
        {
            title: 'Commission %',
            dataIndex: 'commissionPercent',
            key: 'commissionPercent',
        },
        {
            title: 'Course Split',
            dataIndex: 'courseSplit',
            key: 'courseSplit',
            render: (text, record, index) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showCourseSplit(record)}
                    >
                        Show Course Split
                    </Button>
                </span>
            ),
        },
    ]

    const returnCoursesummaryCommissionFullDetailsTable = (record) => {
        return (
            <>
                <table
                    style={{
                        border: '1px solid lightGray',
                        borderCollapse: 'collapse',
                        width: 'auto',
                    }}
                >
                    {returnSalesTableHeading()}
                    <tbody>
                        {returnSalesTableRow('Total MRP', record.totalMrp)}
                        {returnSalesTableRow('Total Price', record.totalPrice)}
                        {returnSalesTableRow('Faculty MRP', record.facultyMrp)}
                        {returnSalesTableRow(
                            'Faculty Price',
                            record.facultyPrice
                        )}
                        {returnSalesTableRow('GST', record.gst)}
                        {returnSalesTableRow('Base Price', record.basePrice)}
                        {returnSalesTableRow(
                            'Commission %',
                            record.commissionPercent
                        )}
                        {returnSalesTableRow(
                            'Sale Commission',
                            record.commission
                        )}
                    </tbody>
                </table>
                <br />
                <table
                    style={{
                        border: '1px solid lightGray',
                        borderCollapse: 'collapse',
                        width: 'auto',
                    }}
                >
                    {returnSalesTableHeading()}
                    <tbody>
                        {returnSalesTableRow(
                            'Ind Modules + Extensions MRP',
                            record.indModulesFacultyMrp
                        )}
                        {returnSalesTableRow(
                            'Course MRP',
                            record.indModulesToFullCourseConvPrice
                        )}
                        {returnSalesTableRow(
                            'Ind Modules To Full Course Conversion Count',
                            record.indModulesToFullCourseConvCount
                        )}
                        {returnSalesTableRow(
                            'Full Subject Sale Count',
                            record.fullSubjectsSaleCount
                        )}
                        {returnSalesTableRow(
                            'Total Sale Count',
                            record.totalSaleCount
                        )}
                    </tbody>
                </table>
            </>
        )
    }

    const showfullDetailsCourseSummaryModal = (record) => {
        setSelectedCourseSummaryRecord(record)
        setCourseSummaryFullDetailsModalVisible(true)
    }

    const hidefullDetailsCourseSummaryModal = () => {
        setCourseSummaryFullDetailsModalVisible(false)
    }

    const showCourseSplit = (record) => {
        setSelectedCourseSummaryRecord(record)

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_SALE_COURSE_SPLIT + record.uid)
            .then((res) => {
                setCourseSplitData(res.data)

                setSalesSummaryVisible(false)
                setCourseSummaryVisible(false)
                setCourseSplitVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideCourseSplit = () => {
        setSalesSummaryVisible(false)
        setCourseSummaryVisible(true)
        setCourseSplitVisible(false)
    }

    const courseSplitTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Count',
            dataIndex: 'totalSaleCount',
            key: 'totalSaleCount',
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
        {
            title: 'Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
        },
        {
            title: 'MRP',
            dataIndex: 'totalMrp',
            key: 'totalMrp',
        },
        {
            title: 'Price',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
        },
        {
            title: 'Share',
            dataIndex: 'facultyShare',
            key: 'facultyShare',
        },
        {
            title: 'Faculty MRP',
            dataIndex: 'facultyMrp',
            key: 'facultyMrp',
        },
        {
            title: 'Faculty Price',
            dataIndex: 'facultyPrice',
            key: 'facultyPrice',
        },
    ]

    const showContentRecordingSplit = (record) => {
        setSelectedRecord(record)
        getContentRecordingSplit(record.uid)
    }

    const getContentRecordingSplit = (uid) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_CONTENT_RECORDING_SPLIT + uid)
            .then((res) => {
                setContentRecordingSplitData(res.data)
                setContentRecordingSplitVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideContentRecordingSplit = () => {
        setContentRecordingSplitVisible(false)
    }

    const contentRecordingTableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateContentRecordingModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
            width: 400,
        },
        {
            title: 'Hrs',
            dataIndex: 'contentHrs',
            key: 'contentHrs',
        },
        {
            title: 'Price / hr',
            dataIndex: 'pricePerHr',
            key: 'pricePerHr',
        },
        {
            title: 'Total Price',
            dataIndex: 'totalContentHrsPrice',
            key: 'totalContentHrsPrice',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteContentRecording(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddUpdateContentRecordingModal = (record = {}) => {
        setSelectedContentRecording(record)
        setAddUpdateContentRecordingModalVisible(true)

        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateContentRecordingModal = () => {
        setAddUpdateContentRecordingModalVisible(false)
    }

    const courseOptions = () => {
        var options = []
        courses.forEach((item) => {
            options.push(
                <Option value={item.id} key={item.id}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const addUpdateContentRecording = () => {
        form.validateFields(['courseId', 'contentHrs', 'pricePerHr'])
            .then((values) => {
                setLoading(true)

                values.saleSummaryUID = selectedRecord.uid

                var url = GLOBAL.URL.FACULTY.ADD_CONTENT_RECORDING_SPLIT
                if (selectedContentRecording.id) {
                    url = GLOBAL.URL.FACULTY.UPDATE_CONTENT_RECORDING_SPLIT
                    values.id = selectedContentRecording.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateContentRecordingModal()
                        getContentRecordingSplit(selectedRecord.uid)
                        fetchSalesSummary(selectedFacultyId)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteContentRecording = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.FACULTY.DELETE_CONTENT_RECORDING_SPLIT,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getContentRecordingSplit(selectedRecord.uid)
                fetchSalesSummary(selectedFacultyId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Faculty Sales</h2>
            <Select
                placeholder="Select Faculty"
                style={{ width: 500 }}
                showSearch
                filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                allowClear={true}
                onChange={(facultyId) => {
                    facultyId
                        ? fetchSalesSummary(facultyId)
                        : fetchSalesSummary(0)
                }}
            >
                {facultyOptions()}
            </Select>
            {salesSummaryVisible ? (
                <div style={{ marginTop: 10 }}>
                    <h3>Month Wise Sales Summary</h3>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => fetchSalesSummary(selectedFacultyId)}
                        loading={loading}
                    >
                        Refresh
                    </Button>
                    <Button
                        type="default"
                        size="small"
                        onClick={showGenerateSalesReportModal}
                        loading={loading}
                        style={{ marginLeft: 10 }}
                    >
                        Generate Sales Report
                    </Button>
                    <Table
                        columns={tableColumns}
                        dataSource={salesSummaryList}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="uid"
                        style={{ marginTop: 10 }}
                    />
                    {showMoreButtonVisible ? (
                        <Button
                            type="primary"
                            size="small"
                            onClick={fetchMoreSalesSummary}
                            style={{ marginTop: 10 }}
                        >
                            Show More
                        </Button>
                    ) : null}
                </div>
            ) : null}
            {couseSummaryVisible ? (
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        size="small"
                        onClick={hideCourseSummary}
                        loading={loading}
                    >
                        Close
                    </Button>
                    <h3>Course Contract Sales Summary</h3>
                    <Table
                        columns={courseSummaryTableColumns}
                        dataSource={courseSummaryData}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="uid"
                        style={{ marginTop: 10 }}
                    />
                </div>
            ) : null}
            {courseSplitVisible ? (
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        size="small"
                        onClick={hideCourseSplit}
                        loading={loading}
                    >
                        Close
                    </Button>
                    <h3>
                        {"Course Level Split for '" +
                            selectedCourseSummaryRecord.courseName +
                            "'"}
                    </h3>
                    <Table
                        columns={courseSplitTableColumns}
                        dataSource={courseSplitData}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="id"
                        style={{ marginTop: 10 }}
                    />
                </div>
            ) : null}
            <Modal
                title={
                    selectedRecord.startDate + ' to ' + selectedRecord.endDate
                }
                open={fullDetailsModalVisible}
                onCancel={hidefullDetailsModal}
                destroyOnClose={true}
                footer={null}
            >
                {returnSalesTable(selectedRecord)}
            </Modal>
            <Modal
                title={selectedCourseSummaryRecord.courseName}
                open={courseSummaryFullDetailsModalVisible}
                onCancel={hidefullDetailsCourseSummaryModal}
                destroyOnClose={true}
                footer={null}
            >
                {returnCoursesummaryCommissionFullDetailsTable(
                    selectedCourseSummaryRecord
                )}
            </Modal>
            <Modal
                title={'Generate Sales Report'}
                open={generateSalesReportModalVisible}
                onCancel={hideGenerateSalesReportModal}
                onOk={generateSalesReport}
                confirmLoading={loading}
                okText={'Generate'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Month"
                        name="month"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{monthOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Year"
                        name="year"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{yearOptions()}</Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Course Recording Split"
                open={contentRecordingSplitVisible}
                onCancel={hideContentRecordingSplit}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                <h4>
                    Period{': '}
                    {selectedRecord.startDate + ' to ' + selectedRecord.endDate}
                </h4>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddUpdateContentRecordingModal}
                    loading={loading}
                    style={{ marginTop: 10 }}
                >
                    Add
                </Button>
                <Table
                    columns={contentRecordingTableColumns}
                    dataSource={contentRecordingSplitData}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Content Recording'}
                open={addUpdateContentRecordingModalVisible}
                onCancel={hideAddUpdateContentRecordingModal}
                onOk={addUpdateContentRecording}
                confirmLoading={loading}
                okText={selectedContentRecording.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={900}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Content Hrs"
                        name="contentHrs"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} step={1.0} autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="Price per hr"
                        name="pricePerHr"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} step={1.0} />
                    </Form.Item>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Courses"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default FacultySalesSummary
