import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ILEmployeeTableRow from './ILEmployeeTableRow'

const ILEmployeeDayDataTable = ({
    rows,
    getEmployeeLoginData = () => {},
    activeDate,
    setViewingDate,
}) => {
    const { classes, cx } = useStyles()
    return (
        <>
            <TableContainer className={classes.tablePaper} component={Paper}>
                <Table className={classes.table} aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={cx(
                                    classes.tableHead,
                                    classes.dateHeading
                                )}
                                align="left"
                            >
                                Date
                            </TableCell>

                            <TableCell
                                className={classes.tableHead}
                                align="right"
                            >
                                Hours
                            </TableCell>
                            <TableCell
                                className={classes.tableHead}
                                align="right"
                            >
                                Login
                            </TableCell>
                            <TableCell
                                className={cx(
                                    classes.tableHead,
                                    classes.logOut
                                )}
                                align="right"
                            >
                                Logout
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, i) => (
                            <ILEmployeeTableRow
                                key={i}
                                row={row}
                                activeDate={activeDate}
                                setViewingDate={setViewingDate}
                                lastIndex={rows.length - 1 === i}
                                getEmployeeLoginData={getEmployeeLoginData}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ILEmployeeDayDataTable

const useStyles = makeStyles()((theme) => ({
    tableHead: {
        fontSize: '18px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
        },
    },
    tablePaper: {
        width: '100%',
        margin: '10px 0px',
        borderRadius: '6px',
        boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important',
    },
    dateHeading: {
        '&.MuiTableCell-head': {
            paddingLeft: '30px',
        },
    },
}))
