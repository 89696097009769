import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'

function GroupCourseReviews() {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditGroupModalVisible, setAddEditGroupModalVisible] =
        useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [coursesModalVisible, setCoursesModalVisible] = useState(false)
    const [groupCourses, setGroupCourses] = useState([])
    const [courses, setCourses] = useState([])
    const [addCourseModalVisible, setAddCourseModalVisible] = useState(false)
    const inputRef = useRef()
    useEffect(() => {
        getGroups()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditGroupModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditGroupModalVisible, inputRef])

    const getGroups = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.COURSE_REVIEW.GET_GROUPS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditGroupModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Group Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showCoursesModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    {record.name}
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteGroup(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const coursesTableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCourseFromGroup(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddEditGroupModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditGroupModalVisible(true)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({ name: record.name })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditGroupModal = () => {
        setAddEditGroupModalVisible(false)
    }

    const deleteGroup = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURSE_REVIEW.DELETE_GROUP, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getGroups()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateGroup = () => {
        form.validateFields(['name'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.COURSE_REVIEW.ADD_GROUP
                if (selectedRecord.id) {
                    url = GLOBAL.URL.COURSE_REVIEW.UPDATE_GROUP
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditGroupModal()
                        getGroups()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showCoursesModal = (record) => {
        setSelectedRecord(record)
        setCoursesModalVisible(true)
        getCoursesInGroup(record.id)
    }

    const hideCoursesModal = () => {
        setCoursesModalVisible(false)
    }

    const getCoursesInGroup = (groupId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.COURSE_REVIEW.GET_GROUP_COURSES + groupId)
            .then((res) => {
                setGroupCourses(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddCourseModal = () => {
        setAddCourseModalVisible(true)
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }
    }

    const hideAddCourseModal = () => {
        setAddCourseModalVisible(false)
    }

    const courseOptions = () => {
        var options = []
        courses.forEach((item) => {
            options.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const addCourseToGroup = () => {
        form.validateFields(['courseId'])
            .then((values) => {
                setLoading(true)

                values.groupId = selectedRecord.id
                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.COURSE_REVIEW.ADD_GROUP_COURSE,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddCourseModal()
                        getCoursesInGroup(selectedRecord.id)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteCourseFromGroup = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURSE_REVIEW.DELETE_GROUP_COURSE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCoursesInGroup(selectedRecord.id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Group Reviews</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddEditGroupModal}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="index"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Group'}
                open={addEditGroupModalVisible}
                onOk={addOrUpdateGroup}
                confirmLoading={loading}
                onCancel={hideAddEditGroupModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={700}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Group Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Configure Courses'}
                open={coursesModalVisible}
                onCancel={hideCoursesModal}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <h3>Group: {selectedRecord.name}</h3>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddCourseModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={coursesTableColumns}
                    dataSource={groupCourses}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Add Course'}
                open={addCourseModalVisible}
                onOk={addCourseToGroup}
                confirmLoading={loading}
                onCancel={hideAddCourseModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Courses"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default GroupCourseReviews
