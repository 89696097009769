import React from 'react'
import ILLoginModal from '../components/ILShareComponents/ILLoginModal'
import { useSelector } from 'react-redux'
import MainScreen from '../components/MainScreen'
import Login from '../components/Login'

const PageRoutes = () => {
    const isLogIn = useSelector((state) => state.isLoginScreen)

    return (
        <>
            {isLogIn ? <Login /> : <MainScreen />}
            <ILLoginModal />
        </>
    )
}

export default PageRoutes
