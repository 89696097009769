import {
    Input,
    Modal,
    message,
    Table,
    Button,
    Select,
    Popconfirm,
    Divider,
    Checkbox,
    DatePicker,
    Radio,
    InputNumber,
    notification,
    Form,
} from 'antd'
import {
    EditOutlined,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import dayjs from 'dayjs'

const dateFormat = 'DD-MMM-YY HH:mm'

var ENABLE_CONSTANT = 'enable'
var MODE_ACTIVE = 'ACTIVE'
var MODE_ARCHIVED = 'ARCHIVED'
var MODE_BULK = 'BULK'
var MODE_SEARCH = 'SEARCH'

function Coupons({ navigate }) {
    const { Search } = Input
    const { Option } = Select
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [couponData, setCouponData] = useState([])
    const [archivedCouponsData, setArchivedCouponsData] = useState([])
    const [archivedNextUrl, setArchivedNextUrl] = useState('')
    const [bulkCouponsData, setBulkCouponsData] = useState([])
    const [bulkNextUrl, setBulkNextUrl] = useState('')
    const [couponTypes, setCouponTypes] = useState([])
    const [editCouponRecord, setEditCouponRecord] = useState({})
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [mode, setMode] = useState(MODE_ACTIVE)
    const [searchData, setSearchData] = useState([])
    const [partners, setPartners] = useState([])
    const [partnerSelected, setPartnerSelected] = useState(false)
    const [netPercentSelected, setNetPercentSelected] = useState(0)
    const [netPriceSelected, setNetPriceSelected] = useState(0)
    const [purchaseDate, setPurchaseDate] = useState('')
    const [selectedPartnerId, setSelectedPartnerId] = useState(0)
    const [updateSalesMemberModalVisible, setUpdateSalesMemberModalVisible] =
        useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [salesUsers, setSalesUsers] = useState([])
    const [updateDateRangeModalVisible, setUpdateDateRangeModalVisible] =
        useState(false)
    const [loadingAddRemoveLimit, setLoadingAddRemoveLimit] = useState(false)

    const [offerCoupon, setOfferCoupon] = useState()
    const [offerCouponModalVisible, setOfferCouponModalVisible] =
        useState(false)
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        getOfferCoupon()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        reload()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPartnerId])

    useEffect(() => {
        setTimeout(() => {
            if (modalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [modalVisible, inputRef])

    const getOfferCoupon = () => {
        RestApi.doGet(GLOBAL.URL.GET_OFFER_COUPON).then((res) => {
            setOfferCoupon(res.data)
        })
    }

    const getCouponsData = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_ALL_COUPONS + '?partner=' + selectedPartnerId
        )
            .then((res) => {
                setCouponData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })

        if (partners.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_PARTNERS).then((res) => {
                setPartners(res.data)
            })
        }
    }

    const getArchivedCouponsData = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_ARCHIVED_COUPONS + '?partner=' + selectedPartnerId
        )
            .then((res) => {
                var response = JSON.parse(res.data)
                setArchivedCouponsData(response.data)
                setArchivedNextUrl(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextArchivedCoupons = () => {
        if (!archivedNextUrl) {
            return
        }

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + archivedNextUrl)
            .then((res) => {
                var response = JSON.parse(res.data)
                setArchivedCouponsData((preState) => {
                    return [...preState, ...response.data]
                })
                setArchivedNextUrl(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getBulkCouponsData = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_BULK_COUPONS + '?partner=' + selectedPartnerId
        )
            .then((res) => {
                var response = JSON.parse(res.data)
                setBulkCouponsData(response.data)
                setBulkNextUrl(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextBulkCoupons = () => {
        if (!bulkNextUrl) {
            return
        }

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + bulkNextUrl)
            .then((res) => {
                var response = JSON.parse(res.data)
                setBulkCouponsData((preState) => {
                    return [...preState, ...response.data]
                })
                setBulkNextUrl(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showModal = (record) => {
        setModalVisible(true)
        setPartnerSelected(false)

        setTimeout(() => {
            var partnerSelected = false
            var netPercentSelected = 0
            var netPriceSelected = 0
            var purchaseDateInObject = null
            var startDate = null
            var endDate = null

            if (record && record.couponName) {
                var formRecord = {}
                formRecord.couponName = record.couponName
                formRecord.type = record.type
                formRecord.discount = record.discount
                formRecord.limit = record.limit
                formRecord.oncePerUser = record.oncePerUser

                if (record.startDate) {
                    startDate = record.startDate
                    endDate = record.endDate

                    var range = [
                        dayjs(record.startDate, 'DD-MMM-YY HH:mm'),
                        dayjs(record.endDate, 'DD-MMM-YY HH:mm'),
                    ]

                    formRecord.dateRange = range
                }

                if (record.purchaseDate) {
                    purchaseDateInObject = record.purchaseDate
                    var purchaseDate = dayjs(
                        record.purchaseDate,
                        'DD-MMM-YY HH:mm'
                    )

                    formRecord.purchaseDate = purchaseDate
                }

                if (record.partner && record.partner.name) {
                    partnerSelected = true
                    netPercentSelected = record.netPercent
                    netPriceSelected = record.netPrice

                    formRecord.partnerId = record.partner.id
                    formRecord.partnerTransactionId =
                        record.partnerTransactionId
                    formRecord.netPercent = record.netPercent
                    formRecord.netPrice = record.netPrice
                }

                form.setFieldsValue(formRecord)
            } else {
                record = {}
            }

            setEditCouponRecord(record)
            setPartnerSelected(partnerSelected)
            setNetPercentSelected(netPercentSelected)
            setNetPriceSelected(netPriceSelected)
            setPurchaseDate(purchaseDateInObject)
            setStartDate(startDate)
            setEndDate(endDate)
        }, 100)

        if (couponTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COUPON_TYPES).then((res) => {
                setCouponTypes(res.data)
            })
        }
    }

    const onChangePurchaseDate = (value, dateString) => {
        setPurchaseDate(dateString)
    }

    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                if (values.type === 'percent' && values.discount > 100) {
                    message.error('Coupon value cannot be more than 100%')
                    return
                }

                setModalLoading(true)
                values['startDate'] = startDate
                values['endDate'] = endDate
                values['purchaseDate'] = purchaseDate

                var url = GLOBAL.URL.ADD_COUPON
                if (editRecord.id) {
                    url = GLOBAL.URL.UPDATE_COUPON
                    values['id'] = editRecord.id
                }
                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyCourseObject)
                    .then((response) => {
                        var couponsJsonArrayObject = []
                        couponsJsonArrayObject.push(response.data)
                        if (editRecord && mode === MODE_ACTIVE) {
                            getCouponsData()
                        } else if (editRecord && mode === MODE_BULK) {
                            getBulkCouponsData()
                        } else {
                            setCouponData((preState) => {
                                return [...preState, ...couponsJsonArrayObject]
                            })
                        }
                        cancelModal()
                        message.success(
                            editRecord
                                ? 'Coupon Edited Successfully'
                                : 'Coupon Added Successfully'
                        )
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch((error) => {
                //empty catch block
            })
    }

    const cancelModal = () => {
        setModalVisible(false)
        setEditCouponRecord({})

        form.resetFields()
    }

    const enableDisableCoupon = (id, action) => {
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        let url = ''
        if (action === ENABLE_CONSTANT) {
            url = GLOBAL.URL.ENABLE_COUPON
        } else {
            url = GLOBAL.URL.DISABLE_COUPON
        }

        RestApi.doPost(url, postBody).then((response) => {
            if (response.code === 200) {
                if (mode === MODE_ACTIVE) {
                    var fullData = clone(couponData)
                    fullData.forEach((data) => {
                        if (id === data.id) {
                            data.enabled =
                                action === ENABLE_CONSTANT ? true : false
                            return
                        }
                    })
                    setCouponData(fullData)
                } else if (mode === MODE_BULK) {
                    var localBulkData = clone(bulkCouponsData)
                    localBulkData.forEach((data) => {
                        if (id === data.id) {
                            data.enabled =
                                action === ENABLE_CONSTANT ? true : false
                            return
                        }
                    })
                    setBulkCouponsData(localBulkData)
                }

                message.destroy()
                message.success(response.data)
            }
        })
    }

    const deleteCoupon = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.DELETE_COUPON, postBody)
            .then((response) => {
                if (response.code === 200) {
                    if (mode === MODE_ACTIVE) {
                        setCouponData(couponData.filter((key) => key.id !== id))
                    } else if (mode === MODE_BULK) {
                        setBulkCouponsData(
                            bulkCouponsData.filter((key) => key.id !== id)
                        )
                    } else if (mode === MODE_ARCHIVED) {
                        setArchivedCouponsData(
                            archivedCouponsData.filter((key) => key.id !== id)
                        )
                    }

                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const archiveCoupon = (id, archive) => {
        setLoading(true)

        var url = GLOBAL.URL.ARCHIVE_COUPON
        if (!archive) {
            url = GLOBAL.URL.UNARCHIVE_COUPON
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(url, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                if (archive && mode === MODE_ACTIVE) {
                    getCouponsData()
                } else if (archive && mode === MODE_BULK) {
                    setBulkCouponsData(
                        bulkCouponsData.filter((key) => key.id !== id)
                    )
                    setLoading(false)
                } else {
                    setArchivedCouponsData(
                        archivedCouponsData.filter((key) => key.id !== id)
                    )
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const navigateToCourseMapping = (record) => {
        navigate('/couponcoursemapping/' + record.id, {
            state: { couponName: record.couponName },
        })
    }

    const navigateToUserMapping = (record) => {
        navigate('/couponusermapping/' + record.id, {
            state: { couponName: record.couponName, id: record.id },
        })
    }

    const onChangeSelection = (e) => {
        setMode(e.target.value)

        if (
            e.target.value === MODE_ARCHIVED &&
            archivedCouponsData.length === 0
        ) {
            getArchivedCouponsData()
        } else if (
            e.target.value === MODE_BULK &&
            bulkCouponsData.length === 0
        ) {
            getBulkCouponsData()
        }
    }

    const navigateToCreateBulkCoupons = () => {
        navigate('/createbulkcoupons')
    }

    const navigateToComboDiscounts = () => {
        navigate('/combo-discounts')
    }

    const reload = () => {
        if (mode === MODE_ACTIVE) {
            getCouponsData()
        } else if (mode === MODE_ARCHIVED) {
            getArchivedCouponsData()
        } else if (mode === MODE_BULK) {
            getBulkCouponsData()
        }
    }

    const searchCoupon = (filter) => {
        setLoading(true)
        setSearchData([])

        RestApi.doGet(GLOBAL.URL.SEARCH_COUPON + filter)
            .then((res) => {
                setSearchData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handlePartnerChange = (value) => {
        if (value) {
            const selectedPartner = partners.find(
                (partner) => partner.id === value
            )
            setNetPercentSelected(selectedPartner.netPercent)
            form.setFieldsValue({
                netPercent: selectedPartner.netPercent,
                partnerTransactionId: editCouponRecord.partnerTransactionId,
            })
        }

        setPartnerSelected(value ? true : false)
    }

    const handlePartnerFilterChange = (value) => {
        setSelectedPartnerId(value ? value : 0)
    }

    const showUpdateSalesMemberModal = (record) => {
        if (salesUsers.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
                setSalesUsers(res.data)
            })
        }

        setUpdateSalesMemberModalVisible(true)
        setSelectedRecord(record)
    }

    const hideUpdateSalesMemberModal = () => {
        setUpdateSalesMemberModalVisible(false)
    }

    const linkSalesUser = () => {
        form.validateFields().then((values) => {
            setLoading(true)

            values.id = selectedRecord.id

            var formData = new FormData()
            formData.append('payload', JSON.stringify(values))

            RestApi.doPost(GLOBAL.URL.UPDATE_SALES_USER_IN_COUPON, formData)
                .then((response) => {
                    notification['success']({
                        message: response.data,
                    })

                    hideUpdateSalesMemberModal()
                    getCouponsData()
                })
                .catch(() => {
                    setLoading(false)
                })
        })
    }

    const clearUserLink = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.CLEAR_SALES_USER_IN_COUPON, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCouponsData()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const showUpdateDateRangeModal = (record) => {
        setUpdateDateRangeModalVisible(true)
        setSelectedRecord(record)

        setTimeout(() => {
            var startDate = null
            var endDate = null

            if (record.startDate) {
                startDate = record.startDate
                endDate = record.endDate

                var range = [
                    dayjs(record.startDate, 'DD-MMM-YY HH:mm'),
                    dayjs(record.endDate, 'DD-MMM-YY HH:mm'),
                ]

                form.setFieldsValue({
                    dateRange: range,
                })
            }

            setStartDate(startDate)
            setEndDate(endDate)
        }, 100)
    }

    const hideUpdateDateRangeModal = () => {
        setUpdateDateRangeModalVisible(false)
    }

    const updateDateRange = () => {
        form.validateFields().then((values) => {
            if (!startDate || !endDate) {
                message.error('Invalid date range!')
                return
            }

            setModalLoading(true)

            var payload = {}
            payload.startDate = startDate
            payload.endDate = endDate
            payload.id = selectedRecord.id

            let formData = new FormData()
            formData.append('payload', JSON.stringify(payload))

            RestApi.doPost(GLOBAL.URL.UPDATE_COUPON_DATE_RANGE, formData)
                .then((response) => {
                    message.success(response.data)
                    hideUpdateDateRangeModal()
                    getCouponsData()
                })
                .finally(() => {
                    setModalLoading(false)
                })
        })
    }

    const increaseLimit = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.INCREASE_COUPON_LIMIT, postBody)
            .then((response) => {
                message.success(response.data)
                getCouponsData()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const decreaseLimit = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.DECREASE_COUPON_LIMIT, postBody)
            .then((response) => {
                message.success(response.data)
                getCouponsData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const populateRemoveLimit = (isPopulate) => {
        setLoadingAddRemoveLimit(true)

        var url = GLOBAL.URL.POPULATE_LIMIT_BY_SALES
        if (!isPopulate) {
            url = GLOBAL.URL.REMOVE_LIMIT_BY_SALES
        }

        RestApi.doPost(url, new FormData())
            .then((response) => {
                message.success(response.data)
                getCouponsData()
            })
            .finally(() => {
                setLoadingAddRemoveLimit(false)
            })
    }

    const columns = [
        {
            title: 'Edit',
            key: 'id',
            width: '50px',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    </Button>
                </span>
            ),
        },
        {
            title: 'Status',
            key: 'enabled',
            render: (text, record) => (
                <span>
                    {!record.archived ? (
                        !record.enabled ? (
                            <Button
                                style={{
                                    background: 'red',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    enableDisableCoupon(
                                        record.id,
                                        ENABLE_CONSTANT
                                    )
                                }}
                                size="small"
                            >
                                Disabled
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                onClick={() => {
                                    enableDisableCoupon(record.id, 'disable')
                                }}
                                size="small"
                            >
                                Enabled
                            </Button>
                        )
                    ) : null}
                    {record.deleted ? <span>Deleted</span> : null}
                </span>
            ),
        },
        {
            title: 'Coupon',
            dataIndex: 'coupon',
            key: 'coupon',
            render: (text, record) => (
                <span>
                    {record.couponName}
                    <br />
                    <span style={{ fontSize: 12 }}>
                        {record.discount} {record.type}
                    </span>
                </span>
            ),
        },
        {
            title: 'Revenue',
            dataIndex: 'Revenue',
            key: 'Revenue',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>#</span> {record.usage}
                    <br />
                    <FontAwesomeIcon
                        icon={faRupeeSign}
                        style={{ fontSize: 10 }}
                    />{' '}
                    {record.revenue}
                </span>
            ),
        },
        {
            title: 'Date Range',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateDateRangeModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    </Button>
                    <span style={{ marginLeft: 5 }}>{record.startDate}</span>
                    <br />
                    <span style={{ marginLeft: 18 }}>{record.endDate}</span>
                </span>
            ),
        },
        {
            title: 'Max uses #',
            dataIndex: 'limit',
            key: 'limit',
            render: (text, record) => (
                <div>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => increaseLimit(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            loading={loading}
                        >
                            {'↑'}
                        </Button>
                    </Popconfirm>
                    <span style={{ marginLeft: 5 }}>{record.limit}</span>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => decreaseLimit(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, marginLeft: 5 }}
                            loading={loading}
                        >
                            {'↓'}
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
        {
            title: 'Once Per User',
            dataIndex: 'oncePerUser',
            key: 'oncePerUser',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.oncePerUser ? 'Yes' : 'No'}
                </span>
            ),
        },
        {
            title: 'Partner',
            dataIndex: 'partner',
            key: 'partner',
            render: (text, record) => (
                <span>{record.partner.name ? record.partner.name : ''}</span>
            ),
        },
        {
            title: 'Sales Member',
            key: 'salesUser',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateSalesMemberModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    </Button>
                    {record.salesUser ? (
                        <span>
                            <span style={{ marginLeft: 5 }}>
                                {record.salesUser.firstName}
                            </span>
                            <br />
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => clearUserLink(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        fontSize: 11,
                                        marginLeft: 20,
                                    }}
                                >
                                    clear link
                                </Button>
                            </Popconfirm>
                        </span>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Updated By',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy}
                    <br></br>
                    <span style={{ fontSize: 10 }}>{record.updateAt}</span>
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'active',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            navigateToCourseMapping(record)
                        }}
                        style={{ padding: 0, fontSize: 11 }}
                    >
                        Courses
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        type="link"
                        onClick={() => {
                            navigateToUserMapping(record)
                        }}
                        style={{ padding: 0, fontSize: 11 }}
                    >
                        Users
                    </Button>
                    <br />
                    {!record.archived ? (
                        <Popconfirm
                            title="Are you sure want to archive ?"
                            onConfirm={() => {
                                archiveCoupon(record.id, true)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                onClick={() => {}}
                                style={{ padding: 0, fontSize: 11 }}
                            >
                                Archive
                            </Button>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Are you sure want to unarchive ?"
                            onConfirm={() => {
                                archiveCoupon(record.id, false)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                onClick={() => {}}
                                style={{ padding: 0, fontSize: 11 }}
                            >
                                Unarchive
                            </Button>
                        </Popconfirm>
                    )}
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Are you sure want to delete ?"
                        onConfirm={() => {
                            deleteCoupon(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            onClick={() => {}}
                            style={{ padding: 0, fontSize: 11 }}
                        >
                            Delete
                        </Button>
                    </Popconfirm>{' '}
                </span>
            ),
        },
    ]

    const getCouponTypeView = () => {
        var couponTypeView = []
        couponTypes.forEach((item) => {
            couponTypeView.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return couponTypeView
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getPartnerOptions = () => {
        var partnerOptions = []
        partners.forEach((item) => {
            partnerOptions.push(
                <Option value={item.id} key={item.id}>
                    {item.name}
                </Option>
            )
        })

        return partnerOptions
    }

    const getSalesUserOptions = () => {
        var options = []
        salesUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const showOfferCouponModal = () => {
        setOfferCouponModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                coupon: offerCoupon,
            })
        }, 100)
    }

    const hideOfferCouponModal = () => {
        setOfferCouponModalVisible(false)
    }

    const updateOfferCoupon = () => {
        form.validateFields().then((values) => {
            setLoading(true)

            let formData = new FormData()
            formData.append('payload', JSON.stringify(values))

            RestApi.doPost(GLOBAL.URL.UPDATE_OFFER_COUPON, formData)
                .then((response) => {
                    message.success(response.data)
                    getOfferCoupon()
                    hideOfferCouponModal()
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    return (
        <div>
            <h2>Coupons</h2>
            <div>
                <Button type="primary" onClick={showModal} size="small">
                    Create Coupon
                </Button>
                <Button
                    type="primary"
                    onClick={navigateToCreateBulkCoupons}
                    style={{ marginLeft: 10 }}
                    size="small"
                >
                    Create Bulk
                </Button>
                <Button
                    type="primary"
                    onClick={navigateToComboDiscounts}
                    style={{ marginLeft: 10 }}
                    size="small"
                >
                    Combo Discounts
                </Button>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => populateRemoveLimit(true)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        loading={loadingAddRemoveLimit}
                        type="default"
                        size="small"
                        style={{ marginLeft: 10 }}
                    >
                        Populate Limit
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => populateRemoveLimit(false)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        loading={loadingAddRemoveLimit}
                        type="default"
                        size="small"
                        style={{ marginLeft: 10 }}
                    >
                        Remove Limit
                    </Button>
                </Popconfirm>
            </div>

            <Radio.Group
                value={mode}
                onChange={onChangeSelection}
                style={{ marginTop: 10 }}
            >
                <Radio.Button value={MODE_ACTIVE}>Active</Radio.Button>
                <Radio.Button value={MODE_BULK}>Bulk</Radio.Button>
                <Radio.Button value={MODE_ARCHIVED}>Archived</Radio.Button>
                <Radio.Button value={MODE_SEARCH}>Search</Radio.Button>
            </Radio.Group>

            {mode !== MODE_SEARCH ? (
                <>
                    <Button type="link" onClick={reload}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                    <Select
                        placeholder="Filter by Partner"
                        onChange={(value) => {
                            handlePartnerFilterChange(value)
                        }}
                        showSearch
                        allowClear={true}
                        style={{ width: 200, marginLeft: 10 }}
                    >
                        {getPartnerOptions()}
                    </Select>
                </>
            ) : null}

            <span style={{ marginLeft: 20 }}>
                Offer Coupon -
                <strong style={{ marginLeft: 5 }}>
                    {offerCoupon ? offerCoupon : '<NA>'}
                </strong>
                <Button
                    type="link"
                    onClick={showOfferCouponModal}
                    style={{ padding: 0, marginLeft: 5 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 11 }} />
                </Button>
            </span>

            {mode === MODE_ACTIVE ? (
                <Table
                    columns={columns}
                    dataSource={couponData}
                    pagination={false}
                    size="small"
                    loading={loading}
                    style={{ marginTop: 10 }}
                    rowKey="id"
                />
            ) : null}

            {mode === MODE_ARCHIVED ? (
                <div>
                    <Table
                        columns={columns}
                        dataSource={archivedCouponsData}
                        pagination={false}
                        size="small"
                        loading={loading}
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                    {archivedNextUrl ? (
                        <Button
                            type="primary"
                            style={{ marginTop: 10 }}
                            onClick={getNextArchivedCoupons}
                        >
                            More
                        </Button>
                    ) : null}
                </div>
            ) : null}

            {mode === MODE_BULK ? (
                <div>
                    <Table
                        columns={columns}
                        dataSource={bulkCouponsData}
                        pagination={false}
                        size="small"
                        loading={loading}
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                    {bulkNextUrl ? (
                        <Button
                            type="primary"
                            style={{ marginTop: 10 }}
                            onClick={getNextBulkCoupons}
                        >
                            More
                        </Button>
                    ) : null}
                </div>
            ) : null}

            {mode === MODE_SEARCH ? (
                <div>
                    <Search
                        placeholder="Search Coupon"
                        onSearch={(value) => searchCoupon(value)}
                        style={{ width: 200, marginTop: 10 }}
                        autoFocus
                    />
                    <Table
                        columns={columns}
                        dataSource={searchData}
                        pagination={false}
                        size="small"
                        loading={loading}
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                </div>
            ) : null}

            <Modal
                title={editCouponRecord.id ? 'Update' : 'Add'}
                open={modalVisible}
                onOk={() => {
                    handleSubmit(editCouponRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={cancelModal}
                okText={editCouponRecord.id ? 'Update' : 'Add'}
                width={800}
                destroyOnClose={true}
            >
                {modalVisible ? (
                    <Form
                        form={form}
                        initialValues={{
                            oncePerUser: editCouponRecord.oncePerUser,
                            netPercent: netPercentSelected,
                            netPrice: netPriceSelected,
                        }}
                        layout="horizontal"
                        {...formItemLayout}
                    >
                        <Form.Item
                            label="Coupon Name"
                            style={{ marginBottom: 10 }}
                            name="couponName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Coupon Name',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Enter Coupon Name"
                                autoFocus
                                ref={inputRef}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Select Coupon Type"
                            style={{ marginBottom: 10 }}
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Coupon type',
                                },
                            ]}
                        >
                            <Select placeholder="Select Coupon type">
                                {getCouponTypeView()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Enter Coupon value"
                            style={{ marginBottom: 10 }}
                            name="discount"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Coupon value',
                                },
                            ]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                            label="Max uses #"
                            style={{ marginBottom: 10 }}
                            name="limit"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Once Per User?"
                            style={{ marginBottom: 10 }}
                            name="oncePerUser"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Date range"
                            style={{ marginBottom: 10 }}
                            name="dateRange"
                        >
                            <RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format={dateFormat}
                                placeholder={['Start Date', 'End Date']}
                                onChange={onChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Purchase Date"
                            style={{ marginBottom: 10 }}
                            name="purchaseDate"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                showTime
                                placeholder="Select Date"
                                format="DD-MMM-YY HH:mm"
                                onChange={onChangePurchaseDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Partner"
                            style={{ marginBottom: 10 }}
                            name="partnerId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select partner"
                                onChange={(value) => {
                                    handlePartnerChange(value)
                                }}
                                showSearch
                                allowClear={true}
                            >
                                {getPartnerOptions()}
                            </Select>
                        </Form.Item>
                        {partnerSelected ? (
                            <>
                                <Form.Item
                                    label="Partner Transaction #"
                                    style={{ marginBottom: 10 }}
                                    name="partnerTransactionId"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Partner Transaction ID" />
                                </Form.Item>
                                <Form.Item
                                    label="Net Percent (our share)"
                                    shouldUpdate={true}
                                    style={{ marginBottom: 10 }}
                                    name="netPercent"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} max={100} />
                                </Form.Item>
                                <Form.Item
                                    label="Net Price ₹ (our share)"
                                    shouldUpdate={true}
                                    style={{ marginBottom: 10 }}
                                    name="netPrice"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} precision={2} />
                                </Form.Item>
                            </>
                        ) : null}
                    </Form>
                ) : null}
                {partnerSelected ? (
                    <span>Note: Need either net percent or net price</span>
                ) : null}
            </Modal>

            <Modal
                title={'Link Sales User'}
                open={updateSalesMemberModalVisible}
                onCancel={hideUpdateSalesMemberModal}
                onOk={() => {
                    linkSalesUser()
                }}
                confirmLoading={loading}
                okText={'Assign'}
                destroyOnClose={true}
            >
                {selectedRecord.salesUser ? (
                    <div style={{ marginBottom: 10 }}>
                        Current assignee:{' '}
                        <strong>{selectedRecord.salesUser.firstName}</strong>
                    </div>
                ) : null}
                {updateSalesMemberModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Assign To"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                            name="salesUserId"
                        >
                            <Select
                                placeholder="Asignee"
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                            >
                                {getSalesUserOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>

            <Modal
                title={'Update Date Range'}
                open={updateDateRangeModalVisible}
                onCancel={hideUpdateDateRangeModal}
                onOk={() => {
                    updateDateRange()
                }}
                confirmLoading={modalLoading}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                <span>
                    {'Coupon Name: '}
                    <strong>{selectedRecord.couponName}</strong>
                </span>
                <div style={{ marginTop: 10 }}>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Date range"
                            style={{ marginBottom: 10 }}
                            name="dateRange"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format={dateFormat}
                                placeholder={['Start Date', 'End Date']}
                                onChange={onChange}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>

            <Modal
                title={'Update Offer Coupon'}
                open={offerCouponModalVisible}
                onCancel={hideOfferCouponModal}
                onOk={updateOfferCoupon}
                confirmLoading={loading}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                {offerCouponModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Coupon Name"
                            style={{ marginBottom: 10 }}
                            name="coupon"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input autoFocus onPressEnter={updateOfferCoupon} />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default Coupons
