import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, message } from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { RestApi } from '../../RestApi'
import { useDispatch, useSelector } from 'react-redux'
import { actionTypes } from '../../actions'
import { goBack } from '../../Util/ILUtil'

const BodyRow = ({ index, moveRow, ...restProps }) => {
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveRow(dragIndex, hoverIndex)
            item.index = hoverIndex
        },
    })
    const [, drag] = useDrag({
        type: 'card',
        item: () => {
            const id = index + 1
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })
    drag(drop(ref))
    const style = {
        ...restProps.style,
        cursor: 'move',
    }

    return (
        <tr
            ref={ref}
            data-handler-id={handlerId}
            style={style}
            {...restProps}
        />
    )
}

const DragSortingTable = ({ navigate, location }) => {
    const [state, setState] = useState({
        data: [],
        updateUrl: null,
        columns: [],
        payload: {},
        loading: false,
        isFavoritesUpdate: false,
    })
    const userFavoritemenus = useSelector((state) => state.userFavoriteMenus)
    const dispatch = useDispatch()

    const moveRow = (dragIndex, hoverIndex) => {
        const { data } = state
        const dragRow = data[dragIndex]

        setState({
            ...state,
            data: update(state.data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            }),
        })
    }

    const updateTable = () => {
        setState({
            ...state,
            loading: true,
        })

        if (state.isFavoritesUpdate) {
            dispatch({
                type: actionTypes.SET_USER_FAVORITE_MENU,
                payload: state.data,
            })
            setState({
                ...state,
                loading: false,
            })
            message.success('Favourites Updated')
            goBack(navigate)
        } else {
            const payload = {
                data: state.data,
                ...(state.payload || {}),
            }

            const formbody = new FormData()
            formbody.append('payload', JSON.stringify(payload))

            RestApi.doPost(state.updateUrl, formbody)
                .then((response) => {
                    message.success(response.data)
                    goBack(navigate)
                })
                .finally(() => {
                    setState({
                        ...state,
                        loading: false,
                    })
                })
        }
    }

    useEffect(() => {
        const { reorderData, updateUrl, columns, payload, isFavoritesUpdate } =
            (location && location.state) || {}

        const updatedData = isFavoritesUpdate
            ? userFavoritemenus
            : reorderData || []

        setState({
            data: updatedData,
            updateUrl: updateUrl || null,
            loading: false,
            columns: columns || [],
            payload: payload || {},
            isFavoritesUpdate: isFavoritesUpdate || false,
        })
        //eslint-disable-next-line
    }, [location])

    return (
        <div>
            <Button type="primary" onClick={updateTable}>
                Update
            </Button>
            <DndProvider backend={HTML5Backend}>
                <Table
                    columns={state.columns}
                    dataSource={state.data}
                    components={{
                        body: {
                            row: BodyRow,
                        },
                    }}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                    pagination={false}
                    loading={state.loading}
                />
            </DndProvider>
        </div>
    )
}

export default DragSortingTable
