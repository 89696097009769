import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import {
    Table,
    Button,
    Radio,
    Tag,
    Modal,
    Popconfirm,
    notification,
    Select,
    InputNumber,
    Input,
    Form,
} from 'antd'
import { BookOutlined, EditTwoTone } from '@ant-design/icons'
import TransactionConstants from '../../Transactions/TransactionConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExternalLinkAlt,
    faCheck,
    faTimes,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { getMessageSalesUserNumber } from '../../JS/IlUtil'
import { Utils } from '../../JS/Utils'
import Paper from '@mui/material/Paper'
import clone from 'clone'
import { Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const TRANSACTION_FILTER_ALL = 'all'
const TRANSACTION_FILTER_SUCCESS = 'success'
const TRANSACTION_FILTER_FAILURE = 'failure'
const TRANSACTION_FILTER_PENDING = 'pending'

const FILTER_TRANSACTIONS = 'Transactions'
const FILTER_BOOK_TRANSACTIONS = 'Book Transactions'
const FILTER_COURIERS = 'Couriers'

function UserTransactions(props) {
    const { Option } = Select
    const { TextArea } = Input

    const [transactionFilter, setTransactionFilter] = useState(
        TRANSACTION_FILTER_ALL
    )
    const [transactionsData, setTransactionsData] = useState([])
    const [successTransactions, setSuccessTransactions] = useState([])
    const [failureTransactions, setFailureTransactions] = useState([])
    const [pendingTransactions, setPendingTransactions] = useState([])
    const [transactions, setTransactions] = useState([])
    const [totalSuccessTransactionsMrp, setTotalSuccessTransactionsMrp] =
        useState(0)
    const [totalSuccessTransactionsPrice, setTotalSuccessTransactionsPrice] =
        useState(0)
    useState(0)
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(FILTER_TRANSACTIONS)

    const [bookTransactions, setBookTransactions] = useState([])
    const [transactionItemsLoading, setTransactionItemsLoading] =
        useState(false)
    const [transactionItemsModalVisible, setTransactionItemsModalVisible] =
        useState(false)
    const [transactionItems, setTransactionItems] = useState([])
    const [selectedTransactionId, setSelectedTransactionId] = useState()
    const [selectedBookTransactionRecord, setSelectedBookTransactionRecord] =
        useState({ user: {} })
    const [
        updateBookTransactiontatusModalVisible,
        setUpdateBookTransactionStatusModalVisible,
    ] = useState(false)
    const [courierStatusEnumList, setCourierStatusEnumList] = useState([])

    const [couriers, setCouriers] = useState([])
    const [selectedRecord, setSelectedRecord] = useState({})
    const [itemsModalVisible, setItemsModalVisible] = useState(false)
    const [items, setItems] = useState([])
    const [loadingItems, setLoadingItems] = useState(false)
    const [addressModalVisible, setAddressModalVisible] = useState(false)
    const [updateAddressVisible, setUpdateAddressVisible] = useState(false)
    const [
        updateCourierStatusModalVisible,
        setUpdateCourierStatusModalVisible,
    ] = useState(false)

    const [extensions, setExtensions] = useState([])
    const [loadingExtensions, setLoadingExtensions] = useState(false)
    const [extensionsModalVisible, setExtensionsModalVisible] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()

    useEffect(() => {
        getTransactions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTransactions = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_TRANSACTIONS + props.userId)
            .then((res) => {
                setTransactions(res.data)
                setTransactionsData(res.data)

                var successTransactions = []
                var failureTransactions = []
                var pendingTransactions = []
                var totalSuccessTransactionsMrp = 0
                var totalSuccessTransactionsPrice = 0

                res.data.forEach((transaction) => {
                    if (
                        transaction.status === 'success' ||
                        transaction.status === 'loan_approved'
                    ) {
                        successTransactions.push(transaction)
                        totalSuccessTransactionsMrp =
                            totalSuccessTransactionsMrp + transaction.mrpInt
                        totalSuccessTransactionsPrice =
                            totalSuccessTransactionsPrice +
                            transaction.priceDouble
                    } else if (
                        transaction.status === 'failure' ||
                        transaction.status === 'refunded' ||
                        transaction.status === 'loan_rejected'
                    ) {
                        failureTransactions.push(transaction)
                    } else if (
                        transaction.status === 'pending' ||
                        transaction.status === 'loan_processing'
                    ) {
                        pendingTransactions.push(transaction)
                    }
                })

                setSuccessTransactions(successTransactions)
                setFailureTransactions(failureTransactions)
                setPendingTransactions(pendingTransactions)
                setTotalSuccessTransactionsMrp(totalSuccessTransactionsMrp)
                setTotalSuccessTransactionsPrice(totalSuccessTransactionsPrice)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeTransactionFilter = (e) => {
        setTransactionFilter(e.target.value)

        if (e.target.value === TRANSACTION_FILTER_ALL) {
            setTransactions(transactionsData)
        } else {
            if (e.target.value === TRANSACTION_FILTER_SUCCESS) {
                setTransactions(successTransactions)
            } else if (e.target.value === TRANSACTION_FILTER_FAILURE) {
                setTransactions(failureTransactions)
            } else if (e.target.value === TRANSACTION_FILTER_PENDING) {
                setTransactions(pendingTransactions)
            }
        }
    }

    const onChangeFilter = (e) => {
        setFilter(e.target.value)

        if (e.target.value === FILTER_BOOK_TRANSACTIONS) {
            getBookTransactions()
        } else if (e.target.value === FILTER_COURIERS) {
            getCouriers()
        }
    }

    const getBookTransactions = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_BOOK_TRANSACTIONS + props.userId)
            .then((res) => {
                setBookTransactions(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCouriers = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_COURIERS + props.userId)
            .then((res) => {
                setCouriers(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const userTransactionsTableColumns = [
        {
            title: 'ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => (
                <span style={{ maxWidth: 150 }}>
                    <Link
                        href={
                            '/transactions/transactiondetails/' +
                            record.transactionId
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            navigate(
                                '/transactions/transactiondetails/' +
                                    record.transactionId
                            )
                        }}
                        underline="none"
                    >
                        <Button
                            type="link"
                            style={{
                                whiteSpace: 'pre-wrap',
                                maxWidth: 150,
                                padding: 0,
                            }}
                        >
                            {record.transactionId}
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <>
                    <Tag color={returnTransactionColor(record.status)}>
                        {record.status}
                    </Tag>
                    {record.status !== 'success' ? (
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() =>
                                sendTransactionFailureWhatsApp(record)
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                style={{ width: 15 }}
                            >
                                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                            </svg>
                        </Button>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'initiatedAt',
            key: 'initiatedAt',
        },
        {
            title: 'MRP | Pricce',
            dataIndex: 'mrp',
            key: 'mrp',
            render: (text, record) => (
                <span>
                    {record.mrp + ' | '}
                    <b>{record.price}</b>
                </span>
            ),
        },
        {
            title: 'Credits | Discount',
            dataIndex: 'credits',
            key: 'credits',
            render: (text, record) => (
                <span>
                    {record.credits} | {record.discount}{' '}
                    {record.couponName ? ' | ' + record.couponName : null}
                </span>
            ),
        },
        {
            title: 'Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => <ul>{getCourseNames(record.courses)}</ul>,
        },
    ]

    const getTransactionFailureWhatsAppCourseNames = (courses) => {
        var names = ''
        courses.forEach((item, index) => {
            names = names + (index + 1) + '. ' + item.courseName + '\n'
        })

        return names
    }

    const sendTransactionFailureWhatsApp = (record) => {
        var userData = props.userData

        var message =
            'Dear ' +
            userData.firstName +
            ',\n\n' +
            'Greetings from *1FIN by IndigoLearn!!*' +
            '\n\n' +
            'I tried reaching your 📞 but couldn’t connect.' +
            '\n\n' +
            'Thanks for your interest in' +
            '\n' +
            getTransactionFailureWhatsAppCourseNames(record.courses) +
            '\n' +
            '*Complete the purchase and get books delivered right at your doorstep.*' +
            '\n\n' +
            'Call/WhatsApp: ' +
            getMessageSalesUserNumber() +
            '\n\n' +
            'Start Learning Like Never Before!!'

        var url =
            'https://api.whatsapp.com/send?phone=' +
            userData.isd +
            userData.phone +
            '&text=' +
            encodeURIComponent(message)
        window.open(url, '_blank')
    }

    const getCourseNames = (record) => {
        var names = record.map((item) => (
            <li key={item.courseName}>
                {item.attempt + ' | ' + item.courseName}
            </li>
        ))

        return names
    }

    const returnTransactionColor = (status) => {
        var color = ''
        if (status === TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS) {
            color = GLOBAL.COLORAPP.EMARALD
        } else if (
            status === TransactionConstants.TRANSACTION_CONSTANTS.PENDING
        ) {
            color = GLOBAL.COLORAPP.SUNFLOWER
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }
        return color
    }

    const bookTransactionTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <>
                        {Utils.isUserAdmin() &&
                        record.statusKey === 'book_transaction_done' ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    showBookTransactionUpdateStatusModal(record)
                                }}
                                style={{ padding: 0, marginRight: 5 }}
                            >
                                <EditTwoTone style={{ fontSize: 12 }} />
                            </Button>
                        ) : null}
                        <Button type="link" style={{ padding: 0 }}>
                            {returnBookTransactionColor(
                                record.statusKey,
                                record.status.toUpperCase()
                            )}
                        </Button>
                    </>
                )
            },
        },
        {
            title: 'Transactions Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => (
                <span>
                    <Link
                        href={
                            '/transactions/transactiondetails/' +
                            record.transactionId
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            navigate(
                                '/transactions/transactiondetails/' +
                                    record.transactionId
                            )
                        }}
                        underline="none"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <span>
                                {record.transactionId}
                                <br />
                                <span style={{ fontSize: 12 }}>
                                    {record.createdAt}
                                </span>
                            </span>
                        </Button>
                    </Link>
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/transactions/transactiondetails/' +
                                    record.transactionId,
                                '_blank'
                            )
                        }}
                        underline="none"
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 10 }}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Books List',
            dataIndex: 'books',
            key: 'books',
            render: (text, record) => {
                return (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() =>
                            showTransactionsItemsModal(record.transactionId)
                        }
                        loading={transactionItemsLoading}
                    >
                        {'Books List'}
                    </Button>
                )
            },
        },
        {
            title: 'Student',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <Link
                    href={'/userdetails/' + record.user.id}
                    onClick={(event) => {
                        event.preventDefault()
                        navigate('/userdetails/' + record.user.id)
                    }}
                    underline="none"
                >
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12, textAlign: 'left' }}
                    >
                        {record.user.firstName + ' ' + record.user.lastName}
                        <br />
                        {record.user.isd + '-' + record.user.phone}
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Last Update',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.updatedAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <>
                    {Utils.isUserAdmin() ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteBookTransaction(record.transactionId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ]

    const showTransactionsItemsModal = (transactionId) => {
        setSelectedTransactionId(transactionId)
        getTransactionItems(transactionId)
    }

    const hideTransactionsItemsModal = () => {
        setTransactionItemsModalVisible(false)
    }

    const getTransactionItems = (transactionId) => {
        setTransactionItemsLoading(true)
        setTransactionItems([])

        RestApi.doGet(
            GLOBAL.URL.GET_USER_BOOK_TRANSACTION_ITEMS + transactionId
        )
            .then((res) => {
                setTransactionItems(res.data)
                setTransactionItemsModalVisible(true)
            })
            .finally(() => {
                setTransactionItemsLoading(false)
            })
    }

    const deleteTransactionItem = (bookName) => {
        setTransactionItemsLoading(true)

        var payload = {}
        payload.transactionId = selectedTransactionId
        payload.bookName = bookName

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.DELETE_BOOK_TRANSACTION_ITEM, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTransactionItems(selectedTransactionId)
            })
            .catch((error) => {
                setTransactionItemsLoading(false)
            })
    }

    const transactionItemsColumns = [
        {
            title: '#',
            dataIndex: 'num',
            key: 'num',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Book Name',
            dataIndex: 'bookName',
            key: 'bookName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteTransactionItem(record.bookName)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const returnBookTransactionColor = (statusKey, status) => {
        var color = ''
        if (statusKey === 'book_transaction_address_confirmation') {
            color = GLOBAL.COLORAPP.SUNFLOWER
        } else if (statusKey === 'book_transaction_open') {
            color = GLOBAL.COLORAPP.JAMAORANGE
        } else if (statusKey === 'book_transaction_shipping') {
            color = GLOBAL.COLORAPP.APPBLUE
        } else if (statusKey === 'book_transaction_done') {
            color = GLOBAL.COLORAPP.EMARALD
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }

        return (
            <Tag color={color}>
                <span style={{ fontSize: 10 }}>{status.toUpperCase()}</span>
            </Tag>
        )
    }

    const returnCourierStatusColor = (statusKey, status) => {
        var color = ''
        if (statusKey === 'courier_packing') {
            color = GLOBAL.COLORAPP.DARKBLUE
        } else if (statusKey === 'courier_pickup') {
            color = GLOBAL.COLORAPP.JAMAORANGE
        } else if (statusKey === 'courier_shipped') {
            color = GLOBAL.COLORAPP.MOONSHADOW
        } else if (statusKey === 'courier_delivered') {
            color = GLOBAL.COLORAPP.EMARALD
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }

        return (
            <Tag color={color}>
                <span style={{ fontSize: 10 }}>{status.toUpperCase()}</span>
            </Tag>
        )
    }

    const courierTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => showUpdateCourierStatusModal(record)}
                    >
                        {returnCourierStatusColor(
                            record.statusKey,
                            record.status.toUpperCase()
                        )}
                    </Button>
                )
            },
        },
        {
            title: 'ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => {
                return (
                    <span>
                        <span>
                            {record.transactionId + '-' + record.sequence}
                        </span>
                        <br />
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12 }}
                            onClick={() => showItemsModal(record)}
                        >
                            Items List
                        </Button>
                        <Button
                            style={{ marginLeft: 10, padding: 0 }}
                            type="link"
                            onClick={() => showAddressModal(record)}
                        >
                            <BookOutlined style={{ fontSize: 10 }} />
                        </Button>
                        {Utils.isUserAdmin() &&
                        record.statusKey === 'courier_packing' ? (
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteCourier(record.uid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{
                                        border: 'none',
                                        color: 'red',
                                        padding: 0,
                                        marginLeft: 10,
                                        fontSize: 10,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </span>
                )
            },
        },
        {
            title: 'Packing',
            dataIndex: 'packingLabel',
            key: 'packingLabel',
            width: 70,
            render: (text, record) => {
                return (
                    <span>
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12 }}
                        >
                            {record.itemsListPrinted ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                            <span style={{ marginLeft: 4 }}>Packing</span>
                        </Button>
                    </span>
                )
            },
        },
        {
            title: 'Type (gms)',
            dataIndex: 'packageType',
            key: 'packageType',
            width: 100,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.packageType}
                    <br />
                    {'(' + record.totalWeightInGrams + ')'}
                </span>
            ),
        },
        {
            title: 'Student',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <Link
                    href={'/userdetails/' + record.user.id}
                    onClick={(event) => {
                        event.preventDefault()
                        navigate('/userdetails/' + record.user.id)
                    }}
                    underline="none"
                >
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12, textAlign: 'left' }}
                    >
                        {record.user.firstName + ' ' + record.user.lastName}
                        <br />
                        {record.user.isd + '-' + record.user.phone}
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Shipping',
            dataIndex: 'courierLabelPrinted',
            key: 'courierLabelPrinted',
            width: 70,
            render: (text, record) => {
                return (
                    <span>
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12 }}
                        >
                            {record.courierLabelPrinted ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                            <span style={{ marginLeft: 4 }}>Shipping</span>
                        </Button>
                    </span>
                )
            },
        },
        {
            title: 'Partner',
            dataIndex: 'courierPartner',
            key: 'courierPartner',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.courierPartner}
                    <br />
                    {record.courierName}
                </span>
            ),
        },
        {
            title: 'AWB',
            dataIndex: 'awbCode',
            key: 'awbCode',
            render: (text, record) => (
                <>
                    {record.awbCode ? (
                        <span>
                            <a
                                href={
                                    'https://shiprocket.co/tracking/' +
                                    record.awbCode
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: 12 }}
                            >
                                {record.awbCode}
                            </a>
                            <br />
                            {record.freightCharges +
                                ' / ' +
                                record.freightAppliedWeight}
                        </span>
                    ) : null}
                </>
            ),
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            render: (text, record) => (
                <>
                    {record.city ? (
                        <span style={{ fontSize: 12 }}>
                            {record.city}
                            <br />
                            {record.state}
                        </span>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.createdAt}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
    ]

    const showItemsModal = (record) => {
        setSelectedRecord(record)
        setItemsModalVisible(true)

        setLoadingItems(true)
        RestApi.doGet(GLOBAL.URL.GET_UESR_COURIER_ITEMS + record.uid)
            .then((res) => {
                setItems(res.data)
            })
            .finally(() => {
                setLoadingItems(false)
            })
    }

    const hideItemsModal = () => {
        setItemsModalVisible(false)
    }

    const itemsTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    {record.book.name}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {'(' + record.book.code + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Inventory',
            dataIndex: 'inventoryTotal',
            key: 'inventoryTotal',
            render: (text, record) => (
                <span>
                    <span
                        style={{
                            background:
                                record.book.inventoryTotal -
                                    record.book.inventorySold <=
                                0
                                    ? 'red'
                                    : 'none',
                        }}
                    >
                        {record.book.inventoryTotal -
                            record.book.inventorySold +
                            ' / ' +
                            record.book.inventoryTotal}
                    </span>
                </span>
            ),
        },
        {
            title: 'Weight (gms)',
            dataIndex: 'weightInGrams',
            key: 'weightInGrams',
            render: (text, record) => <span>{record.book.weightInGrams}</span>,
        },
    ]

    const showAddressModal = (record) => {
        setSelectedRecord(record)
        setAddressModalVisible(true)
    }

    const hideAddressModal = () => {
        setAddressModalVisible(false)
    }

    const deleteCourier = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURIER.DELETE_COURIER, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCouriers()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const deleteBookTransaction = (transactionId) => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.DELETE_BOOK_TRANSACTION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getBookTransactions()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showBookTransactionUpdateStatusModal = (record) => {
        setSelectedBookTransactionRecord(record)
        setUpdateBookTransactionStatusModalVisible(true)
    }

    const hideBookTransactionUpdateStatusModal = () => {
        setSelectedBookTransactionRecord({ user: {} })
        setUpdateBookTransactionStatusModalVisible(false)
    }

    const updateBookTransactionStatusToAddressConfirmation = (
        transactionId
    ) => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.BOOKS
                .UPDATE_BOOK_TRANSACTION_STATUS_TO_ADDRESS_CONFIRMATION,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideBookTransactionUpdateStatusModal()
                getBookTransactions()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showUpdateCourierStatusModal = (record) => {
        if (!Utils.isUserAdmin()) {
            return
        }

        if (courierStatusEnumList.length === 0) {
            RestApi.doGet(GLOBAL.URL.COURIER.GET_STATUS_ENUM_LIST).then(
                (res) => {
                    setCourierStatusEnumList(res.data)
                }
            )
        }

        setSelectedRecord(record)
        setUpdateCourierStatusModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                status: record.statusKey,
            })
        }, 100)
    }

    const hideUpdateCourierStatusModal = () => {
        setSelectedRecord({})
        setUpdateCourierStatusModalVisible(false)
    }

    const courierStatusOptions = () => {
        var options = []
        courierStatusEnumList.forEach((record) => {
            options.push(
                <Option value={record.keyName} key={record.keyName}>
                    {record.value}
                </Option>
            )
        })

        return options
    }

    const updateCourierStatus = () => {
        form.validateFields(['status'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.COURIER.UPDATE_COURIER_STATUS,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateCourierStatusModal()
                        getCouriers()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formUpdateAddressItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showUpdateAddress = () => {
        setUpdateAddressVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                firstName: selectedRecord.firstName,
                lastName: selectedRecord.lastName,
                address: selectedRecord.address,
                city: selectedRecord.city,
                pincode: selectedRecord.pincode,
                phone: selectedRecord.phone,
            })
        }, 100)
    }

    const hideUpdateAddress = () => {
        setUpdateAddressVisible(false)
    }

    const updateAddress = () => {
        form.validateFields([
            'firstName',
            'lastName',
            'address',
            'city',
            'pincode',
            'phone',
        ])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.COURIER.UPDATE_COURIER_ADDRESS,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        RestApi.doGet(
                            GLOBAL.URL.COURIER.GET_COURIER + selectedRecord.uid
                        ).then((res) => {
                            var fullData = clone(couriers)
                            fullData.forEach((item, index) => {
                                if (item.uid === selectedRecord.uid) {
                                    fullData[index] = res.data
                                    return
                                }
                            })

                            setCouriers(fullData)
                            setSelectedRecord(res.data)
                        })

                        hideUpdateAddress()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showExtensionOptions = () => {
        setLoadingExtensions(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_EXTENSION_OPTIONS + props.userId)
            .then((res) => {
                setExtensions(res.data)
                setExtensionsModalVisible(true)
            })
            .finally(() => {
                setLoadingExtensions(false)
            })
    }

    const hideExtensionOptions = () => {
        setExtensionsModalVisible(false)
    }

    const extensionsTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'From',
            dataIndex: 'fromAttempt',
            key: 'fromAttempt',
        },
        {
            title: 'To',
            dataIndex: 'toAttempt',
            key: 'toAttempt',
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
        },
    ]

    return (
        <div>
            <div>
                <Radio.Group value={filter} onChange={onChangeFilter}>
                    <Radio.Button
                        value={FILTER_TRANSACTIONS}
                        key={FILTER_TRANSACTIONS}
                    >
                        {FILTER_TRANSACTIONS}
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_BOOK_TRANSACTIONS}
                        key={FILTER_BOOK_TRANSACTIONS}
                    >
                        {FILTER_BOOK_TRANSACTIONS}
                    </Radio.Button>
                    <Radio.Button value={FILTER_COURIERS} key={FILTER_COURIERS}>
                        {FILTER_COURIERS}
                    </Radio.Button>
                </Radio.Group>
                <Button
                    style={{ marginLeft: 10 }}
                    type="default"
                    loading={loadingExtensions}
                    onClick={showExtensionOptions}
                >
                    Extension Options
                </Button>
            </div>
            {filter === FILTER_TRANSACTIONS ? (
                <div style={{ marginTop: 15 }}>
                    <Radio.Group
                        value={transactionFilter}
                        onChange={onChangeTransactionFilter}
                    >
                        <Radio.Button
                            value={TRANSACTION_FILTER_ALL}
                            key={TRANSACTION_FILTER_ALL}
                        >
                            All
                        </Radio.Button>
                        <Radio.Button
                            value={TRANSACTION_FILTER_SUCCESS}
                            key={TRANSACTION_FILTER_SUCCESS}
                        >
                            Success
                        </Radio.Button>
                        <Radio.Button
                            value={TRANSACTION_FILTER_FAILURE}
                            key={TRANSACTION_FILTER_FAILURE}
                        >
                            Failure
                        </Radio.Button>
                        <Radio.Button
                            value={TRANSACTION_FILTER_PENDING}
                            key={TRANSACTION_FILTER_PENDING}
                        >
                            Pending
                        </Radio.Button>
                    </Radio.Group>
                    <span style={{ marginLeft: 10 }}>
                        Total Success Value:{' '}
                        <strong>
                            {totalSuccessTransactionsMrp +
                                ' | ' +
                                Math.round(totalSuccessTransactionsPrice)}
                        </strong>
                    </span>
                    <Table
                        loading={loading}
                        columns={userTransactionsTableColumns}
                        dataSource={transactions}
                        pagination={false}
                        style={{ marginTop: 10 }}
                        rowKey={'transactionId'}
                        size="small"
                    />
                </div>
            ) : filter === FILTER_BOOK_TRANSACTIONS ? (
                <>
                    <Table
                        columns={bookTransactionTableColumns}
                        dataSource={bookTransactions}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="transactionId"
                        style={{ marginTop: 10 }}
                    />
                    <Modal
                        title={'Books List'}
                        open={transactionItemsModalVisible}
                        onCancel={hideTransactionsItemsModal}
                        destroyOnClose={true}
                        width={800}
                        footer={null}
                    >
                        <Table
                            columns={transactionItemsColumns}
                            dataSource={transactionItems}
                            pagination={false}
                            size="small"
                            loading={transactionItemsLoading}
                            rowKey="uid"
                            style={{ marginTop: 10 }}
                        />
                    </Modal>
                </>
            ) : filter === FILTER_COURIERS ? (
                <>
                    <Table
                        columns={courierTableColumns}
                        dataSource={couriers}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="uid"
                        style={{ marginTop: 10 }}
                    />
                    <Modal
                        title={
                            'Items List: ' +
                            selectedRecord.transactionId +
                            '-' +
                            selectedRecord.sequence
                        }
                        open={itemsModalVisible}
                        onCancel={hideItemsModal}
                        destroyOnClose={true}
                        width={800}
                        footer={null}
                    >
                        <h4>
                            {selectedRecord.user
                                ? selectedRecord.user.firstName +
                                  ' ' +
                                  selectedRecord.user.lastName
                                : null}
                        </h4>
                        <Table
                            columns={itemsTableColumns}
                            dataSource={items}
                            pagination={false}
                            size="small"
                            loading={loadingItems}
                            rowKey="uid"
                            style={{ marginTop: 10 }}
                        />
                    </Modal>
                    <Modal
                        title={
                            'Address: ' +
                            selectedRecord.transactionId +
                            '-' +
                            selectedRecord.sequence
                        }
                        open={addressModalVisible}
                        onCancel={hideAddressModal}
                        destroyOnClose={true}
                        width={800}
                        footer={null}
                    >
                        <span>
                            <b>
                                {selectedRecord.firstName +
                                    ' ' +
                                    selectedRecord.lastName}
                            </b>
                        </span>
                        <br />
                        <span style={{ whiteSpace: 'pre-wrap' }}>
                            {selectedRecord.address}
                        </span>
                        <br />
                        <span>{selectedRecord.city}</span>
                        <br />
                        <span>
                            {selectedRecord.state +
                                ' - ' +
                                selectedRecord.pincode}
                        </span>
                        <br />
                        <span>{selectedRecord.phone}</span>
                        {Utils.isCourierAddressUpdate() ? (
                            <div style={{ marginTop: 10 }}>
                                {!updateAddressVisible ? (
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={showUpdateAddress}
                                    >
                                        Update Address
                                    </Button>
                                ) : (
                                    <Paper
                                        elevation={2}
                                        style={{ padding: 10, marginTop: 10 }}
                                    >
                                        <h3>Update Address</h3>
                                        <Form
                                            form={form}
                                            layout="horizontal"
                                            {...formUpdateAddressItemLayout}
                                        >
                                            <Form.Item
                                                label="First Name"
                                                style={{ marginBottom: 0 }}
                                                name="firstName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required!',
                                                    },
                                                ]}
                                            >
                                                <Input autoFocus />
                                            </Form.Item>
                                            <Form.Item
                                                label="Last Name"
                                                style={{ marginBottom: 0 }}
                                                name="lastName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Address"
                                                style={{ marginBottom: 0 }}
                                                name="address"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required!',
                                                    },
                                                ]}
                                            >
                                                <TextArea rows={3} />
                                            </Form.Item>
                                            <Form.Item
                                                label="City"
                                                style={{ marginBottom: 0 }}
                                                name="city"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required!',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                label="Pincode"
                                                style={{ marginBottom: 0 }}
                                                name="pincode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required!',
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    max={999999}
                                                    style={{ width: 140 }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Phone"
                                                style={{ marginBottom: 0 }}
                                                name="phone"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required!',
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={1}
                                                    max={9999999999}
                                                    style={{ width: 140 }}
                                                />
                                            </Form.Item>
                                        </Form>
                                        <div
                                            style={{
                                                marginTop: 10,
                                                marginLeft: 90,
                                            }}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={updateAddress}
                                                loading={loading}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                type="default"
                                                style={{ marginLeft: 10 }}
                                                onClick={hideUpdateAddress}
                                                loading={loading}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Paper>
                                )}
                            </div>
                        ) : null}
                    </Modal>
                </>
            ) : null}
            <Modal
                title={'Update Book Transaction Satus'}
                open={updateBookTransactiontatusModalVisible}
                onCancel={hideBookTransactionUpdateStatusModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                {updateBookTransactiontatusModalVisible ? (
                    <>
                        <h4>{selectedBookTransactionRecord.transactionId}</h4>
                        <h4>
                            {selectedBookTransactionRecord.user.firstName +
                                ' ' +
                                selectedBookTransactionRecord.user.lastName}
                        </h4>
                        <h4>
                            {selectedBookTransactionRecord.user.isd +
                                '-' +
                                selectedBookTransactionRecord.user.phone}
                        </h4>
                        <div style={{ marginTop: 10 }}>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() =>
                                    updateBookTransactionStatusToAddressConfirmation(
                                        selectedBookTransactionRecord.transactionId
                                    )
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" loading={loading}>
                                    Change to Address Confirmation
                                </Button>
                            </Popconfirm>
                        </div>
                    </>
                ) : null}
            </Modal>
            <Modal
                title={'Update Courier Status'}
                open={updateCourierStatusModalVisible}
                onOk={updateCourierStatus}
                confirmLoading={loading}
                onCancel={hideUpdateCourierStatusModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <h4>
                    {selectedRecord.transactionId +
                        '-' +
                        selectedRecord.sequence}
                </h4>
                <h4>
                    {selectedRecord.firstName + ' ' + selectedRecord.lastName}
                </h4>
                <Form form={form}>
                    <Form.Item
                        label="Status"
                        style={{ marginBottom: 0 }}
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select placeholder="Select Status" showSearch>
                            {courierStatusOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Course Extension Options'}
                open={extensionsModalVisible}
                onCancel={hideExtensionOptions}
                destroyOnClose={true}
                footer={null}
                width={800}
            >
                <Table
                    loading={loadingExtensions}
                    columns={extensionsTableColumns}
                    dataSource={extensions}
                    pagination={false}
                    rowKey={'courseName'}
                    size="small"
                />
            </Modal>
        </div>
    )
}

export default UserTransactions
