import { actionTypes } from '../actions'

export const isSideDrawerCollapsed = (state = false, action) => {
    switch (action.type) {
        case actionTypes.SET_IS_SIDE_DRAWER_COLLAPSED:
            const isCollapsed = action.isCollapsed
            return isCollapsed
        default:
            return state
    }
}
