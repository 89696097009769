import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Select, DatePicker, Radio, Form } from 'antd'
import StatsConstants from '../Stats/StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

function CourseCompletionByAttempt() {
    const [form] = Form.useForm()
    const { Option } = Select
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [transactionTypes, setTransactionTypes] = useState([])
    const [purchaseStartDate, setPurchaseStartDate] = useState()
    const [purchaseEndDate, setPurchaseEndDate] = useState()
    const [report, setReport] = useState({})
    const [reportFetched, setReportFetched] = useState(false)
    const [barChartMapData, setBarChartMapData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [barChartOptions, setBarChartOptions] = useState({})
    const [dateOfReport, setDateOfReport] = useState()

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
            setCourseNames(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            var attemptsList = []
            res.data.forEach((item) => {
                attemptsList.push(item.attempt)
            })

            setAttempts(attemptsList)
        })

        RestApi.doGet(GLOBAL.URL.GET_TRANSACTION_TYPES).then((res) => {
            setTransactionTypes(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!report.attempt) {
            return
        }

        if (dateOfReport) {
            drawDateOfReportChart()
        } else {
            drawChart()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getAttemptOptions = () => {
        var attemptOptions = []
        attempts.forEach((item) => {
            attemptOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return attemptOptions
    }

    const getCourseOptions = () => {
        var courseOptions = []
        courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return courseOptions
    }

    const onDateChange = (value, dateString) => {
        setPurchaseStartDate(dateString[0])
        setPurchaseEndDate(dateString[1])
    }

    const getTransactionTypeOptions = () => {
        var transactionTypeOptions = []
        transactionTypeOptions.push(
            <Radio.Button value={''} key={'both'}>
                {'BOTH'}
            </Radio.Button>
        )
        transactionTypes.forEach((item) => {
            transactionTypeOptions.push(
                <Radio.Button value={item} key={item}>
                    {item.toUpperCase()}
                </Radio.Button>
            )
        })

        return transactionTypeOptions
    }

    const getReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                if (purchaseStartDate) {
                    values.purchaseStartDate = purchaseStartDate
                    values.purchaseEndDate = purchaseEndDate
                }

                var url =
                    GLOBAL.URL.ANALYTICS
                        .GET_COURSE_COMPLETION_PERCENT_BY_ATTEMPT
                if (dateOfReport) {
                    values.dateOfReport = dateOfReport
                    url =
                        GLOBAL.URL.ANALYTICS
                            .GET_COURSE_COMPLETION_PERCENT_BY_ATTEMPT_ON_DATE
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        setReport(response.data)
                        setReportFetched(true)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const scrollToChart = () => {
        const barChartElement = document.getElementById('bar-chart')
        if (barChartElement) {
            barChartElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }

    const drawChart = () => {
        var labels = []
        var data = []
        report.rows.forEach((row) => {
            labels.push(row.label)
            data.push(row.countInteger)
        })

        var chartDataSet = {}
        chartDataSet.data = data
        chartDataSet.label = 'Completion %'
        chartDataSet.backgroundColor = '#4bc0c0'
        chartDataSet.borderColor = '#4bc0c0'
        chartDataSet.fill = true

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(chartDataSet)

        var options = StatsConstants.CHART_OPTIONS
        options.plugins.title.text = 'Completion % by month'
        // options.scales.yAxes[0].ticks.max = 100

        setBarChartMapData(mapData)
        setBarChartOptions(options)
        scrollToChart()
    }

    const drawDateOfReportChart = () => {
        var labels = []
        var data = []
        var dataPercent = []
        report.rows.forEach((row) => {
            labels.push(row.label)
            data.push(row.countInteger)
            dataPercent.push(row.countPercent)
        })

        var dataSet = {}
        dataSet.type = 'bar'
        dataSet.data = data
        dataSet.label = 'Students Count'
        dataSet.backgroundColor = '#4bc0c0'
        dataSet.borderColor = '#4bc0c0'
        dataSet.fill = true
        dataSet.yAxisID = 'y-1'
        dataSet.datalabels = { display: true }

        var dataPercentSet = {}
        dataPercentSet.type = 'line'
        dataPercentSet.data = dataPercent
        dataPercentSet.label = '%'
        dataPercentSet.borderColor = '#396ab1'
        dataPercentSet.fill = false
        dataPercentSet.yAxisID = 'y-2'
        dataSet.datalabels = { display: true }
        dataPercentSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(dataPercentSet)
        mapData.datasets.push(dataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'Completion % split'
        // options.scales.yAxes[0].ticks.max = 100

        setBarChartMapData(mapData)
        setBarChartOptions(options)
        scrollToChart()
    }

    const onChangeReportDate = (value, dateString) => {
        setDateOfReport(dateString)
    }

    return (
        <div>
            <h2>Course Completion</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Attempt"
                    name="attempt"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select placeholder="Select Attempt" showSearch>
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Course"
                    name="courseName"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select placeholder="Select Course" showSearch>
                        {getCourseOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Purchase Date"
                    name="purchaseDate"
                    rules={[
                        {
                            required: false,
                            message: 'Required!',
                        },
                    ]}
                >
                    <RangePicker
                        format="DD-MMM-YYYY"
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Type"
                    name="transactionType"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group>{getTransactionTypeOptions()}</Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Report On Date"
                    name="dateOfReport"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <DatePicker
                        placeholder="Select Date"
                        onChange={onChangeReportDate}
                        format="DD-MMM-YYYY"
                    />
                </Form.Item>
            </Form>
            <div style={{ marginTop: 10, marginLeft: 161 }}>
                <Button type="primary" onClick={getReport} loading={loading}>
                    Get Report
                </Button>
            </div>

            <div style={{ marginTop: 20 }}>
                {reportFetched ? (
                    <h4>Total Students: {report.totalStudents}</h4>
                ) : null}
                <Bar
                    id="bar-chart"
                    data={barChartMapData}
                    width={800}
                    height={400}
                    options={barChartOptions}
                />
            </div>
        </div>
    )
}

export default CourseCompletionByAttempt
