import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    notification,
    InputNumber,
    Popconfirm,
    Input,
    TimePicker,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'

function StudyPlannerSessionConfig() {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const inputRef = useRef()

    useEffect(() => {
        getConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [addEditModalVisible, inputRef])

    const getConfig = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.STUDY_PLANNER.GET_SESSION_CONFIG)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.name) {
            setStartTime(record.startTime)
            setEndTime(record.endTime)

            var startTimeMoment = dayjs(record.startTime, 'HH:mm')
            var endtTimeMoment = dayjs(record.endTime, 'HH:mm')

            setTimeout(() => {
                form.setFieldsValue({
                    sessionNumber: record.sessionNumber,
                    name: record.name,
                    startTime: startTimeMoment,
                    endTime: endtTimeMoment,
                })
            }, 100)
        } else {
            form.resetFields()
            setStartTime('')
            setEndTime('')
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
        setStartTime('')
        setEndTime('')
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Session Number',
            dataIndex: 'sessionNumber',
            key: 'sessionNumber',
            width: 150,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
            key: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
            key: 'endTime',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}{' '}
                    <span style={{ fontSize: 10 }}>
                        {' (' + record.updatedAt + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteConfig(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateConfig = () => {
        form.validateFields()
            .then((values) => {
                if (!startTime) {
                    notification['error']({
                        message: 'Session start time is needed!',
                    })
                    return
                }

                if (!endTime) {
                    notification['error']({
                        message: 'Session end time is needed!',
                    })
                    return
                }

                setLoading(true)

                var url = GLOBAL.URL.STUDY_PLANNER.ADD_SESSION_CONFIG
                if (selectedRecord.id) {
                    url = GLOBAL.URL.STUDY_PLANNER.UPDATE_SESSION_CONFIG
                    values.id = selectedRecord.id
                }

                values.startTime = startTime
                values.endTime = endTime

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getConfig()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteConfig = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.STUDY_PLANNER.DELETE_SESSION_CONFIG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getConfig()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onChangeStartTime = (time, timeString) => {
        setStartTime(timeString)
    }

    const onChangeEndTime = (time, timeString) => {
        setEndTime(timeString)
    }

    return (
        <div>
            <h2>Study Planner Session Config</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="sessionNumber"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure'}
                open={addEditModalVisible}
                onOk={addOrUpdateConfig}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Session Number"
                        name="sessionNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Start Time"
                        name="startTime"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <TimePicker
                            placeholder="Select Time"
                            format="HH:mm"
                            onChange={onChangeStartTime}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Time"
                        name="endTime"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <TimePicker
                            placeholder="Select Time"
                            format="HH:mm"
                            onChange={onChangeEndTime}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default StudyPlannerSessionConfig
