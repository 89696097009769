import {
    Input,
    Button,
    Popconfirm,
    Form,
    message,
    Radio,
    Upload,
    InputNumber,
    DatePicker,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'

var AWARD_TO_ALL_USERS = 'All Users'
var AWARD_FILE_UPLOAD = 'File Upload'

const AwardiCash = () => {
    const [loading, setLoading] = useState(false)
    const [awardType, setAwardType] = useState('')
    const [expiry, setExpiry] = useState('')
    const [fileList, setFileList] = useState([])
    const [form] = Form.useForm()

    const onChangeAwardType = (event) => {
        setAwardType(event.target.value)
    }

    const awardiCash = () => {
        form.validateFields()
            .then((values) => {
                let body = new FormData()

                var url = ''
                if (awardType === AWARD_TO_ALL_USERS) {
                    url = GLOBAL.URL.AWARD_ICASH_TO_ALL_USERS
                } else if (awardType === AWARD_FILE_UPLOAD) {
                    if (fileList.length === 0) {
                        message.error('Please attach the file!')
                        return
                    }

                    url = GLOBAL.URL.AWARD_ICASH_BY_EXCEL_UPLOAD
                    body.append('file', fileList[0])
                }

                setLoading(true)

                values['expiry'] = expiry

                body.append('payload', JSON.stringify(values))

                RestApi.doPost(url, body)
                    .then((response) => {
                        message.success(response.data)
                        form.resetFields()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const { Dragger } = Upload

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 7 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            setFileList((prev) => {
                const index = prev.indexOf(file)
                const newFileList = prev.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    return (
        <div>
            <h2>Award iCash</h2>
            <Radio.Group onChange={onChangeAwardType} style={{ marginTop: 10 }}>
                <Radio.Button value={AWARD_TO_ALL_USERS}>
                    {AWARD_TO_ALL_USERS}
                </Radio.Button>
                <Radio.Button value={AWARD_FILE_UPLOAD}>
                    {AWARD_FILE_UPLOAD}
                </Radio.Button>
            </Radio.Group>
            {awardType ? (
                <div style={{ width: '50%', marginTop: 10 }}>
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="iCash to award"
                            style={{ marginBottom: 10, lineHeight: 0 }}
                            name="credits"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter iCash!',
                                },
                            ]}
                        >
                            <InputNumber min={1} autoFocus />
                        </Form.Item>
                        <Form.Item
                            label="Expiry"
                            style={{ marginBottom: 10, lineHeight: 0 }}
                            name="expiry"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please slecet expiry!',
                                },
                            ]}
                        >
                            <DatePicker
                                showTime
                                placeholder="Set expiry"
                                format="DD-MMM-YY HH:mm"
                                onChange={(value, dateString) => {
                                    setExpiry(dateString)
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="iCash Award Description"
                            style={{ marginBottom: 10, lineHeight: 0 }}
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter description!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        {awardType === AWARD_FILE_UPLOAD ? (
                            <Form.Item
                                label="Notification Title"
                                style={{ marginBottom: 0, lineHeight: 0 }}
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter title!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        ) : null}
                    </Form>
                    {awardType === AWARD_FILE_UPLOAD ? (
                        <div>
                            <Dragger {...uploadProps} style={{ marginTop: 10 }}>
                                <Button>
                                    <UploadOutlined /> Click or Drag files to
                                    this area to add attachment
                                </Button>
                            </Dragger>
                            <ul>
                                <li>Have only 1 sheet in the excel file</li>
                                <li>
                                    Mandatory column name:{' '}
                                    <strong>Email</strong>
                                    <br />
                                    (Do not have formulae in this column)
                                </li>
                            </ul>
                        </div>
                    ) : null}
                    <div style={{ marginTop: 10 }}>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => {
                                awardiCash()
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" loading={loading}>
                                Award iCash - {awardType}
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default AwardiCash
