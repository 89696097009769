import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'

function BlogCategory(props) {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [blogMappings, setBlogMappings] = useState([])
    const [blogMappingModalVisible, setBlogMappingModalVisible] =
        useState(false)
    const [addBlogMappingModalVisible, setAddBlogMappingModalVisible] =
        useState(false)
    const [blogs, setBlogs] = useState([])
    const inputRef = useRef()

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [addEditModalVisible, inputRef])

    const getCategories = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_BLOG_CATEGORIES)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.categoryId) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Category Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Blogs List',
            dataIndex: 'blogs',
            key: 'blogs',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showBlogMappingModal(record)}
                >
                    View Blogs
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCategory(record.categoryId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const mappingTableColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                    }}
                    onClick={() => enableDisableMapping(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Blog Title',
            dataIndex: 'blogTitle',
            key: 'blogTitle',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteBlogMapping(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateCategory = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.BLOG.ADD_BLOG_CATEGORY
                if (selectedRecord.categoryId) {
                    url = GLOBAL.URL.BLOG.UPDATE_BLOG_CATEGORY
                    values.categoryId = selectedRecord.categoryId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getCategories()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteCategory = (categoryId) => {
        setLoading(true)

        var payload = {}
        payload.categoryId = categoryId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_BLOG_CATEGORY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCategories()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.BLOG.ENABLE_BLOG_CATEGORY
        if (record.enabled) {
            url = GLOBAL.URL.BLOG.DISABLE_BLOG_CATEGORY
        }

        var formData = new FormData()
        formData.append(
            'payload',
            JSON.stringify({ categoryId: record.categoryId })
        )

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(data)
                fullData.forEach((item) => {
                    if (item.categoryId === record.categoryId) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Category Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: data,
                updateUrl: GLOBAL.URL.BLOG.REORDER_BLOG_CATEGORY,
                columns: columns,
            },
        })
    }

    const showBlogMappingModal = (record) => {
        setBlogMappings([])
        setSelectedRecord(record)
        setBlogMappingModalVisible(true)
        getBlogMappings(record.categoryId)
    }

    const hideBlogMappingModal = () => {
        setBlogMappingModalVisible(false)
    }

    const enableDisableMapping = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.BLOG.ENABLE_BLOG_IN_CATEGORY
        if (record.enabled) {
            url = GLOBAL.URL.BLOG.DISABLE_BLOG_IN_CATEGORY
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(blogMappings)
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setBlogMappings(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddBlogMappingModal = () => {
        setAddBlogMappingModalVisible(true)

        if (blogs.length === 0) {
            setLoading(true)

            RestApi.doGet(GLOBAL.URL.BLOG.GET_PAGES_LIST)
                .then((res) => {
                    setBlogs(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const hideAddBlogMappingModal = () => {
        setAddBlogMappingModalVisible(false)
    }

    const getBlogMappings = (categoryId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_BLOGS_IN_CATEGORY + categoryId)
            .then((res) => {
                setBlogMappings(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const addBlogMapping = () => {
        form.validateFields(['blogId'])
            .then((values) => {
                setLoading(true)

                values.categoryId = selectedRecord.categoryId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BLOG.ADD_BLOG_TO_CATEGORY, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddBlogMappingModal()
                        getBlogMappings(selectedRecord.categoryId)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const getBlogOptions = () => {
        var options = []
        blogs.forEach((blog) => {
            options.push(
                <Option value={blog.blogId} key={blog.title}>
                    {blog.title}
                </Option>
            )
        })

        return options
    }

    const deleteBlogMapping = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_BLOG_FROM_CATEGORY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getBlogMappings(selectedRecord.categoryId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Blog Categories</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={reorder}
                    style={{ marginLeft: 10 }}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="name"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Category'}
                open={addEditModalVisible}
                onOk={addOrUpdateCategory}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.categoryId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Blogs In ' + selectedRecord.name}
                open={blogMappingModalVisible}
                onOk={addOrUpdateCategory}
                confirmLoading={loading}
                onCancel={hideBlogMappingModal}
                footer={null}
                destroyOnClose={true}
                width={1000}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddBlogMappingModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={mappingTableColumns}
                    dataSource={blogMappings}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="blogTitle"
                    style={{ marginTop: 10 }}
                    scroll={{ y: 400 }}
                />
            </Modal>
            <Modal
                title={'Add Blog To Category'}
                open={addBlogMappingModalVisible}
                onOk={addBlogMapping}
                confirmLoading={loading}
                onCancel={hideAddBlogMappingModal}
                okText={'Add'}
                destroyOnClose={true}
                width={900}
            >
                {addBlogMappingModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Select Blog"
                            name="blogId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Blog"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                            >
                                {getBlogOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default BlogCategory
