import React from 'react'
import { Layout, message } from 'antd'
import Paper from '@mui/material/Paper'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { GoogleLogin } from '@react-oauth/google'
import GLOBAL from '../Globals'
import { RestApi } from '../RestApi'
import store from '../store/store'
import { actionTypes } from '../actions'

class Login extends React.Component {
    state = {
        collapsed: false,
    }
    componentDidMount() {
        var token = localStorage.getItem('token') //TODO UserInfoCall
        if (token) {
            GLOBAL.AUTH_TOKEN = token
            RestApi.doGet(GLOBAL.URL.GET_USER_INFO).then((res) => {
                GLOBAL.USER_DATA = res.data
            })
            this.setNavigate()
        } else {
        }
    }

    googleSignIn = (token) => {
        let postBody = new FormData()
        postBody.append('idToken', token)

        RestApi.doPost(GLOBAL.URL.ADMIN_GOOGLE_LOGIN, postBody)
            .then((res) => {
                if (res.code === 200) {
                    // temporary token storage
                    GLOBAL.AUTH_TOKEN = res.data
                    localStorage.setItem('token', res.data)
                    RestApi.doGet(GLOBAL.URL.GET_USER_INFO).then((res) => {
                        GLOBAL.USER_DATA = res.data
                    })
                    // navigation
                    const { isLoginModal } = store.getState(
                        (state) => state.isLoginModal
                    )

                    if (isLoginModal) {
                        store.dispatch({
                            type: actionTypes.SET_SHOW_LOG_IN_MODAL,
                            showLoginModal: false,
                        })
                        window.location.reload()
                    } else {
                        this.setNavigate()
                    }
                } else {
                    message.error('Signin error')
                }
            })
            .catch((error) => {
                message.error(error)
            })
    }

    setNavigate = () => {
        store.dispatch({
            type: actionTypes.SET_IS_LOG_IN,
            isLogIn: false,
        })
    }

    onSuccess = (credentialResponse) => {
        this.googleSignIn(credentialResponse.credential)
    }

    onError = (error) => {}

    render() {
        const isLogInModal = this.props.isLoginModal

        return (
            <Layout
                className="layout"
                style={{
                    minHeight: isLogInModal ? '0vh' : '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: isLogInModal ? '#FFF' : '#0F327s4',
                }}
            >
                <Paper
                    elevation={isLogInModal ? 0 : 2}
                    style={{ padding: isLogInModal ? 0 : 100 }}
                >
                    <GoogleOAuthProvider clientId="125088266382-oro8a9mvttoomqoqfr7at582scoio080.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={(credentialResponse) => {
                                this.onSuccess(credentialResponse)
                            }}
                            onError={this.onError}
                            theme="filled_blue"
                            text="signin_with"
                            shape="rectangular"
                        />
                    </GoogleOAuthProvider>
                </Paper>
            </Layout>
        )
    }
}

export default Login
