import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button, Tag, Radio, message, Switch } from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import StatsConstants from './StatsConstants'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)
const emptyBarData = {
    datasets: [{}],
}

class UserStats extends React.Component {
    state = {
        loading: false,
        data: [],
        labels: [],
        mapData: emptyBarData,
        options: {},
        dataDays: [],
        labelsDays: [],
        mapDataDays: emptyBarData,
        optionsDays: {},
        limit: 0,
        totalUsersCountLoading: true,
        totalUsersCount: 0,
        os: 'all',
        streamData: [],
        levelData: [],
        selectedCourse: '',
        selectedLevel: '',
        withPhoneOnly: 'no',
    }

    componentDidMount() {
        this.getRequiredData()
        this.getData()
    }
    getRequiredData() {
        this.setState({
            totalUsersCountLoading: true,
        })

        RestApi.doGet(GLOBAL.URL.GET_TOTAL_USERS_COUNT)
            .then((res) => {
                this.setState({
                    totalUsersCount: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    totalUsersCountLoading: false,
                })
            })
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            this.setState({
                streamData: streamData,
                levelData: streamData[0].levels,
            })
        })
    }

    getData() {
        this.setState({
            loading: true,
        })

        var url =
            GLOBAL.URL.GET_USERS_COUNT_BY_MONTH +
            '?os=' +
            this.state.os +
            '&stream=' +
            this.state.selectedCourse +
            '&level=' +
            this.state.selectedLevel +
            '&withPhoneOnly=' +
            this.state.withPhoneOnly

        if (this.state.limit > 0) {
            url = url + '&limit=' + this.state.limit
        }

        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var data = []
                var response = JSON.parse(res.data)
                response.forEach((row) => {
                    labels.push(row.label)
                    data.push(row.value)
                })
                this.setState(
                    {
                        labels: labels,
                        data: data,
                    },
                    () => this.drawChart()
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart() {
        var newSignupDataSet = {}
        newSignupDataSet.data = this.state.data
        newSignupDataSet.label = 'Users Count'
        newSignupDataSet.backgroundColor = '#4bc0c0'
        newSignupDataSet.borderColor = '#4bc0c0'
        newSignupDataSet.fill = true

        var mapData = {}
        mapData.labels = this.state.labels
        mapData.datasets = []
        mapData.datasets.push(newSignupDataSet)

        var options = {
            ...StatsConstants.CHART_OPTIONS,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
            },
        }
        options.plugins.title.text = 'New User Sign Up Month'
        options.onClick = (evt, item) => {
            this.getMonthSplit(item)
        }
        this.setState({
            mapData: mapData,
            options: options,
        })
    }

    onChange = (value) => {
        this.setState({
            limit: value,
        })
    }

    onClick = () => {
        this.getData()
    }

    getMonthSplit = (item) => {
        if (item.length === 0) {
            return
        }

        message.loading('Fetching data...', 0)
        var url =
            GLOBAL.URL.GET_USERS_COUNT_BY_MONTH +
            '/' +
            this.state.labels[item[0].index] +
            '?os=' +
            this.state.os +
            '&stream=' +
            this.state.selectedCourse +
            '&level=' +
            this.state.selectedLevel +
            '&withPhoneOnly=' +
            this.state.withPhoneOnly

        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var data = []
                var response = JSON.parse(res.data)
                response.forEach((row) => {
                    labels.push(row.label)
                    data.push(row.value)
                })
                this.setState({
                    labelsDays: labels,
                    dataDays: data,
                })
                this.drawDayChart(data, labels)
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
                message.destroy()
            })
    }

    drawDayChart(
        dataDays = this.state.dataDays,
        labelsDays = this.state.labelsDays
    ) {
        var newSignupDataSet = {}
        newSignupDataSet.data = dataDays
        newSignupDataSet.label = 'Users Count'
        newSignupDataSet.backgroundColor = '#36a2eb'
        newSignupDataSet.borderColor = '#36a2eb'
        newSignupDataSet.fill = true

        var mapData = {}
        mapData.labels = labelsDays
        mapData.datasets = []
        mapData.datasets.push(newSignupDataSet)

        var options = {
            ...StatsConstants.CHART_OPTIONS,
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                },
            },
        }
        options.plugins.title.text = 'New User Sign Up By Day'

        this.setState({
            mapDataDays: mapData,
            optionsDays: options,
        })

        document
            .getElementById('dayChart')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    onChangeOs = (e) => {
        this.setState(
            {
                os: e.target.value,
            },
            () => {
                this.getData()
            }
        )
    }

    selectCourse = (event) => {
        var levelData = []
        this.state.streamData.forEach((item) => {
            if (item.course === event.target.value) {
                levelData = item.levels
            }
        })

        this.setState(
            {
                levelData: levelData,
                selectedLevel: '',
                selectedCourse: event.target.value,
            },
            () => {
                this.getData()
            }
        )
    }
    selectLevel = (event) => {
        this.setState(
            {
                selectedLevel: event.target.value,
            },
            () => {
                this.getData()
            }
        )
    }

    getStreamOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={''} key={'all'}>
                {'All'}
            </Radio.Button>
        )
        this.state.streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    getLevelOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={''} key={'all'}>
                {'All'}
            </Radio.Button>
        )
        this.state.levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    onChangeWithPhoneNumbers = (enabled) => {
        this.setState({ withPhoneOnly: enabled ? 'yes' : 'no' }, () => {
            this.getData()
        })
    }

    render() {
        return (
            <div>
                {this.state.totalUsersCountLoading ? (
                    <LoadingOutlined />
                ) : (
                    <Tag
                        color="geekblue"
                        style={{ marginBottom: 10, display: 'table' }}
                    >
                        Total Users Count:{this.state.totalUsersCount}
                    </Tag>
                )}
                <span># Months: </span>
                <InputNumber
                    min={1}
                    defaultValue={6}
                    onChange={this.onChange}
                />
                <Button
                    type="primary"
                    onClick={this.onClick}
                    loading={this.state.loading}
                    style={{ marginLeft: 10, marginRight: 10 }}
                >
                    Fetch
                </Button>
                <span>
                    <span>With Phone Numbers?</span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onClick={(enabled) =>
                            this.onChangeWithPhoneNumbers(enabled)
                        }
                        disabled={this.state.loading}
                        style={{ marginLeft: 10 }}
                    />
                </span>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        onChange={this.onChangeOs}
                        value={this.state.os}
                    >
                        <Radio.Button value="all" key={'all'}>
                            {'All'}
                        </Radio.Button>
                        <Radio.Button value="android" key={'android'}>
                            {'Android'}
                        </Radio.Button>
                        <Radio.Button value="web" key={'web'}>
                            {'Web'}
                        </Radio.Button>
                        <Radio.Button value="ios" key={'ios'}>
                            {'iOS'}
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        onChange={this.selectCourse}
                        value={this.state.selectedCourse}
                    >
                        {this.getStreamOptions()}
                    </Radio.Group>
                </div>
                {this.state.selectedCourse ? (
                    <div style={{ marginTop: 10 }}>
                        <Radio.Group
                            value={this.state.selectedLevel}
                            onChange={this.selectLevel}
                        >
                            {this.getLevelOptions()}
                        </Radio.Group>
                    </div>
                ) : null}
                <Bar
                    data={this.state.mapData}
                    width={800}
                    height={400}
                    options={this.state.options}
                />
                <Bar
                    id="dayChart"
                    data={this.state.mapDataDays}
                    width={800}
                    height={400}
                    options={this.state.optionsDays}
                />
            </div>
        )
    }
}

export default UserStats
