import React, { useEffect, useRef, useState } from 'react'
import {
    message,
    Table,
    Button,
    Popconfirm,
    Modal,
    Input,
    Typography,
} from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Link, useParams } from 'react-router-dom'
import { EditTwoTone } from '@ant-design/icons'
import TinyMceEditor from '../Common/TinyMceEditor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const QuestionDetails = (props) => {
    const [questionData, setQuestionData] = useState({ mappedSubjects: [] })
    const [loading, setLoading] = useState(false)
    const [showTextAreaModal, setShowtextAreaModal] = useState(false)
    const [showtinyMceEditorModal, setShowtinyMceEditorModal] = useState(false)
    const [modalTitle, setModalTitle] = useState('')
    const [textInput, setTextInput] = useState('')
    const [htmltextContent, setHtmlTextContent] = useState('')
    const [questionDetails, setQuestionDetails] = useState({
        isQuestionClicked: false,
        isFeedBackClicked: false,
        isOptionClicked: false,
    })
    const [optionRecord, setOptionRecord] = useState({})
    const { TextArea } = Input
    const params = useParams()
    const inputRef = useRef(null)

    useEffect(() => {
        getTestsQuestionData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (showTextAreaModal && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [showTextAreaModal, inputRef])

    const getTestsQuestionData = () => {
        setLoading(true)
        const { questionId } = params
        RestApi.doGet(GLOBAL.URL.GET_QUESTION_DETAILS + questionId)
            .then((res) => {
                setQuestionData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteQuestion = () => {
        const { questionId } = params

        const payload = { id: questionId }
        const formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_QUESTION, formbody)
            .then((response) => {
                message.success(response.data)
                props.navigate('/mcq/search/')
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const openUpdateQuestionModal = () => {
        setModalTitle('Update Question')
        if (questionData.plainText) {
            setShowtextAreaModal(true)
            setTextInput(questionData.question)
        } else {
            setShowtinyMceEditorModal(true)
            setHtmlTextContent(questionData.question)
        }
        setQuestionDetails({ isQuestionClicked: true })
    }
    const openUpdateOptionModal = (text, record) => {
        setOptionRecord(record)
        setModalTitle('Update Option')
        setShowtextAreaModal(true)
        setTextInput(text)
        setQuestionDetails({ isOptionClicked: true })
    }

    const openUpdateFeedbackModal = () => {
        setModalTitle('Update Feedback')
        if (questionData.feedbackPlainText) {
            setShowtextAreaModal(true)
            setTextInput(questionData.feedback)
        } else {
            setShowtinyMceEditorModal(true)
            setHtmlTextContent(questionData.feedback)
        }
        setQuestionDetails({ isFeedBackClicked: true })
    }

    const closeTextAreaModal = () => {
        setShowtextAreaModal(false)
        setModalTitle('')
        setTextInput('')
        setQuestionDetails({
            isQuestionClicked: false,
            isFeedBackClicked: false,
            isOptionClicked: false,
        })
    }

    const closeEditorModal = () => {
        setShowtinyMceEditorModal(false)
        setHtmlTextContent('')
        setModalTitle('')
        setQuestionDetails({
            isQuestionClicked: false,
            isFeedBackClicked: false,
            isOptionClicked: false,
        })
    }

    const onTextChange = (e) => {
        setTextInput(e.target.value)
    }

    const handleEditorChange = (newValue, e) => {
        setHtmlTextContent(newValue)
    }

    const postUpdateText = () => {
        if (questionDetails.isQuestionClicked) {
            onUpdateQuestion()
        } else if (questionDetails.isFeedBackClicked) {
            onUpdateFeedback()
        } else {
            onUpdateOption()
        }
    }

    const onUpdateQuestion = () => {
        const { questionId } = params

        const payload = {
            questionId: questionId,
            question: questionData.plainText ? textInput : htmltextContent,
        }
        const formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_QUESTION_ONLY, formbody)
            .then((response) => {
                if (questionData.plainText) {
                    closeTextAreaModal()
                } else {
                    closeEditorModal()
                }
                getTestsQuestionData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onUpdateFeedback = () => {
        const { questionId } = params

        const payload = {
            questionId: questionId,
            feedback: questionData.feedbackPlainText
                ? textInput
                : htmltextContent,
        }
        const formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_FEEDBACK, formbody)
            .then((response) => {
                if (questionData.plainText) {
                    closeTextAreaModal()
                } else {
                    closeEditorModal()
                }

                getTestsQuestionData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onUpdateOption = () => {
        const payload = {
            optionId: optionRecord.id,
            option: textInput,
        }
        const formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_OPTION, formbody)
            .then((response) => {
                closeTextAreaModal()
                getTestsQuestionData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const handleCorrectOption = (record) => {
        const payload = {
            optionId: record.id,
        }
        const formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.MARK_OPTION_AS_CORRECT_ANSWER, formbody)
            .then((response) => {
                getTestsQuestionData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const optionsColums = [
        {
            title: 'Correct',
            dataIndex: 'correct',
            key: 'correct',
            width: 100,
            render: (text, record) => (
                <>
                    {!record.correct ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => handleCorrectOption(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            </Button>
                        </Popconfirm>
                    ) : (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'option',
            dataIndex: 'option',
            key: 'option',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        type="link"
                        onClick={() => openUpdateOptionModal(text, record)}
                        style={{ padding: 0, marginRight: 5 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <Typography> {text}</Typography>
                </div>
            ),
        },
    ]

    return (
        <div>
            <div>
                <Link
                    href={'/mcq/addquestion'}
                    onClick={(event) => {
                        event.preventDefault()
                        props.navigate('/mcq/search')
                    }}
                    underline="none"
                >
                    <Button size="small">Search MCQ</Button>
                </Link>
                <Link
                    href={'/mcq/addquestion'}
                    onClick={(event) => {
                        event.preventDefault()
                        props.navigate('/mcq/addquestion', {
                            state: {
                                testId: null,
                                question: questionData,
                            },
                        })
                    }}
                    underline="none"
                >
                    <Button
                        type="primary"
                        loading={loading}
                        size="small"
                        style={{ marginLeft: 20 }}
                    >
                        Update
                    </Button>
                </Link>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={deleteQuestion}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        style={{ marginLeft: 20 }}
                        loading={loading}
                        size="small"
                    >
                        Delete
                    </Button>
                </Popconfirm>
                <h2>Question Details</h2>
            </div>
            <div>
                <p>
                    Subject/Test:{' '}
                    <b>
                        {questionData.mappedSubjects.length !== 0
                            ? questionData.mappedSubjects[0]
                            : ''}
                    </b>
                </p>
                <p>
                    <span>
                        <Button
                            type="link"
                            onClick={openUpdateQuestionModal}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    </span>
                    <b> Question:</b>
                    <br />

                    {questionData.plainText ? (
                        <span>{questionData.question}</span>
                    ) : (
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: questionData.question,
                                }}
                            />
                        </div>
                    )}
                </p>
                <p>
                    <span>
                        <Button
                            type="link"
                            onClick={openUpdateFeedbackModal}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    </span>
                    <b> Feedback:</b>
                    <br />
                    {questionData.feedback ? (
                        <span>
                            {questionData.feedbackPlainText ? (
                                <span>{questionData.feedback}</span>
                            ) : (
                                <>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: questionData.feedback,
                                        }}
                                    />
                                </>
                            )}
                        </span>
                    ) : (
                        'No Feedback'
                    )}
                </p>
            </div>

            <Table
                columns={optionsColums}
                dataSource={questionData.options || []}
                pagination={false}
                rowKey="id"
                loading={loading}
            />

            <Modal
                title={modalTitle}
                open={showTextAreaModal}
                confirmLoading={loading}
                onCancel={closeTextAreaModal}
                onOk={postUpdateText}
                okText="Save"
            >
                <TextArea
                    rows={4}
                    onChange={onTextChange}
                    autoFocus
                    ref={inputRef}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    value={textInput}
                />
            </Modal>

            <Modal
                title={modalTitle}
                open={showtinyMceEditorModal}
                confirmLoading={loading}
                onCancel={closeEditorModal}
                onOk={postUpdateText}
                okText="Save"
                width={1000}
            >
                <TinyMceEditor
                    height={400}
                    handleEditorChange={handleEditorChange}
                    initialValue={htmltextContent}
                />
            </Modal>
        </div>
    )
}

export default QuestionDetails
