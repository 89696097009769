import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import {
    Radio,
    Table,
    Tag,
    Button,
    message,
    Modal,
    Select,
    Form,
    notification,
} from 'antd'
import { Utils } from '../../JS/Utils'
import { useNavigate } from 'react-router-dom'
import { EditTwoTone } from '@ant-design/icons'
import { Link } from '@mui/material'

const COURSE_FILTER_ACTIVE = 'active'
const COURSE_FILTER_FREE = 'free'
const COURSE_FILTER_EXPIRED = 'expired'

function UserCourses(props) {
    const { Option } = Select
    const [form] = Form.useForm()

    const userId = props.userId
    const [courseFilter, setCourseFilter] = useState(COURSE_FILTER_ACTIVE)
    const [loadingCourses, setLoadingCourses] = useState(false)
    const [courses, setCourses] = useState([])
    const [loadingCourseProgress, setLoadingCourseProgress] = useState(false)
    const [modulesProgressData, setModulesProgressData] = useState([])
    const [
        selectedCourseForModulesProgress,
        setSelectedCourseForModulesProgress,
    ] = useState({})
    const [modulesProgressModalVisible, setModulesProgressModalVisible] =
        useState(false)
    const [selectedCourseObj, setSelectedCourseObj] = useState({})
    const [updateCourseAttemptVisible, setUpdateCourseAttemptVisible] =
        useState(false)
    const [attempts, setAttempts] = useState([])
    const [addCourseModalVisibe, setAddCourseModalVisibe] = useState(false)
    const [selectedCourseForAccess, setSelectedCourseForAccess] = useState('')
    const [selectedAttemptForAcces, setSelectedAttemptForAcces] = useState('')
    const [courseNames, setCourseNames] = useState([])
    const [addCourseLoading, setAddCourseLoading] = useState(false)
    const [sectionsProgressData, setSectionsProgressData] = useState([])
    const [
        selectedModuleForSectionProgress,
        setSelectedModuleForSectionProgress,
    ] = useState({})
    const [sectionsProgressModalVisible, setSectionsProgressModalVisible] =
        useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseFilter])

    const getCourses = () => {
        setLoadingCourses(true)

        RestApi.doGet(
            GLOBAL.URL.GET_USER_COURSES + userId + '?type=' + courseFilter
        )
            .then((res) => {
                setCourses(res.data)
            })
            .finally(() => {
                setLoadingCourses(false)
            })
    }
    const onChangeCourseFilter = (e) => {
        setCourseFilter(e.target.value)
    }

    const userCoursesTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => showModulesProgress(record)}
                    >
                        {record.courseName}
                    </Button>
                    {record.archived ? (
                        <>
                            <br />
                            <Tag color="red">Archived</Tag>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Enrolled',
            dataIndex: 'enrolledAt',
            key: 'enrolledAt',
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateCourseAttemptModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <span style={{ marginLeft: 5 }}>{record.attempt}</span>
                </>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Completed',
            dataIndex: 'completionPercent',
            key: 'completionPercent',
            render: (text, record) => (
                <span>
                    {Math.round(record.completionPercent)}
                    {'%'}
                </span>
            ),
        },
    ]

    const showModulesProgress = (record) => {
        setLoadingCourseProgress(true)

        RestApi.doGet(
            GLOBAL.URL.GET_USER_COURSE_MODULES +
                '?courseId=' +
                record.id +
                '&userId=' +
                userId
        )
            .then((res) => {
                setModulesProgressData(res.data)
                setSelectedCourseForModulesProgress(record)
                setModulesProgressModalVisible(true)
            })
            .finally(() => {
                setLoadingCourseProgress(false)
            })
    }

    const hideModulesProgress = () => {
        setModulesProgressModalVisible(false)
    }

    const showUpdateCourseAttemptModal = (record) => {
        getAttempts()
        setSelectedCourseObj(record)
        setUpdateCourseAttemptVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                attempt: record.attempt,
            })
        }, 100)
    }

    const hideUpdateCourseAttemptModal = () => {
        setSelectedCourseObj({})
        setUpdateCourseAttemptVisible(false)
    }

    const getAttempts = () => {
        if (attempts.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS).then((res) => {
                var options = []
                res.data.forEach((item) => {
                    options.push(item.attempt)
                })

                setAttempts(options)
            })
        }
    }

    const showAddCourseModal = () => {
        setAddCourseModalVisibe(true)
        setSelectedCourseForAccess('')
        setSelectedAttemptForAcces('')

        getAttempts()
        if (courseNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
                setCourseNames(res.data)
            })
        }
    }

    const hideAddCourseModal = () => {
        setAddCourseModalVisibe(false)
    }

    const addCourse = () => {
        if (!selectedCourseForAccess) {
            message.error('Please select course!')
            return
        }

        if (!selectedAttemptForAcces) {
            message.error('Please select attempt!')
            return
        }

        setAddCourseLoading(true)

        var payload = {}
        payload.userId = userId
        payload.courseName = selectedCourseForAccess
        payload.attempt = selectedAttemptForAcces

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_COURSE_ACCESS, formData)
            .then((response) => {
                message.success(response.data)
                hideAddCourseModal()
                getCourses()
            })
            .finally(() => {
                setAddCourseLoading(false)
            })
    }

    const courseNameOptions = () => {
        var options = []
        courseNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const attemptsOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const courseModulesProgressTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Module Name',
            dataIndex: 'name',
            key: 'name',
            width: 700,
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => showSectionsProgress(record)}
                    >
                        {record.name}
                    </Button>
                </span>
            ),
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (text, record) => (
                <span>
                    {Math.round(record.completionPercent) +
                        '% (' +
                        record.completedSections +
                        ' / ' +
                        record.totalSections +
                        ')'}
                </span>
            ),
        },
    ]

    const showSectionsProgress = (record) => {
        setLoadingCourseProgress(true)

        RestApi.doGet(
            GLOBAL.URL.GET_USER_COURSE_SECTIONS +
                '?courseId=' +
                selectedCourseForModulesProgress.id +
                '&moduleId=' +
                record.id +
                '&userId=' +
                userId
        )
            .then((res) => {
                setSectionsProgressData(res.data)
                setSelectedModuleForSectionProgress(record)
                setSectionsProgressModalVisible(true)
            })
            .finally(() => {
                setLoadingCourseProgress(false)
            })
    }

    const hideSectionsProgress = () => {
        setSectionsProgressModalVisible(false)
    }

    const courseSectionsProgressTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Section Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span>{getSectionProgress(record.status)}</span>
            ),
        },
    ]

    const getSectionProgress = (progress) => {
        var progressName
        var backgroundColor
        if (progress === 'inprogress') {
            progressName = 'In Progress'
            backgroundColor = 'lightyellow'
        } else if (progress === 'completed') {
            progressName = 'Completed'
            backgroundColor = 'lightgreen'
        } else {
            progressName = 'Not Started'
            backgroundColor = 'lightgray'
        }

        return (
            <Button size="small" style={{ backgroundColor: backgroundColor }}>
                {progressName}
            </Button>
        )
    }

    const updateCourseAttempt = () => {
        form.validateFields()
            .then((values) => {
                setLoadingCourses(true)

                var payload = {}
                payload.userId = userId
                payload.courseId = selectedCourseObj.id
                payload.attempt = values.attempt

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_USER_COURSE_ATTEMPT, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })
                        getCourses()
                        hideUpdateCourseAttemptModal()
                    })
                    .catch((error) => {
                        setLoadingCourses(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            <span>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        value={courseFilter}
                        onChange={onChangeCourseFilter}
                    >
                        <Radio.Button
                            value={COURSE_FILTER_FREE}
                            key={COURSE_FILTER_FREE}
                        >
                            Free
                        </Radio.Button>
                        <Radio.Button
                            value={COURSE_FILTER_ACTIVE}
                            key={COURSE_FILTER_ACTIVE}
                        >
                            Active
                        </Radio.Button>
                        <Radio.Button
                            value={COURSE_FILTER_EXPIRED}
                            key={COURSE_FILTER_EXPIRED}
                        >
                            Expired
                        </Radio.Button>
                    </Radio.Group>

                    <Link
                        href={'/user-watchtime-analytics/' + userId}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate('/user-watchtime-analytics/' + userId)
                        }}
                        underline="none"
                        target="_blank"
                    >
                        <Button type="default" style={{ marginLeft: 10 }}>
                            Analytics
                        </Button>
                    </Link>

                    {Utils.isUserAdmin() ? (
                        <Button
                            type="default"
                            style={{ marginLeft: 10 }}
                            onClick={showAddCourseModal}
                        >
                            Add Course
                        </Button>
                    ) : null}
                </div>
                <Table
                    loading={loadingCourses}
                    columns={userCoursesTableColumns}
                    dataSource={courses}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    rowKey="id"
                />
            </span>
            <Modal
                open={addCourseModalVisibe}
                title="Add Course"
                okText="Add"
                onCancel={hideAddCourseModal}
                confirmLoading={addCourseLoading}
                onOk={addCourse}
                destroyOnClose={true}
            >
                <p>
                    <strong>
                        Note: This operation will not show up in any purchase
                        analytics.
                    </strong>
                </p>
                <p>Select Course:</p>
                <Select
                    placeholder="Select course"
                    style={{ width: '100%' }}
                    showSearch
                    onChange={(value) => setSelectedCourseForAccess(value)}
                >
                    {courseNameOptions()}
                </Select>
                <p style={{ marginTop: 10 }}>Select Attempt</p>
                <Select
                    placeholder="Select attempt"
                    style={{ width: '100%' }}
                    showSearch
                    onChange={(value) => setSelectedAttemptForAcces(value)}
                >
                    {attemptsOptions()}
                </Select>
            </Modal>
            <Modal
                title={'Module level progress'}
                open={modulesProgressModalVisible}
                onCancel={hideModulesProgress}
                footer={null}
                destroyOnClose={true}
                width={1000}
            >
                <h4>{selectedCourseForModulesProgress.courseName}</h4>
                <Table
                    loading={loadingCourseProgress}
                    columns={courseModulesProgressTableColumns}
                    dataSource={modulesProgressData}
                    pagination={false}
                    rowKey="id"
                />
            </Modal>
            <Modal
                title={'Section level progress'}
                open={sectionsProgressModalVisible}
                onCancel={hideSectionsProgress}
                footer={null}
                destroyOnClose={true}
                width={900}
            >
                <h4>{selectedModuleForSectionProgress.name}</h4>
                <Table
                    loading={loadingCourseProgress}
                    columns={courseSectionsProgressTableColumns}
                    dataSource={sectionsProgressData.sections}
                    pagination={false}
                    rowKey="id"
                />
            </Modal>
            <Modal
                title={'Update Course Attempt'}
                open={updateCourseAttemptVisible}
                onOk={updateCourseAttempt}
                confirmLoading={loadingCourses}
                onCancel={hideUpdateCourseAttemptModal}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                <h3>{selectedCourseObj.courseName}</h3>
                {updateCourseAttemptVisible ? (
                    <Form form={form}>
                        <Form.Item
                            label="Update Attempt"
                            name="attempt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Select placeholder="Select attempt" showSearch>
                                {attemptsOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}
export default UserCourses
