import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Modal } from 'antd'

function RingingCalls() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [modalVisible, setModalVisible] = useState(false)

    const getDatabuttonClicked = () => {
        setModalVisible(true)
        getData()
    }

    const hideModal = () => {
        setModalVisible(false)
    }

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.CLOUD_PHONE.GET_MY_INCOMING_RINGING_CALLS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => (
                <span>
                    <span>{index + 1}</span>
                </span>
            ),
        },
        {
            title: 'Ring Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => navigateToCallTo(record)}
                >
                    {record.callTo.firstName}
                    {record.callTo.lastName ? ' ' + record.callTo.lastName : ''}
                </Button>
            ),
        },
        {
            title: 'Student',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => navigateToCallTo(record)}
                >
                    {record.callTo.phone}
                </Button>
            ),
        },
    ]

    const navigateToCallTo = (record) => {
        if (record.externalLead) {
            window.open(
                '/leads/external?filter=open&searchQuery=' +
                    record.callTo.phone,
                '_blank'
            )
        } else {
            window.open('/userdetails/' + record.callTo.id, '_blank')
        }
    }

    return (
        <>
            <Button style={{ marginLeft: 30 }} onClick={getDatabuttonClicked}>
                My Incoming Calls
            </Button>
            <Modal
                title="My Ringing Calls"
                open={modalVisible}
                onCancel={hideModal}
                width={800}
                footer={null}
            >
                <Button onClick={getData} size="small" loading={loading}>
                    Refresh
                </Button>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </>
    )
}

export default RingingCalls
