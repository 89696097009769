import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Table, DatePicker, Tag } from 'antd'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import DatamapsIndia from 'react-datamaps-india'

function CourierStats(props) {
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const onChangeDateRange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getStats = () => {
        setLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.STATS.GET_COURIER_STATS, formData)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 70,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    return (
        <div>
            <h2>Courier Stats</h2>
            <div style={{ marginTop: 10 }}>
                <span>Date Range: </span>
                <RangePicker
                    format="DD-MMM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onChangeDateRange}
                    style={{ marginLeft: 10 }}
                />
                <Button
                    type="primary"
                    onClick={getStats}
                    loading={loading}
                    style={{ marginLeft: 20 }}
                >
                    Fetch Data
                </Button>
            </div>
            <div style={{ marginTop: 20 }}>
                {data.totalMrp ? (
                    <div style={{ marginBottom: 10 }}>
                        <Tag
                            color="geekblue"
                            style={{
                                display: 'initial',
                                fontSize: 16,
                                fontWeight: 'bold',
                                padding: 5,
                            }}
                        >
                            Total Revenue: {data.totalMrp}
                        </Tag>
                        <Tag
                            color="geekblue"
                            style={{
                                display: 'initial',
                                fontSize: 16,
                                fontWeight: 'bold',
                                padding: 5,
                                marginLeft: 20,
                            }}
                        >
                            Books Direct Revenue: {data.directBooksPurchaseMrp}
                        </Tag>
                    </div>
                ) : null}
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Paper style={{ padding: 10 }}>
                            <h3>Courier Count</h3>
                            <Table
                                columns={columns}
                                dataSource={data.statusStats}
                                pagination={false}
                                size="small"
                                loading={loading}
                                rowKey="name"
                                style={{ marginTop: 10 }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper style={{ padding: 10 }}>
                            <h3>Package Type</h3>
                            <Table
                                columns={columns}
                                dataSource={data.packageTypeStats}
                                pagination={false}
                                size="small"
                                loading={loading}
                                rowKey="name"
                                style={{ marginTop: 10 }}
                            />
                            {data.freightStats ? (
                                <div style={{ marginTop: 10 }}>
                                    <h3>Freight Stats</h3>
                                    <Table
                                        columns={columns}
                                        dataSource={data.freightStats}
                                        pagination={false}
                                        size="small"
                                        loading={loading}
                                        rowKey="name"
                                        style={{ marginTop: 10 }}
                                    />
                                </div>
                            ) : null}
                        </Paper>
                    </Grid>
                    {data.stateWiseStats ? (
                        <Grid item xs={5}>
                            <div style={{ position: 'relative' }}>
                                <DatamapsIndia
                                    style={{ postion: 'relative' }}
                                    regionData={JSON.parse(data.stateWiseStats)}
                                    hoverComponent={({ value }) => {
                                        return (
                                            <div>
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {value.name +
                                                        ' - ' +
                                                        (value.value
                                                            ? value.value
                                                            : '0')}
                                                </span>
                                            </div>
                                        )
                                    }}
                                    mapLayout={{
                                        title: 'State Split',
                                        legendTitle: 'Number of Couriers',
                                        startColor: '#b3d1ff',
                                        endColor: '#005ce6',
                                        hoverTitle: 'Count',
                                        noDataColor: '#f5f5f5',
                                        borderColor: '#8D8D8D',
                                        hoverColor: 'blue',
                                        hoverBorderColor: 'green',
                                        height: 10,
                                        weight: 30,
                                    }}
                                />
                            </div>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <Paper style={{ padding: 10, marginTop: 20 }}>
                            <h3>Books</h3>
                            <Table
                                columns={columns}
                                dataSource={data.bookStats}
                                pagination={false}
                                size="small"
                                loading={loading}
                                rowKey="name"
                                style={{ marginTop: 10 }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default CourierStats
