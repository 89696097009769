import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, notification, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const FILTER_ALL = 'all'
const FILTER_FIRST = 'first'
const FILTER_REPEAT = 'repeat'
const FILTER_FIRST_VS_REPEAT = 'first_vs_repeat'

function PurchaseAgingStats() {
    const { MonthPicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState('')
    const [startMonth, setStartMonth] = useState(0)
    const [startYear, setStartYear] = useState(0)
    const [endMonth, setEndMonth] = useState(0)
    const [endYear, setEndYear] = useState(0)
    const [filter, setFilter] = useState(FILTER_FIRST)
    const [chartData, setChartData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [options, setOptions] = useState({})
    const [firstVsRepeatChartData, setFirstVsRepeatChartData] = useState()
    const [firstvsRepeatOptions, setFirstvsRepeatOptions] = useState({})

    useEffect(() => {
        if (data.length === 0) {
            return
        }

        formChartObject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onChangeStartDate = (date, dateString) => {
        const dateSplit = dateString.split('-')
        setStartMonth(parseInt(dateSplit[0]))
        setStartYear(parseInt(dateSplit[1]))
    }

    const onChangeEndDate = (date, dateString) => {
        const dateSplit = dateString.split('-')
        setEndMonth(parseInt(dateSplit[0]))
        setEndYear(parseInt(dateSplit[1]))
    }

    const getData = () => {
        if (startMonth === 0) {
            notification['error']({
                message: 'Select Start Date!',
            })

            return
        }

        setLoading(true)

        var payload = {}
        payload.startMonth = startMonth
        payload.startYear = startYear
        payload.endMonth = endMonth
        payload.endYear = endYear
        payload.filter = filter

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.STATS.GET_PURCHASE_AGING_STATS
        if (filter === FILTER_FIRST_VS_REPEAT) {
            url = GLOBAL.URL.STATS.GET_FIRST_VS_REPEAT_AGING_STATS
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const formChartObject = () => {
        if (filter === FILTER_FIRST_VS_REPEAT) {
            formFirstVsRepeatChartObject()
        } else {
            formAgingChartObject()
        }
    }

    const formAgingChartObject = () => {
        var labels = []
        data.forEach((dataRow) => {
            labels.push(dataRow.month)
        })

        var dataSetsData = {}
        data.forEach((dataRow) => {
            dataRow.agingStats.forEach((statsRow) => {
                if (statsRow.label in dataSetsData) {
                    dataSetsData[statsRow.label].push(statsRow.mrpPercent)
                } else {
                    dataSetsData[statsRow.label] = [statsRow.mrpPercent]
                }
            })
        })

        var cnt = 0
        var dataSets = []
        for (var key in dataSetsData) {
            if (dataSetsData.hasOwnProperty(key)) {
                var color =
                    StatsConstants.COLORS[
                        cnt % (StatsConstants.COLORS.length - 1)
                    ]

                var dataSet = {}
                dataSet.label = key
                dataSet.data = dataSetsData[key]
                dataSet.backgroundColor = color
                dataSet.borderColor = color
                dataSet.fill = true

                dataSets.push(dataSet)

                cnt++
            }
        }

        var chartData = {}
        chartData.labels = labels
        chartData.datasets = dataSets

        var options = clone(StatsConstants.CHART_OPTIONS)
        options.plugins.datalabels.anchor = 'center'
        options.hover = {}
        options.tooltips = { mode: 'label' }

        setChartData(chartData)
        setOptions(options)
    }

    const formFirstVsRepeatChartObject = () => {
        var labels = []
        var dataSetsData = { First: [], Repeat: [] }
        data.forEach((dataRow) => {
            labels.push(dataRow.month)
            dataSetsData.First.push(dataRow.firstPurchaseMrpPercent)
            dataSetsData.Repeat.push(dataRow.repeatPurchaseMrpPercent)
        })

        var cnt = 0
        var dataSets = []
        for (var key in dataSetsData) {
            if (dataSetsData.hasOwnProperty(key)) {
                var color =
                    StatsConstants.COLORS[
                        cnt % (StatsConstants.COLORS.length - 1)
                    ]

                var dataSet = {}
                dataSet.label = key
                dataSet.data = dataSetsData[key]
                dataSet.backgroundColor = color
                dataSet.borderColor = color
                dataSet.fill = true

                dataSets.push(dataSet)

                cnt++
            }
        }

        var chartData = {}
        chartData.labels = labels
        chartData.datasets = dataSets

        var options = clone(StatsConstants.CHART_OPTIONS)
        options.plugins.datalabels.anchor = 'center'
        options.hover = {}
        options.tooltips = { mode: 'label' }
        options.onClick = (evt, item) => {
            showFirstVsRepeatSplit(item)
            setTimeout(() => {
                document
                    .getElementById('firstVsRepeatSplit')
                    .scrollIntoView({ behavior: 'smooth', block: 'center' })
            }, 100)
        }

        setChartData(chartData)
        setOptions(options)
    }

    const showFirstVsRepeatSplit = (item) => {
        var label
        if (item[0]._model.label) {
            label = item[0]._model.label
        } else if (item[1]._model.label) {
            label = item[1]._model.label
        } else {
            return
        }

        data.forEach((row) => {
            if (row.month === label) {
                var labels = []
                var dataSetsData = { First: [], Repeat: [] }
                row.firstPurchaseStats.forEach((dataRow) => {
                    labels.push(dataRow.label)
                    dataSetsData.First.push(dataRow.mrpPercent)
                })

                row.repeatPurchaseStats.forEach((dataRow) => {
                    dataSetsData.Repeat.push(dataRow.mrpPercent)
                })

                var cnt = 0
                var dataSets = []
                for (var key in dataSetsData) {
                    if (dataSetsData.hasOwnProperty(key)) {
                        var color =
                            StatsConstants.COLORS[
                                cnt % (StatsConstants.COLORS.length - 1)
                            ]

                        var dataSet = {}
                        dataSet.label = key
                        dataSet.data = dataSetsData[key]
                        dataSet.backgroundColor = color
                        dataSet.borderColor = color
                        dataSet.fill = true

                        dataSets.push(dataSet)

                        cnt++
                    }
                }

                var chartData = {}
                chartData.labels = labels
                chartData.datasets = dataSets

                var options = clone(StatsConstants.CHART_OPTIONS)
                options.plugins.title.text = label
                options.plugins.datalabels.anchor = 'center'
                options.hover = {}
                options.indexAxis = 'y'
                options.tooltips = { mode: 'label' }

                setFirstVsRepeatChartData(chartData)
                setFirstvsRepeatOptions(options)

                return
            }
        })
    }

    const getStatsTable = () => {
        return (
            <table
                style={{
                    border: '1px solid black',
                    borderCollapse: 'collapse',
                }}
            >
                <thead>
                    <tr
                        style={{
                            backgroundColor: '#32127A',
                            color: 'white',
                        }}
                    >
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            Month
                        </th>
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            Total MRP
                        </th>
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            Filter MRP
                        </th>
                        <th
                            style={{
                                padding: 5,
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                            }}
                        >
                            %
                        </th>
                    </tr>
                </thead>
                <tbody>{getTableRows()}</tbody>
            </table>
        )
    }

    const getTableRows = () => {
        var rows = []
        data.forEach((stat, index) => {
            rows.push(
                <tr key={index}>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                        }}
                    >
                        {stat.month}
                    </td>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'right',
                        }}
                    >
                        {stat.totalMrp}
                    </td>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'right',
                        }}
                    >
                        {stat.filterMrp}
                    </td>
                    <td
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderCollapse: 'collapse',
                            textAlign: 'right',
                        }}
                    >
                        {stat.filterMrpPercent}
                    </td>
                </tr>
            )
        })

        return rows
    }

    return (
        <div>
            <h2>Aging Stats</h2>
            <div style={{ marginTop: 10 }}>
                <Radio.Group
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                >
                    <Radio.Button value={FILTER_FIRST} key={FILTER_FIRST}>
                        {'First'}
                    </Radio.Button>
                    <Radio.Button value={FILTER_REPEAT} key={FILTER_REPEAT}>
                        {'Repeat'}
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_FIRST_VS_REPEAT}
                        key={FILTER_FIRST_VS_REPEAT}
                    >
                        {'First Vs Repeat'}
                    </Radio.Button>
                    <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                        {'All'}
                    </Radio.Button>
                </Radio.Group>
                <MonthPicker
                    onChange={onChangeStartDate}
                    picker="month"
                    format="MM-YYYY"
                    placeholder="Start Date"
                    style={{ marginLeft: 15 }}
                />
                <span style={{ marginLeft: 5, marginRight: 5 }}>{'-'}</span>
                <MonthPicker
                    onChange={onChangeEndDate}
                    picker="month"
                    format="MM-YYYY"
                    placeholder="End Date"
                />
                <Button
                    type="primary"
                    onClick={getData}
                    loading={loading}
                    style={{ marginLeft: 15 }}
                >
                    Fetch
                </Button>
            </div>
            {data.length ? (
                <>
                    <Bar
                        data={chartData}
                        width={800}
                        height={400}
                        options={options}
                    />
                    {filter !== FILTER_FIRST_VS_REPEAT ? (
                        <div style={{ marginTop: 10 }}>{getStatsTable()}</div>
                    ) : firstVsRepeatChartData ? (
                        <div
                            id="firstVsRepeatSplit"
                            style={{ marginTop: 10, display: 'flex' }}
                        >
                            <Bar
                                data={firstVsRepeatChartData}
                                options={firstvsRepeatOptions}
                                style={{ width: '100%' }}
                            />
                        </div>
                    ) : null}
                </>
            ) : null}
        </div>
    )
}

export default PurchaseAgingStats
