import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    InputNumber,
    Radio,
    Select,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'

function ComboDiscount() {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)

    const [comboList, setComboList] = useState([])
    const [selectedComboRecord, setSelectedComboRecord] = useState({})
    const [addUpdateComboModalVisible, setAddUpdateComboModalVisible] =
        useState(false)

    const [configLoading, setConfigLoading] = useState(false)
    const [configList, setConfigList] = useState([])
    const [configModalVisible, setConfigModalVisible] = useState(false)
    const [selectedConfig, setSelectedConfig] = useState({})
    const [addUpdateConfigModalVisible, setAddUpdateConfigModalVisible] =
        useState(false)
    const [couponTypes, setCouponTypes] = useState([])

    const [coursesLoading, setCoursesLoading] = useState(false)
    const [coursesList, setCoursesList] = useState([])
    const [coursesModalVisible, setCoursesModalVisible] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState({})
    const [addUpdateCoursesModalVisible, setAddUpdateCoursesModalVisible] =
        useState(false)
    const [coursesWithIds, setCoursesWithIds] = useState([])
    const inputRef = useRef()

    useEffect(() => {
        getComboList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (
                (addUpdateConfigModalVisible || addUpdateComboModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addUpdateConfigModalVisible, addUpdateComboModalVisible, inputRef])

    const getComboList = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.ECOMMERCE.GET_COMBO_DISCOUNTS)
            .then((res) => {
                setComboList(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'uid',
            key: 'uid',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditComboModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableCombo(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Config',
            dataIndex: 'config',
            key: 'config',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                        showConfigModal(record)
                    }}
                >
                    Config
                </Button>
            ),
        },
        {
            title: 'Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                        showCoursesModal(record)
                    }}
                >
                    Courses
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCombo(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showAddEditComboModal = (record) => {
        setAddUpdateComboModalVisible(true)
        setSelectedComboRecord(record)

        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({ title: record.title })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditComboModal = () => {
        setAddUpdateComboModalVisible(false)
        setSelectedComboRecord({})
    }

    const deleteCombo = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ECOMMERCE.DELETE_COMBO_DISCOUNT, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getComboList()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const addOrUpdateCombo = () => {
        form.validateFields(['title'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ECOMMERCE.ADD_COMBO_DISCOUNT
                if (selectedComboRecord.uid) {
                    url = GLOBAL.URL.ECOMMERCE.UPDATE_COMBO_DISCOUNT
                    values.uid = selectedComboRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditComboModal()
                        getComboList()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const enableDisableCombo = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.ECOMMERCE.ENABLE_COMBO_DISCOUNT
        if (record.enabled) {
            url = GLOBAL.URL.ECOMMERCE.DISABLE_COMBO_DISCOUNT
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getComboList()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showConfigModal = (record) => {
        setConfigModalVisible(true)
        setSelectedComboRecord(record)
        getConfig(record.uid)
    }

    const hideConfigModal = () => {
        setConfigModalVisible(false)
        setSelectedComboRecord({})
    }

    const getConfig = (comboId) => {
        setConfigLoading(true)

        RestApi.doGet(GLOBAL.URL.ECOMMERCE.GET_COMBO_DISCOUNT_CONFIG + comboId)
            .then((res) => {
                setConfigList(res.data)
            })
            .finally(() => {
                setConfigLoading(false)
            })
    }

    const tableConfigColumns = [
        {
            title: 'Edit',
            dataIndex: 'uid',
            key: 'uid',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditConfigModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableConfig(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Courses Count',
            dataIndex: 'coursesCount',
            key: 'coursesCount',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (text, record) => (
                <span>{record.discount + ' ' + record.type}</span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 11 }}>
                        {record.updatedBy.firstName}
                        <br />
                        {record.updatedAt}
                    </span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteConfig(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddEditConfigModal = (record) => {
        setSelectedConfig(record)
        setAddUpdateConfigModalVisible(true)

        if (couponTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COUPON_TYPES).then((res) => {
                setCouponTypes(res.data)
            })
        }

        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({
                    coursesCount: record.coursesCount,
                    type: record.type,
                    discount: record.discount,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditConfigModal = () => {
        setSelectedConfig({})
        setAddUpdateConfigModalVisible(false)
    }

    const getDiscountTypeOptions = () => {
        var typeViewObj = []
        couponTypes.forEach((item) => {
            typeViewObj.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        var options = []
        couponTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const enableDisableConfig = (record) => {
        setConfigLoading(true)

        let url = GLOBAL.URL.ECOMMERCE.ENABLE_COMBO_DISCOUNT_CONFIG
        if (record.enabled) {
            url = GLOBAL.URL.ECOMMERCE.DISABLE_COMBO_DISCOUNT_CONFIG
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getConfig(selectedComboRecord.uid)
            })
            .catch((error) => {
                setConfigLoading(false)
            })
    }

    const deleteConfig = (uid) => {
        setConfigLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.ECOMMERCE.DELETE_COMBO_DISCOUNT_CONFIG,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getConfig(selectedComboRecord.uid)
            })
            .catch((error) => {
                setConfigLoading(false)
            })
    }

    const addOrUpdateConfig = () => {
        form.validateFields(['coursesCount', 'type', 'discount'])
            .then((values) => {
                setConfigLoading(true)

                var url = GLOBAL.URL.ECOMMERCE.ADD_COMBO_DISCOUNT_CONFIG
                if (selectedConfig.uid) {
                    url = GLOBAL.URL.ECOMMERCE.UPDATE_COMBO_DISCOUNT_CONFIG
                    values.uid = selectedConfig.uid
                }

                values.comboId = selectedComboRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditConfigModal()
                        getConfig(selectedComboRecord.uid)
                    })
                    .catch((error) => {
                        setConfigLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showCoursesModal = (record) => {
        setCoursesModalVisible(true)
        setSelectedComboRecord(record)
        getCourses(record.uid)
    }

    const hideCoursesModal = () => {
        setCoursesModalVisible(false)
        setSelectedComboRecord({})
    }

    const getCourses = (comboId) => {
        setCoursesLoading(true)

        RestApi.doGet(GLOBAL.URL.ECOMMERCE.GET_COMBO_DISCOUNT_COURSES + comboId)
            .then((res) => {
                setCoursesList(res.data)
            })
            .finally(() => {
                setCoursesLoading(false)
            })
    }

    const showAddEditCoursesModal = (record) => {
        setSelectedCourse(record)
        setAddUpdateCoursesModalVisible(true)

        if (coursesWithIds.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCoursesWithIds(res.data)
                }
            )
        }
    }

    const hideAddEditCoursesModal = () => {
        setSelectedCourse({})
        setAddUpdateCoursesModalVisible(false)
    }

    const tableCoursesColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableCourse(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Courses Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 11 }}>
                        {record.updatedBy.firstName}
                        <br />
                        {record.updatedAt}
                    </span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCourse(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const courseOptions = () => {
        var options = []
        coursesWithIds.forEach((item) => {
            options.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const addCourse = () => {
        form.validateFields(['courseId'])
            .then((values) => {
                setCoursesLoading(true)

                values.comboId = selectedComboRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ECOMMERCE.ADD_COMBO_DISCOUNT_COURSE,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditCoursesModal()
                        getCourses(selectedComboRecord.uid)
                    })
                    .catch((error) => {
                        setCoursesLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const enableDisableCourse = (record) => {
        setCoursesLoading(true)

        let url = GLOBAL.URL.ECOMMERCE.ENABLE_COMBO_DISCOUNT_COURSE
        if (record.enabled) {
            url = GLOBAL.URL.ECOMMERCE.DISABLE_COMBO_DISCOUNT_COURSE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCourses(selectedComboRecord.uid)
            })
            .catch((error) => {
                setCoursesLoading(false)
            })
    }

    const deleteCourse = (uid) => {
        setCoursesLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.ECOMMERCE.DELETE_COMBO_DISCOUNT_COURSE,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCourses(selectedComboRecord.uid)
            })
            .catch((error) => {
                setCoursesLoading(false)
            })
    }

    return (
        <div>
            <h2>Combo Discounts</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddEditComboModal}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={comboList}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Combo'}
                open={addUpdateComboModalVisible}
                onOk={addOrUpdateCombo}
                confirmLoading={loading}
                onCancel={hideAddEditComboModal}
                okText={selectedComboRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            onPressEnter={addOrUpdateCombo}
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Combo Config'}
                open={configModalVisible}
                onCancel={hideConfigModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedComboRecord.title}</h3>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddEditConfigModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={tableConfigColumns}
                    dataSource={configList}
                    pagination={false}
                    size="small"
                    loading={configLoading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Config'}
                open={addUpdateConfigModalVisible}
                onOk={addOrUpdateConfig}
                confirmLoading={configLoading}
                onCancel={hideAddEditConfigModal}
                okText={selectedConfig.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={700}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Courses Count"
                        name="coursesCount"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber autoFocus min={1} ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Discount Type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{getDiscountTypeOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Discount Value"
                        name="discount"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Combo Courses'}
                open={coursesModalVisible}
                onCancel={hideCoursesModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedComboRecord.title}</h3>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddEditCoursesModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={tableCoursesColumns}
                    dataSource={coursesList}
                    pagination={false}
                    size="small"
                    loading={configLoading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Course'}
                open={addUpdateCoursesModalVisible}
                onOk={addCourse}
                confirmLoading={coursesLoading}
                onCancel={hideAddEditCoursesModal}
                okText={selectedCourse.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={700}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Course"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ComboDiscount
