import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button } from 'antd'

class BlogOpenCommentsList extends React.Component {
    state = {
        loading: false,
        data: [],
    }

    componentDidMount() {
        this.getBlogWithOpenCommentsList()
    }

    getBlogWithOpenCommentsList = () => {
        this.setState({ loading: true })
        RestApi.doGet(GLOBAL.URL.BLOG.GET_BLOGS_WITH_OPEN_COMMENTS_LIST)
            .then((res) => {
                this.setState({
                    data: res.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        const columns = [
            {
                title: 'Unanswered #',
                dataIndex: 'openCommentsCount',
                key: 'openCommentsCount',
                width: 130,
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            this.props.navigate(
                                '/blog/comments/' + record.blogId,
                                { state: { blogId: record.blogId } }
                            )
                        }}
                    >
                        {record.title}
                    </Button>
                ),
            },
        ]

        return (
            <div>
                <h3>Blogs With Unanswered Comments</h3>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={false}
                    size="small"
                    loading={this.state.loading}
                    rowKey="title"
                    style={{ marginTop: 10 }}
                />
            </div>
        )
    }
}

export default BlogOpenCommentsList
