import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
    Radio,
    Select,
    Tag,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import ReorderTable from '../Common/ReorderTable'
import Linkify from 'linkify-react'
import ILAttachmentFileList, {
    processClipboardAttachment,
} from '../ILShareComponents/ILAttachmentFileList'
import { EditTwoTone } from '@ant-design/icons'

function Results(props) {
    const [form] = Form.useForm()
    const inputRef = useRef()
    const { TextArea } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [selectedStream, setSelectedStream] = useState()
    const [selectedLevel, setSelectedLevel] = useState()
    const [selectedRecord, setSelectedRecord] = useState({})
    // Course Config
    const [courseConfigModalVisible, setCourseConfigModalVisible] =
        useState(false)
    const [selectedCourseConfigStream, setSelectedCourseConfigStream] =
        useState()
    const [selectedCourseConfigLevel, setSelectedCourseConfigLevel] = useState()
    const [courseConfigLevelsData, setCourseConfigLevelsData] = useState([])
    const [courseConfigData, setCourseConfigData] = useState([])
    const [
        addEditCourseConfigModalVisible,
        setAddEditCourseConfigModalVisible,
    ] = useState(false)
    const [selectedCourseConfig, setSelectedCourseConfig] = useState({})
    const [reorderCourseConfigVisible, setReorderCourseConfigVisible] =
        useState(false)
    // Comments
    const [commentsList, setCommentsList] = useState([])
    const [commentsVisible, setCommentsVisible] = useState(false)
    const [configureCommentVisible, setConfigureCommentVisible] =
        useState(false)
    const [selectedComment, setSelectedComment] = useState({})
    const [commentAttachmentFileList, setCommentAttachmentFileList] = useState(
        []
    )
    const [exportResultsVisible, setExportResultsVisible] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [selectedCourseExport, setSelectedCourseExport] = useState()
    const [exportLevelsData, setExportLevelsData] = useState([])
    const [exportAttempts, setExportAttempts] = useState([])

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })
    }, [])

    useEffect(() => {
        if (!selectedCourseConfigLevel) {
            return
        }

        getCourseConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCourseConfigLevel])

    useEffect(() => {
        getResults()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStream, selectedLevel])

    const showCourseConfig = () => {
        setCourseConfigModalVisible(true)
    }

    const hideCourseConfig = () => {
        setCourseConfigModalVisible(false)
    }

    const getStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getLevelOptions = (data) => {
        var options = []
        data.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeCourseConfigStream = (e) => {
        setSelectedCourseConfigLevel()

        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setSelectedCourseConfigStream(e.target.value)
        setCourseConfigLevelsData(levels)
    }

    const onChangeCourseConfigLevel = (e) => {
        setSelectedCourseConfigLevel(e.target.value)
    }

    const getCourseConfig = () => {
        if (!selectedCourseConfigStream) {
            notification['error']({
                message: 'Select Course',
            })
            return
        }

        if (!selectedCourseConfigLevel) {
            notification['error']({
                message: 'Select Level',
            })
            return
        }

        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.RESULTS.GET_COURSE_CONFIG +
                '?course=' +
                selectedCourseConfigStream +
                '&level=' +
                selectedCourseConfigLevel
        )
            .then((res) => {
                setCourseConfigData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumnsCourseConfig = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddCourseConfig(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    </Button>
                    <span style={{ marginLeft: 5 }}>{record.courseName}</span>
                </>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCourseConfig(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteCourseConfig = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.RESULTS.DELETE_COURSE_CONFIG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCourseConfig()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAddCourseConfig = (config = {}) => {
        setSelectedCourseConfig(config)
        setAddEditCourseConfigModalVisible(true)

        if (config.uid) {
            setTimeout(() => {
                form.setFieldsValue({ courseName: config.courseName })
            }, 100)
        } else {
            form.resetFields()
        }

        setTimeout(() => {
            inputRef.current.focus()
        }, 120)
    }

    const hideAddCourseConfig = () => {
        setAddEditCourseConfigModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateCourseConfig = () => {
        form.validateFields(['courseName'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.RESULTS.ADD_COURSE_CONFIG
                if (selectedCourseConfig.uid) {
                    url = GLOBAL.URL.RESULTS.UPDATE_COURSE_CONFIG
                    values.uid = selectedCourseConfig.uid
                } else {
                    values.course = selectedCourseConfigStream
                    values.level = selectedCourseConfigLevel
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddCourseConfig()
                        getCourseConfig()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showReorderCourseConfig = () => {
        setReorderCourseConfigVisible(true)
    }

    const hideReorderCourseConfig = () => {
        setReorderCourseConfigVisible(false)
    }

    const reorderCourseConfigTableColumns = [
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const reorderCourseConfig = (data) => {
        setLoading(true)

        var payload = {}
        payload.data = data

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.RESULTS.REORDER_COURSE_CONFIG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideReorderCourseConfig()
                getCourseConfig()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onChangeStream = (e) => {
        setSelectedLevel()

        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setSelectedStream(e.target.value)
        setLevelsData(levels)
    }

    const onChangeLevel = (e) => {
        setSelectedLevel(e.target.value)
    }

    const getResults = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.RESULTS.GET_RESULTS +
                '?course=' +
                (selectedStream ? selectedStream : '') +
                '&level=' +
                (selectedLevel ? selectedLevel : '')
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Student',
            dataIndex: 'student',
            key: 'student',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            window.open(
                                '/userdetails/' + record.user.id,
                                '_blank'
                            )
                        }}
                    >
                        {record.user
                            ? record.user.firstName + ' ' + record.user.lastName
                            : ''}
                    </Button>
                    {record.paidUser ? (
                        <>
                            <br />
                            <Tag color="purple" style={{ fontSize: 11 }}>
                                Paid User
                            </Tag>
                        </>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Pass',
            dataIndex: 'pass',
            key: 'pass',
            render: (text, record) => (
                <>
                    {record.pass ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                            style={{ fontSize: 16 }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="red"
                            style={{ fontSize: 16 }}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'G1',
            dataIndex: 'passGroup1',
            key: 'passGroup1',
            render: (text, record) => (
                <>
                    {record.passGroup1 ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                            style={{ fontSize: 12 }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="red"
                            style={{ fontSize: 12 }}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'G2',
            dataIndex: 'passGroup2',
            key: 'passGroup2',
            render: (text, record) => (
                <>
                    {record.passGroup2 ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                            style={{ fontSize: 12 }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="red"
                            style={{ fontSize: 12 }}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>
                    {record.stream.course + ' - ' + record.stream.level}
                </span>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (text, record) => (
                <ul style={{ paddingLeft: 10 }}>{getScores(record.scores)}</ul>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showComments(record)}
                >
                    Comments
                </Button>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.updatedBy && record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
    ]

    const getScores = (record) => {
        var names = record.map((item) => (
            <li key={item.courseName}>
                {item.score + ' ~ ' + item.courseName}
            </li>
        ))

        return names
    }

    const getNextPage = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showComments = (record) => {
        setCommentsVisible(true)
        setSelectedRecord(record)
        getComments(record.uid)
    }

    const hidecomments = () => {
        setCommentsVisible(false)
    }

    const getComments = (uid) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.RESULTS.GET_COMMENTS + uid)
            .then((response) => {
                setCommentsList(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const commentsColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showConfigureComment(record)
                        }}
                        style={{ padding: 0, marginRight: 10 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <Linkify
                        as="p"
                        options={{
                            target: '_blank',
                            rel: 'noopener noreferrer',
                        }}
                        style={{
                            marginBlockStart: '0.4em',
                            marginBlockEnd: '0.4em',
                            whiteSpace: 'pre-wrap',
                            display: 'contents',
                        }}
                    >
                        {record.comments}
                    </Linkify>
                </>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteComment(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteComment = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.RESULTS.DELETE_COMMENTS, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getComments(selectedRecord.uid)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showConfigureComment = (record = {}) => {
        setCommentAttachmentFileList([])
        setConfigureCommentVisible(true)
        setSelectedComment(record)
        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({ comments: record.comments })
            }, 100)
        } else {
            form.resetFields()
        }

        setTimeout(() => {
            inputRef.current.focus()
        }, 120)
    }

    const hideConfigureComment = () => {
        setConfigureCommentVisible(false)
    }

    const addOrUpdateComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.comments = values.comments

                var url = GLOBAL.URL.RESULTS.ADD_COMMENTS
                if (selectedComment.uid) {
                    url = GLOBAL.URL.RESULTS.UPDATE_COMMENTS
                    payload.uid = selectedComment.uid
                } else {
                    payload.resultUid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                commentAttachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(url, formData)
                    .then((response) => {
                        hideConfigureComment()
                        getComments(selectedRecord.uid)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showExportResults = () => {
        setExportResultsVisible(true)
    }

    const hideExportResults = () => {
        setExportResultsVisible(false)
    }

    const onChangeExportStreamCourse = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setSelectedCourseExport(e.target.value)
        setExportLevelsData(levels)
    }

    const onChangeExportStreamLevel = (e) => {
        setExportAttempts([])
        setLoadingExport(true)

        RestApi.doGet(
            GLOBAL.URL.RESULTS.GET_DOWNLOAD_FILTERS +
                '?streamCourse=' +
                selectedCourseExport +
                '&streamLevel=' +
                e.target.value
        )
            .then((res) => {
                setExportAttempts(res.data.attempts)
            })
            .finally(() => {
                setLoadingExport(false)
            })
    }

    const getExportAttemptOptions = () => {
        var options = []
        exportAttempts.forEach((attempt) => {
            options.push(
                <Option value={attempt} key={attempt}>
                    {attempt}
                </Option>
            )
        })

        return options
    }

    const exportResults = () => {
        form.validateFields(['streamCourse', 'streamLevel', 'attempts'])
            .then((values) => {
                setLoadingExport(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.RESULTS.DOWNLOAD_RESULTS, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideExportResults()
                    })
                    .finally(() => {
                        setLoadingExport(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <h2>Student Results</h2>
            <div>
                <div>
                    <Button size="small" onClick={showCourseConfig}>
                        Course Config
                    </Button>
                    <Button
                        size="small"
                        onClick={showExportResults}
                        style={{ marginLeft: 20 }}
                    >
                        Export Results
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group onChange={onChangeStream}>
                        {getStreamOptions()}
                    </Radio.Group>
                </div>
                {selectedStream && (
                    <div style={{ marginTop: 10 }}>
                        <Radio.Group
                            onChange={onChangeLevel}
                            value={selectedLevel}
                        >
                            {getLevelOptions(levelsData)}
                        </Radio.Group>
                    </div>
                )}
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPage}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
            <Modal
                title="Course Config"
                open={courseConfigModalVisible}
                onCancel={hideCourseConfig}
                footer={null}
                width={700}
                destroyOnClose={true}
            >
                <div>
                    <Radio.Group onChange={onChangeCourseConfigStream}>
                        {getStreamOptions()}
                    </Radio.Group>
                </div>
                {selectedCourseConfigStream && (
                    <div style={{ marginTop: 10 }}>
                        <div>
                            <Radio.Group
                                onChange={onChangeCourseConfigLevel}
                                value={selectedCourseConfigLevel}
                            >
                                {getLevelOptions(courseConfigLevelsData)}
                            </Radio.Group>
                        </div>
                        {selectedCourseConfigLevel && (
                            <div style={{ marginTop: 10 }}>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={showAddCourseConfig}
                                >
                                    Add Course
                                </Button>
                                <Button
                                    size="small"
                                    onClick={showReorderCourseConfig}
                                    style={{ marginLeft: 10 }}
                                >
                                    Reorder
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                <Table
                    columns={tableColumnsCourseConfig}
                    dataSource={courseConfigData}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="key"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Course'}
                open={addEditCourseConfigModalVisible}
                onOk={addOrUpdateCourseConfig}
                confirmLoading={loading}
                onCancel={hideAddCourseConfig}
                okText={selectedCourseConfig.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={600}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course Name"
                        name="courseName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            ref={inputRef}
                            onPressEnter={addOrUpdateCourseConfig}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Reorder Course Config'}
                open={reorderCourseConfigVisible}
                width={600}
                onCancel={hideReorderCourseConfig}
                destroyOnClose={true}
                footer={null}
            >
                <ReorderTable
                    reorderData={courseConfigData}
                    columns={reorderCourseConfigTableColumns}
                    onOk={reorderCourseConfig}
                />
            </Modal>
            <Modal
                title={'Comments ~ ' + selectedRecord.attempt}
                open={commentsVisible}
                width={1000}
                onCancel={hidecomments}
                destroyOnClose={true}
                footer={null}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showConfigureComment}
                    >
                        Add Comments
                    </Button>
                </div>
                <Table
                    columns={commentsColumns}
                    dataSource={commentsList}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={(selectedComment.uid ? 'Update' : 'Add') + ' Comments'}
                open={configureCommentVisible}
                onOk={addOrUpdateComment}
                confirmLoading={loading}
                onCancel={hideConfigureComment}
                okText={selectedComment.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="Comments"
                            autoFocus
                            onPaste={(e) =>
                                processClipboardAttachment(
                                    e,
                                    setCommentAttachmentFileList
                                )
                            }
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
                <ILAttachmentFileList
                    attachmentFileList={commentAttachmentFileList}
                    setAttachmentFileList={setCommentAttachmentFileList}
                />
            </Modal>
            <Modal
                title={'Export Results'}
                open={exportResultsVisible}
                onOk={exportResults}
                confirmLoading={loadingExport}
                onCancel={hideExportResults}
                okText={'Export'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="streamCourse"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeExportStreamCourse}>
                            {getStreamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourseExport ? (
                        <Form.Item
                            label="Level"
                            name="streamLevel"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeExportStreamLevel}>
                                {getLevelOptions(exportLevelsData)}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    {exportAttempts.length ? (
                        <Form.Item
                            label="Attempts"
                            style={{ marginBottom: 0 }}
                            name="attempts"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Attempts"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                mode="multiple"
                            >
                                {getExportAttemptOptions()}
                            </Select>
                        </Form.Item>
                    ) : null}
                </Form>
            </Modal>
        </div>
    )
}

export default Results
