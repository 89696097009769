import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Radio,
    Select,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

function CartLeadTypes(props) {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [statusTypes, setStatusTypes] = useState([])
    const [subTypes, setSubTypes] = useState([])
    const [subTypesModalVisible, setSubTypesModalVisible] = useState(false)
    const [selectedSubTypeRecord, setSelectedSubTypeRecord] = useState({})
    const [addEditSubTypeModalVisible, setAddEditSubTypeModalVisible] =
        useState(false)
    const [groupNames, setGroupNames] = useState([])
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        getLeadTypes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getLeadTypes = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_TYPES)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getLeadStatusTypes = () => {
        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_STATUS_TYPES).then((res) => {
            setStatusTypes(res.data)
        })
    }

    const showAddEditModal = (record = {}) => {
        if (statusTypes.length === 0) {
            getLeadStatusTypes()
        }

        if (groupNames.length === 0) {
            getGroupNames()
        }

        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const getGroupNames = () => {
        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_TYPE_GROUP_NAMES).then((res) => {
            setGroupNames(res.data)
        })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 80,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => enableDisable(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Status Type',
            dataIndex: 'statusType',
            key: 'statusType',
            width: 100,
        },
        {
            title: 'Lead Type',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showSubTypes(record)}
                >
                    {record.type}
                </Button>
            ),
        },
        {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            width: 150,
        },
        {
            title: 'Active Call',
            dataIndex: 'activeCall',
            key: 'activeCall',
            width: 100,
            render: (text, record) => (
                <span>{record.activeCall ? 'Yes' : 'No'}</span>
            ),
        },
        {
            title: 'Revenue Counted',
            dataIndex: 'revenueCounted',
            key: 'revenueCounted',
            render: (text, record) => (
                <span>{record.revenueCounted ? 'Yes' : 'No'}</span>
            ),
        },
        {
            title: 'Non Call Type',
            dataIndex: 'nonCallType',
            key: 'nonCallType',
            render: (text, record) => (
                <span>{record.nonCallType ? 'Yes' : 'No'}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteLeadType(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateLeadType = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_CART_LEAD_TYPE
                if (selectedRecord.id) {
                    url = GLOBAL.URL.UPDATE_CART_LEAD_TYPE
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getLeadTypes()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteLeadType = (id, isSubType = false) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_CART_LEAD_TYPE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                if (isSubType) {
                    getSubTypes(selectedRecord.id)
                } else {
                    getLeadTypes()
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const StatusTypeOptions = () => {
        var options = []
        statusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const activeCallTypeOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        return options
    }

    const revenueCountedOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        return options
    }

    const nonCallTypeOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        return options
    }

    const enableDisable = (record, isSubType = false) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_CART_LEAD_TYPE
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_CART_LEAD_TYPE
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                notification['success']({
                    message: res.data,
                })

                if (isSubType) {
                    getSubTypes(selectedRecord.id)
                } else {
                    getLeadTypes()
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const subTypeTableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditSubTypeModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 80,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => enableDisable(record, true)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Sub Type',
            dataIndex: 'type',
            key: 'type',
            width: 150,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteLeadType(record.id, true)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showSubTypes = (record) => {
        setSubTypesModalVisible(true)
        setSelectedRecord(record)
        getSubTypes(record.id)
    }

    const hideSubTypes = () => {
        setSubTypesModalVisible(false)
    }

    const getSubTypes = (parentId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES + parentId)
            .then((res) => {
                setSubTypes(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditSubTypeModal = (record) => {
        setSelectedSubTypeRecord(record)
        setAddEditSubTypeModalVisible(true)

        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({ type: record.type })
            }, 100)
        }
    }

    const hideAddEditSubTypeModal = () => {
        setSelectedSubTypeRecord({})
        setAddEditSubTypeModalVisible(false)
    }

    const addOrUpdateSubLeadType = () => {
        form.validateFields(['type'])
            .then((values) => {
                setLoading(true)

                values.parentId = selectedRecord.id

                var url = GLOBAL.URL.LEADS.ADD_LEAD_SUB_TYPE
                if (selectedSubTypeRecord.id) {
                    url = GLOBAL.URL.LEADS.UPDATE_LEAD_SUB_TYPE
                    values.id = selectedSubTypeRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditSubTypeModal()
                        getSubTypes(selectedRecord.id)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getGroupNameOptions = () => {
        var options = []
        groupNames.forEach((name) => {
            options.push(
                <Option value={name} key={name}>
                    {name}
                </Option>
            )
        })

        return options
    }

    return (
        <div>
            <h2>Cart Lead Types</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="type"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Lead Type'}
                open={addEditModalVisible}
                onOk={addOrUpdateLeadType}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Lead Type"
                        style={{ marginBottom: 10 }}
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Group Name"
                        style={{ marginBottom: 10 }}
                        name="groupName"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                        >
                            {getGroupNameOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Status Type"
                        style={{ marginBottom: 10 }}
                        name="statusType"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{StatusTypeOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Active Call"
                        style={{ marginBottom: 10 }}
                        name="activeCall"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{activeCallTypeOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Revenue Counted"
                        style={{ marginBottom: 10 }}
                        name="revenueCounted"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{revenueCountedOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Non Call Type"
                        style={{ marginBottom: 0 }}
                        name="nonCallType"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{nonCallTypeOptions()}</Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Sub Types'}
                open={subTypesModalVisible}
                onCancel={hideSubTypes}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddEditSubTypeModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={subTypeTableColumns}
                    dataSource={subTypes}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="type"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Sub Type'}
                open={addEditSubTypeModalVisible}
                onOk={addOrUpdateSubLeadType}
                confirmLoading={loading}
                onCancel={hideAddEditSubTypeModal}
                okText={selectedSubTypeRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Lead Type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default CartLeadTypes
