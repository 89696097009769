import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    InputNumber,
    message,
    Select,
    Radio,
    TimePicker,
    Tag,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import TinyMceEditor from '../Common/TinyMceEditor'
import clone from 'clone'
import Paper from '@mui/material/Paper'
import { v4 as uuidv4 } from 'uuid'
import ReorderTable from '../Common/ReorderTable'
import RouteData from '../Common/RouteData'
import WhatsAppTemplates from '../User/WhatsAppTemplates'
import { EditTwoTone, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

const DRIP_TYPE_EMAIL = 'Email'
const DRIP_TYPE_SMS = 'SMS'
const DRIP_TYPE_WHATSAPP = 'WhatsApp'
const DRIP_TYPE_NOTIFICATION = 'Notification'

const FLOW_PHONE_NOT_SET = 'Phone_Not_Set'
const FLOW_STREAM_NOT_SET = 'Stream_Not_Set'

function Drip() {
    const [form] = Form.useForm()
    const { TextArea } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [dripFlows, setDripFlows] = useState([])
    const [selectedDripFlow, setSelectedDripFlow] = useState()
    const [selectedDripType, setSelectedDripType] = useState(DRIP_TYPE_EMAIL)
    const [testing, setTesting] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [previewEmailModalVisible, setPreviewEmailModalVisible] =
        useState(false)
    const [emailBody, setEmailBody] = useState()
    const [isEmailCustomHtml, setIsEmailCustomHtml] = useState(false)
    const [emailCustomHtml, setEmailCustomHtml] = useState('')
    const [
        emailCustomHtmlPreviewModalVisible,
        setEmailCustomHtmlPreviewModalVisible,
    ] = useState(false)
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [userPlaceHolders, setUserPlaceHolders] = useState([])
    const [timeToSend, setTimeToSend] = useState()
    const [sms, setSms] = useState()
    const [whatsappTemplateNames, setWhatsappTemplateNames] = useState([])
    const [whatsappTemplateNamesLoading, setWhatsappTemplateNamesLoading] =
        useState(false)
    const [whatsAppTemplatesModalVisible, setWhatsAppTemplatesModalVisible] =
        useState(false)
    const [addVariableDataModalVisible, setAddVariableDataModalVisible] =
        useState(false)
    const [selectedVariableData, setSelectedVariableData] = useState({})
    const [variablesData, setVariablesData] = useState([])
    const [
        reorderVariableDataModalVisible,
        setReorderVariableDataModalVisible,
    ] = useState(false)
    const [notificationTitle, setNotificationTitle] = useState()
    const [routeData, setRouteData] = useState()

    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [addStreamMappingVisible, setAddStreamMappingVisible] =
        useState(false)
    const [selectedStream, setSelectedStream] = useState()
    const [streamFilter, setStreamFilter] = useState('')
    const [levelFilter, setLevelFilter] = useState('')
    const [selectedStreamLevelData, setSelectedStreamLevelData] = useState([])
    const [streamMappingModalVisible, setStreamMappingModalVisible] =
        useState(false)

    const inputRef = useRef(null)

    useEffect(() => {
        getDripFlows()
    }, [])

    useEffect(() => {
        if (!selectedDripFlow || !selectedDripType) {
            return
        }

        getDrips()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDripFlow, selectedDripType, streamFilter, levelFilter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [addEditModalVisible, inputRef])

    const getDripFlows = () => {
        RestApi.doGet(GLOBAL.URL.DRIP.GET_DRIP_FLOWS).then((resp) => {
            setDripFlows(resp.data)

            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
                var streamObj = JSON.parse(res.data)
                setStreamData(streamObj)
                setStreamFilter(streamObj[0].course)
                setSelectedDripFlow(resp.data[0])
            })
        })
    }

    const getDrips = () => {
        setLoading(true)
        setData([])

        RestApi.doGet(
            GLOBAL.URL.DRIP.GET_DRIPS +
                '?flow=' +
                selectedDripFlow +
                '&type=' +
                selectedDripType +
                '&stream=' +
                streamFilter +
                '&level=' +
                levelFilter
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                    }}
                    onClick={() => enableDisable(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Day Gap',
            dataIndex: 'dayGap',
            key: 'dayGap',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    {record.title}
                    {record.type === 'Email' ? (
                        <span style={{ fontSize: 11 }}>
                            <br />
                            Open count: {record.emailOpenCount}
                        </span>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Time',
            dataIndex: 'timeToSend',
            key: 'timeToSend',
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <>
                    {record.type === DRIP_TYPE_EMAIL ? (
                        <>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => showPreviewEmail(record)}
                                style={{ padding: 0 }}
                            >
                                Preview Email
                            </Button>
                        </>
                    ) : selectedDripType === DRIP_TYPE_SMS ? (
                        <>{record.sms}</>
                    ) : selectedDripType === DRIP_TYPE_WHATSAPP ? (
                        <>{record.whatsAppTemplateName}</>
                    ) : selectedDripType === DRIP_TYPE_NOTIFICATION ? (
                        <>
                            <strong>{record.notificationTitle}</strong>
                            <br />
                            {record.notificationBody}
                            <br />
                            <span style={{ fontSize: 12 }}>
                                {record.notificationRoute}
                            </span>
                            {record.notificationImage ? (
                                <>
                                    <br />
                                    <a
                                        href={record.notificationImage}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Image
                                    </a>
                                </>
                            ) : null}
                        </>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Test',
            dataIndex: 'test',
            key: 'test',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => testDrip(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        size="small"
                        disabled={testing}
                        style={{ padding: 0 }}
                    >
                        Test
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Stream Mapping',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <>
                    {selectedDripFlow !== FLOW_PHONE_NOT_SET &&
                    selectedDripFlow !== FLOW_STREAM_NOT_SET ? (
                        <Button
                            type="link"
                            size="small"
                            style={{ padding: 0 }}
                            onClick={() => showStreamMappingModal(record)}
                        >
                            Stream Mapping
                        </Button>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteDrip(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const streamMappingTableColumns = [
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => <>{record.stream.course}</>,
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            render: (text, record) => <>{record.stream.level}</>,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteStreamMapping(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const getDripFlowTypeOptions = () => {
        var options = []
        dripFlows.forEach((flow) => {
            options.push(
                <Radio.Button value={flow} key={flow}>
                    {flow}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeDripFlow = (event) => {
        if (
            event.target.value === FLOW_PHONE_NOT_SET ||
            event.target.value === FLOW_STREAM_NOT_SET
        ) {
            setStreamFilter('')
            setLevelFilter('')
        }

        setSelectedDripFlow(event.target.value)
    }

    const getIsEmailCustomHtmlOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )

        return options
    }

    const onChangeIsEmailCustomHtml = (event) => {
        setIsEmailCustomHtml(event.target.value)
    }

    const getDripTypeOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={DRIP_TYPE_EMAIL} key={DRIP_TYPE_EMAIL}>
                {DRIP_TYPE_EMAIL}
            </Radio.Button>
        )

        if (selectedDripFlow !== FLOW_PHONE_NOT_SET) {
            options.push(
                <Radio.Button value={DRIP_TYPE_SMS} key={DRIP_TYPE_SMS}>
                    {DRIP_TYPE_SMS}
                </Radio.Button>
            )
            options.push(
                <Radio.Button
                    value={DRIP_TYPE_WHATSAPP}
                    key={DRIP_TYPE_WHATSAPP}
                >
                    {DRIP_TYPE_WHATSAPP}
                </Radio.Button>
            )
        }

        options.push(
            <Radio.Button
                value={DRIP_TYPE_NOTIFICATION}
                key={DRIP_TYPE_NOTIFICATION}
            >
                {DRIP_TYPE_NOTIFICATION}
            </Radio.Button>
        )

        return options
    }

    const onChangeDripType = (event) => {
        setSelectedDripType(event.target.value)
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.DRIP.ENABLE_DRIP
        if (record.enabled) {
            url = GLOBAL.URL.DRIP.DISABLE_DRIP
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(data)
                fullData.forEach((item) => {
                    if (item.uid === record.uid) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteDrip = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DRIP.DELETE_DRIP, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getDrips()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const testDrip = (uid) => {
        setTesting(true)
        message.loading('Sending...', 0)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DRIP.TEST_DRIP, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setTesting(false)
                message.destroy()
            })
    }

    const showPreviewEmail = (record) => {
        setSelectedRecord(record)
        setPreviewEmailModalVisible(true)
    }

    const hidePreviewEmail = () => {
        setPreviewEmailModalVisible(false)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getUserPlaceHolders = () => {
        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }
    }

    const getPlaceHolders = () => {
        var res = ''
        userPlaceHolders.forEach((userPlaceHolder) => {
            if (res) {
                res = res + ', '
            }

            res = res + userPlaceHolder
        })

        return res
    }

    const handleEmailBodyChange = (newValue, e) => {
        setEmailBody(newValue)
    }

    const onChangeTime = (time, timeString) => {
        setTimeToSend(timeString)
    }

    const getWhatsAppTemplates = () => {
        setWhatsappTemplateNamesLoading(true)
        RestApi.doGet(GLOBAL.URL.NOTIFICATION.GET_WHATSAPP_TEMPLATE_NAMES)
            .then((res) => {
                setWhatsappTemplateNames(res.data)
            })
            .finally(() => {
                setWhatsappTemplateNamesLoading(false)
            })
    }

    const whatsappTemplateOptions = () => {
        var options = []
        whatsappTemplateNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const variablesDataTableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddVariableDataModalVisible(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Variable #',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteVariableData(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteVariableData = (id) => {
        setVariablesData(variablesData.filter((item) => item.id !== id))
    }

    const showAddVariableDataModalVisible = (record = {}) => {
        setAddVariableDataModalVisible(true)
        setSelectedVariableData(record)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({
                    dataValue: record.data,
                })
            }, 100)
        } else {
            form.resetFields()
        }

        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }
    }

    const hideAddVariableDataModalVisible = () => {
        setAddVariableDataModalVisible(false)
    }

    const showReorderVariableDataModal = () => {
        setReorderVariableDataModalVisible(true)
    }

    const hideReorderVariableDataModal = () => {
        setReorderVariableDataModalVisible(false)
    }

    const saveReorderVariablesData = (data) => {
        setVariablesData(data)
        hideReorderVariableDataModal()
    }

    const reorderVariablesDataTableColumns = [
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
    ]

    const updateVariableDataInState = (variablesDataObj = []) => {
        var obj = []
        variablesDataObj.forEach((item) => {
            var varObj = {}
            varObj.id = uuidv4()
            varObj.data = item

            obj.push(varObj)
        })

        setVariablesData(obj)
    }

    const addOrUpdateVariableData = () => {
        form.validateFields(['dataValue'])
            .then((values) => {
                var variablesData1 = clone(variablesData)
                if (selectedVariableData.id) {
                    variablesData1.forEach((item) => {
                        if (item.id === selectedVariableData.id) {
                            item.data = values.dataValue
                        }
                    })
                } else {
                    var varData = {}
                    varData.id = uuidv4()
                    varData.data = values.dataValue

                    variablesData1.push(varData)
                }

                setVariablesData(variablesData1)
                hideAddVariableDataModalVisible()
            })
            .catch((error) => {})
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        getUserPlaceHolders()

        if (selectedDripType === DRIP_TYPE_WHATSAPP) {
            getWhatsAppTemplates()
        }

        if (record && record.uid) {
            setTimeToSend(record.timeToSend)

            var formData = {}
            formData.title = record.title
            formData.dayGap = record.dayGap
            formData.timeToSend = dayjs(record.timeToSend, 'HH:mm')

            if (selectedDripType === DRIP_TYPE_EMAIL) {
                setIsEmailCustomHtml(record.emailCustomHtmlBoolean)
                formData.emailSubject = record.emailSubject

                if (record.emailCustomHtmlBoolean) {
                    formData.emailCustomHtml = record.emailCustomHtml
                    setEmailCustomHtml(record.emailCustomHtml)
                    setEmailBody('')
                } else {
                    setEmailBody(record.emailBody)
                    setEmailCustomHtml('')
                    formData.emailPreHeader = record.emailPreHeader
                }
            } else if (selectedDripType === DRIP_TYPE_SMS) {
                setSms(record.sms)
                formData.sms = record.sms
            } else if (selectedDripType === DRIP_TYPE_WHATSAPP) {
                updateVariableDataInState(
                    record.whatsAppParams
                        ? JSON.parse(record.whatsAppParams)
                        : []
                )
                formData.whatsAppTemplateName = record.whatsAppTemplateName
                formData.whatsAppImage = record.whatsAppImage ?? ''
            } else if (selectedDripType === DRIP_TYPE_NOTIFICATION) {
                setNotificationTitle(record.notificationTitle)
                setRouteData(record.notificationRoute)

                formData.notificationTitle = record.notificationTitle
                formData.notificationBody = record.notificationBody
                formData.notificationImage = record.notificationImage
            }

            setTimeout(() => {
                form.setFieldsValue(formData)
            }, 100)
        } else {
            form.resetFields()
            setTimeToSend()
            setEmailBody()
            setSms()
            setVariablesData([])
            setNotificationTitle()
            setRouteData()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const addOrUpdate = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                if (!timeToSend) {
                    notification['error']({
                        message: 'Select time to send!',
                    })

                    return
                }

                values.flow = selectedDripFlow
                values.type = selectedDripType
                values.timeToSend = timeToSend

                if (selectedDripType === DRIP_TYPE_EMAIL) {
                    values.isEmailCustomHtmlBoolean = isEmailCustomHtml

                    if (!isEmailCustomHtml) {
                        values.emailBody = emailBody
                    }
                } else if (selectedDripType === DRIP_TYPE_WHATSAPP) {
                    var whatsAppParams = []
                    variablesData.forEach((item) => {
                        whatsAppParams.push(item.data)
                    })

                    values.whatsAppParams = whatsAppParams
                } else if (selectedDripType === DRIP_TYPE_NOTIFICATION) {
                    var routeObj = {}
                    routeObj.key = 'route'
                    routeObj.value = values.route

                    if (!values.routeData) {
                        values.routeData = []
                    }

                    values.routeData.push(routeObj)
                }

                var URL = GLOBAL.URL.DRIP.ADD_DRIP
                if (selectedRecord.uid) {
                    values.uid = selectedRecord.uid
                    URL = GLOBAL.URL.DRIP.UPDATE_DRIP
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(URL, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getDrips()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteStreamMapping = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DRIP.DELETE_DRIP_STREAM_MAPPING, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getDrips()
                hideStreamMappingModal()
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const showAddStreamMapping = (record) => {
        setSelectedRecord(record)
        setAddStreamMappingVisible(true)
    }

    const hideAddStreamMapping = (record) => {
        setAddStreamMappingVisible(false)
        setSelectedRecord({})
        setSelectedStream()
        setLevelData([])
    }

    const handleStreamChange = (value) => {
        setSelectedStream(value)
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
            }
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const addStreamMapping = () => {
        form.validateFields(['stream', 'level'])
            .then((values) => {
                setLoading(true)
                values.dripId = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.DRIP.ADD_DRIP_STREAM_MAPPING,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddStreamMapping()
                        getDrips()
                        hideStreamMappingModal()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const enableDisableStreamMapping = (record) => {
        setLoading(true)

        var payload = {}
        payload.dripId = record.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.DRIP.ENABLE_DRIP_STREAM_MAPPINGS
        if (record.streamsEnabled) {
            url = GLOBAL.URL.DRIP.DISABLE_DRIP_STREAM_MAPPINGS
        }

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getDrips()
                hideStreamMappingModal()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showWhatsAppTemplatesModal = () => {
        setWhatsAppTemplatesModalVisible(true)
    }

    const hideWhatsAppTemplatesModal = () => {
        setWhatsAppTemplatesModalVisible(false)
    }

    const showEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(true)
    }

    const hideEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(false)
    }

    const filterStreamOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={''} key={'All'}>
                {'All'}
            </Radio.Button>
        )

        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        options.push(
            <Radio.Button value={'not set'} key={'Not-Set'}>
                {'Not Set'}
            </Radio.Button>
        )

        return options
    }

    const filterLevelOptions = () => {
        var options = []
        selectedStreamLevelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const handleStreamFilterChange = (value) => {
        setStreamFilter(value)
        setLevelFilter('')
        streamData.forEach((item) => {
            if (item.course === value) {
                setSelectedStreamLevelData(item.levels)
            }
        })
    }

    const showStreamMappingModal = (record) => {
        setSelectedRecord(record)
        setStreamMappingModalVisible(true)
    }

    const hideStreamMappingModal = () => {
        setSelectedRecord({})
        setStreamMappingModalVisible(false)
    }

    return (
        <div>
            <h2>Drip</h2>
            <div>
                <Radio.Group
                    onChange={onChangeDripFlow}
                    value={selectedDripFlow}
                >
                    {getDripFlowTypeOptions()}
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group
                    onChange={onChangeDripType}
                    value={selectedDripType}
                >
                    {getDripTypeOptions()}
                </Radio.Group>
                <Button
                    type="link"
                    onClick={getDrips}
                    style={{ marginLeft: 10 }}
                >
                    {loading ? (
                        <LoadingOutlined
                            type="loading"
                            style={{ fontSize: 20 }}
                        />
                    ) : (
                        <ReloadOutlined
                            type="reload"
                            style={{ fontSize: 20 }}
                        />
                    )}
                </Button>
            </div>
            {selectedDripFlow !== FLOW_PHONE_NOT_SET &&
            selectedDripFlow !== FLOW_STREAM_NOT_SET ? (
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        onChange={(e) =>
                            handleStreamFilterChange(e.target.value)
                        }
                        value={streamFilter}
                    >
                        {filterStreamOptions()}
                    </Radio.Group>
                    <Radio.Group
                        style={{ marginLeft: 10 }}
                        onChange={(e) => setLevelFilter(e.target.value)}
                        value={levelFilter}
                    >
                        {filterLevelOptions()}
                    </Radio.Group>
                </div>
            ) : null}
            <div style={{ marginTop: 10 }}>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                />
            </div>
            <Modal
                title={selectedRecord.title}
                open={previewEmailModalVisible}
                onCancel={hidePreviewEmail}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <h4>Subject</h4>
                <Paper style={{ marginBottom: 10, padding: 10 }}>
                    {selectedRecord.emailSubject}
                </Paper>
                {selectedRecord.emailCustomHtmlBoolean ? (
                    <>
                        <h4>Email Body</h4>
                        <Paper
                            dangerouslySetInnerHTML={createMarkupWeb(
                                selectedRecord.emailCustomHtml
                            )}
                            style={{ padding: 10 }}
                        />
                    </>
                ) : (
                    <>
                        <h4>Pre Header</h4>
                        <Paper style={{ marginBottom: 10, padding: 10 }}>
                            {selectedRecord.emailPreHeader}
                        </Paper>
                        <h4>Email Body</h4>
                        <Paper
                            dangerouslySetInnerHTML={createMarkupWeb(
                                selectedRecord.emailBody
                            )}
                            style={{ padding: 10 }}
                        />
                    </>
                )}
            </Modal>
            <Modal
                title={'Custom HTML Preview'}
                open={emailCustomHtmlPreviewModalVisible}
                onCancel={hideEmailCustomHtmlPreview}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <Paper
                    dangerouslySetInnerHTML={createMarkupWeb(emailCustomHtml)}
                    style={{ padding: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Drip'}
                open={addEditModalVisible}
                onOk={addOrUpdate}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1200}
            >
                {addEditModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Title"
                            style={{ marginBottom: 5 }}
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input autoFocus ref={inputRef} />
                        </Form.Item>
                        <Form.Item
                            label="Day Gap"
                            style={{ marginBottom: 5 }}
                            name="dayGap"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber placeholder="Day Gap" min={0} />
                        </Form.Item>
                        <Form.Item
                            label="Time"
                            style={{ marginBottom: 5 }}
                            name="timeToSend"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeTime}
                            />
                        </Form.Item>
                        {selectedDripType === DRIP_TYPE_EMAIL ? (
                            <>
                                <Form.Item
                                    label="Email Subject"
                                    style={{ marginBottom: 5 }}
                                    name="emailSubject"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <div
                                    style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                    <span
                                        style={{
                                            color: 'black',
                                            marginRight: 10,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Email Body Custom Html?
                                    </span>
                                    <Radio.Group
                                        onChange={onChangeIsEmailCustomHtml}
                                        value={isEmailCustomHtml}
                                    >
                                        {getIsEmailCustomHtmlOptions()}
                                    </Radio.Group>
                                </div>
                                {isEmailCustomHtml ? (
                                    <>
                                        <Button
                                            type="primary"
                                            style={{
                                                marginBottom: 10,
                                                marginLeft: 144,
                                            }}
                                            onClick={showEmailCustomHtmlPreview}
                                        >
                                            Preview Html
                                        </Button>
                                        <Form.Item
                                            label="Custom Html"
                                            style={{ marginBottom: 5 }}
                                            name="emailCustomHtml"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required!',
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                rows={5}
                                                onChange={(e) =>
                                                    setEmailCustomHtml(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item
                                            label="Email Pre Header"
                                            style={{ marginBottom: 5 }}
                                            name="emailPreHeader"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <UploadAndGetLink />
                                        <div style={{ marginTop: 0 }}>
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                }}
                                            >
                                                {'Place holders:  '}
                                                {getPlaceHolders()}
                                            </span>
                                            <div style={{ marginTop: 10 }}>
                                                <span>Email Body:</span>
                                            </div>
                                            <TinyMceEditor
                                                handleEditorChange={
                                                    handleEmailBodyChange
                                                }
                                                initialValue={emailBody}
                                                height={300}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        ) : null}
                        {selectedDripType === DRIP_TYPE_SMS ? (
                            <>
                                <div style={{ marginTop: 0 }}>
                                    <span
                                        style={{
                                            fontSize: 12,
                                            marginLeft: 145,
                                        }}
                                    >
                                        {'Place holders:  '}
                                        {getPlaceHolders()}
                                    </span>
                                </div>
                                <Form.Item
                                    label="SMS"
                                    style={{ marginBottom: 5 }}
                                    name="sms"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) => setSms(e.target.value)}
                                    />
                                </Form.Item>
                                {sms && sms.length ? (
                                    <span style={{ marginLeft: 145 }}>
                                        {sms.length + ' characters'}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                        {selectedDripType === DRIP_TYPE_WHATSAPP ? (
                            <>
                                <Form.Item
                                    label="WA Template"
                                    style={{ marginBottom: 5 }}
                                    name="whatsAppTemplateName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select template"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        allowClear={true}
                                    >
                                        {whatsappTemplateOptions()}
                                    </Select>
                                </Form.Item>
                                <div style={{ marginBottom: 5 }}>
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={getWhatsAppTemplates}
                                        loading={whatsappTemplateNamesLoading}
                                        style={{ marginLeft: 145 }}
                                    >
                                        Refresh Templates
                                    </Button>
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={showWhatsAppTemplatesModal}
                                        loading={whatsappTemplateNamesLoading}
                                        style={{ marginLeft: 10 }}
                                    >
                                        Configure
                                    </Button>
                                </div>
                                <Form.Item
                                    label="WA Image"
                                    style={{ marginBottom: 5 }}
                                    name="whatsAppImage"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <div style={{ marginTop: 10 }}>
                                    <div>
                                        <label style={{ color: 'black' }}>
                                            {'Variables Data'}
                                        </label>
                                        <Button
                                            type="primary"
                                            size="small"
                                            style={{ marginLeft: 10 }}
                                            onClick={
                                                showAddVariableDataModalVisible
                                            }
                                        >
                                            Add
                                        </Button>
                                        <Button
                                            onClick={
                                                showReorderVariableDataModal
                                            }
                                            type="primary"
                                            size="small"
                                            style={{ marginLeft: 10 }}
                                        >
                                            Reorder
                                        </Button>
                                    </div>
                                    <Table
                                        columns={variablesDataTableColumns}
                                        dataSource={variablesData}
                                        pagination={false}
                                        size="small"
                                        loading={loading}
                                        rowKey="id"
                                        style={{ marginTop: 10 }}
                                    />
                                </div>
                            </>
                        ) : null}
                        {selectedDripType === DRIP_TYPE_NOTIFICATION ? (
                            <>
                                <Form.Item
                                    label="Notification Title"
                                    style={{ marginBottom: 5 }}
                                    name="notificationTitle"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <Input
                                        onChange={(e) =>
                                            setNotificationTitle(e.target.value)
                                        }
                                    />
                                </Form.Item>
                                {notificationTitle &&
                                notificationTitle.length ? (
                                    <>
                                        <span style={{ marginLeft: 145 }}>
                                            {notificationTitle.length +
                                                ' characters'}
                                        </span>
                                        {notificationTitle.length > 43 ? (
                                            <span
                                                style={{
                                                    textAlign: 'right',
                                                    color: 'red',
                                                }}
                                            >
                                                {
                                                    '  (Recommended title length is 43 characters, beyond that it may get trimmed)'
                                                }
                                            </span>
                                        ) : null}
                                    </>
                                ) : null}
                                <Form.Item
                                    label="Notification Body"
                                    style={{ marginBottom: 5 }}
                                    name="notificationBody"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <TextArea rows={3} />
                                </Form.Item>
                                <Form.Item
                                    label="Notification Image"
                                    style={{ marginBottom: 5 }}
                                    name="notificationImage"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <RouteData
                                    form={form}
                                    routeRequired={true}
                                    data={routeData}
                                />
                                {routeData ? (
                                    <span style={{ marginLeft: 145 }}>
                                        Current Route: {routeData}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Configure Variable Data'}
                open={addVariableDataModalVisible}
                onOk={addOrUpdateVariableData}
                onCancel={hideAddVariableDataModalVisible}
                okText={selectedVariableData.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={600}
            >
                {addVariableDataModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Data"
                            style={{ marginBottom: 5 }}
                            name="dataValue"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                    </Form>
                ) : null}
                <span style={{ fontSize: 12, marginTop: 20 }}>
                    {'Place holders:  '}
                    <br />
                    {getPlaceHolders()}
                </span>
            </Modal>
            <Modal
                title={'Reorder Variables Data'}
                open={reorderVariableDataModalVisible}
                width={600}
                onCancel={hideReorderVariableDataModal}
                destroyOnClose={true}
                footer={null}
            >
                <ReorderTable
                    reorderData={variablesData}
                    columns={reorderVariablesDataTableColumns}
                    onOk={saveReorderVariablesData}
                />
            </Modal>
            <Modal
                title={'Add Stream Mapping'}
                open={addStreamMappingVisible}
                onOk={addStreamMapping}
                confirmLoading={loading}
                onCancel={hideAddStreamMapping}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                {addStreamMappingVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Stream"
                            style={{ marginBottom: 5 }}
                            name="stream"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group
                                onChange={(e) =>
                                    handleStreamChange(e.target.value)
                                }
                            >
                                {streamOptions()}
                            </Radio.Group>
                        </Form.Item>
                        {selectedStream ? (
                            <Form.Item
                                label="Level"
                                style={{ marginBottom: 0 }}
                                name="level"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Radio.Group placeholder="Select Level">
                                    {levelOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </Form>
                ) : null}
            </Modal>
            {whatsAppTemplatesModalVisible ? (
                <WhatsAppTemplates hideModal={hideWhatsAppTemplatesModal} />
            ) : null}
            <Modal
                title={'Stream Mappings'}
                open={streamMappingModalVisible}
                onCancel={hideStreamMappingModal}
                destroyOnClose={true}
                footer={null}
            >
                <h3>{selectedRecord.title}</h3>
                <div>
                    {selectedRecord.streamMappings ? (
                        <Table
                            columns={streamMappingTableColumns}
                            dataSource={selectedRecord.streamMappings}
                            pagination={false}
                            size="small"
                            loading={loading}
                            rowKey="uid"
                        />
                    ) : null}
                    <div style={{ marginTop: 10 }}>
                        {selectedRecord.streamMappings ? (
                            <Popconfirm
                                title={
                                    'Are you sure to ' +
                                    (selectedRecord.streamsEnabled
                                        ? 'Disable'
                                        : 'enable') +
                                    '?'
                                }
                                onConfirm={() =>
                                    enableDisableStreamMapping(selectedRecord)
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    size="small"
                                    type="link"
                                    style={{ marginRight: 10, padding: 0 }}
                                >
                                    <Tag
                                        color={
                                            selectedRecord.streamsEnabled
                                                ? 'geekblue'
                                                : 'magenta'
                                        }
                                    >
                                        {selectedRecord.streamsEnabled
                                            ? 'Enabled'
                                            : 'Disabled'}
                                    </Tag>
                                </Button>
                            </Popconfirm>
                        ) : null}
                        <Button
                            type="default"
                            size="small"
                            onClick={() => showAddStreamMapping(selectedRecord)}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Drip
