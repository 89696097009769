import React, { useRef, useState, useEffect } from 'react'
import { KeyboardArrowDown } from '@mui/icons-material'
import {
    Typography,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    CircularProgress,
    Divider,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SITE_SEARCH_TABS, searchFilters } from '../searchUtil'
import { searchStyles } from './styles'
import { actionTypes } from '../../../actions'
import HistoryCard from './HistoryCard'
import ResultCard from './ResultCard'
import SearchFilters from './SearchFilters'
import EmptyHistoryMessage from './EmptyHistoryMessage'
import SearchHeadingContainer from './SearchHeadingContainer'
import Globals from '../../../Globals'
import { RestApi } from '../../../RestApi'

const SearchModal = ({ open = true, onCloseSearchModal }) => {
    const { classes } = searchStyles()
    const dispatch = useDispatch()
    const [searchInput, setSearchInput] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [nextUrl, setNextUrl] = useState('')
    const [resultsLoading, setResultsLoading] = useState(false)
    const [moreResultsLoading, setMoreResultsLoading] = useState(false)
    const [showNoResultsError, setShowNoResultsError] = useState(false)
    const [selectedSearchFilter, setSelectedSearchFilter] = useState(
        SITE_SEARCH_TABS.FORUM
    )
    const userSearchHistory = useSelector((state) => state.siteSearchHistory)
    const scrollPosition = useSelector((state) => state.searchScrollPosition)
    const [prevScrollPosition, setPrevScrollPosition] = useState(scrollPosition)
    const searchResultsLength = searchResults.length
    const contentRef = useRef(null)

    useEffect(() => {
        if (open && prevScrollPosition > 0) {
            scrollToElement()
        }
        //eslint-disable-next-line
    }, [open])

    const scrollToElement = () => {
        setTimeout(() => {
            contentRef.current.scrollTop = prevScrollPosition
        }, 50)
    }

    const handleSearchInputChange = (e) => {
        showNoResultsError && setShowNoResultsError(false)
        setSearchInput(e.target.value)
    }

    const handleOnCloseSearchModal = () => {
        setPrevScrollPosition(contentRef.current.scrollTop)
        setScrollPosition()
        if (onCloseSearchModal) {
            onCloseSearchModal()
        }
    }

    const setScrollPosition = () => {
        dispatch({
            type: actionTypes.SET_SEARCH_SCROLL_POSITION,
            payload: contentRef.current.scrollTop,
        })
    }

    const handleSearchFilterChange = (e) => {
        setSelectedSearchFilter(e.target.value)
    }

    const updateUserSearchHistory = (userSearchInput) => {
        const index = userSearchHistory.indexOf(userSearchInput)

        if (index !== -1) {
            const updatedHistory = [
                userSearchInput,
                ...userSearchHistory.slice(0, index),
                ...userSearchHistory.slice(index + 1),
            ]
            return updatedHistory
        } else {
            const updatedHistory = [userSearchInput, ...userSearchHistory]
            return updatedHistory
        }
    }

    const onClickMoreResults = () => {
        if (!nextUrl) {
            return
        }

        setMoreResultsLoading(true)
        RestApi.doGet(Globals.URL.BASE_URL + nextUrl)
            .then((res) => {
                const updatedResponse = JSON.parse(res.data)
                const updatedResults = [
                    ...searchResults,
                    ...updatedResponse.data,
                ]
                setSearchResults(updatedResults)
                setNextUrl(updatedResponse.next)
            })
            .finally(() => setMoreResultsLoading(false))
    }

    const onClearSearchHistoryItem = (index) => {
        const updatedSearchItems = [...userSearchHistory]
        updatedSearchItems.splice(index, 1)
        dispatch({
            type: actionTypes.SET_USER_SEARCH_HISTORY,
            payload: updatedSearchItems,
        })
    }

    const onClickSearchHistoryItem = (item) => {
        setSearchInput(item)
        setShowNoResultsError(false)
        handleSearchSubmit(item)
    }

    const handleOnClearInput = () => {
        setSearchInput('')
        setSearchResults([])
        setNextUrl('')
        setShowNoResultsError(false)
    }

    const handleSearchSubmit = (userInput = searchInput) => {
        if (resultsLoading || moreResultsLoading) {
            return
        }
        const updatedSearchInput = userInput.trim()
        if (updatedSearchInput) {
            setResultsLoading(true)
            const encodedUserInput = encodeURIComponent(updatedSearchInput)
            RestApi.doGet(
                `${Globals.URL.SEARCH[selectedSearchFilter]}${encodedUserInput}`
            )
                .then((res) => {
                    const updatedResponse = JSON.parse(res.data)
                    setSearchResults(updatedResponse.data)
                    setNextUrl(updatedResponse.next)
                    if (updatedResponse.data.length > 0) {
                        setShowNoResultsError(false)
                        const updatedUserHistory =
                            updateUserSearchHistory(updatedSearchInput)
                        dispatch({
                            type: actionTypes.SET_USER_SEARCH_HISTORY,
                            payload: updatedUserHistory,
                        })
                    } else {
                        setShowNoResultsError(true)
                    }
                })
                .finally(() => setResultsLoading(false))
        }
    }

    return (
        <Dialog
            open={open}
            onClose={!resultsLoading && handleOnCloseSearchModal}
            maxWidth={'sm'}
            fullWidth
            className={classes.searchDialog}
            transitionDuration={400}
        >
            <DialogTitle
                sx={{
                    '.MuiDialogTitle-root&.MuiDialogTitle-root': {
                        pt: 1,
                        pb: 1,
                    },
                }}
            >
                <SearchHeadingContainer
                    searchInput={searchInput}
                    handleSearchInputChange={handleSearchInputChange}
                    searchResultsLength={searchResultsLength}
                    handleOnClearInput={handleOnClearInput}
                    resultsLoading={resultsLoading}
                    handleSearchSubmit={handleSearchSubmit}
                    moreResultsLoading={moreResultsLoading}
                    onClickClose={handleOnCloseSearchModal}
                    onFocus={!searchResults.length}
                />
                <Divider />
                {showNoResultsError && (
                    <Typography className={classes.noDataErr}>
                        No Data Found
                    </Typography>
                )}
                <SearchFilters
                    searchFilters={searchFilters}
                    handleSearchFilterChange={handleSearchFilterChange}
                    selectedSearchFilter={selectedSearchFilter}
                />
            </DialogTitle>
            <DialogContent
                ref={(ref) => {
                    contentRef.current = ref
                }}
                className={classes.customScrollbar}
            >
                <Box>
                    <Typography className={classes.filtersTxt}>
                        {searchResultsLength ? 'RESULTS' : 'RECENT'}
                    </Typography>
                    <Box className={classes.resultsContainer}>
                        {searchResultsLength ? (
                            <>
                                {searchResults.map((item, i) => (
                                    <>
                                        <ResultCard item={item} key={i} />
                                        {i !== searchResultsLength - 1 && (
                                            <Divider
                                                className={
                                                    classes.resultDivider
                                                }
                                            />
                                        )}
                                    </>
                                ))}
                            </>
                        ) : userSearchHistory.length ? (
                            <>
                                {userSearchHistory.map((item, i) => (
                                    <HistoryCard
                                        item={item}
                                        index={i}
                                        key={i}
                                        onClearSearchItem={
                                            onClearSearchHistoryItem
                                        }
                                        onClickSearchItem={
                                            onClickSearchHistoryItem
                                        }
                                    />
                                ))}
                            </>
                        ) : (
                            <EmptyHistoryMessage />
                        )}
                    </Box>
                    {nextUrl.length > 0 && (
                        <Box className={classes.moreBtnContainer}>
                            <Button
                                variant="standard"
                                className={classes.moreBtn}
                                endIcon={
                                    moreResultsLoading ? (
                                        <CircularProgress size={14} />
                                    ) : (
                                        <KeyboardArrowDown />
                                    )
                                }
                                onClick={onClickMoreResults}
                                disabled={moreResultsLoading}
                            >
                                More results
                            </Button>
                        </Box>
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default SearchModal
