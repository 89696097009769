import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
    Radio,
} from 'antd'
import { CopyOutlined, EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MetaFormItems from '../Common/MetaFormItems'
import { Link } from '@mui/material'
import { useParams } from 'react-router-dom'
import { goBack } from '../../Util/ILUtil'

function StreamPage(props) {
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedStreamCourse, setSelectedStreamCourse] = useState()
    const [pageId, setPageId] = useState(null)
    const [form] = Form.useForm()
    const { id: paramsId = '' } = useParams()
    const inputRef = useRef()

    useEffect(() => {
        setPageId(paramsId)
        getCourseAndLevel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (pageId == null) {
            return
        }

        getPages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageId])

    useEffect(() => {
        setTimeout(() => {
            if (addUpdateModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addUpdateModalVisible, inputRef])

    const getPages = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.STREAM_PAGE.GET_STREAM_PAGES + '?parentPageId=' + pageId
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCourseAndLevel = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER)
            .then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
                setLevelData(streamData[0].levels)
            })
            .catch((error) => {
                notification['error']({
                    message: error,
                })
            })
    }

    const showAddUpdateModal = (record = {}) => {
        setAddUpdateModalVisible(true)
        setSelectedRecord(record)

        if (record.pageId) {
            onSelectStreamCourse(record.stream.course)
            setTimeout(() => {
                var recordClone = clone(record)

                setSelectedStreamCourse(record.stream.course)
                recordClone.course = record.stream.course
                recordClone.level = record.stream.level
                form.setFieldsValue(recordClone)
                setFormFieldValues(recordClone)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
    }

    const addUpdatePage = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.parentPageId = pageId
                var filtered = values.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })

                var seo = {}
                seo.title = values.seoTitle ? values.seoTitle : ''
                seo.meta = filtered
                seo.bodyEnd = values.bodyEnd ? values.bodyEnd : ''

                values['seo'] = seo

                var url = GLOBAL.URL.STREAM_PAGE.ADD_STREAM_PAGE
                if (selectedRecord.pageId) {
                    url = GLOBAL.URL.STREAM_PAGE.UPDATE_STREAM_PAGE
                    values.pageId = selectedRecord.pageId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateModal()
                        getPages()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.STREAM_PAGE.ENABLE_STREAM_PAGE
        if (record.enabled) {
            url = GLOBAL.URL.STREAM_PAGE.DISABLE_STREAM_PAGE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ pageId: record.pageId }))

        RestApi.doPost(url, formData)
            .then((response) => {
                var fullData = data
                fullData.forEach((item) => {
                    if (item.pageId === record.pageId) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deletePage = (pageId) => {
        setLoading(true)

        var payload = {}
        payload.pageId = pageId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.STREAM_PAGE.DELETE_STREAM_PAGE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getPages()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'pageId',
            key: 'pageId',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 80,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => {
                        enableDisable(record)
                    }}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'pageTitle',
            key: 'pageTitle',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/streampage/' + record.pageId}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/streampage/' + record.pageId)
                        }}
                        underline="none"
                    >
                        <span>{record.pageTitle}</span>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Content',
            dataIndex: 'Content',
            key: 'Content',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/streampage/content/' + record.pageId}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(
                                '/streampage/content/' + record.pageId
                            )
                        }}
                        underline="none"
                    >
                        <span>Content</span>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Deep Link',
            dataIndex: 'deepLink',
            key: 'deepLink',
            render: (text, record) => (
                <span>
                    {record.deepLink ? (
                        <CopyToClipboard
                            text={record.deepLink}
                            onCopy={() =>
                                notification['success']({
                                    message: 'Link copied!',
                                })
                            }
                        >
                            <span>
                                <Button
                                    type="link"
                                    style={{ padding: 0, fontSize: 10 }}
                                >
                                    <CopyOutlined />
                                    {'Copy Link'}
                                </Button>
                            </span>
                        </CopyToClipboard>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'course',
            key: 'course',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.stream.course
                        ? record.stream.course + ' - ' + record.stream.level
                        : ''}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deletePage(record.pageId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const setFormFieldValues = (record) => {
        var metaArrayFields = [0]
        var meta = []
        var length = 1
        var seoObj = null
        if (record.details) {
            seoObj = JSON.parse(record.details).seo
            if (seoObj.meta) {
                var filtered = seoObj.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })

                if (filtered.length) {
                    metaArrayFields = []
                    meta = filtered
                    length = filtered.length

                    for (var k = 0; k < length; k++) {
                        metaArrayFields.push(k)
                    }
                }
            }
        }

        setTimeout(() => {
            form.setFieldsValue({
                meta: meta,
                seoTitle: seoObj ? seoObj.title : '',
                bodyEnd: seoObj ? seoObj.bodyEnd : '',
            })
        }, 100)
    }

    const onChangeStreamCourse = (event) => {
        onSelectStreamCourse(event.target.value)
    }

    const onSelectStreamCourse = (streamCourse) => {
        streamData.forEach((item) => {
            if (item.course === streamCourse) {
                setLevelData(item.levels)
                setSelectedStreamCourse(streamCourse)

                return
            }
        })
    }

    const getStreamCourseOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Title',
                dataIndex: 'pageTitle',
                key: 'pageTitle',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: data,
                updateUrl: GLOBAL.URL.STREAM_PAGE.REORDER_STREAM_PAGES,
                columns: columns,
            },
        })
    }

    return (
        <div>
            <h2>Course Guide Pages</h2>
            <div>
                {pageId ? (
                    <Button
                        onClick={() => {
                            goBack(props.navigate)
                        }}
                        size="small"
                        style={{ marginRight: 10 }}
                    >
                        Go Back
                    </Button>
                ) : null}
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddUpdateModal}
                >
                    Add
                </Button>
                <Button
                    type="default"
                    size="small"
                    onClick={reorder}
                    style={{ marginLeft: 10 }}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="pageId"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Page'}
                open={addUpdateModalVisible}
                onOk={addUpdatePage}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                okText={selectedRecord.pageId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                <Form
                    layout="horizontal"
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                    form={form}
                >
                    <Form.Item
                        label="Page Title"
                        style={{ marginBottom: 5 }}
                        name="pageTitle"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter page title',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        style={{ marginBottom: 5 }}
                        name="description"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Course"
                        style={{ marginBottom: 5 }}
                        name="course"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeStreamCourse}>
                            {getStreamCourseOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedStreamCourse ? (
                        <Form.Item
                            label="Level"
                            style={{ marginBottom: 5 }}
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{getStreamLevelOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="SEO: Title"
                        style={{ marginBottom: 5 }}
                        name="seoTitle"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input placeholder="SEO: Title" />
                    </Form.Item>
                    <Form.Item
                        label="SEO: Body End"
                        style={{ marginBottom: 0 }}
                        name="bodyEnd"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>
                    <h3 style={{ marginTop: 10 }}>SEO: Meta</h3>
                    <MetaFormItems />
                </Form>
            </Modal>
        </div>
    )
}

export default StreamPage
