import {
    Input,
    Button,
    Table,
    Popconfirm,
    Form,
    message,
    Modal,
    Radio,
    Select,
} from 'antd'
import {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    EditTwoTone,
} from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

let FILTER_GROUPS = 'groups'
let FILTER_PAGES = 'pages'

const FAQ = (props) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [selectedFilter, setSelectedFilter] = useState('')
    const [groupsData, setGroupsData] = useState([])
    const [pageNamesData, setPageNamesData] = useState([])
    const [filterValueSelected, setFilterValueSelected] = useState(false)
    const [selectedGroupId, setSelectedGroupId] = useState(0)
    const [selectedPageName, setSelectedPageName] = useState('')
    const [groupsModalVisible, setGroupsModalVisible] = useState(false)
    const [addGroupModalVisible, setAddGroupModalVisible] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState({})
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            if (
                (addEditModalVisible || addGroupModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, addGroupModalVisible, inputRef])

    const getFAQsData = (
        groupId = selectedGroupId,
        pageName = selectedPageName
    ) => {
        setLoading(true)

        var url =
            GLOBAL.URL.FAQ.GET_FAQS +
            '?groupId=' +
            groupId +
            '&pageName=' +
            pageName

        RestApi.doGet(url)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.FAQ.ENABLE_FAQ
        if (record.enabled) {
            url = GLOBAL.URL.FAQ.DISABLE_FAQ
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.destroy()
                message.success(res.data)

                var dataNew = data
                dataNew.forEach((item) => {
                    if (record.id === item.id) {
                        item.enabled = !record.enabled
                        return
                    }
                })

                setData(data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = () => {
        setAddEditModalVisible(true)
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const edit = (record = {}) => {
        setSelectedRecord(record)
        showAddEditModal()

        setTimeout(() => {
            form.setFieldsValue(record)
        }, 100)
    }

    const add = () => {
        setSelectedRecord({})
        form.resetFields()
        showAddEditModal()
    }

    const handleSubmit = () => {
        form.validateFields(['question', 'answer'])
            .then((values) => {
                setLoading(true)

                values.groupId = selectedGroupId
                values.pageName = selectedPageName

                var url = GLOBAL.URL.FAQ.ADD_FAQ
                if (selectedRecord.id) {
                    url = GLOBAL.URL.FAQ.UPDATE_FAQ
                    values['id'] = selectedRecord.id
                }

                let payload = new FormData()
                payload.append('payload', JSON.stringify(values))
                RestApi.doPost(url, payload)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideAddEditModal()
                        getFAQsData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteItem = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.FAQ.DELETE_FAQ, postBody)
            .then((response) => {
                setData(data.filter((key) => key.id !== id))

                message.destroy()
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Question',
                dataIndex: 'question',
                key: 'question',
            },
        ]

        var reorderData = []
        data.forEach((item) => {
            var row = {}
            row.id = item.id
            row.question = item.question

            reorderData.push(row)
        })

        props.navigate('/reorder', {
            state: {
                reorderData: reorderData,
                updateUrl: GLOBAL.URL.FAQ.REORDER_FAQ,
                columns: columns,
            },
        })
    }

    const onChangeTransactionType = (event) => {
        setSelectedFilter(event.target.value)
        setSelectedGroupId(0)
        setSelectedPageName('')
        setFilterValueSelected(false)
        if (event.target.value === FILTER_GROUPS && groupsData.length === 0) {
            getGroupsData()
        } else if (pageNamesData.length === 0) {
            getPageNamesData()
        }
    }

    const getGroupsData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.FAQ.GET_FAQ_GROUPS)
            .then((res) => {
                setGroupsData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getPageNamesData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.FAQ.GET_FAQ_PAGE_NAMES)
            .then((res) => {
                setPageNamesData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showGroupsModal = () => {
        setGroupsModalVisible(true)
    }

    const hideGroupsModal = () => {
        setGroupsModalVisible(false)
    }

    const showAddUpdateGroupModal = (record = {}) => {
        setAddGroupModalVisible(true)
        setSelectedGroup(record)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateGroupModal = () => {
        setAddGroupModalVisible(false)
    }

    const addOrUpdateGroup = () => {
        form.validateFields(['name'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.FAQ.ADD_FAQ_GROUP
                if (selectedGroup.id) {
                    url = GLOBAL.URL.FAQ.UPDATE_FAQ_GROUP
                    values.id = selectedGroup.id
                }

                let payload = new FormData()
                payload.append('payload', JSON.stringify(values))

                RestApi.doPost(url, payload)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideAddUpdateGroupModal()
                        getGroupsData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteGroup = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.FAQ.DELETE_FAQ_GROUP, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
                getGroupsData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const enableDisableGroup = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.FAQ.ENABLE_FAQ_GROUP
        if (record.enabled) {
            url = GLOBAL.URL.FAQ.DISABLE_FAQ_GROUP
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.destroy()
                message.success(res.data)

                var data = groupsData
                data.forEach((item) => {
                    if (record.id === item.id) {
                        item.enabled = !record.enabled
                        return
                    }
                })

                setGroupsData(data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorderGroups = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: groupsData,
                updateUrl: GLOBAL.URL.FAQ.REORDER_FAQ_GROUP,
                columns: columns,
            },
        })
    }

    const filterChanged = (groupId, pageName) => {
        setFilterValueSelected(true)
        setSelectedGroupId(groupId)
        setSelectedPageName(pageName)
        setData([])
        getFAQsData(groupId, pageName)
    }

    const { TextArea } = Input
    const { Option } = Select

    var groupsOptions = []
    groupsData.forEach((item) => {
        groupsOptions.push(
            <Option value={item.id} key={item.id}>
                {item.name}
            </Option>
        )
    })

    var pageNamesOptions = []
    pageNamesData.forEach((item) => {
        pageNamesOptions.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    const groupsColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 75,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                    }}
                    onClick={() => enableDisableGroup(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddUpdateGroupModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <span style={{ marginLeft: 3 }}>{record.name}</span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteGroup(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const columns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 75,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{ border: 'none', padding: 0 }}
                    onClick={() => {
                        enableDisable(record)
                    }}
                >
                    {record.enabled ? (
                        <CheckCircleTwoTone style={{ fontSize: 18 }} />
                    ) : (
                        <CloseCircleTwoTone
                            twoToneColor="#eb2f96"
                            style={{ fontSize: 18 }}
                        />
                    )}
                </Button>
            ),
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
        },
        {
            title: 'Answer',
            dataIndex: 'answer',
            key: 'answer',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ paddingLeft: 0 }}
                        onClick={() => edit(record)}
                    >
                        Edit
                    </Button>{' '}
                    |
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => {
                            deleteItem(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <div>
            <h3>Frequently Asked Questions</h3>
            <div>
                <span>Select Filter: </span>
                <Radio.Group onChange={onChangeTransactionType}>
                    <Radio.Button value={FILTER_GROUPS}>Groups</Radio.Button>
                    <Radio.Button value={FILTER_PAGES}>Pages</Radio.Button>
                </Radio.Group>
            </div>
            {selectedFilter ? (
                <div style={{ marginTop: 10 }}>
                    {selectedFilter === FILTER_GROUPS ? (
                        <div>
                            <Select
                                placeholder="Select Group"
                                style={{ width: 400 }}
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={(groupId) =>
                                    filterChanged(groupId, '')
                                }
                            >
                                {groupsOptions}
                            </Select>
                            <Button
                                type="default"
                                style={{ marginLeft: 10 }}
                                onClick={() => showGroupsModal()}
                            >
                                Manage Groups
                            </Button>
                        </div>
                    ) : (
                        <Select
                            placeholder="Select Page"
                            style={{ width: 400 }}
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={(pageName) => filterChanged(0, pageName)}
                        >
                            {pageNamesOptions}
                        </Select>
                    )}
                </div>
            ) : null}
            {filterValueSelected ? (
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        onClick={() => add()}
                        size="small"
                        loading={loading}
                    >
                        Add FAQ
                    </Button>
                    <Button
                        size="small"
                        style={{
                            marginLeft: 10,
                        }}
                        loading={loading}
                        onClick={reorder}
                    >
                        Reorder FAQs
                    </Button>
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        size="small"
                        loading={loading}
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                </div>
            ) : null}
            <Modal
                title="FAQ"
                open={addEditModalVisible}
                onOk={() => {
                    handleSubmit()
                }}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                width={1000}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Question"
                        name="question"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter question!',
                            },
                        ]}
                    >
                        <TextArea rows={3} autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Answer"
                        name="answer"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter answer!',
                            },
                        ]}
                    >
                        <TextArea rows={3} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Groups"
                open={groupsModalVisible}
                onCancel={hideGroupsModal}
                footer={null}
                destroyOnClose={true}
            >
                <Button
                    type="primary"
                    onClick={() => showAddUpdateGroupModal()}
                    size="small"
                    loading={loading}
                >
                    Add Group
                </Button>
                <Button
                    type="default"
                    onClick={() => reorderGroups()}
                    size="small"
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Reorder
                </Button>
                <Table
                    columns={groupsColumns}
                    dataSource={groupsData}
                    pagination={false}
                    size="small"
                    loading={loading}
                    style={{ marginTop: 10 }}
                    rowKey="id"
                />
            </Modal>
            <Modal
                title="Configure Group"
                open={addGroupModalVisible}
                onOk={() => {
                    addOrUpdateGroup()
                }}
                confirmLoading={loading}
                onCancel={hideAddUpdateGroupModal}
                okText={selectedGroup.id ? 'Update' : 'Add'}
                width={800}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter name!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default FAQ
