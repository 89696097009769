import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    message,
    DatePicker,
    Radio,
    Checkbox,
    Select,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import clone from 'clone'
import { EditTwoTone, CopyTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'

const FILTER_OPEN = 'open'
const FILTER_ALL = 'all'

function LiveClassSchedule() {
    const [form] = Form.useForm()
    const { TextArea } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [schedule, setSchedule] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [filtersModalVisible, setFiltersModalVisible] = useState(false)
    const [scheduledTime, setScheduledTime] = useState()
    const [classOnTypes, setClassOnTypes] = useState([])
    const [paidUsers, setPaidUsers] = useState(false)
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [streams, setStreams] = useState([])
    const [filter, setFilter] = useState(FILTER_OPEN)
    const inputRef = useRef()
    useEffect(() => {
        getScheduleList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getScheduleList = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.LIVE_CLASS_SCHEDULE.GET_SCHEDULE + '?filter=' + filter
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setSchedule(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageSchedule = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setSchedule((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        populateFormFieldsAndShowAddEditModal(record)
    }

    const populateFormFieldsAndShowAddEditModal = (record) => {
        setAddEditModalVisible(true)
        if (record.uid) {
            setScheduledTime(record.scheduledTime)
            setPaidUsers(record.paidUsers)
            var clonedRecord = clone(record)

            var scheduledTime = dayjs(record.scheduledTime, 'DD-MMM-YYYY HH:mm')
            clonedRecord.scheduledTime = scheduledTime

            setTimeout(() => {
                form.setFieldsValue(clonedRecord)
            }, 200)
        } else {
            form.resetFields()
        }

        if (classOnTypes.length === 0) {
            RestApi.doGet(
                GLOBAL.URL.LIVE_CLASS_SCHEDULE.GET_CLASS_ON_TYPE
            ).then((res) => {
                setClassOnTypes(res.data)
            })
        }

        if (courseNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_ACCESS_COURSE_NAMES).then(
                (res) => {
                    setCourseNames(res.data)
                }
            )
        }

        if (attempts.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
                var options = []
                res.data.forEach((item) => {
                    options.push(item.attempt)
                })
                setAttempts(options)
            })
        }

        if (streams.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_NAMES_LIST).then((res) => {
                setStreams(res.data)
            })
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
        setSelectedRecord({})
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onLinkCopy = () => {
        message.success('Link copied!')
    }

    const showFiltersModal = (record) => {
        setSelectedRecord(record)
        setFiltersModalVisible(true)
    }

    const hideFiltersModal = () => {
        setFiltersModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 75,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        enableDisable(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    <strong>{record.title}</strong>
                    <br />
                    <span style={{ fontSize: 12 }}>{record.description}</span>
                </span>
            ),
        },
        {
            title: 'Schedule',
            dataIndex: 'scheduledTime',
            key: 'scheduledTime',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.scheduledTime}</span>
            ),
        },
        {
            title: 'On',
            dataIndex: 'classOn',
            key: 'classOn',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.classOn}</span>
            ),
        },
        {
            title: 'Target URL',
            dataIndex: 'targetUrl',
            key: 'targetUrl',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.targetUrl ? (
                        <>
                            <CopyToClipboard
                                text={record.targetUrl}
                                onCopy={onLinkCopy}
                            >
                                <Button type="link">
                                    <CopyTwoTone />
                                </Button>
                            </CopyToClipboard>
                            <a
                                href={record.targetUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Open
                            </a>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Paid Users',
            dataIndex: 'paidUsers',
            key: 'paidUsers',
            width: 100,
            render: (text, record) => (
                <span>
                    {record.paidUsers ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </span>
            ),
        },
        {
            title: 'Filters',
            dataIndex: 'filters',
            key: 'filters',
            width: 100,
            render: (text, record) => (
                <span>
                    {record.paidUsers ? (
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12 }}
                            onClick={() => showFiltersModal(record)}
                        >
                            Filters
                        </Button>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Updated By',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}
                    <br></br>
                    <span style={{ fontSize: 10 }}>{record.updatedAt}</span>
                </span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 75,
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => deleteSchedule(record.uid)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                    <Button
                        type="link"
                        onClick={() =>
                            populateFormFieldsAndShowAddEditModal(record)
                        }
                    >
                        <CopyTwoTone />
                    </Button>
                </>
            ),
        },
    ]

    const getFilterCourseNames = () => {
        var options = []

        selectedRecord.courseNames.forEach((element) => {
            options.push(<li>{element}</li>)
        })

        return options
    }

    const getFilterAttempts = () => {
        var options = []

        selectedRecord.attempts.forEach((element) => {
            options.push(<li>{element}</li>)
        })

        return options
    }

    const getFilterStreams = () => {
        var options = []

        selectedRecord.streams.forEach((element) => {
            options.push(<li>{element}</li>)
        })

        return options
    }

    const onChangeScheduledTime = (value, dateString) => {
        setScheduledTime(dateString)
    }

    const classOnOptions = () => {
        var options = []
        classOnTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onPaidUsersOptionChange = (e) => {
        setPaidUsers(e.target.checked)
    }

    const courseOptions = () => {
        var options = []
        courseNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const attemptOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const streamOptions = () => {
        var options = []
        streams.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const addOrUpdateSchedule = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.LIVE_CLASS_SCHEDULE.ADD_SCHEDULE
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.LIVE_CLASS_SCHEDULE.UPDATE_SCHEDULE
                    values.uid = selectedRecord.uid
                }

                values.scheduledTime = scheduledTime

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getScheduleList()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const onChangeFilterType = (e) => {
        setFilter(e.target.value)
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.LIVE_CLASS_SCHEDULE.ENABLE_SCHEDULE
        if (record.enabled) {
            url = GLOBAL.URL.LIVE_CLASS_SCHEDULE.DISABLE_SCHEDULE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(schedule)
                fullData.forEach((item) => {
                    if (item.uid === record.uid) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setSchedule(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteSchedule = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LIVE_CLASS_SCHEDULE.DELETE_SCHEDULE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getScheduleList()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Live Classes Schedule</h2>
            <div>
                <Radio.Group
                    onChange={onChangeFilterType}
                    defaultValue={filter}
                >
                    <Radio.Button value={FILTER_OPEN}>Open</Radio.Button>
                    <Radio.Button value={FILTER_ALL}>All</Radio.Button>
                </Radio.Group>
                <Button
                    type="primary"
                    onClick={showAddEditModal}
                    style={{ marginLeft: 20 }}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={schedule}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageSchedule}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title="Filters"
                open={filtersModalVisible}
                onCancel={hideFiltersModal}
                footer={null}
            >
                {selectedRecord.courseNames &&
                selectedRecord.courseNames.length ? (
                    <>
                        <h3>Courses:</h3>
                        <ol>{getFilterCourseNames()}</ol>
                    </>
                ) : null}
                {selectedRecord.attempts && selectedRecord.attempts.length ? (
                    <>
                        <h3>Attempts:</h3>
                        <ol>{getFilterAttempts()}</ol>
                    </>
                ) : null}
                {selectedRecord.streams && selectedRecord.streams.length ? (
                    <>
                        <h3>Course Streams:</h3>
                        <ol>{getFilterStreams()}</ol>
                    </>
                ) : null}
            </Modal>
            <Modal
                title={'Configure Class Schedule'}
                open={addEditModalVisible}
                onOk={addOrUpdateSchedule}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={900}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    initialValues={{ paidUsers: false }}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Schedule Time"
                        name="scheduledTime"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <DatePicker
                            showTime
                            placeholder="Select"
                            format="DD-MMM-YYYY HH:mm"
                            onChange={onChangeScheduledTime}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Class On"
                        name="classOn"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{classOnOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Target URL"
                        name="targetUrl"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Paid Users?"
                        name="paidUsers"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox onChange={onPaidUsersOptionChange}></Checkbox>
                    </Form.Item>
                    {paidUsers ? (
                        <>
                            <Form.Item
                                label="Courses"
                                name="courseNames"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select Courses"
                                    showSearch
                                    mode="multiple"
                                >
                                    {courseOptions()}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Attempts"
                                name="attempts"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select attempts"
                                    showSearch
                                    mode="multiple"
                                >
                                    {attemptOptions()}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Streams"
                                name="streams"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select"
                                    showSearch
                                    mode="multiple"
                                >
                                    {streamOptions()}
                                </Select>
                            </Form.Item>
                        </>
                    ) : null}
                </Form>
            </Modal>
        </div>
    )
}

export default LiveClassSchedule
