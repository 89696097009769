import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, Select, Radio, notification } from 'antd'
import { makeStyles } from 'tss-react/mui'
import { LoadingOutlined } from '@ant-design/icons'

const FILTER_LEADS_CURRENT_STATUS = 'FILTER_LEADS_CURRENT_STATUS'
const FILTER_EXPORT_UTM_LEADS = 'FILTER_EXPORT_UTM_LEADS'
const FILTER_EXPORT_UTM_LEADS_BY_TRANSACTIONS =
    'FILTER_EXPORT_UTM_LEADS_BY_TRANSACTIONS'

const FILTER_SOURCE_USER_SIGN_UP_OS = 'User_SignUp_OS'
const FILTER_SOURCE_LEAD_TYPE = 'Lead_Type'

function LeadsCurrentStatus() {
    const { classes } = useStyles()

    const { RangePicker } = DatePicker
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [utmSourceLoading, setUtmSourceLoading] = useState(false)
    const [utmMediumLoading, setUtmMediumLoading] = useState(false)
    const [utmCampaignLoading, setUtmCampaignLoading] = useState(false)
    const [utmTermLoading, setUtmTermLoading] = useState(false)
    const [filter, setFilter] = useState(FILTER_LEADS_CURRENT_STATUS)

    const [data, setData] = useState()
    const [leadTypes, setLeadTypes] = useState([])
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [utmSources, setUtmSources] = useState([])
    const [utmMediums, setUtmMediums] = useState([])
    const [utmCampaigns, setUtmCampaigns] = useState([])
    const [utmTerms, setUtmTerms] = useState([])

    const [isWaterfallModel, setIsWaterfallModel] = useState(true)
    const [source, setSource] = useState(FILTER_SOURCE_LEAD_TYPE)
    const [userSignUpOS, setUserSignUpOS] = useState()
    const [leadType, setLeadType] = useState()
    const [stream, setStream] = useState('')
    const [level, setLevel] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [adsOnly, setAdsOnly] = useState('no')
    const [adsOs, setAdsOs] = useState()
    const [adsUtmSource, setAdsUtmSource] = useState()
    const [adsUtmMedium, setAdsUtmMedium] = useState()
    const [adsUtmCampaign, setAdsUtmCampaign] = useState()
    const [adsUtmTerm, setAdsUtmTerm] = useState()

    const dateFormat = 'DD-MMM-YYYY'

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!adsOs) {
            return
        }

        getUtmSources()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adsOs])

    useEffect(() => {
        if (!adsUtmSource) {
            return
        }

        getUtmMediums()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adsUtmSource])

    useEffect(() => {
        if (!adsUtmMedium) {
            return
        }

        getUtmCampaigns()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adsUtmMedium])

    useEffect(() => {
        if (!adsUtmCampaign) {
            return
        }

        getUtmTerms()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adsUtmCampaign])

    const getData = () => {
        setLoading(true)

        var payload = {}
        payload.isWaterfallModel = isWaterfallModel
        payload.source = source
        payload.userSignUpOS = userSignUpOS
            ? userSignUpOS === 'all'
                ? ''
                : userSignUpOS
            : ''
        payload.leadType = leadType
        payload.startDate = startDate
        payload.endDate = endDate
        payload.adsOnly = adsOnly
        payload.adsOs = adsOs ? (adsOs === 'all' ? '' : adsOs) : ''
        payload.adsUtmSource = adsUtmSource
        payload.adsUtmMedium = adsUtmMedium
        payload.adsUtmCampaign = adsUtmCampaign
        payload.adsUtmTerm = adsUtmTerm
        payload.stream = stream
        payload.level = level

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.GET_LEADS_CURRENT_STATUS, formData)
            .then((response) => {
                setData(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getFilters = () => {
        RestApi.doGet(GLOBAL.URL.GET_ENABLED_CART_LEAD_TYPES).then((res) => {
            setLeadTypes(res.data)
        })
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getLeadTypeOptions = () => {
        var options = []
        leadTypes.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const onChangeAdsOnly = (event) => {
        setAdsOnly(event.target.value)
    }

    const onChangeAdsOs = (event) => {
        setAdsOs(event.target.value)
    }

    const onChangeUserSignupOS = (event) => {
        setUserSignUpOS(event.target.value)
        getStreamData()
    }

    const clearUtmSource = () => {
        setUtmMediums([])
        setUtmCampaigns([])
        setUtmTerms([])

        setAdsUtmSource()
        setAdsUtmMedium()
        setAdsUtmCampaign()
        setAdsUtmTerm()
    }

    const getUtmSources = () => {
        clearUtmSource()
        setUtmSources([])
        setUtmSourceLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate
        payload.os = adsOs ? (adsOs === 'all' ? '' : adsOs) : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.GET_UTM_SOURCES, formData)
            .then((response) => {
                setUtmSources(response.data)
            })
            .finally(() => {
                setUtmSourceLoading(false)
            })
    }

    const getUtmSourceOptions = () => {
        var options = []
        utmSources.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const getUtmMediumOptions = () => {
        var options = []
        utmMediums.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const getUtmCampaignOptions = () => {
        var options = []
        utmCampaigns.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const getUtmTermOptions = () => {
        var options = []
        utmTerms.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const clearUtmMedium = () => {
        setUtmCampaigns([])
        setUtmTerms([])

        setAdsUtmMedium()
        setAdsUtmCampaign()
        setAdsUtmTerm()
    }

    const getUtmMediums = () => {
        clearUtmMedium()
        setUtmMediums([])
        setUtmMediumLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate
        payload.os = adsOs ? (adsOs === 'all' ? '' : adsOs) : ''
        payload.utmSource = adsUtmSource

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.GET_UTM_MEDIUMS, formData)
            .then((response) => {
                setUtmMediums(response.data)
            })
            .finally(() => {
                setUtmMediumLoading(false)
            })
    }

    const clearUtmCampaign = () => {
        setUtmTerms([])

        setAdsUtmCampaign()
        setAdsUtmTerm()
    }

    const getUtmCampaigns = () => {
        clearUtmCampaign()
        setUtmCampaigns([])
        setUtmCampaignLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate
        payload.os = adsOs ? (adsOs === 'all' ? '' : adsOs) : ''
        payload.utmSource = adsUtmSource
        payload.utmMedium = adsUtmMedium

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.GET_UTM_CAMPAIGNS, formData)
            .then((response) => {
                setUtmCampaigns(response.data)
            })
            .finally(() => {
                setUtmCampaignLoading(false)
            })
    }

    const clearUtmTerm = () => {
        setAdsUtmTerm()
    }

    const getUtmTerms = () => {
        clearUtmTerm()
        setUtmTerms([])
        setUtmTermLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate
        payload.os = adsOs ? (adsOs === 'all' ? '' : adsOs) : ''
        payload.utmSource = adsUtmSource
        payload.utmMedium = adsUtmMedium
        payload.utmCampaign = adsUtmCampaign

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.GET_UTM_TERMS, formData)
            .then((response) => {
                setUtmTerms(response.data)
            })
            .finally(() => {
                setUtmTermLoading(false)
            })
    }

    const onChangeFilter = (e) => {
        setFilter(e.target.value)
        setStartDate()
        setEndDate()
    }

    const downloadUTMLeads = () => {
        setLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate
        payload.adsOnly = adsOnly

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.LEADS.DOWNLOAD_UTM_LEADS_REPORT, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const downloadUTMLeadsByTransactions = () => {
        setLoading(true)

        var payload = {}
        payload.startDate = startDate
        payload.endDate = endDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.LEADS.DOWNLOAD_UTM_LEADS_REPORT_BY_TRANSACTIONS,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeIsWaterfallModel = (event) => {
        setIsWaterfallModel(event.target.value)
    }

    const onChangeSourceFilter = (event) => {
        setSource(event.target.value)
    }

    const onChangeLeadType = (value) => {
        setLeadType(value ? value : '')
        getStreamData()
    }

    const getStreamData = () => {
        if (streamData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
                setStreamData(JSON.parse(res.data))
            })
        }
    }

    const handleStreamChange = (value) => {
        if (!value) {
            setLevelData([])
            setStream('')

            return
        } else {
            streamData.forEach((item) => {
                if (item.course === value) {
                    setLevelData(item.levels)
                    setStream(value)
                }
            })
        }

        setLevel('')
    }

    const handleLevelChange = (value) => {
        setLevel(value)
    }

    const streamOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={''} key={'All'}>
                {'All'}
            </Radio.Button>
        )

        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={''} key={'All'}>
                {'All'}
            </Radio.Button>
        )

        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    return (
        <div>
            <div>
                <Radio.Group
                    onChange={onChangeFilter}
                    value={filter}
                    style={{ marginBottom: 10 }}
                >
                    <Radio.Button
                        value={FILTER_LEADS_CURRENT_STATUS}
                        key={FILTER_LEADS_CURRENT_STATUS}
                    >
                        Leads Current Status
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_EXPORT_UTM_LEADS}
                        key={FILTER_EXPORT_UTM_LEADS}
                    >
                        Download UTM Leads
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_EXPORT_UTM_LEADS_BY_TRANSACTIONS}
                        key={FILTER_EXPORT_UTM_LEADS_BY_TRANSACTIONS}
                    >
                        Download UTM Leads by Transactions
                    </Radio.Button>
                </Radio.Group>
            </div>
            {filter === FILTER_LEADS_CURRENT_STATUS ? (
                <>
                    <h2>Leads Current Status</h2>
                    <div>
                        <span className={classes.inputLabel}>Waterfall</span>
                        <Radio.Group
                            onChange={onChangeIsWaterfallModel}
                            value={isWaterfallModel}
                        >
                            <Radio.Button value={true} key={'Yes'}>
                                Yes
                            </Radio.Button>
                            <Radio.Button value={false} key={'No'}>
                                No
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span className={classes.inputLabel}>Source</span>
                        <Radio.Group
                            onChange={onChangeSourceFilter}
                            value={source}
                        >
                            <Radio.Button
                                value={FILTER_SOURCE_LEAD_TYPE}
                                key={FILTER_SOURCE_LEAD_TYPE}
                            >
                                Lead Type
                            </Radio.Button>
                            <Radio.Button
                                value={FILTER_SOURCE_USER_SIGN_UP_OS}
                                key={FILTER_SOURCE_USER_SIGN_UP_OS}
                            >
                                Sign Up Source
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    {source === FILTER_SOURCE_LEAD_TYPE ? (
                        <div style={{ marginTop: 10 }}>
                            <span className={classes.inputLabel}>
                                Lead Type
                            </span>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                                onChange={onChangeLeadType}
                            >
                                {getLeadTypeOptions()}
                            </Select>
                        </div>
                    ) : (
                        <div style={{ marginTop: 10 }}>
                            <span className={classes.inputLabel}>
                                Sign Up OS
                            </span>
                            <Radio.Group
                                onChange={onChangeUserSignupOS}
                                value={userSignUpOS}
                            >
                                <Radio.Button value={'all'} key={'all'}>
                                    All
                                </Radio.Button>
                                <Radio.Button value={'web'} key={'web'}>
                                    Web
                                </Radio.Button>
                                <Radio.Button value={'android'} key={'android'}>
                                    Android
                                </Radio.Button>
                                <Radio.Button value={'ios'} key={'ios'}>
                                    iOS
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    )}
                    {leadType || userSignUpOS ? (
                        <>
                            {isWaterfallModel ? (
                                <>
                                    <div style={{ marginTop: 10 }}>
                                        <span className={classes.inputLabel}>
                                            Stream
                                        </span>
                                        <Radio.Group
                                            onChange={(e) =>
                                                handleStreamChange(
                                                    e.target.value
                                                )
                                            }
                                            value={stream}
                                        >
                                            {streamOptions()}
                                        </Radio.Group>
                                    </div>
                                    {stream ? (
                                        <div style={{ marginTop: 10 }}>
                                            <span
                                                className={classes.inputLabel}
                                            >
                                                Level
                                            </span>
                                            <Radio.Group
                                                onChange={(e) =>
                                                    handleLevelChange(
                                                        e.target.value
                                                    )
                                                }
                                                value={level}
                                            >
                                                {levelOptions()}
                                            </Radio.Group>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            <div style={{ marginTop: 10 }}>
                                <span className={classes.inputLabel}>
                                    Date Range
                                </span>
                                <RangePicker
                                    format={dateFormat}
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateChange}
                                />
                            </div>
                            {startDate && endDate ? (
                                <>
                                    <div style={{ marginTop: 10 }}>
                                        <span className={classes.inputLabel}>
                                            Ad Leads Only
                                        </span>
                                        <Radio.Group
                                            onChange={onChangeAdsOnly}
                                            value={adsOnly}
                                        >
                                            <Radio.Button
                                                value={'no'}
                                                key={'no'}
                                            >
                                                No
                                            </Radio.Button>
                                            <Radio.Button
                                                value={'yes'}
                                                key={'yes'}
                                            >
                                                Yes
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    {adsOnly === 'yes' ? (
                                        <>
                                            <div style={{ marginTop: 10 }}>
                                                <span
                                                    className={
                                                        classes.inputLabel
                                                    }
                                                >
                                                    OS
                                                </span>
                                                <Radio.Group
                                                    onChange={onChangeAdsOs}
                                                    value={adsOs}
                                                >
                                                    <Radio.Button
                                                        value={'all'}
                                                        key={'all'}
                                                    >
                                                        All
                                                    </Radio.Button>
                                                    <Radio.Button
                                                        value={'web'}
                                                        key={'web'}
                                                    >
                                                        Web
                                                    </Radio.Button>
                                                    <Radio.Button
                                                        value={'android'}
                                                        key={'android'}
                                                    >
                                                        Android
                                                    </Radio.Button>
                                                    <Radio.Button
                                                        value={'ios'}
                                                        key={'ios'}
                                                    >
                                                        iOS
                                                    </Radio.Button>
                                                </Radio.Group>
                                            </div>
                                            {adsOs ? (
                                                <>
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <span
                                                            className={
                                                                classes.inputLabel
                                                            }
                                                        >
                                                            UTM Source
                                                        </span>
                                                        <Select
                                                            showSearch
                                                            allowClear={true}
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option.props.children
                                                                    .toLowerCase()
                                                                    .indexOf(
                                                                        input.toLowerCase()
                                                                    ) >= 0
                                                            }
                                                            style={{
                                                                width: 350,
                                                            }}
                                                            onChange={(e) => {
                                                                setAdsUtmSource(
                                                                    e ? e : ''
                                                                )
                                                                if (!e) {
                                                                    clearUtmSource()
                                                                }
                                                            }}
                                                        >
                                                            {getUtmSourceOptions()}
                                                        </Select>
                                                        {utmSourceLoading ? (
                                                            <LoadingOutlined
                                                                style={{
                                                                    fontSize: 20,
                                                                    marginLeft: 10,
                                                                }}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    {adsUtmSource ? (
                                                        <>
                                                            <div
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                <span
                                                                    className={
                                                                        classes.inputLabel
                                                                    }
                                                                >
                                                                    UTM Medium
                                                                </span>
                                                                <Select
                                                                    showSearch
                                                                    allowClear={
                                                                        true
                                                                    }
                                                                    filterOption={(
                                                                        input,
                                                                        option
                                                                    ) =>
                                                                        option.props.children
                                                                            .toLowerCase()
                                                                            .indexOf(
                                                                                input.toLowerCase()
                                                                            ) >=
                                                                        0
                                                                    }
                                                                    style={{
                                                                        width: 350,
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setAdsUtmMedium(
                                                                            e
                                                                                ? e
                                                                                : ''
                                                                        )
                                                                        if (
                                                                            !e
                                                                        ) {
                                                                            clearUtmMedium()
                                                                        }
                                                                    }}
                                                                >
                                                                    {getUtmMediumOptions()}
                                                                </Select>
                                                                {utmMediumLoading ? (
                                                                    <LoadingOutlined
                                                                        style={{
                                                                            fontSize: 20,
                                                                            marginLeft: 10,
                                                                        }}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                            {adsUtmMedium ? (
                                                                <>
                                                                    <div
                                                                        style={{
                                                                            marginTop: 10,
                                                                        }}
                                                                    >
                                                                        <span
                                                                            className={
                                                                                classes.inputLabel
                                                                            }
                                                                        >
                                                                            UTM
                                                                            Campaign
                                                                        </span>
                                                                        <Select
                                                                            showSearch
                                                                            allowClear={
                                                                                true
                                                                            }
                                                                            filterOption={(
                                                                                input,
                                                                                option
                                                                            ) =>
                                                                                option.props.children
                                                                                    .toLowerCase()
                                                                                    .indexOf(
                                                                                        input.toLowerCase()
                                                                                    ) >=
                                                                                0
                                                                            }
                                                                            style={{
                                                                                width: 350,
                                                                            }}
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setAdsUtmCampaign(
                                                                                    e
                                                                                        ? e
                                                                                        : ''
                                                                                )
                                                                                if (
                                                                                    !e
                                                                                ) {
                                                                                    clearUtmCampaign()
                                                                                }
                                                                            }}
                                                                        >
                                                                            {getUtmCampaignOptions()}
                                                                        </Select>
                                                                        {utmCampaignLoading ? (
                                                                            <LoadingOutlined
                                                                                style={{
                                                                                    fontSize: 20,
                                                                                    marginLeft: 10,
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                    </div>
                                                                    {adsUtmCampaign ? (
                                                                        <>
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 10,
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    className={
                                                                                        classes.inputLabel
                                                                                    }
                                                                                >
                                                                                    UTM
                                                                                    Term
                                                                                </span>
                                                                                <Select
                                                                                    showSearch
                                                                                    allowClear={
                                                                                        true
                                                                                    }
                                                                                    filterOption={(
                                                                                        input,
                                                                                        option
                                                                                    ) =>
                                                                                        option.props.children
                                                                                            .toLowerCase()
                                                                                            .indexOf(
                                                                                                input.toLowerCase()
                                                                                            ) >=
                                                                                        0
                                                                                    }
                                                                                    style={{
                                                                                        width: 350,
                                                                                    }}
                                                                                    onChange={(
                                                                                        e
                                                                                    ) => {
                                                                                        setAdsUtmTerm(
                                                                                            e
                                                                                                ? e
                                                                                                : ''
                                                                                        )
                                                                                        if (
                                                                                            !e
                                                                                        ) {
                                                                                            clearUtmTerm()
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    {getUtmTermOptions()}
                                                                                </Select>
                                                                                {utmTermLoading ? (
                                                                                    <LoadingOutlined
                                                                                        style={{
                                                                                            fontSize: 20,
                                                                                            marginLeft: 10,
                                                                                        }}
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        </>
                                                                    ) : null}
                                                                </>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                    <div style={{ marginTop: 10 }}>
                                        <Button
                                            type="primary"
                                            onClick={getData}
                                            loading={loading}
                                            style={{ marginLeft: 120 }}
                                        >
                                            Fetch
                                        </Button>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : null}
                    {data ? (
                        <div
                            style={{ marginTop: 10 }}
                            dangerouslySetInnerHTML={{ __html: data }}
                        />
                    ) : null}
                </>
            ) : filter === FILTER_EXPORT_UTM_LEADS ? (
                <>
                    <div style={{ marginTop: 10 }}>
                        <span className={classes.inputLabel}>Date Range</span>
                        <RangePicker
                            format={dateFormat}
                            placeholder={['Start Date', 'End Date']}
                            onChange={onDateChange}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span className={classes.inputLabel}>
                            Ad Leads Only
                        </span>
                        <Radio.Group onChange={onChangeAdsOnly} value={adsOnly}>
                            <Radio.Button value={'no'} key={'no'}>
                                No
                            </Radio.Button>
                            <Radio.Button value={'yes'} key={'yes'}>
                                Yes
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            onClick={downloadUTMLeads}
                            loading={loading}
                            style={{ marginLeft: 120 }}
                        >
                            Download
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ marginTop: 10 }}>
                        <span className={classes.inputLabel}>Date Range</span>
                        <RangePicker
                            format={dateFormat}
                            placeholder={['Start Date', 'End Date']}
                            onChange={onDateChange}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            onClick={downloadUTMLeadsByTransactions}
                            loading={loading}
                            style={{ marginLeft: 120 }}
                        >
                            Download
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

const useStyles = makeStyles()((theme) => ({
    inputLabel: {
        width: 110,
        textAlign: 'right',
        display: 'inline-block',
        fontWeight: 'bold',
        marginRight: 10,
    },
}))

export default LeadsCurrentStatus
