import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Checkbox,
    InputNumber,
    Radio,
    Select,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'
import { Link } from '@mui/material'

function CourseTests(props) {
    const [form] = Form.useForm()
    const { Search } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [timedTest, setTimedTest] = useState(false)
    const [random, setRandom] = useState(false)
    const [randomConfigModalVisible, setRandomConfigModalVisible] =
        useState(false)
    const [randomTestConfigList, setRandomTestConfigList] = useState([])
    const [selectedRandomConfig, setSelectedRandomConfig] = useState({})
    const [
        addUpdateRandomConfigModalVisible,
        setAddUpdateRandomConfigModalVisible,
    ] = useState(false)
    const [difficultyData, setDifficultyData] = useState([])
    const [sectionsModalVisible, setSectionsModalVisible] = useState(false)
    const [sectionsData, setSectionsData] = useState([])
    const [loadingSections, setLoadingSections] = useState(false)
    const [addUpdateSectionModaVisible, setAddUpdateSectionModaVisible] =
        useState(false)
    const [selectedSectionRecord, setSelectedSectionRecord] = useState({})
    const [subjects, setSubjects] = useState([])
    const inputRef = useRef()

    useEffect(() => {
        getTests('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addUpdateModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addUpdateModalVisible, inputRef])

    const getTests = (query) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_COURSE_TEST + query)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageTests = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12 }} />
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'testNameUnique',
            key: 'testNameUnique',
            render: (text, record) => (
                <Link
                    href={'/mcq/test/' + record.id}
                    onClick={(event) => {
                        event.preventDefault()
                        props.navigate('/mcq/test/' + record.id)
                    }}
                    underline="none"
                >
                    <Button type="link" style={{ padding: 0 }}>
                        {record.testNameUnique}
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Timed',
            dataIndex: 'timed',
            key: 'timed',
            render: (text, record) => (
                <span>
                    {record.timed ? 'Yes | ' + record.timeInMinutes : 'No'}
                </span>
            ),
        },
        {
            title: 'Random',
            dataIndex: 'random',
            key: 'random',
            render: (text, record) => (
                <span>
                    {record.random ? (
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => showRadomConfigModal(record)}
                        >
                            {'Yes | ' + record.totalQuestions}
                        </Button>
                    ) : (
                        'No'
                    )}
                </span>
            ),
        },
        {
            title: 'Certification',
            dataIndex: 'certificationTest',
            key: 'certificationTest',
            render: (text, record) => (
                <span>{record.certificationTest ? 'Yes' : ''}</span>
            ),
        },
        {
            title: '# Attempts / Gap',
            dataIndex: 'attemptsAllowed',
            key: 'attemptsAllowed',
            render: (text, record) => (
                <span>
                    {record.attemptsAllowed > 0 ? record.attemptsAllowed : ''}
                    {record.gapBetweenAttempts > 0
                        ? ' / ' + record.gapBetweenAttempts + ' days'
                        : ''}
                </span>
            ),
        },
        {
            title: 'Score',
            dataIndex: 'correctAnswerScore',
            key: 'correctAnswerScore',
            render: (text, record) => (
                <span>
                    {record.correctAnswerScore > 0
                        ? record.correctAnswerScore +
                          ' / ' +
                          record.wrongAnswerScore
                        : ''}
                </span>
            ),
        },
        {
            title: 'Max Answers',
            dataIndex: 'maxAnswersCount',
            key: 'maxAnswersCount',
            render: (text, record) => (
                <span>
                    {record.maxAnswersCount > 0 ? record.maxAnswersCount : ''}
                </span>
            ),
        },
        {
            title: 'Sections?',
            dataIndex: 'questionSectionMappingEnabled',
            key: 'questionSectionMappingEnabled',
            render: (text, record) => (
                <span>
                    {record.questionSectionMappingEnabled ? (
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => showSections(record)}
                        >
                            Yes
                        </Button>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => deleteTest(record.testId)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                    <div style={{ fontSize: 8 }}>{record.testId}</div>
                </>
            ),
        },
    ]

    const searchTest = (query) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_COURSE_TEST + encodeURIComponent(query))
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddUpdateModal = (record) => {
        if (subjects.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_SUBJECTS).then((response) => {
                setSubjects(response.data)
            })
        }

        setAddUpdateModalVisible(true)
        setSelectedRecord(record)

        if (record.testId) {
            setTimeout(() => {
                form.setFieldsValue({
                    name: record.testNameUnique,
                    isTimed: record.timed,
                    certificationTest: record.certificationTest,
                    airTest: record.airTest,
                    attemptsAllowed: record.attemptsAllowed,
                    gapBetweenAttempts: record.gapBetweenAttempts,
                    correctAnswerScore: record.correctAnswerScore,
                    wrongAnswerScore: record.wrongAnswerScore,
                    maxAnswersCount: record.maxAnswersCount,
                    testNamePublic: record.testNamePublic,
                    questionSectionMappingEnabled:
                        record.questionSectionMappingEnabled,
                    subject: record.subject || '',
                    isRandom: record.random,
                })

                setTimedTest(record.timed)
                setRandom(record.random)
                if (record.timed) {
                    setTimeout(() => {
                        form.setFieldsValue({
                            timeInMinutes: record.timeInMinutes,
                            isRandom: record.random,
                        })
                        if (record.random) {
                            form.setFieldsValue({
                                totalQuestions: record.totalQuestions,
                            })
                        }
                    }, 100)
                }
            }, 100)
        } else {
            form.resetFields()
            setTimedTest(false)
            setRandom(false)
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
        setSelectedRecord({})
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateTest = () => {
        form.validateFields([
            'name',
            'isTimed',
            'timeInMinutes',
            'isRandom',
            'totalQuestions',
            'certificationTest',
            'airTest',
            'attemptsAllowed',
            'gapBetweenAttempts',
            'correctAnswerScore',
            'wrongAnswerScore',
            'maxAnswersCount',
            'testNamePublic',
            'questionSectionMappingEnabled',
            'subject',
        ])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_COURSE_TEST
                if (selectedRecord.id) {
                    url = GLOBAL.URL.UPDATE_COURSE_TEST
                    values.testId = selectedRecord.testId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateModal()
                        getTests('')
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteTest = (testId) => {
        setLoading(true)

        var payload = {}
        payload.testId = testId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_TEST, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTests('')
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showRadomConfigModal = (record) => {
        setSelectedRecord(record)
        setRandomConfigModalVisible(true)
        getRandomConfigData(record.id)
    }

    const getRandomConfigData = (testId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_RANDOM_TEST_CONFIG + testId)
            .then((res) => {
                setRandomTestConfigList(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideRadomConfigModal = () => {
        setRandomConfigModalVisible(false)
    }

    const randomConfigTableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateRandomConfigModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12 }} />
                </Button>
            ),
        },
        {
            title: 'Difficulty',
            dataIndex: 'difficulty',
            key: 'difficulty',
        },
        {
            title: 'Questions Count',
            dataIndex: 'questionsCount',
            key: 'questionsCount',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteRandomConfig(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddUpdateRandomConfigModal = (record = {}) => {
        setSelectedRandomConfig(record)
        setAddUpdateRandomConfigModalVisible(true)

        if (difficultyData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_DIFFICULTY_LEVELS).then((res) => {
                setDifficultyData(res.data)
            })
        }

        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({
                    difficulty: record.difficulty,
                    questionsCount: record.questionsCount,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateRandomConfigModal = () => {
        setAddUpdateRandomConfigModalVisible(false)
    }

    const getDifficultyOptions = () => {
        var options = []

        difficultyData.forEach((item) => {
            options.push(
                <Radio.Button value={item.difficulty} key={item.difficulty}>
                    {item.difficulty}
                </Radio.Button>
            )
        })

        return options
    }

    const addOrRandomConfig = () => {
        form.validateFields(['difficulty', 'questionsCount'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_RANDOM_TEST_CONFIG
                if (selectedRandomConfig.id) {
                    url = GLOBAL.URL.UPDATE_RANDOM_TEST_CONFIG
                    values.id = selectedRandomConfig.id
                }

                values.testId = selectedRecord.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateRandomConfigModal()
                        getRandomConfigData(selectedRecord.id)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteRandomConfig = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_RANDOM_TEST_CONFIG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getRandomConfigData(selectedRecord.id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showSections = (record) => {
        setSectionsModalVisible(true)
        setSelectedRecord(record)
        setLoadingSections(false)
        getSections(record.testId)
    }

    const getSections = (testId) => {
        RestApi.doGet(GLOBAL.URL.MCQ.GET_TEST_SECTIONS + testId)
            .then((res) => {
                setSectionsData(res.data)
            })
            .finally(() => {
                setLoadingSections(false)
            })
    }

    const hideSections = () => {
        setSectionsModalVisible(false)
        setSelectedRecord({})
    }

    const sectionsColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateSectionModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12 }} />
                </Button>
            ),
        },
        {
            title: 'Section Name',
            dataIndex: 'sectionName',
            key: 'sectionName',
        },
        {
            title: 'Max Answers',
            dataIndex: 'maxAnswersCount',
            key: 'maxAnswersCount',
            width: 100,
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => deleteTestSection(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                    <div style={{ fontSize: 8 }}>{record.testId}</div>
                </>
            ),
        },
    ]

    const showAddUpdateSectionModal = (record = {}) => {
        setAddUpdateSectionModaVisible(true)
        setSelectedSectionRecord(record)

        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({
                    sectionName: record.sectionName,
                    maxAnswersCount: record.maxAnswersCount,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateSectionModal = () => {
        setAddUpdateSectionModaVisible(false)
        setSelectedSectionRecord({})
    }

    const addOrUpdateTestSection = () => {
        form.validateFields(['sectionName', 'maxAnswersCount'])
            .then((values) => {
                setLoadingSections(true)

                values.testId = selectedRecord.testId

                var url = GLOBAL.URL.MCQ.ADD_TEST_SECTION
                if (selectedSectionRecord.id) {
                    url = GLOBAL.URL.MCQ.UPDATE_TEST_SECTION
                    values.id = selectedSectionRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateSectionModal()
                        getSections(selectedRecord.testId)
                    })
                    .catch((error) => {
                        setLoadingSections(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteTestSection = (id) => {
        setLoadingSections(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.MCQ.DELETE_TEST_SECTION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getSections(selectedRecord.testId)
            })
            .catch((error) => {
                setLoadingSections(false)
            })
    }

    const getSubjectOptions = () => {
        var options = []
        subjects.forEach((subject) => {
            options.push(
                <Option value={subject.name} key={subject.name}>
                    {subject.name}
                </Option>
            )
        })

        return options
    }

    return (
        <div>
            <h2>Course Tests</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddUpdateModal}
                >
                    Add
                </Button>
            </div>
            <Search
                placeholder="Search Test"
                onSearch={(value) => searchTest(value)}
                style={{ width: 600, marginTop: 10 }}
                autoFocus
            />
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="testNameUnique"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageTests}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure Test'}
                open={addUpdateModalVisible}
                onOk={addOrUpdateTest}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    initialValues={{
                        isTimed: false,
                        isRandom: false,
                        certificationTest: false,
                        airTest: false,
                        questionSectionMappingEnabled: false,
                    }}
                >
                    <Form.Item
                        label="Test Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Timed Test?"
                        name="isTimed"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(event) =>
                                setTimedTest(event.target.checked)
                            }
                        ></Checkbox>
                    </Form.Item>
                    {timedTest ? (
                        <>
                            <Form.Item
                                label="Time (mins)"
                                name="timeInMinutes"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                            <Form.Item
                                label="Random"
                                name="isRandom"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    onChange={(event) =>
                                        setRandom(event.target.checked)
                                    }
                                ></Checkbox>
                            </Form.Item>
                            {random ? (
                                <Form.Item
                                    label="Total Questions"
                                    name="totalQuestions"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <InputNumber min={1} />
                                </Form.Item>
                            ) : null}
                        </>
                    ) : null}
                    <Form.Item
                        label="Certification Test?"
                        name="certificationTest"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="AIR Test?"
                        name="airTest"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Attempts Allowed"
                        name="attemptsAllowed"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Next Attempt Gap (Days)"
                        name="gapBetweenAttempts"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Correct Answer Score"
                        name="correctAnswerScore"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Wrong Answer Score"
                        name="wrongAnswerScore"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber max={0} />
                    </Form.Item>
                    <Form.Item
                        label="Max Answers Count"
                        name="maxAnswersCount"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Subject"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: '100%' }}
                            allowClear
                        >
                            {getSubjectOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Test Name Public"
                        name="testNamePublic"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Has Sections?"
                        name="questionSectionMappingEnabled"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Random Test Configs'}
                open={randomConfigModalVisible}
                onOk={addOrUpdateTest}
                onCancel={hideRadomConfigModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddUpdateRandomConfigModal}
                >
                    Add
                </Button>
                <Table
                    columns={randomConfigTableColumns}
                    dataSource={randomTestConfigList}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="difficulty"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Random Test'}
                open={addUpdateRandomConfigModalVisible}
                onOk={addOrRandomConfig}
                confirmLoading={loading}
                onCancel={hideAddUpdateRandomConfigModal}
                okText={selectedRandomConfig.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={700}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Difficulty"
                        name="difficulty"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group
                            disabled={selectedRandomConfig.id ? true : false}
                        >
                            {getDifficultyOptions()}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Questions Count"
                        name="questionsCount"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Configure Sections'}
                open={sectionsModalVisible}
                onCancel={hideSections}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <h3>{selectedRecord.testNameUnique}</h3>
                <Button
                    size="small"
                    type="primary"
                    onClick={showAddUpdateSectionModal}
                >
                    Add
                </Button>
                <Table
                    columns={sectionsColumns}
                    dataSource={sectionsData}
                    pagination={false}
                    size="small"
                    loading={loadingSections}
                    rowKey="sectionName"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Configure Section'}
                open={addUpdateSectionModaVisible}
                onOk={addOrUpdateTestSection}
                onCancel={hideAddUpdateSectionModal}
                destroyOnClose={true}
                width={800}
                okText={selectedSectionRecord.id ? 'Update' : 'Add'}
                confirmLoading={loadingSections}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Section Name"
                        name="sectionName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="Max Answers Count"
                        name="maxAnswersCount"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default CourseTests
