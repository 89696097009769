import React from 'react'
import {
    Grid,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const AllCoursesTable = ({
    tableData,
    monthLabel,
    day,
    tableCourseNameColors = [],
}) => {
    const { classes, cx } = useStyles()

    const IndicatorDot = ({ courseName }) => {
        let color = ''
        tableCourseNameColors.forEach((data) => {
            if (data.label === courseName) {
                return (color = data.backgroundColor)
            }
        })
        return (
            <Grid
                style={{
                    background: color,
                }}
                className={classes.indicatorDot}
            />
        )
    }

    const getDateAndMonth = (day, monthLabel) => {
        let monthNumber = Number(monthLabel.split('-')[0])
        let year = monthLabel.split('-')[1]
        let months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ]
        return `${day}-${months[monthNumber - 1]}-${year}`
    }
    return tableData.length > 0 ? (
        <Grid className={classes.tableContainer}>
            <Grid className={classes.tableHeading}>
                <Typography>
                    <span className={classes.boldFont}>
                        {day ? 'Date: ' : 'Month: '}
                    </span>
                    {day ? getDateAndMonth(day, monthLabel) : monthLabel}
                </Typography>
            </Grid>
            <Grid className={classes.scrollableTable}>
                <Table
                    className={cx(
                        classes.customTableStyle,
                        classes.customCellPadding
                    )}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Course</TableCell>
                            <TableCell>
                                WatchTime ({tableData[0].valueLabel})
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((item, index) =>
                            item.value ? (
                                <TableRow key={index}>
                                    <TableCell style={{ position: 'relative' }}>
                                        <IndicatorDot
                                            courseName={item.courseName}
                                        />
                                        {item.courseName}
                                    </TableCell>
                                    <TableCell>{item.value}</TableCell>
                                </TableRow>
                            ) : null
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    ) : null
}

export const useStyles = makeStyles()((theme) => ({
    tableContainer: {
        overflow: 'hidden',
    },
    customCellPadding: {
        '& .MuiTableCell-root': {
            padding: '8px 8px 8px 14px',
        },
    },
    boldFont: {
        fontWeight: 'bold',
    },
    tableHeading: {
        display: 'flex',
        padding: '10px 8px',
        justifyContent: 'space-between',
    },
    scrollableTable: {
        overflow: 'auto',
        maxHeight: 370,
        [theme.breakpoints.down('lg')]: {
            maxHeight: 285,
        },
        '&::-webkit-scrollbar': {
            width: 2,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.background,
            borderRadius: 10,
        },
    },
    indicatorDot: {
        height: 5,
        width: 5,
        borderRadius: '50%',
        position: 'absolute',
        left: 4,
        top: 14,
    },
    customTableStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        borderLeft: 0,
        '& .MuiTableCell-head': {
            fontWeight: 'bold',
            background: '#f1f8fe',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    },
}))
