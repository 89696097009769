import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import { InputNumber, Button, Radio } from 'antd'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const FILTER_MRP = 'mrp'
const FILTER_NET_INCOME = 'net_income'
const FILTER_DISCOUNT = 'discount'
const FILTER_TRANSACTIONS = 'transactions'
const FILTER_USERS = 'users'
const FILTER_TRANSACTION_ITEMS = 'transaction_items'

const RevenueStats = () => {
    const [data, setData] = useState([])
    const [chartData, setChartData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [options, setOptions] = useState()

    const [dayData, setDayData] = useState([])
    const [dayChartData, setDayChartData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [dayOptions, setDayOptions] = useState()

    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState()
    const [filter, setFilter] = useState('mrp')

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        var url = GLOBAL.URL.GET_REVENUE_COUNT_BY_MONTH
        if (limit > 0) {
            url = url + '?limit=' + limit
        }

        RestApi.doGet(url)
            .then((res) => {
                setData(JSON.parse(res.data))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        drawChart()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, filter])

    const drawChart = () => {
        if (data.length === 0) {
            return
        }

        var chartLabels = []
        var revenueData = []
        var datasetLabel = ''
        data.forEach((row) => {
            chartLabels.push(row.label)

            var revenueRowData = null
            if (filter === FILTER_MRP) {
                revenueRowData = row.mrp
                datasetLabel = 'Revenue (lacs)'
            } else if (filter === FILTER_NET_INCOME) {
                revenueRowData = row.net_income
                datasetLabel = 'Net Income (lacs)'
            } else if (filter === FILTER_DISCOUNT) {
                revenueRowData = row.discount
                datasetLabel = 'Discount (%)'
            } else if (filter === FILTER_TRANSACTIONS) {
                revenueRowData = row.transactions
                datasetLabel = '# Transactions'
            } else if (filter === FILTER_USERS) {
                revenueRowData = row.users
                datasetLabel = '# Unique Users'
            } else if (filter === FILTER_TRANSACTION_ITEMS) {
                revenueRowData = row.transactionItems
                datasetLabel = '# Courses Purchased'
            }

            revenueData.push(revenueRowData)
        })

        var revenueDataSet = {}
        revenueDataSet.data = revenueData
        revenueDataSet.label = datasetLabel
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true

        var mapData = {}
        mapData.labels = chartLabels
        mapData.datasets = []
        mapData.datasets.push(revenueDataSet)
        setChartData(mapData)

        var options = clone(StatsConstants.CHART_OPTIONS)
        options.plugins.title.text = 'Revenue Stats'
        options.onClick = (evt, item) => {
            getMonthSplit(item, chartLabels)
        }

        setOptions(options)
    }

    const getMonthSplit = (item, labels) => {
        var label = labels[item[0].index]
        if (!label && item.length === 1) {
            return
        } else if (!label) {
            label = item[1]._model.label
        }

        if (!label) {
            return
        }

        getMonthSpliData(label)
    }

    const getMonthSpliData = (label) => {
        var url = GLOBAL.URL.GET_REVENUE_COUNT_BY_MONTH + '/' + label
        RestApi.doGet(url).then((res) => {
            setDayData(JSON.parse(res.data))
        })
    }

    useEffect(() => {
        const drawDayChart = () => {
            if (dayData.length === 0) {
                return
            }

            var labelsDays = []
            var revenueDataDays = []
            var revenueCountDays = []
            dayData.forEach((row) => {
                labelsDays.push(row.label)
                revenueDataDays.push(row.total)
                revenueCountDays.push(row.count)
            })

            var revenueDataSet = {}
            revenueDataSet.type = 'bar'
            revenueDataSet.data = revenueDataDays
            revenueDataSet.label = 'Revenue'
            revenueDataSet.backgroundColor = '#4bc0c0'
            revenueDataSet.borderColor = '#4bc0c0'
            revenueDataSet.fill = true
            revenueDataSet.yAxisID = 'y-1'
            revenueDataSet.datalabels = { display: true }

            var countDataSet = {}
            countDataSet.type = 'line'
            countDataSet.data = revenueCountDays
            countDataSet.label = 'Count'
            countDataSet.borderColor = '#396ab1'
            countDataSet.fill = false
            countDataSet.yAxisID = 'y-2'
            countDataSet.tension = 0.3

            var mapData = {}
            mapData.labels = labelsDays
            mapData.datasets = []
            mapData.datasets.push(countDataSet)
            mapData.datasets.push(revenueDataSet)
            setDayChartData(mapData)

            var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
            options.plugins.title.text = 'Revenue By Day'

            setDayOptions(options)

            document
                .getElementById('dayChart')
                .scrollIntoView({ behavior: 'smooth', block: 'center' })
        }

        drawDayChart()
    }, [dayData])

    const handleChange = (event) => {
        setFilter(event.target.value)
    }

    return (
        <div>
            <div>
                <span>Number of months to show: </span>
                <InputNumber
                    min={1}
                    defaultValue={6}
                    onChange={(value) => setLimit(value)}
                />
                <Button
                    type="primary"
                    onClick={getData}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Filters: </span>
                <Radio.Group onChange={handleChange} defaultValue={filter}>
                    <Radio.Button value={FILTER_MRP}>Gross Income</Radio.Button>
                    <Radio.Button value={FILTER_NET_INCOME}>
                        Net Income
                    </Radio.Button>
                    <Radio.Button value={FILTER_DISCOUNT}>
                        Discount
                    </Radio.Button>
                    <Radio.Button value={FILTER_TRANSACTIONS}>
                        Transactions
                    </Radio.Button>
                    <Radio.Button value={FILTER_USERS}>Users</Radio.Button>
                    <Radio.Button value={FILTER_TRANSACTION_ITEMS}>
                        Courses
                    </Radio.Button>
                </Radio.Group>
            </div>

            <Bar data={chartData} width={800} height={400} options={options} />
            <Bar
                id="dayChart"
                data={dayChartData}
                width={800}
                height={400}
                options={dayOptions}
            />
        </div>
    )
}

export default RevenueStats
