import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    notification,
    Radio,
    Select,
    Table,
    Modal,
    Input,
    Form,
} from 'antd'
import clone from 'clone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function SubjectCompletionReport(props) {
    const { Option } = Select
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [selectedStream, setSelectedStream] = useState('')
    const [levelsData, setLevelsData] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [searchUserModalVisible, setSearchUserModalVisible] = useState(false)
    const [searchUsers, setSearchUsers] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const getData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            setStreamData(JSON.parse(res.data))
        })

        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
            setCourseNames(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttempts(res.data)
        })
    }

    const onChangeStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setSelectedStream(e.target.value)
        setLevelsData(levels)
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var userIds = []
                selectedUsers.forEach((user) => {
                    userIds.push(user.id)
                })

                values.userIds = userIds

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.GENERATE_COURSE_COMPLETION_REPORT,
                    body
                )
                    .then((res) => {
                        notification['success']({
                            message: res.data,
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getStreamOptions = () => {
        var streamOptions = []
        streamData.forEach((item) => {
            streamOptions.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return streamOptions
    }

    const getLevelOptions = () => {
        var levelOptions = []
        levelsData.forEach((item) => {
            levelOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return levelOptions
    }

    const getAttemptOptions = () => {
        var attemptOptions = []
        attempts.forEach((attempt) => {
            attemptOptions.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt +
                        ' (' +
                        (attempt.validityString
                            ? attempt.validityString
                            : attempt.allowedDays + ' Days') +
                        ')'}
                </Option>
            )
        })

        return attemptOptions
    }

    const getCourseOptions = () => {
        var courseOptions = []
        courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return courseOptions
    }

    const selectedUsersTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => (
                <span>
                    {record.phone ? record.isd + '-' + record.phone : ''}
                </span>
            ),
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{
                        border: 'none',
                        color: 'red',
                        padding: 0,
                    }}
                    onClick={() => removeSelectedUser(record)}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
            ),
        },
    ]

    const searchUserColums = [
        {
            title: 'Select',
            dataIndex: 'select',
            key: 'select',
            render: (text, record) => (
                <Button
                    type="default"
                    size="small"
                    onClick={() => selectUser(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => (
                <span>
                    {record.phone ? record.isd + '-' + record.phone : ''}
                </span>
            ),
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const showSearchUserModal = () => {
        setSearchUserModalVisible(true)
    }

    const hideSearchUserModal = () => {
        setSearchUserModalVisible(false)
    }

    const searchUser = (query) => {
        setLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUsers(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const selectUser = (record) => {
        const selectedUsersClone = clone(selectedUsers)
        selectedUsersClone.push(record)

        setSelectedUsers(selectedUsersClone)
        hideSearchUserModal()
    }

    const removeSelectedUser = (record) => {
        var selectedUsersClone = clone(selectedUsers)
        selectedUsersClone = selectedUsersClone.filter(
            (item) => item.id !== record.id
        )
        setSelectedUsers(selectedUsersClone)
    }

    return (
        <div>
            <h2>Course Completion Report</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Attempts"
                    style={{ marginBottom: 10 }}
                    name="attemptNames"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        allowClear={true}
                        mode="multiple"
                    >
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Courses"
                    style={{ marginBottom: 10 }}
                    name="courseNames"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        allowClear={true}
                        mode="multiple"
                    >
                        {getCourseOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select Stream"
                    style={{ marginBottom: 10 }}
                    name="stream"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeStream}>
                        {getStreamOptions()}
                    </Radio.Group>
                </Form.Item>
                {selectedStream ? (
                    <Form.Item
                        label="Select level"
                        style={{ marginBottom: 10 }}
                        name="level"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{getLevelOptions()}</Radio.Group>
                    </Form.Item>
                ) : null}
            </Form>
            <div style={{ marginTop: 5 }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
                    {'Selected Users (optional)'}
                </span>
                <div style={{ marginTop: 5 }}>
                    <Button
                        size="small"
                        type="default"
                        onClick={showSearchUserModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={selectedUsersTableColumns}
                    dataSource={selectedUsers}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </div>
            <Button
                type="primary"
                loading={loading}
                onClick={generateReport}
                style={{ marginTop: 20 }}
            >
                Generate Report
            </Button>
            <Modal
                title={'Search User'}
                open={searchUserModalVisible}
                onCancel={hideSearchUserModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    style={{ width: 500 }}
                    autoFocus
                />
                <Table
                    columns={searchUserColums}
                    dataSource={searchUsers}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default SubjectCompletionReport
