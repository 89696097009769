import {
    Input,
    Modal,
    message,
    Table,
    Button,
    Select,
    Popconfirm,
    Form,
    Divider,
    Radio,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Link } from '@mui/material'
import { Utils } from '../JS/Utils'

const { Option } = Select
var ENABLE_CONSTANT = 'enable'

const Tests = (props) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [testData, setTestData] = useState([])
    const [editTestRecord, setEditTestRecord] = useState([])
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedChapter, setSelectedChapter] = useState('')
    const [chapterData, setChapterData] = useState({})
    const [subjectData, setSubjectData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedLevel, setSelectedLevel] = useState('')
    const [isTimed, setIsTimed] = useState('')
    const [isChapterTest, setIsChapterTest] = useState(true)
    const [downloadLoading, setDownloadLoading] = useState(false)
    const [downloadText, setDownloadText] = useState('')
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getRequiredData()
    }, [])

    useEffect(() => {
        if (selectedSubject) {
            getTestsData()
        }
        //eslint-disable-next-line
    }, [selectedSubject, selectedChapter, isChapterTest])

    useEffect(() => {
        if (selectedLevel) {
            getSubjectsData()
        }
        //eslint-disable-next-line
    }, [selectedLevel])

    useEffect(() => {
        setTimeout(() => {
            if (modalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [modalVisible, inputRef])

    const getTestsData = () => {
        RestApi.doGet(
            GLOBAL.URL.GET_TESTS +
                selectedSubject +
                '&chapter=' +
                selectedChapter +
                '&isChapterTest=' +
                isChapterTest
        ).then((res) => {
            setTestData(res.data)
        })
        if (!chapterData[selectedSubject]) {
            RestApi.doGet(
                GLOBAL.URL.GET_CHAPTERS_BY_SUBJECT +
                    '?subject=' +
                    selectedSubject
            )
                .then((res) => {
                    var existingState = chapterData
                    existingState[selectedSubject] = res.data
                    setChapterData(existingState)
                })
                .catch((error) => {
                    message.error(error)
                })
        }
    }

    const getRequiredData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamDataNew = JSON.parse(res.data)
            setStreamData(streamDataNew)
            setLevelData(streamDataNew[0].levels)
        })
    }

    const showModal = (record) => {
        if (record) {
            setIsTimed(record.timed)
            form.setFieldsValue(record)
            form.setFieldsValue({
                isTimed: record.timed,
            })
        }
        setModalVisible(true)
        setEditTestRecord(record)
    }
    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_TEST
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_TEST
                    values['id'] = editRecord.id
                }
                values['subject'] = selectedSubject
                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyCourseObject)
                    .then((response) => {
                        var TestsJsonArrayObject = []
                        TestsJsonArrayObject.push(response.data)
                        if (editRecord) {
                            getTestsData()
                        } else {
                            setTestData([...testData, ...TestsJsonArrayObject])
                        }
                        cancelModal()
                        message.success(
                            editRecord
                                ? 'Test Edited Successfully'
                                : 'Test Added Successfully'
                        )
                    })
                    .catch((resp) => {
                        message.error(resp.data, 5)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const cancelModal = () => {
        setModalVisible(false)
        setEditTestRecord(null)
        form.resetFields()
    }

    const enableDisableTest = (id, action) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        let url = ''
        if (action === ENABLE_CONSTANT) {
            url = GLOBAL.URL.ENABLE_TEST
        } else {
            url = GLOBAL.URL.DISABLE_TEST
        }
        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = testData
                    successObject.forEach((data) => {
                        if (id === data.id) {
                            data.enabled =
                                action === ENABLE_CONSTANT ? true : false
                            return
                        }
                    })
                    setTestData(successObject)
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }
    const deleteTest = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_TEST, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var existingData = testData
                    setTestData(existingData.filter((key) => key.id !== id))
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }
    const selectSubject = (value) => {
        setSelectedSubject(value)
        setDownloadText('')
    }
    const selectChapter = (value) => {
        setSelectedChapter(value ? value : '')
    }
    const getSubjectsData = () => {
        RestApi.doGet(
            GLOBAL.URL.GET_SUBJECTS_BY_STREAM +
                '?course=' +
                selectedCourse +
                '&level=' +
                selectedLevel
        ).then((res) => {
            setSubjectData(res.data)
        })
    }
    const selectCourse = (event) => {
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                setSelectedCourse(event.target.value)
                return
            }
        })
    }
    const selectLevel = (event) => {
        setSelectedLevel(event.target.value)
    }
    const onChangeTestType = (event) => {
        setIsChapterTest(event.target.value)
        setSelectedChapter('')
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: testData,
                updateUrl: GLOBAL.URL.REORDER_TESTS,
                columns: columns,
            },
        })
    }

    const downloadQuestions = () => {
        setDownloadLoading(true)

        var payload = {}
        payload.subjectName = selectedSubject

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.MCQ.DOWNLOAD_QUESTIONS_BY_TEST, formData)
            .then((response) => {
                setDownloadText(response.data)
            })
            .finally(() => {
                setDownloadLoading(false)
            })
    }

    const columns = [
        {
            title: 'Edit',
            key: 'action',
            width: '60px',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            showModal(record)
                        }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 14 }}
                        />
                    </Button>
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/mcq/test/' + record.id}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/mcq/test/' + record.id)
                        }}
                        underline="none"
                    >
                        <Button type="link">{record.name}</Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Chapter',
            dataIndex: 'chapter',
            key: 'chapter',
        },
        {
            title: 'Timed/Time In Minutes',
            key: 'createdBy',
            render: (text, record) => (
                <span>
                    <p>
                        {record.timed ? 'Yes | ' + record.timeInMinutes : 'No'}
                    </p>
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'active',
            render: (text, record) => (
                <span>
                    {!record.enabled ? (
                        <Button
                            style={{ background: 'red', color: 'white' }}
                            onClick={() => {
                                enableDisableTest(record.id, ENABLE_CONSTANT)
                            }}
                            size="small"
                        >
                            Disabled
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                enableDisableTest(record.id, 'disable')
                            }}
                            size="small"
                        >
                            Enabled
                        </Button>
                    )}
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Are you sure want to delete ?"
                        onConfirm={() => {
                            deleteTest(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" onClick={() => {}}>
                            Delete
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]
    var subjectOptionsView = []
    subjectData.forEach((item) => {
        subjectOptionsView.push(<Option value={item.name}>{item.name}</Option>)
    })
    var chapterOptionsView = []
    if (chapterData[selectedSubject]) {
        chapterData[selectedSubject].forEach((item) => {
            chapterOptionsView.push(
                <Option value={item.name}>{item.name}</Option>
            )
        })
    }

    var streamDetails = []
    streamData.forEach((item) => {
        streamDetails.push(
            <Radio.Button value={item.course} key={item.course}>
                {item.course}
            </Radio.Button>
        )
    })
    var levelDetails = []
    levelData.forEach((item) => {
        levelDetails.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    return (
        <div>
            <h2>MCQ Tests</h2>
            <Button
                type="primary"
                onClick={() => {
                    showModal()
                }}
                size="small"
            >
                Add Tests
            </Button>
            <div style={{ marginTop: 10 }}>
                <Radio.Group onChange={selectCourse}>
                    {streamDetails}
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <Radio.Group
                    value={selectedLevel}
                    onChange={selectLevel}
                    placeholder="Select Level"
                    disabled={selectedCourse ? false : true}
                >
                    {levelDetails}
                </Radio.Group>
            </div>
            {subjectData.length ? (
                <>
                    <div style={{ marginTop: 10 }}>
                        <Select
                            onChange={selectSubject}
                            placeholder="Select Subject"
                            style={{ width: 350 }}
                            disabled={!Object.keys(streamData).length}
                        >
                            {subjectOptionsView}
                        </Select>
                        {selectedSubject ? (
                            <>
                                <Radio.Group
                                    onChange={onChangeTestType}
                                    defaultValue={true}
                                    style={{ marginLeft: 10 }}
                                >
                                    <Radio.Button value={true} key={'chapter'}>
                                        Chapter Tests
                                    </Radio.Button>
                                    <Radio.Button value={false} key={'comp'}>
                                        Comp Tests
                                    </Radio.Button>
                                </Radio.Group>
                                {selectedChapter ? (
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        type="default"
                                        onClick={reorder}
                                    >
                                        Reorder
                                    </Button>
                                ) : null}
                                {Utils.isUserAdmin() ? (
                                    <span style={{ marginLeft: 10 }}>
                                        {downloadText ? (
                                            <span style={{ color: 'blue' }}>
                                                {downloadText}
                                            </span>
                                        ) : (
                                            <Popconfirm
                                                title="Click on Yes to download"
                                                onConfirm={downloadQuestions}
                                                type="danger"
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="default"
                                                    loading={downloadLoading}
                                                >
                                                    Download Questions
                                                </Button>
                                            </Popconfirm>
                                        )}
                                    </span>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        {selectedSubject && isChapterTest ? (
                            <Select
                                placeholder="Select Chapter"
                                style={{ width: 350 }}
                                onChange={selectChapter}
                                allowClear={true}
                            >
                                {chapterOptionsView}
                            </Select>
                        ) : null}
                    </div>
                </>
            ) : null}
            <div style={{ marginTop: 10 }}>
                <Table
                    columns={columns}
                    dataSource={testData}
                    pagination={false}
                    rowKey="name"
                />
            </div>
            <Modal
                title={editTestRecord ? 'Edit Test' : 'Add Tests'}
                open={modalVisible}
                onOk={() => {
                    handleSubmit(editTestRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={cancelModal}
                okText={editTestRecord ? 'Edit Test' : 'Add Test'}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Test Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Test Name',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Please enter Test Name"
                            ref={inputRef}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Is Chapter test"
                        name="isChapterTest"
                        rules={[
                            {
                                required: true,
                                message: 'Please select',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Is Timed Test"
                        name="isTimed"
                        rules={[
                            {
                                required: true,
                                message: 'Please select',
                            },
                        ]}
                    >
                        <Radio.Group
                            // placeholder="Select Is Timed Test"
                            onChange={(event) => {
                                setIsTimed(event.target.value)
                            }}
                        >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {!isTimed ? (
                        <Form.Item
                            label="Select Chapter"
                            name="chapter"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Chapter',
                                },
                            ]}
                        >
                            <Select placeholder="Please select Chapter">
                                {chapterOptionsView}
                            </Select>
                        </Form.Item>
                    ) : (
                        <Form.Item
                            label="Enter Time In Minutes"
                            name="timeInMinutes"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Time In Minutes',
                                },
                            ]}
                        >
                            <Input placeholder="Please Time In Minutes" />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </div>
    )
}

export default Tests
