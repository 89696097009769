import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { DatePicker } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

class SubjectRevenue extends React.Component {
    state = {
        loading: false,
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        data: {},
    }

    getData = (value, dateString) => {
        if (!dateString[0]) {
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.startDate = dateString[0]
        payload.endDate = dateString[1]

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.STATS.GET_FIRST_PURCHASE_STATS, formData)
            .then((res) => {
                this.setState(
                    {
                        data: res.data,
                    },
                    () => {
                        this.drawChart()
                    }
                )
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    drawChart() {
        var data = []
        var count = []
        var labels = []

        this.state.data.revenueStats.forEach((row) => {
            labels.push(row.month)
            data.push(row.mrp)
            count.push(row.count)
        })

        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = data
        revenueDataSet.label = 'Revenue (lacs)'
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.datalabels = { display: true }

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = count
        countDataSet.label = 'Count'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'First Purchase Revenue'

        this.setState({
            mapData: mapData,
            options: options,
        })

        // Aging stats
        var labelsAgingTransactionsRevenue = []
        var countAgingTransactionsRevenue = []
        var agingTransactionsRevenueBackgroundColor = []

        var cnt = 0
        this.state.data.agingStats.forEach((row) => {
            labelsAgingTransactionsRevenue.push(row.label)
            countAgingTransactionsRevenue.push(row.mrpPercent)
            agingTransactionsRevenueBackgroundColor.push(
                StatsConstants.COLORS[cnt % (StatsConstants.COLORS.length - 1)]
            )

            cnt++
        })

        var agingTransactionsRevenueDataSet = {}
        agingTransactionsRevenueDataSet.data = countAgingTransactionsRevenue
        agingTransactionsRevenueDataSet.label = 'Aging Revenue Split'
        agingTransactionsRevenueDataSet.fill = true
        agingTransactionsRevenueDataSet.backgroundColor =
            agingTransactionsRevenueBackgroundColor

        var agingTransactionsRevenueMapData = {}
        agingTransactionsRevenueMapData.labels = labelsAgingTransactionsRevenue
        agingTransactionsRevenueMapData.datasets = []
        agingTransactionsRevenueMapData.datasets.push(
            agingTransactionsRevenueDataSet
        )

        var agingTransactionsRevenueSplitOptions = clone(
            StatsConstants.CHART_OPTIONS
        )
        agingTransactionsRevenueSplitOptions.plugins.title.text =
            'Aging Revenue Split'
        agingTransactionsRevenueSplitOptions.plugins.datalabels.font.size = 12
        agingTransactionsRevenueSplitOptions.indexAxis = 'y'

        this.setState({
            agingTransactionsRevenueMapData: agingTransactionsRevenueMapData,
            agingTransactionsRevenueSplitOptions:
                agingTransactionsRevenueSplitOptions,
        })
    }

    render() {
        const { RangePicker } = DatePicker
        const dateFormat = 'DD-MMM-YYYY'

        return (
            <div>
                <h2>First Purchase Stats</h2>
                <div style={{ marginTop: 10 }}>
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={this.getData}
                    />
                    {this.state.loading ? (
                        <LoadingOutlined
                            style={{
                                fontSize: 20,
                                marginLeft: 20,
                            }}
                        />
                    ) : null}
                </div>
                {this.state.mapData.labels ? (
                    <>
                        <div>
                            <Bar
                                data={this.state.mapData}
                                width={800}
                                height={400}
                                options={this.state.options}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Bar
                                data={
                                    this.state.agingTransactionsRevenueMapData
                                }
                                options={
                                    this.state
                                        .agingTransactionsRevenueSplitOptions
                                }
                            />
                        </div>
                    </>
                ) : null}
            </div>
        )
    }
}

export default SubjectRevenue
