import { Input, Table, Button } from 'antd'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import { Link } from '@mui/material'

class SearchMCQ extends React.Component {
    state = {
        loading: false,
    }

    search = (query) => {
        this.setState({ loading: true })

        RestApi.doGet(GLOBAL.URL.SEARCH_MCQ + '?query=' + query)
            .then((res) => {
                if (res.data.length === 1) {
                    var record = res.data[0]
                    this.props.navigate('/mcq/questiondetails/' + record.id)
                } else {
                    this.setState({
                        data: res.data,
                    })
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    onRowClick = (record) => {
        this.props.navigate('/mcq/questiondetails/' + record.id, {
            state: { record: record },
        })
    }

    render() {
        const { Search } = Input

        const colums = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                render: (text, record) => (
                    <span>
                        <Link
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(
                                    '/mcq/questiondetails/' + record.id,
                                    '_blank'
                                )
                            }}
                            underline="none"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                {record.id}
                            </Button>
                        </Link>
                    </span>
                ),
            },
            {
                title: 'Question',
                dataIndex: 'question',
                key: 'question',
            },
            {
                title: 'Subject Name',
                dataIndex: 'subjectName',
                key: 'subjectName',
            },
        ]

        return (
            <div>
                <h3>Search MCQ by ID</h3>
                <div>
                    <Search
                        placeholder="Search MCQ"
                        onSearch={(query) => this.search(query)}
                        style={{ width: '75%' }}
                        autoFocus
                    />
                    <span style={{ marginLeft: 20 }}>
                        <UploadAndGetLink isMcqUpload={true} />
                    </span>
                </div>
                <Table
                    columns={colums}
                    dataSource={this.state.data ? this.state.data : null}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    loading={this.state.loading}
                    rowKey="id"
                />
            </div>
        )
    }
}

export default SearchMCQ
