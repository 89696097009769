import React, { useState } from 'react'
import {
    Grid,
    Typography,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

export const Filter = ({ onFetchClick, loading }) => {
    const { classes, cx } = useStyles()
    const [selectedMonth, setSelectedMonth] = useState(6)

    return (
        <Grid className={classes.filterContainer}>
            <Grid
                container
                alignItems="center"
                className={classes.monthFilterContainer}
            >
                <Typography>#Months:</Typography>
                <TextField
                    variant="outlined"
                    color="primary"
                    type="number"
                    value={selectedMonth}
                    size="small"
                    onChange={(event) => setSelectedMonth(event.target.value)}
                    className={cx(
                        classes.monthFilter,
                        classes.filterRightMargin
                    )}
                    InputProps={{ inputProps: { min: 1 } }}
                />
            </Grid>
            <Button
                onClick={() => onFetchClick(selectedMonth)}
                variant="contained"
                color="primary"
                size="small"
                className={classes.fetchButton}
                startIcon={loading ? <CircularProgress size={14} /> : null}
                disabled={loading}
            >
                Fetch
            </Button>
        </Grid>
    )
}

export const UserWatchTimeAnalyticsByMonthDetail = ({
    data,
    options,
    fetchData,
    loading,
}) => {
    const { classes } = useStyles()

    return Object.keys(data).length > 0 ? (
        <Grid>
            <Typography varaitn="h2" className={classes.heading}>
                Watch Time Analytics By Month
            </Typography>
            <Filter
                onFetchClick={(selectedMonth) => fetchData(selectedMonth)}
                loading={loading}
            />
            <Grid className={classes.scrollableWindow}>
                <Grid className={classes.barContainer}>
                    <Bar data={data} options={options} />
                    <Typography
                        variant="subtitle2"
                        className={classes.chartNote}
                    >
                        <span className={classes.noteText}>Note</span>: Click on
                        a month to get day wise analytics
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    ) : null
}

export const useStyles = makeStyles()((theme) => ({
    monthFilter: {
        width: 60,
        marginLeft: 5,
        '& .MuiInputBase-input': {
            padding: 7,
        },
        '& .MuiInputBase-root': {
            height: 27,
        },
    },
    monthFilterContainer: {
        width: 'fit-content',
    },
    filterContainer: {
        display: 'flex',
        flexFlow: 'row',
        marginBottom: '20px',
    },
    filterRightMargin: {
        marginRight: 15,
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        },
    },
    barContainer: {
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            width: 650,
        },
    },
    fetchButton: {
        fontSize: 13,
        padding: '2px 8px',
        minWidth: 10,
        height: 27,
        textTransform: 'none',
    },
    byDayBarContainer: {
        marginTop: 30,
    },
    scrollableWindow: {
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto',
        },
    },
    chartNote: {
        marginTop: 10,
    },
    noteText: {
        fontSize: 'inherit',
        textDecoration: 'underline',
    },
    analyticsByDayHeading: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexFlow: 'column',
        },
    },
}))
