import clone from 'clone'
import GraphConstants from '../Charts/ChartConstants'

export const mapAllCourses = (data, byMonth, paramsFunction) => {
    var label = []
    var dataSets = []
    var dataSetsData = {}

    data.forEach((dataRow) => {
        label.push(dataRow.label)
        dataRow.courses.forEach((courseRow) => {
            if (courseRow.courseName in dataSetsData) {
                dataSetsData[courseRow.courseName].push({
                    label: dataRow.label,
                    value: courseRow.value,
                })
            } else {
                dataSetsData[courseRow.courseName] = [
                    {
                        label: dataRow.label,
                        value: courseRow.value,
                    },
                ]
            }
        })
    })
    var cnt = 0
    for (var key in dataSetsData) {
        if (dataSetsData.hasOwnProperty(key)) {
            var datasetValue = []
            var courseData = dataSetsData[key]
            var color =
                GraphConstants.COLORS[cnt % (GraphConstants.COLORS.length - 1)]
            cnt++
            let index = 0
            while (index < label.length) {
                for (let i = 0; i < courseData.length; i++) {
                    if (label[index] === courseData[i].label) {
                        datasetValue.push(courseData[i].value)
                        index++
                    } else {
                        if (i + 1 === courseData.length) {
                            datasetValue.push(0)
                            index++
                        }
                    }
                }
            }

            var dataset = {}
            dataset.type = 'bar'
            dataset.label = key
            dataset.data = datasetValue
            dataset.backgroundColor = color
            dataset.borderWidth = 1
            dataset.fill = true
            dataSets.push(dataset)
        }
    }
    var watchTimeData = {}
    watchTimeData.labels = label
    watchTimeData.datasets = dataSets
    var options = clone(GraphConstants.CHART_OPTIONS)
    options.legend.display = false
    options.plugins.datalabels.display = false
    options.onClick = (event, item) => {
        if (item.length > 0) {
            if (byMonth) {
                let monthLabel = label[item[0].index]
                paramsFunction(monthLabel, '', data[item[0].index].courses)
            } else {
                paramsFunction(data[item[0].index].courses, item[0].index + 1)
            }
        }
    }

    return { watchTimeData, options }
}
