import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Descriptions,
    Radio,
    Table,
    Button,
    Popconfirm,
    message,
    Modal,
    InputNumber,
    Input,
    Form,
} from 'antd'
import { EditTwoTone, LoadingOutlined } from '@ant-design/icons'
import { Utils } from '../JS/Utils'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import UserCart from './components/UserCart'
import UserCourses from './components/UserCourses'
import UserTransactions from './components/UserTransactions'
import UserCourseExtend from './components/UserCourseExtend'
import UseriCash from './components/UseriCash'
import UserPoints from './components/UserPoints'
import UserForumTopics from './components/UserForumTopics'
import UserTopicReplies from './components/UserTopicReplies'
import UserAlertsSubscription from './components/UserAlertsSubscription'
import UserResults from './components/UserResults'
import { useParams } from 'react-router-dom'

var DATA_TYPE_COURSES = 'courses'
var DATA_TYPE_CREDITS = 'credits'
var DATA_TYPE_POINTS = 'points'
var DATA_TYPE_DEVICE_CHANGE_HISTORY = 'devicechangehistory'
var DATA_TYPE_FORUM_TOPICS = 'forumtopics'
var DATA_TYPE_FORUM_REPLIES = 'forumreplies'
var DATA_TYPE_COURSE_EXTEND = 'courseextend'
var DATA_TYPE_CART = 'cart'
var DATA_TYPE_ALERTS = 'alerts'
var DATA_TYPE_TRANSACTIONS = 'transactions'
var DATA_TYPE_RESULTS = 'results'

function UserDetails(props) {
    const [userDeviceChangesCount, setUserDeviceChangesCount] = useState(0)
    const [userDeviceChangesCountLoading, setUserDeviceChangesCountLoading] =
        useState(false)
    const [record, setRecord] = useState({})
    const [enableDisableLoading, setEnableDisableLoading] = useState(false)
    const [updatePhoneModalVisible, setUpdatePhoneModalVisible] =
        useState(false)
    const [updateEmailModalVisible, setUpdateEmailModalVisible] =
        useState(false)
    const [loading, setLoading] = useState(false)
    const [loginHistoryModalVisible, setLoginHistoryModalVisible] =
        useState(false)
    const [loginHistory, setLoginHistory] = useState([])
    const [loginHistoryLoading, setLoginHistoryLoading] = useState(false)
    const [loginHistoryNextUrl, setLoginHistoryNextUrl] = useState('')
    const [downloadLimitModalVisible, setDownloadLimitModalVisible] =
        useState(false)
    const [dataType, setDataType] = useState()
    const [userDeviceChangeHistoryLoading, setUserDeviceChangeHistoryLoading] =
        useState(false)
    const [userDeviceChangeHistory, setUserDeviceChangeHistory] = useState([])
    const [form] = Form.useForm()
    const { userId } = useParams()

    useEffect(() => {
        getUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserData = () => {
        RestApi.doGet(GLOBAL.URL.GET_USER_BY_ID + userId).then((res) => {
            setRecord(res.data)
        })

        setUserDeviceChangesCountLoading(true)
        RestApi.doGet(GLOBAL.URL.GET_USER_DEVICE_CHANGES_COUNT + userId)
            .then((res) => {
                setUserDeviceChangesCount(res.data)
            })
            .finally(() => {
                setUserDeviceChangesCountLoading(false)
            })
    }

    const enableDisable = () => {
        setEnableDisableLoading(true)

        var payload = {}
        payload.userId = record.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.ENABLE_USER
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_USER
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                var record1 = record
                record1.enabled = !record1.enabled
                setRecord(record1)

                message.destroy()
                message.success(response.data)
            })
            .finally(() => {
                setEnableDisableLoading(false)
            })
    }

    const deleteUser = () => {
        setEnableDisableLoading(true)

        var payload = {}
        payload.userId = record.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_USER, postBody).then((response) => {
            message.destroy()
            message.success(response.data)
            props.navigate('/searchuser/')
        })
    }

    const enableDisableDeviceCourseAccessCheck = () => {
        setEnableDisableLoading(true)

        var payload = {}
        payload.userId = record.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.DISABLE_DEVICE_COURSE_ACCESS_CHECK
        if (!record.disableDeviceCourseAccessCheck) {
            url = GLOBAL.URL.ENABLE_DEVICE_COURSE_ACCESS_CHECK
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                var record1 = record
                record1.disableDeviceCourseAccessCheck =
                    !record1.disableDeviceCourseAccessCheck
                setRecord(record1)

                message.destroy()
                message.success(response.data)
            })
            .finally(() => {
                setEnableDisableLoading(false)
            })
    }

    const onCopy = () => {
        message.success('Copied!')
    }

    const showUpdatePhoneModal = () => {
        setUpdatePhoneModalVisible(true)
        setTimeout(() => {
            var isd = ''
            if (record.isd) {
                isd = record.isd.split('+')[1]
            }
            form.setFieldsValue({
                phone: record.phone,
                isd: isd,
            })
        }, 100)
    }

    const hideUpdatePhoneModal = () => {
        setUpdatePhoneModalVisible(false)
    }

    const updatePhone = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)
                var isd = '+' + values.isd

                values.userId = record.id
                values.isd = isd

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_USER_PHONE, formData)
                    .then((response) => {
                        message.success(response.data)

                        var user = record
                        user.phone = values.phone
                        user.isd = isd
                        setRecord(user)

                        hideUpdatePhoneModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showLoginHistoryModal = () => {
        setLoginHistoryModalVisible(true)
        setLoginHistory([])
        setLoginHistoryLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_LOGIN_HISTORY + record.id)
            .then((response) => {
                var res = JSON.parse(response.data)
                setLoginHistory(res.data)
                setLoginHistoryNextUrl(res.next)
            })
            .finally(() => {
                setLoginHistoryLoading(false)
            })
    }

    const getLoginHistoryNextPage = () => {
        if (!loginHistoryNextUrl) {
            return
        }

        setLoginHistoryLoading(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + loginHistoryNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setLoginHistory((preState) => {
                    return [...preState, ...res.data]
                })
                setLoginHistoryNextUrl(res.next)
            })
            .finally(() => {
                setLoginHistoryLoading(false)
            })
    }

    const hideLoginHistoryModal = () => {
        setLoginHistoryModalVisible(false)
    }

    const increaseAllowedDeviceSwitches = () => {
        setUserDeviceChangesCountLoading(true)

        var payload = {}
        payload.userId = record.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.INCREASE_DEVICE_SWITCH_HISTORY, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                RestApi.doGet(GLOBAL.URL.GET_USER_BY_ID + record.id).then(
                    (res) => {
                        setRecord(res.data)
                    }
                )
            })
            .finally(() => {
                setUserDeviceChangesCountLoading(false)
            })
    }

    const showDownloadLimitModal = () => {
        setDownloadLimitModalVisible(true)
        setTimeout(() => {
            form.setFieldsValue({
                downloadLimitMins: record.downloadLimitMins,
            })
        }, 100)
    }

    const hideDownloadLimitModal = () => {
        setDownloadLimitModalVisible(false)
    }

    const updateDownloadLimit = () => {
        form.validateFields(['downloadLimitMins'])
            .then((values) => {
                setLoading(true)

                values.userId = record.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.UPDATE_OFFLINE_DOWNLOAD_LIMIT,
                    formData
                )
                    .then((response) => {
                        message.success(response.data)

                        var user = record
                        user.downloadLimitMins = values.downloadLimitMins
                        setRecord(user)

                        hideDownloadLimitModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const loginHistoryTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            render: (text, record) => <span>{record.ipInfo?.ip}</span>,
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            render: (text, record) => <span>{record.ipInfo?.city}</span>,
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            render: (text, record) => <span>{record.ipInfo?.region}</span>,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            render: (text, record) => <span>{record.ipInfo?.country}</span>,
        },
        {
            title: 'Pincode',
            dataIndex: 'postal',
            key: 'postal',
            render: (text, record) => <span>{record.ipInfo?.postal}</span>,
        },
    ]

    const onChangeSelection = (e) => {
        setDataType(e.target.value)

        if (e.target.value === DATA_TYPE_DEVICE_CHANGE_HISTORY) {
            getDeviceChangeHistory()
        }
    }

    const userDeviceSwitchHistoryTableColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'OS',
            dataIndex: 'os',
            key: 'os',
        },
        {
            title: 'Version',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
        },
        {
            title: 'Device Unique ID',
            dataIndex: 'uniqueId',
            key: 'uniqueId',
        },
    ]

    const getDeviceChangeHistory = () => {
        setUserDeviceChangeHistoryLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_DEVICE_CHANGE_HISTORY + record.id)
            .then((res) => {
                setUserDeviceChangeHistory(res.data)
            })
            .finally(() => {
                setUserDeviceChangeHistoryLoading(false)
            })
    }

    const showUpdateEmailModal = () => {
        setUpdateEmailModalVisible(true)
        setTimeout(() => {
            form.setFieldsValue({
                email: record.email,
            })
        }, 100)
    }

    const hideUpdateEmailModal = () => {
        setUpdateEmailModalVisible(false)
    }

    const updateEmail = () => {
        form.validateFields(['email'])
            .then((values) => {
                setLoading(true)

                values.userId = record.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_USER_EMAIL, formData)
                    .then((response) => {
                        message.success(response.data)

                        var user = record
                        user.email = values.email
                        setRecord(user)

                        hideUpdateEmailModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <div style={{ marginBottom: 10 }}>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={enableDisable}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type={record.enabled ? 'primary' : 'danger'}
                        size="small"
                        loading={enableDisableLoading}
                    >
                        {record.enabled ? 'User Enabled' : 'User Disabled'}
                    </Button>
                </Popconfirm>
                {Utils.isUserAdmin() ? (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={deleteUser}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="default"
                            size="small"
                            style={{ marginLeft: 20 }}
                        >
                            Delete User
                        </Button>
                    </Popconfirm>
                ) : null}
                {Utils.isUserAdmin() ? (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={enableDisableDeviceCourseAccessCheck}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            danger={record.disableDeviceCourseAccessCheck}
                            size="small"
                            loading={enableDisableLoading}
                            style={{ marginLeft: 20 }}
                        >
                            {record.disableDeviceCourseAccessCheck
                                ? 'Device Course Access Check Disabled'
                                : 'Device Course Access Check Enabled'}
                        </Button>
                    </Popconfirm>
                ) : null}
            </div>
            <Descriptions title="User Details" bordered size="small">
                <Descriptions.Item label="Email">
                    {Utils.isSuperUserAdmin() ? (
                        <Button
                            type="link"
                            style={{ padding: 0, marginRight: 5 }}
                            onClick={showUpdateEmailModal}
                        >
                            <EditTwoTone style={{ fontSize: 14 }} />
                        </Button>
                    ) : null}
                    <CopyToClipboard text={record.email} onCopy={onCopy}>
                        <Button type="link" style={{ padding: 0 }}>
                            {record.email}
                        </Button>
                    </CopyToClipboard>
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                    {Utils.isUserAdmin() ? (
                        <Button
                            type="link"
                            style={{ padding: 0, marginRight: 5 }}
                            onClick={showUpdatePhoneModal}
                        >
                            <EditTwoTone style={{ fontSize: 14 }} />
                        </Button>
                    ) : null}
                    <CopyToClipboard text={record.phone} onCopy={onCopy}>
                        <Button type="link" style={{ padding: 0 }}>
                            {record.phone
                                ? record.isd + '-' + record.phone
                                : ''}
                        </Button>
                    </CopyToClipboard>
                </Descriptions.Item>
                <Descriptions.Item label="Name">
                    {record.firstName + ' ' + record.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Joined At">
                    {record.joinedAt}
                </Descriptions.Item>
                <Descriptions.Item label="Stream">
                    {record.stream && record.stream.course
                        ? record.stream.course + '-' + record.stream.level
                        : null}
                </Descriptions.Item>
                <Descriptions.Item label="Attempt">
                    {record.attempt}
                </Descriptions.Item>
                <Descriptions.Item label="Device Switches">
                    {userDeviceChangesCountLoading ? (
                        <LoadingOutlined />
                    ) : (
                        <div>
                            {userDeviceChangesCount}/{' '}
                            {record.deviceChangeThreshold}
                            {Utils.userIsSupportManager() ? (
                                <Popconfirm
                                    title="Are you sure you want to increase allowed device switches?"
                                    onConfirm={increaseAllowedDeviceSwitches}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link">Add</Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                    )}{' '}
                </Descriptions.Item>
                <Descriptions.Item label="Download Limit Per Day(mins)">
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={showDownloadLimitModal}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>{' '}
                    {record.downloadLimitMins}
                </Descriptions.Item>
                <Descriptions.Item label="Login History">
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={showLoginHistoryModal}
                    >
                        {'View'}
                    </Button>
                </Descriptions.Item>
            </Descriptions>
            <Radio.Group
                value={dataType}
                onChange={onChangeSelection}
                style={{ marginTop: 15 }}
            >
                <Radio.Button value={DATA_TYPE_CART}>Lead</Radio.Button>
                <Radio.Button value={DATA_TYPE_COURSES}>Courses</Radio.Button>
                <Radio.Button value={DATA_TYPE_TRANSACTIONS}>
                    Transactions
                </Radio.Button>
                {Utils.isUserAdmin() ? (
                    <Radio.Button value={DATA_TYPE_COURSE_EXTEND}>
                        Extend Course
                    </Radio.Button>
                ) : null}
                <Radio.Button value={DATA_TYPE_CREDITS}>iCash</Radio.Button>
                <Radio.Button value={DATA_TYPE_POINTS}>Points</Radio.Button>
                <Radio.Button value={DATA_TYPE_FORUM_TOPICS}>
                    Topics
                </Radio.Button>
                <Radio.Button value={DATA_TYPE_FORUM_REPLIES}>
                    Replies
                </Radio.Button>
                <Radio.Button value={DATA_TYPE_DEVICE_CHANGE_HISTORY}>
                    Device History
                </Radio.Button>
                <Radio.Button value={DATA_TYPE_ALERTS}>Alerts</Radio.Button>
                <Radio.Button value={DATA_TYPE_RESULTS}>Results</Radio.Button>
            </Radio.Group>
            <div style={{ marginTop: 10 }}>
                {dataType === DATA_TYPE_CART ? (
                    <UserCart userId={record.id} userData={record} />
                ) : dataType === DATA_TYPE_COURSES ? (
                    <UserCourses userId={record.id} />
                ) : dataType === DATA_TYPE_TRANSACTIONS ? (
                    <UserTransactions userId={record.id} userData={record} />
                ) : dataType === DATA_TYPE_COURSE_EXTEND ? (
                    <UserCourseExtend userId={record.id} />
                ) : dataType === DATA_TYPE_CREDITS ? (
                    <UseriCash userId={record.id} />
                ) : dataType === DATA_TYPE_POINTS ? (
                    <UserPoints userId={record.id} />
                ) : dataType === DATA_TYPE_FORUM_TOPICS ? (
                    <UserForumTopics
                        userId={record.id}
                        navigate={props.navigate}
                    />
                ) : dataType === DATA_TYPE_FORUM_REPLIES ? (
                    <UserTopicReplies
                        userId={record.id}
                        navigate={props.navigate}
                    />
                ) : dataType === DATA_TYPE_DEVICE_CHANGE_HISTORY ? (
                    <Table
                        loading={userDeviceChangeHistoryLoading}
                        columns={userDeviceSwitchHistoryTableColumns}
                        dataSource={userDeviceChangeHistory}
                        pagination={false}
                        style={{ marginTop: 8 }}
                    />
                ) : dataType === DATA_TYPE_ALERTS ? (
                    <UserAlertsSubscription userId={record.id} />
                ) : dataType === DATA_TYPE_RESULTS ? (
                    <UserResults userId={record.id} />
                ) : null}
            </div>
            <Modal
                title={'Update Phone'}
                open={updatePhoneModalVisible}
                onOk={updatePhone}
                confirmLoading={loading}
                onCancel={hideUpdatePhoneModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="ISD"
                        name="isd"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: 200 }}
                            precision={0}
                            onPressEnter={updatePhone}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: 200 }}
                            precision={0}
                            onPressEnter={updatePhone}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Offline Download Limit'}
                open={downloadLimitModalVisible}
                onOk={updateDownloadLimit}
                confirmLoading={loading}
                onCancel={hideDownloadLimitModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Limit Mins"
                        name="downloadLimitMins"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder="Limit in minutes"
                            autoFocus
                            style={{ width: 400 }}
                            precision={0}
                            onPressEnter={updateDownloadLimit}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Login History'}
                open={loginHistoryModalVisible}
                onCancel={hideLoginHistoryModal}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <Table
                    loading={loginHistoryLoading}
                    columns={loginHistoryTableColumns}
                    dataSource={loginHistory}
                    pagination={false}
                    rowKey="id"
                    size="small"
                />
                {loginHistoryNextUrl ? (
                    <Button
                        style={{ marginTop: 10 }}
                        type="primary"
                        size="small"
                        loading={loginHistoryLoading}
                        onClick={getLoginHistoryNextPage}
                    >
                        Show More
                    </Button>
                ) : null}
            </Modal>
            <Modal
                title={'Update Email'}
                open={updateEmailModalVisible}
                onOk={updateEmail}
                confirmLoading={loading}
                onCancel={hideUpdateEmailModal}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Input onPressEnter={updateEmail} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default UserDetails
