import React, { useEffect, useState } from 'react'
import { Input, Button, Row, Col, message, Select, Checkbox, Form } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import TinyMceEditor from '../Common/TinyMceEditor'

const { Option } = Select

const AddCourse = (props) => {
    const [streamData, setStreamData] = useState([])
    const [attemptData, setAttemptData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [facultyData, setFacultyData] = useState([])
    const [courseData, setCourseData] = useState([])
    const [level, setLevel] = useState('')
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm()

    useEffect(() => {
        getRequiredData()
        checkEdit()
        //eslint-disable-next-line
    }, [])

    const checkEdit = () => {
        const { record } = props.location.state || {}
        if (record) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE + record.name)
                .then((res) => {
                    var length = res.data.prices.length
                    var arrayFields = []
                    for (var i = 0; i < length; i++) {
                        arrayFields.push(i)
                    }

                    form.setFieldsValue(res.data)
                    form.setFieldsValue({
                        course: record.stream.course,
                        level: record.stream.level,
                    })

                    setContent(res.data.content)
                })
                .catch((error) => {
                    message.error(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
        }
    }

    const getRequiredData = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS)
            .then((res) => {
                setAttemptData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE)
            .then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
                setLevelData(streamData[0].levels)
            })
            .catch((error) => {
                message.error(error)
            })
        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY)
            .then((res) => {
                var facultyData = res.data
                setFacultyData(facultyData)
            })
            .catch((error) => {
                message.error(error)
            })
        RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS)
            .then((res) => {
                setCourseData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setLevel('')
                return
            }
        })
    }

    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                values['content'] = content
                var url = GLOBAL.URL.ADD_COURSE
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_COURSE
                    values['id'] = editRecord.id
                }
                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyCourseObject)
                    .then((response) => {
                        message.success(response.data, 5)
                        props.navigate('/courses')
                    })
                    .catch((resp) => {
                        message.error(resp.data, 5)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const propsRecord = props.location.state ? props.location.state.record : {}
    var attemptDetails = []
    attemptData.forEach((item) => {
        attemptDetails.push(
            <Option value={item.attempt}>{item.attempt}</Option>
        )
    })

    const formItems = () => {
        return (
            <Form.List name="prices">
                {(fields, { add, remove }) => (
                    <>
                        {fields.length === 0 && add()}
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Row gutter={16} key={key}>
                                <Col span={4}>
                                    <Form.Item
                                        label={index === 0 ? 'Price' : ''}
                                        {...restField}
                                        name={[name, 'price']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input Price or delete this field.',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Price" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={index === 0 ? 'Attempt' : ''}
                                        {...restField}
                                        name={[name, 'attempt']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select Attempt or delete this field.',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select Attempt">
                                            {attemptDetails}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={index === 0 ? 'Action' : ''}
                                    >
                                        {fields.length > 1 && (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(name)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}

                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                style={{ width: '30%' }}
                            >
                                Add Price
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    }

    var streamDetails = []
    streamData.forEach((item) => {
        streamDetails.push(<Option value={item.course}>{item.course}</Option>)
    })
    var levelDetails = []
    levelData.forEach((item) => {
        levelDetails.push(<Option value={item}>{item}</Option>)
    })
    var facultyDetails = []
    facultyData.forEach((item) => {
        facultyDetails.push(
            <Option value={item.id}>
                {item.firstName + ' ' + item.lastName}
            </Option>
        )
    })
    var allCourses = []
    courseData.forEach((item) => {
        if (!item.bundle) {
            allCourses.push(<Option value={item.id}>{item.name}</Option>)
        }
    })

    return (
        <div>
            <b>ADD COURSE</b>
            <Form
                form={form}
                initialValues={{ bundle: false }}
                layout="vertical"
            >
                <Row gutter={16} style={{ marginTop: 20 }}>
                    <Col span={12}>
                        <Form.Item
                            label="Course Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Course Name',
                                },
                            ]}
                        >
                            <Input placeholder="Please enter Course Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Picture Url"
                            name="pictureUrl"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter Picture Url',
                                },
                                {
                                    type: 'url',
                                    message: 'Please enter valid url',
                                },
                            ]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Please enter Picture Url"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Select course"
                            name="course"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                onChange={handleCourseChange}
                                showSearch
                            >
                                {streamDetails}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Select level"
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select level',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select level"
                                value={level}
                                showSearch
                            >
                                {levelDetails}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={18}>
                        <Form.Item
                            label="Select Faculty"
                            name="faculty"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter First Name',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select Faculty"
                                mode="multiple"
                            >
                                {facultyDetails}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Enter duration"
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter duration',
                                },
                            ]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Please enter duration"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={2}>
                        <Form.Item
                            label="Bundle?"
                            name="bundle"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item
                            label="Select Courses"
                            name="bundleCourseIds"
                            rules={[
                                {
                                    required: false,
                                    message: 'Select courses',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select courses"
                                mode="multiple"
                            >
                                {allCourses}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Enter Demo Links (Separated by Comma)"
                            name="demoLinks"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter DemoLinks',
                                },
                            ]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Please enter DemoLinks"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {formItems()}

                {loading ? null : (
                    <Form.Item>
                        <TinyMceEditor
                            handleEditorChange={handleEditorChange}
                            initialValue={content}
                        />
                    </Form.Item>
                )}

                <Form.Item>
                    {propsRecord ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                handleSubmit(propsRecord)
                            }}
                        >
                            Update
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                handleSubmit()
                            }}
                        >
                            Submit
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddCourse
