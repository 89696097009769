import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    DatePicker,
    Radio,
    notification,
    Modal,
    Select,
    Form,
} from 'antd'
import { Utils } from '../JS/Utils'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Link } from '@mui/material'

function ExternalSalesNewLeads(props) {
    const { RangePicker } = DatePicker
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState()
    const [data, setData] = useState([])
    const [stream, setStream] = useState('')
    const [level, setLevel] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
    const [adminUsers, setAdminUsers] = useState([])
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        getFiltersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getLeads()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize])

    const getFiltersData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })
    }

    const getLeads = () => {
        setLoading(true)

        var url =
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_NEW_LEADS +
            '?stream=' +
            stream +
            '&level=' +
            level +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            (pageSize && pageSize !== 10 ? '&pageSize=' + pageSize : '')

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'User',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/external-sales-lead-details/' + record.id}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(
                                '/external-sales-lead-details/' + record.id
                            )
                        }}
                        underline="none"
                        target="_blank"
                    >
                        {record.firstName + ' ' + record.lastName}
                    </Link>
                </span>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => (
                <span>
                    {record.phone ? record.isd + '-' + record.phone : ''}
                </span>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>
                    {record.stream ? record.stream + ' - ' + record.level : ''}
                </span>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            render: (text, record) => <span>{record.attempt}</span>,
        },
        {
            title: 'Joined At',
            dataIndex: 'joinedAt',
            key: 'joinedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.joinedAt}</span>
            ),
        },
    ]

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getStreamCourseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value="" key={'all'}>
                {'All'}
            </Radio.Button>
        )
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelOptions = () => {
        var options = []
        if (!stream) {
            return options
        }

        options.push(
            <Radio.Button value="" key={'all'}>
                {'All'}
            </Radio.Button>
        )
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeStreamCourse = (event) => {
        setStream(event.target.value)
        setLevelData([])
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                return
            }
        })
    }

    const onChangeStreamLevel = (event) => {
        setLevel(event.target.value)
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    const showBulkAssignModal = () => {
        setBulkAssignModalVisible(true)
        if (adminUsers.length === 0) {
            RestApi.doGet(
                GLOBAL.URL.SALES.EXTERNAL_SALES_GET_ASSIGNED_TO_FILTER_DATA
            ).then((res) => {
                setAdminUsers(res.data)
            })
        }
    }

    const hideBulkAssignModal = () => {
        setBulkAssignModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const bulkAssignLeads = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                if (selectedRows.length === 0) {
                    notification['error']({
                        message: 'No leads selected',
                    })
                    return
                }

                setLoading(true)

                var userIds = []
                selectedRows.forEach((item) => {
                    userIds.push(item.id)
                })

                values.userIds = userIds

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_BULK_ASSIGN_LEADS,
                    formData,
                    'blob'
                )
                    .then((response) => {
                        if (response.type === 'application/json') {
                            const reader = new FileReader()
                            reader.addEventListener('loadend', () => {
                                notification['success']({
                                    message: JSON.parse(reader.result).data,
                                })
                            })
                            reader.readAsText(response)
                        } else {
                            const url = window.URL.createObjectURL(
                                new Blob([response])
                            )
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', 'Users.xlsx')
                            document.body.appendChild(link)
                            link.click()
                            link.parentNode.removeChild(link)
                        }

                        hideBulkAssignModal()
                        getLeads()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const pageSizeOptions = () => {
        var options = []
        options.push(
            <Option value={10} key={10}>
                {10}
            </Option>
        )
        options.push(
            <Option value={20} key={20}>
                {20}
            </Option>
        )
        options.push(
            <Option value={30} key={30}>
                {30}
            </Option>
        )
        options.push(
            <Option value={40} key={40}>
                {40}
            </Option>
        )
        options.push(
            <Option value={50} key={50}>
                {50}
            </Option>
        )

        return options
    }

    const onChangePageSize = (size) => {
        setPageSize(size)
    }

    return (
        <div>
            <h2>New Leads</h2>
            <div style={{ marginTop: 10 }}>
                <Radio.Group onChange={onChangeStreamCourse} value={stream}>
                    {getStreamCourseOptions()}
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeStreamLevel}
                    style={{ marginLeft: 20 }}
                    value={level}
                >
                    {getStreamLevelOptions()}
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <RangePicker
                    format="DD-MMM-YY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateFilterChange}
                />
                <span style={{ marginLeft: 10 }}>
                    <Button type="link" onClick={getLeads}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                </span>
                {Utils.isUserExternalSalesManager() ? (
                    <Button
                        type="default"
                        style={{ marginLeft: 10 }}
                        disabled={selectedRows.length === 0}
                        onClick={showBulkAssignModal}
                    >
                        Bulk Assign
                    </Button>
                ) : null}
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="id"
                style={{ marginTop: 10 }}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
            />
            {nextUrl ? (
                <div>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageData}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        More Data
                    </Button>
                    <Select
                        placeholder="Page Size"
                        style={{ width: 60, marginLeft: 20 }}
                        onChange={onChangePageSize}
                        defaultValue={pageSize}
                    >
                        {pageSizeOptions()}
                    </Select>
                </div>
            ) : null}
            <Modal
                title={'Bulk Assign'}
                open={bulkAssignModalVisible}
                onOk={bulkAssignLeads}
                confirmLoading={loading}
                onCancel={hideBulkAssignModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ExternalSalesNewLeads
