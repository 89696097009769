import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import {
    Table,
    Button,
    Popconfirm,
    message,
    Modal,
    InputNumber,
    DatePicker,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'

function UserCourseExtend(props) {
    const userId = props.userId
    const [form] = Form.useForm()

    const [selectedExtensionRows, setSelectedExtensionRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [loadingCourseExtension, setLoadingCourseExtension] = useState(false)
    const [courseExtensioData, setCourseExtensioData] = useState([])
    const [courseExtensionEndDate, setCourseExtensionEndDate] = useState('')

    const [
        updateDurationViewsLimitModalVisible,
        setUpdateDurationViewsLimitModalVisible,
    ] = useState(false)
    const [selectedExtendCourseRecord, setSelectedExtendCourseRecord] =
        useState({})
    const [loadingDurationViewsLimit, setLoadingDurationViewsLimit] =
        useState(false)

    useEffect(() => {
        getCourseExtensionData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCourseExtensionData = () => {
        setLoadingCourseExtension(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_COURSE_ACCESS_LIST + userId)
            .then((res) => {
                setCourseExtensioData(res.data)
            })
            .finally(() => {
                setLoadingCourseExtension(false)
            })
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedExtensionRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    const onCourseExtensionEndDateChange = (value, dateString) => {
        setCourseExtensionEndDate(dateString)
    }

    const extendCourses = () => {
        if (!selectedExtensionRows.length) {
            message.error('Select courses to extend!')
            return
        }

        if (!courseExtensionEndDate) {
            message.error('Select end date!')
            return
        }

        setLoadingCourseExtension(true)

        var ids = []
        selectedExtensionRows.forEach((row) => {
            ids.push(row.id)
        })

        var payload = {}
        payload.userId = userId
        payload.ids = ids
        payload.date = courseExtensionEndDate

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.EXTEND_COURSES, formData)
            .then((response) => {
                message.success(response.data)

                setSelectedRowKeys([])
                setSelectedExtensionRows([])

                getCourseExtensionData()
            })
            .catch((error) => {
                setLoadingCourseExtension(false)
            })
    }

    const resetCourseProgress = () => {
        if (!selectedExtensionRows.length) {
            message.error('Select courses to extend!')
            return
        }

        setLoadingCourseExtension(true)

        var ids = []
        selectedExtensionRows.forEach((row) => {
            ids.push(row.courseId)
        })

        var payload = {}
        payload.userId = userId
        payload.courseIds = ids

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.RESET_COURSE_PRGORESS, formData)
            .then((response) => {
                message.success(response.data)

                setSelectedRowKeys([])
                setSelectedExtensionRows([])
            })
            .finally(() => {
                setLoadingCourseExtension(false)
            })
    }

    var courseExtensionColumns = [
        {
            title: 'Course Name',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => record.courseName,
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
        {
            title: 'Enrolled At',
            dataIndex: 'enrolledAt',
            key: 'enrolledAt',
        },
        {
            title: 'Manual End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (text, record) => (
                <>
                    {record.endDate ? (
                        <>
                            {record.endDate}
                            <Popconfirm
                                title="Clear End Date?"
                                onConfirm={() => {
                                    clearEndDateInCourse(record.id)
                                }}
                                type="danger"
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{ padding: 0, marginLeft: 5 }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color="red"
                                    />
                                </Button>
                            </Popconfirm>
                        </>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Views Limit',
            dataIndex: 'durationViewsLimit',
            key: 'durationViewsLimit',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateDurationViewsLimitModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <span style={{ marginLeft: 5 }}>
                        {record.durationViewsLimit}
                    </span>
                </>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Remove Course Access?"
                    onConfirm={() => deleteCourseAccess(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const clearEndDateInCourse = (id) => {
        setLoadingCourseExtension(true)

        var payload = {}
        payload.userId = userId
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.CLEAR_END_DATE_IN_USER_COURSE, formData)
            .then((response) => {
                message.success(response.data)

                setSelectedRowKeys([])
                setSelectedExtensionRows([])

                getCourseExtensionData()
            })
            .catch((error) => {
                setLoadingCourseExtension(false)
            })
    }

    const deleteCourseAccess = (id) => {
        setLoadingCourseExtension(true)

        var payload = {}
        payload.userId = userId
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_ACCESS, formData)
            .then((response) => {
                message.success(response.data)

                setSelectedRowKeys([])
                setSelectedExtensionRows([])

                getCourseExtensionData()
            })
            .catch((error) => {
                setLoadingCourseExtension(false)
            })
    }

    const showUpdateDurationViewsLimitModal = (record) => {
        setUpdateDurationViewsLimitModalVisible(true)
        setSelectedExtendCourseRecord(record)

        setTimeout(() => {
            form.setFieldsValue({
                durationViewsLimit: record.durationViewsLimit
                    ? record.durationViewsLimit
                    : 0,
            })
        }, 100)
    }

    const hideUpdateDurationViewsLimitModal = () => {
        setUpdateDurationViewsLimitModalVisible(false)
        setSelectedExtendCourseRecord({})
    }

    const updateDurationViewsLimit = () => {
        form.validateFields()
            .then((values) => {
                setLoadingDurationViewsLimit(true)

                var payload = {}
                payload.userId = userId
                payload.id = selectedExtendCourseRecord.id
                payload.durationViewsLimit = values.durationViewsLimit

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.UPDATE_COURSE_ACCESS_DURATION_VIEWS_LIMIT,
                    formData
                )
                    .then((response) => {
                        message.success(response.data)
                        hideUpdateDurationViewsLimitModal()
                        getCourseExtensionData()
                    })
                    .finally(() => {
                        setLoadingDurationViewsLimit(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            <span>End date: </span>
            <DatePicker
                placeholder="Select End Date"
                onChange={onCourseExtensionEndDateChange}
                format="DD-MMM-YYYY"
            />
            <Button
                type="primary"
                disabled={
                    selectedExtensionRows.length === 0 ||
                    !courseExtensionEndDate
                }
                style={{ marginLeft: 10 }}
                loading={loadingCourseExtension}
                onClick={extendCourses}
            >
                Extend
            </Button>
            <Popconfirm
                title="Are you sure?"
                onConfirm={resetCourseProgress}
                type="danger"
                okText="Yes"
                cancelText="No"
            >
                <Button
                    type="default"
                    disabled={selectedExtensionRows.length === 0}
                    style={{ marginLeft: 10 }}
                    loading={loadingCourseExtension}
                >
                    Reset Course Progress
                </Button>
            </Popconfirm>
            <Table
                columns={courseExtensionColumns}
                dataSource={courseExtensioData}
                pagination={false}
                loading={loadingCourseExtension}
                rowKey="id"
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                style={{ marginTop: 10 }}
                size="small"
            />
            <Modal
                title={'Update Duration Views Limit'}
                open={updateDurationViewsLimitModalVisible}
                onOk={updateDurationViewsLimit}
                confirmLoading={loadingDurationViewsLimit}
                onCancel={hideUpdateDurationViewsLimitModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <h3>{selectedExtendCourseRecord.courseName}</h3>
                {updateDurationViewsLimitModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Duration Views Limit"
                            style={{ marginBottom: 0 }}
                            name="durationViewsLimit"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <InputNumber
                                min={0}
                                max={100}
                                step={1.0}
                                autoFocus
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default UserCourseExtend
