import React, { useState, useEffect } from 'react'
import { Input, Select, Form } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'

const defaultRoute = 'Home'
function RouteData(props) {
    const { Option } = Select

    const [selectedRoute, setSelectedRoute] = useState(
        props.intialRoute ? props.intialRoute : defaultRoute
    )
    const [params, setParams] = useState([])
    const [routesData, setRoutesData] = useState([])

    useEffect(() => {
        getRoutes()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        onRouteChange(selectedRoute)
        // eslint-disable-next-line
    }, [routesData])

    const getRoutes = () => {
        RestApi.doGet(GLOBAL.URL.GET_ROUTES).then((resposne) => {
            setRoutesData(JSON.parse(resposne.data))
        })
    }

    const getRouteNameOptions = () => {
        var options = []
        routesData.forEach((route) => {
            options.push(
                <Option value={route.route} key={route.route}>
                    {route.displayName}
                </Option>
            )
        })

        return options
    }

    const onRouteChange = (value) => {
        var params = []
        routesData.forEach((item) => {
            if (item.route === value && item.params) {
                params = item.params
                return
            }
        })

        setSelectedRoute(value)
        setParams(params)

        props.form.setFieldsValue({
            route: value,
            routeData: params.map((item) => ({
                key: item.name,
                value: '',
            })),
        })
    }

    const getParamSelectOptions = (listValues) => {
        var paramSelectOptions = []
        listValues.forEach((listValue) => {
            paramSelectOptions.push(
                <Option value={listValue.value} key={listValue.value}>
                    {listValue.displayName}
                </Option>
            )
        })

        return paramSelectOptions
    }

    const formItems = () => {
        const items = []
        params.forEach((k, index) => {
            items.push(
                <span key={k.name}>
                    <Form.Item
                        label="Route Key"
                        key={k.name}
                        style={{ lineHeight: 0, marginBottom: 5 }}
                        name={[`routeData`, index, 'key']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                            {
                                required: k.required,
                                message: 'Please enter data!',
                            },
                        ]}
                        valuePropName="value"
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="Route value"
                        key={k.name + '1'}
                        style={{ lineHeight: 0, marginBottom: 5 }}
                        name={[`routeData`, index, 'value']}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                            {
                                required: k.required,
                                message: 'Please enter data!',
                            },
                        ]}
                    >
                        {k.type === 'input' ? (
                            <Input />
                        ) : (
                            <Select
                                placeholder="Select"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getParamSelectOptions(k.listValues)}
                            </Select>
                        )}
                    </Form.Item>
                </span>
            )
        })

        return items
    }

    return (
        <>
            <Form.Item
                label="App Routing"
                style={{ lineHeight: 0, marginBottom: 5 }}
                name="route"
                rules={[
                    {
                        required: props.routeRequired && true,
                        message: 'Select route!',
                    },
                ]}
            >
                <Select
                    placeholder="Select route"
                    onChange={onRouteChange}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    defaultValue={selectedRoute}
                    showSearch
                >
                    {getRouteNameOptions()}
                </Select>
            </Form.Item>
            {formItems()}
        </>
    )
}

export default RouteData
