import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    InputNumber,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function Dnd() {
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [filter, setFilter] = useState('')
    const inputNumberRef = useRef()

    useEffect(() => {
        searchDndPhoneNumbers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        setTimeout(() => {
            if (addModalVisible && inputNumberRef.current) {
                inputNumberRef.current.focus()
            }
        }, 10)
    }, [addModalVisible, inputNumberRef])

    const searchDndPhoneNumbers = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.NOTIFICATION.SEARCH_DND_PHONE + filter)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPage = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'name',
            key: 'name',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Added By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.createdBy.firstName}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteDndPhone(record.phone)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddModal = () => {
        setAddModalVisible(true)
        form.resetFields()
    }

    const hideAddModal = () => {
        setAddModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addDndPhone = () => {
        form.validateFields(['phone'])
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.NOTIFICATION.ADD_DND_PHONE, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddModal()
                        searchDndPhoneNumbers()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteDndPhone = (phone) => {
        setLoading(true)

        var payload = {}
        payload.phone = phone

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.NOTIFICATION.DELETE_DND_PHONE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                searchDndPhoneNumbers()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>DND</h2>
            <div>
                <Button type="primary" onClick={showAddModal}>
                    Add
                </Button>
                <Search
                    placeholder="Search Phone"
                    onSearch={(value) => setFilter(value)}
                    style={{ width: 500, marginLeft: 10 }}
                    autoFocus
                    allowClear
                />
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="key"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPage}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
                <Modal
                    title={'Add DND Phone'}
                    open={addModalVisible}
                    onOk={addDndPhone}
                    confirmLoading={loading}
                    onCancel={hideAddModal}
                    okText={'Add'}
                    destroyOnClose={true}
                    width={800}
                >
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber
                                autoFocus
                                style={{ width: 200 }}
                                min={0}
                                max={9999999999}
                                ref={inputNumberRef}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default Dnd
