import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import CircularProgress from '@mui/material/CircularProgress'
import { Radio, Select } from 'antd'
import { Utils } from '../JS/Utils'

const FILTER_ALL = 'all'
const FILTER_OPEN = 'open'
const FILTER_CLOSED = 'closed'

function ExternalSalesDashboard() {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [filter, setFilter] = useState(FILTER_OPEN)
    const [teams, setTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState()

    useEffect(() => {
        if (!Utils.isUserAdmin() && !Utils.userIsSalesMember()) {
            getDashboard()
        } else {
            if (!teams.length) {
                RestApi.doGet(
                    GLOBAL.URL.SALES.GET_EXTERNAL_SALES_COMPANY_LIST
                ).then((res) => {
                    setTeams(res.data)
                    if (res.data.length) {
                        setSelectedTeam(res.data[0].companyName)
                    }
                })
            } else {
                getDashboard()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        if (selectedTeam) {
            getDashboard()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam])

    const getDashboard = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_DASHBOARD +
                '?filter=' +
                filter +
                '&teamName=' +
                selectedTeam
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeFilterType = (event) => {
        setFilter(event.target.value)
    }

    const teamsOptions = () => {
        var options = []
        teams.forEach((team) => {
            options.push(
                <Option value={team.companyName} key={team.companyName}>
                    {team.companyName}
                </Option>
            )
        })

        return options
    }

    const onChangeTeam = (teamName) => {
        setSelectedTeam(teamName)
    }

    return (
        <div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
                <Radio.Group
                    onChange={onChangeFilterType}
                    defaultValue={filter}
                    disabled={loading}
                >
                    <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value={FILTER_OPEN} key={FILTER_OPEN}>
                        {'Open'}
                    </Radio.Button>
                    <Radio.Button value={FILTER_CLOSED} key={FILTER_CLOSED}>
                        {'Closed'}
                    </Radio.Button>
                </Radio.Group>
                {Utils.userIsSalesMember() ? (
                    <Select
                        placeholder="Select Team"
                        style={{ width: 200, marginLeft: 20 }}
                        onChange={onChangeTeam}
                        value={selectedTeam}
                    >
                        {teamsOptions()}
                    </Select>
                ) : null}
            </div>
            {loading ? <CircularProgress /> : null}
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    )
}

export default ExternalSalesDashboard
