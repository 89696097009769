import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, message, Modal, Input, Table, Popconfirm, Form } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { DownloadOutlined } from '@ant-design/icons'

function Videos(props) {
    const { Search } = Input

    const [query, setQuery] = useState('')
    const [loading, setLoading] = useState(false)
    const [updateMetaModalVisible, setUpdateMetaModalVisible] = useState(false)
    const [replaceVideoModalVisible, setReplaceVideoModalVisible] =
        useState(false)
    const [videos, setVideos] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [selectedVideo, setSelectedVideo] = useState({})
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [addVideoModalVisible, setAddVideoModalVisible] = useState(false)

    const [updateVdocipherModalVisible, setUpdateVdocipherModalVisible] =
        useState(false)
    const [updateGumletModalVisible, setUpdateGumletModalVisible] =
        useState(false)
    const [fetchAndAddVdoCipherLoading, setFetchAndAddVdoCipherLoading] =
        useState(false)

    const [fetchVdoCipherLoading, setFetchVdoCipherLoading] = useState(false)
    const [fetchGumletLoading, setFetchGumletLoading] = useState(false)
    const [fetchAndAddGumletLoading, setFetchAndAddGumletLoading] =
        useState(false)
    const [form] = Form.useForm()

    const showUpdateMetaModal = () => {
        setUpdateMetaModalVisible(true)
    }

    const hideUpdateMetaModal = () => {
        setUpdateMetaModalVisible(false)
    }

    const showReplaceVideoModal = () => {
        setReplaceVideoModalVisible(true)
    }

    const hideReplaceVideoModal = () => {
        setReplaceVideoModalVisible(false)
    }

    const inputRef = useRef()

    useEffect(() => {
        setTimeout(() => {
            if (
                (addVideoModalVisible ||
                    updateGumletModalVisible ||
                    updateVdocipherModalVisible ||
                    editModalVisible ||
                    replaceVideoModalVisible ||
                    updateMetaModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [
        addVideoModalVisible,
        updateGumletModalVisible,
        updateVdocipherModalVisible,
        editModalVisible,
        replaceVideoModalVisible,
        updateMetaModalVisible,
        inputRef,
    ])

    const updateMeta = () => {
        form.validateFields(['title'])
            .then((values) => {
                setLoading(true)

                var postBody = new FormData()
                postBody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.UPDATE_VIDEO_META, postBody)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideUpdateMetaModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const replaceVideo = () => {
        form.validateFields(['title'])
            .then((values) => {
                setLoading(true)

                var postBody = new FormData()
                postBody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.REPLACE_VIDEO, postBody)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideReplaceVideoModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    }

    const searchVideo = () => {
        if (!query) {
            return
        }

        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_VIDEO + query)
            .then((response) => {
                var res = JSON.parse(response.data)
                setVideos(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPage = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setVideos((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showEditModal = (record) => {
        setSelectedVideo(record)
        setEditModalVisible(true)
        setTimeout(() => {
            form.setFieldsValue({ title: record.title })
        }, 100)
    }

    const hideEditModal = () => {
        setEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showEditModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>{record.title}</span>
                </span>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'VdoCipher',
            dataIndex: 'vdocipher',
            key: 'vdocipher',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateVdoCipherModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 10 }} />
                    </Button>
                    {record.hostVideoCipher ? (
                        <>
                            <span>{record.hostVideoCipher.hostVideoId}</span>
                            {record.hostVideoCipher.posterUrl ? (
                                <>
                                    <br />
                                    <a
                                        href={record.hostVideoCipher.posterUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginLeft: 15 }}
                                    >
                                        poster
                                    </a>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    fetchAndAddVdoCipher(record)
                                }}
                                style={{ padding: 0, marginLeft: 20 }}
                                loading={fetchAndAddVdoCipherLoading}
                            >
                                <DownloadOutlined />
                            </Button>
                        </>
                    )}
                </span>
            ),
        },
        {
            title: 'Gumlet',
            dataIndex: 'gumlet',
            key: 'gumlet',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateGumletModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 10 }} />
                    </Button>
                    {record.hostGumlet && record.hostGumlet.hostVideoId ? (
                        <>
                            <span>{record.hostGumlet.hostVideoId}</span>
                            {record.hostGumlet.posterUrl ? (
                                <>
                                    <br />
                                    <a
                                        href={record.hostGumlet.posterUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ marginLeft: 15 }}
                                    >
                                        poster
                                    </a>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    fetchAndAddGumlet(record)
                                }}
                                style={{ padding: 0, marginLeft: 20 }}
                                loading={fetchAndAddGumletLoading}
                            >
                                <DownloadOutlined />
                            </Button>
                        </>
                    )}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteVideo(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const updateVideo = () => {
        form.validateFields(['title'])
            .then((values) => {
                setLoading(true)
                values.id = selectedVideo.id

                var postBody = new FormData()
                postBody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.UPDATE_VIDEO, postBody)
                    .then((response) => {
                        message.destroy()
                        message.success('Updated')

                        var data = videos
                        data.forEach((video) => {
                            if (video.id === selectedVideo.id) {
                                video.title = values.title
                            }
                        })

                        setVideos(data)
                        hideEditModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteVideo = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_VIDEO, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                var data = videos
                setVideos(data.filter((video) => video.id !== id))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showUpdateVdoCipherModal = (record) => {
        setSelectedVideo(record)
        setUpdateVdocipherModalVisible(true)
        if (record.hostVideoCipher) {
            setTimeout(() => {
                form.setFieldsValue({
                    hostVdoCipherId: record.hostVideoCipher.hostVideoId,
                })
            }, 100)
        }
    }

    const hideUpdateVdoCipherModal = () => {
        setSelectedVideo({})
        setUpdateVdocipherModalVisible(false)
    }

    const updateVdoCipherData = () => {
        form.validateFields(['hostVdoCipherId'])
            .then((values) => {
                setLoading(true)

                values.id = selectedVideo.id

                var postBody = new FormData()
                postBody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.UPDATE_VIDEO_VDOCIPHER_DATA, postBody)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        var data = videos
                        data.forEach((video) => {
                            if (video.id === selectedVideo.id) {
                                video.title = values.title
                            }
                        })

                        hideUpdateVdoCipherModal()
                        searchVideo()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showAddVideoModal = () => {
        setAddVideoModalVisible(true)
    }

    const hideAddVideoModal = () => {
        setAddVideoModalVisible(false)
    }

    const addVideo = () => {
        form.validateFields(['title', 'hostVdoCipherId', 'hostGumletId'])
            .then((values) => {
                setLoading(true)

                var postBody = new FormData()
                postBody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.ADD_VIDEO, postBody)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)
                        hideAddVideoModal()
                    })
                    .finally((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const fetchVdoCipherId = () => {
        form.validateFields(['title'])
            .then((values) => {
                form.setFieldsValue({
                    hostVdoCipherId: '',
                })

                setFetchVdoCipherLoading(true)
                RestApi.doGet(
                    GLOBAL.URL.GET_VDOCIPHER_ID +
                        encodeURIComponent(values.title)
                )
                    .then((res) => {
                        form.setFieldsValue({
                            hostVdoCipherId: res.data,
                        })
                    })
                    .finally(() => {
                        setFetchVdoCipherLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateGumletModal = (record) => {
        setSelectedVideo(record)
        setUpdateGumletModalVisible(true)
        if (record.hostGumlet) {
            setTimeout(() => {
                form.setFieldsValue({
                    hostGumletId: record.hostGumlet.hostVideoId,
                })
            }, 100)
        }
    }

    const hideUpdateGumletModal = () => {
        setSelectedVideo({})
        setUpdateGumletModalVisible(false)
    }

    const updateGumletData = () => {
        form.validateFields(['hostGumletId'])
            .then((values) => {
                setLoading(true)

                values.id = selectedVideo.id

                var postBody = new FormData()
                postBody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.UPDATE_VIDEO_GUMLET_DATA, postBody)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideUpdateGumletModal()
                        searchVideo()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const fetchGumletId = () => {
        form.validateFields(['title'])
            .then((values) => {
                form.setFieldsValue({
                    hostGumletId: '',
                })

                setFetchGumletLoading(true)
                RestApi.doGet(
                    GLOBAL.URL.GET_GUMLET_ID + encodeURIComponent(values.title)
                )
                    .then((response) => {
                        form.setFieldsValue({
                            hostGumletId: response.data,
                        })
                    })
                    .finally(() => {
                        setFetchGumletLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const fetchAndAddGumlet = (record) => {
        setFetchAndAddGumletLoading(true)

        var payload = {}
        payload.id = record.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.FETCH_AND_ADD_GUMLET, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
                searchVideo()
            })
            .finally(() => {
                setFetchAndAddGumletLoading(false)
            })
    }

    const fetchAndAddVdoCipher = (record) => {
        setFetchAndAddVdoCipherLoading(true)

        var payload = {}
        payload.id = record.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.FETCH_AND_ADD_VDOCIPHER, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
                searchVideo()
            })
            .finally(() => {
                setFetchAndAddVdoCipherLoading(false)
            })
    }

    return (
        <div>
            <h2>Videos</h2>
            <div>
                <Button
                    onClick={showAddVideoModal}
                    disabled={loading}
                    size="small"
                >
                    Add Video
                </Button>
                <Button
                    onClick={showReplaceVideoModal}
                    disabled={loading}
                    style={{ marginLeft: 10 }}
                    size="small"
                >
                    Replace Video
                </Button>
                <Button
                    onClick={showUpdateMetaModal}
                    disabled={loading}
                    size="small"
                    style={{ marginLeft: 10 }}
                >
                    Update Meta
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Search
                    placeholder="Search Video"
                    onSearch={searchVideo}
                    style={{ width: 700 }}
                    autoFocus
                    allowClear
                    onChange={(e) => setQuery(e.target.value)}
                />
                <Table
                    columns={tableColumns}
                    dataSource={videos}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="title"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPage}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
            <Modal
                title="Update Video Meta"
                open={updateMetaModalVisible}
                onOk={updateMeta}
                onCancel={hideUpdateMetaModal}
                confirmLoading={loading}
                okText="Update"
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        style={{ marginBottom: 0, lineHeight: 0 }}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter video title!',
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            onPressEnter={updateMeta}
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Replace Video"
                open={replaceVideoModalVisible}
                onOk={replaceVideo}
                onCancel={hideReplaceVideoModal}
                confirmLoading={loading}
                okText="Replace"
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        style={{ marginBottom: 0, lineHeight: 0 }}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter video title!',
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            onPressEnter={replaceVideo}
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Video'}
                open={editModalVisible}
                onOk={updateVideo}
                confirmLoading={loading}
                onCancel={hideEditModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            onPressEnter={updateVideo}
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update VdoCipher Data'}
                open={updateVdocipherModalVisible}
                onOk={updateVdoCipherData}
                confirmLoading={loading}
                onCancel={hideUpdateVdoCipherModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Host ID"
                        name="hostVdoCipherId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            onPressEnter={updateVdoCipherData}
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Gumlet Data'}
                open={updateGumletModalVisible}
                onOk={updateGumletData}
                confirmLoading={loading}
                onCancel={hideUpdateGumletModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Host ID"
                        name="hostGumletId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            onPressEnter={updateGumletData}
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Video'}
                open={addVideoModalVisible}
                onOk={addVideo}
                confirmLoading={loading}
                onCancel={hideAddVideoModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="VdoCipher ID"
                        style={{ marginBottom: 0 }}
                        name="hostVdoCipherId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Button
                        type="default"
                        size="small"
                        style={{ marginTop: 5, marginLeft: 93 }}
                        onClick={fetchVdoCipherId}
                        loading={fetchVdoCipherLoading}
                    >
                        Fetch VdoCipher ID
                    </Button>
                    <Form.Item
                        label="Gumlet ID"
                        style={{ marginBottom: 0, marginTop: 5 }}
                        name="hostGumletId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Button
                        type="default"
                        size="small"
                        style={{ marginTop: 5, marginLeft: 93 }}
                        onClick={fetchGumletId}
                        loading={fetchGumletLoading}
                    >
                        Fetch Gumlet ID
                    </Button>
                </Form>
            </Modal>
        </div>
    )
}

export default Videos
