import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import { DatePicker } from 'antd'
import { makeStyles } from 'tss-react/mui'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import dayjs from 'dayjs'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

function RevenueSplitByState() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [chartData, setChartData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [options, setOptions] = useState({})

    const { RangePicker } = DatePicker
    const { classes, cx } = useStyles()

    useEffect(() => {
        getStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate])

    useEffect(() => {
        if (data.length === 0) {
            return
        }

        formChartObject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const getStats = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_REVENUE_SPLIT_BY_STATE +
                '?startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCurrentMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month, 1)

        var range = [dayjs(firstDay), dayjs(date)]

        return range
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const formChartObject = () => {
        var labels = []
        var dataSetsData = []
        data.forEach((dataRow) => {
            labels.push(dataRow.state)
            dataSetsData.push(dataRow.revenuePercent)
        })

        var dataSets = []

        var dataSet = {}
        dataSet.label = '% Revenue Share'
        dataSet.data = dataSetsData
        dataSet.backgroundColor = '#4bc0c0'
        dataSet.borderColor = '#4bc0c0'
        dataSet.fill = true

        dataSets.push(dataSet)

        var chartData = {}
        chartData.labels = labels
        chartData.datasets = dataSets

        var options = clone(StatsConstants.CHART_OPTIONS)

        setChartData(chartData)
        setOptions(options)
    }

    const getStatsTable = () => {
        return (
            <table className={classes.table}>
                <thead>
                    <tr className={classes.trHeader}>
                        <th className={classes.th}>{'#'}</th>
                        <th className={classes.th}>{'State'}</th>
                        <th className={classes.th}>{'Revenue'}</th>
                        <th className={classes.th}>{'Revenue %'}</th>
                        <th className={classes.th}>{'Count'}</th>
                    </tr>
                </thead>
                <tbody>{getTableBody()}</tbody>
            </table>
        )
    }

    const getTableBody = () => {
        var rows = []
        data.forEach((stat, index) => {
            rows.push(
                <tr key={index}>
                    <td className={classes.td}>{index + 1}</td>
                    <td className={classes.td}>{stat.state}</td>
                    <td className={cx(classes.td, classes.textAlignRight)}>
                        {stat.revenue}
                    </td>
                    <td className={cx(classes.td, classes.textAlignRight)}>
                        {stat.revenuePercent}
                    </td>
                    <td className={cx(classes.td, classes.textAlignRight)}>
                        {stat.count}
                    </td>
                </tr>
            )
        })

        return rows
    }

    return (
        <div>
            <h2>Revenue Split By State</h2>
            <div>
                <span>Date range: </span>
                <RangePicker
                    format="DD-MMM-YY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateChange}
                    style={{ marginLeft: 10 }}
                    defaultValue={getCurrentMonthRange()}
                />
                {loading ? (
                    <div style={{ marginTop: 10 }}>Fetchind data...</div>
                ) : null}
            </div>
            <Bar data={chartData} width={800} height={400} options={options} />
            <div style={{ marginTop: 10 }}>
                <span>{getStatsTable()}</span>
            </div>
        </div>
    )
}

const useStyles = makeStyles()((theme) => ({
    table: {
        border: '1px solid black',
        borderCollapse: 'collapse',
    },
    trHeader: {
        backgroundColor: '#32127A',
        color: 'white',
    },
    th: {
        padding: 5,
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'center',
    },
    td: {
        padding: 5,
        border: '1px solid black',
        borderCollapse: 'collapse',
    },
    textAlignRight: {
        textAlign: 'right',
    },
}))

export default RevenueSplitByState
