import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, message, Form } from 'antd'

const SubjectWiseRevenueReport = () => {
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [form] = Form.useForm()

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url =
                    GLOBAL.URL.GENERATE_SUBJECT_WISE_REVENUE_REPORT +
                    '?startDate=' +
                    startDate +
                    '&endDate=' +
                    endDate

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doGet(url)
                    .then((res) => {
                        message.success(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const { RangePicker } = DatePicker
    const dateFormat = 'DD-MMM-YY'

    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <div>
            <h2>Generate Subject Wise Revenue Report</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Date Range"
                    style={{ marginBottom: 0 }}
                    name="dateRange"
                    rules={[
                        {
                            required: true,
                            message: 'Date range needed!',
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateChange}
                    />
                </Form.Item>
            </Form>
            <Button
                type="primary"
                loading={loading}
                onClick={() => generateReport()}
                style={{ marginTop: 20, marginLeft: 10 }}
            >
                Generate Report
            </Button>
        </div>
    )
}

export default SubjectWiseRevenueReport
