import { Table, Button, Tag, Radio, Input, DatePicker, message } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from '@mui/material'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import TransactionConstants from '../Transactions/TransactionConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

function Transactions(props) {
    const { Search } = Input
    const { RangePicker } = DatePicker

    const [transactionData, setTransactionData] = useState([])
    const [transactionType, setTransactionType] = useState('')
    const [loadingMoreData, setLoadingMoreData] = useState(false)
    const [nextUrl, setNextUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(() => {
        getTrasactionData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionType, startDate, endDate])

    const getTrasactionData = () => {
        setLoading(true)

        var url =
            GLOBAL.URL.GET_TRANSACTIONS +
            transactionType +
            '&query=' +
            query +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setTransactionData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getMoreData = () => {
        if (!nextUrl) {
            return
        }

        setLoadingMoreData(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setTransactionData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally((error) => {
                setLoadingMoreData(false)
            })
    }

    const onChangeTransactionType = (event) => {
        setTransactionType(event.target.value)
    }

    const returnTransactionColor = (status) => {
        var color = ''
        if (status === TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS) {
            color = GLOBAL.COLORAPP.EMARALD
        } else if (
            status === TransactionConstants.TRANSACTION_CONSTANTS.PENDING ||
            status ===
                TransactionConstants.TRANSACTION_CONSTANTS.LOAN_PROCESSING
        ) {
            color = GLOBAL.COLORAPP.SUNFLOWER
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }

        return color
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const onSearch = (value) => {
        if (!value) {
            message.error('Please enter Transaction Id')
            return
        }

        props.navigate('/transactions/transactiondetails/' + value)
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => (
                <span>
                    <Link
                        href={
                            '/transactions/transactiondetails/' +
                            record.transactionId
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(
                                '/transactions/transactiondetails/' +
                                    record.transactionId
                            )
                        }}
                        underline="none"
                    >
                        <Button type="link">
                            <span style={{ textAlign: 'left' }}>
                                <span>{record.transactionId}</span>
                                <br></br>
                                <span style={{ fontSize: 12 }}>
                                    {record.initiatedAt}
                                </span>
                            </span>
                        </Button>
                    </Link>
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/transactions/transactiondetails/' +
                                    record.transactionId,
                                '_blank'
                            )
                        }}
                        underline="none"
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 10 }}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <Tag color={returnTransactionColor(record.status)}>
                        <span style={{ fontSize: 10 }}>
                            {record.status.toUpperCase()}
                        </span>
                    </Tag>
                )
            },
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <span>
                    {record.mrp + ' | '}
                    <b>{record.price}</b>
                    <br />
                    <span style={{ fontSize: 10 }}>({record.type})</span>
                </span>
            ),
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <span>
                    {record.user.firstName + ' ' + record.user.lastName}
                    <br></br>
                    {record.user.phone
                        ? record.user.isd + '-' + record.user.phone
                        : ''}
                </span>
            ),
        },
        {
            title: 'Credits',
            dataIndex: 'credits',
            key: 'credits',
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.discount}
                    {record.couponName ? ' | ' + record.couponName : null}
                    <br />
                    {record.comboDiscountDouble
                        ? 'Combo: ' + record.comboDiscount
                        : null}
                </span>
            ),
        },
        {
            title: 'Pincode',
            dataIndex: 'pincode',
            key: 'pincode',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.pincode ? record.pincode : ''}
                    <br />
                    {record.state}
                </span>
            ),
        },
        {
            title: 'OS',
            dataIndex: 'os',
            key: 'os',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.os}
                    <br />
                    {'(' + record.paymentGateway + ')'}
                </span>
            ),
        },
    ]

    return (
        <div>
            <h2>Transactions</h2>
            <div>
                <RangePicker
                    format="DD-MMM-YY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateChange}
                    style={{ width: 250 }}
                />
                <Search
                    placeholder="Transaction Id"
                    onSearch={(value) => onSearch(value)}
                    enterButton
                    style={{ marginLeft: 10, width: 300 }}
                    allowClear={true}
                />
                <Search
                    placeholder="Email / Phone / Coupon"
                    onChange={(e) => setQuery(e.target.value)}
                    onSearch={(value) => getTrasactionData()}
                    enterButton
                    allowClear={true}
                    style={{ marginLeft: 10, width: 300 }}
                />
            </div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
                <div>
                    <Radio.Group
                        onChange={onChangeTransactionType}
                        defaultValue=""
                    >
                        <Radio.Button value="">All</Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .SUCCESS
                            }
                        >
                            Success
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .PENDING
                            }
                        >
                            Pending
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .FAILURE
                            }
                        >
                            Failure
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .REFUNDED
                            }
                        >
                            Refunded
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .LOAN_PROCESSING
                            }
                        >
                            Loan Processing
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .LOAN_REJECTED
                            }
                        >
                            Loan Rejected
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .LOAN_APPROVED
                            }
                        >
                            Loan Approved
                        </Radio.Button>
                    </Radio.Group>
                    <Button
                        type="link"
                        style={{ padding: 0, marginLeft: 10 }}
                        onClick={() => getTrasactionData()}
                    >
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 16 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 16 }} />
                        )}
                    </Button>
                </div>
                <Table
                    columns={columns}
                    dataSource={transactionData}
                    pagination={false}
                    size="small"
                    rowKey="transactionId"
                    loading={loading}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loadingMoreData}
                        onClick={getMoreData}
                        style={{ marginTop: 10 }}
                        size="small"
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
        </div>
    )
}

export default Transactions
