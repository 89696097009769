import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Popconfirm,
    notification,
    InputNumber,
    Radio,
    Input,
    DatePicker,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import dayjs from 'dayjs'

function CartDropCoupons(props) {
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [couponTypes, setCouponTypes] = useState([])

    const [loadingCoupons, setLoadingCoupons] = useState(false)
    const [coupons, setCoupons] = useState([])
    const [creatCouponModalVisible, setCreateCouponModalVisible] =
        useState(false)
    const [updateCouponModalVisible, setUpdateCouponModalVisible] =
        useState(false)
    const [page, setPage] = useState(1)
    const [moreButtonVisible, setMoreButtonVisible] = useState(true)
    const [users, setUsers] = useState([])
    const [modalLoading, setModalLoading] = useState(false)
    const [selectedCoupon, setSelectedCoupon] = useState({ user: {} })
    const [endDateString, setEndDateString] = useState('')
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        getDiscountSlabs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (
                (addEditModalVisible || creatCouponModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, creatCouponModalVisible, inputRef])

    const getDiscountSlabs = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.ECOMMERCE.GET_COUPON_CART_DROP_DISCOUNTS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'MRP Range Start',
            dataIndex: 'mrpRangeStart',
            key: 'mrpRangeStart',
            width: 150,
        },
        {
            title: 'MRP Range End',
            dataIndex: 'mrpRangeEnd',
            key: 'mrpRangeEnd',
            width: 150,
        },
        {
            title: 'Discount',
            dataIndex: 'discount',
            key: 'discount',
            width: 150,
            render: (text, record) => (
                <span>
                    {record.discount + ' (' + record.discountType + ')'}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteSlab(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const tableCouponsColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showUpdateCouponModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Coupon',
            dataIndex: 'couponName',
            key: 'couponName',
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => navigateToUserDetails(record.user)}
                    >
                        {record.user.firstName} {record.user.lastName}
                    </Button>
                </span>
            ),
        },
        {
            title: 'Limit',
            dataIndex: 'limit',
            key: 'limit',
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName
                        ? record.updatedBy.firstName
                        : null}
                </span>
            ),
        },
        {
            title: 'Is Sign Up',
            dataIndex: 'signupCoupon',
            key: 'signupCoupon',
            render: (text, record) => (
                <span>{record.signupCoupon ? 'Yes' : 'No'}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCoupon(record.user.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const navigateToUserDetails = (user) => {
        props.navigate('/userdetails/' + user.id)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showAddEditModal = (record = {}) => {
        if (couponTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COUPON_TYPES).then((res) => {
                setCouponTypes(res.data)
            })
        }

        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const deleteSlab = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.ECOMMERCE.DELETE_COUPON_CART_DROP_DISCOUNT,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getDiscountSlabs()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const addOrUpdateSlab = () => {
        form.validateFields([
            'mrpRangeStart',
            'mrpRangeEnd',
            'discount',
            'discountType',
        ])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ECOMMERCE.ADD_COUPON_CART_DROP_DISCOUNT
                if (selectedRecord.id) {
                    url = GLOBAL.URL.ECOMMERCE.UPDATE_COUPON_CART_DROP_DISCOUNT
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getDiscountSlabs()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const couponTypeView = () => {
        var couponTypeView = []
        couponTypes.forEach((item) => {
            couponTypeView.push(
                <Radio.Button value={item} key={item}>
                    {item.toUpperCase()}
                </Radio.Button>
            )
        })

        return couponTypeView
    }

    useEffect(() => {
        getCoupons()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getCoupons = () => {
        setLoadingCoupons(true)

        RestApi.doGet(
            GLOBAL.URL.ECOMMERCE.GET_CART_DROP_COUPONS + '?page=' + page
        )
            .then((res) => {
                setCoupons((preState) => {
                    return [...preState, ...res.data]
                })

                if (res.data.length === 0) {
                    setMoreButtonVisible(false)
                } else {
                    setMoreButtonVisible(true)
                }
            })
            .finally(() => {
                setLoadingCoupons(false)
            })
    }

    const searchCoupon = (query) => {
        if (!query) {
            setCoupons([])
            if (page === 1) {
                getCoupons()
            } else {
                setPage(1)
            }

            return
        }

        setLoadingCoupons(true)
        setMoreButtonVisible(false)

        RestApi.doGet(GLOBAL.URL.ECOMMERCE.SEARCH_CART_DROP_COUPONS + query)
            .then((res) => {
                setCoupons(res.data)
            })
            .finally(() => {
                setLoadingCoupons(false)
            })
    }

    const showCreatCouponModal = () => {
        setUsers([])
        setCreateCouponModalVisible(true)
    }

    const hideCreatCouponModal = () => {
        setCreateCouponModalVisible(false)
    }

    const searchUser = (query) => {
        setModalLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setUsers(res.data)
            })
            .finally(() => {
                setModalLoading(false)
            })
    }

    const userSearchColumns = [
        {
            title: 'Create',
            dataIndex: 'create',
            key: 'create',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => createCoupon(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="default" size="small">
                        Create
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const createCoupon = (userId) => {
        setModalLoading(true)

        var payload = {}
        payload.userId = userId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ECOMMERCE.CREATE_CART_DROP_COUPON, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideCreatCouponModal()
                setCoupons([])
                if (page === 1) {
                    getCoupons()
                } else {
                    setPage(1)
                }
            })
            .finally(() => {
                setModalLoading(false)
            })
    }

    const deleteCoupon = (userId) => {
        setLoadingCoupons(true)

        var payload = {}
        payload.userId = userId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ECOMMERCE.DELETE_CART_DROP_COUPON, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                setCoupons([])
                if (page === 1) {
                    getCoupons()
                } else {
                    setPage(1)
                }
            })
            .finally(() => {
                setLoadingCoupons(false)
            })
    }

    const showUpdateCouponModal = (record) => {
        setSelectedCoupon(record)
        setUpdateCouponModalVisible(true)
        setEndDateString(record.endDate)

        setTimeout(() => {
            var coupon = clone(record)
            var endDate = dayjs(record.endDate, 'DD-MMM-YY HH:mm')
            coupon.endDate = endDate

            form.setFieldsValue(coupon)
        }, 100)
    }

    const hideUpdateCouponModal = () => {
        setUpdateCouponModalVisible(false)
    }

    const updateCoupon = () => {
        form.validateFields(['limit', 'endDate'])
            .then((values) => {
                setModalLoading(true)

                values.endDate = endDateString
                values.userId = selectedCoupon.user.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ECOMMERCE.UPDATE_CART_DROP_COUPON,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateCouponModal()
                        setCoupons([])
                        if (page === 1) {
                            getCoupons()
                        } else {
                            setPage(1)
                        }
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onChangeEndDate = (value, dateString) => {
        setEndDateString(dateString)
    }

    return (
        <div>
            <div>
                <span style={{ fontSize: 20 }}>Cart Drop Discount Slabs</span>
                <Button
                    type="default"
                    size="small"
                    onClick={showAddEditModal}
                    style={{ marginLeft: 20 }}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="mrpRangeStart"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Discount Slab'}
                open={addEditModalVisible}
                onOk={addOrUpdateSlab}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="MRP Range Start"
                        name="mrpRangeStart"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="MRP Range End"
                        name="mrpRangeEnd"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Discount"
                        name="discount"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Discount Type"
                        name="discountType"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Coupon type',
                            },
                        ]}
                    >
                        <Radio.Group>{couponTypeView()}</Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <div style={{ marginTop: 10 }}>
                <div>
                    <span style={{ fontSize: 20 }}>Coupons</span>
                    <Button
                        type="default"
                        size="small"
                        onClick={showCreatCouponModal}
                        style={{ marginLeft: 20 }}
                    >
                        Create
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Search
                        placeholder="Search by email / phone / coupon"
                        onSearch={(value) => searchCoupon(value)}
                        style={{ width: 500 }}
                        autoFocus
                        allowClear
                    />
                </div>
                <Table
                    columns={tableCouponsColumns}
                    dataSource={coupons}
                    pagination={false}
                    size="small"
                    loading={loadingCoupons}
                    rowKey="couponName"
                    style={{ marginTop: 10 }}
                />
                {moreButtonVisible ? (
                    <Button
                        type="primary"
                        loading={loadingCoupons}
                        onClick={() => setPage(page + 1)}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
                <Modal
                    title={'Create Coupon'}
                    open={creatCouponModalVisible}
                    onCancel={hideCreatCouponModal}
                    destroyOnClose={true}
                    footer={null}
                    width={800}
                >
                    <Search
                        placeholder="Search User"
                        onSearch={(value) => searchUser(value)}
                        style={{ width: 600 }}
                        autoFocus
                        ref={inputRef}
                    />
                    <Table
                        columns={userSearchColumns}
                        dataSource={users}
                        pagination={false}
                        size="small"
                        loading={modalLoading}
                        rowKey="email"
                        style={{ marginTop: 10 }}
                    />
                </Modal>
                <Modal
                    title={
                        'Update Coupon - ' +
                        selectedCoupon.couponName +
                        ' (' +
                        selectedCoupon.user.firstName +
                        ' ' +
                        selectedCoupon.user.lastName +
                        ')'
                    }
                    open={updateCouponModalVisible}
                    onOk={updateCoupon}
                    confirmLoading={modalLoading}
                    onCancel={hideUpdateCouponModal}
                    okText={'Update'}
                    destroyOnClose={true}
                    width={800}
                >
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Limit"
                            name="limit"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber min={1} />
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                            style={{ marginBottom: 10 }}
                            name="endDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <DatePicker
                                showTime
                                placeholder="Select Date"
                                format="DD-MMM-YY HH:mm"
                                onChange={onChangeEndDate}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}

export default CartDropCoupons
