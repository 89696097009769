import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    Form,
    notification,
    message,
    Radio,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import TinyMceEditor from '../Common/TinyMceEditor'
import clone from 'clone'
import Paper from '@mui/material/Paper'
import { EditTwoTone } from '@ant-design/icons'

function EmailTemplates() {
    const [form] = Form.useForm()
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [content, setContent] = useState('')
    const [previewModalVisible, setPreviewModalVisible] = useState(false)
    const [testing, setTesting] = useState(false)
    const [userPlaceHolders, setUserPlaceHolders] = useState([])
    const [isEmailCustomHtml, setIsEmailCustomHtml] = useState(false)
    const [
        emailCustomHtmlPreviewModalVisible,
        setEmailCustomHtmlPreviewModalVisible,
    ] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        getTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getTemplates = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.EMAIL.GET_EMAIL_TEMPLATES)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageTemplates = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.EMAIL.ENABLE_EMAIL_TEMPLATE
        if (record.enabled) {
            url = GLOBAL.URL.EMAIL.DISABLE_EMAIL_TEMPLATE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: record.uid }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(data)
                fullData.forEach((item) => {
                    if (item.uid === record.uid) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const deleteTemplate = (uid) => {
        setLoading(true)

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ uid: uid }))

        RestApi.doPost(GLOBAL.URL.EMAIL.DELETE_EMAIL_TEMPLATE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTemplates()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showPreview = (record) => {
        setSelectedRecord(record)
        setPreviewModalVisible(true)
    }

    const hidePreview = () => {
        setPreviewModalVisible(false)
    }

    const testEmail = (uid) => {
        setTesting(true)
        message.loading('Sending Test Email', 0)

        RestApi.doGet(GLOBAL.URL.EMAIL.TEST_EMAIL_TEMPLATE + '?uid=' + uid)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setTesting(false)
                message.destroy()
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'blogId',
            key: 'blogId',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => <span>{record.title}</span>,
        },
        {
            title: 'Template Name',
            dataIndex: 'templateName',
            key: 'templateName',
            render: (text, record) => <span>{record.templateName}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => showPreview(record)}
                        style={{ padding: 0 }}
                    >
                        Preview Email
                    </Button>
                    <br />
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => testEmail(record.uid)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            size="small"
                            disabled={testing}
                            style={{ padding: 0, fontSize: 11 }}
                        >
                            Test
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Updated By',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}
                    <br></br>
                    <span style={{ fontSize: 10 }}>{record.updatedAt}</span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteTemplate(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)

        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }

        if (record.uid) {
            setContent(record.emailBody)
            setIsEmailCustomHtml(record.customHtml)
        } else {
            setContent('')
        }

        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({
                    templateName: record.templateName,
                    title: record.title,
                    emailSubject: record.emailSubject,
                    emailPreHeader: record.preHeader,
                })

                if (record.customHtml) {
                    form.setFieldsValue({
                        emailBody: record.emailBody,
                    })
                }
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setSelectedRecord({})
        setAddEditModalVisible(false)
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getTemplate = (uid) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.EMAIL.GET_EMAIL_TEMPLATE_DATA + '?uid=' + uid)
            .then((response) => {
                var fullData = clone(data)
                fullData.forEach((item, index) => {
                    if (item.uid === uid) {
                        fullData[index] = response.data
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const addOrUpdate = () => {
        form.validateFields()
            .then((values) => {
                if (!content) {
                    notification['error']({
                        message: 'Email body content cannot be empty',
                    })

                    return
                }

                setLoading(true)

                values.emailIsCustomHtml = isEmailCustomHtml
                values.emailBody = content

                var url = GLOBAL.URL.EMAIL.ADD_EMAIL_TEMPLATE
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.EMAIL.UPDATE_EMAIL_TEMPLATE
                    values.uid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        if (selectedRecord.uid) {
                            getTemplate(selectedRecord.uid)
                        } else {
                            getTemplates()
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const getPlaceHolders = () => {
        var res = ''
        userPlaceHolders.forEach((userPlaceHolder) => {
            if (res) {
                res = res + ', '
            }

            res = res + userPlaceHolder
        })

        return res
    }

    const getIsEmailCustomHtmlOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )

        return options
    }

    const onChangeIsEmailCustomHtml = (event) => {
        setIsEmailCustomHtml(event.target.value)
        setContent('')
    }

    const showEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(true)
    }

    const hideEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(false)
    }

    return (
        <div>
            <h2>Email Templates</h2>
            <div>
                <Button type="primary" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageTemplates}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure Template'}
                open={addEditModalVisible}
                onOk={addOrUpdate}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Template Name"
                        name="templateName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email Subject"
                        name="emailSubject"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div style={{ marginTop: 15, marginBottom: 15 }}>
                        <span
                            style={{
                                color: 'black',
                                marginRight: 10,
                                fontWeight: 'bold',
                            }}
                        >
                            Email Body Custom Html?
                        </span>
                        <Radio.Group
                            onChange={onChangeIsEmailCustomHtml}
                            value={isEmailCustomHtml}
                        >
                            {getIsEmailCustomHtmlOptions()}
                        </Radio.Group>
                    </div>
                    {isEmailCustomHtml ? (
                        <>
                            <Button
                                type="primary"
                                style={{
                                    marginBottom: 10,
                                    marginLeft: 158,
                                }}
                                onClick={showEmailCustomHtmlPreview}
                            >
                                Preview Html
                            </Button>
                            <Form.Item
                                label="Custom Html"
                                name="emailBody"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={5}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <Form.Item
                                label="Pre Header"
                                name="emailPreHeader"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <div style={{ marginTop: 10 }}>
                                <h4>Email Body</h4>
                                <span style={{ fontSize: 12 }}>
                                    {'User place holders:  '}
                                    {getPlaceHolders()}
                                </span>
                                <TinyMceEditor
                                    height={400}
                                    handleEditorChange={handleEditorChange}
                                    initialValue={content}
                                />
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
            <Modal
                title={'Preview (' + selectedRecord.title + ')'}
                open={previewModalVisible}
                onCancel={hidePreview}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <h4>Email Subject</h4>
                <div style={{ marginBottom: 20 }}>
                    {selectedRecord.emailSubject}
                </div>
                {selectedRecord.preHeader ? (
                    <>
                        <h4>Pre Header</h4>
                        <p style={{ marginBottom: 20 }}>
                            {selectedRecord.preHeader}
                        </p>
                    </>
                ) : null}
                <h4>Email Body</h4>
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(
                        selectedRecord.emailBody
                    )}
                />
            </Modal>
            <Modal
                title={'Custom HTML Preview'}
                open={emailCustomHtmlPreviewModalVisible}
                onCancel={hideEmailCustomHtmlPreview}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <Paper
                    dangerouslySetInnerHTML={createMarkupWeb(content)}
                    style={{ padding: 10 }}
                />
            </Modal>
        </div>
    )
}

export default EmailTemplates
