import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { DatePicker, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import dayjs from 'dayjs'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const FILTER_TYPE_MRP = 'MRP'
const FILTER_TYPE_NET = 'Net'
const FILTER_TYPE_NET_BEFORE_GST = 'Net Before GST'

class Arpu extends React.Component {
    state = {
        loading: false,
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        startDate: '',
        endDate: '',
        mapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,
        lastMonthStartDate: '',
        lastMonthEndDate: '',
        currentMonthFilterType: FILTER_TYPE_MRP,
        lastMonthFilterType: FILTER_TYPE_MRP,
        currentMonthData: [],
        lastMonthData: [],
    }

    componentDidMount() {
        this.getData(true)
        this.getData(false)
    }

    getData(currentMonth) {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.GET_ARPU
        if (currentMonth && this.state.startDate) {
            url =
                url +
                '?startDate=' +
                this.state.startDate +
                '&endDate=' +
                this.state.endDate
        } else if (!currentMonth && this.state.lastMonthStartDate) {
            url =
                url +
                '?startDate=' +
                this.state.lastMonthStartDate +
                '&endDate=' +
                this.state.lastMonthEndDate
        } else if (!currentMonth && !this.state.lastMonthStartDate) {
            url = url + '?prevMonth=true'
        }

        RestApi.doGet(url)
            .then((res) => {
                if (currentMonth) {
                    this.setState({ currentMonthData: res.data }, () =>
                        this.drawChart(currentMonth)
                    )
                } else {
                    this.setState({ lastMonthData: res.data }, () =>
                        this.drawChart(currentMonth)
                    )
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart(currentMonth) {
        var labels = []
        var arpu = []
        var users = []

        var response = []
        var filterType = ''
        if (currentMonth) {
            filterType = this.state.currentMonthFilterType
            response = this.state.currentMonthData
        } else {
            filterType = this.state.lastMonthFilterType
            response = this.state.lastMonthData
        }

        response.forEach((row) => {
            labels.push(row.label)
            users.push(row.users)

            if (filterType === FILTER_TYPE_MRP) {
                arpu.push(row.arpu)
            } else if (filterType === FILTER_TYPE_NET) {
                arpu.push(row.net)
            } else if (filterType === FILTER_TYPE_NET_BEFORE_GST) {
                arpu.push(row.netOfGst)
            }
        })

        var arpuDataSet = {}
        arpuDataSet.type = 'bar'
        arpuDataSet.data = arpu
        arpuDataSet.label = 'ARPU'
        arpuDataSet.backgroundColor = '#4bc0c0'
        arpuDataSet.borderColor = '#4bc0c0'
        arpuDataSet.fill = true
        arpuDataSet.yAxisID = 'y-1'

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = users
        countDataSet.label = 'Users'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'
        countDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(arpuDataSet)

        var options = StatsConstants.CHART_OPTIONS_TWO_LEVELS
        options.plugins.title.text = 'ARPU'

        if (currentMonth) {
            this.setState({
                mapData: mapData,
                options: options,
            })
        } else {
            this.setState({
                mapDataSecond: mapData,
                options: options,
            })
        }
    }

    onDateChange = (value, dateString) => {
        this.setState(
            {
                startDate: dateString[0],
                endDate: dateString[1],
            },
            () => {
                this.getData(true)
            }
        )
    }

    onDateChangeLastMonth = (value, dateString) => {
        this.setState(
            {
                lastMonthStartDate: dateString[0],
                lastMonthEndDate: dateString[1],
            },
            () => {
                this.getData(false)
            }
        )
    }

    getCurrentMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month, 1)

        var range = [dayjs(firstDay), dayjs(date)]

        return range
    }

    getLastMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month - 1, 1)

        var lastDay = new Date()
        lastDay.setFullYear(year, month, 0)

        var range = [dayjs(firstDay), dayjs(lastDay)]

        return range
    }

    onChangeCurrentMonthFilterType = (event) => {
        this.setState(
            {
                currentMonthFilterType: event.target.value,
            },
            () => {
                this.drawChart(true)
            }
        )
    }

    onChangeLastMonthFilterType = (event) => {
        this.setState(
            {
                lastMonthFilterType: event.target.value,
            },
            () => {
                this.drawChart(false)
            }
        )
    }

    render() {
        const { RangePicker } = DatePicker

        return (
            <div>
                <div>
                    <span>Date range: </span>
                    <RangePicker
                        format="DD-MMM-YY"
                        placeholder={['Start Date', 'End Date']}
                        onChange={this.onDateChange}
                        style={{ marginLeft: 10 }}
                        defaultValue={this.getCurrentMonthRange()}
                    />
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <Radio.Group
                            onChange={this.onChangeCurrentMonthFilterType}
                            defaultValue={this.state.currentMonthFilterType}
                        >
                            <Radio.Button value={FILTER_TYPE_MRP}>
                                {FILTER_TYPE_MRP}
                            </Radio.Button>
                            <Radio.Button value={FILTER_TYPE_NET}>
                                {FILTER_TYPE_NET}
                            </Radio.Button>
                            <Radio.Button value={FILTER_TYPE_NET_BEFORE_GST}>
                                {FILTER_TYPE_NET_BEFORE_GST}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    {this.state.loading ? (
                        <div style={{ marginTop: 10 }}>Fetchind data...</div>
                    ) : null}
                    <Bar
                        data={this.state.mapData}
                        width={800}
                        height={400}
                        options={this.state.options}
                    />
                </div>
                <div style={{ marginTop: 25 }}>
                    <span>Date range: </span>
                    <RangePicker
                        format="DD-MMM-YY"
                        placeholder={['Start Date', 'End Date']}
                        onChange={this.onDateChangeLastMonth}
                        style={{ marginLeft: 10 }}
                        defaultValue={this.getLastMonthRange()}
                    />
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        <Radio.Group
                            onChange={this.onChangeLastMonthFilterType}
                            defaultValue={this.state.lastMonthFilterType}
                        >
                            <Radio.Button value={FILTER_TYPE_MRP}>
                                {FILTER_TYPE_MRP}
                            </Radio.Button>
                            <Radio.Button value={FILTER_TYPE_NET}>
                                {FILTER_TYPE_NET}
                            </Radio.Button>
                            <Radio.Button value={FILTER_TYPE_NET_BEFORE_GST}>
                                {FILTER_TYPE_NET_BEFORE_GST}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    {this.state.loading ? (
                        <div style={{ marginTop: 10 }}>Fetchind data...</div>
                    ) : null}
                    <Bar
                        data={this.state.mapDataSecond}
                        width={800}
                        height={400}
                        options={this.state.options}
                    />
                </div>
            </div>
        )
    }
}

export default Arpu
