import React, { useEffect, useState } from 'react'
import { Input, Button, message, Select, Form } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import RouteData from '../Common/RouteData'
import { goBack } from './../../Util/ILUtil'

const { Option } = Select
const { TextArea } = Input
const STATS_CONSTANT = 'Stats'

const HomeItemsForm = (props) => {
    const [pageData, setPageData] = useState([])
    const [statsType, setStatsType] = useState([])
    const [selectedType, setSelectedType] = useState('')
    const [form] = Form.useForm()
    let typesData = []
    let homeItemData = {}
    if (props.location.state) {
        typesData = props.location.state.typesData
        homeItemData = props.location.state.homeItemData
    }

    useEffect(() => {
        getRequiredData()
        checkEdit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getRequiredData = () => {
        RestApi.doGet(GLOBAL.URL.GET_LIST_OF_PAGES).then((res) => {
            setPageData(res.data)
        })
    }
    const checkEdit = () => {
        if (homeItemData) {
            form.setFieldsValue(homeItemData)
            if (homeItemData.type === STATS_CONSTANT) {
                onChangeType(homeItemData.type)
            }
        }
    }
    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                var routeObj = {}
                routeObj.key = 'route'
                routeObj.value = values.route

                if (!values.routeData) {
                    values.routeData = []
                }

                values.routeData.push(routeObj)

                var url = GLOBAL.URL.ADD_HOME_ITEM
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_HOME_ITEM
                    values['id'] = editRecord.id
                }
                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyCourseObject).then((response) => {
                    message.success('Edit Sucessfull', 5)
                    goBack(props.navigate)
                })
            })
            .catch(() => {
                //empty catch block
            })
    }
    const onChangeType = (value) => {
        setSelectedType(value)
        if (value === STATS_CONSTANT) {
            if (Object.keys(statsType).length === 0) {
                RestApi.doGet(GLOBAL.URL.GET_STATS_TYPE).then((response) => {
                    setStatsType(response.data)
                })
            }
        }
    }

    var pageDetails = []
    pageData.forEach((item) => {
        pageDetails.push(<Option value={item.id}>{item.title}</Option>)
    })

    var typeDetails = []
    typesData.forEach((item) => {
        typeDetails.push(<Option value={item}>{item}</Option>)
    })

    var statsDetails = []
    statsType.forEach((item) => {
        statsDetails.push(<Option value={item}>{item}</Option>)
    })

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <div>
            <h3>Add Home Item</h3>
            <div style={{ marginTop: 10 }}>
                <UploadAndGetLink />
            </div>
            <Form
                layout="horizontal"
                form={form}
                {...formItemLayout}
                style={{ marginTop: 20 }}
            >
                <Form.Item
                    label="Title"
                    style={{ marginBottom: 10 }}
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Title',
                        },
                    ]}
                >
                    <Input placeholder="Please enter Title" />
                </Form.Item>
                <Form.Item
                    label="Image Url"
                    style={{ marginBottom: 10 }}
                    name="imageUrl"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Image Url',
                        },
                        {
                            type: 'url',
                            message: 'Please enter Image Url',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Please enter Image Url"
                    />
                </Form.Item>
                <Form.Item
                    label="Wide Image Url"
                    style={{ marginBottom: 10 }}
                    name="wideImageUrl"
                    rules={[
                        {
                            required: false,
                            message: 'Please enter Image Url',
                        },
                        {
                            type: 'url',
                            message: 'Please enter Image Url',
                        },
                    ]}
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Please enter Image Url"
                    />
                </Form.Item>
                <Form.Item
                    label="Select type"
                    style={{ marginBottom: 10 }}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: 'Please select type',
                        },
                    ]}
                >
                    <Select
                        placeholder="Please select type"
                        onChange={onChangeType}
                    >
                        {typeDetails}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select Stats Type"
                    style={{ marginBottom: 10 }}
                    name="data"
                >
                    <Select
                        placeholder="Please select Stats Type"
                        disabled={selectedType !== STATS_CONSTANT}
                    >
                        {statsDetails}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Enter description"
                    style={{ marginBottom: 10 }}
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter description',
                        },
                    ]}
                >
                    <TextArea
                        rows={2}
                        placeholder="Enter description"
                        autoSize
                    />
                </Form.Item>
                <Form.Item
                    label="Select Page"
                    style={{ marginBottom: 10 }}
                    name="pageId"
                >
                    <Select placeholder="Please select Page">
                        {pageDetails}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Video Url"
                    style={{ marginBottom: 10 }}
                    name="videoUrl"
                >
                    <Input style={{ width: '100%' }} placeholder="Video url" />
                </Form.Item>
                <Form.Item
                    label="External Url"
                    style={{ marginBottom: 10 }}
                    name="externalUrl"
                >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="External url"
                    />
                </Form.Item>
                <RouteData
                    form={form}
                    intialRoute={
                        props.location.state &&
                        props.location.state.homeItemData
                            ? props.location.state.homeItemData.route
                            : null
                    }
                />
            </Form>
            {homeItemData ? (
                <Button
                    type="primary"
                    onClick={() => {
                        handleSubmit(homeItemData)
                    }}
                >
                    Update
                </Button>
            ) : (
                <Button
                    type="primary"
                    onClick={() => {
                        handleSubmit()
                    }}
                >
                    Submit
                </Button>
            )}
        </div>
    )
}

export default HomeItemsForm
