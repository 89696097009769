import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from '@mui/material'
import { useParams } from 'react-router-dom'

function GetQuestionsInTest(props) {
    const [loading, setLoading] = useState(false)
    const [questions, setQuestions] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [testData, setTestData] = useState({})

    const { testId } = useParams()

    useEffect(() => {
        getQuestions()
        getTestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getQuestions = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.GET_QUESTIONS_IN_TEST + testId)
            .then((res) => {
                setQuestions(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getTestData = () => {
        RestApi.doGet(GLOBAL.URL.GET_TEST + testId).then((res) => {
            setTestData(res.data)
        })
    }

    const deleteSelectedQuestions = () => {
        if (selectedRows.length === 0) {
            message.error('No questions selected!')
        }

        setLoading(true)

        var ids = []
        selectedRows.forEach((item) => {
            ids.push(item.questionId)
        })

        var payload = {}
        payload.ids = ids

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_QUESTIONS, postBody)
            .then((response) => {
                setSelectedRows([])

                message.destroy()
                message.success(response.data)

                getQuestions()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/mcq/questiondetails/' + record.questionId}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(
                                '/mcq/questiondetails/' + record.questionId
                            )
                        }}
                        underline="none"
                        target="_blank"
                    >
                        <Button type="link">{record.question}</Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Section',
            dataIndex: 'sectionName',
            key: 'sectionName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteQuestion(record.questionId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    const deleteQuestion = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_QUESTION, formbody)
            .then((response) => {
                message.success(response.data)
                getQuestions()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>
                Questions in test:{' '}
                {testData.id
                    ? testData.testNameUnique
                        ? testData.testNameUnique
                        : testData.subject + '(' + testData.name + ')'
                    : null}
            </h2>
            <Link
                href={'/mcq/addquestion'}
                onClick={(event) => {
                    event.preventDefault()
                    props.navigate('/mcq/addquestion', {
                        state: { testId: testId, question: null },
                    })
                }}
                underline="none"
            >
                <Button
                    type="primary"
                    size="small"
                    disabled={loading ? true : false}
                >
                    Add Question
                </Button>
            </Link>
            <Popconfirm
                title="Confirm deleting selected questions."
                onConfirm={deleteSelectedQuestions}
                type="danger"
                okText="Yes"
                cancelText="No"
            >
                <Button
                    type="default"
                    onClick={() => {}}
                    style={{ marginLeft: 10 }}
                    disabled={
                        selectedRows.length === 0 || loading ? true : false
                    }
                    size="small"
                >
                    Delete
                </Button>
            </Popconfirm>
            <Table
                columns={columns}
                dataSource={questions}
                pagination={false}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                loading={loading}
                style={{ marginTop: 10 }}
                size="small"
                rowKey="id"
            />
        </div>
    )
}

export default GetQuestionsInTest
