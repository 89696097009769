import { PlusOutlined } from '@ant-design/icons'
import { Attachment } from '@mui/icons-material'
import { Button, Image, Upload } from 'antd'
import React, { useState } from 'react'

export const processClipboardAttachment = (
    event,
    setAttachmentFileList = () => {}
) => {
    const items = event.clipboardData.items
    for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
            const file = items[i].getAsFile()
            const isImage = items[i].type.startsWith('image/')
            const uniqueId =
                Date.now().toString() +
                Math.random().toString(36).substring(2, 9)
            const newFile = {
                uid: uniqueId,
                name: file.name,
                status: 'done',
                originFileObj: file,
                ...(isImage && { url: URL.createObjectURL(file) }),
            }
            setAttachmentFileList((prev) => [...prev, newFile])
            event.preventDefault()
        }
    }
}

function ILAttachmentFileList({
    attachmentFileList = [],
    setAttachmentFileList = () => {},
    customStyle = {},
}) {
    const [attachmentPrevImg, setAttachmentPrevImage] = useState('')
    const [attachmentPrevImgOpen, setAttachmentPrevImageOpen] = useState('')

    const handleAttachmentChange = ({ fileList: newFileList }) =>
        setAttachmentFileList(newFileList)

    const handleAttachmentDelete = (file) => {
        setAttachmentFileList((prev) =>
            prev.filter((item) => item.uid !== file.uid)
        )
    }

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setAttachmentPrevImage(file.url || file.preview)
        setAttachmentPrevImageOpen(true)
    }

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    )

    return (
        <div>
            <div
                style={{
                    marginTop: 10,
                    marginLeft: customStyle.marginLeft ?? 40,
                }}
            >
                <Upload
                    listType="picture-card"
                    fileList={attachmentFileList}
                    onPreview={handlePreview}
                    onChange={handleAttachmentChange}
                >
                    {uploadButton}
                </Upload>

                {attachmentPrevImg && (
                    <Image
                        wrapperStyle={{
                            display: 'none',
                        }}
                        preview={{
                            visible: attachmentPrevImgOpen,
                            onVisibleChange: (visible) =>
                                setAttachmentPrevImageOpen(visible),
                            afterOpenChange: (visible) =>
                                !visible && setAttachmentPrevImage(''),
                        }}
                        src={attachmentPrevImg}
                    />
                )}
            </div>

            <div
                style={{
                    marginTop: 20,
                    marginBottom: 10,
                    maxWidth: '350px',
                    marginLeft: 40,
                }}
            >
                {attachmentFileList.map((file) => (
                    <div
                        key={file.uid}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: 10,
                        }}
                    >
                        <Attachment
                            sx={{
                                fontSize: '18px',
                                marginRight: '5px',
                            }}
                        />
                        <span style={{ flex: 1 }}>{file.name}</span>
                        <Button
                            type="link"
                            onClick={() => handleAttachmentDelete(file)}
                        >
                            Remove
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ILAttachmentFileList
