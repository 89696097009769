import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Radio,
    Select,
    Form,
    message,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import IlEmployeeLeave from './IlEmployeeLeave'
import IlEmployeeCompOff from './ILEmployeeCompOff'
import IlAllLogins from './IlAllLogins'
import { EditTwoTone } from '@ant-design/icons'
import ILEmployeeCalendarView from './ILEmployeeCalendarView'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from '@mui/material'

const FILTER_ACTIVE = 'active'
const FILTER_INACTIVE = 'inactive'
const FILTER_ALL = 'all'
const FILTER_SEARCH = 'search'

function IlEmployees(props) {
    const { Search } = Input
    const { Option } = Select
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [filter, setFilter] = useState(FILTER_ACTIVE)
    const [selectedUserInConfigure, setSelectedUserInConfigure] = useState(null)
    const [employeeRoles, setEmployeeRoles] = useState([])

    const [searchLoading, setSearchLoading] = useState(false)
    const [searchUserVisible, setSearchUserVisible] = useState(false)
    const [searchUserData, setSearchUserData] = useState([])

    const [leavesVisible, setLeaveVisible] = useState(false)
    const [loginsVisible, setLoginsVisible] = useState(false)
    const [compOffVisible, setCompOffVisible] = useState(false)
    const [allLoginsVisible, setAllLoginsVisible] = useState(false)

    useEffect(() => {
        if (filter === FILTER_SEARCH) {
            return
        }

        getEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const getEmployees = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.IL.GET_EMPLOYEES + filter)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const searchEmployee = (query) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.IL.SEARCH_EMPLOYEE + query)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        if (!employeeRoles.length) {
            getEmployeeRoles()
        }

        form.resetFields()
        setSelectedRecord(record)
        setSelectedUserInConfigure(record.user)
        setAddEditModalVisible(true)
        if (record.user) {
            setTimeout(() => {
                form.setFieldsValue({
                    roleUid: record.roleUid,
                    email: record.email,
                    managerUserID: record.manager ? record.manager.id : '',
                })
            }, 100)
        }
    }

    const getEmployeeRoles = () => {
        RestApi.doGet(GLOBAL.URL.IL.GET_EMP_ROLES).then((res) => {
            setEmployeeRoles(res.data)
        })
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
        setSelectedUserInConfigure(null)
        setSelectedRecord({})
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 75,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddEditModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 12, marginRight: 5 }} />
                    </Button>
                    <span>
                        <Link
                            href={'/userdetails/' + record.user.id}
                            onClick={(event) => {
                                event.preventDefault()
                                props.navigate('/userdetails/' + record.user.id)
                            }}
                            underline="none"
                            target="_blank"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                {record.user.firstName +
                                    ' ' +
                                    record.user.lastName}
                            </Button>
                        </Link>
                    </span>
                </>
            ),
        },
        {
            title: 'Leaves',
            dataIndex: 'leaves',
            key: 'leaves',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showLeavesModal(record)}
                >
                    Leaves
                </Button>
            ),
        },
        {
            title: 'Logins',
            dataIndex: 'logins',
            key: 'logins',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showLoginsModal(record)}
                >
                    Logins
                </Button>
            ),
        },
        {
            title: 'Role',
            dataIndex: 'roleName',
            key: 'roleName',
        },
        {
            title: 'Email / Manager',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => (
                <span>
                    {record.email ? (
                        <span>
                            <CopyToClipboard
                                text={record.email}
                                onCopy={onCopy}
                            >
                                <Button type="link" style={{ padding: 0 }}>
                                    {record.email}
                                </Button>
                            </CopyToClipboard>
                        </span>
                    ) : null}
                    {record.manager ? (
                        <>
                            <br />
                            <span>
                                {record.manager.firstName +
                                    ' ' +
                                    record.manager.lastName}
                            </span>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}
                    <br />
                    <span style={{ fontSize: 10 }}>{record.updatedAt}</span>
                </span>
            ),
        },
    ]

    const onCopy = () => {
        message.success('Copied!')
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateEmployee = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.IL.ADD_EMPLOYEE
                if (selectedRecord.user) {
                    url = GLOBAL.URL.IL.UPDATE_EMPLOYEE
                    values.userId = selectedRecord.user.id
                } else {
                    values.userId = selectedUserInConfigure.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getEmployees()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.IL.ENABLE_EMPLOYEE
        if (record.enabled) {
            url = GLOBAL.URL.IL.DISABLE_EMPLOYEE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ userId: record.user.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getEmployees()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const getEmployeeRoleOptions = () => {
        var options = []
        employeeRoles.forEach((role) => {
            options.push(
                <Option value={role.uid} key={role.roleName}>
                    {role.roleName}
                </Option>
            )
        })

        return options
    }

    const showSearchEmployeeModal = () => {
        setSearchUserVisible(true)
    }

    const hideSearchEmployeeModal = () => {
        setSearchUserVisible(false)
    }

    const searchUser = (query) => {
        setSearchLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUserData(res.data)
            })
            .finally(() => {
                setSearchLoading(false)
            })
    }

    const selectUserInSearch = (record) => {
        setSelectedUserInConfigure(record)
        hideSearchEmployeeModal()
    }

    const searchUserColumns = [
        {
            title: 'Add',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectUserInSearch(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const showLeavesModal = (record) => {
        setLeaveVisible(true)
        setSelectedRecord(record)
    }

    const hideLeavesModal = () => {
        setLeaveVisible(false)
    }

    const showLoginsModal = (record) => {
        setLoginsVisible(true)
        setSelectedRecord(record)
    }

    const hideLoginsModal = () => {
        setLoginsVisible(false)
    }

    const showCompOffModal = (record) => {
        setCompOffVisible(true)
        setSelectedRecord(record)
    }

    const hideCompOffModal = () => {
        setCompOffVisible(false)
    }

    const showAllLogins = () => {
        setAllLoginsVisible(true)
    }

    const hideAllLogins = () => {
        setAllLoginsVisible(false)
    }

    const getManagerOptions = () => {
        var options = []
        data.forEach((row) => {
            options.push(
                <Option value={row.user.id} key={row.user.id}>
                    {row.user.firstName + ' ' + row.user.lastName}
                </Option>
            )
        })

        return options
    }

    return (
        <div>
            <h2>Employees</h2>
            <div>
                <div>
                    <Radio.Group
                        onChange={(e) => setFilter(e.target.value)}
                        value={filter}
                    >
                        <Radio.Button value={FILTER_ACTIVE} key={FILTER_ACTIVE}>
                            {'Active'}
                        </Radio.Button>
                        <Radio.Button
                            value={FILTER_INACTIVE}
                            key={FILTER_INACTIVE}
                        >
                            {'Inactive'}
                        </Radio.Button>
                        <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                            {'All'}
                        </Radio.Button>
                        <Radio.Button value={FILTER_SEARCH} key={FILTER_SEARCH}>
                            {'Search'}
                        </Radio.Button>
                    </Radio.Group>
                    <Button
                        type="primary"
                        onClick={showAddEditModal}
                        style={{ marginLeft: 20 }}
                    >
                        Add
                    </Button>
                    <Button
                        type="default"
                        style={{ marginLeft: 20 }}
                        onClick={showCompOffModal}
                    >
                        Comp Offs
                    </Button>
                    <Button
                        type="default"
                        style={{ marginLeft: 20 }}
                        onClick={showAllLogins}
                    >
                        Logins
                    </Button>
                </div>
                {filter === FILTER_SEARCH ? (
                    <div style={{ marginTop: 10 }}>
                        <Search
                            placeholder="Search Employee"
                            onSearch={(value) => searchEmployee(value)}
                            style={{ width: 400 }}
                            autoFocus
                        />
                    </div>
                ) : null}
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </div>
            <Modal
                title={'Configure User'}
                open={addEditModalVisible}
                onOk={addOrUpdateEmployee}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.user ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={600}
            >
                {addEditModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="User"
                            style={{ marginBottom: 20 }}
                            name="user"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <div
                                style={{
                                    width: 350,
                                    borderColor: '#fff',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: 4,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    height: 40,
                                }}
                            >
                                {selectedUserInConfigure ? (
                                    <span
                                        style={{
                                            marginRight: 10,
                                            fontWeight: 600,
                                        }}
                                    >
                                        {selectedUserInConfigure.firstName +
                                            ' ' +
                                            selectedUserInConfigure.lastName}
                                    </span>
                                ) : null}
                                {!selectedRecord.user ? (
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={showSearchEmployeeModal}
                                    >
                                        {'Select'}
                                    </Button>
                                ) : null}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="Role"
                            style={{ marginBottom: 10 }}
                            name="roleUid"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                            >
                                {getEmployeeRoleOptions()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            style={{ marginBottom: 10 }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Manager"
                            name="managerUserID"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            style={{ marginBottom: 10 }}
                        >
                            <Select
                                placeholder="Select"
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                            >
                                {getManagerOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Search User'}
                open={searchUserVisible}
                onCancel={hideSearchEmployeeModal}
                destroyOnClose={true}
                footer={null}
                width={900}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    autoFocus
                />
                <Table
                    columns={searchUserColumns}
                    dataSource={searchUserData}
                    pagination={false}
                    size="small"
                    loading={searchLoading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            {leavesVisible ? (
                <IlEmployeeLeave
                    selectedRecord={selectedRecord}
                    hideLeavesModal={hideLeavesModal}
                    employees={data}
                />
            ) : null}
            {loginsVisible ? (
                <ILEmployeeCalendarView
                    selectedRecord={selectedRecord}
                    hideLoginsModal={hideLoginsModal}
                />
            ) : null}
            {compOffVisible ? (
                <IlEmployeeCompOff
                    hideCompOffModal={hideCompOffModal}
                    employees={data}
                />
            ) : null}
            {allLoginsVisible ? (
                <IlAllLogins hideModal={hideAllLogins} />
            ) : null}
        </div>
    )
}

export default IlEmployees
