import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Radio,
    Table,
    Button,
    Select,
    message,
    Popconfirm,
    Input,
    Modal,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

class FreeCourses extends React.Component {
    state = {
        streamData: [],
        levelsData: [],
        selectedStream: '',
        selectedLevel: '',
        loading: false,
        data: [],
        courseNames: [],
        addCourseFlag: false,
        selectedCourseName: '',
        displayText: '',
        editMode: false,
        selectedRecord: {},
    }

    componentDidMount() {
        this.getRequiredData()
    }

    getRequiredData() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            this.setState({
                streamData: streamData,
            })
        })
    }

    onChangeStream = (e) => {
        var levels = []
        this.state.streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        this.setState({
            selectedStream: e.target.value,
            levelsData: levels,
            selectedLevel: '',
            data: [],
        })
    }

    onChangeLevel = (e) => {
        this.setState(
            {
                selectedLevel: e.target.value,
            },
            () => {
                this.getFreeCourses()
            }
        )
    }

    getFreeCourses() {
        this.setState({ loading: true, data: [] })

        RestApi.doGet(
            GLOBAL.URL.GET_FREE_COURSES +
                '?course=' +
                this.state.selectedStream +
                '&level=' +
                this.state.selectedLevel
        )
            .then((res) => {
                this.setState({ data: res.data })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    showAddCourse = () => {
        if (this.state.courseNames.length === 0) {
            this.setState({ loading: true })
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES)
                .then((res) => {
                    this.setState({
                        courseNames: res.data,
                    })
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }

        this.setState({
            addCourseFlag: true,
            selectedCourseName: '',
            displayText: '',
        })
    }

    hideAddCourse = () => {
        this.setState({
            addCourseFlag: false,
            selectedCourseName: '',
            displayText: '',
        })
    }

    onAddCourseSelect = (courseName) => {
        this.setState({ selectedCourseName: courseName })
    }

    addCourse = () => {
        if (!this.state.selectedCourseName) {
            message.error('Select course to add!')
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.courseName = this.state.selectedCourseName
        payload.course = this.state.selectedStream
        payload.level = this.state.selectedLevel
        payload.displayText = this.state.displayText

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_FREE_COURSE, body)
            .then((res) => {
                message.success(res.data)
                this.hideAddCourse()
                this.getFreeCourses()
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    delete = (id) => {
        var payload = {}
        payload.id = id

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_FREE_COURSE, body)
            .then((res) => {
                message.success(res.data)
                this.getFreeCourses()
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    reorder = () => {
        const columns = [
            {
                title: 'Course',
                dataIndex: 'courseName',
                key: 'courseName',
            },
        ]

        this.props.navigate('/reorder', {
            state: {
                reorderData: this.state.data,
                updateUrl: GLOBAL.URL.REORDER_FREE_COURSE,
                columns: columns,
            },
        })
    }

    showEditModal = (record) => {
        this.setState({
            editMode: true,
            displayText: record.displayText,
            id: record.id,
            selectedRecord: record,
        })
    }

    hideEditModal = () => {
        this.setState({ editMode: false })
    }

    update = () => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = this.state.id
        payload.displayText = this.state.displayText

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_FREE_COURSE, body)
            .then((res) => {
                message.success(res.data)
                this.hideEditModal()
                this.getFreeCourses()
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    enableDisable = (record) => {
        this.setState({ loading: true })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))

        let url = GLOBAL.URL.ENABLE_FREE_COURSE
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_FREE_COURSE
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                this.getFreeCourses()
                message.destroy()
                message.success(response.data)
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    render() {
        const { Option } = Select

        var streamOptions = []
        this.state.streamData.forEach((item) => {
            streamOptions.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        var levelOptions = []
        this.state.levelsData.forEach((item) => {
            levelOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        const colums = [
            {
                title: '#',
                dataIndex: 'uid',
                key: 'uid',
                render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
                title: 'Enabled',
                dataIndex: 'enabled',
                key: 'enabled',
                width: 100,
                render: (text, record) => (
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                        onClick={() => {
                            this.enableDisable(record)
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Edit',
                dataIndex: 'edit',
                key: 'edit',
                render: (text, record) => (
                    <Button
                        type="link"
                        onClick={() => this.showEditModal(record)}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                ),
            },
            {
                title: 'Course',
                dataIndex: 'courseName',
                key: 'courseName',
            },
            {
                title: 'Display Text',
                dataIndex: 'displayText',
                key: 'displayText',
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                key: 'delete',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.delete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                ),
            },
        ]

        var courseList = []
        this.state.courseNames.forEach((item) => {
            courseList.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return (
            <div>
                <div>
                    <Radio.Group onChange={this.onChangeStream}>
                        {streamOptions}
                    </Radio.Group>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        onChange={this.onChangeLevel}
                        value={this.state.selectedLevel}
                    >
                        {levelOptions}
                    </Radio.Group>
                </div>
                {this.state.selectedStream && this.state.selectedLevel ? (
                    <div>
                        {this.state.addCourseFlag ? (
                            <div style={{ width: '500px' }}>
                                <Select
                                    placeholder="Select Course"
                                    showSearch={true}
                                    onSelect={(course) =>
                                        this.onAddCourseSelect(course)
                                    }
                                    style={{ width: '100%', marginTop: 10 }}
                                    autoFocus
                                >
                                    {courseList}
                                </Select>
                                <Input
                                    placeholder="Display Text"
                                    style={{ marginTop: 10 }}
                                    onChange={(e) =>
                                        this.setState({
                                            displayText: e.target.value,
                                        })
                                    }
                                />
                                <div>
                                    <Button
                                        type="primary"
                                        style={{ marginTop: 10 }}
                                        onClick={() => this.addCourse()}
                                    >
                                        Add
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        onClick={() => this.hideAddCourse()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ marginTop: 10 }}>
                                <Button
                                    type="primary"
                                    loading={this.state.loading}
                                    onClick={() => this.showAddCourse()}
                                >
                                    Add Course
                                </Button>
                                <Button
                                    type="primary"
                                    loading={this.state.loading}
                                    onClick={() => this.reorder()}
                                    style={{ marginLeft: 10 }}
                                >
                                    Reorder
                                </Button>
                            </div>
                        )}
                        <Table
                            columns={colums}
                            dataSource={this.state.data}
                            pagination={false}
                            style={{ marginTop: 8 }}
                            loading={this.state.loading}
                            rowKey="courseName"
                            size="small"
                        />
                    </div>
                ) : null}
                <Modal
                    title={this.state.selectedRecord.courseName}
                    open={this.state.editMode}
                    onOk={() => {
                        this.update()
                    }}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideEditModal}
                    okText="Update"
                >
                    <h4>Display Text</h4>
                    <Input
                        value={this.state.displayText}
                        onChange={(e) =>
                            this.setState({
                                displayText: e.target.value,
                            })
                        }
                        autoFocus
                    ></Input>
                </Modal>
            </div>
        )
    }
}

export default FreeCourses
