import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import { Table, Button, Tag } from 'antd'

function UserPoints(props) {
    const userId = props.userId
    const [loadingPoints, setLoadingPoints] = useState(false)
    const [points, setPoints] = useState(0)
    const [pointsHistory, setPointsHistory] = useState([])
    const [pointsNexUrl, setPointsNexUrl] = useState()
    const [loadingPointsMore, setLoadingPointsMore] = useState(false)

    useEffect(() => {
        getPoints()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPoints = () => {
        setLoadingPoints(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_POINTS + userId).then((res) => {
            setPoints(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_USER_POINTS_HISTORY + userId)
            .then((res) => {
                var response = JSON.parse(res.data)
                setPointsHistory(response.data)
                setPointsNexUrl(response.next)
            })
            .finally(() => {
                setLoadingPoints(false)
            })
    }

    const userPointsHistoryTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
    ]

    const getNextPointsHistory = () => {
        if (!pointsNexUrl) {
            return
        }

        setLoadingPointsMore(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + pointsNexUrl)
            .then((res) => {
                var response = JSON.parse(res.data)
                setPointsHistory((preState) => {
                    return [...preState, ...response.data]
                })
                setPointsNexUrl(response.next)
            })
            .finally(() => {
                setLoadingPointsMore(false)
            })
    }

    return (
        <div>
            <Tag color="geekblue" style={{ marginTop: 8 }}>
                Balance: {points}
            </Tag>
            <Table
                loading={loadingPoints}
                columns={userPointsHistoryTableColumns}
                dataSource={pointsHistory}
                pagination={false}
                style={{ marginTop: 8 }}
            />
            {pointsNexUrl ? (
                <Button
                    type="primary"
                    onClick={getNextPointsHistory}
                    style={{ marginTop: 8 }}
                    loadingCreditsMore={loadingPointsMore}
                >
                    More
                </Button>
            ) : null}
        </div>
    )
}

export default UserPoints
