import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { EditTwoTone, CopyTwoTone } from '@ant-design/icons'

function ShortLink() {
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [query, setQuery] = useState('')
    const [updateLinkIdModalVisible, setUpdateLinkIdModalVisible] =
        useState(false)
    const inputRef = useRef()
    useEffect(() => {
        getShortLinks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    useEffect(() => {
        setTimeout(() => {
            if (
                (addEditModalVisible || updateLinkIdModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, updateLinkIdModalVisible, inputRef])

    const getShortLinks = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SHORT_LINK.GET + query)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageShortLinks = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.linkId) {
            setTimeout(() => {
                form.setFieldsValue({
                    targetUrl: record.targetUrl,
                    description: record.description,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const showUpdateLinkIdModal = (record = {}) => {
        setSelectedRecord(record)
        setUpdateLinkIdModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                newLinkId: record.linkId,
            })
        }, 100)
    }

    const hideUpdateLinkIdModal = () => {
        setUpdateLinkIdModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Link ID',
            dataIndex: 'linkId',
            key: 'linkId',
            render: (text, record) => (
                <span>
                    <CopyToClipboard
                        text={'https://1fin.in/' + record.linkId}
                        onCopy={onCopy}
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <CopyTwoTone style={{ fontSize: 16 }} />
                        </Button>
                    </CopyToClipboard>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateLinkIdModal(record)
                        }}
                        style={{ padding: 0, marginLeft: 10 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>{record.linkId}</span>
                </span>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 200,
        },
        {
            title: 'Target URL',
            dataIndex: 'targetUrl',
            key: 'targetUrl',
            render: (text, record) => (
                <a
                    href={record.targetUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {record.targetUrl}
                </a>
            ),
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 100,
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}
                    <br />
                    <span style={{ fontSize: 10 }}>{record.updatedAt}</span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteShortLink(record.linkId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const onCopy = () => {
        notification['success']({
            message: 'Link copied!',
        })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateShortLink = () => {
        form.validateFields(['targetUrl', 'description'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.SHORT_LINK.ADD
                if (selectedRecord.linkId) {
                    url = GLOBAL.URL.SHORT_LINK.UPDATE
                    values.linkId = selectedRecord.linkId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getShortLinks()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const updateShortLinkId = () => {
        form.validateFields(['newLinkId'])
            .then((values) => {
                setLoading(true)

                values.linkId = selectedRecord.linkId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.SHORT_LINK.UPDATE_LINK_ID, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateLinkIdModal()
                        getShortLinks()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteShortLink = (linkId) => {
        setLoading(true)

        var payload = {}
        payload.linkId = linkId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SHORT_LINK.DELETE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getShortLinks()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.SHORT_LINK.ENABLE
        if (record.enabled) {
            url = GLOBAL.URL.SHORT_LINK.DISABLE
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ linkId: record.linkId }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getShortLink(record.linkId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const getShortLink = (linkId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SHORT_LINK.GET_LINK + linkId)
            .then((response) => {
                var fullData = clone(data)
                fullData.forEach((item, index) => {
                    if (item.linkId === linkId) {
                        fullData[index] = response.data
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Short Links</h2>
            <div>
                <div>
                    <Button type="primary" onClick={showAddEditModal}>
                        Add
                    </Button>
                    <Search
                        placeholder="Search Links"
                        onSearch={(value) =>
                            value ? setQuery(value) : setQuery('')
                        }
                        style={{ width: 500, marginLeft: 10 }}
                        autoFocus
                        allowClear
                    />
                </div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="linkId"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageShortLinks}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>

            <Modal
                title={'Configure Short Link'}
                open={addEditModalVisible}
                onOk={addOrUpdateShortLink}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.linkId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Target URL"
                        name="targetUrl"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'Update Link ID'}
                open={updateLinkIdModalVisible}
                onOk={updateShortLinkId}
                confirmLoading={loading}
                onCancel={hideUpdateLinkIdModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="New Link ID"
                        name="newLinkId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ShortLink
