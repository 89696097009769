import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    DatePicker,
    InputNumber,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import dayjs from 'dayjs'
import { Link } from '@mui/material'

function Surveys(props) {
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getSurveys()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addUpdateModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addUpdateModalVisible, inputRef])

    const getSurveys = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_SURVEYS)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageSurveys = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.ENABLE_SURVEY
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_SURVEY
        }

        var formData = new FormData()
        formData.append(
            'payload',
            JSON.stringify({ surveyId: record.surveyId })
        )

        RestApi.doPost(url, formData)
            .then((response) => {
                var fullData = data
                fullData.forEach((item) => {
                    if (item.surveyId === record.surveyId) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enabledDisableResults = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.ENABLE_SURVEY_RESULTS
        if (record.showResults) {
            url = GLOBAL.URL.DISABLE_SURVEY_RESULTS
        }

        var formData = new FormData()
        formData.append(
            'payload',
            JSON.stringify({ surveyId: record.surveyId })
        )

        RestApi.doPost(url, formData)
            .then((response) => {
                var fullData = data
                fullData.forEach((item) => {
                    if (item.surveyId === record.surveyId) {
                        item.showResults = !item.showResults
                        return
                    }
                })

                setData(fullData)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        enableDisable(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Survey',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/survey/questions/' + record.surveyId}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(
                                '/survey/questions/' + record.surveyId,
                                { state: { surveyId: record.surveyId } }
                            )
                        }}
                        underline="none"
                    >
                        <span>{record.title}</span>
                    </Link>
                    <br />
                    <span style={{ fontSize: 12 }}>{record.description}</span>
                    <div style={{ marginTop: 10 }}>
                        <Button
                            size="small"
                            onClick={() => {
                                props.navigate(
                                    '/survey/responses/' + record.surveyId,
                                    { state: { surveyId: record.surveyId } }
                                )
                            }}
                        >
                            Responses
                        </Button>
                    </div>
                </span>
            ),
        },
        {
            title: 'Rewards',
            dataIndex: 'rewards',
            key: 'rewards',
            width: 120,
            render: (text, record) => (
                <span>
                    <span>
                        iCash: <b>{record.awardCredits}</b>
                    </span>
                    <br />
                    <span>
                        Points: <b>{record.awardPoints}</b>
                    </span>
                </span>
            ),
        },
        {
            title: 'Date Range',
            dataIndex: 'dates',
            key: 'dates',
            width: 120,
            render: (text, record) => (
                <span>
                    <span>{record.startDate}</span>
                    <br />
                    <span>{record.endDate}</span>
                </span>
            ),
        },
        {
            title: 'Show Results',
            dataIndex: 'showResults',
            key: 'showResults',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        enabledDisableResults(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        {record.showResults ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteSurvey(record.surveyId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteSurvey = (surveyId) => {
        var payload = {}
        payload.surveyId = surveyId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_SURVEY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getSurveys()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAddUpdateModal = (record = {}) => {
        setAddUpdateModalVisible(true)
        setSelectedRecord(record)

        if (record.surveyId) {
            setStartDate(record.startDate)
            setEndDate(record.endDate)

            var selectedRecord = clone(record)
            selectedRecord.startDate = dayjs(record.startDate, 'DD-MMM-YY')
            selectedRecord.endDate = dayjs(record.endDate, 'DD-MMM-YY')

            setTimeout(() => {
                form.setFieldsValue(selectedRecord)
            }, 100)
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addUpdateSurvey = () => {
        form.validateFields()
            .then((values) => {
                values.startDate = startDate
                values.endDate = endDate

                var url = GLOBAL.URL.ADD_SURVEY
                if (selectedRecord.surveyId) {
                    url = GLOBAL.URL.UPDATE_SURVEY
                    values.surveyId = selectedRecord.surveyId
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateModal()
                        getSurveys()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <h2>Surveys</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddUpdateModal}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="title"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageSurveys}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure Survey'}
                open={addUpdateModalVisible}
                onOk={addUpdateSurvey}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                okText={selectedRecord.surveyId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter title',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please select',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY"
                            onChange={(value, dateString) =>
                                setStartDate(dateString)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={[
                            {
                                required: true,
                                message: 'Please select',
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY"
                            onChange={(value, dateString) =>
                                setEndDate(dateString)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Award Points"
                        name="awardPoints"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Award iCash"
                        name="awardCredits"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Surveys
