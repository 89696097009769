import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

function ExternalSalesSearchLead(props) {
    const { Search } = Input
    const [loading, setLoading] = useState(false)

    const searchUser = (phone) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SALES.EXTERNAL_SALES_SEARCH_LEAD + phone)
            .then((res) => {
                props.navigate('/external-sales-lead-details/' + res.data)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Search Lead By Phone</h2>
            <Search
                placeholder="Search Phone"
                onSearch={(value) => searchUser(value)}
                style={{ width: 200 }}
                autoFocus
            />
            {loading ? (
                <LoadingOutlined style={{ fontSize: 20, marginLeft: 10 }} />
            ) : null}
        </div>
    )
}

export default ExternalSalesSearchLead
