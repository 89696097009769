import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button, Select, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

class ActiveSubscribers extends React.Component {
    state = {
        loading: false,
        streamData: [],
        levelsData: [],
        limit: 6,
        course: '',
        level: '',
        indOrFullSubject: 'both',
        data: [],
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        courseNames: [],
        selectedCourseNames: [],
        monthData: [],
        monthMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        monthOptions: {},
        attempts: [],
        selectedAttempts: [],
    }

    componentDidMount() {
        this.getStreamCourses()
        this.getCourseNames()
        this.getAttempts()
        this.getData()
    }

    getStreamCourses() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            var streamData = JSON.parse(res.data)
            this.setState({
                streamData: streamData,
            })
        })
    }

    getCourseNames = () => {
        var url =
            GLOBAL.URL.GET_COURSE_NAMES +
            '?course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject

        RestApi.doGet(url).then((res) => {
            this.setState({
                courseNames: res.data,
            })
        })
    }

    getAttempts = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            this.setState({
                attempts: res.data,
            })
        })
    }

    getEncodedSelectedCourseNames = () => {
        var response = ''
        this.state.selectedCourseNames.forEach((course) => {
            if (response) {
                response = response + ','
            }

            response = response + encodeURIComponent(course)
        })

        return response
    }

    getEncodedSelectedAttemptNames = () => {
        var response = ''
        this.state.selectedAttempts.forEach((attempt) => {
            if (response) {
                response = response + ','
            }

            response = response + encodeURIComponent(attempt)
        })

        return response
    }

    getData = () => {
        this.setState({
            loading: true,
        })

        var url =
            GLOBAL.URL.GET_ACTIVE_SUBSCRIBERS_MONTH_WISE +
            '?limit=' +
            this.state.limit +
            '&course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject +
            '&courseNames=' +
            this.getEncodedSelectedCourseNames() +
            '&attemptNames=' +
            this.getEncodedSelectedAttemptNames()

        RestApi.doGet(url)
            .then((res) => {
                this.setState(
                    {
                        data: res.data,
                    },
                    () => {
                        this.drawChart()
                    }
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    getMonthSplit = (item, labels) => {
        this.setState({
            loading: true,
        })

        var url =
            GLOBAL.URL.GET_ACTIVE_SUBSCRIBERS_DAY_WISE +
            '?monthData=' +
            labels[item[0].index] +
            '&course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject +
            '&courseNames=' +
            this.getEncodedSelectedCourseNames() +
            '&attemptNames=' +
            this.getEncodedSelectedAttemptNames()

        RestApi.doGet(url)
            .then((res) => {
                this.setState(
                    {
                        monthData: res.data,
                    },
                    () => {
                        this.drawMonthChart(labels[item[0].index], res.data)
                    }
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart() {
        var count = []
        var coursesCount = []
        var labels = []

        this.state.data.forEach((row) => {
            labels.push(row.label)
            count.push(row.count)
            coursesCount.push(row.coursesCount)
        })

        var countDataSet = {}
        countDataSet.type = 'bar'
        countDataSet.data = count
        countDataSet.label = 'Users Count'
        countDataSet.backgroundColor = '#4bc0c0'
        countDataSet.borderColor = '#4bc0c0'
        countDataSet.fill = true
        countDataSet.yAxisID = 'y-1'
        countDataSet.datalabels = { display: true }

        var coursesCountDataSet = {}
        coursesCountDataSet.type = 'line'
        coursesCountDataSet.data = coursesCount
        coursesCountDataSet.label = 'Courses Count'
        coursesCountDataSet.borderColor = '#396ab1'
        coursesCountDataSet.fill = false
        coursesCountDataSet.yAxisID = 'y-2'
        coursesCountDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(coursesCountDataSet)
        mapData.datasets.push(countDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'Active Subscribers By Month'
        options.tooltips = { mode: 'label' }
        options.plugins.datalabels.anchor = 'center'
        options.onClick = (evt, item) => {
            this.getMonthSplit(item, labels)
        }

        this.setState({
            mapData: mapData,
            options: options,
        })
    }

    drawMonthChart(title, monthData = this.state.monthData) {
        var count = []
        var coursesCount = []
        var labels = []

        monthData.forEach((row) => {
            labels.push(row.label)
            count.push(row.count)
            coursesCount.push(row.coursesCount)
        })

        var countDataSet = {}
        countDataSet.type = 'bar'
        countDataSet.data = count
        countDataSet.label = 'Users Count'
        countDataSet.backgroundColor = '#4bc0c0'
        countDataSet.borderColor = '#4bc0c0'
        countDataSet.fill = true
        countDataSet.yAxisID = 'y-1'
        countDataSet.datalabels = { display: true }

        var coursesCountDataSet = {}
        coursesCountDataSet.type = 'line'
        coursesCountDataSet.data = coursesCount
        coursesCountDataSet.label = 'Courses Count'
        coursesCountDataSet.borderColor = '#396ab1'
        coursesCountDataSet.fill = false
        coursesCountDataSet.yAxisID = 'y-2'
        coursesCountDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(coursesCountDataSet)
        mapData.datasets.push(countDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text =
            'Active Subscribers By Month (' + title + ')'
        options.tooltips = { mode: 'label' }
        options.plugins.datalabels.anchor = 'center'
        options.plugins.datalabels.rotation = 270

        this.setState({
            monthMapData: mapData,
            monthOptions: options,
        })

        document
            .getElementById('dayChart')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    onChangeLimit = (value) => {
        this.setState({
            limit: value,
        })
    }

    onChangeStream = (e) => {
        var levels = []
        this.state.streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        this.setState(
            {
                course: e.target.value,
                levelsData: levels,
                level: '',
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeLevel = (e) => {
        this.setState(
            {
                level: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeIndOrFullSubject = (e) => {
        this.setState(
            {
                indOrFullSubject: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeCourseName = (value) => {
        this.setState({ selectedCourseNames: value })
    }

    onClearCourseName = () => {
        this.setState({ selectedCourseNames: [] })
    }

    onChangeAttemptsName = (value) => {
        this.setState({ selectedAttempts: value })
    }

    onClearAttempts = () => {
        this.setState({ selectedAttempts: [] })
    }

    render() {
        const { Option } = Select

        var streamOptions = []
        this.state.streamData.forEach((item) => {
            streamOptions.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })
        streamOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var levelOptions = []
        this.state.levelsData.forEach((item) => {
            levelOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })
        levelOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var courseOptions = []
        this.state.courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        var attemptOptions = []
        this.state.attempts.forEach((attempt) => {
            attemptOptions.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt +
                        ' (' +
                        (attempt.validityString
                            ? attempt.validityString
                            : attempt.allowedDays + ' Days') +
                        ')'}
                </Option>
            )
        })

        return (
            <div>
                <h3>Active Subscribers</h3>
                <div style={{ marginTop: 10 }}>
                    {this.state.streamData.length > 0 ? (
                        <div>
                            <Radio.Group onChange={this.onChangeStream}>
                                {streamOptions}
                            </Radio.Group>
                            {this.state.course ? (
                                <Radio.Group
                                    onChange={this.onChangeLevel}
                                    value={this.state.level}
                                    style={{ marginLeft: 10 }}
                                >
                                    {levelOptions}
                                </Radio.Group>
                            ) : null}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 10, float: 'left' }}>
                        <Radio.Group
                            onChange={this.onChangeIndOrFullSubject}
                            value={this.state.indOrFullSubject}
                        >
                            <Radio.Button value="both" key={'both'}>
                                {'Both'}
                            </Radio.Button>
                            <Radio.Button value="full" key={'full'}>
                                {'Full Subjects'}
                            </Radio.Button>
                            <Radio.Button value="ind" key={'ind'}>
                                {'Individual Modules'}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 600 }}
                        allowClear={true}
                        onChange={this.onChangeCourseName}
                        onClear={this.onClearCourseName}
                        mode="multiple"
                    >
                        {courseOptions}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        onClick={this.getData}
                        loading={this.state.loading}
                        style={{ marginRight: 10 }}
                    >
                        Fetch
                    </Button>
                    <span># Months:</span>
                    <InputNumber
                        min={1}
                        defaultValue={6}
                        onChange={this.onChangeLimit}
                        style={{ marginLeft: 10 }}
                    />
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 600 }}
                        allowClear={true}
                        onChange={this.onChangeAttemptsName}
                        onClear={this.onClearAttempts}
                        mode="multiple"
                    >
                        {attemptOptions}
                    </Select>
                </div>
                {this.state.mapData.labels ? (
                    <div>
                        <Bar
                            data={this.state.mapData}
                            width={800}
                            height={400}
                            options={this.state.options}
                        />
                    </div>
                ) : null}
                {this.state.mapData.labels ? (
                    <div>
                        <Bar
                            id="dayChart"
                            data={this.state.monthMapData}
                            width={800}
                            height={400}
                            options={this.state.monthOptions}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default ActiveSubscribers
