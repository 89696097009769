import { message, Table, Button, Popconfirm, Radio, Modal } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import HomeTopBanner from './HomeTopBanner'
import { Link } from '@mui/material'

class HomeItems extends React.Component {
    state = {
        typesData: [],
        homeItemsData: [],
        selectedtype: '',
        loading: false,
        selectedRows: [],
        selectedRowKeys: [],
        topBannerModalVisible: false,
    }
    componentDidMount() {
        this.getTypes()
    }
    getTypes() {
        RestApi.doGet(GLOBAL.URL.GET_LIST_OF_TYPES).then((res) => {
            this.setState(
                { typesData: res.data, selectedtype: res.data[0] },
                () => {
                    this.getHomeItems()
                }
            )
        })
    }
    getHomeItems() {
        this.setState({ loading: true, homeItemsData: [] })

        RestApi.doGet(
            GLOBAL.URL.GET_ITEMS_BY_TYPES +
                encodeURIComponent(this.state.selectedtype)
        )
            .then((res) => {
                this.setState({ homeItemsData: res.data })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisable(record) {
        this.setState({ loading: true })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = GLOBAL.URL.ENABLE_HOME_ITEM
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_HOME_ITEM
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = this.state.homeItemsData
                    successObject.forEach((data) => {
                        if (record.id === data.id) {
                            data.enabled = !record.enabled
                            return
                        }
                    })

                    this.setState({ homeItemsData: successObject })
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisableWeb(record) {
        this.setState({ loading: true })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = GLOBAL.URL.ENABLE_HOME_ITEM_WEB
        if (record.webEnabled) {
            url = GLOBAL.URL.DISABLE_HOME_ITEM_WEB
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = this.state.homeItemsData
                    successObject.forEach((data) => {
                        if (record.id === data.id) {
                            data.webEnabled = !record.webEnabled
                            return
                        }
                    })

                    this.setState({ homeItemsData: successObject })
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisableCourseDetails(record) {
        this.setState({ loading: true })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = GLOBAL.URL.ENABLE_HOME_ITEM_COURSE_DETAILS
        if (record.courseDetailsEnabled) {
            url = GLOBAL.URL.DISABLE_HOME_ITEM_COURSE_DETAILS
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = this.state.homeItemsData
                    successObject.forEach((data) => {
                        if (record.id === data.id) {
                            data.courseDetailsEnabled =
                                !record.courseDetailsEnabled
                            return
                        }
                    })

                    this.setState({ homeItemsData: successObject })
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisableFeed(record) {
        this.setState({ loading: true })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = GLOBAL.URL.ENABLE_HOME_ITEM_FEED
        if (record.feedEnabled) {
            url = GLOBAL.URL.DISABLE_HOME_ITEM_FEED
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = this.state.homeItemsData
                    successObject.forEach((data) => {
                        if (record.id === data.id) {
                            data.feedEnabled = !record.feedEnabled
                            return
                        }
                    })

                    this.setState({ homeItemsData: successObject })
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    deleteHomeItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_HOME_ITEM, postBody).then(
            (response) => {
                if (response.code === 200) {
                    var existingData = this.state.homeItemsData
                    this.setState({
                        homeItemsData: existingData.filter(
                            (key) => key.id !== id
                        ),
                    })
                    message.destroy()
                    message.success(response.data)
                }
            }
        )
    }
    selecttypes = (e) => {
        this.setState(
            {
                selectedtype: e.target.value,
            },
            () => {
                this.getHomeItems()
            }
        )
    }

    reorder = () => {
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
        ]

        this.props.navigate('/reorder', {
            state: {
                reorderData: this.state.homeItemsData,
                updateUrl: GLOBAL.URL.REORDER_HOME_ITEMS,
                columns: columns,
            },
        })
    }

    enableDisableAll = (enable) => {
        this.setState({ loading: true })

        var ids = []
        this.state.selectedRows.forEach((record) => {
            ids.push(record.id)
        })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ ids: ids }))
        let url = GLOBAL.URL.ENABLE_ALL_HOME_ITEMS
        if (!enable) {
            url = GLOBAL.URL.DISABLE_ALL_HOME_ITEMS
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.success(response.data)
                    this.getHomeItems()
                }
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    showTopBannerModal = () => {
        this.setState({ topBannerModalVisible: true })
    }

    hideTopBannerModal = () => {
        this.setState({ topBannerModalVisible: false })
    }

    render() {
        const columns = [
            {
                title: 'Edit',
                key: 'action',
                width: '60px',
                render: (text, record) => (
                    <span>
                        <Link
                            href={'/homeitems/homeitemsform'}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.navigate(
                                    '/homeitems/homeitemsform',
                                    {
                                        state: {
                                            typesData: this.state.typesData,
                                            homeItemData: record,
                                        },
                                    }
                                )
                            }}
                            underline="none"
                            target="_blank"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 14 }}
                                />
                            </Button>
                        </Link>
                    </span>
                ),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Route',
                dataIndex: 'route',
                key: 'route',
            },
            {
                title: 'Page Id',
                dataIndex: 'pageId',
                key: 'pageId',
            },
            {
                title: 'Enabled',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (text, record) => (
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() => {
                            this.enableDisable(record)
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Delete',
                key: 'delete',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="Are you sure want to delete ?"
                            onConfirm={() => {
                                this.deleteHomeItem(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                onClick={() => {}}
                                style={{ padding: 0 }}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]

        const Carousal_Images_Columns = [
            {
                title: 'Edit',
                key: 'action',
                width: '60px',
                render: (text, record) => (
                    <span>
                        <Link
                            href={'/homeitems/homeitemsform'}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.navigate(
                                    '/homeitems/homeitemsform',
                                    {
                                        state: {
                                            typesData: this.state.typesData,
                                            homeItemData: record,
                                        },
                                    }
                                )
                            }}
                            underline="none"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 14 }}
                                />
                            </Button>
                        </Link>
                    </span>
                ),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Route',
                dataIndex: 'route',
                key: 'route',
            },
            {
                title: 'Page Id',
                dataIndex: 'pageId',
                key: 'pageId',
            },
            {
                title: 'App',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (text, record) => (
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() => {
                            this.enableDisable(record)
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Web',
                key: 'web',
                render: (text, record) => (
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() => {
                            this.enableDisableWeb(record)
                        }}
                    >
                        {record.webEnabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Course',
                key: 'course',
                render: (text, record) => (
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() => {
                            this.enableDisableCourseDetails(record)
                        }}
                    >
                        {record.courseDetailsEnabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Feed',
                key: 'feed',
                render: (text, record) => (
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() => {
                            this.enableDisableFeed(record)
                        }}
                    >
                        {record.feedEnabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Delete',
                key: 'delete',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="Are you sure want to delete ?"
                            onConfirm={() => {
                                this.deleteHomeItem(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                onClick={() => {}}
                                style={{ padding: 0 }}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
        var typesOptionsView = []
        this.state.typesData.forEach((item) => {
            typesOptionsView.push(
                <Radio.Button value={item}>{item}</Radio.Button>
            )
        })

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRows: selectedRows,
                    selectedRowKeys: selectedRowKeys,
                })
            },
        }

        return (
            <div>
                <h2>Home Items</h2>
                <div>
                    <Link
                        href={'/homeitems/homeitemsform'}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.navigate('/homeitems/homeitemsform', {
                                state: {
                                    typesData: this.state.typesData,
                                    homeItemData: null,
                                },
                            })
                        }}
                        underline="none"
                    >
                        <Button type="primary" size="small">
                            Add Home Item
                        </Button>
                    </Link>
                    <Button
                        onClick={() => this.reorder()}
                        style={{ marginLeft: 10 }}
                        disabled={this.state.homeItemsData.length === 0}
                        size="small"
                    >
                        Reorder
                    </Button>
                    <span style={{ marginLeft: 10 }}>
                        <UploadAndGetLink />
                    </span>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.enableDisableAll(true)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="default"
                            style={{ marginLeft: 10 }}
                            size="small"
                            disabled={
                                this.state.selectedRows.length === 0 ||
                                this.state.loading
                            }
                        >
                            Enable All
                        </Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.enableDisableAll(false)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="default"
                            style={{ marginLeft: 10 }}
                            size="small"
                            disabled={
                                this.state.selectedRows.length === 0 ||
                                this.state.loading
                            }
                        >
                            Disable All
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: 30 }}
                        onClick={this.showTopBannerModal}
                    >
                        Header Top Banner
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        onChange={this.selecttypes}
                        value={this.state.selectedtype}
                    >
                        {typesOptionsView}
                    </Radio.Group>
                </div>
                <Table
                    columns={
                        this.state.selectedtype === 'Carousal_Images'
                            ? Carousal_Images_Columns
                            : columns
                    }
                    dataSource={this.state.homeItemsData}
                    pagination={false}
                    loading={this.state.loading}
                    rowKey="title"
                    rowSelection={rowSelection}
                    selectedRowKeys={this.state.selectedRowKeys}
                />
                <Modal
                    title={'Header Top Banner'}
                    open={this.state.topBannerModalVisible}
                    onCancel={this.hideTopBannerModal}
                    footer={null}
                    destroyOnClose={true}
                    width={800}
                >
                    <HomeTopBanner />
                </Modal>
            </div>
        )
    }
}

export default HomeItems
