import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Modal, DatePicker } from 'antd'
import CircularProgress from '@mui/material/CircularProgress'

function BooksInventoryReport() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [endDate, setEndDate] = useState()
    const [selectedRecord, setSelectedRecord] = useState({})
    const [inventoryDetailsModalVisible, setInventoryDetailsModalVisible] =
        useState(false)

    useEffect(() => {
        if (!endDate) {
            return
        }

        getReport()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate])

    const onChangeEndDate = (value, dateString) => {
        setEndDate(dateString)
    }

    const getReport = () => {
        setLoading(true)

        var payload = {}
        payload.endDate = endDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.GET_BOOKS_INVENTORY_REPORT, formData)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Book Name',
            dataIndex: 'bookName',
            key: 'bookName',
        },
        {
            title: 'Quantity Left',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
        },
        {
            title: 'Cost',
            dataIndex: 'totalPriceString',
            key: 'totalPriceString',
        },
        {
            title: 'Inventory Details',
            dataIndex: 'inventoryList',
            key: 'inventoryList',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0, fontSize: 12 }}
                    onClick={() => showInventoryDetailsModal(record)}
                >
                    Details
                </Button>
            ),
        },
    ]

    const showInventoryDetailsModal = (record) => {
        setSelectedRecord(record)
        setInventoryDetailsModalVisible(true)
    }

    const hideInventoryDetailsModal = () => {
        setSelectedRecord({})
        setInventoryDetailsModalVisible(false)
    }

    const inventoryDetailsTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Cost/unit',
            dataIndex: 'pricePerUnit',
            key: 'pricePerUnit',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
    ]

    return (
        <div>
            <h2>Books Inventory Left Report</h2>
            <div>
                Select End Date{' '}
                <DatePicker
                    placeholder="Select"
                    format="DD-MMM-YYYY"
                    onChange={onChangeEndDate}
                />
                {loading ? (
                    <CircularProgress
                        style={{ marginLeft: 20, width: 25, height: 25 }}
                    />
                ) : null}
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="bookName"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Inventory Details'}
                open={inventoryDetailsModalVisible}
                onCancel={hideInventoryDetailsModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedRecord.bookName}</h3>
                <Table
                    columns={inventoryDetailsTableColumns}
                    dataSource={selectedRecord.inventoryList}
                    pagination={false}
                    size="small"
                    rowKey="date"
                />
            </Modal>
        </div>
    )
}

export default BooksInventoryReport
