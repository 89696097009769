import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    notification,
    Input,
    Form,
    Modal,
    Popconfirm,
} from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Utils } from '../JS/Utils'
import { EditTwoTone, CopyTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function Deeplinks() {
    const { Search } = Input
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [filter, setFilter] = useState('')
    const [selectedRecord, setSelectedRecord] = useState({})
    const [updateLinkIdModalVisible, setUpdateLinkIdModalVisible] =
        useState(false)
    const inputRef = useRef()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.DEEPLINK.GET_LINKS + filter)
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.linkId) {
            setTimeout(() => {
                form.setFieldsValue({
                    title: record.title,
                    webUrl: record.webUrl,
                    navParams: record.navParams,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12 }} />
                </Button>
            ),
        },
        {
            title: 'Link ID',
            dataIndex: 'linkId',
            key: 'linkId',
            render: (text, record) => (
                <span>
                    <CopyToClipboard
                        text={'https://1fin.link/' + record.linkId}
                        onCopy={onCopy}
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <CopyTwoTone style={{ fontSize: 16 }} />
                        </Button>
                    </CopyToClipboard>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateLinkIdModal(record)
                        }}
                        style={{ padding: 0, marginLeft: 10 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>{record.linkId}</span>
                </span>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Clicks',
            dataIndex: 'clickCount',
            key: 'clickCount',
        },
        {
            title: 'Last Click',
            dataIndex: 'lastClickDate',
            key: 'lastClickDate',
        },
        {
            title: 'Routing',
            dataIndex: 'webUrl',
            key: 'webUrl',
            render: (text, record) => (
                <span>
                    <a
                        href={record.webUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {record.webUrl}
                    </a>
                    <br />
                    <span>{record.navParams}</span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteLink(record.linkId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const onCopy = () => {
        notification['success']({
            message: 'Link copied!',
        })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addUpdateDeeplink = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.DEEPLINK.ADD_LINK
                if (selectedRecord.linkId) {
                    values.linkId = selectedRecord.linkId
                    url = GLOBAL.URL.DEEPLINK.UPDATE_LINK
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((res) => {
                        notification['success']({
                            message: res.data,
                        })

                        hideAddEditModal()
                        getData()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showUpdateLinkIdModal = (record = {}) => {
        setSelectedRecord(record)
        setUpdateLinkIdModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                newLinkId: record.linkId,
            })
        }, 100)
    }

    const hideUpdateLinkIdModal = () => {
        setUpdateLinkIdModalVisible(false)
    }

    const updateLinkId = () => {
        form.validateFields(['newLinkId'])
            .then((values) => {
                setLoading(true)

                values.oldLinkId = selectedRecord.linkId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.DEEPLINK.UPDATE_LINK_ID, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateLinkIdModal()
                        getData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteLink = (linkId) => {
        setLoading(true)

        var payload = {}
        payload.linkId = linkId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DEEPLINK.DELETE_LINK, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Deeplinks</h2>
            <div>
                <Search
                    placeholder="Search"
                    onSearch={(value) =>
                        value ? setFilter(value) : setFilter('')
                    }
                    style={{ width: 500 }}
                    autoFocus
                />
                {Utils.isUserSiteManager() ? (
                    <Button
                        type="primary"
                        style={{ marginLeft: 10 }}
                        onClick={showAddEditModal}
                    >
                        Add
                    </Button>
                ) : null}
            </div>
            <div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="linkId"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageData}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
            <Modal
                title={'Generate Deeplink'}
                open={addEditModalVisible}
                onOk={addUpdateDeeplink}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.linkId ? 'Update' : 'Add'}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    {!selectedRecord.linkId ? (
                        <Form.Item
                            label="Link ID"
                            name="linkId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input autoFocus ref={inputRef} />
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Web URL"
                        name="webUrl"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="App Routing"
                        name="navParams"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Link ID'}
                open={updateLinkIdModalVisible}
                onOk={updateLinkId}
                confirmLoading={loading}
                onCancel={hideUpdateLinkIdModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="New Link ID"
                        name="newLinkId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Deeplinks
