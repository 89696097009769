import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import AttemptProgressCharts from './Components/AttemptProgressCharts'

const UserAttemptWiseAnalytics = ({ userId }) => {
    const [data, setData] = useState([])
    const { classes } = useStyles()

    useEffect(() => {
        getAttemptsList()
        // eslint-disable-next-line
    }, [])

    const getAttemptsList = () => {
        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_ATTEMPT_WISE_STATS + userId
        ).then((res) => {
            setData(res.data)
        })
    }

    return (
        <Box className={classes.chartContainer}>
            {data.length ? (
                <AttemptProgressCharts data={data} />
            ) : (
                <Typography className={classes.noDataTxt}>
                    No Data To Show
                </Typography>
            )}
        </Box>
    )
}

export default UserAttemptWiseAnalytics

const useStyles = makeStyles()((theme) => ({
    chartContainer: {
        display: 'flex',
        flexFlow: 'column',
        padding: '20px 30px',
        [theme.breakpoints.down('md')]: {
            padding: '20px 15px',
        },
    },
    noDataTxt: {
        fontSize: '22px',
        fontWeight: 600,
    },
}))
