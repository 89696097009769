import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import CircularProgress from '@mui/material/CircularProgress'
import { Select } from 'antd'
import { Utils } from '../JS/Utils'

function ExternalSalesUnattendedLeadsStats() {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [teams, setTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState()

    useEffect(() => {
        if (!Utils.userIsSalesMember()) {
            getStats()
        } else {
            if (!teams.length) {
                RestApi.doGet(
                    GLOBAL.URL.SALES.GET_EXTERNAL_SALES_COMPANY_LIST
                ).then((res) => {
                    setTeams(res.data)
                    if (res.data.length) {
                        setSelectedTeam(res.data[0].companyName)
                    }
                })
            } else {
                getStats()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedTeam) {
            getStats()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam])

    const getStats = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_UNATTENDED_LEADS_STATS +
                '?teamName=' +
                selectedTeam
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const teamsOptions = () => {
        var options = []
        teams.forEach((team) => {
            options.push(
                <Option value={team.companyName} key={team.companyName}>
                    {team.companyName}
                </Option>
            )
        })

        return options
    }

    const onChangeTeam = (teamName) => {
        setSelectedTeam(teamName)
    }

    return (
        <div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
                {Utils.userIsSalesMember() ? (
                    <Select
                        placeholder="Select Team"
                        style={{ width: 200 }}
                        onChange={onChangeTeam}
                        value={selectedTeam}
                    >
                        {teamsOptions()}
                    </Select>
                ) : null}
            </div>
            {loading ? <CircularProgress /> : null}
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    )
}

export default ExternalSalesUnattendedLeadsStats
