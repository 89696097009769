import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import CoursesProgressTable, { determineColor } from './CoursesProgressTable'
import { makeStyles } from 'tss-react/mui'
import { Radio } from 'antd'

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ChartDataLabels
)

const AttemptProgressCharts = ({ data }) => {
    const [selectedAttempt, setSelectedAttempt] = useState(0)
    const { classes } = useStyles()

    const currentAttemptData = data[selectedAttempt]
    const attemptExpectedCompletion = Math.round(
        currentAttemptData.attemptLevelData.expectedCompletion
    )
    const attemptActualCompletion = Math.round(
        currentAttemptData.attemptLevelData.actualCompletion
    )

    const attemptChartData = {
        labels: [''],
        datasets: [
            {
                label: 'Actual Completion',
                backgroundColor: determineColor(
                    attemptActualCompletion,
                    attemptExpectedCompletion
                ),
                data: [attemptActualCompletion],
                borderRadius: 3,
            },
            {
                label: 'Expected Completion',
                backgroundColor: '#2d8ce6',
                data: [attemptExpectedCompletion],
                borderRadius: 3,
            },
        ],
    }

    const attemptOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                formatter: function (value) {
                    return `${value}%`
                },
                align: 'end',
                anchor: 'end',
                color: 'black',
                font: {
                    size: 14,
                },
            },
        },
        layout: {
            padding: {
                right: 60,
            },
        },
        scales: {
            x: {
                min: 0,
                max: 100,
                ticks: {
                    beginAtZero: true,
                    stepSize: 10,
                },
            },
        },
    }

    const handleTabChange = (event) => {
        setSelectedAttempt(event.target.value)
    }

    return (
        <>
            {data.length > 1 && (
                <Radio.Group onChange={handleTabChange} value={selectedAttempt}>
                    {data.map((attemptData, index) => (
                        <Radio.Button value={index} key={index}>
                            {attemptData.attempt}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            )}
            <Box className={classes.barsContainer}>
                <Typography className={classes.titleTxt}>
                    {data[selectedAttempt].attempt} Progress
                </Typography>
                <Box className={classes.attemptContainer}>
                    <Bar data={attemptChartData} options={attemptOptions} />
                </Box>
                <Typography className={classes.titleTxt}>
                    Subject Wise Progress
                </Typography>
                <Box className={classes.coursesContainer}>
                    <CoursesProgressTable data={currentAttemptData.courses} />
                </Box>
            </Box>
        </>
    )
}

export default AttemptProgressCharts

const useStyles = makeStyles()((theme) => ({
    barsContainer: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    attemptContainer: {
        padding: '20px 5px',
        borderRadius: '8px',
        width: '100%',
        maxWidth: '700px',
        height: '250px',
        marginBottom: '30px',
        marginTop: '20px',
        boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
        },
    },
    tab: {
        '&.MuiTab-root': {
            transition: 'all 0.3s',
            fontSize: '15px !important',
            color: '#32117A',
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px',
            },
        },
        '&.Mui-selected': {
            background: '#F6F2FF',
            fontWeight: '600 !important',
            borderRadius: '12px 12px 0px 0px',
        },
    },
    titleTxt: {
        fontSize: '20px',
        fontWeight: 600,
    },
    coursesContainer: {
        marginTop: '20px',
    },
}))
