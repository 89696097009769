import { Input, Table, Select, message, Button, Radio } from 'antd'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Link } from '@mui/material'

var OPTION_SEARCH = 'search'

class UserRoles extends React.Component {
    state = {
        loading: false,
        data: [],
        userRoles: [],
        roleTypes: [],
        selectedUser: {},
        selectedUserRoleFilter: OPTION_SEARCH,
    }

    componentDidMount() {
        this.getRoleTypes()
        this.getAllAdminSiteUsers()
    }

    getRoleTypes = () => {
        RestApi.doGet(GLOBAL.URL.GET_ROLE_TYPES).then((res) => {
            var roleTypes = res.data
            roleTypes.unshift(OPTION_SEARCH)

            this.setState({
                roleTypes: roleTypes,
            })
        })
    }

    getUser = (query) => {
        this.setState({
            loading: true,
            selectedUser: {},
        })

        if (query) {
            var payload = {}
            payload.query = query ? query : ''

            var formData = new FormData()
            formData.append('payload', JSON.stringify(payload))

            RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
                .then((res) => {
                    this.setState({
                        data: res.data,
                    })
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    })
                })
        } else {
            this.getAllAdminSiteUsers()
        }
    }

    onRowClick = (record) => {
        this.setState({
            loading: true,
            selectedUser: {},
        })

        RestApi.doGet(GLOBAL.URL.GET_USER_ROLES + record.id)
            .then((res) => {
                this.setState({
                    userRoles: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                    selectedUser: record,
                })
            })
    }

    handleRoleChange = (values) => {
        this.setState({ userRoles: values })
    }

    updateRoles = () => {
        this.setState({
            loading: true,
        })

        var payload = {}
        payload.userId = this.state.selectedUser.id
        payload.roles = this.state.userRoles

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_USER_ROLES, postBody)
            .then((res) => {
                message.success(res.data)
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    onChangeUserRoleFilter = (value) => {
        this.setState({
            selectedUserRoleFilter: value,
            selectedUser: {},
        })
        if (value === OPTION_SEARCH) {
            this.setState({
                data: [],
            })
            this.getAllAdminSiteUsers()
        } else {
            this.setState({ loading: true })

            RestApi.doGet(GLOBAL.URL.GET_USERS_BY_ROLE + value)
                .then((res) => {
                    this.setState({
                        data: res.data,
                    })
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    })
                })
        }
    }

    getAllAdminSiteUsers = () => {
        this.setState({ loading: true })

        RestApi.doGet(GLOBAL.URL.GET_USERS_WITH_ADMIN_SITE_ACCESS)
            .then((res) => {
                this.setState({
                    data: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const { Search } = Input
        const { Option } = Select

        const colums = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50,
                render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => (
                    <span>
                        <Link
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(
                                    '/userdetails/' + record.userId,
                                    '_blank'
                                )
                            }}
                            underline="none"
                        >
                            <Button type="link" style={{ padding: 0 }}>
                                {record.email}
                            </Button>
                        </Link>
                    </span>
                ),
            },
        ]

        var roleOptions = []
        this.state.roleTypes.forEach((item) => {
            roleOptions.push(
                <Option key={item} value={item}>
                    {item}
                </Option>
            )
        })

        var roleRadioOptions = []
        this.state.roleTypes.forEach((item) => {
            roleRadioOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return (
            <div>
                <h3>User Roles</h3>
                <Select
                    style={{ width: '60%' }}
                    placeholder="Select Role"
                    onChange={this.onChangeUserRoleFilter}
                    defaultValue={OPTION_SEARCH}
                    showSearch
                >
                    {roleOptions}
                </Select>

                {this.state.selectedUserRoleFilter === OPTION_SEARCH ? (
                    <div style={{ marginTop: 10 }}>
                        <Search
                            placeholder="Search User"
                            onSearch={(value) => this.getUser(value)}
                            style={{ width: 300 }}
                            autoFocus
                        />
                    </div>
                ) : null}
                {this.state.selectedUser.id ? (
                    <div style={{ marginTop: 10 }}>
                        <div>
                            <Button
                                onClick={() =>
                                    this.props.navigate(
                                        '/userdetails/' +
                                            this.state.selectedUser.id
                                    )
                                }
                                type="link"
                                style={{
                                    padding: 0,
                                    border: 'none',
                                    height: 0,
                                    fontSize: 22,
                                }}
                            >
                                {this.state.selectedUser.firstName +
                                    ' ' +
                                    this.state.selectedUser.lastName}
                            </Button>
                        </div>
                        <Select
                            mode="multiple"
                            style={{ width: '60%' }}
                            placeholder="Please select roles"
                            defaultValue={this.state.userRoles}
                            onChange={this.handleRoleChange}
                        >
                            {roleOptions}
                        </Select>
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="primary"
                                onClick={() => this.updateRoles()}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                ) : null}
                <Table
                    columns={colums}
                    dataSource={this.state.data ? this.state.data : null}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    loading={this.state.loading}
                    rowKey="email"
                    size="small"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                this.onRowClick(record)
                            },
                        }
                    }}
                />
            </div>
        )
    }
}

export default UserRoles
