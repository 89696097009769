import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Popconfirm } from 'antd'

const LeadsReport = () => {
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState('')

    const exportAlOpenLeads = () => {
        setLoading(true)

        RestApi.doPost(GLOBAL.URL.LEADS.EXPORT_OPEN_LEADS, new FormData())
            .then((res) => {
                setMessage(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Leads Report</h2>
            <div>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={exportAlOpenLeads}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button loading={loading}>Export All Open Leads</Button>
                </Popconfirm>
            </div>
            {message ? (
                <div style={{ marginTop: 15 }}>
                    <span style={{ color: 'blue' }}>{message}</span>
                </div>
            ) : null}
        </div>
    )
}

export default LeadsReport
