import React, { useState, useEffect, useRef } from 'react'
import {
    Input,
    Button,
    Table,
    Popconfirm,
    message,
    Modal,
    Radio,
    InputNumber,
    Select,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import UploadAndGetLink from '../Common/UploadAndGetLink'

const PAGE_NAME_TESTIMONIALS = 'Testimonials'
const PAGE_NAME_STARRED = 'Starred'

function WOF(props) {
    const { TextArea } = Input
    const { Search } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [loadingCopyToStarred, setLoadingCopyToStarred] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [imageModalVisible, setImageModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [pages, setPages] = useState([])
    const [selectedPage, setSelectedPage] = useState()
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    const [selectedStreamFilter, setSelectedStreamFilter] = useState('')
    const [selectedStreamLevelFilter, setSelectedStreamLevelFilter] =
        useState('')
    const [selectedAttemptFilter, setSelectedAttemptFilter] = useState('')
    const [streamFilters, setStreamFilters] = useState([])
    const [streamLevelFilters, setStreamLevelFilters] = useState([])
    const [attemptFilters, setAttemptFilters] = useState([])
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getPages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedPage) {
            return
        }

        getData()
        getStreamFitlers()
        getAttemptFilters()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage, searchQuery])

    useEffect(() => {
        if (!selectedPage) {
            return
        }

        getData()
        getStreamLevelFilters()
        getAttemptFilters()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStreamFilter])

    useEffect(() => {
        if (!selectedPage) {
            return
        }

        getData()
        getAttemptFilters()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStreamLevelFilter])

    useEffect(() => {
        if (!selectedPage) {
            return
        }

        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAttemptFilter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getPages = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.PAGES_WOF).then((res) => {
            setPages(res.data)
            setSelectedPage(res.data[0])
        })
    }

    const getData = () => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_WOF +
                '?pageFilter=' +
                selectedPage +
                '&searchQuery=' +
                searchQuery +
                '&stream=' +
                selectedStreamFilter +
                '&level=' +
                selectedStreamLevelFilter +
                '&attempt=' +
                selectedAttemptFilter
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const pageFilterOptions = () => {
        var options = []

        pages.forEach((page) => {
            options.push(
                <Radio.Button value={page} key={page}>
                    {page}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangePageFilter = (e) => {
        setSelectedPage(e.target.value)
    }

    const showAddEditModal = (record = {}) => {
        if (!streamData.length) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
                setStreamData(JSON.parse(res.data))
            })
        }

        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.id) {
            var recordClone = clone(record)
            if (!recordClone.attemptYear) {
                recordClone.attemptYear = ''
            }

            handleCourseChange(recordClone.stream)
            setTimeout(() => {
                form.setFieldsValue(recordClone)
            }, 100)
        } else {
            setSelectedCourse()
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
        setSelectedCourse()
    }

    const enableDisable = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_WOF
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_WOF
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.destroy()
                message.success(res.data)

                var data = this.state.data
                data.forEach((item) => {
                    if (record.id === item.id) {
                        item.enabled = !record.enabled
                        return
                    }
                })

                setData(data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showImageModal = (record) => {
        setSelectedRecord(record)
        setImageModalVisible(true)
    }

    const hideImageModal = () => {
        setSelectedRecord({})
        setImageModalVisible(false)
    }

    const deleteWof = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.DELETE_WOF, postBody)
            .then((response) => {
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const copyToStarred = (id) => {
        setLoadingCopyToStarred(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.COPY_WOF_TO_STARRED, postBody)
            .then((response) => {
                message.success(response.data)
            })
            .finally(() => {
                setLoadingCopyToStarred(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'count',
            key: 'count',
            width: 40,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 70,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                    }}
                    onClick={() => enableDisable(record)}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <span>{record.name}</span>
                    {record.image ? (
                        <>
                            <br />
                            <Button
                                type="link"
                                style={{ padding: 0, fontSize: 12 }}
                                onClick={() => {
                                    showImageModal(record)
                                }}
                            >
                                Image
                            </Button>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Review',
            dataIndex: 'review',
            key: 'review',
            width: 700,
            render: (text, record) => (
                <span>
                    {record.review}
                    <br />
                    <span style={{ fontSize: 12 }}>
                        {record.stream ? (
                            <span>
                                <br />
                                {record.stream + ' ' + record.level}
                            </span>
                        ) : null}
                        {record.attemptMonth ? (
                            <span>
                                <br />
                                {record.attemptMonthString +
                                    ' ' +
                                    record.attemptYear}
                            </span>
                        ) : null}
                    </span>
                    {selectedPage !== PAGE_NAME_STARRED ? (
                        <>
                            <br />
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => copyToStarred(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{ padding: 0, fontSize: 12 }}
                                    loading={loadingCopyToStarred}
                                >
                                    Copy To Starred
                                </Button>
                            </Popconfirm>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteWof(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const attemptMonth = () => {
        var options = []
        options.push(
            <Radio.Button value={1} key={1}>
                {'Jan'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={2} key={2}>
                {'Feb'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={3} key={3}>
                {'Mar'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={4} key={4}>
                {'Apr'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={5} key={5}>
                {'May'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={6} key={6}>
                {'Jun'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={7} key={7}>
                {'Jul'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={8} key={8}>
                {'Aug'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={9} key={9}>
                {'Sep'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={10} key={10}>
                {'Oct'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={11} key={11}>
                {'Nov'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={12} key={12}>
                {'Dec'}
            </Radio.Button>
        )

        return options
    }

    const genderOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={'M'} key={'M'}>
                {'Male'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={'F'} key={'F'}>
                {'Female'}
            </Radio.Button>
        )

        return options
    }

    const addOrUpdateWof = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.page = selectedPage

                var url = GLOBAL.URL.ADD_WOF
                if (selectedRecord.id) {
                    url = GLOBAL.URL.UPDATE_WOF
                    values['id'] = selectedRecord.id
                }

                let payload = new FormData()
                payload.append('payload', JSON.stringify(values))
                RestApi.doPost(url, payload)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideAddEditModal()
                        getData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: data,
                updateUrl: GLOBAL.URL.REORDER_WOF,
                columns: columns,
            },
        })
    }

    const getStreamFitlers = () => {
        RestApi.doGet(
            GLOBAL.URL.WOF_GET_STREAM_FILTERS + '?page=' + selectedPage
        ).then((res) => {
            setStreamFilters(res.data)
        })
    }

    const getStreamLevelFilters = () => {
        RestApi.doGet(
            GLOBAL.URL.WOF_GET_STREAM_LEVEL_FILTERS +
                '?page=' +
                selectedPage +
                '&stream=' +
                selectedStreamFilter
        ).then((res) => {
            setStreamLevelFilters(res.data)
        })
    }

    const getAttemptFilters = () => {
        RestApi.doGet(
            GLOBAL.URL.WOF_GET_ATTEMPT_FILTERS +
                '?page=' +
                selectedPage +
                '&stream=' +
                selectedStreamFilter +
                '&level=' +
                selectedAttemptFilter
        ).then((res) => {
            setAttemptFilters(res.data)
        })
    }

    const getStreamFilterOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={''} key={'all'}>
                {'All'}
            </Radio.Button>
        )

        streamFilters.forEach((stream) => {
            options.push(
                <Radio.Button value={stream} key={stream}>
                    {stream}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelFilterOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={''} key={'all'}>
                {'All'}
            </Radio.Button>
        )

        streamLevelFilters.forEach((level) => {
            options.push(
                <Radio.Button value={level} key={level}>
                    {level}
                </Radio.Button>
            )
        })

        return options
    }

    const getAttemptFilterOptions = () => {
        var options = []

        attemptFilters.forEach((attempt) => {
            options.push(
                <Option value={attempt} key={attempt}>
                    {attempt}
                </Option>
            )
        })

        return options
    }

    const onChangeStreamFilter = (e) => {
        setSelectedStreamFilter(e.target.value)
    }

    const onChangeStreamLevelFilter = (e) => {
        setSelectedStreamLevelFilter(e.target.value)
    }

    const onChangeAttemptFilter = (value) => {
        setSelectedAttemptFilter(value ? value : '')
    }

    return (
        <div>
            <h2>Testimonials</h2>
            <div>
                <Radio.Group onChange={onChangePageFilter} value={selectedPage}>
                    {pageFilterOptions()}
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeStreamFilter}
                    value={selectedStreamFilter}
                    style={{ marginLeft: 10 }}
                >
                    {getStreamFilterOptions()}
                </Radio.Group>
                {selectedStreamFilter ? (
                    <Radio.Group
                        onChange={onChangeStreamLevelFilter}
                        value={selectedStreamLevelFilter}
                        style={{ marginLeft: 10 }}
                    >
                        {getStreamLevelFilterOptions()}
                    </Radio.Group>
                ) : null}
                <Select
                    placeholder="Attempt"
                    showSearch
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 200, marginLeft: 10 }}
                    onChange={onChangeAttemptFilter}
                    allowClear
                >
                    {getAttemptFilterOptions()}
                </Select>
            </div>
            <div style={{ marginTop: 10 }}>
                <Button
                    type="primary"
                    onClick={showAddEditModal}
                    loading={loading}
                >
                    Add
                </Button>
                {selectedPage !== PAGE_NAME_TESTIMONIALS && data.length ? (
                    <Button
                        type="default"
                        onClick={reorder}
                        loading={loading}
                        style={{ marginLeft: 10 }}
                    >
                        Reorder
                    </Button>
                ) : null}
                <Search
                    placeholder="Search"
                    onSearch={(value) => setSearchQuery(value)}
                    style={{ width: 300, marginLeft: 10 }}
                    allowClear
                />
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                style={{ marginTop: 10 }}
                rowKey="id"
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title="Student Image"
                open={imageModalVisible}
                onCancel={hideImageModal}
                footer={null}
            >
                <img
                    src={selectedRecord.image}
                    alt="profile preview"
                    style={{ maxWidth: '100%' }}
                />
            </Modal>
            <Modal
                title={'Configure Testimonial'}
                open={addEditModalVisible}
                onOk={addOrUpdateWof}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Name"
                        style={{ marginBottom: 10 }}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Review"
                        style={{ marginBottom: 10 }}
                        name="review"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea autoSize={true} />
                    </Form.Item>
                    <Form.Item
                        label="Image"
                        style={{ marginBottom: 10 }}
                        name="image"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div style={{ paddingLeft: 125 }}>
                        <UploadAndGetLink />
                    </div>
                    <Form.Item
                        label="Course Stream"
                        style={{ marginBottom: 10 }}
                        name="stream"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            style={{ marginBottom: 10 }}
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{levelOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Attempt Month"
                        style={{ marginBottom: 10 }}
                        name="attemptMonth"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{attemptMonth()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Attempt Year"
                        style={{ marginBottom: 10 }}
                        name="attemptYear"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                        label="Gender"
                        style={{ marginBottom: 10 }}
                        name="gender"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{genderOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Marksheets"
                        style={{ marginBottom: 10 }}
                        name="marksheets"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <span style={{ marginLeft: 125, fontSize: 12 }}>
                        {'(Comma separated image urls)'}
                    </span>
                    <div style={{ paddingLeft: 125 }}>
                        <UploadAndGetLink />
                    </div>
                    <Form.Item
                        label="Special Tag"
                        style={{ marginBottom: 0 }}
                        name="specialTag"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default WOF
