import {
    message,
    Table,
    Button,
    Col,
    Radio,
    Spin,
    Modal,
    Input,
    Tag,
    Select,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import EmailToUser from '../EmailToUser/EmailToUser'
import { Link } from '@mui/material'
import clone from 'clone'

const { TextArea } = Input
const OPEN_CONSTANT = 'open',
    CLOSE_CONSTANT = 'closed',
    ALL_CONSTANT = 'all'

const Transactions = (props) => {
    const [flagData, setFlagData] = useState([])
    const [statusType, setStatusType] = useState(OPEN_CONSTANT)
    const [loadingMoreData, setLoadingMoreData] = useState(false)
    const [nextUrl, setNextUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [commentRecordId, setCommentRecordId] = useState('')
    const [addEditCommentModalVisible, setAddEditCommentModalVisible] =
        useState(false)
    const [selectedCategory, setSelectedCategory] = useState('mcq')
    const [selectedSubCategory, setSelectedSubCategory] = useState('')
    const [categoryData, setCategoryData] = useState([])
    const [subCategoryData, setSubCategoryData] = useState([])
    const [count, setCount] = useState(0)
    const [allCommentsModalVisible, setAllCommentsModalVisible] =
        useState(false)
    const [loadingComments, setLoadingComments] = useState(false)
    const [comments, setComments] = useState([])
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [flagAdminUsers, setFlagAdminUsers] = useState([])
    const [assignedTo, setAssignedTo] = useState('')
    const [subjects, setSubjects] = useState([])
    const [selectedSubjectId, setSelectedSubjectId] = useState('')
    const [modalLoading, setModalLoading] = useState(false)
    const [form] = Form.useForm()
    const [emailModalVisible, setEmailModalVisible] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        getFlagItems()
        getCategoryData()
        getFlagAdminUsers()
        getSubjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditCommentModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditCommentModalVisible, inputRef])

    const getFlagAdminUsers = () => {
        RestApi.doGet(GLOBAL.URL.GET_FLAG_ADMIN_USERS).then((res) => {
            setFlagAdminUsers(res.data)
        })
    }
    const getCategoryData = () => {
        RestApi.doGet(GLOBAL.URL.GET_FLAG_CATEGORIES).then((response) => {
            var categoryData = JSON.parse(response.data)
            setCategoryData(categoryData)
        })
    }
    const getSubjects = (status = statusType) => {
        RestApi.doGet(GLOBAL.URL.GET_FLAGGED_SUBJECTS + status).then((res) => {
            setSubjects(res.data)
        })
    }
    const getFlagItems = (
        statusTypeNew = statusType,
        selectedCategoryNew = selectedCategory,
        selectedSubCategoryNew = selectedSubCategory,
        assigned = assignedTo,
        selectedSubject = selectedSubjectId
    ) => {
        var selectedCategoryConverted = encodeURIComponent(selectedCategoryNew)
        var selectedSubCategoryConverted = encodeURIComponent(
            selectedSubCategoryNew
        )
        setFlagData([])
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_FLAG_ITEMS +
                statusTypeNew +
                '&category=' +
                selectedCategoryConverted +
                '&subCategory=' +
                selectedSubCategoryConverted +
                '&assignedTo=' +
                assigned +
                '&subjectId=' +
                selectedSubject
        )
            .then((res) => {
                var flagData = JSON.parse(res.data)
                setFlagData(flagData.data)
                setNextUrl(flagData.next)
                setCount(flagData.count)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const openAddEditCommentModal = (record) => {
        form.setFieldsValue(record)
        setAddEditCommentModalVisible(true)
        setCommentRecordId(record.autoId)
    }
    const openAddCommentModal = (autoId) => {
        setAddEditCommentModalVisible(true)
        setCommentRecordId(autoId)
        closeAllCommentsModal()
    }
    const closeAddEditCommentModal = () => {
        form.resetFields()
        setAddEditCommentModalVisible(false)
        setCommentRecordId(null)
    }
    const openAllCommentsModal = (record) => {
        setAllCommentsModalVisible(true)
        setCommentRecordId(record.autoId)
        getAllComments(record.autoId)
    }
    const closeAllCommentsModal = () => {
        setAllCommentsModalVisible(false)
    }
    const getAllComments = (autoId) => {
        setLoadingComments(true)
        RestApi.doGet(GLOBAL.URL.GET_FLAG_COMMENTS + autoId)
            .then((res) => {
                setComments(res.data)
            })
            .finally(() => {
                setLoadingComments(false)
            })
    }
    const addOrEditComment = (recordId) => {
        form.validateFields(['comments'])
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_FLAG_COMMENT
                values['autoId'] = recordId
                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyLevel)
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.GET_FLAG_LATEST_COMMENT + recordId
                        ).then((latestComment) => {
                            var fullData = flagData
                            fullData.forEach((item) => {
                                if (item.autoId === recordId) {
                                    item.latestComment = latestComment.data
                                    return
                                }
                            })
                            setFlagData(fullData)
                        })

                        closeAddEditCommentModal()
                        message.success(response.data)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }
    const getMoreData = () => {
        if (nextUrl) {
            setLoadingMoreData(true)
            var getMoreData = GLOBAL.URL.BASE_URL + nextUrl
            RestApi.doGet(getMoreData)
                .then((resp) => {
                    var moreData = JSON.parse(resp.data)
                    setFlagData([...flagData, ...moreData.data])
                    setNextUrl(moreData.next)
                })
                .finally((error) => {
                    setLoadingMoreData(false)
                })
        }
    }
    const onChangeStatus = (event) => {
        setStatusType(event.target.value)
        getFlagItems(event.target.value)
        getSubjects(event.target.value)
    }
    const openClose = (autoId, action) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ autoId: autoId }))
        let url = ''
        if (action === OPEN_CONSTANT) {
            url = GLOBAL.URL.OPEN_FLAG
        } else {
            url = GLOBAL.URL.CLOSE_FLAG
        }
        RestApi.doPost(url, postBody).then((response) => {
            if (response.code === 200) {
                var successObject = clone(flagData)
                successObject.forEach((data) => {
                    if (autoId === data.autoId) {
                        data.status = action
                    }
                })

                setFlagData(successObject)
                message.destroy()
                message.success(response.data)
            }
        })
    }
    const onChangeCategory = (e) => {
        categoryData.forEach((item) => {
            if (item.category === e.target.value) {
                setSubCategoryData(item.subCategory ? item.subCategory : [])
                setSelectedCategory(e.target.value)
                setSelectedSubCategory('')
                getFlagItems(
                    statusType,
                    e.target.value,
                    '',
                    assignedTo,
                    selectedSubjectId
                )
                return
            }
        })
    }
    const onChangeSubCategory = (e) => {
        setSelectedSubCategory(e.target.value)
        getFlagItems(statusType, selectedCategory, e.target.value)
    }

    const showAssignToModal = (record) => {
        setAssignToModalVisible(true)
        setSelectedRecord(record)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const assignFlagItem = () => {
        form.validateFields(['userId'])
            .then((values) => {
                setModalLoading(true)

                var payload = {}
                payload.userId = values.userId
                payload.autoId = selectedRecord.autoId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.ASSIGN_FLAG_ITEM, formData)
                    .then((response) => {
                        var selectedAdminUSer = {}
                        flagAdminUsers.forEach((adminUser) => {
                            if (adminUser.id === values.userId) {
                                selectedAdminUSer = adminUser
                            }
                        })

                        var fullData = flagData
                        fullData.forEach((item) => {
                            if (item.autoId === selectedRecord.autoId) {
                                item.assignedTo = selectedAdminUSer
                                return
                            }
                        })
                        setFlagData(fullData)
                        hideAssignToModal()
                        message.success(response.data)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onChangeAsignee = (value) => {
        setAssignedTo(value ? value : '')
        getFlagItems(
            statusType,
            selectedCategory,
            selectedSubCategory,
            value ? value : ''
        )
    }

    const onChangeSubjectFilter = (value) => {
        setSelectedSubjectId(value ? value : '')
        getFlagItems(
            statusType,
            selectedCategory,
            selectedSubCategory,
            assignedTo,
            value ? value : ''
        )
    }

    const { Option } = Select

    const commentsColumns = [
        {
            title: 'Date',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>{record.date}</span>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => <span>{record.user.firstName}</span>,
        },
    ]

    const columns = [
        {
            title: 'Status',
            key: 'active',
            render: (text, record) => (
                <span>
                    {record.status !== OPEN_CONSTANT ? (
                        <Button
                            style={{
                                fontSize: 11,
                            }}
                            onClick={() => {
                                openClose(record.autoId, OPEN_CONSTANT)
                            }}
                            size="small"
                            type="default"
                            loading={loading}
                        >
                            Closed
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                openClose(record.autoId, CLOSE_CONSTANT)
                            }}
                            size="small"
                            style={{ fontSize: 11 }}
                            loading={loading}
                        >
                            Open
                        </Button>
                    )}
                </span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>{record.date}</span>
                    <br />
                    {record.assignedTo && record.assignedTo.id ? (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    showAssignToModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 10 }}
                                />
                            </Button>
                            <span style={{ marginLeft: 5 }}>
                                {record.assignedTo
                                    ? record.assignedTo.firstName
                                    : ''}
                            </span>
                        </>
                    ) : (
                        <Button
                            size="small"
                            onClick={() => {
                                showAssignToModal(record)
                            }}
                            style={{ fontSize: 10, padding: 0 }}
                            type="link"
                        >
                            Assign
                        </Button>
                    )}
                </span>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => {
                if (record.category === 'mcq') {
                    return (
                        <Link
                            href={'/mcq/questiondetails/' + record.id}
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(
                                    '/mcq/questiondetails/' + record.id,
                                    '_blank'
                                )
                            }}
                            underline="none"
                            target="_blank"
                        >
                            <Button
                                size="small"
                                type="link"
                                style={{ padding: 0, fontSize: 12 }}
                            >
                                {record.category}
                            </Button>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    fontSize: 9,
                                    marginLeft: 5,
                                }}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </Button>
                        </Link>
                    )
                } else if (
                    record.category === 'forum' &&
                    record.subCategory === 'question'
                ) {
                    return (
                        <Link
                            href={'/forumtopicdetails/' + record.id}
                            onClick={(event) => {
                                event.preventDefault()
                                props.navigate(
                                    '/forumtopicdetails/' + record.id
                                )
                            }}
                            underline="none"
                            target="_blank"
                        >
                            <Button
                                size="small"
                                type="link"
                                style={{ padding: 0, fontSize: 12 }}
                            >
                                {record.category}
                            </Button>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    fontSize: 9,
                                    marginLeft: 5,
                                }}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </Button>
                        </Link>
                    )
                } else {
                    return (
                        <span style={{ fontSize: 11 }}>
                            {record.category}
                            <br />
                            {record.subCategory}
                        </span>
                    )
                }
            },
        },
        {
            title: 'Report Type',
            dataIndex: 'reportType',
            key: 'reportType',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.reportType}</span>
            ),
        },
        {
            title: 'Subject / Data',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.data}</span>
            ),
        },

        {
            title: 'Reported By',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <>
                    <Link
                        href={'/userdetails/' + record.user.id}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/userdetails/' + record.user.id)
                        }}
                        underline="none"
                    >
                        <Button
                            size="small"
                            type="link"
                            style={{ padding: 0, fontSize: 11 }}
                        >
                            {record.user.firstName}
                        </Button>
                    </Link>
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 11 }}
                        onClick={() => showEmailModal(record)}
                    >
                        Send Email
                    </Button>
                </>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <>
                    {record.latestComment && record.latestComment.comment ? (
                        <div>
                            {record.latestComment.comment}
                            <br />
                            <span style={{ fontSize: 10 }}>
                                {' ('}
                                {record.latestComment.user.firstName}
                                {', '}
                                {record.latestComment.date}
                                {')'}
                            </span>
                        </div>
                    ) : null}
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                openAddEditCommentModal(record)
                            }}
                            size="small"
                            style={{ fontSize: 10, padding: 0 }}
                        >
                            Add Comment
                        </Button>
                        {record.latestComment &&
                        record.latestComment.comment ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    openAllCommentsModal(record)
                                }}
                                size="small"
                                style={{
                                    fontSize: 10,
                                    padding: 0,
                                    marginLeft: 10,
                                }}
                            >
                                All Comments
                            </Button>
                        ) : null}
                    </div>
                </>
            ),
        },
    ]
    var categoryView = []
    categoryData.forEach((item) => {
        categoryView.push(
            <Radio.Button value={item.category} key={item.category}>
                {item.category}
            </Radio.Button>
        )
    })

    var subCategoryView = []
    subCategoryData.forEach((item) => {
        subCategoryView.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getAdminUserOptions = () => {
        var options = []
        flagAdminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const getSubjectFilterOptions = () => {
        var options = []
        subjects.forEach((subject) => {
            options.push(
                <Option value={subject.subjectId} key={subject.name}>
                    {subject.name}
                </Option>
            )
        })

        return options
    }

    const showEmailModal = (record) => {
        setSelectedRecord(record)
        setEmailModalVisible(true)
    }

    const hideEmailModal = () => {
        setEmailModalVisible(false)
    }

    return (
        <div>
            <Spin spinning={loading}>
                <h2>Flagged</h2>
                <div>
                    <Radio.Group
                        onChange={onChangeStatus}
                        defaultValue={OPEN_CONSTANT}
                    >
                        <Radio.Button value={OPEN_CONSTANT}>
                            {OPEN_CONSTANT}
                        </Radio.Button>
                        <Radio.Button value={CLOSE_CONSTANT}>
                            {CLOSE_CONSTANT}
                        </Radio.Button>
                        <Radio.Button value={ALL_CONSTANT}>
                            {ALL_CONSTANT}
                        </Radio.Button>
                    </Radio.Group>
                    <span style={{ marginLeft: 20 }}>
                        <Select
                            placeholder="Assignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                            onChange={onChangeAsignee}
                        >
                            {getAdminUserOptions()}
                        </Select>
                        <Select
                            placeholder="Subject Filter"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350, marginLeft: 10 }}
                            onChange={onChangeSubjectFilter}
                        >
                            {getSubjectFilterOptions()}
                        </Select>
                    </span>
                    <div style={{ marginTop: 10 }}>
                        <Radio.Group
                            onChange={onChangeCategory}
                            defaultValue={selectedCategory}
                        >
                            {categoryView}
                        </Radio.Group>
                        <span style={{ marginLeft: 10 }}>
                            <Radio.Group onChange={onChangeSubCategory}>
                                {subCategoryView}
                            </Radio.Group>
                        </span>
                    </div>
                </div>
                <Tag
                    color="geekblue"
                    style={{ marginTop: 10, display: 'table' }}
                >
                    Filtered Items Count:{count}
                </Tag>
                <Table
                    columns={columns}
                    dataSource={flagData}
                    pagination={false}
                    size="small"
                    style={{ marginTop: 10 }}
                    rowKey="id"
                />
                {nextUrl ? (
                    <Col style={{ textAlign: 'right', marginTop: 5 }}>
                        <Button
                            type="primary"
                            loading={loadingMoreData}
                            onClick={getMoreData}
                        >
                            More Data
                        </Button>
                    </Col>
                ) : null}
                <Modal
                    title={'Add Comment'}
                    open={addEditCommentModalVisible}
                    onOk={() => {
                        addOrEditComment(commentRecordId)
                    }}
                    confirmLoading={modalLoading}
                    onCancel={closeAddEditCommentModal}
                    okText={'Add Comment'}
                >
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Comment"
                            name="comments"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter comment',
                                },
                            ]}
                        >
                            <TextArea
                                rows={3}
                                placeholder="Please enter comment"
                                autoFocus
                                ref={inputRef}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={'Comments'}
                    open={allCommentsModalVisible}
                    onCancel={closeAllCommentsModal}
                    footer={null}
                    width={800}
                >
                    <div>
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                                openAddCommentModal(commentRecordId)
                            }}
                        >
                            Add
                        </Button>
                    </div>
                    <Table
                        columns={commentsColumns}
                        dataSource={comments}
                        loading={loadingComments}
                        pagination={false}
                        size="small"
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                </Modal>
                <Modal
                    title={'Assign'}
                    open={assignToModalVisible}
                    onOk={() => {
                        assignFlagItem()
                    }}
                    confirmLoading={modalLoading}
                    onCancel={hideAssignToModal}
                    okText={'Assign'}
                    destroyOnClose={true}
                >
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Assign To"
                            name="userId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Assignee"
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                            >
                                {getAdminUserOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={
                        'Send Email - ' +
                        (selectedRecord &&
                        selectedRecord.user &&
                        selectedRecord.user.firstName
                            ? selectedRecord.user.firstName
                            : '')
                    }
                    open={emailModalVisible}
                    onCancel={hideEmailModal}
                    footer={null}
                    destroyOnClose={true}
                    width={1000}
                >
                    {emailModalVisible ? (
                        <EmailToUser
                            userId={selectedRecord.user.id}
                            onSuccess={hideEmailModal}
                        />
                    ) : null}
                </Modal>
            </Spin>
        </div>
    )
}

export default Transactions
