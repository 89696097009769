import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    notification,
    Select,
    InputNumber,
    Radio,
    Popconfirm,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'

function StudyPlannerCourseConfig(props) {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedStreamCourse, setSelectedStreamCourse] = useState()
    const [selectedStreamLevel, setSelectedStreamLevel] = useState()
    const [courses, setCourses] = useState([])

    useEffect(() => {
        getCourseAndLevel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedStreamLevel) {
            return
        }

        setData([])
        getConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStreamLevel])

    const getCourseAndLevel = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER)
            .then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
                setLevelData(streamData[0].levels)
            })
            .catch((error) => {
                notification['error']({
                    message: error,
                })
            })
    }

    const getConfig = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.STUDY_PLANNER.GET_COURSE_CONFIG +
                '?course=' +
                selectedStreamCourse +
                '&level=' +
                selectedStreamLevel
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getStreamCourseOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeStreamCourse = (event) => {
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                setSelectedStreamCourse(event.target.value)
                setSelectedStreamLevel('')

                return
            }
        })
    }

    const onChangeStreamLevel = (event) => {
        setSelectedStreamLevel(event.target.value)
    }

    const showAddEditModal = (record = {}) => {
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.courseId) {
            setTimeout(() => {
                form.setFieldsValue({
                    courseId: record.courseId,
                    revisionDays: record.revisionDays,
                    hrsMultiple: record.hrsMultiple,
                    hrsMultipleHigh: record.hrsMultipleHigh,
                    hrsMultipleMedium: record.hrsMultipleMedium,
                    hrsMultipleLow: record.hrsMultipleLow,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Hrs',
            dataIndex: 'hrsMultiple',
            key: 'hrsMultiple',
        },
        {
            title: 'Hrs (High)',
            dataIndex: 'hrsMultipleHigh',
            key: 'hrsMultipleHigh',
        },
        {
            title: 'Hrs (Medium)',
            dataIndex: 'hrsMultipleMedium',
            key: 'hrsMultipleMedium',
        },
        {
            title: 'Hrs (Low)',
            dataIndex: 'hrsMultipleLow',
            key: 'hrsMultipleLow',
        },
        {
            title: 'Revision Days',
            dataIndex: 'revisionDays',
            key: 'revisionDays',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}{' '}
                    <span style={{ fontSize: 10 }}>
                        {' (' + record.updatedAt + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteConfig(record.courseId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateConfig = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.STUDY_PLANNER.ADD_COURSE_CONFIG
                if (selectedRecord.courseId) {
                    url = GLOBAL.URL.STUDY_PLANNER.UPDATE_COURSE_CONFIG
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getConfig()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteConfig = (courseId) => {
        setLoading(true)

        var payload = {}
        payload.courseId = courseId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.STUDY_PLANNER.DELETE_COURSE_CONFIG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getConfig()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const courseOptions = () => {
        var options = []
        courses.forEach((item) => {
            options.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Course Name',
                dataIndex: 'courseName',
                key: 'courseName',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: data,
                updateUrl: GLOBAL.URL.STUDY_PLANNER.REORDER_COURSE_CONFIG,
                columns: columns,
            },
        })
    }

    return (
        <div>
            <h2>Study Planner Course Config</h2>
            <div>
                <div>
                    <Radio.Group onChange={onChangeStreamCourse}>
                        {getStreamCourseOptions()}
                    </Radio.Group>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Radio.Group
                        value={selectedStreamLevel}
                        onChange={onChangeStreamLevel}
                        disabled={selectedStreamCourse ? false : true}
                    >
                        {getStreamLevelOptions()}
                    </Radio.Group>
                </div>
            </div>
            <div style={{ marginTop: 10 }}>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddEditModal}
                    disabled={selectedStreamLevel ? false : true}
                    loading={loading}
                >
                    Add
                </Button>
                <Button
                    type="default"
                    size="small"
                    style={{ marginLeft: 10 }}
                    loading={loading}
                    onClick={reorder}
                    disabled={selectedStreamLevel ? false : true}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="courseName"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Course'}
                open={addEditModalVisible}
                onOk={addOrUpdateConfig}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.courseId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Courses"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Hrs Multiple"
                        name="hrsMultiple"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} step={0.1} precision={1} />
                    </Form.Item>
                    <Form.Item
                        label="Hrs Multiple High"
                        name="hrsMultipleHigh"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} step={0.1} precision={1} />
                    </Form.Item>
                    <Form.Item
                        label="Hrs Multiple Medium"
                        name="hrsMultipleMedium"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} step={0.1} precision={1} />
                    </Form.Item>
                    <Form.Item
                        label="Hrs Multiple Low"
                        name="hrsMultipleLow"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} step={0.1} precision={1} />
                    </Form.Item>
                    <Form.Item
                        label="Revision Days"
                        name="revisionDays"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default StudyPlannerCourseConfig
