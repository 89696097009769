import React, { useState, useEffect } from 'react'
import { Table, Button, Popconfirm, notification } from 'antd'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function OrphanModules() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_ORPHAN_MODULES)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Module Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteModule(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteModule = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_MODULE, postBody)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getData()
            })
            .catch((error) => {
                setLoading(true)
            })
    }

    return (
        <div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="name"
            />
        </div>
    )
}

export default OrphanModules
