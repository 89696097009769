import React, { useEffect, useState } from 'react'
import { Input, Button, message, Form } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import TinyMceEditor from '../Common/TinyMceEditor'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import MetaFormItems from '../Common/MetaFormItems'

const Pages = (props) => {
    const [content, setContent] = useState('')
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        checkEdit()
        //eslint-disable-next-line
    }, [])

    const checkEdit = () => {
        const { record } = props.location.state || {}
        if (record) {
            setLoading(true)
            RestApi.doGet(GLOBAL.URL.GET_PAGE + record.id)
                .then((res) => {
                    setFormFieldValues(res.data)
                    setContent(res.data.content)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const setFormFieldValues = (record) => {
        var metaArrayFields = [0]
        var meta = []
        var length = 1
        var seoObj = null
        if (record.details) {
            seoObj = JSON.parse(record.details).seo
            if (seoObj.meta) {
                var filtered = seoObj.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })

                if (filtered.length) {
                    metaArrayFields = []
                    meta = filtered
                    length = filtered.length

                    for (var k = 0; k < length; k++) {
                        metaArrayFields.push(k)
                    }
                }
            }
        }

        setTimeout(() => {
            form.setFieldsValue({ title: record.title })
            form.setFieldsValue({
                meta: meta,
                seoTitle: seoObj ? seoObj.title : '',
            })
        }, 100)
    }

    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var filtered = []
                if (values.meta && Array.isArray(values.meta)) {
                    filtered = values.meta.filter(function (el) {
                        return el != null && el.property && el.content
                    })
                }

                var seo = {}
                seo.title = values.seoTitle ? values.seoTitle : ''
                seo.meta = filtered

                values['seo'] = seo
                values['content'] = content

                var url = GLOBAL.URL.ADD_PAGE
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_PAGE
                    values['id'] = editRecord.id
                }
                let formbody = new FormData()
                formbody.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbody)
                    .then((response) => {
                        message.success(response.data, 5)
                        props.navigate('/page/pages')
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    let propsRecord = null
    if (props.location.state && props.location.state.record) {
        propsRecord = props.location.state.record
    }

    return (
        <div>
            <h2>Page</h2>
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Title"
                    style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                    }}
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Title',
                        },
                    ]}
                >
                    <Input placeholder="Please enter title" />
                </Form.Item>
                <Form.Item
                    label="Body"
                    style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        marginTop: 10,
                    }}
                >
                    {!loading ? (
                        <TinyMceEditor
                            handleEditorChange={handleEditorChange}
                            initialValue={content}
                        />
                    ) : null}
                </Form.Item>
                <Form.Item
                    label="SEO: Title"
                    style={{ marginBottom: 0 }}
                    name="seoTitle"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input placeholder="SEO: Title" />
                </Form.Item>
                <h3 style={{ marginTop: 10 }}>SEO: Meta</h3>
                <MetaFormItems />
            </Form>
            <div style={{ marginTop: 10 }}>
                {propsRecord ? (
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSubmit(propsRecord)
                        }}
                        loading={loading}
                        size="small"
                    >
                        Update
                    </Button>
                ) : (
                    <Button
                        type="primary"
                        onClick={() => {
                            handleSubmit()
                        }}
                        loading={loading}
                        size="small"
                    >
                        Add
                    </Button>
                )}
                <span style={{ marginLeft: 10 }}>
                    <UploadAndGetLink />
                </span>
            </div>
        </div>
    )
}

export default Pages
