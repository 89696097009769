import Globals from '../../Globals'

export function getMessageSalesUserNumber() {
    var number = ''

    if (Globals.USER_DATA.id === '06b127d4589548d087672195b1496c94') {
        // Nikhila
        number = '9154911197'
    } else if (Globals.USER_DATA.id === '516263096b4646c28fdd58b3cab0b2bd') {
        // Palani
        number = '9133310549'
    } else if (Globals.USER_DATA.id === '51eb0423f52a45cdaf5b3457a89cc93f') {
        // Manasa
        number = '9154911195'
    } else if (Globals.USER_DATA.id === '8b0f5004398e49898dfdad9c9f9cfdd5') {
        // Pravallika
        number = '7386733004'
    } else {
        // Company
        number = '9640111110'
    }

    return number
}
