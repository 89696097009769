import React, { useState, useEffect } from 'react'
import { InputNumber, Button, Table, Modal } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import StatsConstants from '../Stats/StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ChartDataLabels
)

const emptyBarData = {
    datasets: [{}],
}

function NotificationsSendingSummary() {
    const [loading, setLoading] = useState(false)
    const [monthWiseData, setMonthWiseData] = useState([])
    const [limit, setLimit] = useState(6)
    const [chartData, setChartData] = useState(emptyBarData)
    const [options, setOptions] = useState({})
    const [selectedRecord, setSelectedRecord] = useState({})
    const [selectedType, setSelectedType] = useState('')
    const [selectedTitle, setSelectedTitle] = useState('')
    const [monthSplitData, setMonthSplitData] = useState([])
    const [loadingMonthSplit, setLoadingMonthSplit] = useState(false)
    const [monthSplitModalVisible, setMonthSplitModalVisible] = useState(false)
    const [dayWiseData, setDayWiseData] = useState([])
    const [loadingDayWiseData, setLoadingDayWiseData] = useState(false)
    const [dayWiseModalVisible, setDayWiseModalVisible] = useState(false)

    useEffect(() => {
        getMonthWiseStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        formChartObject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthWiseData])

    const getMonthWiseStats = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.NOTIFICATION.GET_SENDING_SUMMARY_BY_MONTH + limit
        )
            .then((res) => {
                setMonthWiseData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Month',
            dataIndex: 'label',
            key: 'label',
            width: 100,
        },
        {
            title: 'Total',
            dataIndex: 'totalCount',
            key: 'totalCount',
            width: 100,
        },
        {
            title: 'WhatsApp',
            dataIndex: 'whatsAppCount',
            key: 'whatsAppCount',
            width: 100,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => getMonthSplit(record, record.whatsAppType)}
                >
                    {record.whatsAppCount}
                </Button>
            ),
        },
        {
            title: 'SMS',
            dataIndex: 'smsCount',
            key: 'smsCount',
            width: 100,
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => getMonthSplit(record, record.smsType)}
                >
                    {record.smsCount}
                </Button>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'emailCount',
            key: 'emailCount',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => getMonthSplit(record, record.emailType)}
                >
                    {record.emailCount}
                </Button>
            ),
        },
    ]

    const formChartObject = () => {
        if (!monthWiseData.length) {
            return
        }

        var labels = []
        monthWiseData.forEach((dataRow) => {
            labels.push(dataRow.label)
        })

        var dataSetsData = { whatsApp: [], sms: [], email: [] }
        monthWiseData.forEach((dataRow) => {
            dataSetsData.whatsApp.push(dataRow.whatsAppCountlong)
            dataSetsData.sms.push(dataRow.smsCountLong)
            dataSetsData.email.push(dataRow.emailCountLong)
        })

        var cnt = 0
        var dataSets = []
        for (var key in dataSetsData) {
            if (dataSetsData.hasOwnProperty(key)) {
                var color =
                    StatsConstants.COLORS[
                        cnt % (StatsConstants.COLORS.length - 1)
                    ]

                var dataSet = {}
                dataSet.label = key
                dataSet.data = dataSetsData[key]
                dataSet.backgroundColor = color
                dataSet.borderColor = color
                dataSet.fill = true

                dataSets.push(dataSet)

                cnt++
            }
        }

        var chartData = {}
        chartData.labels = labels
        chartData.datasets = dataSets

        var options = {
            ...clone(StatsConstants.CHART_OPTIONS),
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        }
        options.plugins.datalabels.anchor = 'center'
        options.hover = {}
        options.tooltips = { mode: 'label' }

        setChartData(chartData)
        setOptions(options)
    }

    const getMonthSplit = (record, type) => {
        setMonthSplitData([])
        setSelectedRecord(record)
        setSelectedType(type)
        setMonthSplitModalVisible(true)
        setLoadingMonthSplit(true)

        RestApi.doGet(
            GLOBAL.URL.NOTIFICATION.GET_SENDING_SUMMARY_MONTH_SPLIT +
                '?month=' +
                record.label +
                '&type=' +
                type
        )
            .then((res) => {
                setMonthSplitData(res.data)
            })
            .finally(() => {
                setLoadingMonthSplit(false)
            })
    }

    const hideMonthSplitModal = () => {
        setMonthSplitModalVisible(false)
    }

    const monthSplitTableColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 100,
        },
        {
            title: 'Split',
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() =>
                        getDayWiseData(
                            selectedRecord,
                            selectedType,
                            record.label
                        )
                    }
                >
                    {record.label}
                </Button>
            ),
        },
    ]

    const getDayWiseData = (record, type, title = '') => {
        setDayWiseData([])
        setLoadingDayWiseData(true)
        setDayWiseModalVisible(true)
        setSelectedTitle(title)

        RestApi.doGet(
            GLOBAL.URL.NOTIFICATION.GET_SUMMARY_BY_DAY +
                '?month=' +
                record.label +
                '&type=' +
                type +
                '&title=' +
                title
        )
            .then((res) => {
                setDayWiseData(res.data)
            })
            .finally(() => {
                setLoadingDayWiseData(false)
            })
    }

    const hideDayWiseDataModal = () => {
        setDayWiseModalVisible(false)
    }

    const daySplitTableColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'label',
            key: 'label',
            width: 100,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 100,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>{getDaySplitView(record.split)}</span>
            ),
        },
    ]

    const getDaySplitView = (split) => {
        if (split.length === 1) {
            return <span>{split[0].label}</span>
        } else {
            return (
                <Table
                    columns={daySplitByTitleColumns}
                    dataSource={split}
                    pagination={false}
                    size="small"
                    rowKey="label"
                />
            )
        }
    }

    const daySplitByTitleColumns = [
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            width: 100,
        },
        {
            title: 'Title',
            dataIndex: 'label',
            key: 'label',
        },
    ]

    return (
        <div>
            <h2>Notifications Sending Summary</h2>
            <div>
                <span># Months: </span>
                <InputNumber
                    min={1}
                    defaultValue={6}
                    onChange={(value) => setLimit(value)}
                />
                <Button
                    type="primary"
                    onClick={getMonthWiseStats}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch
                </Button>
            </div>
            <Bar data={chartData} width={800} height={400} options={options} />
            <h2>Table View</h2>
            <Table
                columns={tableColumns}
                dataSource={monthWiseData}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="label"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={selectedRecord.label + ' Month Split'}
                open={monthSplitModalVisible}
                onCancel={hideMonthSplitModal}
                footer={null}
                width={600}
            >
                <div>
                    <Button
                        onClick={() =>
                            getDayWiseData(selectedRecord, selectedType)
                        }
                    >
                        Show Day Split
                    </Button>
                </div>
                <Table
                    columns={monthSplitTableColumns}
                    dataSource={monthSplitData}
                    pagination={false}
                    size="small"
                    loading={loadingMonthSplit}
                    rowKey="label"
                />
            </Modal>
            <Modal
                title={
                    selectedRecord.label +
                    ' Day Split' +
                    (selectedTitle ? ' - ' + selectedTitle : '')
                }
                open={dayWiseModalVisible}
                onCancel={hideDayWiseDataModal}
                footer={null}
                width={800}
            >
                <Table
                    columns={daySplitTableColumns}
                    dataSource={dayWiseData}
                    pagination={false}
                    size="small"
                    loading={loadingDayWiseData}
                    rowKey="label"
                />
            </Modal>
        </div>
    )
}

export default NotificationsSendingSummary
