import {
    Table,
    message,
    Button,
    Popconfirm,
    notification,
    Radio,
    Modal,
} from 'antd'
import { CopyOutlined, EditOutlined, WechatOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AddNews from './AddNews'
class NewsAndUpdates extends React.Component {
    state = {
        visible: false,
        loading: false,
        newsData: [],
        nextUrl: '',
        filter: '',
        addUpdateNewsVisible: false,
        selectedRecord: {},
    }
    componentDidMount() {
        this.getNewsandUpdates()
    }
    getNewsandUpdates = () => {
        this.setState({ loading: true })
        RestApi.doGet(GLOBAL.URL.GET_NEWS + '?filter=' + this.state.filter)
            .then((res) => {
                var parsedObject = JSON.parse(res.data)
                this.setState({
                    newsData: parsedObject.data,
                    nextUrl: parsedObject.next,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    getMoreNews = () => {
        if (!this.state.nextUrl) {
            return
        }

        this.setState({ loading: true })
        var getMoreData = GLOBAL.URL.BASE_URL + this.state.nextUrl
        RestApi.doGet(getMoreData)
            .then((resp) => {
                var moreData = JSON.parse(resp.data)
                this.setState({
                    newsData: [...this.state.newsData, ...moreData.data],
                    nextUrl: moreData.next,
                })
            })
            .finally((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    publishNews(id, action) {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('id', id)
        let url = ''
        if (action === 'publish') {
            url = GLOBAL.URL.PUBLISH_NEWS
        } else {
            url = GLOBAL.URL.UNPUBLISH_NEWS
        }
        RestApi.doPost(url, postBody).then((response) => {
            if (response.code === 200) {
                var successObject = this.state.newsData
                successObject.forEach((data) => {
                    if (id === data.id) {
                        data.published = action === 'publish' ? true : false
                        return
                    }
                })
                this.setState({ newsData: successObject })
                message.destroy()
                message.success(response.data)
            }
        })
    }

    updateAllowComments = (record) => {
        this.setState({ loading: true })

        var url = GLOBAL.URL.ENABLE_COMMENTS_IN_NEWS
        if (record.allowComments) {
            url = GLOBAL.URL.DISABLE_COMMENTS_IN_NEWS
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success(response.data)

                    var newsData = this.state.newsData
                    newsData.forEach((data) => {
                        if (record.id === data.id) {
                            data.allowComments = !data.allowComments
                            return
                        }
                    })
                    this.setState({ newsData: newsData })
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    deleteNews = (id) => {
        this.setState({ loading: true })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ newsId: id }))

        RestApi.doPost(GLOBAL.URL.DELETE_NEWS, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getNewsandUpdates()
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    onChangeFilter = (event) => {
        this.setState({ filter: event.target.value }, () => {
            this.getNewsandUpdates()
        })
    }

    showAddUpdateNews = (record = {}) => {
        this.setState({ addUpdateNewsVisible: true, selectedRecord: record })
    }

    hideAddUpdateNews = () => {
        this.setState({ addUpdateNewsVisible: false, selectedRecord: {} })
    }

    render() {
        const columns = [
            {
                title: 'Edit',
                key: 'action',
                width: '50px',
                render: (text, record) => (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => this.showAddUpdateNews(record)}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 14 }}
                        />
                    </Button>
                ),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (
                    <span>
                        <a
                            href={'/newswithopencomments/' + record.id}
                            style={{
                                display: 'table-cell',
                                padding: 0,
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <WechatOutlined />
                        </a>
                        <span style={{ marginLeft: 5 }}>{record.title}</span>
                    </span>
                ),
            },
            {
                title: 'Views',
                dataIndex: 'views',
                key: 'views',
            },
            {
                title: 'Deep Link',
                dataIndex: 'deepLink',
                key: 'deepLink',
                render: (text, record) => (
                    <span>
                        {record.deepLink ? (
                            <CopyToClipboard
                                text={record.deepLink}
                                onCopy={() =>
                                    notification['success']({
                                        message: 'Link copied!',
                                    })
                                }
                            >
                                <span>
                                    <Button
                                        type="link"
                                        style={{ padding: 0, fontSize: 10 }}
                                    >
                                        <CopyOutlined />
                                        {'Copy Link'}
                                    </Button>
                                </span>
                            </CopyToClipboard>
                        ) : null}
                    </span>
                ),
            },
            {
                title: 'Scheduled At',
                dataIndex: 'scheduledTime',
                key: 'scheduledTime',
                render: (text, record) => (
                    <span>
                        <span style={{ fontSize: 10 }}>
                            {record.scheduledTime}
                        </span>
                    </span>
                ),
            },
            {
                title: 'Published At',
                dataIndex: 'publishedAt',
                key: 'publishedAt',
                render: (text, record) => (
                    <span>
                        <span style={{ fontSize: 10 }}>
                            {record.publishedAt}
                        </span>
                    </span>
                ),
            },
            {
                title: 'Comments',
                key: 'comments',
                render: (text, record) => (
                    <span>
                        {!record.allowComments ? (
                            <Button
                                type="default"
                                onClick={() => {
                                    this.updateAllowComments(record)
                                }}
                                size="small"
                            >
                                Not Allowed
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                onClick={() => {
                                    this.updateAllowComments(record)
                                }}
                                size="small"
                            >
                                Allowed
                            </Button>
                        )}
                    </span>
                ),
            },
            {
                title: 'Published',
                key: 'published',
                render: (text, record) => (
                    <span>
                        {!record.published ? (
                            <Button
                                style={{
                                    background: 'red',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    this.publishNews(record.id, 'publish')
                                }}
                                size="small"
                            >
                                Not Published
                            </Button>
                        ) : (
                            <Button
                                type="default"
                                onClick={() => {
                                    this.publishNews(record.id, 'unpublish')
                                }}
                                size="small"
                            >
                                Published
                            </Button>
                        )}
                    </span>
                ),
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                key: 'delete',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.deleteNews(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                                fontSize: 11,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                ),
            },
        ]
        return (
            <div>
                <h2>News &amp; Updates</h2>
                <div>
                    <Button type="primary" onClick={this.showAddUpdateNews}>
                        Add News
                    </Button>
                    <Radio.Group
                        onChange={this.onChangeFilter}
                        defaultValue={this.state.filter}
                        style={{ marginLeft: 10 }}
                    >
                        <Radio.Button value={''}>All</Radio.Button>
                        <Radio.Button value={'Open'}>Open</Radio.Button>
                    </Radio.Group>
                    <span style={{ marginLeft: 10 }}>
                        <UploadAndGetLink />
                    </span>
                </div>
                <Table
                    columns={columns}
                    dataSource={this.state.newsData}
                    pagination={false}
                    size="small"
                    loading={this.state.loading}
                    rowKey="title"
                    style={{ marginTop: 10 }}
                />
                {this.state.nextUrl ? (
                    <Button
                        type="primary"
                        size="small"
                        loading={this.state.loading}
                        style={{ marginTop: 10 }}
                        onClick={() => this.getMoreNews()}
                    >
                        More
                    </Button>
                ) : null}
                <Modal
                    title={'Configure News'}
                    open={this.state.addUpdateNewsVisible}
                    footer={null}
                    onCancel={this.hideAddUpdateNews}
                    destroyOnClose={true}
                    width={1000}
                >
                    <AddNews
                        id={this.state.selectedRecord.id}
                        hideAddUpdateNews={this.hideAddUpdateNews}
                        refreshData={this.getNewsandUpdates}
                    />
                </Modal>
            </div>
        )
    }
}

export default NewsAndUpdates
