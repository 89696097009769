import React, { useState } from 'react'
import { Upload, Button, notification } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'

function UpdateCoursePrices() {
    const [fileList, setFileList] = useState([])
    const [updateMessage, setUpdateMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const { Dragger } = Upload

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    const updatePrices = () => {
        if (fileList.length === 0) {
            notification['error']({
                message: 'Select file to upload!',
            })
            return
        }

        setLoading(true)

        var postBody = new FormData()
        postBody.append('file', fileList[0])

        RestApi.doPost(GLOBAL.URL.UPDATE_COURSE_PRICES_BULK, postBody)
            .then((response) => {
                setUpdateMessage(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <div>
                <ul>
                    <li>{'Have only 1 sheet in the excel file'}</li>
                    <li>
                        {'Column headers in 1st row:'}
                        <ul>
                            <li>{'course_id'}</li>
                            <li>{'attempt'}</li>
                            <li>{'strike_price'}</li>
                            <li>{'price'}</li>
                        </ul>
                    </li>
                </ul>
                <Dragger {...uploadProps}>
                    <Button>
                        <UploadOutlined />
                        {'Click or Drag file to this area to add attachment'}
                    </Button>
                </Dragger>
            </div>
            <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={updatePrices} loading={loading}>
                    Update
                </Button>
            </div>
            <div style={{ marginTop: 20 }}>
                {updateMessage ? (
                    <span style={{ color: 'blue' }}>{updateMessage}</span>
                ) : null}
            </div>
        </div>
    )
}

export default UpdateCoursePrices
