import React, { useEffect, useState } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import { Table } from 'antd'

function UserCartView(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_CART_VIEW + props.userId)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const coursesTableColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Attempt',
            dataIndex: 'attemptDisplayName',
            key: 'attemptDisplayName',
        },
        {
            title: 'Strike %',
            dataIndex: 'strikePriceDiscount',
            key: 'strikePriceDiscount',
        },
        {
            title: 'Price',
            dataIndex: 'originalPrice',
            key: 'originalPrice',
        },
        {
            title: 'Strike Price',
            dataIndex: 'strikePriceString',
            key: 'strikePriceString',
        },
    ]

    const getSummaryTable = () => {
        return (
            <div style={{ marginTop: 20 }}>
                <h4>Summary</h4>
                <table
                    style={{
                        border: '1px solid #e8e8e8',
                        borderCollapse: 'collapse',
                        borderRadius: 4,
                    }}
                >
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'left',
                                }}
                            >
                                {'MRP'}
                            </td>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'right',
                                }}
                            >
                                {data.strikePrice}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'left',
                                }}
                            >
                                {'Price'}
                            </td>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'right',
                                }}
                            >
                                {data.mrp}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'left',
                                }}
                            >
                                {'iCash'}
                            </td>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'right',
                                }}
                            >
                                {'- ' + data.credits}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'left',
                                }}
                            >
                                {'Discount'}
                            </td>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'right',
                                }}
                            >
                                {'- ' + data.discount}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                }}
                            >
                                {'Total Amount'}
                            </td>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                }}
                            >
                                {data.price}
                            </td>
                        </tr>
                        <tr>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    color: 'green',
                                }}
                            >
                                {'You Saved (' +
                                    data.totalDiscountPercent +
                                    ')'}
                            </td>
                            <td
                                style={{
                                    padding: 10,
                                    border: '1px solid #e8e8e8',
                                    borderCollapse: 'collapse',
                                    textAlign: 'right',
                                    fontWeight: 'bold',
                                    color: 'green',
                                }}
                            >
                                {data.totalDiscount}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div>
            <h4>Courses</h4>
            <Table
                columns={coursesTableColumns}
                dataSource={data.courses}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="courseName"
            />
            {data.coupon && data.coupon.couponName ? (
                <div style={{ marginTop: 20 }}>
                    <h4>Coupon</h4>
                    <span
                        style={{
                            color: 'green',
                            fontSize: 16,
                            fontWeight: 'bold',
                        }}
                    >
                        {data.coupon.couponName +
                            '   **   ' +
                            data.discount +
                            '   **   ' +
                            data.coupon.discount +
                            data.coupon.type}
                    </span>
                </div>
            ) : null}
            {getSummaryTable()}
        </div>
    )
}

export default UserCartView
