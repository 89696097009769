import { actionTypes } from '../actions'

export const isLoginModal = (state = false, action) => {
    switch (action.type) {
        case actionTypes.SET_SHOW_LOG_IN_MODAL:
            const showLoginModal = action.showLoginModal
            return showLoginModal
        default:
            return state
    }
}
