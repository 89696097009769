import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    Form,
    notification,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import TinyMceEditor from '../Common/TinyMceEditor'
import { useParams } from 'react-router-dom'
import { goBack } from '../../Util/ILUtil'

function StreamPageContent(props) {
    const [loading, setLoading] = useState(false)
    const [pageId, setPageId] = useState()
    const [data, setData] = useState([])
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [content, setContent] = useState('')
    const [contentModalVisible, setContentModalVisible] = useState(false)
    const [form] = Form.useForm()
    const { pageid: paramsPageId } = useParams()

    useEffect(() => {
        setPageId(paramsPageId)
        getPageContent(paramsPageId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getPageContent = (pageid = pageId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.STREAM_PAGE.GET_STREAM_PAGE_CONTENT + pageid)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddUpdateModal = (record = {}) => {
        setAddUpdateModalVisible(true)
        setSelectedRecord(record)
        setContent('')

        if (record.id) {
            setContent(record.content)
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
    }

    const addUpdateContent = () => {
        form.validateFields()
            .then((values) => {
                if (!content) {
                    notification['error']({
                        message: 'Section content cannot be empty',
                    })

                    return
                }

                setLoading(true)

                values.pageId = pageId
                values.content = content

                var url = GLOBAL.URL.STREAM_PAGE.ADD_STREAM_PAGE_CONTENT
                if (selectedRecord.id) {
                    url = GLOBAL.URL.STREAM_PAGE.UPDATE_STREAM_PAGE_CONTENT
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateModal()
                        getPageContent()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.STREAM_PAGE.ENABLE_STREAM_PAGE_CONTENT
        if (record.enabled) {
            url = GLOBAL.URL.STREAM_PAGE.DISABLE_STREAM_PAGE_CONTENT
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                var fullData = data
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteContent = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.STREAM_PAGE.DELETE_STREAM_PAGE_CONTENT,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getPageContent()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'pageId',
            key: 'pageId',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 80,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                    }}
                    onClick={() => {
                        enableDisable(record)
                    }}
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'pageTitle',
            key: 'pageTitle',
            render: (text, record) => <span>{record.title}</span>,
        },
        {
            title: 'TOC Title',
            dataIndex: 'tocTitle',
            key: 'tocTitle',
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showContentModal(record)}
                >
                    View
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteContent(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const showContentModal = (record) => {
        setContent(record.content)
        setContentModalVisible(true)
    }

    const hideContentModal = () => {
        setContentModalVisible(false)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: data,
                updateUrl: GLOBAL.URL.STREAM_PAGE.REORDER_STREAM_PAGE_CONTENT,
                columns: columns,
            },
        })
    }

    return (
        <div>
            <h2>{pageId}</h2>
            <div style={{ marginTop: 10 }}>
                <Button
                    onClick={() => {
                        goBack(props.navigate)
                    }}
                    size="small"
                >
                    Go Back
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => showAddUpdateModal({})}
                    style={{ marginLeft: 10 }}
                    loading={loading}
                >
                    Add Section
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={reorder}
                    style={{ marginLeft: 10 }}
                    loading={loading}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="title"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Section'}
                open={addUpdateModalVisible}
                onOk={addUpdateContent}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter section title',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="TOC Title"
                        name="tocTitle"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter TOC title',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <div style={{ marginTop: 10 }}>
                        <h4>Section Content</h4>
                        <TinyMceEditor
                            height={300}
                            handleEditorChange={handleEditorChange}
                            initialValue={content}
                        />
                    </div>
                </Form>
            </Modal>
            <Modal
                title={'Sectiong Content'}
                open={contentModalVisible}
                confirmLoading={loading}
                onCancel={hideContentModal}
                footer={null}
                destroyOnClose={true}
                width={1000}
            >
                <div dangerouslySetInnerHTML={createMarkupWeb(content)} />
            </Modal>
        </div>
    )
}

export default StreamPageContent
