import React from 'react'
import { message, Button, Modal, Input, Popconfirm, Radio } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { goBack } from '../../Util/ILUtil'

const FILTER_OPEN = 'open'
const FILTER_ALL = 'all'

class BlogComments extends React.Component {
    state = {
        blogId: '',
        data: [],
        comments: [],
        replyModalVisible: false,
        parentId: '',
        reply: '',
        comment: '',
        loading: false,
        filter: FILTER_OPEN,
    }

    componentDidMount() {
        const { blogId } = this.props.location.state
        this.setState({ blogId: blogId }, () => {
            this.getBlogWithOpenComments()
        })
    }

    getBlogWithOpenComments = () => {
        message.loading('Updating Data', 0)

        RestApi.doGet(
            GLOBAL.URL.BLOG.GET_BLOG_WITH_OPEN_COMMENTS +
                this.state.blogId +
                '&filter=' +
                this.state.filter
        ).then((res) => {
            this.setState({
                data: res.data,
                comments: res.data.comments,
            })

            message.destroy()
        })
    }

    navigateToUserDetails = (record) => {
        this.props.navigate('/userdetails/' + record.id, {
            state: { record: record },
        })
    }

    deleteComment = (commentId) => {
        message.loading('Deleting Comment', 0)

        var payload = {}
        payload.commentId = commentId

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_BLOG_COMMENT, body).then(
            (response) => {
                message.destroy()
                message.success(response.data)

                this.getBlogWithOpenComments()
            }
        )
    }

    updateAnswered = (commentId, answered) => {
        message.loading('Updating Answered Status', 0)

        var url = GLOBAL.URL.BLOG.MARK_BLOG_COMMENT_ANSWERED
        if (answered) {
            url = GLOBAL.URL.BLOG.MARK_BLOG_COMMENT_UNANSWERED
        }

        var payload = {}
        payload.commentId = commentId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel).then((res) => {
            message.destroy()
            this.getBlogWithOpenComments()
        })
    }

    showReplyModal = (parentId) => {
        this.setState({
            parentId: parentId,
            replyModalVisible: true,
            reply: '',
        })
    }

    hideReplyModal = () => {
        this.setState({
            replyModalVisible: false,
        })
    }

    getCommentCard = (record, parent) => {
        return (
            <Card
                style={{ marginBottom: parent ? 15 : 5 }}
                variant="outlined"
                key={record.commentId}
            >
                <CardContent style={{ padding: 8 }}>
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() =>
                            this.updateAnswered(
                                record.commentId,
                                record.answered
                            )
                        }
                    >
                        {record.answered ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                    {record.comments}
                    <div style={{ marginLeft: 29 }}>
                        <Button
                            style={{ fontSize: 12, padding: 0 }}
                            type="link"
                            onClick={() =>
                                this.navigateToUserDetails(record.user)
                            }
                        >
                            {record.user.firstName} {record.user.lastName}
                        </Button>
                        {' ('}
                        <span style={{ fontSize: 11 }}>{record.date}</span>
                        {')'}
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                this.deleteComment(record.commentId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    fontSize: 9,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    </div>
                    {record.children.length > 0
                        ? record.children.map((comment) =>
                              this.getCommentCard(comment, false)
                          )
                        : null}
                    {parent ? (
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="primary"
                                onClick={() =>
                                    this.showReplyModal(record.commentId)
                                }
                                size="small"
                            >
                                Reply
                            </Button>
                        </div>
                    ) : null}
                </CardContent>
            </Card>
        )
    }

    onReplyChange = (e) => {
        this.setState({ reply: e.target.value })
    }

    addComment = () => {
        message.loading('Posting your reply', 0)
        this.setState({ loading: true })

        var payload = {}
        payload.blogId = this.state.blogId
        payload.comments = this.state.reply
        payload.parentId = this.state.parentId

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.ADD_BLOG_COMMENT, body)
            .then((response) => {
                message.destroy()
                this.hideReplyModal()
                this.getBlogWithOpenComments()
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    markAllCommentsAnswered = () => {
        message.loading('Marking all comments as answered', 0)
        this.setState({ loading: true })

        var payload = {}
        payload.blogId = this.state.blogId

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.MARK_ALL_BLOG_COMMENTS_AS_ANSWERED, body)
            .then((response) => {
                message.destroy()
                this.getBlogWithOpenComments()
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    onChangeFilter = (e) => {
        this.setState({ filter: e.target.value }, () => {
            this.getBlogWithOpenComments()
        })
    }

    render() {
        const { TextArea } = Input

        return (
            <div>
                <div>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => goBack(this.props.navigate)}
                    >
                        {'<'} Back
                    </Button>
                    <Radio.Group
                        onChange={this.onChangeFilter}
                        defaultValue={this.state.filter}
                        style={{ marginLeft: 20 }}
                    >
                        <Radio.Button value={FILTER_OPEN} key={FILTER_OPEN}>
                            Open Comments
                        </Radio.Button>
                        <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                            All Comments
                        </Radio.Button>
                    </Radio.Group>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.markAllCommentsAnswered()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" style={{ marginLeft: 20 }}>
                            Mark All Comments Answered
                        </Button>
                    </Popconfirm>
                </div>
                <h2 style={{ marginTop: 5 }}>{this.state.data.title}</h2>
                <h4>Answered / Comment</h4>
                {this.state.comments.map((comment) =>
                    this.getCommentCard(comment, true)
                )}
                <Modal
                    title="Your Reply"
                    open={this.state.replyModalVisible}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideReplyModal}
                    onOk={this.addComment}
                    okText="Post"
                    destroyOnClose={true}
                >
                    <TextArea
                        rows={4}
                        onChange={this.onReplyChange}
                        autoFocus
                    />
                </Modal>
            </div>
        )
    }
}

export default BlogComments
