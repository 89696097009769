import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
    Table,
    Tag,
    Popconfirm,
    notification,
    Radio,
    Button,
    Modal,
    Input,
    Select,
    DatePicker,
    message,
    Tooltip,
    Upload,
    Checkbox,
    Form,
} from 'antd'
import {
    EditOutlined,
    LoadingOutlined,
    ReloadOutlined,
    UploadOutlined,
} from '@ant-design/icons'
import clone from 'clone'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import TinyMceEditor from '../Common/TinyMceEditor'
import Paper from '@mui/material/Paper'
import { Link } from 'react-router-dom'
import { Utils } from '../JS/Utils'
import dayjs from 'dayjs'

const NOTIFICATION_TYPE_ALL = 'All'
const NOTIFICATION_TYPE_REGISTERED_USERS = 'Course'
const NOTIFICATION_TYPE_STREAM = 'Stream'
const NOTIFICATION_TYPE_FILE_UPLOAD = 'File_Upload'

function EmailNotifications(props) {
    const { Option } = Select
    const { Dragger } = Upload
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [filter, setFilter] = useState('')
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [testMessageLoading, setTestMessageLoading] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [scheduledTime, setScheduledTime] = useState()
    const [fileList, setFileList] = useState([])
    const [userPlaceHolders, setUserPlaceHolders] = useState([])
    const [notificationType, setNotificationType] = useState('')
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [content, setContent] = useState('')
    const [emailTemplates, setEmailTemplates] = useState([])
    const [selectedTemplateName, setSelectedTemplateName] = useState()
    const [previewBodyModalVisible, setPreviewBodyModalVisible] =
        useState(false)
    const [refreshEmailStatsLoading, setRefreshEmailStatsLoading] =
        useState(false)
    const [emailOpenRateLoading, setEmailOpenRateLoading] = useState(false)
    const [isEmailCustomHtml, setIsEmailCustomHtml] = useState(false)
    const [
        emailCustomHtmlPreviewModalVisible,
        setEmailCustomHtmlPreviewModalVisible,
    ] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        getNotications()

        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            setStreamData(JSON.parse(res.data))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const getNotications = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.EMAIL.GET_EMAIL_NOTIFICATIONS + '?filter=' + filter
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageNotifications = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddUpdateModal = (record) => {
        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }

        if (emailTemplates.length === 0) {
            RestApi.doGet(GLOBAL.URL.EMAIL.GET_EMAIL_ALL_TEMPLATES).then(
                (res) => {
                    setEmailTemplates(res.data)
                }
            )
        }

        setSelectedRecord(record)
        setFileList([])
        setAddUpdateModalVisible(true)
        setContent('')
        if (record.emailId) {
            checkOnChangeNotificationTypeDependencies(
                record.notificationType,
                selectedRecord.course
            )

            if (record.course) {
                onChangeStream(record.course)
            }

            setNotificationType(record.notificationType)
            setIsEmailCustomHtml(record.customHtml)
            if (record.body) {
                setContent(record.body)
            }

            var recordClone = clone(record)
            if (record.scheduledTime) {
                recordClone.scheduledTime = dayjs(
                    record.scheduledTime,
                    'DD-MMM-YY HH:mm'
                )
            }

            setTimeout(() => {
                form.setFieldsValue({
                    scheduledTime: recordClone.scheduledTime,
                    subject: recordClone.subject,
                    emailPreHeader: recordClone.preHeader,
                    courseNames: recordClone.courseNames,
                    attemptNames: recordClone.attemptNames,
                    course: recordClone.course,
                    level: recordClone.level,
                    subscribedUsersOnly: recordClone.subscribedUsersOnly,
                })

                if (recordClone.template && recordClone.template.templateName) {
                    setSelectedTemplateName(recordClone.template.templateName)

                    form.setFieldsValue({
                        templateName: recordClone.template.templateName,
                    })
                }

                if (recordClone.customHtml) {
                    form.setFieldsValue({
                        body: recordClone.body,
                    })
                }
            }, 100)
        } else {
            setScheduledTime()
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
        setNotificationType()
        setSelectedTemplateName()
    }

    const onChangeScheduleDate = (value, dateString) => {
        setScheduledTime(dateString)
    }

    const getPlaceHolders = () => {
        var res = []
        userPlaceHolders.forEach((userPlaceHolder) => {
            res.push(<Tag>{userPlaceHolder}</Tag>)
        })

        return res
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onChangeFilter = (event) => {
        setFilter(event.target.value)
    }

    const deleteNotification = (emailId) => {
        setLoading(true)

        var payload = {}
        payload.emailId = emailId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.EMAIL.DELETE_EMAIL_NOTIFICATION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getNotications()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const sendTestEmailByRecord = (record) => {
        sendTestEmail(
            record.template && record.template.templateName
                ? record.template.templateName
                : '',
            record.subject,
            record.body,
            record.preHeader,
            record.customHtml
        )
    }

    const sendTestEmailByForm = () => {
        form.validateFields(['templateName', 'subject', 'emailPreHeader'])
            .then((values) => {
                if (!values.templateName && !values.subject) {
                    message.error('Select a template or enter Email Subject!')
                    return
                }

                if (!values.templateName && !content) {
                    message.error('Select a template or enter Email Body!')
                    return
                }

                sendTestEmail(
                    values.templateName,
                    values.subject,
                    content,
                    values.emailPreHeader,
                    isEmailCustomHtml
                )
            })
            .catch(() => {
                //empty catch block
            })
    }

    const sendTestEmail = (
        templateName,
        subject,
        body,
        emailPreHeader = '',
        customHtml = false
    ) => {
        setTestMessageLoading(true)

        var payload = {}
        payload.templateName = templateName
        payload.subject = subject
        payload.body = body
        payload.emailPreHeader = emailPreHeader
        payload.emailIsCustomHtml = customHtml

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.EMAIL.TEST_EMAIL_NOTIFICATION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setTestMessageLoading(false)
            })
    }

    const showBodyPreview = (record) => {
        if (!record.body) {
            return
        }

        setSelectedRecord(record)
        setPreviewBodyModalVisible(true)
    }

    const hideBodyPreview = () => {
        setSelectedRecord({})
        setPreviewBodyModalVisible(false)
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span>
                    {record.status === 'Sent' ? (
                        <span style={{ fontSize: 12 }}>{record.status}</span>
                    ) : (
                        <>
                            <Tag
                                color={
                                    record.status === 'Open'
                                        ? 'geekblue'
                                        : record.status === 'Error'
                                        ? 'red'
                                        : 'purple'
                                }
                            >
                                {record.status}
                            </Tag>
                            {record.status === 'Error' && record.statusText ? (
                                <Tooltip title={record.statusText}>
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            ) : null}
                        </>
                    )}
                </span>
            ),
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (text, record) => (
                <span>
                    {record.status === 'Open' ||
                    record.status === 'Sending' ||
                    record.status === 'Queued' ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showAddUpdateModal(record)
                            }}
                            style={{ padding: 0, marginRight: 5 }}
                        >
                            <EditOutlined
                                theme="twoTone"
                                style={{ fontSize: 10 }}
                            />
                        </Button>
                    ) : null}
                    <span style={{ fontSize: 14, fontWeight: 500 }}>
                        {record.template && record.template.emailSubject
                            ? record.template.emailSubject
                            : record.subject}
                    </span>
                    {record.template ? (
                        <>
                            <br />
                            <span>{'Template: ' + record.template.title}</span>
                        </>
                    ) : null}
                    <br />
                    <Popconfirm
                        title="Send test email?"
                        onConfirm={() => sendTestEmailByRecord(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            size="small"
                            loading={testMessageLoading}
                            style={{ padding: 0, fontSize: 11 }}
                        >
                            Test
                        </Button>
                    </Popconfirm>
                    {record.body ? (
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 11, marginLeft: 10 }}
                            onClick={() => showBodyPreview(record)}
                        >
                            Preview
                        </Button>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
        {
            title: 'Scheduled Time',
            dataIndex: 'scheduledTime',
            key: 'scheduledTime',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>{record.scheduledTime}</span>
                </span>
            ),
        },
        {
            title: 'Sent Time',
            dataIndex: 'sentStartTime',
            key: 'sentStartTime',
            width: 125,
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.sentStartTime}
                    <br />
                    {record.sentEndTime}
                </span>
            ),
        },
        {
            title: 'Count',
            dataIndex: 'totalCount',
            key: 'totalCount',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {Utils.isUserAdmin() ? (
                        <Button
                            type="link"
                            onClick={() => refreshEmailStats(record.emailId)}
                            style={{ marginRight: 0, padding: 0 }}
                        >
                            {refreshEmailStatsLoading ? (
                                <LoadingOutlined style={{ fontSize: 10 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 10 }} />
                            )}
                        </Button>
                    ) : null}
                    {record.totalCount}
                    {record.totalCount !== record.sentCount ? (
                        <>
                            <br />
                            {record.sentCount}
                            {record.sendingCount ? (
                                <span>
                                    <br />
                                    {'(' + record.sendingCount + ')'}
                                </span>
                            ) : null}
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Open',
            dataIndex: 'open',
            key: 'open',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.openPercent ? (
                        record.openPercent
                    ) : (
                        <Button
                            type="link"
                            onClick={() => getOpenRate(record.emailId)}
                        >
                            {emailOpenRateLoading ? (
                                <LoadingOutlined style={{ fontSize: 12 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 12 }} />
                            )}
                        </Button>
                    )}
                </span>
            ),
        },
        {
            title: 'Failed',
            dataIndex: 'failedCount',
            key: 'failedCount',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.failedCount}</span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 11 }}>
                        {record.updatedBy.firstName}
                        <br />
                        {record.updatedAt}
                    </span>
                </span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'emailId',
            key: 'emailId',
            render: (text, record) => (
                <>
                    {record.status === 'Open' ||
                    record.status === 'Sending' ||
                    record.status === 'Queued' ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => deleteNotification(record.emailId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                    marginRight: 10,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ]

    const onChangeNotificationType = (event) => {
        setNotificationType(event.target.value)
        setScheduledTime()
        checkOnChangeNotificationTypeDependencies(event.target.value)
    }

    const checkOnChangeNotificationTypeDependencies = (type) => {
        if (
            type === NOTIFICATION_TYPE_REGISTERED_USERS &&
            courseNames.length === 0
        ) {
            RestApi.doGet(GLOBAL.URL.GET_NON_BUNDLE_COURSE_NAMES).then(
                (res) => {
                    setCourseNames(res.data)
                }
            )
        }

        if (
            (type === NOTIFICATION_TYPE_REGISTERED_USERS ||
                type === NOTIFICATION_TYPE_STREAM) &&
            attempts.length === 0
        ) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
                setAttempts(res.data)
            })
        }
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const emailTemplateOptions = () => {
        var options = []
        emailTemplates.forEach((item) => {
            options.push(
                <Option value={item.templateName} key={item.templateName}>
                    {item.title}
                </Option>
            )
        })

        return options
    }

    const courseNameOptions = () => {
        var options = []
        courseNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const attemptOptions = () => {
        var options = []
        attempts.forEach((attempt) => {
            options.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt}
                </Option>
            )
        })

        return options
    }

    const onChangeStream = (value) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === value) {
                levels = row.levels
            }
        })
        setLevelsData(levels)
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelsData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const addOrUpdateEmailNotification = () => {
        form.validateFields([
            'templateName',
            'subject',
            'emailPreHeader',
            'courseNames',
            'attemptNames',
            'course',
            'level',
            'subscribedUsersOnly',
        ])
            .then((values) => {
                if (!values.templateName && !values.subject) {
                    message.error('Select a template or enter Email Subject!')
                    return
                }

                if (!values.templateName && !content) {
                    message.error('Select a template or enter Email Body!')
                    return
                }

                setLoading(true)

                values.scheduledTime = scheduledTime
                values.notificationType = notificationType
                values.body = content
                values.emailIsCustomHtml = isEmailCustomHtml

                var url = GLOBAL.URL.EMAIL.ADD_EMAIL_NOTIFICATION
                if (selectedRecord.emailId) {
                    url = GLOBAL.URL.EMAIL.UPDATE_EMAIL_NOTIFICATION
                    values.emailId = selectedRecord.emailId
                }

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                if (notificationType === NOTIFICATION_TYPE_FILE_UPLOAD) {
                    body.append('file', fileList[0])
                }

                RestApi.doPost(url, body)
                    .then((response) => {
                        message.success(response.data)
                        hideAddUpdateModal()
                        getNotications()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const getOpenRate = (emailId) => {
        setEmailOpenRateLoading(true)

        var payload = {}
        payload.emailId = emailId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.EMAIL.GET_EMAIL_OPEN_RATE, formData)
            .then((response) => {
                var dataNew = data
                dataNew.forEach((data) => {
                    if (emailId === data.emailId) {
                        data.openPercent = response.data
                    }
                })

                setData(dataNew)
            })
            .finally((error) => {
                setEmailOpenRateLoading(false)
            })
    }

    const refreshEmailStats = (emailId) => {
        setRefreshEmailStatsLoading(true)

        var payload = {}
        payload.emailId = emailId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.EMAIL.REFRESH_EMAIL_STATS, formData)
            .then((response) => {
                var dataNew = data
                dataNew.forEach((item, index) => {
                    if (emailId === item.emailId) {
                        dataNew[index] = response.data
                        return
                    }
                })

                setData(dataNew)
            })
            .finally((error) => {
                setRefreshEmailStatsLoading(false)
            })
    }

    const getIsEmailCustomHtmlOptions = () => {
        var options = []

        options.push(
            <Radio.Button value={false} key={'No'}>
                {'No'}
            </Radio.Button>
        )

        options.push(
            <Radio.Button value={true} key={'Yes'}>
                {'Yes'}
            </Radio.Button>
        )

        return options
    }

    const onChangeIsEmailCustomHtml = (event) => {
        setIsEmailCustomHtml(event.target.value)
        setContent('')
    }

    const showEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(true)
    }

    const hideEmailCustomHtmlPreview = () => {
        setEmailCustomHtmlPreviewModalVisible(false)
    }

    return (
        <div>
            <div>
                <Button type="primary" onClick={showAddUpdateModal}>
                    Create
                </Button>
                <Radio.Group
                    onChange={onChangeFilter}
                    defaultValue={filter}
                    style={{ marginLeft: 20 }}
                >
                    <Radio.Button value={''}>All</Radio.Button>
                    <Radio.Button value={'Open'}>Open</Radio.Button>
                </Radio.Group>

                <span>
                    <Button type="link" onClick={getNotications}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                    <Link
                        href={'/email-templates'}
                        onClick={(event) => {
                            event.preventDefault()
                            window.open('/email-templates', '_blank')
                        }}
                        underline="none"
                    >
                        <Button style={{ marginLeft: 100 }}>
                            {'Email Templates'}
                            <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                style={{ marginLeft: 5 }}
                            />
                        </Button>
                    </Link>
                </span>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="emailId"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageNotifications}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure Email Notification'}
                open={addUpdateModalVisible}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                destroyOnClose={true}
                width={1000}
                onOk={addOrUpdateEmailNotification}
                okText={selectedRecord.emailId ? 'Update' : 'Add'}
            >
                <Radio.Group
                    onChange={onChangeNotificationType}
                    value={notificationType}
                >
                    <Radio.Button
                        value={NOTIFICATION_TYPE_ALL}
                        key={NOTIFICATION_TYPE_ALL}
                    >
                        {'All'}
                    </Radio.Button>
                    <Radio.Button
                        value={NOTIFICATION_TYPE_REGISTERED_USERS}
                        key={NOTIFICATION_TYPE_REGISTERED_USERS}
                    >
                        {'Course Users'}
                    </Radio.Button>
                    <Radio.Button
                        value={NOTIFICATION_TYPE_STREAM}
                        key={NOTIFICATION_TYPE_STREAM}
                    >
                        {'By Stream'}
                    </Radio.Button>
                    <Radio.Button
                        value={NOTIFICATION_TYPE_FILE_UPLOAD}
                        key={NOTIFICATION_TYPE_FILE_UPLOAD}
                    >
                        {'File Upload'}
                    </Radio.Button>
                </Radio.Group>
                {notificationType ? (
                    <Form
                        form={form}
                        layout="horizontal"
                        {...formItemLayout}
                        initialValues={{ subscribedUsersOnly: false }}
                        style={{ marginTop: 15 }}
                    >
                        <Form.Item
                            label="Template"
                            style={{ marginBottom: 5 }}
                            name="templateName"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select template"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear={true}
                                onChange={(value) =>
                                    setSelectedTemplateName(value)
                                }
                            >
                                {emailTemplateOptions()}
                            </Select>
                        </Form.Item>
                        {!selectedTemplateName ? (
                            <Form.Item
                                label="Subject"
                                style={{ marginBottom: 5 }}
                                name="subject"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input autoFocus />
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label="Schedule"
                            style={{
                                marginBottom: 5,
                                lineHeight: 0,
                            }}
                            name="scheduledTime"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <DatePicker
                                showTime
                                placeholder="Select Date &amp; Time"
                                format="DD-MMM-YY HH:mm"
                                onChange={onChangeScheduleDate}
                            />
                        </Form.Item>
                        {notificationType ===
                        NOTIFICATION_TYPE_REGISTERED_USERS ? (
                            <Form.Item
                                label="Select Courses"
                                style={{
                                    marginBottom: 5,
                                    lineHeight: 0,
                                }}
                                name="courseNames"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Select course(s)!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select course"
                                    mode="multiple"
                                >
                                    {courseNameOptions()}
                                </Select>
                            </Form.Item>
                        ) : null}
                        {notificationType ===
                        NOTIFICATION_TYPE_REGISTERED_USERS ? (
                            <Form.Item
                                label="Select Attempts"
                                style={{
                                    marginBottom: 5,
                                    lineHeight: 0,
                                }}
                                name="attemptNames"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Select attempt(s)!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select attempts"
                                    mode="multiple"
                                >
                                    {attemptOptions()}
                                </Select>
                            </Form.Item>
                        ) : null}
                        {notificationType === NOTIFICATION_TYPE_STREAM ? (
                            <Form.Item
                                label="Select stream"
                                style={{
                                    marginBottom: 5,
                                    lineHeight: 0,
                                }}
                                name="course"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select stream',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    onChange={(e) =>
                                        onChangeStream(e.target.value)
                                    }
                                >
                                    {streamOptions()}
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                        {notificationType === NOTIFICATION_TYPE_STREAM ? (
                            <Form.Item
                                label="Select level"
                                style={{
                                    marginBottom: 5,
                                    lineHeight: 0,
                                }}
                                name="level"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Radio.Group>{levelOptions()}</Radio.Group>
                            </Form.Item>
                        ) : null}
                        {notificationType === NOTIFICATION_TYPE_STREAM ? (
                            <Form.Item
                                label="Paid Active Users Only?"
                                style={{
                                    marginBottom: 5,
                                    lineHeight: 0,
                                }}
                                name="subscribedUsersOnly"
                                valuePropName="checked"
                            >
                                <Checkbox></Checkbox>
                            </Form.Item>
                        ) : null}
                        {notificationType === NOTIFICATION_TYPE_FILE_UPLOAD &&
                        !(selectedRecord && selectedRecord.emailId) ? (
                            <>
                                <Paper
                                    elevation={2}
                                    style={{ padding: 10, marginTop: 10 }}
                                >
                                    <ul>
                                        <li>
                                            {
                                                'Have only 1 sheet in the excel file'
                                            }
                                        </li>
                                        <li>
                                            {
                                                'In the first column just have the list of emails without any title in first row'
                                            }
                                            <br />
                                            {'(Do not have formulae)'}
                                        </li>
                                    </ul>
                                    <Dragger {...uploadProps}>
                                        <Button>
                                            <UploadOutlined />
                                            {
                                                'Click or Drag file to this area to add attachment'
                                            }
                                        </Button>
                                    </Dragger>
                                </Paper>
                            </>
                        ) : null}
                        {!selectedTemplateName ? (
                            <div style={{ marginTop: 10 }}>
                                <div
                                    style={{ marginTop: 15, marginBottom: 15 }}
                                >
                                    <span
                                        style={{
                                            color: 'black',
                                            marginRight: 10,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Email Body Custom Html?
                                    </span>
                                    <Radio.Group
                                        onChange={onChangeIsEmailCustomHtml}
                                        value={isEmailCustomHtml}
                                    >
                                        {getIsEmailCustomHtmlOptions()}
                                    </Radio.Group>
                                </div>
                                {isEmailCustomHtml ? (
                                    <>
                                        <Button
                                            type="primary"
                                            style={{
                                                marginBottom: 10,
                                                marginLeft: 158,
                                            }}
                                            onClick={showEmailCustomHtmlPreview}
                                        >
                                            Preview Html
                                        </Button>
                                        <Form.Item
                                            label="Custom Html"
                                            style={{ marginBottom: 5 }}
                                            name="body"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Required!',
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                rows={5}
                                                onChange={(e) =>
                                                    setContent(e.target.value)
                                                }
                                            />
                                        </Form.Item>
                                    </>
                                ) : (
                                    <>
                                        <Form.Item
                                            label="Pre Header"
                                            style={{ marginBottom: 5 }}
                                            name="emailPreHeader"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <h4>Email Body</h4>
                                        <span style={{ fontSize: 12 }}>
                                            {'User place holders:  '}
                                            {getPlaceHolders()}
                                        </span>
                                        <TinyMceEditor
                                            height={400}
                                            handleEditorChange={
                                                handleEditorChange
                                            }
                                            initialValue={content}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div style={{ marginTop: 10 }}>
                            <Button
                                onClick={sendTestEmailByForm}
                                loading={testMessageLoading}
                                type={'primary'}
                            >
                                Send Test Email
                            </Button>
                        </div>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Preview'}
                open={previewBodyModalVisible}
                onCancel={hideBodyPreview}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                {selectedRecord.preHeader ? (
                    <>
                        <h4>Pre Header</h4>
                        <p style={{ marginBottom: 20 }}>
                            {selectedRecord.preHeader}
                        </p>
                    </>
                ) : null}
                <h4>Body</h4>
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(
                        selectedRecord.body
                    )}
                />
            </Modal>
            <Modal
                title={'Custom HTML Preview'}
                open={emailCustomHtmlPreviewModalVisible}
                onCancel={hideEmailCustomHtmlPreview}
                destroyOnClose={true}
                width={1000}
                footer={null}
            >
                <Paper
                    dangerouslySetInnerHTML={createMarkupWeb(content)}
                    style={{ padding: 10 }}
                />
            </Modal>
        </div>
    )
}

export default EmailNotifications
