import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Popconfirm,
    notification,
    Radio,
    Badge,
    Form,
    Select,
    Input,
} from 'antd'
import {
    LoadingOutlined,
    DoubleRightOutlined,
    ReloadOutlined,
    EditOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt,
    faThumbtack,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { Utils } from '../JS/Utils'
import TinyMceEditor from '../Common/TinyMceEditor'
import ILAttachmentFileList from '../ILShareComponents/ILAttachmentFileList'

const FILTER_ALL = 'all'
const FILTER_COMMENTS = 'comments'
const FILTER_ACTIVITY = 'activity'

const COMMENT_FILTER_ALL = 'COMMENT_FILTER_ALL'
const COMMENT_FILTER_PINNED = 'COMMENT_FILTER_PINNED'

function TicketDetails() {
    const { id } = useParams()
    const [form] = Form.useForm()
    const { Option } = Select
    const { Search } = Input

    const [ticketLoading, setTicketLoading] = useState(false)
    const [data, setData] = useState([])
    const [filter, setFilter] = useState(FILTER_COMMENTS)
    const [comments, setComments] = useState([])
    const [pinnedComments, setPinnedComments] = useState([])
    const [loading, setLoading] = useState(false)
    const [addUpdateCommentsModalVisible, setAddUpdateCommentsModalVisible] =
        useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [pinUnpinLoading, setPinUnpinLoading] = useState(false)
    const [selectedCommentFilter, setSelectedCommentFilter] =
        useState(COMMENT_FILTER_ALL)
    const [editorComments, setEditorComments] = useState('')
    const [commentAttachmentFileList, setCommentAttachmentFileList] = useState(
        []
    )
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [employees, setEmployees] = useState([])
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [description, setDescription] = useState('')
    const [attachmentFileList, setAttachmentFileList] = useState([])
    const [ticketStatusList, setTicketStatusList] = useState([])
    const [updateStatusModalVisible, setUpdateStatusModalVisible] =
        useState(false)
    const [linkUserModalVisible, setLinkUserModal] = useState(false)
    const [searchUserLoading, setSearchUserLoading] = useState(false)
    const [searchUserData, setSearchUserData] = useState([])

    useEffect(() => {
        getTicket()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        getComments()
        //eslint-disable-next-line
    }, [filter])

    const getTicket = () => {
        setTicketLoading(true)

        RestApi.doGet(GLOBAL.URL.TICKETS.GET_TICKET_BY_ID + id)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setTicketLoading(false)
            })
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const onChangeFilter = (event) => {
        setSelectedCommentFilter(COMMENT_FILTER_ALL)
        setFilter(event.target.value)
    }

    const getComments = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.TICKETS.GET_COMMENTS + id + '&filter=' + filter
        )
            .then((res) => {
                var commentsPinned = []
                res.data.forEach((comment) => {
                    if (comment.pinned) {
                        commentsPinned.push(comment)
                    }
                })

                setComments(res.data)
                setPinnedComments(commentsPinned)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <span>
                    {record.pinned ? (
                        <FontAwesomeIcon
                            icon={faThumbtack}
                            style={{ color: '#1890ff', marginRight: 10 }}
                        />
                    ) : null}
                    {record.activityEntry ? (
                        <Badge
                            count={
                                <DoubleRightOutlined
                                    style={{ fontSize: 10, marginRight: 3 }}
                                />
                            }
                        />
                    ) : (
                        ''
                    )}
                    <span
                        dangerouslySetInnerHTML={createMarkupWeb(
                            record.comments
                        )}
                    />
                    {displayAttachments(record.attachments)}
                </span>
            ),
        },
        {
            title: 'Agent',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => pinUnpinComments(record)}
                        loading={pinUnpinLoading}
                    >
                        {record.pinned ? 'Unpin' : 'Pin'}
                    </Button>
                    {Utils.isUserAdmin() ? (
                        <>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteComments(record.uid)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{
                                        border: 'none',
                                        color: 'red',
                                        fontSize: 9,
                                        marginLeft: 5,
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                            </Popconfirm>
                            {!record.activityEntry ? (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        showAddUpdateCommentsModal(record)
                                    }}
                                    style={{ padding: 0, marginLeft: 5 }}
                                >
                                    <EditOutlined
                                        theme="twoTone"
                                        style={{ fontSize: 10 }}
                                    />
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </>
            ),
        },
    ]

    const displayAttachments = (attachments) => {
        if (!attachments) {
            return
        }

        var attachmentsArr = JSON.parse(attachments)
        var response = []

        attachmentsArr.forEach((row, index) => {
            response.push(
                <a
                    href={row}
                    style={{ marginRight: 10, textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Attachment {index + 1}
                </a>
            )
        })

        return response
    }

    const showAddUpdateCommentsModal = (record = {}) => {
        setAddUpdateCommentsModalVisible(true)
        setSelectedRecord(record)

        if (record.uid) {
            setEditorComments(record.comments)
        } else {
            setEditorComments('')
        }
    }

    const hideAddUpdateCommentsModal = () => {
        setAddUpdateCommentsModalVisible(false)
        setSelectedRecord({})
        setCommentAttachmentFileList([])
    }

    const deleteComments = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.TICKETS.DELETE_COMMENTS, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getComments()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const pinUnpinComments = (record) => {
        setPinUnpinLoading(true)

        var payload = {}
        payload.uid = record.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.TICKETS.PIN_COMMENTS
        if (record.pinned) {
            url = GLOBAL.URL.TICKETS.UNPIN_COMMENTS
        }

        RestApi.doPost(url, formData)
            .then((res) => {
                notification['success']({
                    message: res.data,
                })

                getComments()
            })
            .finally(() => {
                setPinUnpinLoading(false)
            })
    }

    const handleCommentsChange = (newValue, e) => {
        setEditorComments(newValue)
    }

    const addUpdateComments = () => {
        if (!editorComments) {
            notification['error']({
                message: 'Comments needed',
            })

            return
        }

        setLoading(true)

        var payload = {}
        payload.uid = id
        payload.comments = editorComments

        var url = GLOBAL.URL.TICKETS.ADD_COMMENTS
        if (selectedRecord.uid) {
            url = GLOBAL.URL.TICKETS.UPDATE_COMMENTS
            payload.uid = selectedRecord.uid
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        commentAttachmentFileList.forEach((file) => {
            formData.append('file', file.originFileObj)
        })

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideAddUpdateCommentsModal()
                getComments()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAssignToModal = () => {
        if (!employees.length) {
            RestApi.doGet(GLOBAL.URL.IL.GET_EMPLOYEES + 'active').then(
                (res) => {
                    setEmployees(res.data)
                }
            )
        }

        setAssignToModalVisible(true)

        setTimeout(() => {
            if (data.assignedTo) {
                form.setFieldsValue({ assignedTo: data.assignedTo.id })
            } else {
                form.resetFields()
            }
        }, 100)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const getEmployeeOptions = () => {
        var options = []
        employees.forEach((employee) => {
            options.push(
                <Option value={employee.user.id} key={employee.user.id}>
                    {employee.user.firstName + ' ' + employee.user.lastName}
                </Option>
            )
        })

        return options
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const assignTicket = () => {
        form.validateFields(['assignedTo'])
            .then((values) => {
                setLoading(true)

                values.uid = data.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.TICKETS.ASSIGN, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAssignToModal()
                        getTicket()
                        getComments()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateModal = () => {
        setUpdateModalVisible(true)

        setDescription(data.description)
        setTimeout(() => {
            form.setFieldsValue({
                title: data.title,
            })
        }, 100)
    }

    const hideUpdateModal = () => {
        setUpdateModalVisible(false)
        setAttachmentFileList([])
    }

    const handleDescriptionChange = (newValue, e) => {
        setDescription(newValue)
    }

    const updateTicket = () => {
        form.validateFields(['title'])
            .then((values) => {
                if (!description) {
                    notification['error']({
                        message: 'Description needed',
                    })
                    return
                }

                setLoading(true)

                values.uid = data.uid
                values.description = description

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                attachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(GLOBAL.URL.TICKETS.UPDATE, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateModal()
                        getTicket()
                        getComments()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateStatusModal = () => {
        if (!ticketStatusList.length) {
            RestApi.doGet(GLOBAL.URL.TICKETS.GET_STATUS_LIST).then((res) => {
                setTicketStatusList(res.data)
            })
        }

        setUpdateStatusModalVisible(true)
        setTimeout(() => {
            form.setFieldsValue({ status: data.status.keyName })
        }, 100)
    }

    const hideUpdateStatusModal = () => {
        setUpdateStatusModalVisible(false)
    }

    const updateStatus = () => {
        form.validateFields(['status'])
            .then((values) => {
                setLoading(true)

                values.uid = data.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.TICKETS.UPDATE_STATUS, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateStatusModal()
                        getTicket()
                        getComments()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getStatusFilterOptions = () => {
        var options = []

        ticketStatusList.forEach((row) => {
            options.push(
                <Radio.Button value={row.keyName} key={row.keyName}>
                    {row.value}
                </Radio.Button>
            )
        })

        return options
    }

    const deleteTicket = () => {
        setLoading(true)

        var payload = {}
        payload.uid = data.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.TICKETS.DELETE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally((error) => {
                setLoading(false)
            })
    }

    const showLinkedUserModal = () => {
        setLinkUserModal(true)
    }

    const hideLinkedUserModal = () => {
        setLinkUserModal(false)
    }

    const searchUser = (query) => {
        setSearchUserLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUserData(res.data)
            })
            .finally(() => {
                setSearchUserLoading(false)
            })
    }

    const searchUserColumns = [
        {
            title: 'Add',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectUserInSearch(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const navigateToLinkedUser = () => {
        if (data.linkedUser && data.linkedUser.id) {
            window.open('/userdetails/' + data.linkedUser.id, '_blank')
        }
    }

    const selectUserInSearch = (record) => {
        setSearchUserLoading(true)

        var payload = {}
        payload.uid = data.uid
        payload.linkedUserId = record.id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.TICKETS.UPDATE_LINKED_USER, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideLinkedUserModal()
                getTicket()
                getComments()
            })
            .finally((error) => {
                setSearchUserLoading(false)
            })
    }

    const clearUserLink = () => {
        setLoading(true)

        var payload = {}
        payload.uid = data.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.TICKETS.CLEAR_LINKED_USER, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTicket()
                getComments()
            })
            .finally((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            {ticketLoading ? (
                <LoadingOutlined style={{ fontSize: 30 }} />
            ) : (
                <>
                    <h4>
                        {'Ticket Details '}
                        <span style={{ color: 'gray', fontSize: 14 }}>
                            {'(' + data.id + ')'}
                        </span>
                    </h4>
                    <div style={{ width: '80%' }}>
                        <Button type="primary" onClick={showUpdateStatusModal}>
                            {data.status && data.status.value}
                        </Button>
                        <Paper
                            elevation={2}
                            style={{
                                padding: 10,
                                display: 'inline',
                                marginLeft: 20,
                            }}
                        >
                            <span>{'Assigned To '}</span>
                            <Button
                                type={'link'}
                                style={{ padding: 0, fontWeight: 'bold' }}
                                onClick={showAssignToModal}
                            >
                                {data.assignedTo && data.assignedTo.firstName
                                    ? data.assignedTo.firstName +
                                      ' ' +
                                      data.assignedTo.lastName
                                    : '<<NA>>'}
                            </Button>
                        </Paper>
                        <Paper
                            elevation={2}
                            style={{
                                padding: 10,
                                display: 'inline',
                                marginLeft: 20,
                            }}
                        >
                            <Button
                                type="link"
                                onClick={showLinkedUserModal}
                                style={{ padding: 0, marginRight: 5 }}
                            >
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 11 }}
                                />
                            </Button>
                            <span>{'Linked User '}</span>
                            {data.linkedUser && data.linkedUser.firstName ? (
                                <>
                                    <Button
                                        type={'link'}
                                        style={{
                                            padding: 0,
                                            fontWeight: 'bold',
                                        }}
                                        onClick={navigateToLinkedUser}
                                    >
                                        {data.linkedUser.firstName +
                                            ' ' +
                                            data.linkedUser.lastName}
                                    </Button>
                                    <Popconfirm
                                        title="Are you sure to clear linked user?"
                                        onConfirm={clearUserLink}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            color="red"
                                            style={{
                                                marginLeft: 10,
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </Popconfirm>
                                </>
                            ) : (
                                '<<NA>>'
                            )}
                        </Paper>
                        {Utils.isUserAdmin() ? (
                            <Popconfirm
                                title="Are you sure to delete?"
                                onConfirm={() => deleteTicket()}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{
                                        border: 'none',
                                        color: 'red',
                                        padding: 0,
                                        marginLeft: 20,
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        style={{ fontSize: 12 }}
                                    />
                                </Button>
                            </Popconfirm>
                        ) : null}
                    </div>
                    <div style={{ marginTop: 15 }}>
                        <Paper elevation={2} style={{ padding: 20 }}>
                            <h2 style={{ marginTop: 0 }}>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        showUpdateModal()
                                    }}
                                    style={{ padding: 0, marginRight: 5 }}
                                >
                                    <EditOutlined
                                        theme="twoTone"
                                        style={{ fontSize: 14 }}
                                    />
                                </Button>
                                {data.title}
                            </h2>
                            <p
                                dangerouslySetInnerHTML={createMarkupWeb(
                                    data.description
                                )}
                            />
                            <span>{displayAttachments(data.attachments)}</span>
                        </Paper>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Radio.Group onChange={onChangeFilter} value={filter}>
                            <Radio.Button value={FILTER_ALL}>All</Radio.Button>
                            <Radio.Button value={FILTER_COMMENTS}>
                                Comments
                            </Radio.Button>
                            <Radio.Button value={FILTER_ACTIVITY}>
                                Activity
                            </Radio.Button>
                        </Radio.Group>
                        {pinnedComments.length ? (
                            <Radio.Group
                                value={selectedCommentFilter}
                                onChange={(e) =>
                                    setSelectedCommentFilter(e.target.value)
                                }
                                style={{ marginLeft: 10 }}
                            >
                                <Radio.Button
                                    value={COMMENT_FILTER_ALL}
                                    key={COMMENT_FILTER_ALL}
                                >
                                    All
                                </Radio.Button>
                                <Radio.Button
                                    value={COMMENT_FILTER_PINNED}
                                    key={COMMENT_FILTER_PINNED}
                                >
                                    {'Pinned (' + pinnedComments.length + ')'}
                                </Radio.Button>
                            </Radio.Group>
                        ) : null}
                        <Button
                            onClick={showAddUpdateCommentsModal}
                            type={'primary'}
                            style={{ marginLeft: 20 }}
                        >
                            Add Comments
                        </Button>
                        <Button
                            type="link"
                            style={{ padding: 0, marginLeft: 10 }}
                            onClick={() => getComments()}
                        >
                            {loading ? (
                                <LoadingOutlined style={{ fontSize: 16 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 16 }} />
                            )}
                        </Button>
                    </div>
                    <Table
                        columns={tableColumns}
                        dataSource={
                            selectedCommentFilter === COMMENT_FILTER_ALL
                                ? comments
                                : pinnedComments
                        }
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="uid"
                        style={{ marginTop: 10 }}
                    />
                </>
            )}
            <Modal
                title={(selectedRecord.uid ? 'Update' : 'Add') + ' Comments'}
                open={addUpdateCommentsModalVisible}
                onOk={addUpdateComments}
                confirmLoading={loading}
                onCancel={hideAddUpdateCommentsModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={900}
                maskClosable={false}
            >
                <h4>Comments</h4>
                <TinyMceEditor
                    height={400}
                    handleEditorChange={handleCommentsChange}
                    initialValue={editorComments}
                    uploadGroupName={'tickets'}
                />
                <ILAttachmentFileList
                    attachmentFileList={commentAttachmentFileList}
                    setAttachmentFileList={setCommentAttachmentFileList}
                />
            </Modal>
            <Modal
                title={'Assign Ticket'}
                open={assignToModalVisible}
                onOk={assignTicket}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <p>{selectedRecord.title}</p>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label=""
                        name="assignedTo"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getEmployeeOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Ticket'}
                open={updateModalVisible}
                onOk={updateTicket}
                confirmLoading={loading}
                onCancel={hideUpdateModal}
                okText={'Update'}
                destroyOnClose={true}
                width={900}
                maskClosable={false}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input />
                    </Form.Item>
                    <div>
                        <h4>Description</h4>
                        <TinyMceEditor
                            height={400}
                            handleEditorChange={handleDescriptionChange}
                            initialValue={description}
                            uploadGroupName={'tickets'}
                        />
                        <ILAttachmentFileList
                            attachmentFileList={attachmentFileList}
                            setAttachmentFileList={setAttachmentFileList}
                        />
                    </div>
                </Form>
            </Modal>
            <Modal
                title={'Update Status'}
                open={updateStatusModalVisible}
                onOk={updateStatus}
                confirmLoading={loading}
                onCancel={hideUpdateStatusModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label=""
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{getStatusFilterOptions()}</Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Select User'}
                open={linkUserModalVisible}
                onCancel={hideLinkedUserModal}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    autoFocus
                />
                <Table
                    columns={searchUserColumns}
                    dataSource={searchUserData}
                    pagination={false}
                    size="small"
                    loading={searchUserLoading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default TicketDetails
