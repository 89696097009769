import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Radio, Select, message, Form } from 'antd'

const CourseEnrollmentReport = () => {
    const [loading, setLoading] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [courseNames, setCourseNames] = useState([])
    const [attempts, setAttempts] = useState([])
    const [selectedStream, setSelectedStream] = useState('')
    const [levelsData, setLevelsData] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        getStreamCourses()
        getCourseNames()
        getAttempts()
    }, [])

    const getStreamCourses = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })
    }

    const getCourseNames = () => {
        var url = GLOBAL.URL.GET_COURSE_NAMES

        RestApi.doGet(url).then((res) => {
            setCourseNames(res.data)
        })
    }

    const getAttempts = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttempts(res.data)
        })
    }

    const onChangeStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        setSelectedStream(e.target.value)
        setLevelsData(levels)
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.GENERATE_PAID_USERS_REPORT, body)
                    .then((res) => {
                        message.success(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const { Option } = Select

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    var streamOptions = []
    streamData.forEach((item) => {
        streamOptions.push(
            <Radio.Button value={item.course} key={item.course}>
                {item.course}
            </Radio.Button>
        )
    })

    var levelOptions = []
    levelsData.forEach((item) => {
        levelOptions.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    var attemptOptions = []
    attempts.forEach((attempt) => {
        attemptOptions.push(
            <Option value={attempt.attempt} key={attempt.attempt}>
                {attempt.attempt +
                    ' (' +
                    (attempt.validityString
                        ? attempt.validityString
                        : attempt.allowedDays + ' Days') +
                    ')'}
            </Option>
        )
    })

    var courseOptions = []
    courseNames.forEach((item) => {
        courseOptions.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    return (
        <div>
            <h2>Generate Course Enrollments Report</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Attempts"
                    style={{ marginBottom: 10 }}
                    name="attemptNames"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        allowClear={true}
                        mode="multiple"
                    >
                        {attemptOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Courses"
                    style={{ marginBottom: 10 }}
                    name="courseNames"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        allowClear={true}
                        mode="multiple"
                    >
                        {courseOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Select Stream"
                    style={{ marginBottom: 10 }}
                    name="stream"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeStream}>
                        {streamOptions}
                    </Radio.Group>
                </Form.Item>
                {selectedStream ? (
                    <Form.Item
                        label="Select level"
                        style={{ marginBottom: 0 }}
                        name="level"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{levelOptions}</Radio.Group>
                    </Form.Item>
                ) : null}
            </Form>
            <Button
                type="primary"
                loading={loading}
                onClick={() => generateReport()}
                style={{ marginTop: 20, marginLeft: 50 }}
            >
                Generate Report
            </Button>
        </div>
    )
}

export default CourseEnrollmentReport
