import clone from 'clone'
import GraphConstants from '../Charts/ChartConstants'

export const mapByMonth = (data, getMonthSplit) => {
    var value = []
    var label = []
    data.forEach((item) => {
        value.push(item.value)
        label.push(item.label)
    })

    var dataset = {}
    dataset.type = 'line'
    dataset.label = 'hrs'
    dataset.data = value
    dataset.borderColor = '#008ad3'
    dataset.borderWidth = 1
    dataset.backgroundColor = 'rgba(0, 138, 211, 0.4)'
    dataset.tension = 0.3

    var watchTimeData = {}
    watchTimeData.labels = label
    watchTimeData.datasets = []
    watchTimeData.datasets.push(dataset)

    var options = clone(GraphConstants.CHART_OPTIONS)
    options.onClick = (event, item) => {
        if (item.length > 0) {
            let monthLabel = label[item[0].index]
            getMonthSplit(monthLabel)
        }
    }

    return { watchTimeData, options }
}
