import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Select, Radio, DatePicker, message, Table } from 'antd'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import dayjs from 'dayjs'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const FILTER_REVENUE_MRP = 'mrp'
const FILTER_REVENUE_NET_AFTER_GST = 'net_after_gst'
const FILTER_REVENUE_BEFORE_AFTER_GST = 'net_before_gst'
const FILTER_REVENUE_DISCOUNT = 'discount'

class AllFacultyRevenueStats extends React.Component {
    state = {
        loadingCurrent: false,
        startDate: '',
        endDate: '',
        streamData: [],
        levelsData: [],
        course: '',
        level: '',
        indOrFullSubject: 'both',
        purchaseOrExtension: 'both',
        data: [],
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        courseNames: [],
        selectedCourseNames: [],
        attempts: [],
        selectedAttempts: [],
        labels: [],
        count: [],
        totalMrp: [],
        netAfterGST: [],
        netBeforeGST: [],
        discount: [],
        filterRevenue: FILTER_REVENUE_MRP,
        selectedData: null,
    }

    componentDidMount() {
        this.getStreamCourses()
        this.getCourseNames()
        this.getAttempts()
        this.getData()
    }

    getStreamCourses() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            this.setState({
                streamData: streamData,
            })
        })
    }

    getCourseNames = () => {
        var url =
            GLOBAL.URL.GET_COURSE_NAMES +
            '?course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject

        RestApi.doGet(url).then((res) => {
            this.setState({
                courseNames: res.data,
            })
        })
    }

    getAttempts = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            this.setState({
                attempts: res.data,
            })
        })
    }

    getEncodedSelectedCourseNames = () => {
        var response = ''
        this.state.selectedCourseNames.forEach((course) => {
            if (response) {
                response = response + ','
            }

            response = response + encodeURIComponent(course)
        })

        return response
    }

    getEncodedSelectedAttemptNames = () => {
        var response = ''
        this.state.selectedAttempts.forEach((attempt) => {
            if (response) {
                response = response + ','
            }

            response = response + encodeURIComponent(attempt)
        })

        return response
    }

    getData = () => {
        this.setState({
            loadingCurrent: true,
            selectedData: null,
        })
        var url =
            GLOBAL.URL.GET_ALL_FACULTY_SALES +
            '?course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject +
            '&courseNames=' +
            this.getEncodedSelectedCourseNames() +
            '&attemptNames=' +
            this.getEncodedSelectedAttemptNames() +
            '&purchaseOrExtension=' +
            this.state.purchaseOrExtension +
            '&startDate=' +
            this.state.startDate +
            '&endDate=' +
            this.state.endDate

        RestApi.doGet(url)
            .then((res) => {
                if (res.data.length === 0) {
                    message.info('No data to display!')
                }

                var labels = []
                var count = []
                var totalMrp = []
                var netAfterGST = []
                var netBeforeGST = []
                var discount = []

                res.data.forEach((row) => {
                    labels.push(row.label)
                    count.push(row.count)
                    totalMrp.push(row.totalMrp)
                    netAfterGST.push(row.netAfterGST)
                    netBeforeGST.push(row.netBeforeGST)
                    discount.push(row.discount)
                })

                this.setState(
                    {
                        labels: labels,
                        count: count,
                        totalMrp: totalMrp,
                        netAfterGST: netAfterGST,
                        netBeforeGST: netBeforeGST,
                        discount: discount,
                        data: res.data,
                    },
                    () => {
                        this.drawChart()
                    }
                )
            })
            .finally(() => {
                this.setState({
                    loadingCurrent: false,
                })
            })
    }

    drawChart() {
        var data = []
        var dataLabel = ''
        if (this.state.filterRevenue === FILTER_REVENUE_MRP) {
            dataLabel = 'Revenue'
            data = this.state.totalMrp
        } else if (this.state.filterRevenue === FILTER_REVENUE_NET_AFTER_GST) {
            dataLabel = 'Net After GST'
            data = this.state.netAfterGST
        } else if (
            this.state.filterRevenue === FILTER_REVENUE_BEFORE_AFTER_GST
        ) {
            dataLabel = 'Net Before GST'
            data = this.state.netBeforeGST
        } else if (this.state.filterRevenue === FILTER_REVENUE_DISCOUNT) {
            dataLabel = 'Discount'
            data = this.state.discount
        }

        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = data
        revenueDataSet.label = dataLabel
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.datalabels = { display: true }

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = this.state.count
        countDataSet.label = 'Count'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'
        countDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = this.state.labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'Revenue'
        options.onClick = (evt, item) => {
            this.showCoursesSplit(item)
        }

        this.setState({
            mapData: mapData,
            options: options,
        })
    }

    showCoursesSplit = (item) => {
        var index = item[0].index
        this.setState({ selectedData: this.state.data[index] })

        setTimeout(() => {
            document
                .getElementById('courseSplit')
                .scrollIntoView({ behavior: 'smooth', block: 'start' })
        }, 100)
    }

    onChangeStream = (e) => {
        var levels = []
        this.state.streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        this.setState(
            {
                course: e.target.value,
                levelsData: levels,
                level: '',
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeLevel = (e) => {
        this.setState(
            {
                level: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeIndOrFullSubject = (e) => {
        this.setState(
            {
                indOrFullSubject: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangePurchaseOrExtension = (e) => {
        this.setState({
            purchaseOrExtension: e.target.value,
        })
    }

    onChangeCourseName = (value) => {
        this.setState({ selectedCourseNames: value })
    }

    onClearCourseName = () => {
        this.setState({ selectedCourseNames: [] })
    }

    onChangeAttemptsName = (value) => {
        this.setState({ selectedAttempts: value })
    }

    onClearAttempts = () => {
        this.setState({ selectedAttempts: [] })
    }

    getCurrentMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month, 1)

        var range = [dayjs(firstDay), dayjs(date)]

        return range
    }

    getLastMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month - 1, 1)

        var lastDay = new Date()
        lastDay.setFullYear(year, month, 0)

        var range = [dayjs(firstDay), dayjs(lastDay)]

        return range
    }

    onDateChange = (value, dateString) => {
        this.setState({
            startDate: dateString[0],
            endDate: dateString[1],
        })
    }

    handleRevenueFilterChange = (event) => {
        this.setState({ filterRevenue: event.target.value }, () => {
            this.drawChart()
        })
    }

    render() {
        const { Option } = Select
        const { RangePicker } = DatePicker

        var streamOptions = []
        this.state.streamData.forEach((item) => {
            streamOptions.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        streamOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var levelOptions = []
        this.state.levelsData.forEach((item) => {
            levelOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        levelOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var courseOptions = []
        this.state.courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        var attemptOptions = []
        this.state.attempts.forEach((attempt) => {
            attemptOptions.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt +
                        ' (' +
                        (attempt.validityString
                            ? attempt.validityString
                            : attempt.allowedDays + ' Days') +
                        ')'}
                </Option>
            )
        })

        const coursesSplitTableColumns = [
            {
                title: '#',
                dataIndex: 'courseId',
                key: 'courseId',
                render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
                title: 'Count',
                dataIndex: 'count',
                key: 'count',
            },
            {
                title: 'MRP',
                dataIndex: 'totalMrp',
                key: 'totalMrp',
            },
            {
                title: 'Course Name',
                dataIndex: 'label',
                key: 'label',
            },
        ]

        return (
            <div>
                <h3>All Faculty Revenue Comparison</h3>
                <div style={{ marginTop: 10 }}>
                    {this.state.streamData.length > 0 ? (
                        <div>
                            <Radio.Group onChange={this.onChangeStream}>
                                {streamOptions}
                            </Radio.Group>
                            {this.state.course ? (
                                <Radio.Group
                                    onChange={this.onChangeLevel}
                                    value={this.state.level}
                                    style={{ marginLeft: 10 }}
                                >
                                    {levelOptions}
                                </Radio.Group>
                            ) : null}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 10, float: 'left' }}>
                        <Radio.Group
                            onChange={this.onChangeIndOrFullSubject}
                            value={this.state.indOrFullSubject}
                        >
                            <Radio.Button value="both" key={'both'}>
                                {'Both'}
                            </Radio.Button>
                            <Radio.Button value="full" key={'full'}>
                                {'Full Subjects'}
                            </Radio.Button>
                            <Radio.Button value="ind" key={'ind'}>
                                {'Individual Modules'}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 600 }}
                        allowClear={true}
                        onChange={this.onChangeCourseName}
                        onClear={this.onClearCourseName}
                        mode="multiple"
                    >
                        {courseOptions}
                    </Select>
                </div>
                <div>
                    <Radio.Group
                        onChange={this.onChangePurchaseOrExtension}
                        value={this.state.purchaseOrExtension}
                    >
                        <Radio.Button value="both" key={'both'}>
                            {'Both'}
                        </Radio.Button>
                        <Radio.Button value="purchase" key={'purchase'}>
                            {'Purchase'}
                        </Radio.Button>
                        <Radio.Button value="extension" key={'extension'}>
                            {'Extension'}
                        </Radio.Button>
                    </Radio.Group>
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 600 }}
                        allowClear={true}
                        onChange={this.onChangeAttemptsName}
                        onClear={this.onClearAttempts}
                        mode="multiple"
                    >
                        {attemptOptions}
                    </Select>
                </div>
                <div style={{ marginTop: 20 }}>
                    <span>Date range: </span>
                    <RangePicker
                        format="DD-MMM-YY"
                        placeholder={['Start Date', 'End Date']}
                        onChange={this.onDateChange}
                        style={{ marginLeft: 10 }}
                        defaultValue={this.getCurrentMonthRange()}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.getData(true)}
                        loading={this.state.loadingCurrent}
                        style={{ marginLeft: 10 }}
                    >
                        Fetch
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    {!this.state.loadingCurrent ? (
                        <Radio.Group
                            onChange={(event) =>
                                this.handleRevenueFilterChange(event)
                            }
                            defaultValue={this.state.filterRevenue}
                        >
                            <Radio.Button value={FILTER_REVENUE_MRP}>
                                Gross
                            </Radio.Button>
                            <Radio.Button value={FILTER_REVENUE_NET_AFTER_GST}>
                                Net After GST
                            </Radio.Button>
                            <Radio.Button
                                value={FILTER_REVENUE_BEFORE_AFTER_GST}
                            >
                                Net Before GST
                            </Radio.Button>
                            <Radio.Button value={FILTER_REVENUE_DISCOUNT}>
                                Discount
                            </Radio.Button>
                        </Radio.Group>
                    ) : null}
                </div>
                <div>
                    <Bar
                        data={this.state.mapData}
                        width={800}
                        height={400}
                        options={this.state.options}
                    />
                </div>
                {this.state.selectedData ? (
                    <div id="courseSplit">
                        <h3>Courses Split - {this.state.selectedData.label}</h3>
                        <Table
                            columns={coursesSplitTableColumns}
                            dataSource={this.state.selectedData.coursesSplit}
                            pagination={false}
                            size="small"
                            rowKey="label"
                            style={{ marginTop: 10 }}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default AllFacultyRevenueStats
