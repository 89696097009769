import React, { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Button,
    Col,
    Input,
    Modal,
    Radio,
    message,
    notification,
    Checkbox,
    Form,
} from 'antd'
import { EditTwoTone, CopyOutlined } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Link } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import OrphanModules from './SubComponents/OrphanModules'
import UpdateCoursePrices from './SubComponents/UpdateCoursePrices'

var SEARCH_TYPE_COURSE = 'Course'
var SEARCH_TYPE_MODULE = 'Module'
var SEARCH_TYPE_SECTION = 'Section'
var CONTENT_TYPE_RESOURCE = 'resource'

var COURSE_FILTER_ALL = 'All'
var COURSE_FILTER_ARCHIVED = 'Archived'
var COURSE_FILTER_UNARCHIVED = 'Unarchived'

function Courses({ navigate }) {
    const { Search } = Input
    const [form] = Form.useForm()

    const [courseData, setCourseData] = useState([])
    const [loading, setLoading] = useState(false)
    const [addCourseModalVisible, setAddCourseModalVisible] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [searchType, setSearchType] = useState(SEARCH_TYPE_COURSE)
    const [sections, setSections] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [sectionQueryPage, setSectionQueryPage] = useState(1)
    const [modules, setModules] = useState([])
    const [moduleQueryPage, setModuleQueryPage] = useState(1)
    const [showMoreModulesButton, setShowMoreModulesButton] = useState(false)
    const [copyCourseModalVisible, setCopyCourseModalVisible] = useState(false)
    const [copyCoursesSearchData, setCopyCoursesSearchData] = useState([])
    const [copyCourseSearchQuery, setCopyCourseSearchQuery] = useState('')
    const [copyNewCourseName, setCopyNewCourseName] = useState('')
    const [selectedCourseSearchFilter, setSelectedCourseSearchFilter] =
        useState(COURSE_FILTER_UNARCHIVED)
    const [orphanModulesModaVisible, setOrphanModulesModaVisible] =
        useState(false)
    const [updateCoursePricesModalVisible, setUpdateCoursePricesModalVisible] =
        useState(false)

    useEffect(() => {
        if (searchType === SEARCH_TYPE_MODULE) {
            if (moduleQueryPage === 1) {
                return
            }
        } else if (searchType === SEARCH_TYPE_SECTION) {
            if (sectionQueryPage === 1) {
                return
            }
        }

        search(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleQueryPage, sectionQueryPage])

    const search = (isShowMore = false) => {
        var url = ''
        if (searchType === SEARCH_TYPE_COURSE) {
            if (copyCourseModalVisible) {
                if (!copyCourseSearchQuery) {
                    return
                }
            } else {
                if (!searchQuery) {
                    return
                }
            }

            url =
                GLOBAL.URL.GET_ALL_COURSES +
                '?query=' +
                (copyCourseModalVisible
                    ? copyCourseSearchQuery
                    : searchQuery + '&filter=' + selectedCourseSearchFilter)
        } else if (searchType === SEARCH_TYPE_MODULE) {
            url =
                GLOBAL.URL.SEARCH_MODULE +
                searchQuery +
                '&page=' +
                moduleQueryPage
        } else {
            url =
                GLOBAL.URL.SEARCH_BY_SECTION_NAME +
                searchQuery +
                '&page=' +
                sectionQueryPage
        }

        setLoading(true)

        RestApi.doGet(url)
            .then((res) => {
                if (searchType === SEARCH_TYPE_COURSE) {
                    if (copyCourseModalVisible) {
                        setCopyCoursesSearchData(res.data)
                    } else {
                        setCourseData(res.data)
                    }
                } else if (searchType === SEARCH_TYPE_MODULE) {
                    if (!isShowMore) {
                        setModules(res.data)
                    } else {
                        setModules((preState) => {
                            return [...preState, ...res.data]
                        })
                    }

                    setShowMoreModulesButton(res.data.length === 10)
                } else {
                    if (!isShowMore) {
                        setSections(res.data)
                    } else {
                        setSections((preState) => {
                            return [...preState, ...res.data]
                        })
                    }
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddCourseModal = () => {
        setAddCourseModalVisible(true)

        if (streamData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
                setLevelData(streamData[0].levels)
            })
        }
    }

    const hideAddCourseModal = () => {
        setAddCourseModalVisible(false)
    }

    const handleStreamChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                return
            }
        })
    }

    const addCourse = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.ADD_COURSE, formData)
                    .then((res) => {
                        navigate('/course/configure/' + res.data.courseId)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const onChangeSearchType = (event) => {
        setSearchType(event.target.value)
    }

    const onChangeSearchCourseFilterType = (event) => {
        setSelectedCourseSearchFilter(event.target.value)
    }

    const showCopyCourseModal = () => {
        setCopyCourseModalVisible(true)
        setCopyCoursesSearchData([])
        setSearchType(SEARCH_TYPE_COURSE)
        setCopyNewCourseName('')
    }

    const hideCopyCourseModal = () => {
        setCopyCourseModalVisible(false)
    }

    const copyCourse = (courseId) => {
        if (!copyNewCourseName) {
            message.error('New course name needed!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.courseIdToCopy = courseId
        payload.newCourseName = copyNewCourseName

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COPY_COURSE, formData)
            .then((res) => {
                navigate('/course/configure/' + res.data.courseId)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showOrphanModules = () => {
        setOrphanModulesModaVisible(true)
    }

    const hideOrphanModules = () => {
        setOrphanModulesModaVisible(false)
    }

    const showUpdateCoursePricesModal = () => {
        setUpdateCoursePricesModalVisible(true)
    }

    const hideUpdateCoursePricesModal = () => {
        setUpdateCoursePricesModalVisible(false)
    }

    const columns = [
        {
            title: 'Edit',
            key: 'action',
            width: '50px',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/course/configure/' + record.courseId}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate('/course/configure/' + record.courseId, {
                                state: { record: record },
                            })
                        }}
                        underline="none"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <EditTwoTone style={{ fontSize: 14 }} />
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/modules/' + record.courseId}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate('/modules/' + record.courseId, {
                                state: { courseId: record.courseId },
                            })
                        }}
                        underline="none"
                    >
                        {record.name}
                    </Link>
                </span>
            ),
        },
        {
            title: 'Deep Link',
            dataIndex: 'deepLink',
            key: 'deepLink',
            render: (text, record) => (
                <span>
                    <CopyToClipboard
                        text={record.deepLink}
                        onCopy={() =>
                            notification['success']({
                                message: 'Link copied!',
                            })
                        }
                    >
                        <span>
                            <Button type="link" style={{ padding: 0 }}>
                                <CopyOutlined />
                                {' Deep Link'}
                            </Button>
                        </span>
                    </CopyToClipboard>
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'archived',
            key: 'archived',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.archived ? 'Archived' : 'Active'}
                </span>
            ),
        },
    ]

    const moduleColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Module Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Linked Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => (
                <>
                    {record.courses.map((course, index) => {
                        return (
                            <span key={course.name}>
                                {index !== 0 ? (
                                    <hr
                                        style={{
                                            width: '50%',
                                            marginLeft: 0,
                                        }}
                                    />
                                ) : null}
                                <Link
                                    href={'/modulesections/' + record.id}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        navigate(
                                            '/modulesections/' + record.id,
                                            {
                                                state: {
                                                    courseName: course.name,
                                                    courseId: course.courseId,
                                                    moduleName: record.name,
                                                },
                                            }
                                        )
                                    }}
                                    underline="none"
                                >
                                    {course.name}
                                </Link>
                            </span>
                        )
                    })}
                </>
            ),
        },
    ]

    const sectionColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Section Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Module Name',
            dataIndex: 'moduleName',
            key: 'moduleName',
        },
        {
            title: 'Linked Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => (
                <>
                    {record.courses.map((course, index) => {
                        return (
                            <span key={course.name}>
                                {index !== 0 ? (
                                    <hr
                                        style={{
                                            width: '50%',
                                            marginLeft: 0,
                                        }}
                                    />
                                ) : null}
                                <Link
                                    href={'/modulesections/' + record.moduleId}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        navigate(
                                            '/modulesections/' +
                                                record.moduleId,
                                            {
                                                state: {
                                                    courseName: course.name,
                                                    courseId: course.courseId,
                                                    moduleName:
                                                        record.moduleName,
                                                },
                                            }
                                        )
                                    }}
                                    underline="none"
                                >
                                    {course.name}
                                </Link>
                            </span>
                        )
                    })}
                </>
            ),
        },
        {
            title: 'Content Type',
            dataIndex: 'contentType',
            key: 'contentType',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.contentType === 'video'
                        ? record.video.duration
                        : record.durationString}
                </span>
            ),
        },
        {
            title: 'DATA',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <span>
                    {record.contentType === 'video' ? (
                        <span>{record.video.title}</span>
                    ) : record.contentType === CONTENT_TYPE_RESOURCE ? (
                        record.resource.name + ' (' + record.resource.type + ')'
                    ) : (
                        <a
                            href={record.data}
                            style={{ display: 'table-cell' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {record.data}
                        </a>
                    )}
                </span>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const streamOptions = () => {
        var options = []

        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const copyCourseColumns = [
        {
            title: 'Copy',
            dataIndex: 'copy',
            key: 'copy',
            width: 100,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => copyCourse(record.courseId)}
                >
                    Copy
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    return (
        <div>
            <Row style={{ marginBottom: 8 }}>
                <Col span={12}>
                    <h2>Courses</h2>
                </Col>

                <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => showUpdateCoursePricesModal()}
                    >
                        Update Prices
                    </Button>
                    <Button
                        type="default"
                        size="small"
                        onClick={showOrphanModules}
                        style={{ marginLeft: 10 }}
                    >
                        Orphan Modules
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddCourseModal}
                        style={{ marginLeft: 10 }}
                    >
                        Add Course
                    </Button>
                    <Button
                        type="default"
                        size="small"
                        onClick={showCopyCourseModal}
                        style={{ marginLeft: 10 }}
                    >
                        Copy Course
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <Search
                        placeholder={
                            'Search by ' +
                            (searchType === SEARCH_TYPE_COURSE
                                ? 'course'
                                : searchType === SEARCH_TYPE_SECTION
                                ? 'section'
                                : 'module') +
                            ' name'
                        }
                        onSearch={search}
                        enterButton
                        allowClear={true}
                        width={400}
                        loading={loading}
                        autoFocus
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Col>
                <Col span={10}>
                    <Radio.Group
                        onChange={onChangeSearchType}
                        value={searchType}
                        style={{ marginLeft: 10 }}
                    >
                        <Radio.Button
                            value={SEARCH_TYPE_COURSE}
                            key={SEARCH_TYPE_COURSE}
                        >
                            {SEARCH_TYPE_COURSE}
                        </Radio.Button>
                        <Radio.Button
                            value={SEARCH_TYPE_SECTION}
                            key={SEARCH_TYPE_SECTION}
                        >
                            {SEARCH_TYPE_SECTION}
                        </Radio.Button>
                        <Radio.Button
                            value={SEARCH_TYPE_MODULE}
                            key={SEARCH_TYPE_MODULE}
                        >
                            {SEARCH_TYPE_MODULE}
                        </Radio.Button>
                    </Radio.Group>
                    {searchType === SEARCH_TYPE_COURSE ? (
                        <Radio.Group
                            onChange={onChangeSearchCourseFilterType}
                            value={selectedCourseSearchFilter}
                            style={{ marginLeft: 10 }}
                        >
                            <Radio.Button
                                value={COURSE_FILTER_UNARCHIVED}
                                key={COURSE_FILTER_UNARCHIVED}
                            >
                                {'Active'}
                            </Radio.Button>
                            <Radio.Button
                                value={COURSE_FILTER_ARCHIVED}
                                key={COURSE_FILTER_ARCHIVED}
                            >
                                {COURSE_FILTER_ARCHIVED}
                            </Radio.Button>
                            <Radio.Button
                                value={COURSE_FILTER_ALL}
                                key={COURSE_FILTER_ALL}
                            >
                                {COURSE_FILTER_ALL}
                            </Radio.Button>
                        </Radio.Group>
                    ) : null}
                </Col>
            </Row>
            {searchType === SEARCH_TYPE_COURSE ? (
                <Table
                    columns={columns}
                    dataSource={courseData}
                    style={{ marginTop: 10 }}
                    loading={loading}
                    pagination={false}
                    rowKey="name"
                    size="small"
                />
            ) : searchType === SEARCH_TYPE_MODULE ? (
                <span>
                    <Table
                        columns={moduleColumns}
                        dataSource={modules}
                        style={{ marginTop: 10 }}
                        loading={loading}
                        pagination={false}
                        rowKey="id"
                        size="small"
                    />
                    {showMoreModulesButton ? (
                        <Button
                            type="primary"
                            size="small"
                            style={{ marginTop: 10 }}
                            onClick={() =>
                                setModuleQueryPage(moduleQueryPage + 1)
                            }
                        >
                            Show More
                        </Button>
                    ) : null}
                </span>
            ) : (
                <span>
                    <Table
                        columns={sectionColumns}
                        dataSource={sections}
                        style={{ marginTop: 10 }}
                        loading={loading}
                        pagination={false}
                        rowKey="id"
                        size="small"
                    />
                    {showMoreModulesButton ? (
                        <Button
                            type="primary"
                            size="small"
                            style={{ marginTop: 10 }}
                            onClick={() =>
                                setSectionQueryPage(sectionQueryPage + 1)
                            }
                        >
                            Show More
                        </Button>
                    ) : null}
                </span>
            )}
            <Modal
                title="Add Course"
                open={addCourseModalVisible}
                onOk={addCourse}
                confirmLoading={loading}
                onCancel={hideAddCourseModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    initialValues={{ bookOnlyCourse: false }}
                >
                    <Form.Item
                        label="Course Name"
                        style={{ marginBottom: 0 }}
                        name="courseName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus placeholder="Enter name" />
                    </Form.Item>
                    <Form.Item
                        label="Books Only?"
                        style={{ marginBottom: 0 }}
                        name="bookOnlyCourse"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item
                        label="Stream"
                        style={{ marginBottom: 0 }}
                        name="course"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleStreamChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Level"
                        style={{ marginBottom: 0 }}
                        name="level"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{levelOptions()}</Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Copy Course"
                open={copyCourseModalVisible}
                onCancel={hideCopyCourseModal}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                <Search
                    placeholder={'Search course to copy'}
                    onSearch={search}
                    enterButton
                    loading={loading}
                    autoFocus
                    onChange={(e) => setCopyCourseSearchQuery(e.target.value)}
                />
                <Input
                    placeholder="Enter new course name"
                    style={{ marginTop: 10 }}
                    onChange={(e) => setCopyNewCourseName(e.target.value)}
                />
                <Table
                    columns={copyCourseColumns}
                    dataSource={copyCoursesSearchData}
                    style={{ marginTop: 10 }}
                    loading={loading}
                    pagination={false}
                    rowKey="name"
                    size="small"
                />
            </Modal>
            <Modal
                title="Orphan Modules"
                open={orphanModulesModaVisible}
                onCancel={hideOrphanModules}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                <OrphanModules />
            </Modal>
            <Modal
                title="Update Course Prices"
                open={updateCoursePricesModalVisible}
                onCancel={() => hideUpdateCoursePricesModal()}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                <UpdateCoursePrices />
            </Modal>
        </div>
    )
}

export default Courses
