import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import CartLeads from './CartLeads'
import ExternalLeads from './ExternalLeads'
import SignUpLeads from './SignUpLeads'
import HighProspectLeads from './HighProspectLeads'
import RingingCalls from '../Sales/RingingCalls'
import CloudCalls from '../Leads/CloudCalls'
import Export from '../Sales/ExportLeads'
import FollowupLeadsCountSplitByDay from '../Leads/FollowupLeadsCountSplitByDay'
import LeadsNavigation from './LeadsNavigation'

const LEAD_TYPE_CART = 'LEAD_TYPE_CART'
const LEAD_TYPE_SIGNUP = 'LEAD_TYPE_SIGNUP'
const LEAD_TYPE_EXTERNAL = 'LEAD_TYPE_EXTERNAL'
const LEAD_TYPE_HIGH_PROSPECTS = 'LEAD_TYPE_HIGH_PROSPECTS'
const CLOUD_CALLS = 'CLOUD_CALLS'

function Leads({ navigate, location }) {
    const [selectedLeadType, setSelectedLeadType] = useState(
        getSelectedLeadType()
    )
    const isLeadRoute = [
        '/leads/internal',
        '/leads/signup',
        '/leads/external',
    ].includes(location.pathname)

    useEffect(() => {
        setSelectedLeadType(getSelectedLeadType())
        // eslint-disable-next-line
    }, [location.pathname])

    function getSelectedLeadType() {
        const path = location.pathname

        switch (path) {
            case '/leads/internal':
                return LEAD_TYPE_CART
            case '/leads/signup':
                return LEAD_TYPE_SIGNUP
            case '/leads/external':
                return LEAD_TYPE_EXTERNAL
            case '/leads/high-prospect':
                return LEAD_TYPE_HIGH_PROSPECTS
            case '/leads/cloud-calls':
                return CLOUD_CALLS
            default:
                return LEAD_TYPE_CART
        }
    }

    const onChangeLeadType = (event) => {
        const value = event.target.value
        setSelectedLeadType(value)

        switch (value) {
            case LEAD_TYPE_CART:
                navigate('/leads/internal')
                break
            case LEAD_TYPE_SIGNUP:
                navigate('/leads/signup')
                break
            case LEAD_TYPE_EXTERNAL:
                navigate('/leads/external')
                break
            case LEAD_TYPE_HIGH_PROSPECTS:
                navigate('/leads/high-prospect')
                break
            case CLOUD_CALLS:
                navigate('/leads/cloud-calls')
                break
            default:
                navigate('/leads/internal')
                break
        }
    }

    const getView = () => {
        switch (selectedLeadType) {
            case LEAD_TYPE_SIGNUP:
                return <SignUpLeads />
            case LEAD_TYPE_EXTERNAL:
                return <ExternalLeads />
            case LEAD_TYPE_HIGH_PROSPECTS:
                return <HighProspectLeads />
            case CLOUD_CALLS:
                return <CloudCalls />
            default:
                return <CartLeads />
        }
    }

    return (
        <div>
            <div>
                <Radio.Group
                    onChange={onChangeLeadType}
                    value={selectedLeadType}
                    style={{ marginBottom: 10 }}
                >
                    <Radio.Button value={LEAD_TYPE_CART}>Internal</Radio.Button>
                    <Radio.Button value={LEAD_TYPE_SIGNUP}>
                        Sign Up
                    </Radio.Button>
                    <Radio.Button value={LEAD_TYPE_EXTERNAL}>
                        External
                    </Radio.Button>
                    <Radio.Button value={LEAD_TYPE_HIGH_PROSPECTS}>
                        High Propspect
                    </Radio.Button>
                    <Radio.Button value={CLOUD_CALLS}>Cloud Calls</Radio.Button>
                </Radio.Group>
                <RingingCalls />
                <Export />
                <FollowupLeadsCountSplitByDay />
            </div>
            {isLeadRoute && <LeadsNavigation />}
            <div>{getView()}</div>
        </div>
    )
}

export default Leads
