export const SITE_SEARCH_TABS = {
    ALL: 'ALL',
    FORUM: 'FORUM',
    FREE_RESOURCES: 'FREE_RESOURCES',
    NEWS: 'NEWS',
    BLOGS: 'BLOGS',
}

export const searchFilters = [
    { label: 'All', value: SITE_SEARCH_TABS.ALL },
    { label: 'Forums', value: SITE_SEARCH_TABS.FORUM },
    { label: 'Free Resources', value: SITE_SEARCH_TABS.FREE_RESOURCES },
    { label: 'News', value: SITE_SEARCH_TABS.NEWS },
    { label: 'Blogs', value: SITE_SEARCH_TABS.BLOGS },
]
