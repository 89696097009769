import clone from 'clone'
import GraphConstants from '../Charts/ChartConstants'

export const mapCourseCompletionStats = (data) => {
    var label = []
    var dataSets = []
    var expectedCompletion = []
    var actualCompletion = []

    data.forEach((dataRow) => {
        label.push(dataRow.label)
        actualCompletion.push(dataRow.actualCompletion)
        expectedCompletion.push(dataRow.expectedCompletion)
    })

    dataSets = [
        {
            data: actualCompletion,
            label: 'Actual Completion',
            borderColor: '#4473c5',
            backgroundColor: '#cedbf2',
            fill: true,
            borderWidth: 1.2,
            tension: 0.3,
        },
        {
            data: expectedCompletion,
            label: 'Expected Completion',
            borderColor: '#e97c31',
            backgroundColor: '#fcede2',
            fill: true,
            borderWidth: 1.2,
            hideDataPoints: true,
            tension: 0.3,
        },
    ]

    var courseCompletionStats = {}
    courseCompletionStats.labels = label
    courseCompletionStats.datasets = dataSets
    var options = clone(GraphConstants.CHART_OPTIONS)

    return { courseCompletionStats, options }
}
