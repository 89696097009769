import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, InputNumber, message } from 'antd'

function UsersStreamSplitWeekWise() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [dateEnding, setDateEnding] = useState()
    const [noOfWeeks, setNoOfWeeks] = useState(8)

    const onChangeEndDate = (value, dateString) => {
        setDateEnding(dateString)
    }

    const onChangeNoOfWeeks = (value) => {
        setNoOfWeeks(value)
    }

    const fetchData = () => {
        if (!dateEnding) {
            message.error('Select the end date!')
            return
        }

        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_USERS_BY_STREAM_SPLIT_WEEK_WISE +
                '?dateEnding=' +
                dateEnding +
                '&noOfWeeks=' +
                noOfWeeks
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    return (
        <>
            <h2>Users Stream Split By Week</h2>
            <div>
                <span>Select End Date </span>
                <DatePicker format="DD-MMM-YYYY" onChange={onChangeEndDate} />
                <span style={{ marginLeft: 20 }}># Weeks: </span>
                <InputNumber
                    min={1}
                    defaultValue={noOfWeeks}
                    value={noOfWeeks}
                    onChange={onChangeNoOfWeeks}
                />
                <Button
                    loading={loading}
                    type="primary"
                    onClick={fetchData}
                    style={{ marginLeft: 20 }}
                >
                    Fetch
                </Button>
            </div>
            <div
                dangerouslySetInnerHTML={createMarkupWeb(data)}
                style={{ marginTop: 20 }}
            />
        </>
    )
}

export default UsersStreamSplitWeekWise
