import React from 'react'
import { searchStyles } from './styles'
import {
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    Box,
} from '@mui/material'

const SearchFilters = ({
    searchFilters,
    handleSearchFilterChange,
    selectedSearchFilter,
}) => {
    const { classes, cx } = searchStyles()
    return (
        <Box className={classes.filtersSection}>
            <Typography
                className={cx(classes.filtersTxt, classes.headerFilterTxt)}
            >
                FILTERS
            </Typography>
            <RadioGroup
                row
                name="search-filters-options"
                value={selectedSearchFilter}
                className={classes.filtersContainer}
                onChange={handleSearchFilterChange}
            >
                {searchFilters.map((item, i) => (
                    <FormControlLabel
                        value={item.value}
                        key={i}
                        control={<Radio size="small" />}
                        label={item.label}
                        className={classes.filterOption}
                    />
                ))}
            </RadioGroup>
        </Box>
    )
}

export default SearchFilters
