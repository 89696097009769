import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Select,
    Popconfirm,
    Input,
    notification,
} from 'antd'
import { CopyOutlined, EditTwoTone } from '@ant-design/icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faTimes,
    faTrashAlt,
    faCog,
} from '@fortawesome/free-solid-svg-icons'
import QrCode from '../Misc/QrCode'
import { goBack } from '../../Util/ILUtil'
import { Link } from '@mui/material'

const CourseCatalog = ({ location, navigate }) => {
    const { Option } = Select

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [catalogId, setCatalogId] = useState('')
    const [editSubgGroupNameModalVisible, setEditSubgGroupNameModalVisible] =
        useState(false)
    const [editButtonTextModalVisible, setEditButtonTextModalVisible] =
        useState(false)
    const [record, setRecord] = useState({})
    const [updatedSubGrupName, setUpdatedSubGrupName] = useState('')
    const [updatedButtonText, setUpdatedButtonText] = useState('')
    const [imageSrc, setImageSrc] = useState()
    const [showImageModal, setShowImageModal] = useState(false)
    const [groupModalVisible, setGroupModalVisible] = useState(false)
    const [groupData, setGroupData] = useState([])
    const [selectedGroup, setSelectedGroup] = useState()
    const [courseModalVisible, setCourseModalVisible] = useState(false)
    const [courseData, setCourseData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState()
    const [qrCodeValue, setQrCodeValue] = useState()
    const [qrCodeVisible, setQrCodeVisible] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        setTimeout(() => {
            if (
                (editSubgGroupNameModalVisible || editButtonTextModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [editSubgGroupNameModalVisible, editButtonTextModalVisible, inputRef])

    const fetchData = () => {
        setLoading(true)

        var url = GLOBAL.URL.GET_CATLOG

        const { pathname = '' } = location
        const pathSplit = pathname.split('/')
        if (pathSplit.length > 2) {
            setCatalogId(pathSplit[2])
            url = url + '/' + pathSplit[2]
        }

        RestApi.doGet(url)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getGroupData = (record) => {
        navigate('/coursecatalog/' + record.catalogId + '/' + record.urlName)
    }

    const showEditSubgGroupNameModal = (record) => {
        setEditSubgGroupNameModalVisible(true)
        setRecord(record)
        setUpdatedSubGrupName(record.subGroupName)
    }

    const hideEditSubGroupNameModal = () => {
        setEditSubgGroupNameModalVisible(false)
    }

    const updateSubGroupName = () => {
        setLoading(true)

        var payload = {}
        payload.id = record.id
        payload.subGroupName = updatedSubGrupName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(
            GLOBAL.URL.UPDATE_COURSE_CATALOG_SUB_GROUP_NAME,
            formbody
        )
            .then((response) => {
                hideEditSubGroupNameModal()

                var successObject = data
                successObject.forEach((row) => {
                    if (record.id === row.id) {
                        row.subGroupName = updatedSubGrupName
                        return
                    }
                })

                setData(successObject)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showEditButtonTextNameModal = (record) => {
        setEditButtonTextModalVisible(true)
        setRecord(record)
        setUpdatedButtonText(record.groupButtonCustomName)
    }

    const hideEditButtonTextNameModal = () => {
        setEditButtonTextModalVisible(false)
    }

    const updateButtonText = () => {
        setLoading(true)

        var payload = {}
        payload.id = record.id
        payload.groupButtonCustomName = updatedButtonText

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(
            GLOBAL.URL.UPDATE_COURSE_CATALOG_GROUP_BUTTON_CUSTOM_NAME,
            formbody
        )
            .then((response) => {
                hideEditButtonTextNameModal()

                var successObject = data
                successObject.forEach((row) => {
                    if (record.id === row.id) {
                        row.groupButtonCustomName = updatedButtonText
                        return
                    }
                })

                setData(successObject)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const closeImageModal = () => {
        setShowImageModal(false)
    }

    const enableDisableCatlogItem = (record) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        let url = ''
        if (!record.enabled) {
            url = GLOBAL.URL.ENABLE_CATLOG_ITEM
        } else {
            url = GLOBAL.URL.DISABLE_CATLOG_ITEM
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                var successObject = data
                successObject.forEach((row) => {
                    if (record.id === row.id) {
                        row.enabled = !row.enabled
                        return
                    }
                })
                setData(successObject)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showGroupModalVisible = () => {
        setGroupModalVisible(true)
        setSelectedGroup(null)
        if (groupData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_GROUP_NAMES).then((res) => {
                setGroupData(res.data)
            })
        }
    }

    const hideGroupModalVisible = () => {
        setGroupModalVisible(false)
    }

    const unLinkItem = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.UNLINK_ITEM, postBody)
            .then((response) => {
                var successObject = data
                successObject = successObject.filter((row) => row.id !== id)
                setData(successObject)

                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Button
                    onClick={() => {
                        enableDisableCatlogItem(record)
                    }}
                    size="small"
                    type="link"
                >
                    {record.enabled ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    {record.group ? (
                        <Button
                            type="link"
                            onClick={() => {
                                getGroupData(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            {record.name}
                        </Button>
                    ) : (
                        <>
                            <Link
                                href={'/course/configure/' + record.courseId}
                                underline="none"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={faCog}
                                    style={{ fontSize: 11 }}
                                />
                            </Link>
                            <span style={{ marginLeft: 10 }}>
                                {record.name}
                            </span>
                        </>
                    )}
                </span>
            ),
        },
        {
            title: 'Sub Group',
            dataIndex: 'subGroupName',
            key: 'subGroupName',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showEditSubgGroupNameModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>
                        {record.subGroupName}
                    </span>
                </span>
            ),
        },
        {
            title: 'Button Text',
            dataIndex: 'groupButtonCustomName',
            key: 'groupButtonCustomName',
            render: (text, record) => (
                <span>
                    {record.group ? (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    showEditButtonTextNameModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditTwoTone style={{ fontSize: 14 }} />
                            </Button>
                            <span style={{ marginLeft: 10 }}>
                                {record.groupButtonCustomName}
                            </span>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Deep Link',
            dataIndex: 'deepLink',
            key: 'deepLink',
            render: (text, record) => (
                <span>
                    {record.deepLink ? (
                        <>
                            <CopyToClipboard
                                text={record.deepLink}
                                onCopy={() =>
                                    notification['success']({
                                        message: 'Link copied!',
                                    })
                                }
                            >
                                <span>
                                    <Button
                                        type="link"
                                        style={{ padding: 0, fontSize: 10 }}
                                    >
                                        <CopyOutlined />
                                        {'Copy Link'}
                                    </Button>
                                </span>
                            </CopyToClipboard>
                            <Button
                                type="link"
                                style={{
                                    padding: 0,
                                    marginLeft: 5,
                                    fontSize: 10,
                                }}
                                onClick={() => showQrCode(record.deepLink)}
                            >
                                | QR Code
                            </Button>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Image',
            dataIndex: 'pictureUrl',
            key: 'pictureUrl',
            width: 75,
            render: (text, record) => (
                <span>
                    {record.pictureUrl ? (
                        <Button
                            type="link"
                            style={{ padding: 0, margin: 0 }}
                            onClick={() => {
                                setImageSrc(record.pictureUrl)
                                setShowImageModal(true)
                            }}
                        >
                            View
                        </Button>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Delete',
            key: 'id',
            render: (text, record) => (
                <span>
                    <Popconfirm
                        title="Are you sure want to delete ?"
                        onConfirm={() => {
                            unLinkItem(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                                fontSize: 11,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const addGroup = () => {
        if (!selectedGroup) {
            notification['error']({
                message: 'Please select group name',
            })
            return
        }

        setLoading(true)

        let payload = {}
        payload.name = selectedGroup
        payload.catalogId = catalogId

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_GROUP_TO_CATLOG, formData)
            .then((response) => {
                fetchData()
                notification['success']({
                    message: response.data,
                })
                hideGroupModalVisible()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const groupDetails = () => {
        var groupDetails = []
        groupData.forEach((item) => {
            groupDetails.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return groupDetails
    }

    const showCourseModal = () => {
        setSelectedCourse(null)
        setCourseModalVisible(true)

        if (courseData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
                setCourseData(res.data)
            })
        }
    }

    const hideCourseModal = () => {
        setCourseModalVisible(false)
    }

    const addCourse = () => {
        if (!selectedCourse) {
            notification['error']({
                message: 'Please select course name',
            })
            return
        }

        setLoading(true)

        let payload = {}
        payload.name = selectedCourse
        payload.catalogId = catalogId

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_COURSE_TO_CATLOG, formData)
            .then((response) => {
                fetchData()
                notification['success']({
                    message: response.data,
                })
                hideCourseModal()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const courseDetails = () => {
        var courseDetails = []
        courseData.forEach((item) => {
            courseDetails.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return courseDetails
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
        ]

        var payload = {}
        payload.catalogId = catalogId

        var reorderData = []
        data.forEach((item) => {
            var row = {}
            row.name = item.name
            row.id = item.id

            reorderData.push(row)
        })

        navigate('/reorder', {
            state: {
                reorderData: reorderData,
                updateUrl: GLOBAL.URL.REORDER_CATALOG_ITEMS,
                columns: columns,
                payload: payload,
            },
        })
    }

    const enableDisableAll = (enabled) => {
        setLoading(true)

        var payload = {}
        payload.catalogId = catalogId

        var url = GLOBAL.URL.ENABLE_ALL_COURSE_CATALOG_ITEMS
        if (!enabled) {
            url = GLOBAL.URL.DISABLE_ALL_COURSE_CATALOG_ITEMS
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(url, postBody)
            .then((response) => {
                fetchData()

                notification['success']({
                    message: response.data,
                })
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showQrCode = (link) => {
        setQrCodeValue(link)
        setQrCodeVisible(true)
    }

    const hideQrCode = () => {
        setQrCodeValue('')
        setQrCodeVisible(false)
    }

    return (
        <div>
            <h3>{'Course Catalog'}</h3>
            <div>
                <Button
                    type="default"
                    onClick={() => goBack(navigate)}
                    size="small"
                    loading={loading}
                >
                    Back
                </Button>
                <Button
                    type="primary"
                    onClick={showGroupModalVisible}
                    size="small"
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Add Group
                </Button>
                <Button
                    type="primary"
                    style={{ marginLeft: 10 }}
                    onClick={showCourseModal}
                    size="small"
                    loading={loading}
                >
                    Add Course
                </Button>
                <Button
                    type="primary"
                    style={{ marginLeft: 10 }}
                    onClick={reorder}
                    size="small"
                    loading={loading}
                >
                    Reorder
                </Button>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableAll(true)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="primary"
                        style={{ marginLeft: 10 }}
                        size="small"
                        loading={loading}
                    >
                        Enable All Deep
                    </Button>
                </Popconfirm>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableAll(false)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="danger"
                        style={{ marginLeft: 10 }}
                        size="small"
                        loading={loading}
                    >
                        Disable All Deep
                    </Button>
                </Popconfirm>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="id"
                style={{ marginTop: 10 }}
            />
            <Modal
                title="Edit Sub Group Name"
                open={editSubgGroupNameModalVisible}
                onOk={updateSubGroupName}
                confirmLoading={loading}
                onCancel={hideEditSubGroupNameModal}
                okText="Update"
                destroyOnClose={true}
            >
                <h3>Current name:</h3>
                <span>{record.subGroupName}</span>
                <h3 style={{ marginTop: 15 }}>Updated name:</h3>
                <Input
                    value={updatedSubGrupName}
                    autoFocus
                    onChange={(e) => {
                        setUpdatedSubGrupName(e.target.value)
                    }}
                    ref={inputRef}
                ></Input>
            </Modal>
            <Modal
                title="Configure Button Text"
                open={editButtonTextModalVisible}
                onOk={updateButtonText}
                confirmLoading={loading}
                onCancel={hideEditButtonTextNameModal}
                okText="Update"
                destroyOnClose={true}
            >
                <h3>Current text:</h3>
                <span>{record.groupButtonCustomName}</span>
                <h3 style={{ marginTop: 15 }}>Updated text:</h3>
                <Input
                    value={updatedButtonText}
                    autoFocus
                    onChange={(e) => {
                        setUpdatedButtonText(e.target.value)
                    }}
                    ref={inputRef}
                ></Input>
            </Modal>
            <Modal
                title="Image Preview"
                open={showImageModal}
                onCancel={closeImageModal}
                footer={null}
            >
                <img
                    src={imageSrc}
                    alt={imageSrc}
                    style={{ maxWidth: '100%' }}
                />
            </Modal>
            <Modal
                title="Add Group"
                open={groupModalVisible}
                onOk={addGroup}
                confirmLoading={loading}
                onCancel={hideGroupModalVisible}
                okText="Add Group"
            >
                <Select
                    placeholder="Select Group"
                    style={{ width: '100%' }}
                    onChange={(option) => {
                        setSelectedGroup(option)
                    }}
                    showSearch
                >
                    {groupDetails()}
                </Select>
            </Modal>
            <Modal
                title="Add Course"
                open={courseModalVisible}
                onOk={addCourse}
                confirmLoading={loading}
                onCancel={hideCourseModal}
                okText="Add Course"
            >
                <Select
                    placeholder="Select Course"
                    style={{ width: '100%' }}
                    onChange={(option) => {
                        setSelectedCourse(option)
                    }}
                    showSearch
                >
                    {courseDetails()}
                </Select>
            </Modal>
            <Modal open={qrCodeVisible} footer={null} onCancel={hideQrCode}>
                <QrCode value={qrCodeValue} />
            </Modal>
        </div>
    )
}

export default CourseCatalog
