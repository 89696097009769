import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Popconfirm,
    notification,
    Select,
    Input,
    DatePicker,
    Switch,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

function Attendance() {
    const { Option } = Select
    const { Search } = Input
    const [form] = Form.useForm()

    const [courseFilter, setCourseFilter] = useState('')
    const [selectedCollegeId, setSelectedCollegeId] = useState('')
    const [colleges, setColleges] = useState([])
    const [courses, setCourses] = useState([])

    const [loading, setLoading] = useState(false)
    const [attendanceCourses, setAttendanceCourses] = useState([])
    const [
        addAttendanceCourseModalVisible,
        setAddAttendanceCourseModalVisible,
    ] = useState(false)
    const [selectedAttendanceCourseRecord, setSelectedAttendanceCourseRecord] =
        useState({})
    const [nextAttendanceCourseUrl, setNextAttendanceCourseUrl] = useState()
    const [
        updateCourseCollegeModalVisible,
        setUpdateCourseCollegeModalVisible,
    ] = useState(false)

    const [students, setStudents] = useState([])
    const [studentsModaVisible, setStudentsModalVisible] = useState(false)
    const [loadingStudents, setLoadingStudents] = useState(false)

    const [dates, setDates] = useState([])
    const [datesModalVisible, setDatesModalVisible] = useState(false)
    const [loadingDates, setLoadingDates] = useState(false)
    const [nextDatesUrl, setNextDatesUrl] = useState('')

    const [addAttendanceModalVisible, setAddAttendanceModalVisible] =
        useState(false)
    const [attendanceDate, setAttendanceDate] = useState('')
    const [attendanceStatus, setAttendanceStatus] = useState({})

    const [updateUserInfoVisible, setUpdateUserInfoVisible] = useState(false)
    const [selectedUserInfo, setSelectedUserInfo] = useState({})
    const inputRef = useRef()

    useEffect(() => {
        getAttendanceCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseFilter, selectedCollegeId])

    useEffect(() => {
        getColleges()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        formAttendanceStatusObj()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [students])

    useEffect(() => {
        setTimeout(() => {
            if (updateUserInfoVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [updateUserInfoVisible, inputRef])

    const getColleges = () => {
        RestApi.doGet(GLOBAL.URL.COLLEGE.GET_COLLEGES).then((res) => {
            setColleges(res.data)
        })
    }

    const getAttendanceCourses = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.ATTENDANCE.GET_ATTENDANCE_COURSES +
                '?courseFilter=' +
                courseFilter +
                '&collegeId=' +
                selectedCollegeId
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setAttendanceCourses(res.data)
                setNextAttendanceCourseUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAttendanceCoursesNextPage = () => {
        if (!nextAttendanceCourseUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextAttendanceCourseUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextAttendanceCourseUrl(res.next)
                setAttendanceCourses((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'course',
            key: 'course',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.course}</span>
            ),
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showAddAttendanceModal(record)}
                    >
                        Mark Attendance
                    </Button>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12, marginLeft: 10 }}
                        onClick={() => showDatesModal(record)}
                    >
                        Dates
                    </Button>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12, marginLeft: 10 }}
                        onClick={() => showStudentsModal(record)}
                    >
                        Students
                    </Button>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => generateReport(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12, marginLeft: 10 }}
                        >
                            Report
                        </Button>
                    </Popconfirm>
                </>
            ),
        },
        {
            title: 'College Name',
            dataIndex: 'college',
            key: 'college',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateCourseCollegeModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 12 }}
                        />
                    </Button>
                    <span style={{ marginLeft: 5, fontSize: 12 }}>
                        {record.college}
                    </span>
                </>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.updatedAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteAttendanceCourse(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showAddAttendanceCourseModal = () => {
        form.resetFields()
        setAddAttendanceCourseModalVisible(true)

        if (!courses.length) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }
    }

    const hideAddAttendanceCourseModal = () => {
        setAddAttendanceCourseModalVisible(false)
    }

    const getCourseOptions = () => {
        var courseOptions = []
        courses.forEach((item) => {
            courseOptions.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return courseOptions
    }

    const getCollegeOptions = () => {
        var options = []
        colleges.forEach((college) => {
            options.push(
                <Option value={college.uid} key={college.uid}>
                    {college.name}
                </Option>
            )
        })

        return options
    }

    const addAttendanceCourse = () => {
        form.validateFields(['courseId', 'collegeId'])
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ATTENDANCE.ADD_ATTENDANCE_COURSE,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddAttendanceCourseModal()
                        getAttendanceCourses()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteAttendanceCourse = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ATTENDANCE.DELETE_ATTENDANCE_COURSE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getAttendanceCourses()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showUpdateCourseCollegeModal = (record) => {
        setSelectedAttendanceCourseRecord(record)
        setUpdateCourseCollegeModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({ collegeId: record.collegeId })
        }, 100)
    }

    const hideUpdateCourseCollegeModal = () => {
        setSelectedAttendanceCourseRecord({})
        setUpdateCourseCollegeModalVisible(false)
    }

    const updateCourseCollege = () => {
        form.validateFields(['collegeId'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedAttendanceCourseRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ATTENDANCE.UPDATE_ATTENDANCE_COURSE_COLLEGE,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateCourseCollegeModal()
                        getAttendanceCourses()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showStudentsModal = (record) => {
        setSelectedAttendanceCourseRecord(record)
        setStudentsModalVisible(true)
        setSelectedAttendanceCourseRecord(record)
        getStudents(record.uid)
    }

    const generateReport = (record) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.ATTENDANCE.GET_COURSE_REPORT + record.uid)
            .then((res) => {
                notification['success']({
                    message: res.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideStudentsModal = () => {
        setSelectedAttendanceCourseRecord({})
        setStudentsModalVisible(false)
    }

    const getStudents = (uid) => {
        setLoadingStudents(true)

        RestApi.doGet(GLOBAL.URL.ATTENDANCE.GET_COURSE_USERS_INFO + uid)
            .then((res) => {
                setStudents(res.data)
            })
            .finally(() => {
                setLoadingStudents(false)
            })
    }

    const studentsTableColums = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateUserInfo(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    </Button>
                    <Button
                        type="link"
                        style={{ padding: 0, marginLeft: 5 }}
                        onClick={() =>
                            window.open(
                                '/userdetails/' + record.userId,
                                '_blank'
                            )
                        }
                    >
                        {record.firstName + ' ' + record.lastName}
                    </Button>
                </span>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Roll Number',
            dataIndex: 'rollNumber',
            key: 'rollNumber',
        },
        {
            title: 'Student ID',
            dataIndex: 'studentId',
            key: 'studentId',
        },
        {
            title: 'Phone 1',
            dataIndex: 'phone1',
            key: 'phone1',
        },
        {
            title: 'Phone 2',
            dataIndex: 'phone2',
            key: 'phone2',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy ? (
                        <span style={{ fontSize: 11 }}>
                            {record.updatedBy.firstName}
                            <br />
                            {record.updatedAt}
                        </span>
                    ) : null}
                </span>
            ),
        },
    ]

    const showDatesModal = (record) => {
        setDatesModalVisible(true)
        setSelectedAttendanceCourseRecord(record)
        getDates(record.uid)
    }

    const hideDatesModal = () => {
        setDatesModalVisible(false)
        setSelectedAttendanceCourseRecord({})
    }

    const getDates = (uid) => {
        setLoadingDates(true)

        RestApi.doGet(GLOBAL.URL.ATTENDANCE.GET_ATTENDANCE_DATES + uid)
            .then((response) => {
                var res = JSON.parse(response.data)
                setDates(res.data)
                setNextDatesUrl(res.next)
            })
            .finally(() => {
                setLoadingDates(false)
            })
    }

    const getDatesNextPage = () => {
        if (!nextDatesUrl) {
            return
        }

        setLoadingDates(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextDatesUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextDatesUrl(res.next)
                setDates((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoadingDates(false)
            })
    }

    const deleteAttendanceDate = (uid) => {
        setLoadingDates(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ATTENDANCE.DELETE_ATTENDANCE_DATE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getDates(selectedAttendanceCourseRecord.uid)
            })
            .catch((error) => {
                setLoadingDates(false)
            })
    }

    const datesTableColums = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'attendanceDate',
            key: 'attendanceDate',
        },
        {
            title: 'Marked By',
            dataIndex: 'markedBy',
            key: 'markedBy',
            render: (text, record) => (
                <span>
                    {record.markedBy.firstName + ' ' + record.markedBy.lastName}
                </span>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.updatedAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteAttendanceDate(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddAttendanceModal = (record) => {
        setAddAttendanceModalVisible(true)
        setSelectedAttendanceCourseRecord(record)
        setAttendanceDate('')

        if (!students.length) {
            getStudents(record.uid)
        }
    }

    const formAttendanceStatusObj = () => {
        var obj = {}

        if (students && students.length) {
            students.forEach((student) => {
                obj[student.id] = true
            })
        }

        setAttendanceStatus(obj)
    }

    const hideAddAttendanceModal = () => {
        setAddAttendanceModalVisible(false)
        setSelectedAttendanceCourseRecord({})
        setAttendanceDate('')
    }

    const onChangeAttendanceDate = (value, dateString) => {
        setAttendanceDate(dateString)
    }

    const updateAddAttendanceStatus = (userId, status) => {
        var newStatus = attendanceStatus
        newStatus[userId] = status

        setAttendanceStatus(newStatus)
    }

    const addAttendanceTableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={attendanceStatus[record.id]}
                    onClick={(enabled) =>
                        updateAddAttendanceStatus(record.id, enabled)
                    }
                />
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() =>
                            window.open(
                                '/userdetails/' + record.userId,
                                '_blank'
                            )
                        }
                    >
                        {record.firstName + ' ' + record.lastName}
                    </Button>
                </span>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const addAttendance = () => {
        var payload = {}
        payload.attendanceDate = attendanceDate
    }

    const showUpdateUserInfo = (record) => {
        setSelectedUserInfo(record)
        setUpdateUserInfoVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                studentId: record.studentId,
                rollNumber: record.rollNumber,
                phone1: record.phone1,
                phone2: record.phone2,
            })
        }, 100)
    }

    const hidepdateUserInfo = () => {
        setSelectedUserInfo({})
        setUpdateUserInfoVisible(false)
    }

    const updateUserInfo = () => {
        form.validateFields(['studentId', 'rollNumber', 'phone1', 'phone2'])
            .then((values) => {
                setLoadingStudents(true)

                values.courseUid = selectedAttendanceCourseRecord.uid
                values.userId = selectedUserInfo.userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ATTENDANCE.UPDATE_COURSE_USER_INFO,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hidepdateUserInfo()
                        getStudents(selectedAttendanceCourseRecord.uid)
                    })
                    .catch((error) => {
                        setLoadingStudents(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            <h2>Attendance Courses</h2>
            <div>
                <Search
                    placeholder="Search by Course"
                    onSearch={(value) => setCourseFilter(value ? value : '')}
                    style={{ width: 300 }}
                    allowClear
                />
                <Select
                    placeholder="Select college"
                    showSearch
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ marginLeft: 10, width: 300 }}
                    onChange={(value) =>
                        setSelectedCollegeId(value ? value : '')
                    }
                    allowClear
                >
                    {getCollegeOptions()}
                </Select>
                <Button
                    type="primary"
                    onClick={showAddAttendanceCourseModal}
                    style={{ marginLeft: 10 }}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={attendanceCourses}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="course"
                style={{ marginTop: 10 }}
            />
            {nextAttendanceCourseUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getAttendanceCoursesNextPage}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Add Attendance Course'}
                open={addAttendanceCourseModalVisible}
                onOk={addAttendanceCourse}
                confirmLoading={loading}
                onCancel={hideAddAttendanceCourseModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select course"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {getCourseOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="College"
                        name="collegeId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select college"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {getCollegeOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update College in Course'}
                open={updateCourseCollegeModalVisible}
                onOk={updateCourseCollege}
                confirmLoading={loading}
                onCancel={hideUpdateCourseCollegeModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <h3>{selectedAttendanceCourseRecord.course}</h3>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="College"
                        name="collegeId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select college"
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {getCollegeOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Students List'}
                open={studentsModaVisible}
                onCancel={hideStudentsModal}
                destroyOnClose={true}
                width={1200}
                footer={null}
            >
                <h3>{selectedAttendanceCourseRecord.course}</h3>
                <Table
                    columns={studentsTableColums}
                    dataSource={students}
                    pagination={false}
                    size="small"
                    loading={loadingStudents}
                    rowKey="email"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Dates List'}
                open={datesModalVisible}
                onCancel={hideDatesModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedAttendanceCourseRecord.course}</h3>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                        hideDatesModal()
                        showAddAttendanceModal(selectedAttendanceCourseRecord)
                    }}
                >
                    Mark Attendance
                </Button>
                <Table
                    columns={datesTableColums}
                    dataSource={dates}
                    pagination={false}
                    size="small"
                    loading={loadingDates}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
                {nextDatesUrl ? (
                    <Button
                        type="primary"
                        loading={loadingDates}
                        onClick={getDatesNextPage}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </Modal>
            <Modal
                title={'Mark Attendance'}
                open={addAttendanceModalVisible}
                onOk={addAttendance}
                confirmLoading={loading}
                onCancel={hideAddAttendanceModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                <h3>{selectedAttendanceCourseRecord.course}</h3>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Date"
                        style={{ marginBottom: 10 }}
                        name="attendanceDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <DatePicker
                            showTime
                            placeholder="Select Date"
                            format="DD-MMM-YYYY"
                            onChange={onChangeAttendanceDate}
                        />
                    </Form.Item>
                </Form>
                <Table
                    columns={addAttendanceTableColumns}
                    dataSource={students}
                    pagination={false}
                    size="small"
                    loading={loadingStudents}
                    rowKey="email"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Update Student Info'}
                open={updateUserInfoVisible}
                onOk={updateUserInfo}
                confirmLoading={loadingStudents}
                onCancel={hidepdateUserInfo}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <h3>
                    {selectedUserInfo.firstName +
                        ' ' +
                        selectedUserInfo.lastName}
                </h3>
                {updateUserInfoVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Roll Number"
                            style={{ marginBottom: 10 }}
                            name="rollNumber"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input autoFocus ref={inputRef} />
                        </Form.Item>
                        <Form.Item
                            label="Student ID"
                            style={{ marginBottom: 10 }}
                            name="studentId"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Phone 1"
                            style={{ marginBottom: 10 }}
                            name="phone1"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Phone 2"
                            style={{ marginBottom: 10 }}
                            name="phone2"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default Attendance
