import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    notification,
    DatePicker,
    Select,
    Radio,
    Checkbox,
    Form,
} from 'antd'
import { Utils } from '../JS/Utils'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

const REPORT_TYPE_SUMMARY = 'REPORT_TYPE_SUMMARY'
const REPORT_TYPE_DETAILED = 'REPORT_TYPE_DETAILED'

function SalesTeamCallsReport() {
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker
    const { Option } = Select

    const dateFormat = 'DD-MMM-YYYY'

    const [loading, setLoading] = useState(false)
    const [currentDayReport, setCurrentDayReport] = useState()
    const [adminUsers, setAdminUsers] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [report, setReport] = useState()
    const [loadingCustomReport, setLoadingCustomReport] = useState(false)
    const [reportType, setReportType] = useState(REPORT_TYPE_SUMMARY)
    const [detailedReport, setDetailedReport] = useState()
    const [message, setMessage] = useState()
    const [isUniqueUsers, setIsUniqueUsers] = useState(false)
    const [showCurrentDayData, setShowCurrentDayData] = useState(true)

    useEffect(() => {
        if (Utils.userIsSalesManager()) {
            getCartAdminUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // if (Utils.userIsSalesManager()) {
        //     setShowCurrentDayData(true)
        //     getCurrentDayReport()
        // } else {
        //     const currentDate = new Date()
        //     const currentDayOfMonth = currentDate.getDate()
        //     const currentMonth = currentDate.getMonth() + 1
        //     const currentYear = currentDate.getFullYear()

        //     let dateString =
        //         currentYear +
        //         '-' +
        //         (currentMonth < 10 ? '0' : '') +
        //         currentMonth +
        //         '-' +
        //         (currentDayOfMonth < 10 ? '0' : '') +
        //         currentDayOfMonth +
        //         'T17:30:00'

        //     const currentDate530PM = new Date(dateString)

        //     if (currentDate530PM.getTime() < new Date().getTime()) {
        //         setShowCurrentDayData(true)
        //         getCurrentDayReport()
        //     }
        // }

        setShowCurrentDayData(true)
        getCurrentDayReport()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCurrentDayReport = () => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.SALES.GET_CALLS_REPORT_CURRENT_DAY +
                '?isUniqueUsers=' +
                isUniqueUsers
        )
            .then((res) => {
                setCurrentDayReport(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCartAdminUsers = () => {
        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
            setAdminUsers(res.data)
        })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start date needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End date needed!',
                    })

                    return
                }

                setReport()
                setLoadingCustomReport(true)

                values.startDate = startDate
                values.endDate = endDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.SALES.GENERATE_CALLS_REPORT, formData)
                    .then((response) => {
                        setReport(response.data)
                    })
                    .finally(() => {
                        setLoadingCustomReport(false)
                    })
            })
            .catch((error) => {})
    }

    const onChangeReportType = (event) => {
        setReportType(event.target.value)
    }

    const generateDetailedReport = (isSendEmail) => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start date needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End date needed!',
                    })

                    return
                }

                setReport()
                setLoadingCustomReport(true)

                values.startDate = startDate
                values.endDate = endDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                var url = GLOBAL.URL.SALES.GENERATE_CALLS_MADE_DETAILED_REPORT
                if (isSendEmail) {
                    url = GLOBAL.URL.SALES.DOWNLOAD_CALLS_MADE_DETAILED_REPORT
                }

                RestApi.doPost(url, formData)
                    .then((response) => {
                        if (isSendEmail) {
                            setMessage(response.data)
                        } else {
                            setDetailedReport(response.data)
                        }
                    })
                    .finally(() => {
                        setLoadingCustomReport(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            {showCurrentDayData ? (
                <div style={{ marginBottom: 20 }}>
                    <h2>
                        Calls Report (current day){' '}
                        <Button type="link" onClick={getCurrentDayReport}>
                            {loading ? (
                                <LoadingOutlined style={{ fontSize: 20 }} />
                            ) : (
                                <ReloadOutlined style={{ fontSize: 20 }} />
                            )}
                        </Button>
                    </h2>
                    <Checkbox
                        checked={isUniqueUsers}
                        disabled={loading}
                        onChange={(e) => setIsUniqueUsers(e.target.checked)}
                    >
                        {'Unique Users'}
                    </Checkbox>
                    {currentDayReport ? (
                        <div
                            style={{ marginTop: 20 }}
                            dangerouslySetInnerHTML={{
                                __html: currentDayReport,
                            }}
                        />
                    ) : null}
                </div>
            ) : null}
            <div>
                <h2>Generate Calls Made Report</h2>
                <Radio.Group
                    onChange={onChangeReportType}
                    defaultValue={reportType}
                    style={{ marginBottom: 10 }}
                >
                    <Radio.Button value={REPORT_TYPE_SUMMARY}>
                        Summary
                    </Radio.Button>

                    <Radio.Button value={REPORT_TYPE_DETAILED}>
                        Detailed
                    </Radio.Button>
                </Radio.Group>

                {reportType === REPORT_TYPE_SUMMARY ? (
                    <>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="Date Range"
                                style={{ marginBottom: 5 }}
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <RangePicker
                                    format={dateFormat}
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateChange}
                                />
                            </Form.Item>
                            {Utils.userIsSalesManager() ? (
                                <Form.Item
                                    label="Users"
                                    style={{ marginBottom: 5 }}
                                    name="salesPersonIds"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select"
                                        showSearch
                                        mode="multiple"
                                        allowClear={true}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {getAdminUserOptions()}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                label="Unique Users"
                                style={{ marginBottom: 5 }}
                                name="isUniqueUsers"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Form>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="primary"
                                size="small"
                                loading={loadingCustomReport}
                                onClick={() => generateReport()}
                            >
                                Generate Report
                            </Button>
                        </div>
                        {report ? (
                            <div
                                style={{ marginTop: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: report,
                                }}
                            />
                        ) : null}
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="Date Range"
                                style={{ marginBottom: 5 }}
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <RangePicker
                                    format={dateFormat}
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateChange}
                                />
                            </Form.Item>
                            {Utils.userIsSalesManager() ? (
                                <Form.Item
                                    label="User"
                                    style={{ marginBottom: 5 }}
                                    name="salesPersonId"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        mode="multiple"
                                    >
                                        {getAdminUserOptions()}
                                    </Select>
                                </Form.Item>
                            ) : null}
                        </Form>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="primary"
                                size="small"
                                loading={loadingCustomReport}
                                onClick={() => generateDetailedReport(false)}
                            >
                                Show Here
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                loading={loadingCustomReport}
                                onClick={() => generateDetailedReport(true)}
                                style={{ marginLeft: 20 }}
                            >
                                Generate &amp; Send Email
                            </Button>
                            {message ? (
                                <h3 style={{ marginTop: 20 }}>{message}</h3>
                            ) : null}
                        </div>
                        {detailedReport ? (
                            <div
                                style={{ marginTop: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: detailedReport,
                                }}
                            />
                        ) : null}
                    </>
                )}
            </div>
        </div>
    )
}

export default SalesTeamCallsReport
