import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, message, Select, Form } from 'antd'

const { Option } = Select
const FacultyRevenueReport = () => {
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [facultyData, setFacultyData] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        getFaculties()
    }, [])

    const getFaculties = () => {
        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY).then((res) => {
            setFacultyData(res.data)
        })
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.facultyId = values.facultyId
                payload.startDate = startDate
                payload.endDate = endDate

                let formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.GENERATE_FACULTY_REVENUE_REPORT,
                    formData
                )
                    .then((res) => {
                        message.success(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const facultyOptions = () => {
        var options = []
        options.push(
            <Option value={0} key={0}>
                {'All'}
            </Option>
        )

        facultyData.forEach((item) => {
            options.push(
                <Option value={item.id} key={item.id}>
                    {item.firstName + ' ' + item.lastName}
                </Option>
            )
        })

        return options
    }

    const { RangePicker } = DatePicker
    const dateFormat = 'DD-MMM-YY'

    const formItemLayout = {
        labelCol: {
            xs: { span: 6 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <div>
            <h2>Faculty Revenue Report</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Faculty"
                    style={{ marginBottom: 10 }}
                    name="facultyId"
                    rules={[
                        {
                            required: true,
                            message: 'Select Faculty',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Faculty"
                        style={{ width: 500 }}
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {facultyOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Date Range"
                    style={{ marginBottom: 0 }}
                    name="dateRange"
                    rules={[
                        {
                            required: true,
                            message: 'Date range needed!',
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateChange}
                    />
                </Form.Item>
            </Form>
            <Button
                type="primary"
                loading={loading}
                onClick={() => generateReport()}
                style={{ marginTop: 20, marginLeft: 10 }}
            >
                Generate Report
            </Button>
        </div>
    )
}

export default FacultyRevenueReport
