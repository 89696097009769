import React, { useState, useRef, useEffect } from 'react'
import { Input, Radio, InputNumber, Button } from 'antd'
import { QRCode } from 'react-qrcode-logo'

const QR_STYLE_SQUARES = 'squares'
const QR_STYLE_DOTS = 'dots'
const QR_STYLE_FLUID = 'fluid'

function QrCode(props) {
    const [value, setValue] = useState(
        props.value ? props.value : 'https://1fin.link'
    )
    const [bgColor, setBgColor] = useState('#FFFFFF')
    const [fgColor, setFgColor] = useState('#5a4194')
    const [logo, setLogo] = useState(
        'https://il-app.s3.ap-south-1.amazonaws.com/images/logo_1fin_indigolearn.jpg'
    )
    const [qrStyle, setQrStyle] = useState(QR_STYLE_DOTS)
    const [eyeRadius, setEyeRadius] = useState(20)
    const [eyeColor, setEyeColor] = useState('#32127A')
    const [size, setSize] = useState(300)

    const qrCodeRef = useRef(null)

    useEffect(() => {
        if (props.value) {
            setValue(props.value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const downloadQrCode = () => {
        if (!qrCodeRef) {
            return
        }

        qrCodeRef.current?.download()
    }

    return (
        <div>
            <h2>QR Code Generator</h2>
            <div>
                <span>Value</span>
                <Input
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Logo</span>
                <Input value={logo} onChange={(e) => setLogo(e.target.value)} />
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Background Color</span>
                <Input
                    value={bgColor}
                    onChange={(e) => setBgColor(e.target.value)}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Foreground Color</span>
                <Input
                    value={fgColor}
                    onChange={(e) => setFgColor(e.target.value)}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <span>QR Style</span>
                <Radio.Group
                    onChange={(e) => setQrStyle(e.target.value)}
                    value={qrStyle}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={QR_STYLE_DOTS} key={QR_STYLE_DOTS}>
                        Dots
                    </Radio.Button>
                    <Radio.Button
                        value={QR_STYLE_SQUARES}
                        key={QR_STYLE_SQUARES}
                    >
                        Squares
                    </Radio.Button>
                    <Radio.Button value={QR_STYLE_FLUID} key={QR_STYLE_FLUID}>
                        Fluid
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Eye Radius</span>
                <InputNumber
                    value={eyeRadius}
                    onChange={(value) => setEyeRadius(value)}
                    style={{ marginLeft: 10 }}
                    min={0}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Eye Color</span>
                <Input
                    value={eyeColor}
                    onChange={(e) => setEyeColor(e.target.value)}
                />
            </div>
            <div style={{ marginTop: 10 }}>
                <span>Size</span>
                <InputNumber
                    value={size}
                    onChange={(value) => setSize(value)}
                    style={{ marginLeft: 10 }}
                    min={0}
                />
            </div>
            <div>
                {value ? (
                    <QRCode
                        value={value}
                        ecLevel={'M'}
                        bgColor={bgColor}
                        fgColor={fgColor}
                        logoImage={logo}
                        removeQrCodeBehindLogo={true}
                        qrStyle={qrStyle}
                        eyeRadius={eyeRadius}
                        eyeColor={eyeColor}
                        ref={qrCodeRef}
                        enableCORS={true}
                        size={size}
                        // quietZone={10}
                    />
                ) : null}
            </div>
            <div style={{ marginTop: 10 }}>
                <Button type={'primary'} onClick={downloadQrCode}>
                    Download
                </Button>
            </div>
        </div>
    )
}

export default QrCode
