import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    DatePicker,
    Select,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'

function IlHolidays() {
    const { Option } = Select
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const [holidayDate, setHolidayDate] = useState()

    useEffect(() => {
        getHolidays()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear])

    const getHolidays = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.IL.GET_HOLIDAYS + selectedYear)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.uid) {
            setHolidayDate(record.holidayDate)
            var dateObj = dayjs(record.holidayDate, 'DD-MMM-YYYY')
            setTimeout(() => {
                form.setFieldsValue({
                    date: dateObj,
                    description: record.description,
                })
            }, 100)
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Holiday Date',
            dataIndex: 'holidayDate',
            key: 'holidayDate',
            width: 150,
        },
        {
            title: 'Day',
            dataIndex: 'dayName',
            key: 'dayName',
            width: 75,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deletHoliday(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateHoliday = () => {
        form.validateFields()
            .then((values) => {
                if (!holidayDate) {
                    notification['error']({
                        message: 'Select Date!',
                    })
                    return
                }

                setLoading(true)

                var url = GLOBAL.URL.IL.ADD_HOLIDAY
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.IL.UPDATE_HOLIDAY
                    values.uid = selectedRecord.uid
                }

                values.date = holidayDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getHolidays()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deletHoliday = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.DELETE_HOLIDAY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getHolidays()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onChangeDate = (value, dateString) => {
        setHolidayDate(dateString)
    }

    const getYearOptions = () => {
        var options = []
        options.push(
            <Option value={2024} key={2024}>
                {2024}
            </Option>
        )

        options.push(
            <Option value={2025} key={2025}>
                {2025}
            </Option>
        )

        return options
    }

    return (
        <div>
            <h2>Holidays</h2>
            <div>
                <Select
                    placeholder="Year"
                    showSearch
                    style={{ width: 100 }}
                    onChange={(value) => setSelectedYear(value)}
                    value={selectedYear}
                >
                    {getYearOptions()}
                </Select>
                <Button
                    type="primary"
                    onClick={showAddEditModal}
                    style={{ marginLeft: 20 }}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="key"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Holiday'}
                open={addEditModalVisible}
                onOk={addOrUpdateHoliday}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <DatePicker
                            format="DD-MMM-YYYY"
                            onChange={onChangeDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default IlHolidays
