import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
    Table,
    Tag,
    Popconfirm,
    notification,
    Radio,
    Button,
    Modal,
    Input,
    Select,
    DatePicker,
    InputNumber,
    message,
    Tooltip,
    Upload,
    Checkbox,
    Form,
} from 'antd'
import {
    CopyOutlined,
    EditOutlined,
    LoadingOutlined,
    ReloadOutlined,
    UploadOutlined,
} from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import ReorderTable from '../Common/ReorderTable'
import { makeStyles } from 'tss-react/mui'
import clone from 'clone'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Paper from '@mui/material/Paper'
import WhatsAppTemplates from './WhatsAppTemplates'
import dayjs from 'dayjs'

const USERS_FILTER_TYPE_FILTERS = 'filters'
const USERS_FILTER_TYPE_FILE_UPLOAD = 'fileUpload'

function WhatsAppNotifications(props) {
    const { Option } = Select
    const { Dragger } = Upload

    const { classes, cx } = useStyles()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [filter, setFilter] = useState('')
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [whatsappTemplateNames, setWhatsappTemplateNames] = useState([])
    const [whatsappTemplateNamesLoading, setWhatsappTemplateNamesLoading] =
        useState(false)
    const [whatsAppTemplatesModalVisible, setWhatsAppTemplatesModalVisible] =
        useState(false)
    const [scheduledTime, setScheduledTime] = useState()
    const [variablesData, setVariablesData] = useState([])
    const [addVariableDataModalVisible, setAddVariableDataModalVisible] =
        useState(false)
    const [selectedVariableData, setSelectedVariableData] = useState({})
    const [
        reorderVariableDataModalVisible,
        setReorderVariableDataModalVisible,
    ] = useState(false)
    const [filtersModalVisible, setFiltersModalVisible] = useState(false)
    const [lastLoginStartDate, setLastLoginStartDate] = useState()
    const [userPlaceHolders, setUserPlaceHolders] = useState([])
    const [approxUsersCount, setApproxUsersCount] = useState(0)
    const [loadingApproxUsersCount, setLoadingApproxUsersCount] =
        useState(false)
    const [testMessageLoading, setTestMessageLoading] = useState(false)
    const [usersFilterType, setUsersFilterType] = useState(
        USERS_FILTER_TYPE_FILTERS
    )
    const [fileList, setFileList] = useState([])
    const [testErrorMessage, setTestErrorMessage] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getNotications()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const getNotications = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.NOTIFICATION.GET_WHATSAPP_NOTIFICATIONS +
                '?filter=' +
                filter
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageNotifications = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span>
                    {record.status === 'Sent' ? (
                        <span style={{ fontSize: 12 }}>{record.status}</span>
                    ) : (
                        <>
                            <Tag
                                color={
                                    record.status === 'Open'
                                        ? 'geekblue'
                                        : record.status === 'Error'
                                        ? 'red'
                                        : 'purple'
                                }
                            >
                                {record.status}
                            </Tag>
                            {record.status === 'Error' && record.statusText ? (
                                <Tooltip title={record.statusText}>
                                    <ExclamationCircleOutlined />
                                </Tooltip>
                            ) : null}
                        </>
                    )}
                </span>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    {record.status === 'Open' || record.status === 'Sending' ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showAddUpdateModal(record)
                            }}
                            style={{ padding: 0, marginRight: 5 }}
                        >
                            <EditOutlined
                                theme="twoTone"
                                style={{ fontSize: 10 }}
                            />
                        </Button>
                    ) : null}
                    <span style={{ fontSize: 14, fontWeight: 500 }}>
                        {record.title}
                    </span>
                    <br />
                    <Popconfirm
                        title="Send test notification?"
                        onConfirm={() =>
                            sendTestNotificationByNotificationId(
                                record.notificationId
                            )
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            size="small"
                            loading={testMessageLoading}
                            style={{ padding: 0, fontSize: 11 }}
                        >
                            Test
                        </Button>
                    </Popconfirm>
                    {record.imageUrl ? (
                        <span style={{ marginLeft: 10 }}>
                            <a
                                href={record.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: 12 }}
                            >
                                Image
                            </a>
                        </span>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Scheduled Time',
            dataIndex: 'scheduledTime',
            key: 'scheduledTime',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>{record.scheduledTime}</span>
                </span>
            ),
        },
        {
            title: 'Template Name',
            dataIndex: 'templateName',
            key: 'templateName',
        },
        {
            title: 'Sent Time',
            dataIndex: 'sentStartTime',
            key: 'sentStartTime',
            width: 125,
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.sentStartTime}
                    <br />
                    {record.sentEndTime}
                </span>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'totalCount',
            key: 'totalCount',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.totalCount}</span>
            ),
        },
        {
            title: 'Sent',
            dataIndex: 'sentCount',
            key: 'sentCount',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.sentCount}
                    {record.sendingCount ? (
                        <span>
                            <br />
                            {'(' + record.sendingCount + ')'}
                        </span>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Failed',
            dataIndex: 'failedCount',
            key: 'failedCount',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.failedCount}</span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 11 }}>
                        {record.updatedBy.firstName}
                        <br />
                        {record.updatedAt}
                    </span>
                </span>
            ),
        },
        {
            title: 'Filters / Data',
            dataIndex: 'filters',
            key: 'filters',
            render: (text, record) => (
                <Button
                    onClick={() => showFiltersModal(record)}
                    type="link"
                    style={{ padding: 0, fontSize: 12 }}
                >
                    View
                </Button>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'notificationId',
            key: 'notificationId',
            render: (text, record) => (
                <>
                    {record.status === 'Open' || record.status === 'Sending' ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteNotification(record.notificationId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                    marginRight: 10,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                    <Button
                        type="link"
                        onClick={() => copyVariablesAndShowAddModal(record)}
                    >
                        <CopyOutlined />
                    </Button>
                </>
            ),
        },
    ]

    const copyVariablesAndShowAddModal = (record) => {
        form.resetFields()
        updateVariableDataInState(record.variablesData)
        setAddUpdateModalVisible(true)
        setSelectedRecord({})
        setTimeout(() => {
            form.setFieldsValue({ templateName: record.templateName })
        }, 100)
    }

    const variablesDataTableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddVariableDataModalVisible(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Variable #',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteVariableData(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const variablesDataViewTableColumns = [
        {
            title: 'Variable #',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
    ]

    const reorderVariablesDataTableColumns = [
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
        },
    ]

    const deleteNotification = (notificationId) => {
        setLoading(true)

        var payload = {}
        payload.notificationId = notificationId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.NOTIFICATION.DELETE_WHATSAPP_NOTIFICATION,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getNotications()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onChangeFilter = (event) => {
        setFilter(event.target.value)
    }

    const showAddUpdateModal = (record) => {
        form.resetFields()
        setAddUpdateModalVisible(true)
        setSelectedRecord(record)
        setFileList([])
        if (record.notificationId) {
            setUsersFilterType(record.usersFilterType)
            if (record.scheduledTime) {
                setScheduledTime(record.scheduledTime)
            }

            setLastLoginStartDate(record.lastLoginStartdateString)
            updateVariableDataInState(record.variablesData)

            var recordClone = clone(record)
            if (record.scheduledTime) {
                recordClone.scheduledTime = dayjs(
                    record.scheduledTime,
                    'DD-MMM-YYYY HH:mm'
                )
            }

            if (record.lastLoginStartdateString) {
                recordClone.last_login_start_date = dayjs(
                    record.lastLoginStartdateString,
                    'DD-MMM-YYYY'
                )
            }

            recordClone.logins_count = record.loginsCount

            setTimeout(() => {
                form.setFieldsValue(recordClone)
            }, 100)
        } else {
            setUsersFilterType(USERS_FILTER_TYPE_FILTERS)
            setVariablesData([])
            setScheduledTime()
        }

        if (whatsappTemplateNames.length === 0) {
            getWhatsAppTemplateNames()
        }
    }

    const getWhatsAppTemplateNames = () => {
        setWhatsappTemplateNamesLoading(true)
        RestApi.doGet(GLOBAL.URL.NOTIFICATION.GET_WHATSAPP_TEMPLATE_NAMES)
            .then((res) => {
                setWhatsappTemplateNames(res.data)
            })
            .finally(() => setWhatsappTemplateNamesLoading(false))
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const whatsappTemplateOptions = () => {
        var options = []
        whatsappTemplateNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const onChangeScheduleDate = (value, dateString) => {
        setScheduledTime(dateString)
    }

    const onChangeLastLoginstartDate = (value, dateString) => {
        setLastLoginStartDate(dateString)
    }

    const showAddVariableDataModalVisible = (record = {}) => {
        setAddVariableDataModalVisible(true)
        setSelectedVariableData(record)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue({
                    dataValue: record.data,
                })
            }, 100)
        }
        if (userPlaceHolders.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_USER_PLACE_HOLDERS).then((res) => {
                setUserPlaceHolders(res.data)
            })
        }
    }

    const hideAddVariableDataModalVisible = () => {
        setAddVariableDataModalVisible(false)
    }

    const addOrUpdateVariableData = () => {
        form.validateFields(['dataValue'])
            .then((values) => {
                var variablesDataClone = clone(variablesData)
                if (selectedVariableData.id) {
                    variablesDataClone.forEach((item) => {
                        if (item.id === selectedVariableData.id) {
                            item.data = values.dataValue
                        }
                    })
                } else {
                    var varData = {}
                    varData.id = uuidv4()
                    varData.data = values.dataValue

                    variablesDataClone.push(varData)
                }

                setVariablesData(variablesDataClone)
                hideAddVariableDataModalVisible()
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteVariableData = (id) => {
        setVariablesData(variablesData.filter((item) => item.id !== id))
    }

    const showReorderVariableDataModal = () => {
        setReorderVariableDataModalVisible(true)
    }

    const hideReorderVariableDataModal = () => {
        setReorderVariableDataModalVisible(false)
    }

    const saveReorderVariablesData = (data) => {
        setVariablesData(data)
        hideReorderVariableDataModal()
    }

    const showFiltersModal = (record) => {
        updateVariableDataInState(record.variablesData)
        setFiltersModalVisible(true)
        setSelectedRecord(record)
    }

    const updateVariableDataInState = (variablesDataObj = []) => {
        var obj = []
        variablesDataObj.forEach((item) => {
            var varObj = {}
            varObj.id = uuidv4()
            varObj.data = item

            obj.push(varObj)
        })

        setVariablesData(obj)
    }

    const hideFiltersModal = () => {
        setFiltersModalVisible(false)
    }

    const getApproxUsersCount = () => {
        form.validateFields(['last_login_start_date', 'logins_count'])
            .then((values) => {
                setLoadingApproxUsersCount(true)

                var variablesDataObj = []
                variablesData.forEach((item) => {
                    variablesDataObj.push(item.data)
                })

                values.variablesData = variablesDataObj
                values.last_login_start_date = lastLoginStartDate

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.NOTIFICATION.GET_USERS_NOTIFICATION_COUNT,
                    body
                )
                    .then((res) => {
                        setApproxUsersCount(res.data)
                    })
                    .finally(() => {
                        setLoadingApproxUsersCount(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const sendTestNotification = () => {
        form.validateFields(['templateName', 'imageUrl'])
            .then((values) => {
                setTestMessageLoading(true)

                var variablesDataObj = []
                variablesData.forEach((item) => {
                    variablesDataObj.push(item.data)
                })

                values.variablesData = variablesDataObj

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.NOTIFICATION.TEST_WHATSAPP_NOTIFICATION,
                    body
                )
                    .then((res) => {
                        message.success(res.data)
                        setTestErrorMessage('')
                    })
                    .catch((error) => {
                        setTestErrorMessage(error.data)
                    })
                    .finally(() => {
                        setTestMessageLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const sendTestNotificationByNotificationId = (notificationId) => {
        setTestMessageLoading(true)

        var payload = {}
        payload.notificationId = notificationId

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.NOTIFICATION.TEST_WHATSAPP_NOTIFICATION, body)
            .then((res) => {
                message.success(res.data)
            })
            .finally(() => {
                setTestMessageLoading(false)
            })
    }

    const addOrUpdateNotification = () => {
        form.validateFields([
            'title',
            'templateName',
            'last_login_start_date',
            'logins_count',
            'imageUrl',
            'pickVariablesDataFromFile',
        ])
            .then((values) => {
                if (
                    !selectedRecord.notificationId &&
                    usersFilterType === USERS_FILTER_TYPE_FILE_UPLOAD &&
                    fileList.length === 0
                ) {
                    message.error('Select file to upload!')
                    return
                }

                setLoading(true)

                var variablesDataObj = []
                variablesData.forEach((item) => {
                    variablesDataObj.push(item.data)
                })

                values.scheduledTime = scheduledTime
                values.last_login_start_date = lastLoginStartDate
                values.variablesData = variablesDataObj
                values.usersFilterType = usersFilterType

                var url = GLOBAL.URL.NOTIFICATION.ADD_WHATSAPP_NOTIFICATION
                if (selectedRecord.notificationId) {
                    url = GLOBAL.URL.NOTIFICATION.UPDATE_WHATSAPP_NOTIFICATION
                    values.notificationId = selectedRecord.notificationId
                }

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                if (usersFilterType === USERS_FILTER_TYPE_FILE_UPLOAD) {
                    body.append('file', fileList[0])
                }

                RestApi.doPost(url, body)
                    .then((response) => {
                        message.success(response.data)
                        hideAddUpdateModal()
                        getNotications()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getPlaceHolders = () => {
        var res = []
        userPlaceHolders.forEach((userPlaceHolder) => {
            res.push(<Tag>{userPlaceHolder}</Tag>)
        })

        return res
    }

    const onChangeUsersFilterType = (event) => {
        setUsersFilterType(event.target.value)
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file)
            const newFileList = fileList.slice()
            newFileList.splice(index, 1)
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    const showWhatsAppTemplatesModal = () => {
        setWhatsAppTemplatesModalVisible(true)
    }

    const hideWhatsAppTemplatesModal = () => {
        setWhatsAppTemplatesModalVisible(false)
    }

    return (
        <div>
            <div>
                <Button type="primary" onClick={showAddUpdateModal}>
                    Create
                </Button>
                <Radio.Group
                    onChange={onChangeFilter}
                    defaultValue={filter}
                    style={{ marginLeft: 20 }}
                >
                    <Radio.Button value={''}>All</Radio.Button>
                    <Radio.Button value={'Open'}>Open</Radio.Button>
                </Radio.Group>

                <span>
                    <Button type="link" onClick={getNotications}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                </span>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="notificationId"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageNotifications}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure WhatsApp Notification'}
                open={addUpdateModalVisible}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                footer={null}
                destroyOnClose={true}
                width={1000}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        style={{ marginBottom: 5 }}
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="Template"
                        style={{ marginBottom: 5 }}
                        name="templateName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select template"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                        >
                            {whatsappTemplateOptions()}
                        </Select>
                    </Form.Item>
                    <div style={{ marginBottom: 5 }}>
                        <Button
                            type="default"
                            size="small"
                            onClick={getWhatsAppTemplateNames}
                            loading={whatsappTemplateNamesLoading}
                            style={{ marginLeft: 158 }}
                        >
                            Refresh Templates
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={showWhatsAppTemplatesModal}
                            loading={whatsappTemplateNamesLoading}
                            style={{ marginLeft: 10 }}
                        >
                            Configure
                        </Button>
                    </div>
                    <Form.Item
                        label="Image URL"
                        style={{ marginBottom: 5 }}
                        name="imageUrl"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Schedule"
                        style={{
                            marginBottom: 5,
                            lineHeight: 0,
                        }}
                        name="scheduledTime"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            showTime
                            placeholder="Select Date &amp; Time"
                            format="DD-MMM-YYYY HH:mm"
                            onChange={onChangeScheduleDate}
                        />
                    </Form.Item>

                    <Radio.Group
                        onChange={onChangeUsersFilterType}
                        defaultValue={usersFilterType}
                        style={{ marginBottom: 10, marginLeft: 158 }}
                    >
                        <Radio.Button value={USERS_FILTER_TYPE_FILTERS}>
                            Filters
                        </Radio.Button>
                        <Radio.Button value={USERS_FILTER_TYPE_FILE_UPLOAD}>
                            File Upload
                        </Radio.Button>
                    </Radio.Group>

                    {usersFilterType === USERS_FILTER_TYPE_FILTERS ? (
                        <>
                            <Form.Item
                                label="Last Login Start Date"
                                style={{ marginBottom: 5 }}
                                name="last_login_start_date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    placeholder="Select Date"
                                    format="DD-MMM-YYYY"
                                    onChange={onChangeLastLoginstartDate}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Logins Count"
                                style={{ marginBottom: 5 }}
                                name="logins_count"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                            <div style={{ marginTop: 10 }}>
                                <label
                                    style={{ marginLeft: 13, color: 'black' }}
                                >
                                    {'Approx Users Count :'}
                                </label>
                                <label
                                    style={{
                                        marginLeft: 8,
                                        color: 'black',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {approxUsersCount}
                                </label>
                                <span>
                                    <Button
                                        type="link"
                                        onClick={getApproxUsersCount}
                                    >
                                        {loadingApproxUsersCount ? (
                                            <LoadingOutlined
                                                style={{ fontSize: 15 }}
                                            />
                                        ) : (
                                            <ReloadOutlined
                                                style={{ fontSize: 15 }}
                                            />
                                        )}
                                    </Button>
                                </span>
                            </div>
                        </>
                    ) : (
                        <>
                            <Paper
                                elevation={2}
                                style={{ padding: 10, marginTop: 10 }}
                            >
                                <ul>
                                    <li>
                                        {'Have only 1 sheet in the excel file'}
                                    </li>
                                    <li>
                                        {
                                            'In the first column just have the list of phone numbers without any title in first row'
                                        }
                                        <br />
                                        {'(Do not have formulae)'}
                                    </li>
                                </ul>
                                <Dragger {...uploadProps}>
                                    <Button>
                                        <UploadOutlined />
                                        {
                                            'Click or Drag file to this area to add attachment'
                                        }
                                    </Button>
                                </Dragger>
                                <Form.Item
                                    label="Variables From File"
                                    style={{ marginTop: 10 }}
                                    name="pickVariablesDataFromFile"
                                >
                                    <Checkbox></Checkbox>
                                </Form.Item>
                            </Paper>
                        </>
                    )}
                    <div style={{ marginTop: 10 }}>
                        <div>
                            <label style={{ color: 'black' }}>
                                {'Variables Data'}
                            </label>
                            <Button
                                type="primary"
                                size="small"
                                style={{ marginLeft: 10 }}
                                onClick={showAddVariableDataModalVisible}
                            >
                                Add
                            </Button>
                            <Button
                                onClick={showReorderVariableDataModal}
                                type="primary"
                                size="small"
                                style={{ marginLeft: 10 }}
                            >
                                Reorder
                            </Button>
                        </div>
                        <Table
                            columns={variablesDataTableColumns}
                            dataSource={variablesData}
                            pagination={false}
                            size="small"
                            loading={loading}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                        />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={sendTestNotification}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="default"
                                size="small"
                                loading={testMessageLoading}
                            >
                                Send Test Message
                            </Button>
                        </Popconfirm>
                        {testErrorMessage ? (
                            <>
                                <br />
                                <span>{testErrorMessage}</span>
                            </>
                        ) : null}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={addOrUpdateNotification}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" loading={loading}>
                                {selectedRecord.notificationId
                                    ? 'Update'
                                    : 'Add'}{' '}
                                {' Notification'}
                            </Button>
                        </Popconfirm>
                        <Button
                            type="default"
                            loading={loading}
                            style={{ marginLeft: 10 }}
                            onClick={hideAddUpdateModal}
                        >
                            {'Cancel'}
                        </Button>
                    </div>
                </Form>
            </Modal>
            <Modal
                title={'Configure Variable Data'}
                open={addVariableDataModalVisible}
                onOk={addOrUpdateVariableData}
                onCancel={hideAddVariableDataModalVisible}
                okText={selectedVariableData.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={600}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Data"
                        style={{ marginBottom: 5 }}
                        name="dataValue"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                </Form>
                <span style={{ fontSize: 12, marginTop: 20 }}>
                    {'Place holders:  '}
                    <br />
                    {getPlaceHolders()}
                </span>
            </Modal>
            <Modal
                title={'Reorder Variables Data'}
                open={reorderVariableDataModalVisible}
                width={600}
                onCancel={hideReorderVariableDataModal}
                destroyOnClose={true}
                footer={null}
            >
                <ReorderTable
                    reorderData={variablesData}
                    columns={reorderVariablesDataTableColumns}
                    onOk={saveReorderVariablesData}
                />
            </Modal>
            <Modal
                title={'Filters'}
                open={filtersModalVisible}
                width={800}
                onCancel={hideFiltersModal}
                destroyOnClose={true}
                footer={null}
            >
                <h4>{selectedRecord.title}</h4>
                <table className={classes.table}>
                    <thead>
                        <tr>
                            <th className={classes.th}>{'#'}</th>
                            <th className={classes.th}>{'Filter'}</th>
                            <th className={classes.th}>{'Value'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={classes.td}>{'1'}</td>
                            <td className={classes.td}>
                                {'Last Login Start Date'}
                            </td>
                            <td className={cx(classes.td)}>
                                {selectedRecord.lastLoginStartdateString}
                            </td>
                        </tr>
                        <tr>
                            <td className={classes.td}>{'2'}</td>
                            <td className={classes.td}>{'Logins Count'}</td>
                            <td className={cx(classes.td)}>
                                {selectedRecord.loginsCount}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Table
                    columns={variablesDataViewTableColumns}
                    dataSource={variablesData}
                    pagination={false}
                    size="small"
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            {whatsAppTemplatesModalVisible ? (
                <WhatsAppTemplates hideModal={hideWhatsAppTemplatesModal} />
            ) : null}
        </div>
    )
}

const useStyles = makeStyles()((theme) => ({
    table: {
        border: '1px solid #e8e8e8',
        borderCollapse: 'collapse',
    },
    th: {
        padding: 10,
        border: '1px solid #e8e8e8',
        borderCollapse: 'collapse',
    },
    td: {
        padding: 10,
        border: '1px solid #e8e8e8',
        borderCollapse: 'collapse',
    },
    textAlignRight: {
        textAlign: 'right',
    },
}))

export default WhatsAppNotifications
