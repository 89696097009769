import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { DatePicker } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

function ForumAdminResponseStats(props) {
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const getData = (value, dateString) => {
        var startDate = dateString[0]
        var endDate = dateString[1]

        if (!startDate) {
            return
        }

        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.FORUMS.GET_ADMIN_RESPONSES_STATS +
                '?startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    return (
        <div>
            <h2>Forums Response Stats</h2>
            <div style={{ marginBottom: 10 }}>
                <span>Date Range: </span>
                <RangePicker
                    format="DD-MMM-YYYY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={getData}
                    style={{ marginLeft: 10 }}
                />
                {loading ? (
                    <LoadingOutlined style={{ marginLeft: 10, fontSize: 20 }} />
                ) : null}
            </div>
            <div
                style={{ marginTop: 10 }}
                dangerouslySetInnerHTML={createMarkupWeb(data)}
            />
        </div>
    )
}

export default ForumAdminResponseStats
