import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useStyles } from './UserWatchTimeAnalyticsByMonth'
import clsx from 'clsx'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

export const UserWatchTimeAnalyticsByDay = ({
    id,
    data,
    options,
    selectedMonthLabel,
}) => {
    const { classes } = useStyles()
    return Object.keys(data).length > 0 ? (
        <Grid
            id={id}
            className={classes.byDayBarContainer}
            style={{ marginTop: 30 }}
        >
            <Typography
                varaitn="h2"
                className={clsx(classes.heading, classes.analyticsByDayHeading)}
            >
                Watch Time Analytics By Day
                {` (${selectedMonthLabel})`}
            </Typography>
            <Grid className={classes.scrollableWindow}>
                <Grid className={classes.barContainer}>
                    <Bar data={data} options={options} />
                </Grid>
            </Grid>
        </Grid>
    ) : null
}
