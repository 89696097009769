import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    notification,
    DatePicker,
    Select,
    Radio,
    Checkbox,
    Form,
} from 'antd'
import { Utils } from '../JS/Utils'

function SalesReport() {
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker
    const { Option } = Select

    const dateFormat = 'DD-MMM-YYYY'

    const [loading, setLoading] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedStreamCourse, setSelectedStreamCourse] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [couponData, setCouponData] = useState([])
    const [adminUsers, setAdminUsers] = useState([])
    const [message, setMessage] = useState()
    const [report, setReport] = useState()
    const [teamNames, setTeamNames] = useState([])
    const [selectedTeamName, setSelectedTeamName] = useState('')

    useEffect(() => {
        getCourseAndLevel()
        getCouponsData()
        getTeamNames()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCartAdminUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeamName])

    const getCourseAndLevel = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE)
            .then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
                setLevelData(streamData[0].levels)
            })
            .catch((error) => {
                notification['error']({
                    message: error,
                })
            })
    }

    const getCouponsData = () => {
        if (!Utils.isUserAdmin()) {
            return
        }

        RestApi.doGet(GLOBAL.URL.GET_ALL_COUPONS + '?partner=0')
            .then((res) => {
                setCouponData(res.data)
            })
            .catch((error) => {
                notification['error']({
                    message: error,
                })
            })
    }

    const getCartAdminUsers = () => {
        RestApi.doGet(
            GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS +
                '?teamName=' +
                selectedTeamName
        ).then((res) => {
            setAdminUsers(res.data)
        })
    }

    const getTeamNames = () => {
        RestApi.doGet(GLOBAL.URL.SALES.GET_EXTERNAL_SALES_COMPANY_NAMES).then(
            (res) => {
                setTeamNames(res.data)
            }
        )
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getCouponOptions = () => {
        var options = []
        couponData.forEach((coupon) => {
            options.push(
                <Option value={coupon.couponName} key={coupon.couponName}>
                    {coupon.couponName}
                </Option>
            )
        })

        return options
    }

    const onChangeStreamCourse = (event) => {
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                setSelectedStreamCourse(event.target.value)

                return
            }
        })
    }

    const getStreamCourseOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const getTeamOptions = () => {
        var options = []
        teamNames.forEach((teamName, index) => {
            options.push(
                <Option value={teamName} key={index}>
                    {teamName}
                </Option>
            )
        })

        return options
    }

    const generateReport = (isSendEmail) => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start date needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End date needed!',
                    })

                    return
                }

                setMessage()
                setReport()
                setLoading(true)

                values.startDate = startDate
                values.endDate = endDate

                var url = GLOBAL.URL.GENERATE_COUPON_WISE_SALES_REPORT
                if (isSendEmail) {
                    url = GLOBAL.URL.DOWNLOAD_COUPON_WISE_SALES_REPORT
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        if (isSendEmail) {
                            setMessage(response.data)
                        } else {
                            setReport(response.data)
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            <h2>Generate Sales Report</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Date Range"
                    style={{ marginBottom: 5 }}
                    name="startDate"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateChange}
                    />
                </Form.Item>
                {Utils.isUserAdmin() ? (
                    <Form.Item
                        label="Coupons"
                        style={{ marginBottom: 5 }}
                        name="couponNames"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Coupons"
                            showSearch
                            allowClear={true}
                            mode="multiple"
                        >
                            {getCouponOptions()}
                        </Select>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label="Stream"
                    style={{ marginBottom: 5 }}
                    name="stream"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeStreamCourse}>
                        {getStreamCourseOptions()}
                    </Radio.Group>
                </Form.Item>
                {selectedStreamCourse ? (
                    <Form.Item
                        label="Level"
                        style={{ marginBottom: 5 }}
                        name="level"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{getStreamLevelOptions()}</Radio.Group>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label="Team"
                    style={{ marginBottom: 5 }}
                    name="teamName"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) =>
                            setSelectedTeamName(value ? value : '')
                        }
                    >
                        {getTeamOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Users"
                    style={{ marginBottom: 5 }}
                    name="salesPersonIds"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select"
                        showSearch
                        mode="multiple"
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getAdminUserOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="First Interaction?"
                    style={{ marginBottom: 5 }}
                    name="isFirstInteraction"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Checkbox />
                </Form.Item>
            </Form>
            <div style={{ marginTop: 20 }}>
                <Button
                    type="primary"
                    size="small"
                    loading={loading}
                    onClick={() => generateReport(false)}
                >
                    Show Here
                </Button>
                <Button
                    type="default"
                    size="small"
                    loading={loading}
                    onClick={() => generateReport(true)}
                    style={{ marginLeft: 20 }}
                >
                    Generate &amp; Send Email
                </Button>
            </div>
            {message ? <h3 style={{ marginTop: 20 }}>{message}</h3> : null}
            {report ? (
                <div
                    style={{ marginTop: 20 }}
                    dangerouslySetInnerHTML={{ __html: report }}
                />
            ) : null}
        </div>
    )
}

export default SalesReport
