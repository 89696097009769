import React, { useEffect, useState } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import { Grid, Typography, Button, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
    CheckBoxOutlineBlankRounded,
    CheckBoxRounded,
} from '@mui/icons-material'
import { notification } from 'antd'

function UserAlertsSubscription(props) {
    const { classes, cx } = useStyles()

    const [subscriptionList, setSubscriptionList] = useState([])
    const [overAllModes, setOverAllModes] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_UESR_ALERTS_SUBSCRIPTION + props.userId)
            .then((response) => {
                setSubscriptionList(response.data.subscription)
                setOverAllModes(response.data.modes)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onClickSave = () => {
        setLoading(true)

        let payload = {}
        payload.userId = props.userId

        payload.subscription = []
        subscriptionList.forEach((item) => {
            payload.subscription.push({
                key: item.key,
                modes: item.modes,
            })
        })

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.UPDATE_UESR_ALERTS_SUBSCRIPTION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => setLoading(false))
    }

    const enableDisableAll = (enableAll) => {
        setLoading(true)

        let payload = {}
        payload.userId = props.userId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.USER_SUBSCRIPTION_ENABLE_ALL
        if (!enableAll) {
            url = GLOBAL.URL.USER_SUBSCRIPTION_DISABLE_ALL
        }

        RestApi.doPost(url, formData)
            .then((response) => {
                getData()
                notification['success']({
                    message: response.data,
                })
            })
            .catch((error) => setLoading(false))
    }

    const onClickSubscribe = (subKey, modeKey, isSubscribed) => {
        let updatedSubscribeList = subscriptionList
        subscriptionList.forEach((item) => {
            if (item.key === subKey) {
                item.modes.forEach((mode) => {
                    if (mode.key === modeKey) {
                        mode.subscribed = isSubscribed
                    }
                })
            }
        })
        setSubscriptionList([...updatedSubscribeList])
    }

    const SubscriptionStatus = ({ modeKey, data, subscriptionKey }) => {
        let renderItem = 'empty'

        data.forEach((item, index) => {
            let isFound = item.key === modeKey
            let isChecked = item.subscribed
            if (isFound) {
                renderItem = isChecked
            }
        })

        return (
            <Grid className={cx(classes.tableCell, classes.centerAlign)}>
                {renderItem === 'empty' ? (
                    <></>
                ) : renderItem ? (
                    <CheckBoxRounded
                        className={cx(classes.circle, classes.checkCircle)}
                        onClick={() =>
                            onClickSubscribe(subscriptionKey, modeKey, false)
                        }
                    />
                ) : (
                    <CheckBoxOutlineBlankRounded
                        className={classes.circle}
                        onClick={() =>
                            onClickSubscribe(subscriptionKey, modeKey, true)
                        }
                    />
                )}
            </Grid>
        )
    }

    const SubscriptionTableHeader = () => {
        return (
            <Grid className={classes.row}>
                <Grid
                    className={cx(
                        classes.tableCell,
                        classes.headerContainer,
                        classes.titleWidth
                    )}
                />
                {overAllModes.map((item, index) => (
                    <Grid
                        className={cx(
                            classes.tableCell,
                            classes.headerContainer,
                            classes.centerAlign
                        )}
                        key={index}
                    >
                        <Typography
                            className={cx(classes.titleText, classes.boldText)}
                        >
                            {item.title}
                        </Typography>
                    </Grid>
                ))}
            </Grid>
        )
    }

    const SubscriptionTableBody = () => {
        return subscriptionList.map((item, subIndex) => (
            <Grid className={classes.row} key={subIndex}>
                <Grid
                    className={cx(classes.tableCell, classes.titleWidth)}
                    key={Math.random()}
                >
                    <Typography className={classes.titleText}>
                        {item.title}
                    </Typography>
                </Grid>
                {overAllModes.map((mode, index) => (
                    <SubscriptionStatus
                        data={item.modes}
                        subscriptionKey={item.key}
                        modeKey={mode.key}
                        key={index}
                    />
                ))}
            </Grid>
        ))
    }

    return (
        <Grid>
            <Grid className={classes.wrapper}>
                <Grid className={classes.rightContentWrapper} item xs={12}>
                    <Grid className={classes.middleContainer}>
                        {overAllModes.length ? (
                            <Grid className={classes.container}>
                                <Grid className={classes.tableContainer}>
                                    <SubscriptionTableHeader />
                                    <SubscriptionTableBody />
                                </Grid>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    className={classes.saveButton}
                                    style={{ marginLeft: 10 }}
                                    disabled={loading}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={14} />
                                        ) : null
                                    }
                                    onClick={() => enableDisableAll(true)}
                                >
                                    Enable All
                                </Button>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    className={classes.saveButton}
                                    style={{ marginLeft: 10 }}
                                    disabled={loading}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={14} />
                                        ) : null
                                    }
                                    onClick={() => enableDisableAll(false)}
                                >
                                    Disable All
                                </Button>
                                <Button
                                    color="primary"
                                    size="medium"
                                    variant="contained"
                                    className={classes.saveButton}
                                    onClick={onClickSave}
                                    disabled={loading}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={14} />
                                        ) : null
                                    }
                                >
                                    Save
                                </Button>
                            </Grid>
                        ) : null}
                        <Grid className={classes.feedsWrapper}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles()((theme) => ({
    tableContainer: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        width: 'fit-content',
        borderRadius: 4,
    },
    tableCell: {
        padding: '5px 10px',
        borderTop: '1px solid #e0e0e0',
        borderLeft: '1px solid #e0e0e0',
        minHeight: 50,
        minWidth: 100,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            minWidth: 74,
            padding: '5px 7px',
        },
    },
    titleText: {
        fontSize: 14,
    },
    row: {
        display: 'flex',
        flexFlow: 'row',
    },
    hideBorder: {
        borderTop: 'none',
    },
    centerAlign: {
        justifyContent: 'center',
    },
    wrapper: {
        display: 'flex',
        flexFlow: 'row',
        [theme.breakpoints.down('md')]: { flexFlow: 'column' },
    },
    feedsWrapper: {
        width: '30%',
    },
    middleContainer: {
        width: '70%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    rightContentWrapper: {
        display: 'flex',
        flexFlow: 'column',
        [theme.breakpoints.down('md')]: {
            width: 'unset',
        },
    },
    headerContainer: {
        background: '#eff5f5',
        borderTop: 'none',
    },
    boldText: {
        fontWeight: 'bold',
        color: '#2a4d5d',
    },
    circle: {
        color: 'gray',
        cursor: 'pointer',
    },
    checkCircle: {
        color: '#61aa30',
    },
    saveButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        float: 'right',
        marginTop: 20,
    },
    container: {
        padding: 20,
        width: 'fit-content',
    },
    titleWidth: {
        minWidth: 200,
        borderLeft: 'none',
        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%',
        },
    },
    defaultTextCss: {
        fontWeight: 'bold',
        fontSize: 20,
        marginTop: 20,
        textAlign: 'center',
    },
    loginButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 20,
    },
    defaultView: {
        width: 'unset',
        textAlign: 'center',
    },
}))

export default UserAlertsSubscription
