import React from 'react'
import { Box, Typography } from '@mui/material'
import { searchStyles } from './styles'

const EmptyHistoryMessage = () => {
    const { classes } = searchStyles()
    return (
        <Box className={classes.noHistoryContainer}>
            <Typography>You have no recent search history</Typography>
        </Box>
    )
}

export default EmptyHistoryMessage
