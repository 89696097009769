import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, message, Modal, Input, Tag, Switch } from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    DeleteTwoTone,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
} from '@ant-design/icons'
import clone from 'clone'
import { useParams } from 'react-router-dom'
import { goBack } from '../../Util/ILUtil'

function CouponCourseMapping({ navigate, location }) {
    const [id, setId] = useState('')
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [courseNames, setCourseNames] = useState([])
    const [filteredCourseNames, setFilteredCourseNames] = useState([])
    const [selectedCourseNames, setSelectedCourseNames] = useState([])
    const [showSelectCoursesModal, setShowSelectCoursesModal] = useState(false)
    const [filter, setFilter] = useState('')
    const [attemptFilter, setAttemptFilter] = useState('')
    const [couponEnabled, setCouponEnabled] = useState(true)
    const [attemptsEnabled, setAttemptsEnabled] = useState(true)
    const [attempts, setAttempts] = useState([])
    const [showSelectAttemptsModal, setShowSelectAttemptsModal] =
        useState(false)
    const [couponName, setCouponName] = useState('')
    const [attemptNames, setAttemptNames] = useState([])
    const [filteredAttemptNames, setFilteredAttemptNames] = useState([])
    const [selectedAttemptNames, setSelectedAttemptNames] = useState([])
    const { id: mappingId } = useParams()

    useEffect(() => {
        setId(mappingId)
        setCouponName(location.state.couponName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!id) {
            return
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        console.log(selectedCourseNames)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCourseNames])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_COUPON_COURSE_MAPPING + id)
            .then((res) => {
                var data = JSON.parse(res.data)
                var couponEnabled1 = true
                if (data.courses.length > 0) {
                    couponEnabled1 = data.courses[0].enabled
                }

                var attemptsEnabled1 = true
                if (data.attempts.length > 0) {
                    attemptsEnabled1 = data.attempts[0].enabled
                }

                setData(data.courses)
                setCouponEnabled(couponEnabled1)
                setAttempts(data.attempts)
                setAttemptsEnabled(attemptsEnabled1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const removeCourse = (courseName) => {
        var data1 = clone(data)
        for (var i = 0; i < data1.length; i++) {
            if (data1[i].courseName === courseName) {
                data1.splice(i, 1)
                setData(data1)
            }
        }
    }

    const removeAttempt = (name) => {
        var attempts1 = clone(attempts)
        for (var i = 0; i < attempts1.length; i++) {
            if (attempts1[i].name === name) {
                attempts1.splice(i, 1)
                setAttempts(attempts1)
                break
            }
        }
    }

    const updateMappings = () => {
        setLoading(true)

        var data1 = data
        data1.forEach((item) => {
            item.enabled = couponEnabled
        })

        var attempts1 = attempts
        attempts1.forEach((item) => {
            item.enabled = attemptsEnabled
        })

        var payload = {}
        payload.id = id
        payload.courseMappings = data1
        payload.attempts = attempts1

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_COUPON_COURSE_MAPPING, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCoursesListAndShowSelectCoursesModal = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES)
            .then((res) => {
                var courseNames1 = []
                res.data.forEach((courseName) => {
                    if (!checkForCourseInData(courseName)) {
                        var courseObj = {}
                        courseObj['courseName'] = courseName
                        courseNames1.push(courseObj)
                    }
                })

                setCourseNames(courseNames1)
                setFilteredCourseNames(courseNames1)
                setSelectedCourseNames([])
                setShowSelectCoursesModal(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const checkForCourseInData = (courseName) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].courseName === courseName) {
                return true
            }
        }

        return false
    }

    const getAttemptsListAdShowSelectAttemptsMpdal = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS)
            .then((res) => {
                var attemptNames1 = []
                res.data.forEach((item) => {
                    var attemptName = item.attempt
                    if (!checkForAttemptInAttempts(attemptName)) {
                        var attemptObj = {}
                        attemptObj['attempt'] = attemptName
                        attemptNames1.push(attemptObj)
                    }
                })

                setAttemptNames(attemptNames1)
                setFilteredAttemptNames(attemptNames1)
                setSelectedAttemptNames([])
                setShowSelectAttemptsModal(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const checkForAttemptInAttempts = (attemptName) => {
        for (var i = 0; i < attempts.length; i++) {
            if (attempts[i].attempt === attemptName) {
                return true
            }
        }

        return false
    }

    const filterCourses = (filter) => {
        var filteredCourseNames1 = courseNames.filter((course) =>
            course.courseName.toLowerCase().includes(filter.toLowerCase())
        )

        setFilteredCourseNames(filteredCourseNames1)
        setFilter(filter)
    }

    const filterAttempts = (filter) => {
        var filteredAttemptNames1 = attemptNames.filter((attempt) =>
            attempt.attempt.toLowerCase().includes(filter.toLowerCase())
        )

        setFilteredAttemptNames(filteredAttemptNames1)
        setAttemptFilter(filter)
    }

    const hideSelectCoursesModal = () => {
        setShowSelectCoursesModal(false)
    }

    const hideSelectAttemptsModal = () => {
        setShowSelectAttemptsModal(false)
    }

    const addToSelectedCourse = (courseName) => {
        var courseObj = {}
        courseObj['courseName'] = courseName
        courseObj['enabled'] = couponEnabled

        var selectedCourseNames1 = clone(selectedCourseNames)
        selectedCourseNames1.push(courseObj)
        setSelectedCourseNames(selectedCourseNames1)

        var courseNames1 = courseNames
        for (var i = 0; i < courseNames1.length; i++) {
            if (courseNames1[i].courseName === courseName) {
                courseNames1.splice(i, 1)
                break
            }
        }

        setCourseNames(courseNames1)
        filterCourses(filter)
    }

    const addToSelectedAttempt = (name) => {
        var attemptObj = {}
        attemptObj['attempt'] = name
        attemptObj['enabled'] = attemptsEnabled

        var selectedAttemptNames1 = clone(selectedAttemptNames)
        selectedAttemptNames1.push(attemptObj)

        setSelectedAttemptNames(selectedAttemptNames1)

        var attemptNames1 = attemptNames
        for (var i = 0; i < attemptNames1.length; i++) {
            if (attemptNames1[i].attempt === name) {
                attemptNames1.splice(i, 1)
                break
            }
        }

        setAttemptNames(attemptNames1)
        filterAttempts(attemptFilter)
    }

    const removeSelectedCourse = (courseObj) => {
        var selectedCourseNames1 = clone(selectedCourseNames)
        for (var i = 0; i < selectedCourseNames1.length; i++) {
            if (selectedCourseNames1[i].courseName === courseObj.courseName) {
                selectedCourseNames1.splice(i, 1)
                setSelectedCourseNames(selectedCourseNames1)
                break
            }
        }

        var courseNames1 = clone(courseNames)
        courseNames1.push(courseObj)

        setSelectedCourseNames(selectedCourseNames1)
        setCourseNames(courseNames1)

        filterCourses(filter)
    }

    const removeSelectedAttempt = (attemptObj) => {
        var selectedAttemptNames1 = clone(selectedAttemptNames)
        for (var i = 0; i < selectedAttemptNames1.length; i++) {
            if (selectedAttemptNames1[i].attempt === attemptObj.attempt) {
                selectedAttemptNames1.splice(i, 1)
                break
            }
        }

        var attemptNames1 = clone(attemptNames)
        attemptNames1.push(attemptObj)

        setSelectedAttemptNames(selectedAttemptNames1)
        setAttemptNames(attemptNames1)

        filterAttempts(attemptFilter)
    }

    const onOkSelectCourses = () => {
        var data1 = clone(data)
        data1 = data1.concat(selectedCourseNames)
        setData(data1)
        hideSelectCoursesModal()
    }

    const onOkSelectAttempts = () => {
        var attempts1 = clone(attempts)
        attempts1 = attempts1.concat(selectedAttemptNames)
        setAttempts(attempts1)
        hideSelectAttemptsModal()
    }

    const onEnabledSelection = (enabled) => {
        setCouponEnabled(enabled)
    }

    const onEnabledAttemptsSelection = (enabled) => {
        setAttemptsEnabled(enabled)
    }

    const columns = [
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        removeCourse(record.courseName)
                    }}
                >
                    <DeleteTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 20 }}
                    />
                </Button>
            ),
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const attemptsColumns = [
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        removeAttempt(record.name)
                    }}
                >
                    <DeleteTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 20 }}
                    />
                </Button>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    const courseSearchColums = [
        {
            title: 'Add',
            dataIndex: 'add',
            key: 'add',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        addToSelectedCourse(record.courseName)
                    }}
                >
                    <PlusCircleTwoTone style={{ fontSize: 20 }} />
                </Button>
            ),
        },
        {
            title: 'Avaliable Courses',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const selectedCoursesColums = [
        {
            title: 'Remove',
            dataIndex: 'remove',
            key: 'remove',
            width: 100,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        removeSelectedCourse(record)
                    }}
                >
                    <MinusCircleTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 20 }}
                    />
                </Button>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const attemptSearchColums = [
        {
            title: 'Add',
            dataIndex: 'add',
            key: 'add',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        addToSelectedAttempt(record.attempt)
                    }}
                >
                    <PlusCircleTwoTone style={{ fontSize: 20 }} />
                </Button>
            ),
        },
        {
            title: 'Avaliable Attempts',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    const selectedAttemptsColums = [
        {
            title: 'Remove',
            dataIndex: 'remove',
            key: 'remove',
            width: 100,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        removeSelectedAttempt(record)
                    }}
                >
                    <MinusCircleTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 20 }}
                    />
                </Button>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    return (
        <div>
            <Button
                onClick={() => {
                    goBack(navigate)
                }}
                size="small"
            >
                Go Back
            </Button>
            <div style={{ marginTop: 10 }}>
                <h3>
                    Coupon: '<strong>{couponName}</strong>'
                </h3>
                <div style={{ marginTop: 10 }}>
                    <span>Status: </span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={couponEnabled}
                        onClick={(enabled) => onEnabledSelection(enabled)}
                        disabled={loading}
                    />
                    <Button
                        style={{ marginLeft: 40 }}
                        onClick={getCoursesListAndShowSelectCoursesModal}
                    >
                        Add Courses
                    </Button>
                </div>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                style={{ marginTop: 8 }}
                loading={loading}
                size="small"
                rowKey="courseName"
            />
            <div style={{ marginTop: 10 }}>
                <h3>Selected Attemps</h3>
                <div style={{ marginTop: 10 }}>
                    <span>Status: </span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={attemptsEnabled}
                        onClick={(enabled) =>
                            onEnabledAttemptsSelection(enabled)
                        }
                        disabled={loading}
                    />
                    <Button
                        style={{ marginLeft: 40 }}
                        onClick={getAttemptsListAdShowSelectAttemptsMpdal}
                    >
                        Add Attempts
                    </Button>
                </div>
            </div>
            <Table
                columns={attemptsColumns}
                dataSource={attempts}
                pagination={false}
                style={{ marginTop: 8 }}
                loading={loading}
                size="small"
                rowKey="attempt"
            />
            <Button
                type="primary"
                onClick={updateMappings}
                loading={loading}
                style={{ marginTop: 10 }}
            >
                Update
            </Button>
            <Modal
                title="Add Courses"
                open={showSelectCoursesModal}
                onCancel={hideSelectCoursesModal}
                onOk={onOkSelectCourses}
            >
                <Input
                    placeholder="Search Course"
                    onChange={(e) => filterCourses(e.target.value)}
                    autoFocus
                />
                <Table
                    columns={courseSearchColums}
                    dataSource={filteredCourseNames}
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 200 }}
                    size="small"
                    style={{ marginTop: 8 }}
                />
                <Tag color="geekblue" style={{ marginTop: 12 }}>
                    Selected Courses
                </Tag>
                <Table
                    columns={selectedCoursesColums}
                    dataSource={selectedCourseNames}
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 200 }}
                    size="small"
                    style={{ marginTop: 8 }}
                />
            </Modal>
            <Modal
                title="Add Attempts"
                open={showSelectAttemptsModal}
                onCancel={hideSelectAttemptsModal}
                onOk={onOkSelectAttempts}
            >
                <Input
                    placeholder="Search Attempt"
                    onChange={(e) => filterAttempts(e.target.value)}
                    autoFocus
                />
                <Table
                    columns={attemptSearchColums}
                    dataSource={filteredAttemptNames}
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 200 }}
                    size="small"
                    style={{ marginTop: 8 }}
                />
                <Tag color="geekblue" style={{ marginTop: 12 }}>
                    Selected Attempts
                </Tag>
                <Table
                    columns={selectedAttemptsColums}
                    dataSource={selectedAttemptNames}
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 200 }}
                    size="small"
                    style={{ marginTop: 8 }}
                />
            </Modal>
        </div>
    )
}

export default CouponCourseMapping
