import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers/rootReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const persistConfig = {
    key: 'root',
    storage: storage,
    Statereconciler: autoMergeLevel2,
    blacklist: ['isLoginModal', 'isLoginScreen'],
}
const middlewares = []

const myPersistReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(myPersistReducer, applyMiddleware(...middlewares))

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store)

// sagaMiddleware.run(rootSaga);

export default store
