import {
    Input,
    Select,
    Checkbox,
    DatePicker,
    InputNumber,
    Button,
    Switch,
    Table,
    message,
    Form,
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    DeleteTwoTone,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { goBack } from '../../Util/ILUtil'

const CreateBulkCoupons = ({ navigate }) => {
    const [couponTypes, setCouponTypes] = useState([])
    const [loading, setLoading] = useState(false)
    const [courseNames, setCourseNames] = useState([])
    const [filteredCourseNames, setFilteredCourseNames] = useState([])
    const [selectedCourseNames, setSelectedCourseNames] = useState([])
    const [couponEnabled, setCouponEnabled] = useState(true)
    const [filter, setFilter] = useState('')
    const [partners, setPartners] = useState([])
    const [partnerSelected, setPartnerSelected] = useState(false)
    const [netPercentSelected, setNetPercentSelected] = useState(0)
    const [attemptFilter, setAttemptFilter] = useState('')
    const [attemptsEnabled, setAttemptsEnabled] = useState(true)
    const [attempts] = useState([])
    const [selectedAttemptNames, setSelectedAttemptNames] = useState([])
    const [purchaseDate, setPurchaseDate] = useState('')
    const [attemptNames, setAttemptNames] = useState([])
    const [filteredAttemptNames, setFilteredAttemptNames] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_COUPON_TYPES).then((res) => {
            setCouponTypes(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES_WHICH_HAS_PRICING).then(
            (res) => {
                var courseNamesData = []
                res.data.forEach((courseName) => {
                    var courseObj = {}
                    courseObj['courseName'] = courseName
                    courseNamesData.push(courseObj)
                })
                setCourseNames(courseNamesData)
                setFilteredCourseNames(courseNamesData)
                // setSelectedCourseNames([])
            }
        )

        RestApi.doGet(GLOBAL.URL.GET_PARTNERS).then((res) => {
            setPartners(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS)
            .then((res) => {
                var attemptNamesData = []
                res.data.forEach((item) => {
                    var attemptName = item.attempt
                    if (!checkForAttemptInAttempts(attemptName)) {
                        var attemptObj = {}
                        attemptObj['attempt'] = attemptName
                        attemptNamesData.push(attemptObj)
                    }
                })
                setAttemptNames(attemptNamesData)
                setSelectedAttemptNames([])
                setFilteredAttemptNames(attemptNamesData)
            })
            .finally(() => {
                setLoading(false)
            })
        //eslint-disable-next-line
    }, [])

    const checkForAttemptInAttempts = (attemptName) => {
        var attemptsData = attempts
        for (var i = 0; i < attemptsData.length; i++) {
            if (attemptsData[i].attempt === attemptName) {
                return true
            }
        }

        return false
    }

    const filterAttempts = (filter) => {
        var filteredAttemptNames = attemptNames.filter((attempt) =>
            attempt.attempt.toLowerCase().includes(filter.toLowerCase())
        )

        setFilteredAttemptNames(filteredAttemptNames)
        setAttemptFilter(filter)
    }

    const addToSelectedAttempt = (name) => {
        var selectedAttemptNamesData = selectedAttemptNames
        var attemptObj = {}
        attemptObj['attempt'] = name
        attemptObj['enabled'] = attemptsEnabled
        selectedAttemptNamesData.push(attemptObj)

        var attemptNamesData = attemptNames
        for (var i = 0; i < attemptNamesData.length; i++) {
            if (attemptNamesData[i].attempt === name) {
                attemptNamesData.splice(i, 1)
                break
            }
        }
        setSelectedAttemptNames(selectedAttemptNamesData)
        setAttemptNames(attemptNamesData)

        filterAttempts(attemptFilter)
    }

    const onChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const createCoupons = () => {
        form.validateFields()
            .then((values) => {
                if (values.partnerId) {
                    if (values.netPercent === 0 && values.netPrice === 0) {
                        message.error(
                            'Need net percent or net price, both cannot be 0'
                        )
                        return
                    }

                    if (values.netPercent > 0 && values.netPrice > 0) {
                        message.error(
                            'Need either net percent or net price, both cannot be set'
                        )
                        return
                    }

                    if (values.netPercent > 100) {
                        message.error('Net percent cannot be more than 100')
                        return
                    }

                    if (
                        values.discount !== '100' ||
                        values.type !== 'percent'
                    ) {
                        message.error(
                            'When Partner is selected, then discount should be 100%'
                        )
                        return
                    }

                    if (selectedCourseNames.length === 0) {
                        message.error(
                            'When Partner is selected, please select the course!'
                        )
                        return
                    }

                    if (selectedCourseNames.length > 1) {
                        message.error(
                            'When Partner is selected, please select only 1 course!'
                        )
                        return
                    }

                    if (selectedAttemptNames.length === 0) {
                        message.error(
                            'When Partner is selected, please select the attempt!'
                        )
                        return
                    }

                    if (selectedAttemptNames.length > 1) {
                        message.error(
                            'When Partner is selected, please select only 1 attempt!'
                        )
                        return
                    }

                    if (!values.purchaseDate) {
                        message.error(
                            'When Partner is selected, purchase date is needed!'
                        )
                        return
                    }

                    if (!couponEnabled) {
                        message.error(
                            'When Partner is selected, coupon should be enabled for selected course!'
                        )
                        return
                    }

                    if (!attemptsEnabled) {
                        message.error(
                            'When Partner is selected, coupon should be enabled for selected attempt!'
                        )
                        return
                    }

                    if (values.limit !== '1') {
                        message.error(
                            'When Partner is selected, limit should be set as 1!'
                        )
                        return
                    }

                    if (!values.oncePerUser) {
                        message.error(
                            'When Partner is selected, Once Per User should be selected!'
                        )
                        return
                    }
                }

                setLoading(true)
                values['startDate'] = startDate
                values['endDate'] = endDate
                values['purchaseDate'] = purchaseDate

                var selectedCourseNamesData = selectedCourseNames
                selectedCourseNamesData.forEach((item) => {
                    item.enabled = couponEnabled
                })

                values['courseMappings'] = selectedCourseNamesData

                var selectedAttemptNamesData = selectedAttemptNames
                selectedAttemptNamesData.forEach((item) => {
                    item.enabled = attemptsEnabled
                })

                values['attempts'] = selectedAttemptNamesData

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.CREATE_BULK_COUPONS, body, 'blob')
                    .then((response) => {
                        if (response.type === 'application/json') {
                            const reader = new FileReader()
                            reader.addEventListener('loadend', () => {
                                message.error(JSON.parse(reader.result).data)
                            })
                            reader.readAsText(response)
                        } else {
                            const url = window.URL.createObjectURL(
                                new Blob([response])
                            )
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', 'Coupons.xlsx')
                            document.body.appendChild(link)
                            link.click()
                            link.parentNode.removeChild(link)

                            navigate('/coupons')
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const filterCourses = (filter) => {
        var filteredCourseNames = courseNames.filter((course) =>
            course.courseName.toLowerCase().includes(filter.toLowerCase())
        )

        setFilteredCourseNames(filteredCourseNames)
        setFilter(filter)
    }

    const addToSelectedCourse = (courseName) => {
        var selectedCourseNamesData = selectedCourseNames
        var courseObj = {}
        courseObj['courseName'] = courseName
        courseObj['enabled'] = couponEnabled
        selectedCourseNamesData.push(courseObj)

        var courseNamesData = courseNames
        for (var i = 0; i < courseNamesData.length; i++) {
            if (courseNamesData[i].courseName === courseName) {
                courseNamesData.splice(i, 1)
                break
            }
        }

        setSelectedCourseNames(selectedCourseNamesData)
        setCourseNames(courseNamesData)

        filterCourses(filter)
    }

    const removeSelectedCourse = (courseObj) => {
        var selectedCourseNamesData = selectedCourseNames
        for (var i = 0; i < selectedCourseNamesData.length; i++) {
            if (
                selectedCourseNamesData[i].courseName === courseObj.courseName
            ) {
                selectedCourseNamesData.splice(i, 1)
                setSelectedCourseNames(selectedCourseNamesData)
                break
            }
        }

        var courseNamesData = courseNames
        courseNamesData.push(courseObj)

        setCourseNames(courseNamesData)

        filterCourses(filter)
    }

    const onEnabledSelection = (enabled) => {
        setCouponEnabled(enabled)
    }

    const handlePartnerChange = (value) => {
        if (value) {
            const selectedPartnerData = partners.find(
                (partner) => partner.id === value
            )
            setNetPercentSelected(selectedPartnerData.netPercent)
            form.setFieldsValue({
                netPercent: selectedPartnerData.netPercent,
            })
        }

        setPartnerSelected(value ? true : false)
    }

    const onEnabledAttemptsSelection = (enabled) => {
        setAttemptsEnabled(enabled)
    }

    const removeSelectedAttempt = (attemptObj) => {
        var selectedAttemptNamesData = selectedAttemptNames
        for (var i = 0; i < selectedAttemptNamesData.length; i++) {
            if (selectedAttemptNamesData[i].attempt === attemptObj.attempt) {
                selectedAttemptNamesData.splice(i, 1)
                setSelectedAttemptNames(selectedAttemptNamesData)
                break
            }
        }

        var attemptNamesData = attemptNames
        attemptNamesData.push(attemptObj)

        setSelectedAttemptNames(selectedAttemptNamesData)
        setAttemptNames(attemptNamesData)

        filterAttempts(attemptFilter)
    }

    const onChangePurchaseDate = (value, dateString) => {
        setPurchaseDate(dateString)
    }

    const { RangePicker } = DatePicker
    const { Option } = Select
    const dateFormat = 'DD-MMM-YY HH:mm'
    var couponTypeView = []
    couponTypes.forEach((item) => {
        couponTypeView.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const columns = [
        {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 40,
            render: (text, record) => (
                <DeleteTwoTone
                    onClick={() => {
                        removeSelectedCourse(record)
                    }}
                    twoToneColor="#eb2f96"
                    style={{ fontSize: 20 }}
                />
            ),
        },
        {
            title: 'Selected Courses',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const courseSearchColums = [
        {
            title: '',
            dataIndex: 'add',
            key: 'add',
            width: 40,
            render: (text, record) => (
                <PlusCircleTwoTone
                    onClick={() => {
                        addToSelectedCourse(record.courseName)
                    }}
                    style={{ fontSize: 20 }}
                />
            ),
        },
        {
            title: 'Avaliable Courses',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    var partnerOptions = []
    partners.forEach((item) => {
        partnerOptions.push(
            <Option value={item.id} key={item.id}>
                {item.name}
            </Option>
        )
    })

    const attemptSearchColums = [
        {
            title: '',
            dataIndex: 'add',
            key: 'add',
            width: 40,
            render: (text, record) => (
                <PlusCircleTwoTone
                    onClick={() => {
                        addToSelectedAttempt(record.attempt)
                    }}
                    style={{ fontSize: 20 }}
                />
            ),
        },
        {
            title: 'Avaliable Attempts',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    const selectedAttemptsColums = [
        {
            title: '',
            dataIndex: 'remove',
            key: 'remove',
            width: 100,
            render: (text, record) => (
                <MinusCircleTwoTone
                    onClick={() => {
                        removeSelectedAttempt(record)
                    }}
                    twoToneColor="#eb2f96"
                    style={{ fontSize: 20 }}
                />
            ),
        },
        {
            title: 'Selected Attempts',
            dataIndex: 'attempt',
            key: 'attempt',
        },
    ]

    return (
        <div>
            <Button
                size="small"
                onClick={() => goBack(navigate)}
                loading={loading}
            >
                {'< Back'}
            </Button>
            <h2 style={{ textAlign: 'center' }}>Create Bulk Coupons</h2>
            <Form
                form={form}
                initialValues={{
                    oncePerUser: false,
                    netPrice: 0,
                    netPercent: netPercentSelected,
                }}
                layout="horizontal"
                {...formItemLayout}
            >
                <Form.Item
                    label="Quantity"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="quantity"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter quantity!',
                        },
                    ]}
                >
                    <InputNumber autoFocus min={1} />
                </Form.Item>
                <Form.Item
                    label="Prepend String"
                    style={{ marginBottom: 10 }}
                    name="prepend"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input placeholder="Enter Prepend String" />
                </Form.Item>
                <Form.Item
                    label="Coupon Length"
                    style={{ marginBottom: 10 }}
                    name="charLength"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter coupon length!',
                        },
                    ]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="Select Coupon Type"
                    style={{ marginBottom: 10 }}
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Coupon type',
                        },
                    ]}
                >
                    <Select placeholder="Select Coupon type">
                        {couponTypeView}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Enter Coupon value"
                    style={{ marginBottom: 10 }}
                    name="discount"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Coupon value',
                        },
                    ]}
                >
                    <Input placeholder="Enter Coupon value" />
                </Form.Item>
                <Form.Item
                    label="Max uses #"
                    style={{ marginBottom: 10 }}
                    name="limit"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input placeholder="Enter max uses #" />
                </Form.Item>
                <Form.Item
                    label="Once Per User"
                    style={{ marginBottom: 10 }}
                    name="oncePerUser"
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item
                    label="Date range"
                    style={{ marginBottom: 10 }}
                    name="dateRange"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <RangePicker
                        showTime={{ format: 'HH:mm' }}
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Purchase Date"
                    style={{ marginBottom: 10 }}
                    name="purchaseDate"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <DatePicker
                        showTime
                        placeholder="Select Date"
                        format="DD-MMM-YY HH:mm"
                        onChange={onChangePurchaseDate}
                    />
                </Form.Item>
                <Form.Item
                    label="Partner"
                    style={{ marginBottom: 10 }}
                    name="partnerId"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select partner"
                        onChange={(value) => {
                            handlePartnerChange(value)
                        }}
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {partnerOptions}
                    </Select>
                </Form.Item>
                {partnerSelected ? (
                    <Form.Item
                        label="Partner Transaction #"
                        style={{ marginBottom: 10 }}
                        name="partnerTransactionId"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input placeholder="Partner Transaction ID" />
                    </Form.Item>
                ) : null}
                {partnerSelected ? (
                    <Form.Item
                        label="Net Percent (our share)"
                        style={{ marginBottom: 10 }}
                        name="netPercent"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                ) : null}
                {partnerSelected ? (
                    <Form.Item
                        label="Net Price ₹ (our share)"
                        shouldUpdate={true}
                        style={{ marginBottom: 10 }}
                        name="netPrice"
                        valuePropName="value"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} precision={2} />
                    </Form.Item>
                ) : null}
            </Form>

            <h3 style={{ marginTop: 20, textDecoration: 'underline' }}>
                Select courses (optional)
            </h3>
            <span>Coupon enabled for selected courses?</span>
            <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={couponEnabled}
                onClick={(enabled) => onEnabledSelection(enabled)}
                disabled={loading}
                style={{ marginLeft: 20 }}
            />
            <Input
                placeholder="Search Course"
                onChange={(e) => filterCourses(e.target.value)}
                autoFocus
                style={{ marginTop: 10 }}
            />
            <Table
                columns={courseSearchColums}
                dataSource={filteredCourseNames ? filteredCourseNames : null}
                pagination={false}
                loading={loading}
                scroll={{ y: 200 }}
                size="small"
                style={{ marginTop: 8 }}
            />

            <Table
                key={selectedCourseNames.length}
                columns={columns}
                dataSource={selectedCourseNames ? selectedCourseNames : null}
                pagination={false}
                loading={loading}
                scroll={{ y: 200 }}
                size="small"
                style={{ marginTop: 8 }}
            />
            <div style={{ marginTop: 20 }}>
                <h3 style={{ textDecoration: 'underline' }}>
                    Select attempts (optional)
                </h3>
                <span>Coupon enabled for selected attempts?</span>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={attemptsEnabled}
                    onClick={(enabled) => onEnabledAttemptsSelection(enabled)}
                    disabled={loading}
                    style={{ marginLeft: 20 }}
                />
                <Input
                    placeholder="Search Attempt"
                    onChange={(e) => filterAttempts(e.target.value)}
                    style={{ marginTop: 10 }}
                />
                <Table
                    columns={attemptSearchColums}
                    dataSource={
                        filteredAttemptNames ? filteredAttemptNames : null
                    }
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 200 }}
                    size="small"
                    style={{ marginTop: 8 }}
                />
                <Table
                    key={selectedAttemptNames.length}
                    columns={selectedAttemptsColums}
                    dataSource={
                        selectedAttemptNames ? selectedAttemptNames : null
                    }
                    pagination={false}
                    loading={loading}
                    scroll={{ y: 200 }}
                    size="small"
                    style={{ marginTop: 8 }}
                />
            </div>
            <div>
                <Button
                    type="primary"
                    style={{ marginTop: 20 }}
                    onClick={() => createCoupons()}
                >
                    Create
                </Button>
            </div>
        </div>
    )
}

export default CreateBulkCoupons
