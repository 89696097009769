import React, { useState, useEffect, memo } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    Checkbox,
    message,
    Form,
    Space,
} from 'antd'
import {
    EditTwoTone,
    MinusCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { goBack } from '../../Util/ILUtil'
import { useParams } from 'react-router-dom'

function SurveyQuestions(props) {
    const { TextArea } = Input
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [surveyId, setSurveyId] = useState()
    const [survey, setSurvey] = useState({})
    const [questions, setQuestions] = useState([])
    const [addQuestionModalVisible, setAddQuestionModalVisible] =
        useState(false)
    const [questionTypes, setQuestionTypes] = useState([])
    const [selectedQuestion, setSelectedQuestion] = useState({})
    const [optionTypes, setOptionTypes] = useState([])
    const [selectedQuestionType, setSelectedQuestionType] = useState()
    const [addUpdateOptionModalVisible, setAddUpdateOptionModalVisible] =
        useState(false)
    const [selectedOption, setSelectedOption] = useState({})
    const [form] = Form.useForm()
    const { surveyId: paramsSurveyId } = useParams()

    useEffect(() => {
        setSurveyId(paramsSurveyId)
        getSurvey(paramsSurveyId)
        getQuestions(paramsSurveyId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        message.destroy()
        if (loading) {
            message.loading('Loading...', 0)
        }
    }, [loading])

    const getSurvey = (surveyId) => {
        RestApi.doGet(GLOBAL.URL.GET_SURVEY_SURVEY_ID + surveyId).then(
            (res) => {
                setSurvey(res.data)
            }
        )
    }

    const getQuestions = (surveyId) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_QUESTIONS_IN_SURVEY + surveyId)
            .then((res) => {
                setQuestions(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddUpdateQuestionModal = (record) => {
        setSelectedQuestion(record)
        setAddQuestionModalVisible(true)

        if (questionTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_SURVEY_QUESTION_TYPES).then((res) => {
                setQuestionTypes(res.data)
            })

            RestApi.doGet(GLOBAL.URL.GET_SURVEY_OPTION_TYPES).then((res) => {
                setOptionTypes(res.data)
            })
        }

        if (record.questionId) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateOptionModal = () => {
        setAddUpdateOptionModalVisible(false)
    }

    const showAddUpdateOptionModal = (option, question) => {
        setSelectedOption(option)
        setSelectedQuestion(question)
        setAddUpdateOptionModalVisible(true)

        if (optionTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_SURVEY_OPTION_TYPES).then((res) => {
                setOptionTypes(res.data)
            })
        }

        if (option.optionId) {
            setTimeout(() => {
                form.setFieldsValue(option)
            }, 100)
        }
    }

    const hideAddUpdateQuestionModal = () => {
        setAddQuestionModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const questionTypeOptions = () => {
        var options = []
        questionTypes.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const optionTypesOptions = () => {
        var options = []
        optionTypes.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const AddFormOptions = memo(({ selectedQuestionType }) => {
        return (
            <Form.List name="options">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 0,
                                }}
                                align="baseline"
                            >
                                {selectedQuestionType === 'INPUT' ? (
                                    <Form.Item
                                        label=""
                                        key={key}
                                        {...restField}
                                        name={[name, 'type']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Select type!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select type"
                                            showSearch
                                            style={{
                                                width: '240px',
                                                marginRight: 5,
                                            }}
                                        >
                                            {optionTypesOptions()}
                                        </Select>
                                    </Form.Item>
                                ) : null}

                                <Form.Item
                                    label=""
                                    key={key}
                                    {...restField}
                                    name={[name, 'option']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Enter option!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter option"
                                        style={{
                                            width: '400px',
                                            marginRight: 5,
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item label={''}>
                                    {fields.length > 1 && (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </Form.Item>
                            </Space>
                        ))}

                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                style={{ width: '60%' }}
                            >
                                Add Option
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    })

    const addOrUpdateQuestion = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)
                values.surveyId = surveyId

                var url = GLOBAL.URL.ADD_SURVEY_QUESTION
                if (selectedQuestion.questionId) {
                    values.questionId = selectedQuestion.questionId
                    url = GLOBAL.URL.UPDATE_SURVEY_QUESTION
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData).then((response) => {
                    notification['success']({
                        message: response.data,
                    })

                    hideAddUpdateQuestionModal()
                    getQuestions(surveyId)
                })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteQuestion = (questionId) => {
        setLoading(true)

        var payload = {}
        payload.questionId = questionId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_SURVEY_QUESTION, formData).then(
            (response) => {
                notification['success']({
                    message: response.data,
                })

                getQuestions(surveyId)
            }
        )
    }

    const addOrUpdateOption = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)
                values.questionId = selectedQuestion.questionId

                var url = GLOBAL.URL.ADD_SURVEY_OPTION
                if (selectedOption.optionId) {
                    values.optionId = selectedOption.optionId
                    url = GLOBAL.URL.UPDATE_SURVEY_OPTION
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData).then((response) => {
                    notification['success']({
                        message: response.data,
                    })

                    hideAddUpdateOptionModal()
                    getQuestions(surveyId)
                })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getQuestionCard = (record, index) => {
        return (
            <Card style={{ marginBottom: 15 }} variant="outlined">
                <CardContent style={{ padding: 8 }}>
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                showAddUpdateQuestionModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 14 }} />
                        </Button>
                        <span style={{ marginLeft: 10 }}>
                            <b>
                                {index + 1}. {record.title}
                            </b>
                        </span>
                    </div>
                    <div style={{ fontSize: 11 }}>{record.description}</div>
                    <div style={{ marginTop: 10 }}>
                        Mandatory:{' '}
                        {record.mandatory ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                        <span style={{ marginLeft: 20 }}>
                            Type: {record.type}
                        </span>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => deleteQuestion(record.questionId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size="small" style={{ marginLeft: 20 }}>
                                Delete Question
                            </Button>
                        </Popconfirm>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <h4>Options</h4>
                    </div>
                    <Table
                        columns={optionColumns}
                        dataSource={record.options}
                        pagination={false}
                        size="small"
                        rowKey="optionId"
                        style={{ marginTop: 10 }}
                    />
                    <div style={{ marginTop: 10 }}>
                        <Button
                            size="small"
                            onClick={() => showAddUpdateOptionModal({}, record)}
                        >
                            Add Option
                        </Button>
                        <Button
                            size="small"
                            style={{ marginLeft: 10 }}
                            onClick={() =>
                                reorderOptions(
                                    record.options,
                                    record.questionId
                                )
                            }
                        >
                            Reorder Options
                        </Button>
                    </div>
                </CardContent>
            </Card>
        )
    }

    const reorderQuestions = () => {
        const columns = [
            {
                title: 'Question',
                dataIndex: 'title',
                key: 'title',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: questions,
                updateUrl: GLOBAL.URL.REORDER_SURVEY_QUESTIONS,
                columns: columns,
                payload: { surveyId: surveyId },
            },
        })
    }

    const reorderOptions = (options, questionId) => {
        const columns = [
            {
                title: 'Option',
                dataIndex: 'option',
                key: 'option',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: options,
                updateUrl: GLOBAL.URL.REORDER_SURVEY_OPTIONS,
                columns: columns,
                payload: { questionId: questionId },
            },
        })
    }

    const deleteOption = (optionId) => {
        setLoading(true)

        var payload = {}
        payload.optionId = optionId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_SURVEY_OPTION, formData).then(
            (response) => {
                notification['success']({
                    message: response.data,
                })

                getQuestions(surveyId)
            }
        )
    }

    const optionColumns = [
        {
            title: 'Update',
            dataIndex: 'optionId',
            key: 'optionId',
            width: 70,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateOptionModal(record, {})
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Options',
            dataIndex: 'option',
            key: 'option',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => (
                <span>{record.type ? record.type : '-'}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteOption(record.optionId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    return (
        <div>
            <div>
                <Button
                    onClick={() => {
                        goBack(props.navigate)
                    }}
                    size="small"
                >
                    Go Back
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => showAddUpdateQuestionModal({})}
                    style={{ marginLeft: 10 }}
                    loading={loading}
                >
                    Add Question
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={reorderQuestions}
                    style={{ marginLeft: 10 }}
                    loading={loading}
                >
                    Reorder
                </Button>
            </div>
            <h2 style={{ marginTop: 10 }}>{survey.title}</h2>
            <div>
                <u>Note</u>
                <ol>
                    <li>Last question should be a mandatory question</li>
                    <li>
                        Do no reorder the questions if the survey is in progress
                    </li>
                </ol>
            </div>
            <h4 style={{ marginTop: 10 }}>Questions</h4>
            {questions.map((question, index) =>
                getQuestionCard(question, index)
            )}
            <Modal
                title={'Configure Question'}
                open={addQuestionModalVisible}
                onOk={addOrUpdateQuestion}
                onCancel={hideAddUpdateQuestionModal}
                okText={selectedQuestion.questionId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                {addQuestionModalVisible ? (
                    <Form
                        layout="horizontal"
                        form={form}
                        initialValues={{
                            mandatory: selectedQuestion.questionId
                                ? selectedQuestion.mandatory
                                : true,
                        }}
                        {...formItemLayout}
                        style={{ marginTop: 20 }}
                    >
                        <Form.Item
                            label="Question"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter question',
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                        {!selectedQuestion.questionId ? (
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select type',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select type"
                                    showSearch
                                    onSelect={(type) =>
                                        setSelectedQuestionType(type)
                                    }
                                >
                                    {questionTypeOptions()}
                                </Select>
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label="Mandatory"
                            style={{ marginBottom: 10 }}
                            name="mandatory"
                            valuePropName="checked"
                        >
                            <Checkbox></Checkbox>
                        </Form.Item>
                        {selectedQuestionType !== 'NPS' &&
                        !selectedQuestion.questionId ? (
                            <>
                                <h4>Options</h4>
                                <AddFormOptions
                                    selectedQuestionType={selectedQuestionType}
                                />
                            </>
                        ) : null}
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Configure Option'}
                open={addUpdateOptionModalVisible}
                onOk={addOrUpdateOption}
                onCancel={hideAddUpdateOptionModal}
                okText={selectedOption.optionId ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={1000}
            >
                {addUpdateOptionModalVisible ? (
                    <Form
                        layout="horizontal"
                        form={form}
                        {...formItemLayout}
                        style={{ marginTop: 20 }}
                    >
                        <Form.Item
                            label="Option"
                            name="option"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter option',
                                },
                            ]}
                        >
                            <Input autoFocus />
                        </Form.Item>
                        {selectedQuestion.type === 'INPUT' ||
                        selectedOption.optionId ? (
                            <Form.Item
                                label="Type"
                                name="type"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select type',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select type"
                                    showSearch
                                    onSelect={(type) =>
                                        setSelectedQuestionType(type)
                                    }
                                >
                                    {optionTypesOptions()}
                                </Select>
                            </Form.Item>
                        ) : null}
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default SurveyQuestions
