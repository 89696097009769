import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Radio, Tag, Select, Modal } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Link } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function HighProspectLeads() {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState()
    const [data, setData] = useState([])
    const [leadType, setLeadType] = useState('internal')
    const [active, setActive] = useState('yes')
    const [leadsCount, setLeadsCount] = useState()
    const [adminUsers, setAdminUsers] = useState([])
    const [selectedAsignee, setSelectedAsignee] = useState('')
    const [expectedSalesReport, setExpectedSalesReport] = useState()
    const [expectedSalesReportLoading, setExpectedSalesReportLoading] =
        useState(false)
    const [
        expectedSalesReportModalVisible,
        setExpectedSalesReportModalVisible,
    ] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leadType, active, selectedAsignee])

    const getFilters = () => {
        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
            setAdminUsers(res.data)
        })
    }

    const fetchData = () => {
        setLeadsCount()
        setLoading(true)
        setData([])

        var url =
            GLOBAL.URL.LEADS.GET_HIGH_PROSPECT_LEADS +
            '?leadType=' +
            leadType +
            '&active=' +
            active +
            '&assignToUserId=' +
            selectedAsignee

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData(res.data)
                setLeadsCount(res.leadsCount)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeLeadType = (e) => {
        setLeadType(e.target.value)
        setSelectedAsignee('')
    }

    const onChangeActive = (e) => {
        setActive(e.target.value)
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => (
                <span style={{ fontSize: 12 }}>{index + 1}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (text, record) => (
                <span>
                    <Button
                        type={record.active ? 'primary' : 'outline'}
                        size="small"
                        style={{ fontSize: 11 }}
                    >
                        {record.active ? 'Open' : 'Closed'}
                    </Button>
                </span>
            ),
        },
        {
            title: 'User',
            key: 'userId',
            render: (text, record) => (
                <span>
                    <Link
                        href={
                            record.internalLead
                                ? '/userdetails/' + record.linkedUserId
                                : '/leads/external?filter=' +
                                  (record.leadOpen ? 'open' : 'closed') +
                                  '&searchQuery=' +
                                  record.phone
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            navigate(
                                record.internalLead
                                    ? '/userdetails/' + record.linkedUserId
                                    : '/leads/external?filter=' +
                                          (record.leadOpen
                                              ? 'open'
                                              : 'closed') +
                                          '&searchQuery=' +
                                          record.phone
                            )
                        }}
                        underline="none"
                        target="_blank"
                    >
                        {record.name}
                        <br />
                        {record.phone ? record.phone : ''}
                        <br />
                        <span style={{ fontSize: 10 }}>{record.email}</span>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Prospect',
            key: 'prospect',
            render: (text, record) => (
                <span>
                    {record.active && (
                        <>
                            <Tag color="red">{'High Prospect'}</Tag>
                            <br />
                        </>
                    )}
                    {record.expectedSale ? (
                        <>
                            {record.expectedSaleString}
                            <br />
                        </>
                    ) : null}
                    {record.expectedSaleDate ? (
                        <>{record.expectedSaleDate}</>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Follow Up',
            dataIndex: 'followUpDate',
            key: 'followUpDate',
            render: (text, record) => (
                <span>
                    {record.assignedTo}
                    <br />
                    {record.followUpDate ? record.followUpDate : null}
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span>
                    <Tag color="purple">{record.status}</Tag>
                    <br />
                    <span style={{ fontSize: 11 }}>
                        {record.internalLead ? 'Internal' : 'External'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.updatedBy && record.updatedBy.id ? (
                        <>
                            {record.updatedBy.firstName}
                            <br />
                        </>
                    ) : null}
                    {record.updatedAt}
                </span>
            ),
        },
    ]

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const onChangeAsignee = (value) => {
        setSelectedAsignee(value ? value : '')
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const showExpectedSalesReport = () => {
        setExpectedSalesReportModalVisible(true)
        getExpectedSalesReport()
    }

    const hideExpectedSalesReport = () => {
        setExpectedSalesReport()
        setExpectedSalesReportModalVisible(false)
    }

    const getExpectedSalesReport = () => {
        setExpectedSalesReport()
        setExpectedSalesReportLoading(true)

        RestApi.doGet(GLOBAL.URL.LEADS.GET_HIGH_PROSPECT_LEADS_EXPECTED_SALES)
            .then((response) => {
                setExpectedSalesReport(response.data)
            })
            .finally(() => {
                setExpectedSalesReportLoading(false)
            })
    }

    return (
        <div>
            <div>
                <Radio.Group onChange={onChangeLeadType} value={leadType}>
                    <Radio.Button value={'all'} key={'all'}>
                        All
                    </Radio.Button>
                    <Radio.Button value={'internal'} key={'internal'}>
                        Internal
                    </Radio.Button>
                    <Radio.Button value={'external'} key={'external'}>
                        External
                    </Radio.Button>
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeActive}
                    value={active}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={'all'} key={'all'}>
                        All
                    </Radio.Button>
                    <Radio.Button value={'yes'} key={'yes'}>
                        Active
                    </Radio.Button>
                    <Radio.Button value={'no'} key={'no'}>
                        Closed
                    </Radio.Button>
                </Radio.Group>
                {leadType !== 'all' && (
                    <Select
                        placeholder="Filter By Asignee"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200, marginLeft: 10 }}
                        onChange={onChangeAsignee}
                        value={selectedAsignee || undefined}
                    >
                        {getAdminUserOptions()}
                    </Select>
                )}
                <Button type="link" onClick={fetchData}>
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 20 }} />
                    )}
                </Button>
                <span style={{ fontSize: 16 }}>
                    {leadsCount ? 'Leads count: ' + leadsCount : null}
                </span>
                <Button
                    type="primary"
                    style={{ marginLeft: 10 }}
                    onClick={showExpectedSalesReport}
                    loading={expectedSalesReportLoading}
                >
                    Expected Sales Report
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    More Data
                </Button>
            ) : null}
            <Modal
                title="Expected Sales Report"
                open={expectedSalesReportModalVisible}
                onCancel={hideExpectedSalesReport}
                footer={null}
            >
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(
                        expectedSalesReport
                    )}
                />
            </Modal>
        </div>
    )
}

export default HighProspectLeads
