module.exports = {
    TRANSACTION_CONSTANTS: {
        SUCCESS: 'success',
        PENDING: 'pending',
        FAILURE: 'failure',
        REFUNDED: 'refunded',
        LOAN_PROCESSING: 'loan_processing',
        LOAN_REJECTED: 'loan_rejected',
        LOAN_APPROVED: 'loan_approved',
    },
}
