import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'

const CoursesProgressTable = ({ data }) => {
    const { classes } = useStyles()

    return (
        <TableContainer component={Paper} className={classes.table}>
            <Table sx={{ minWidth: 300 }}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>
                            Course
                        </TableCell>
                        <TableCell className={classes.tableHead} align="center">
                            Expected
                        </TableCell>
                        <TableCell className={classes.tableHead} align="center">
                            Actual
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, i) => {
                        const color = determineColor(
                            item.actualCompletion,
                            item.expectedCompletion
                        )
                        return (
                            <TableRow key={i} className={classes.bodyRow}>
                                <TableCell>{item.courseName}</TableCell>
                                <TableCell
                                    className={classes.percentTxt}
                                    align="center"
                                >
                                    {Math.round(item.expectedCompletion)}%
                                </TableCell>
                                <TableCell
                                    className={classes.percentTxt}
                                    sx={{ color: color }}
                                    align="center"
                                >
                                    {Math.round(item.actualCompletion)}%
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CoursesProgressTable

export const determineColor = (actualPercent, expectedPercent) => {
    let iconColor = ''

    if (actualPercent <= expectedPercent * 0.6) {
        iconColor = 'red'
    } else if (actualPercent <= expectedPercent * 0.8) {
        iconColor = '#FF6E00'
    } else {
        iconColor = 'green'
    }

    return iconColor
}

const useStyles = makeStyles()((theme) => ({
    table: {
        boxShadow:
            'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important',
    },
    tableHead: {
        fontSize: '16px',
        fontWeight: 800,
    },
    percentTxt: {
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '15px',
        },
    },
    bodyRow: {
        '&:hover': {
            background: '#f2f2f2',
        },
    },
    playIcon: {
        width: '20px',
        height: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
    },
    playContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}))
