import React, { useState } from 'react'
import { Popover, Box, Typography, Button } from '@mui/material'
import { ILCalendarViewConstants } from './CalendarViewConstants'
import ScrollableTabs from '../../../IL/ScrollableTabs'
import { makeStyles } from 'tss-react/mui'

const SelectYearMonthModal = ({
    anchorEl,
    handleDateClose,
    selectedYear,
    selectedMonth,
    onClickSubmit,
}) => {
    const { classes } = useStyles()
    const [localYear, setLocalYear] = useState(selectedYear)
    const [localMonth, setLocalMonth] = useState(selectedMonth)

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            anchorEl={anchorEl}
            onClose={handleDateClose}
        >
            <Box className={classes.modal}>
                <Box className={classes.yearContainer}>
                    <Typography className={classes.modalYear}>
                        {ILCalendarViewConstants.monthsList[localMonth]},{' '}
                        {localYear}
                    </Typography>
                </Box>
                <Box className={classes.monthsContainer}>
                    <ScrollableTabs
                        setYear={setLocalYear}
                        setMonth={setLocalMonth}
                        year={localYear}
                        month={localMonth}
                    />
                </Box>
                <Box className={classes.btnContainer}>
                    <Button
                        className={classes.modalBtn}
                        onClick={handleDateClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={classes.modalBtn}
                        onClick={() => onClickSubmit(localMonth, localYear)}
                    >
                        Ok
                    </Button>
                </Box>
            </Box>
        </Popover>
    )
}

export default SelectYearMonthModal

const useStyles = makeStyles()(() => ({
    modal: {
        width: '280px',
    },
    yearContainer: {
        padding: '12px 12px 8px',
    },
    monthsContainer: {
        padding: '8px 5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalYear: {
        fontWeight: 600,
        fontSize: '20px',
        textAlign: 'center',
    },
    btnContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '10px',
        padding: '4px 12px 9px',
    },
    modalBtn: {
        textTransform: 'none',
    },
}))
