import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { mapAllCourses } from './GraphMapping/MapAllCourses'
import { mapCourseByMonth } from './GraphMapping/MapCourseByMonth'
import { mapByDay } from './GraphMapping/MapByDay'
import { CourseWatchTimeAnalyticsByMonth } from './CourseWatchTimeAnalyticsByMonth'
import { CourseWatchTimeAnalyticsByDay } from './CourseWatchTimeAnalyticsByDay'
import CircularProgress from '@mui/material/CircularProgress'
import StatsConstants from '../Stats/StatsConstants'

const dashboardRightContentWidth = '83.4%'

const WatchTimeAnalyticsByCourse = (props) => {
    const [courseWatchTimeDataByMonth, setCourseWatchTimeDataByMonth] =
        useState(StatsConstants.INITIAL_BAR_OPTIONS)
    const [courseWatchTimeDataByDay, setCourseWatchTimeDataByDay] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [courseByMonthOptions, setCourseByMonthOptions] = useState({})
    const [courseByDayOptions, setCourseByDayOptions] = useState({})
    const [courseFilterData, setCourseFilterData] = useState([])
    const initialSelectedMonth = 6
    const [tableData, setTableData] = useState([])
    const [tableDataByDay, setTableDataByDay] = useState([])
    const [monthLabel, setMonthLabel] = useState('')
    const [day, setDay] = useState('')
    const [loading, setLoading] = useState(false)
    const { classes } = useStyles()
    const BY_MONTH = 'BY_MONTH'
    const BY_DAY = 'By_DAY'
    const { userId } = props

    useEffect(() => {
        getCourseWatchTimeDataByMonth(initialSelectedMonth, [], true)
        getCoursesList()
        // eslint-disable-next-line
    }, [])

    const getCoursesList = () => {
        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_COURSE_WATCH_TIME_FILTER +
                '?userId=' +
                userId
        ).then((response) => {
            setCourseFilterData(response.data)
        })
    }

    const getCourseWatchTimeDataByMonth = (
        months,
        coursesArray,
        isSkeletonLoading
    ) => {
        setLoading(true)
        var coursesIds = ''
        coursesArray.forEach((item) => {
            coursesIds = `&courseId=${item.courseId}` + coursesIds
        })

        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_COURSE_WATCH_TIME_BY_MONTH +
                '?limit=' +
                months +
                coursesIds +
                '&userId=' +
                userId
        )
            .then((response) => {
                if (coursesArray.length === 1) {
                    let data = mapCourseByMonth(
                        response.data,
                        getCourseMonthSplit,
                        coursesArray[0].courseId
                    )
                    setCourseWatchTimeDataByMonth(data.watchTimeData)
                    setCourseByMonthOptions(data.options)
                    return (
                        tableData.length > 0 ? setTableData([]) : null,
                        tableDataByDay.length > 0 ? setTableDataByDay([]) : null
                    )
                } else {
                    let data = mapAllCourses(
                        response.data,
                        true,
                        getCourseMonthSplit
                    )
                    setCourseWatchTimeDataByMonth(data.watchTimeData)
                    setCourseByMonthOptions(data.options)
                    getRecentData(response, BY_MONTH)
                    return day ? setDay('') : null
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getRecentData = (data, filter) => {
        for (let index = data.length - 1; index >= 0; index--) {
            if (data[index].courses.length) {
                if (data[index].courses[0].value !== 0) {
                    if (filter === BY_DAY) {
                        setTableDataByDay(data[index].courses)
                        setDay(index + 1)
                    } else {
                        setMonthLabel(data[index].label)
                        setTableData(data[index].courses)
                    }
                    return
                }
            } else {
                if (index === 0) {
                    if (filter === BY_DAY) {
                        setTableDataByDay([])
                        setDay('')
                    } else {
                        setMonthLabel([])
                        setTableData('')
                    }
                }
            }
        }
    }

    const getTableDataByDay = (data, day) => {
        setTableDataByDay(data)
        setDay(day)
    }
    const getCourseMonthSplit = (monthLabel, courseId, data) => {
        setLoading(true)

        if (!courseId) {
            setTableData(data)
            setMonthLabel(monthLabel)
        } else {
            if (tableData.length > 0) {
                setTableData([])
            }
            if (tableDataByDay.length > 0) {
                setTableDataByDay([])
            }
        }
        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_COURSE_WATCH_TIME_BY_DAY +
                '?monthLabel=' +
                monthLabel +
                '&courseId=' +
                courseId +
                '&userId=' +
                userId
        )
            .then((response) => {
                if (courseId) {
                    let data = mapByDay(response.data)
                    setCourseWatchTimeDataByDay(data.watchTimeData)
                    setCourseByDayOptions(data.options)
                } else {
                    let data = mapAllCourses(
                        response.data,
                        false,
                        getTableDataByDay
                    )
                    setCourseWatchTimeDataByDay(data.watchTimeData)
                    setCourseByDayOptions(data.options)
                    getRecentData(response, BY_DAY)
                }
                setTimeout(() => {
                    let doc = document.getElementById('courseAnalyticsByDay')
                    window.scrollTo({
                        top: doc.offsetTop - 130,
                        behavior: 'smooth',
                    })
                }, 50)
            })
            .finally(() => setLoading(false))
    }

    const fetchData = (selectedMonth, selectedCourse) => {
        getCourseWatchTimeDataByMonth(selectedMonth, selectedCourse)

        return Object.keys(courseWatchTimeDataByDay).length
            ? setCourseWatchTimeDataByDay({})
            : null
    }

    return (
        <>
            <Grid className={classes.wrapperContainer} container>
                <Grid item xs={12} className={classes.rightContentContainer}>
                    <Grid
                        item
                        md={12}
                        xs={12}
                        className={classes.chartContainer}
                    >
                        {loading ? <CircularProgress /> : null}
                        <CourseWatchTimeAnalyticsByMonth
                            data={courseWatchTimeDataByMonth}
                            options={courseByMonthOptions}
                            courseFilterData={courseFilterData}
                            fetchData={(selectedMonth, selectedCourse) =>
                                fetchData(selectedMonth, selectedCourse)
                            }
                            tableData={tableData}
                            monthLabel={monthLabel}
                            loading={loading}
                            tableCourseNameColors={
                                courseWatchTimeDataByMonth.datasets
                            }
                        />
                        <CourseWatchTimeAnalyticsByDay
                            id="courseAnalyticsByDay"
                            data={courseWatchTimeDataByDay}
                            options={courseByDayOptions}
                            tableDataByDay={tableDataByDay}
                            monthLabel={monthLabel}
                            day={day}
                            tableCourseNameColors={
                                courseWatchTimeDataByDay.datasets
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WatchTimeAnalyticsByCourse

export const useStyles = makeStyles()((theme) => ({
    wrapperContainer: {
        display: 'flex',
        flexFlow: 'row',
        minHeight: 800,
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
        },
    },
    chartContainer: {
        display: 'flex',
        flexFlow: 'column',
        padding: 20,
        [theme.breakpoints.down('md')]: {
            padding: '20px 15px',
        },
    },
    appContainer: {
        paddingTop: 0,
    },
    rightContentContainer: {
        display: 'flex',
        flexFlow: 'column',
        width: dashboardRightContentWidth,
        [theme.breakpoints.down('md')]: {
            width: 'unset',
        },
    },
}))
