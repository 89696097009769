import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { ILCalendarViewConstants } from '../ILShareComponents/ILCalendar/SubComponents/CalendarViewConstants'
const currentYear = new Date().getFullYear()

const years = Array.from(
    { length: currentYear - 2022 + 1 },
    (_, index) => 2022 + index
)

const ScrollableTabs = ({ setYear, setMonth, year, month }) => {
    const yearTab = years.indexOf(year)

    const handleYearChange = (event, newValue) => {
        setYear(years[newValue])
    }

    const handleMonthChange = (event, newValue) => {
        setMonth(newValue)
    }

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tabs
                    value={yearTab}
                    onChange={handleYearChange}
                    orientation="vertical"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    sx={{ maxHeight: '250px' }}
                >
                    {years.map((yearValue) => (
                        <Tab key={yearValue} label={yearValue.toString()} />
                    ))}
                </Tabs>

                <Box sx={{ p: '0 2' }}>
                    <Tabs
                        value={month}
                        onChange={handleMonthChange}
                        orientation="vertical"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        sx={{ height: '250px' }}
                    >
                        {ILCalendarViewConstants.monthsList.map((item) => (
                            <Tab label={item} key={item} />
                        ))}
                    </Tabs>
                </Box>
            </Box>
        </div>
    )
}

export default ScrollableTabs
