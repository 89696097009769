import {
    Button,
    DatePicker,
    Form,
    Modal,
    Radio,
    Typography,
    Select,
} from 'antd'
import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import dayjs from 'dayjs'

const Export = () => {
    const { Option } = Select

    const LEAD_TYPE_FILTER_ENTRY_IN_DATE_RANGE = 'entry_in_date_range'
    const LEAD_TYPE_FILTER_CURRENT_STATUS = 'current_status'

    const [openOrClose, setOpenOrClose] = useState('open')
    const [selectedCourse, setSelectedCourse] = useState('')
    const [exportModalVisible, setExportModalVisible] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [openStatusTypes, setOpenStatusTypes] = useState([])
    const [closedStatusTypes, setClosedStatusTypes] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [responseMsg, setResponseMsg] = useState('')
    const [leadTypeFilter, setLeadTypeFilter] = useState(
        LEAD_TYPE_FILTER_ENTRY_IN_DATE_RANGE
    )
    const [leadSubTypes, setLeadSubTypes] = useState([])
    const [adminUsers, setAdminUsers] = useState([])

    const [form] = Form.useForm()
    const { RangePicker } = DatePicker

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    useEffect(() => {
        getFiltersData()
    }, [])

    const getFiltersData = () => {
        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEADS_FILTERS_DATA).then((res) => {
            setOpenStatusTypes(res.data.openStatusTypes)
            setClosedStatusTypes(res.data.closedStatusTypes)
            setStreamData(JSON.parse(res.data.streamData))
            setAdminUsers(res.data.adminUsers)
        })
    }

    const openStatusTypeOptions = () => {
        var options = []
        openStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const closedStatusTypeOptions = () => {
        var options = []
        closedStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const showExportModal = () => {
        setLevelData([])
        setSelectedCourse()
        setExportModalVisible(true)
    }

    const hideExportModal = () => {
        setExportModalVisible(false)
        setResponseMsg('')
    }

    const exportLeads = () => {
        form.validateFields()
            .then((values) => {
                setExportLoading(true)

                delete values.date
                values.leadEntryStartDate = startDate
                values.leadEntryEndDate = endDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.LEADS.EXPORT_LEADS, formData)
                    .then((response) => {
                        setResponseMsg(response.data)
                    })
                    .finally(() => {
                        setExportLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const openCloseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={'open'} key={'open'}>
                Open
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={'closed'} key={'closed'}>
                Closed
            </Radio.Button>
        )

        return options
    }

    const onOpenCloseChange = (e) => {
        setOpenOrClose(e.target.value)
        form.setFieldsValue({ leadType: '', leadSubType: '' })
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeLeadTypeFilter = (e) => {
        setLeadTypeFilter(e.target.value)
    }

    const leadTypeFilterOptions = () => {
        var options = []
        options.push(
            <Radio.Button
                value={LEAD_TYPE_FILTER_ENTRY_IN_DATE_RANGE}
                key={LEAD_TYPE_FILTER_ENTRY_IN_DATE_RANGE}
            >
                Lead Type In Date Range
            </Radio.Button>
        )
        options.push(
            <Radio.Button
                value={LEAD_TYPE_FILTER_CURRENT_STATUS}
                key={LEAD_TYPE_FILTER_CURRENT_STATUS}
            >
                Current Status
            </Radio.Button>
        )

        return options
    }

    const getLeadSubTypes = (parentType) => {
        setLeadSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                parentType +
                '&parentStatusType=' +
                (openOrClose === 'open' ? 'Open' : 'Closed')
        ).then((res) => {
            setLeadSubTypes(res.data)
        })
    }

    const onChangeLeadType = (e) => {
        getLeadSubTypes(e.target.value)
        form.setFieldsValue({ leadSubType: '' })
    }

    const leadSubTypeOptions = () => {
        var options = []
        leadSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    return (
        <>
            <Button style={{ marginLeft: 10 }} onClick={showExportModal}>
                Export
            </Button>

            <Modal
                title={'Export Leads'}
                open={exportModalVisible}
                onOk={exportLeads}
                confirmLoading={exportLoading}
                onCancel={hideExportModal}
                okText={'Export'}
                destroyOnClose={true}
                width={1000}
            >
                {exportModalVisible ? (
                    <>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                            initialValues={{ openOrClosed: openOrClose }}
                        >
                            <Form.Item
                                label="Lead Status"
                                style={{ marginBottom: 10 }}
                                name="openOrClosed"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    onChange={onOpenCloseChange}
                                    value={openOrClose}
                                >
                                    {openCloseOptions()}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="Lead Type Filter"
                                style={{ marginBottom: 10 }}
                                name="leadTypeFilter"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Radio.Group
                                    onChange={onChangeLeadTypeFilter}
                                    value={leadTypeFilter}
                                >
                                    {leadTypeFilterOptions()}
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                label="Lead Type"
                                style={{ marginBottom: 10 }}
                                name="leadType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <Radio.Group onChange={onChangeLeadType}>
                                    {openOrClose === 'open'
                                        ? openStatusTypeOptions()
                                        : closedStatusTypeOptions()}
                                </Radio.Group>
                            </Form.Item>

                            {leadSubTypes.length > 0 ? (
                                <Form.Item
                                    label="Lead Sub Type"
                                    style={{ marginBottom: 5 }}
                                    name="leadSubType"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        {leadSubTypeOptions()}
                                    </Radio.Group>
                                </Form.Item>
                            ) : null}

                            <Form.Item
                                label="Lead Entry Date Range"
                                style={{ marginBottom: 10 }}
                                name="date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <RangePicker
                                    format="DD-MMM-YY"
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateFilterChange}
                                    value={
                                        startDate && endDate
                                            ? [dayjs(startDate), dayjs(endDate)]
                                            : null
                                    }
                                    style={{ width: 250 }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Stream"
                                style={{ marginBottom: 10 }}
                                name="stream"
                                rules={[{ required: false }]}
                            >
                                <Radio.Group
                                    onChange={(e) =>
                                        handleCourseChange(e.target.value)
                                    }
                                >
                                    {streamOptions()}
                                </Radio.Group>
                            </Form.Item>

                            {selectedCourse ? (
                                <Form.Item
                                    label="Level"
                                    style={{ marginBottom: 10 }}
                                    name="level"
                                    rules={[{ required: false }]}
                                >
                                    <Radio.Group>{levelOptions()}</Radio.Group>
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                label="Assigned To"
                                style={{ marginBottom: 10 }}
                                name="assignedTo"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    allowClear={true}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: 350 }}
                                >
                                    {getAdminUserOptions()}
                                </Select>
                            </Form.Item>
                        </Form>
                        <Typography
                            style={{
                                color: 'blue',
                                marginLeft: 30,
                                marginTop: 20,
                            }}
                        >
                            {responseMsg}
                        </Typography>
                    </>
                ) : null}
            </Modal>
        </>
    )
}

export default Export
