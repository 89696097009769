import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Modal, Select } from 'antd'
import {
    // CheckOutlined,
    // CloseOutlined,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'

function FollowupLeadsCountSplitByDay() {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [includeActionLeads] = useState(true)
    const [assignedTo, setAssignedTo] = useState('')
    const [adminUsers, setAdminUsers] = useState([])

    useEffect(() => {
        if (!modalVisible) {
            return
        }

        getData()
        getFiltersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalVisible, includeActionLeads, assignedTo])

    const getFiltersData = () => {
        if (adminUsers.length) {
            return
        }

        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEADS_FILTERS_DATA).then((res) => {
            setAdminUsers(res.data.adminUsers)
        })
    }

    const getDatabuttonClicked = () => {
        setModalVisible(true)
    }

    const hideModal = () => {
        setModalVisible(false)
    }

    const getData = () => {
        setLoading(true)

        var payload = {}
        payload.includeActionLeads = includeActionLeads ? 'yes' : 'no'
        payload.assignedTo = assignedTo

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.LEADS.GET_FOLLOW_UP_LEADS_COUNT_SPLIT_BY_DAY,
            formData
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    const onChangedAssignedTo = (value) => {
        setAssignedTo(value ? value : '')
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    return (
        <>
            <Button style={{ marginLeft: 10 }} onClick={getDatabuttonClicked}>
                Follow Up Leads Split By Day
            </Button>
            <Modal
                title={''}
                open={modalVisible}
                onCancel={hideModal}
                width={500}
                footer={null}
                destroyOnClose={true}
            >
                <div>
                    <b style={{ fontSize: 16 }}>
                        Follow Up Leads Count Split By Day
                    </b>
                    <Button type="link" onClick={getData}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Select
                        placeholder="Assigned To"
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 200 }}
                        onChange={onChangedAssignedTo}
                        value={assignedTo || undefined}
                    >
                        {getAdminUserOptions()}
                    </Select>
                    {/* <span style={{ marginLeft: 20 }}>Action Leads</span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        checked={includeActionLeads}
                        onClick={(enabled) => setIncludeActionLeads(enabled)}
                        disabled={loading}
                        style={{ marginLeft: 10 }}
                    /> */}
                </div>
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(data)}
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </>
    )
}

export default FollowupLeadsCountSplitByDay
