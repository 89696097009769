import { message, Cascader } from 'antd'
import React from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'

class Stream extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            streamData: [],
        }
    }

    componentDidMount() {
        this.getStreamData()
    }
    getStreamData() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE)
            .then((res) => {
                var response = JSON.parse(res.data)
                var modifiedResponse = []
                response.forEach((item) => {
                    var courseObject = {}
                    courseObject['value'] = item.course
                    courseObject['label'] = item.course
                    var levelsArray = []
                    item.levels.forEach((item) => {
                        var levelsObject = {}
                        levelsObject['value'] = item
                        levelsObject['label'] = item
                        levelsArray.push(levelsObject)
                    })
                    courseObject['levels'] = levelsArray
                    modifiedResponse.push(courseObject)
                })
                this.setState({ streamData: modifiedResponse })
            })
            .catch((error) => {
                message.error(error)
            })
    }

    onChange = (value) => {
        this.props.selectedCourse(value)
    }

    render() {
        return (
            <Cascader
                fieldNames={{ children: 'levels' }}
                options={this.state.streamData}
                onChange={this.onChange}
                placeholder="Please select course level"
                style={{ width: 250 }}
                value={
                    this.props.course
                        ? [this.props.course, this.props.level]
                        : null
                }
            />
        )
    }
}

export default Stream
