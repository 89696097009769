import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import { Table, Button, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

function UserTopicReplies(props) {
    const userId = props.userId
    const [loadingForumReplies, setLoadingForumReplies] = useState(false)
    const [forumReplies, setForumReplies] = useState([])
    const [forumRepliesNextUrl, setForumRepliesNextUrl] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        getForumReplies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getForumReplies = () => {
        setLoadingForumReplies(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_REPLIES_USER + '?id=' + userId)
            .then((response) => {
                var res = JSON.parse(response.data)
                setForumReplies(res.data)
                setForumRepliesNextUrl(res.next)
            })
            .finally(() => {
                setLoadingForumReplies(false)
            })
    }

    const getMoreForumReplies = () => {
        if (!forumRepliesNextUrl) {
            return
        }

        setLoadingForumReplies(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + forumRepliesNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setForumReplies((preState) => {
                    return [...preState, ...res.data]
                })
                setForumRepliesNextUrl(res.next)
            })
            .finally(() => {
                setLoadingForumReplies(false)
            })
    }

    const forumRepliesColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 100,
            render: (text, record) =>
                record.enabled ? (
                    <CheckCircleTwoTone style={{ fontSize: 16 }} />
                ) : (
                    <CloseCircleTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 16 }}
                    />
                ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 180,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.date}</span>
            ),
        },
        {
            title: 'Reply',
            dataIndex: 'body',
            key: 'body',
        },
        {
            title: 'Title',
            dataIndex: 'topicTitle',
            key: 'topicTitle',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        navigateToTopicDetails(record.topicId)
                    }}
                    style={{ padding: 0 }}
                >
                    {record.topicTitle}
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteThread(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const navigateToTopicDetails = (id) => {
        navigate('/forumtopicdetails/' + id)
    }

    const deleteThread = (id) => {
        setLoadingForumReplies(true)

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_THREAD, formbodyLevel)
            .then((res) => {
                message.success(res.data)
                getForumReplies()
            })
            .catch((error) => {
                setLoadingForumReplies(false)
            })
    }

    return (
        <div>
            <Table
                loading={loadingForumReplies}
                columns={forumRepliesColumns}
                dataSource={forumReplies}
                pagination={false}
                style={{ marginTop: 8 }}
            />
            {forumRepliesNextUrl ? (
                <Button
                    type="primary"
                    onClick={getMoreForumReplies}
                    style={{ marginTop: 8 }}
                    loading={loadingForumReplies}
                >
                    More
                </Button>
            ) : null}
        </div>
    )
}

export default UserTopicReplies
