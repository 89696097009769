import { Button, Table, Popconfirm, message, Modal, Select } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class ExtensionAttempt extends React.Component {
    state = {
        loading: false,
        data: [],
        modalVisible: false,
        selectedRecord: {},
        attemptNames: [],
        currentAttempt: '',
        extendToAttempt: '',
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        this.setState({ loading: true })
        RestApi.doGet(GLOBAL.URL.GET_EXTENSION_ATTEMPTS)
            .then((res) => {
                this.setState({
                    data: res.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisable = (record) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.ENABLE_EXTENSION_ATTEMPT
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_EXTENSION_ATTEMPT
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)

                var data = this.state.data
                data.forEach((item) => {
                    if (record.id === item.id) {
                        item.enabled = !record.enabled
                        return
                    }
                })

                this.setState({
                    data: data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    showModal = (record) => {
        if (this.state.attemptNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS).then((res) => {
                var attemptNames = []
                res.data.forEach((attempt) => {
                    attemptNames.push(attempt.attempt)
                })

                this.setState({ attemptNames: attemptNames })
            })
        }

        this.setState({
            selectedRecord: record ? record : {},
            modalVisible: true,
            currentAttempt: record ? record.attempt : '',
            extendToAttempt: record ? record.targetAttempt : '',
        })
    }

    hideModal = (record) => {
        this.setState({ modalVisible: false })
    }

    addOrUpdate = () => {
        if (!this.state.currentAttempt) {
            message.error('Select Current Attempt!')
            return
        }

        if (!this.state.extendToAttempt) {
            message.error('Select Extend To Attempt')
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.attemptName = this.state.currentAttempt
        payload.targetAttemptName = this.state.extendToAttempt

        var url = GLOBAL.URL.ADD_EXTENSION_ATTEMPT
        if (this.state.selectedRecord.id) {
            url = GLOBAL.URL.UPDATE_EXTENSION_ATTEMPT
            payload.id = this.state.selectedRecord.id
        }

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(url, formData)
            .then((response) => {
                message.success(response.data)
                this.hideModal()
                this.getData()
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    delete = (id) => {
        var payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_EXTENSION_ATTEMPT, formData)
            .then((response) => {
                message.success(response.data)
                this.getData()
            })
            .catch((error) => {})
    }

    navigateToPricing = (record) => {
        this.props.navigate('/extension/price/' + record.id, {
            state: { attemptMappingData: record },
        })
    }

    render() {
        const columns = [
            {
                title: 'Edit',
                dataIndex: 'edit',
                key: 'edit',
                width: 50,
                render: (text, record) => (
                    <span>
                        <Button
                            type="link"
                            onClick={() => {
                                this.showModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditOutlined
                                theme="twoTone"
                                style={{ fontSize: 14 }}
                            />
                        </Button>
                    </span>
                ),
            },
            {
                title: 'Enabled',
                dataIndex: 'enabled',
                key: 'id',
                width: 80,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.enableDisable(record)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                            }}
                        >
                            {record.enabled ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'Pricing',
                dataIndex: 'pricing',
                key: 'pricing',
                width: 150,
                render: (text, record) => (
                    <span>
                        <Button
                            type="link"
                            onClick={() => this.navigateToPricing(record)}
                        >
                            Pricing
                        </Button>
                    </span>
                ),
            },
            {
                title: 'Current Attempt',
                dataIndex: 'attempt',
                key: 'attempt',
                width: 150,
            },
            {
                title: 'Extend To Attempt',
                dataIndex: 'targetAttempt',
                key: 'targetAttempt',
                width: 150,
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.delete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{ border: 'none', color: 'red' }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                ),
            },
        ]

        const { Option } = Select
        var attemptDetails = []
        this.state.attemptNames.forEach((item) => {
            attemptDetails.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return (
            <div>
                <h2>Course Extension Attempts</h2>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => this.showModal()}
                    loading={this.state.loading}
                >
                    Add
                </Button>
                <Table
                    columns={columns}
                    dataSource={this.state.data ? this.state.data : null}
                    pagination={false}
                    loading={this.state.loading}
                    size="small"
                    style={{ marginTop: 8 }}
                    rowKey="id"
                />
                <Modal
                    title={
                        this.state.selectedRecord.id
                            ? 'Update Extension Attempt'
                            : 'Add Extension Attempt'
                    }
                    open={this.state.modalVisible}
                    onOk={() => {
                        this.addOrUpdate()
                    }}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideModal}
                    okText={this.state.selectedRecord.id ? 'Update' : 'Add'}
                    destroyOnClose={true}
                >
                    <h3>Current Attempt:</h3>
                    <Select
                        placeholder="Select Current Attempt"
                        showSearch
                        style={{ width: 200 }}
                        value={this.state.currentAttempt}
                        onChange={(value) =>
                            this.setState({ currentAttempt: value })
                        }
                    >
                        {attemptDetails}
                    </Select>
                    <h3 style={{ marginTop: 15 }}>Extend to Attempt:</h3>
                    <Select
                        placeholder="Select Extend to Attempt"
                        showSearch
                        style={{ width: 200 }}
                        value={this.state.extendToAttempt}
                        onChange={(value) =>
                            this.setState({ extendToAttempt: value })
                        }
                    >
                        {attemptDetails}
                    </Select>
                </Modal>
            </div>
        )
    }
}

export default ExtensionAttempt
