import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    notification,
    Select,
    InputNumber,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'

function StudyPlannerConfig() {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [configNames, setConfigNames] = useState([])

    useEffect(() => {
        getConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getConfig = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.STUDY_PLANNER.GET_CONFIG)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        if (configNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.STUDY_PLANNER.GET_CONFIG_NAMES).then(
                (res) => {
                    setConfigNames(res.data)
                }
            )
        }

        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.name) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Config Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => (
                <span>
                    {record.valueString ? record.valueString : record.valueInt}
                </span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span>
                    {record.updatedBy.firstName}{' '}
                    <span style={{ fontSize: 10 }}>
                        {' (' + record.updatedAt + ')'}
                    </span>
                </span>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateConfig = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.STUDY_PLANNER.ADD_CONFIG
                if (selectedRecord.name) {
                    url = GLOBAL.URL.STUDY_PLANNER.UPDATE_CONFIG
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getConfig()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const configNameOptions = () => {
        var options = []
        configNames.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    return (
        <div>
            <h2>Study Planner Config</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="key"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure'}
                open={addEditModalVisible}
                onOk={addOrUpdateConfig}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.name ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select placeholder="Select" showSearch>
                            {configNameOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Integer Value"
                        name="valueInt"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default StudyPlannerConfig
