import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button } from 'antd'
import StatsConstants from './StatsConstants'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

class ActiveUserStats extends React.Component {
    state = {
        loading: false,
        data: [],
        labels: [],
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        dataDays: [],
        labelsDays: [],
        mapDataDays: StatsConstants.INITIAL_BAR_OPTIONS,
        optionsDays: {},
        limit: 0,
    }
    componentDidMount() {
        this.getData()
    }

    getData() {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.GET_ACTIVE_USERS_COUNT_BY_MONTH
        if (this.state.limit > 0) {
            url = url + '?limit=' + this.state.limit
        }

        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var data = []
                var response = JSON.parse(res.data)
                response.forEach((row) => {
                    labels.push(row.label)
                    data.push(row.value)
                })
                this.setState({
                    labels: labels,
                    data: data,
                })
                this.drawChart(data, labels)
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }
    drawChart(data = this.state.data, labels = this.state.labels) {
        var newSignupDataSet = {}
        newSignupDataSet.data = data
        newSignupDataSet.label = 'Count'
        newSignupDataSet.backgroundColor = '#4bc0c0'
        newSignupDataSet.borderColor = '#4bc0c0'
        newSignupDataSet.fill = true

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(newSignupDataSet)

        var options = StatsConstants.CHART_OPTIONS
        options.plugins.title.text = 'Active Users by Month'
        options.onClick = (evt, item) => {
            this.getMonthSplit(item)
        }

        this.setState({
            mapData: mapData,
            options: options,
        })
    }
    onChange = (value) => {
        this.setState({
            limit: value,
        })
    }
    onClick = () => {
        this.getData()
    }
    getMonthSplit = (item) => {
        var url =
            GLOBAL.URL.GET_ACTIVE_USERS_COUNT_BY_MONTH +
            '/' +
            this.state.labels[item[0].index]
        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var data = []
                var response = JSON.parse(res.data)
                response.forEach((row) => {
                    labels.push(row.label)
                    data.push(row.value)
                })
                this.setState({
                    labelsDays: labels,
                    dataDays: data,
                })
                this.drawDayChart(data, labels)
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }
    drawDayChart(
        dataDays = this.state.dataDays,
        labelsDays = this.state.labelsDays
    ) {
        var newSignupDataSet = {}
        newSignupDataSet.data = dataDays
        newSignupDataSet.label = 'Count'
        newSignupDataSet.backgroundColor = '#36a2eb'
        newSignupDataSet.borderColor = '#36a2eb'
        newSignupDataSet.fill = true

        var mapData = {}
        mapData.labels = labelsDays
        mapData.datasets = []
        mapData.datasets.push(newSignupDataSet)

        var options = StatsConstants.CHART_OPTIONS
        options.plugins.title.text = 'Active Users By Day'

        this.setState({
            mapDataDays: mapData,
            optionsDays: options,
        })

        document
            .getElementById('dayChart')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    render() {
        return (
            <div>
                <InputNumber
                    min={1}
                    defaultValue={6}
                    onChange={this.onChange}
                />
                <Button
                    type="primary"
                    onClick={this.onClick}
                    loading={this.state.loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch
                </Button>
                <Bar
                    data={this.state.mapData}
                    width={800}
                    height={400}
                    options={this.state.options}
                />
                <Bar
                    id="dayChart"
                    data={this.state.mapDataDays}
                    width={800}
                    height={400}
                    options={this.state.optionsDays}
                />
            </div>
        )
    }
}

export default ActiveUserStats
