import {
    Input,
    Button,
    Table,
    Popconfirm,
    message,
    Form,
    Modal,
    Select,
    InputNumber,
} from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'

const DemoVideos = (props) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [courseName, setCourseName] = useState('')
    const [courseNames, setCourseNames] = useState([])
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        var courseNameNew = props.location.state
            ? props.location.state.courseName
            : ''
        setCourseName(courseNameNew)
        getData(courseNameNew)
        getCourseNames()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getData = (courseNameData = courseName) => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_DEMO_VIDEOS +
                '?courseName=' +
                encodeURIComponent(courseNameData)
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCourseNames = () => {
        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
            setCourseNames(res.data)
        })
    }

    const courseNameSelected = (courseName) => {
        setCourseName(courseName ? courseName : '')
        getData(courseName)
    }

    const add = () => {
        setSelectedRecord({})
        form.resetFields()
        showAddEditModal()
    }

    const edit = (record) => {
        if (!record) {
            record = {}
        }

        setSelectedRecord(record)

        form.setFieldsValue(record)
        showAddEditModal()
    }

    const showAddEditModal = () => {
        setAddEditModalVisible(true)
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_DEMO_VIDEO
                if (selectedRecord.id) {
                    url = GLOBAL.URL.UPDATE_DEMO_VIDEO
                    values['id'] = selectedRecord.id
                }

                values['courseName'] = courseName

                let payload = new FormData()
                payload.append('payload', JSON.stringify(values))
                RestApi.doPost(url, payload)
                    .then((response) => {
                        message.destroy()
                        message.success(response.data)

                        hideAddEditModal()
                        getData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteItem = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))

        RestApi.doPost(GLOBAL.URL.DELETE_DEMO_VIDEO, postBody)
            .then((response) => {
                setData((prev) => prev.filter((key) => key.id !== id))
                message.destroy()
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_DEMO_VIDEO
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_DEMO_VIDEO
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.destroy()
                message.success(res.data)

                var dataNew = data
                dataNew.forEach((item) => {
                    if (record.id === item.id) {
                        item.enabled = !record.enabled
                        return
                    }
                })

                setData(dataNew)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
            },
        ]

        var reorderData = []
        data.forEach((item) => {
            var row = {}
            row.id = item.id
            row.title = item.title

            reorderData.push(row)
        })

        props.navigate('/reorder', {
            state: {
                reorderData: reorderData,
                updateUrl: GLOBAL.URL.REORDER_DEMO_VIDEOS,
                columns: columns,
            },
        })
    }

    const columns = [
        {
            title: 'Enabled',
            dataIndex: 'id',
            key: 'id',
            width: 75,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{ border: 'none', padding: 0 }}
                    onClick={() => {
                        enableDisable(record)
                    }}
                >
                    {record.enabled ? (
                        <CheckCircleTwoTone style={{ fontSize: 18 }} />
                    ) : (
                        <CloseCircleTwoTone
                            style={{ fontSize: 18 }}
                            twoToneColor="#eb2f96"
                        />
                    )}
                </Button>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Url',
            dataIndex: 'url',
            key: 'url',
            render: (text, record) => (
                <a
                    href={record.url}
                    style={{ display: 'table-cell' }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {record.url}
                </a>
            ),
        },
        {
            title: 'Duration (secs)',
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 150,
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ paddingLeft: 0 }}
                        onClick={() => edit(record)}
                    >
                        Edit
                    </Button>{' '}
                    |
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => {
                            deleteItem(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">Delete</Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const { Option } = Select
    var courseDetails = []
    courseNames.forEach((item) => {
        courseDetails.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    return (
        <div>
            <h3>Demo Videos</h3>
            <Select
                placeholder="Filter by Course"
                showSearch
                style={{ width: 500 }}
                onChange={courseNameSelected}
                allowClear={true}
                value={courseName}
            >
                {courseDetails}
            </Select>
            <div>
                <Button
                    type="primary"
                    onClick={() => add()}
                    size="small"
                    loading={loading}
                    style={{ marginTop: 10 }}
                >
                    Add
                </Button>
                <Button
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    loading={loading}
                    onClick={reorder}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                style={{ marginTop: 10 }}
                rowKey="id"
            />
            <Modal
                title={
                    'Add Demo Video' + (courseName ? ' to ' + courseName : '')
                }
                open={addEditModalVisible}
                onOk={() => {
                    handleSubmit()
                }}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter title!',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter title"
                            autoFocus
                            ref={inputRef}
                        />
                    </Form.Item>
                    <Form.Item
                        label="URL"
                        name="url"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter url!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter url" />
                    </Form.Item>
                    <Form.Item
                        label="Duration (secs)"
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter duration!',
                            },
                        ]}
                    >
                        <InputNumber placeholder="Duration" min={1} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default DemoVideos
