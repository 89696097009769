import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    message,
    Modal,
    Input,
    Popconfirm,
    Select,
    Tag,
    notification,
    Dropdown,
    Menu,
} from 'antd'
import { CopyOutlined, EditTwoTone } from '@ant-design/icons'
import { Link } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

class CourseModules extends React.Component {
    state = {
        loading: false,
        loadingSearchModules: false,
        data: [],
        courseName: '',
        newModuleName: '',
        addModuleVisible: false,
        addModuleLoading: false,
        addExistingModuleModalVisible: false,
        searchModules: [],
        cloneModuleName: '',
        editNameModalVisible: false,
        selectedRecord: {},
        updatedModuleName: '',
        excludeModalVisible: false,
        selectedExcludeRecord: {},
        excludeAttempts: [],
        attempts: [],
        selectedAttemptToExclude: '',
        courseId: '',
        totalDuration: '',
        enabledDuration: '',
        deepLink: '',
        moduleLinkedCourses: [],
        linkedCoursesModalVisible: false,
        modalLoading: false,
        exportCourseStructureLoading: false,
        copyAllModulesLoading: false,
        copyAllModulesModalVisible: false,
        searchCourses: [],
        loadingSearchCourses: false,
    }

    componentDidMount() {
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.location.state.courseId !==
            this.props.location.state.courseId
        ) {
            this.hideLinkedCoursesModal()
            this.getData()
        }
    }

    getData() {
        const { courseId } = this.props.location.state
        this.setState({
            loading: true,
            courseId: courseId,
        })

        RestApi.doGet(
            GLOBAL.URL.GET_MODULES_IN_COURSE + encodeURIComponent(courseId)
        )
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    data: response.modules,
                    courseName: response.courseName,
                    totalDuration: response.totalDuration,
                    enabledDuration: response.enabledDuration,
                    deepLink: response.deepLink,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    navigateToCourses = () => {
        this.props.navigate('/courses')
    }

    enableDisableModule = (record) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.ENABLE_MODULE
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_MODULE
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var data = this.state.data
                    data.forEach((module) => {
                        if (module.id === record.id) {
                            module.enabled = !record.enabled
                        }
                    })

                    this.setState({
                        data: data,
                    })

                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    enableDisableStudyPlanner = (record) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.ENABLE_MODULE_STUDY_PLANNER
        if (record.studyPlannerEnabled) {
            url = GLOBAL.URL.DISABLE_MODULE_STUDY_PLANNER
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var data = this.state.data
                    data.forEach((module) => {
                        if (module.id === record.id) {
                            module.studyPlannerEnabled =
                                !record.studyPlannerEnabled
                        }
                    })

                    this.setState({
                        data: data,
                    })

                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    deleteModule = (record) => {
        this.setState({
            loading: true,
        })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.moduleId }))
        RestApi.doPost(GLOBAL.URL.DELETE_MODULE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    unlinkModule = (record) => {
        this.setState({
            loading: true,
        })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        RestApi.doPost(GLOBAL.URL.UNLINK_MODULE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    showAddModule = () => {
        this.setState({
            addModuleVisible: true,
        })
    }

    hideAddModule = () => {
        this.setState({
            addModuleVisible: false,
        })
    }

    showAddExistingModuleModal = () => {
        this.setState({
            searchModules: [],
            addExistingModuleModalVisible: true,
            cloneModuleName: '',
        })
    }

    hideAddExistingModuleModal = () => {
        this.setState({
            addExistingModuleModalVisible: false,
        })
    }

    addModule = () => {
        if (!this.state.newModuleName) {
            message.error('Enter Module Name')
            return
        }

        this.setState({
            addModuleLoading: true,
        })

        var payload = {}
        payload.isExistingModule = false
        payload.moduleName = this.state.newModuleName
        payload.courseId = this.state.courseId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_MODULE_TO_COURSE, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success('Added!')
                    this.hideAddModule()
                    this.getData()
                }
            })
            .finally(() => {
                this.setState({
                    addModuleLoading: false,
                })
            })
    }

    searchModule = (value) => {
        if (!value) {
            message.error('Enter Module Name')
            return
        }

        this.setState({
            loadingSearchModules: true,
        })

        RestApi.doGet(GLOBAL.URL.SEARCH_MODULE + encodeURIComponent(value))
            .then((res) => {
                this.setState({
                    searchModules: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loadingSearchModules: false,
                })
            })
    }

    addExistingModule = (id) => {
        this.setState({
            addModuleLoading: true,
            loadingSearchModules: true,
        })

        var payload = {}
        payload.isExistingModule = true
        payload.moduleId = id
        payload.courseId = this.state.courseId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_MODULE_TO_COURSE, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success('Added!')
                    this.hideAddExistingModuleModal()
                    this.getData()
                }
            })
            .finally(() => {
                this.setState({
                    addModuleLoading: false,
                    loadingSearchModules: false,
                })
            })
    }

    cloneModule = (id) => {
        if (!this.state.cloneModuleName) {
            message.error('Enter the new clone module name!')
            return
        }

        this.setState({
            addModuleLoading: true,
            loadingSearchModules: true,
        })

        var payload = {}
        payload.moduleName = this.state.cloneModuleName
        payload.copyModuleId = id
        payload.courseId = this.state.courseId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.COPY_MODULE_TO_COURSE, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success(response.data)
                    this.hideAddExistingModuleModal()
                    this.getData()
                }
            })
            .finally(() => {
                this.setState({
                    addModuleLoading: false,
                    loadingSearchModules: false,
                })
            })
    }

    reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'moduleName',
                key: 'moduleName',
            },
        ]

        this.props.navigate('/reorder', {
            state: {
                reorderData: this.state.data,
                updateUrl: GLOBAL.URL.REORDER_MODULES_IN_COURSE,
                columns: columns,
            },
        })
    }

    showEditNameModal = (record) => {
        this.setState({
            editNameModalVisible: true,
            selectedRecord: record,
            updatedModuleName: record.moduleName,
        })
    }

    hideEditNameModal = () => {
        this.setState({ editNameModalVisible: false })
    }

    updateModuleName = () => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = this.state.selectedRecord.id
        payload.moduleName = this.state.updatedModuleName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_MODULE_NAME, formbody)
            .then((response) => {
                message.success(response.data)
                this.hideEditNameModal()
                this.getData()
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    showEditStudyPlannerNameModal = (record) => {
        this.setState({
            editStudyPlannerNameModalVisible: true,
            selectedRecord: record,
            updatedStudyPlannerName: record.studyPlannerName,
        })
    }

    hideEditStudyPlannerNameModal = () => {
        this.setState({ editStudyPlannerNameModalVisible: false })
    }

    updateStudyPlannerName = () => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = this.state.selectedRecord.id
        payload.studyPlannerName = this.state.updatedStudyPlannerName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_MODULE_STUDY_PLANNER_NAME, formbody)
            .then((response) => {
                message.success(response.data)
                this.hideEditStudyPlannerNameModal()
                this.getData()
            })
            .catch(() => {
                this.setState({ loading: false })
            })
    }

    showExcludeModal = (record) => {
        this.setState(
            {
                selectedAttemptToExclude: '',
                selectedExcludeRecord: record,
            },
            () => {
                this.getExcludedAttempts()
            }
        )

        if (this.state.attempts.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
                var attempts = []
                res.data.forEach((item) => {
                    attempts.push(item.attempt)
                })
                this.setState({ attempts: attempts })
            })
        }
    }

    getExcludedAttempts = () => {
        this.setState({ loading: true })
        RestApi.doGet(
            GLOBAL.URL.GET_MODULES_ATTEMPT_EXCLUSIONS +
                this.state.selectedExcludeRecord.id
        )
            .then((res) => {
                this.setState({
                    excludeAttempts: res.data,
                    excludeModalVisible: true,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    hideExcludeModal = () => {
        this.setState({ excludeModalVisible: false })
    }

    excludeAttempt = () => {
        if (!this.state.selectedAttemptToExclude) {
            message.error('Select attempt to exclude!')
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.moduleCourseMappingId = this.state.selectedExcludeRecord.id
        payload.attempt = this.state.selectedAttemptToExclude

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_MODULE_ATTEMPT_EXCLUSION, formbody)
            .then((response) => {
                message.success(response.data)
                this.getExcludedAttempts()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    deleteExcludeAttempt = (id) => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = id

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_MODULE_ATTEMPT_EXCLUSION, formbody)
            .then((response) => {
                message.success(response.data)
                this.getExcludedAttempts()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    showLinkedCoursesModal = (record) => {
        this.setState({
            linkedCoursesModalVisible: true,
            modalLoading: true,
            selectedRecord: record,
            moduleLinkedCourses: [],
        })

        RestApi.doGet(GLOBAL.URL.GET_MODULE_LINKED_COURSES + record.moduleId)
            .then((res) => {
                this.setState({
                    moduleLinkedCourses: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    modalLoading: false,
                })
            })
    }

    hideLinkedCoursesModal = () => {
        this.setState({
            linkedCoursesModalVisible: false,
        })
    }

    exportStructure = () => {
        this.setState({ exportCourseStructureLoading: true })

        RestApi.download(
            GLOBAL.URL.EXPORT_COURSE_STRUCTURE + this.state.courseId
        )
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Course_Structure.xlsx')
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)
            })
            .finally(() => {
                this.setState({
                    exportCourseStructureLoading: false,
                })
            })
    }

    showCopyAllModulesModal = () => {
        this.setState({
            copyAllModulesModalVisible: true,
        })
    }

    hideCopyAllModulesModal = () => {
        this.setState({
            copyAllModulesModalVisible: false,
        })
    }

    searchCourse = (value) => {
        if (!value) {
            message.error('Enter Course Name')
            return
        }

        this.setState({
            loadingSearchCourses: true,
        })

        RestApi.doGet(
            GLOBAL.URL.GET_ALL_COURSES +
                '?query=' +
                encodeURIComponent(value) +
                '&populateDuration=no'
        )
            .then((res) => {
                this.setState({
                    searchCourses: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loadingSearchCourses: false,
                })
            })
    }

    copyAllModules = (sourceCourseId) => {
        this.setState({
            copyAllModulesLoading: true,
        })

        var payload = {}
        payload.sourceCourseId = sourceCourseId
        payload.targetCourseId = this.state.courseId

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.COPY_ALL_MODULES_FROM_COURSE, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)
                this.hideCopyAllModulesModal()
                this.getData()
            })
            .finally(() => {
                this.setState({
                    copyAllModulesLoading: false,
                })
            })
    }

    render() {
        const { Search } = Input
        const { Option } = Select

        const colums = [
            {
                title: 'Enabled',
                dataIndex: 'enabled',
                key: 'enabled',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.enableDisableModule(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                padding: 0,
                                display: 'block',
                                margin: 'auto',
                            }}
                        >
                            {record.enabled ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'Module Name',
                dataIndex: 'moduleName',
                key: 'moduleName',
                render: (text, record, index) => (
                    <span>
                        <Button
                            type="link"
                            onClick={() => {
                                this.showEditNameModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 11 }} />
                        </Button>
                        <Link
                            href={'/modulesections/' + record.moduleId}
                            onClick={(event) => {
                                event.preventDefault()
                                this.props.navigate(
                                    '/modulesections/' + record.moduleId,
                                    {
                                        state: {
                                            courseName: this.state.courseName,
                                            courseId: this.state.courseId,
                                            moduleName: record.moduleName,
                                        },
                                    }
                                )
                            }}
                            underline="none"
                            style={{ marginLeft: 10 }}
                        >
                            {index + 1}
                            {'. '}
                            {record.moduleName}
                        </Link>
                    </span>
                ),
            },
            {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration',
            },
            {
                title: 'Study Planner',
                dataIndex: 'studyPlannerEnabled',
                key: 'studyPlannerEnabled',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                this.enableDisableStudyPlanner(record)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{
                                    border: 'none',
                                    padding: 0,
                                    fontSize: 10,
                                }}
                            >
                                {record.studyPlannerEnabled ? (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color={GLOBAL.COLORAPP.BLUE}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color="red"
                                    />
                                )}
                            </Button>
                        </Popconfirm>
                        <span style={{ marginLeft: 10 }}>
                            <Button
                                type="link"
                                onClick={() => {
                                    this.showEditStudyPlannerNameModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditTwoTone style={{ fontSize: 10 }} />
                            </Button>
                            <span>{record.studyPlannerName}</span>
                        </span>
                    </span>
                ),
            },
            {
                title: 'Options',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item>
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={() =>
                                            this.showLinkedCoursesModal(record)
                                        }
                                    >
                                        Linked Courses
                                    </Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <Button
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={() =>
                                            this.showExcludeModal(record)
                                        }
                                    >
                                        Exclude
                                    </Button>
                                </Menu.Item>
                                <Menu.Item>
                                    <Popconfirm
                                        title="Confirm delete action?"
                                        onConfirm={() =>
                                            this.deleteModule(record)
                                        }
                                        okText="Delete"
                                        cancelText="Cancel"
                                    >
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                        >
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                </Menu.Item>
                                <Menu.Item>
                                    <Popconfirm
                                        title="Confirm unlink action?"
                                        onConfirm={() =>
                                            this.unlinkModule(record)
                                        }
                                        okText="Unlink"
                                        cancelText="Cancel"
                                    >
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                        >
                                            Unlink
                                        </Button>
                                    </Popconfirm>
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomCenter"
                        arrow
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12 }}
                        >
                            {'Options'}
                        </Button>
                    </Dropdown>
                ),
            },
        ]

        const moduleLinkedCoursesColumns = [
            {
                title: '#',
                dataIndex: 'courseId',
                key: 'courseId',
                render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
                title: 'Course Name',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => (
                    <Link
                        href={'/modules/' + record.courseId}
                        onClick={(event) => {
                            event.preventDefault()
                            this.props.navigate('/modules/' + record.courseId, {
                                state: { courseId: record.courseId },
                            })
                        }}
                        underline="none"
                    >
                        {record.name}
                    </Link>
                ),
            },
        ]

        const moduleSearchColums = [
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                width: 150,
                render: (text, record) => (
                    <span>
                        <Button
                            type="primary"
                            onClick={() => this.addExistingModule(record.id)}
                            size="small"
                        >
                            Add
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => this.cloneModule(record.id)}
                            size="small"
                            style={{ marginLeft: 10 }}
                        >
                            Clone
                        </Button>
                    </span>
                ),
            },
            {
                title: 'Module Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        const courseSearchColums = [
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                width: 80,
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                this.copyAllModules(record.courseId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                size="small"
                                loading={this.state.copyAllModulesLoading}
                            >
                                Copy
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
            {
                title: 'Course Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        const excludedAttemptsColums = [
            {
                title: 'Excluded Attempt',
                dataIndex: 'attempt',
                key: 'attempt',
            },
            {
                title: 'Action',
                dataIndex: 'id',
                key: 'id',
                width: 150,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.deleteExcludeAttempt(record.id)}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            Delete
                        </Button>
                    </Popconfirm>
                ),
            },
        ]

        var attemptOptions = []
        this.state.attempts.forEach((item) => {
            attemptOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return (
            <div>
                <div>
                    <h2>
                        {this.state.courseName}
                        {this.state.deepLink ? (
                            <CopyToClipboard
                                text={this.state.deepLink}
                                onCopy={() =>
                                    notification['success']({
                                        message: 'Link copied!',
                                    })
                                }
                                style={{ marginLeft: 15 }}
                            >
                                <span>
                                    <Button type="link" style={{ padding: 0 }}>
                                        <CopyOutlined />
                                        {' Deep Link'}
                                    </Button>
                                </span>
                            </CopyToClipboard>
                        ) : null}
                    </h2>
                    <Button
                        type="default"
                        onClick={() => {
                            this.props.navigate('/courses')
                        }}
                        size="small"
                    >
                        Search Course
                    </Button>
                    <Button
                        size="small"
                        type="default"
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            this.props.navigate(
                                '/course/configure/' + this.state.courseId
                            )
                        }}
                    >
                        Configure This Course
                    </Button>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="default"
                        size="small"
                        onClick={this.showAddModule}
                        disabled={this.state.addModuleLoading}
                    >
                        Add New Module
                    </Button>
                    <Button
                        type="default"
                        size="small"
                        style={{
                            marginLeft: 10,
                        }}
                        loading={this.state.addModuleLoading}
                        onClick={this.reorder}
                    >
                        Reorder
                    </Button>
                    <Button
                        type="default"
                        style={{
                            marginLeft: 10,
                        }}
                        size="small"
                        disabled={this.state.addModuleLoading}
                        onClick={this.showAddExistingModuleModal}
                    >
                        Add Existing Module
                    </Button>
                    <Button
                        type="default"
                        style={{
                            marginLeft: 10,
                        }}
                        size="small"
                        disabled={this.state.copyAllModulesLoading}
                        onClick={this.showCopyAllModulesModal}
                    >
                        Copy All Modules
                    </Button>
                    <span style={{ marginLeft: 50 }}>
                        Enabled / Total:{' '}
                        <Tag color="geekblue">
                            {this.state.enabledDuration +
                                ' / ' +
                                this.state.totalDuration}
                        </Tag>
                    </span>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => this.exportStructure()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            style={{ float: 'right' }}
                            size="small"
                            loading={this.state.exportCourseStructureLoading}
                        >
                            Export Structure
                        </Button>
                    </Popconfirm>
                </div>
                <div>
                    {this.state.addModuleVisible ? (
                        <div>
                            <Input
                                placeholder="Enter Module Name"
                                autoFocus
                                style={{ marginTop: 10 }}
                                onChange={(e) => {
                                    this.setState({
                                        newModuleName: e.target.value,
                                    })
                                }}
                            />
                            <Button
                                type="primary"
                                size="small"
                                style={{ marginTop: 10 }}
                                onClick={this.addModule}
                                loading={this.state.addModuleLoading}
                            >
                                Add
                            </Button>
                            <Button
                                size="small"
                                style={{ marginTop: 10, marginLeft: 10 }}
                                onClick={this.hideAddModule}
                                disabled={this.state.addModuleLoading}
                            >
                                Cancel
                            </Button>
                        </div>
                    ) : null}
                </div>

                <Table
                    columns={colums}
                    dataSource={this.state.data ? this.state.data : null}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    loading={this.state.loading}
                    rowKey="moduleName"
                    size="small"
                />

                <Modal
                    title="Add Existing Module"
                    open={this.state.addExistingModuleModalVisible}
                    onCancel={this.hideAddExistingModuleModal}
                    footer={null}
                    width={800}
                    destroyOnClose={true}
                >
                    <Search
                        placeholder="Search Module"
                        onSearch={(value) => this.searchModule(value)}
                        autoFocus
                    />
                    <Input
                        placeholder="Clone Module New Name"
                        style={{ marginTop: 10 }}
                        onChange={(e) => {
                            this.setState({ cloneModuleName: e.target.value })
                        }}
                    />
                    <Table
                        columns={moduleSearchColums}
                        dataSource={
                            this.state.searchModules
                                ? this.state.searchModules
                                : null
                        }
                        pagination={false}
                        loading={this.state.loadingSearchModules}
                        scroll={{ y: 400 }}
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                </Modal>
                <Modal
                    title="Copy All Modules From Course"
                    open={this.state.copyAllModulesModalVisible}
                    onCancel={this.hideCopyAllModulesModal}
                    footer={null}
                    width={800}
                    destroyOnClose={true}
                >
                    <Search
                        placeholder="Search Course"
                        onSearch={(value) => this.searchCourse(value)}
                        autoFocus
                    />
                    <Table
                        columns={courseSearchColums}
                        dataSource={this.state.searchCourses}
                        pagination={false}
                        loading={this.state.loadingSearchCourses}
                        scroll={{ y: 400 }}
                        style={{ marginTop: 10 }}
                        rowKey="id"
                    />
                </Modal>
                <Modal
                    title="Edit Module Name"
                    open={this.state.editNameModalVisible}
                    onOk={() => {
                        this.updateModuleName()
                    }}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideEditNameModal}
                    okText="Update"
                    destroyOnClose={true}
                >
                    <h3>Current name:</h3>
                    <span>{this.state.selectedRecord.moduleName}</span>
                    <h3 style={{ marginTop: 15 }}>Updated name:</h3>
                    <Input
                        value={this.state.updatedModuleName}
                        autoFocus
                        onChange={(e) => {
                            this.setState({
                                updatedModuleName: e.target.value,
                            })
                        }}
                    ></Input>
                </Modal>
                <Modal
                    title="Edit Study Planner Name"
                    open={this.state.editStudyPlannerNameModalVisible}
                    onOk={() => {
                        this.updateStudyPlannerName()
                    }}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideEditStudyPlannerNameModal}
                    okText="Update"
                    destroyOnClose={true}
                >
                    {this.state.selectedRecord.studyPlannerName ? (
                        <>
                            <h3>Current name:</h3>
                            <span>
                                {this.state.selectedRecord.studyPlannerName}
                            </span>
                        </>
                    ) : null}
                    <h3 style={{ marginTop: 15 }}>New Study Planner Name:</h3>
                    <Input
                        value={this.state.updatedStudyPlannerName}
                        autoFocus
                        onChange={(e) => {
                            this.setState({
                                updatedStudyPlannerName: e.target.value,
                            })
                        }}
                    ></Input>
                </Modal>
                <Modal
                    title={this.state.selectedExcludeRecord.moduleName}
                    open={this.state.excludeModalVisible}
                    onOk={() => {
                        this.updateModuleName()
                    }}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideExcludeModal}
                    destroyOnClose={true}
                    footer={null}
                >
                    <h4>Select attempt to exclude</h4>
                    <Select
                        placeholder="Select Attempt"
                        showSearch
                        style={{ width: 300 }}
                        onChange={(value) =>
                            this.setState({ selectedAttemptToExclude: value })
                        }
                    >
                        {attemptOptions}
                    </Select>
                    <Button
                        type="primary"
                        size="small"
                        style={{ marginLeft: 10 }}
                        loading={this.state.loading}
                        onClick={this.excludeAttempt}
                    >
                        Exclude
                    </Button>
                    <Table
                        columns={excludedAttemptsColums}
                        dataSource={this.state.excludeAttempts}
                        pagination={false}
                        loading={this.state.loading}
                        rowKey="id"
                        style={{ marginTop: 10 }}
                        size="small"
                    />
                </Modal>
                <Modal
                    title={'Linked Courses'}
                    open={this.state.linkedCoursesModalVisible}
                    onCancel={this.hideLinkedCoursesModal}
                    destroyOnClose={true}
                    footer={null}
                >
                    <h4>{this.state.selectedRecord.moduleName}</h4>
                    <Table
                        columns={moduleLinkedCoursesColumns}
                        dataSource={this.state.moduleLinkedCourses}
                        pagination={false}
                        loading={this.state.modalLoading}
                        rowKey="courseId"
                        style={{ marginTop: 10 }}
                        size="small"
                    />
                </Modal>
            </div>
        )
    }
}

export default CourseModules
