import React from 'react'
import { Link, Box, Typography } from '@mui/material'
import { searchStyles } from './styles'

const ResultCard = ({ item }) => {
    const { classes } = searchStyles()

    const handleResultItemClick = (event) => {
        event.preventDefault()
        window.open(item.websiteLink, '_blank', 'noopener,noreferrer')
    }

    return (
        <Link
            sx={{ textDecoration: 'none' }}
            href={item.websiteLink}
            onClick={handleResultItemClick}
        >
            <Box className={classes.resultItem}>
                <Typography className={classes.resultTitle}>
                    {item.title}
                </Typography>
                <p
                    dangerouslySetInnerHTML={{
                        __html: item.htmlSnippet,
                    }}
                    className={classes.snippetTxt}
                />
            </Box>
        </Link>
    )
}

export default ResultCard
