import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    DatePicker,
    Checkbox,
    InputNumber,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import { EditTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'
import { Link } from '@mui/material'

function FacultyContract(props) {
    const { Option } = Select
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [faculties, setFaculties] = useState([])
    const [contracts, setContracts] = useState([])
    const [selectedFacultyId, setSelectedFacultyId] = useState()
    const [addUpdateContractModalVisible, setAddUpdateContractModalVisible] =
        useState(false)
    const [selectedContract, setSelectedContract] = useState({})
    const [courses, setCourses] = useState([])
    const [contractDate, setContractDate] = useState()
    const [slabResetEveryYear, setSlabResetEveryYear] = useState(false)
    const [slabs, setSlabs] = useState([])
    const [slabsModalVisible, setSlabsModalVisible] = useState(false)
    const [addUpdateSlabModalVisible, setAddUpdateSlabModalVisible] =
        useState(false)
    const [selectedSlab, setSelectedSlab] = useState({})
    const [contractCoursesModalVisible, setContractCoursesModalVisible] =
        useState(false)
    const [contractCourses, setContractCourses] = useState([])
    const [addContractCourseModalVisible, setAddContractCourseModalVisible] =
        useState(false)
    const [showRelatedCoursesModalVisible, setShowRelatedCoursesModalVisible] =
        useState(false)
    const [relatedCourses, setRelatedCourses] = useState([])
    const [showingMissingCoursesToMap, setShowingMissingCoursesToMap] =
        useState(false)
    const [showingMissingWeightagesToMap, setShowingMissingWeightagesToMap] =
        useState(false)
    const [modalTitle, setModalTitle] = useState()

    useEffect(() => {
        getFaculties()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFaculties = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY)
            .then((res) => {
                setFaculties(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const facultyOptions = () => {
        var options = []
        faculties.forEach((item) => {
            options.push(
                <Option value={item.id} key={item.id}>
                    {item.firstName + ' ' + item.lastName}
                </Option>
            )
        })

        return options
    }

    const fetchContracts = (facultyId) => {
        if (!facultyId) {
            return
        }

        setSelectedFacultyId(facultyId)
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_FACULTY_CONTRACTS + facultyId)
            .then((res) => {
                setContracts(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateContractModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableContract(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
            render: (text, record) => (
                <span>
                    <span>{record.courseName}</span>
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showRelatedCourses(record)}
                    >
                        Related Courses
                    </Button>
                </span>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Date Of Contract',
            dataIndex: 'dateOfContract',
            key: 'dateOfContract',
        },
        {
            title: 'Slab Reset',
            dataIndex: 'slabResetEveryYear',
            key: 'slabResetEveryYear',
            render: (text, record) => (
                <span>
                    {record.slabResetEveryYear
                        ? 'Yes (' + record.slabResetMonth + ')'
                        : 'No'}
                </span>
            ),
        },
        {
            title: 'Slabs',
            dataIndex: 'slabs',
            key: 'slabs',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showSlabs(record)}
                >
                    Slabs
                </Button>
            ),
        },
        {
            title: 'Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showContractCourses(record)}
                >
                    Courses
                </Button>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteContract(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const slabTableColumns = [
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateSlabModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Range Start',
            dataIndex: 'rangeStart',
            key: 'rangeStart',
        },
        {
            title: 'Range End',
            dataIndex: 'rangeEnd',
            key: 'rangeEnd',
        },
        {
            title: 'Commission (%)',
            dataIndex: 'commissionPercent',
            key: 'commissionPercent',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteSlab(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddUpdateContractModal = (record = {}) => {
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setSelectedContract(record)
        setAddUpdateContractModalVisible(true)

        if (record.id) {
            setSlabResetEveryYear(record.slabResetEveryYear)

            setContractDate(record.dateOfContract)
            var contractDate = dayjs(record.dateOfContract, 'DD-MMM-YY')
            record.contractDate = contractDate

            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
            setSlabResetEveryYear(false)
        }
    }

    const hideAddUpdateContractModal = () => {
        setAddUpdateContractModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const courseOptions = () => {
        var options = []
        courses.forEach((item) => {
            options.push(
                <Option value={item.id} key={item.id}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const onChangeContractDate = (value, dateString) => {
        setContractDate(dateString)
    }

    const slabResetEveryYearChanged = (e) => {
        setSlabResetEveryYear(e.target.checked)
    }

    const addOrUpdateContract = () => {
        form.validateFields([
            'title',
            'courseId',
            'slabResetEveryYear',
            'slabResetMonth',
        ])
            .then((values) => {
                if (!contractDate) {
                    notification['error']({
                        message: 'Select contract date!',
                    })

                    return
                }

                setLoading(true)

                var url = GLOBAL.URL.FACULTY.ADD_FACULTY_CONTRACT
                if (selectedContract.id) {
                    url = GLOBAL.URL.FACULTY.UPDATE_FACULTY_CONTRACT
                    values.id = selectedContract.id
                }

                values.facultyId = selectedFacultyId
                values.dateOfContract = contractDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateContractModal()
                        fetchContracts(selectedFacultyId)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteContract = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.FACULTY.DELETE_FACULTY_CONTRACT, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                fetchContracts(selectedFacultyId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const enableDisableContract = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.FACULTY.ENABLE_FACULTY_CONTRACT
        if (record.enabled) {
            url = GLOBAL.URL.FACULTY.DISABLE_FACULTY_CONTRACT
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(contracts)
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setContracts(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showSlabs = (record) => {
        setSelectedContract(record)
        getSlabs(record.id)
        setSlabsModalVisible(true)
    }

    const getSlabs = (id) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_FACULTY_SLABS + id)
            .then((res) => {
                setSlabs(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideSlabs = () => {
        setSlabsModalVisible(false)
    }

    const showAddUpdateSlabModal = (record = {}) => {
        setSelectedSlab(record)
        setAddUpdateSlabModalVisible(true)

        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddUpdateSlabModal = () => {
        setAddUpdateSlabModalVisible(false)
    }

    const addOrUpdateSlab = () => {
        form.validateFields(['rangeStart', 'rangeEnd', 'commissionPercent'])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.FACULTY.ADD_FACULTY_SLAB
                if (selectedSlab.id) {
                    url = GLOBAL.URL.FACULTY.UPDATE_FACULTY_SLAB
                    values.id = selectedSlab.id
                }

                values.contractId = selectedContract.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateSlabModal()
                        getSlabs(selectedContract.id)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteSlab = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.FACULTY.DELETE_FACULTY_SLAB, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getSlabs(selectedContract.id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showContractCourses = (record) => {
        setSelectedContract(record)
        getContractCourses(record.id)
        setContractCoursesModalVisible(true)
    }

    const getContractCourses = (id) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_CONTRACT_COURSES + id)
            .then((res) => {
                setContractCourses(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideContractCourses = () => {
        setContractCoursesModalVisible(false)
    }

    const showAddContractCourseModal = () => {
        if (courses.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then(
                (res) => {
                    setCourses(res.data)
                }
            )
        }

        setAddContractCourseModalVisible(true)
    }

    const hideAddContractCourseModal = () => {
        setAddContractCourseModalVisible(false)
    }

    const contractCourseTableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteContractCourse(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const addContractCourse = () => {
        form.validateFields(['courseId'])
            .then((values) => {
                setLoading(true)

                values.contractId = selectedContract.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.FACULTY.ADD_CONTRACT_COURSE, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddContractCourseModal()
                        getContractCourses(selectedContract.id)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteContractCourse = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.FACULTY.DELETE_CONTRACT_COURSE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getContractCourses(selectedContract.id)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showRelatedCoursesOfAllContracts = () => {
        setLoading(true)
        setSelectedContract({})
        setShowingMissingCoursesToMap(false)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_ALL_RELATED_COURSES_IN_ALL_CONTRACTS +
                selectedFacultyId
        )
            .then((res) => {
                setRelatedCourses(res.data)
                setModalTitle('Related courses')
                setShowRelatedCoursesModalVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showRelatedCourses = (record) => {
        setLoading(true)
        setSelectedContract(record)
        setShowingMissingCoursesToMap(false)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_ALL_RELATED_COURSES_IN_CONTRACT + record.id
        )
            .then((res) => {
                setRelatedCourses(res.data)
                setShowRelatedCoursesModalVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showMissingCoursesToMap = () => {
        setLoading(true)
        setSelectedContract({})
        setShowingMissingCoursesToMap(true)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_MISSING_COURSES_TO_MAP + selectedFacultyId
        )
            .then((res) => {
                setRelatedCourses(res.data)
                setModalTitle('Missing courses to map')
                setShowRelatedCoursesModalVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideRelatedCourses = () => {
        setShowRelatedCoursesModalVisible(false)
    }

    const showMissingWeightagesToMap = () => {
        setLoading(true)
        setSelectedContract({})
        setShowingMissingWeightagesToMap(true)

        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_MISSING_WEIGHTAGES_TO_MAP + selectedFacultyId
        )
            .then((res) => {
                setRelatedCourses(res.data)
                setModalTitle('Missing weightages to map')
                setShowRelatedCoursesModalVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Faculty Contracts</h2>
            <Select
                placeholder="Select Faculty"
                style={{ width: 500 }}
                showSearch
                filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
                onSelect={(facultyId) => fetchContracts(facultyId)}
            >
                {facultyOptions()}
            </Select>
            {selectedFacultyId ? (
                <div>
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            size="small"
                            onClick={showAddUpdateContractModal}
                            loading={loading}
                        >
                            Add
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={showRelatedCoursesOfAllContracts}
                            loading={loading}
                            style={{ marginLeft: 10 }}
                        >
                            Related Courses
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={showMissingCoursesToMap}
                            loading={loading}
                            style={{ marginLeft: 10 }}
                        >
                            Missing Courses To Map
                        </Button>
                        <Button
                            type="default"
                            size="small"
                            onClick={showMissingWeightagesToMap}
                            loading={loading}
                            style={{ marginLeft: 10 }}
                        >
                            Missing Weightages To Map
                        </Button>
                    </div>
                    <Table
                        columns={tableColumns}
                        dataSource={contracts}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="id"
                        style={{ marginTop: 10 }}
                    />
                </div>
            ) : null}
            <Modal
                title={'Configure Contract'}
                open={addUpdateContractModalVisible}
                onOk={addOrUpdateContract}
                confirmLoading={loading}
                onCancel={hideAddUpdateContractModal}
                okText={selectedContract.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    initialValues={{ slabResetEveryYear: false }}
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Courses"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Date Of Contract"
                        name="contractDate"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY"
                            onChange={onChangeContractDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Slab reset every year?"
                        name="slabResetEveryYear"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={slabResetEveryYearChanged}
                        ></Checkbox>
                    </Form.Item>
                    {slabResetEveryYear ? (
                        <Form.Item
                            label="Slab Reset Month"
                            name="slabResetMonth"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <InputNumber placeholder="month" min={1} max={12} />
                        </Form.Item>
                    ) : null}
                </Form>
            </Modal>
            <Modal
                title={"Slabs of '" + selectedContract.courseName + "'"}
                open={slabsModalVisible}
                onCancel={hideSlabs}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddUpdateSlabModal}
                        loading={loading}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={slabTableColumns}
                    dataSource={slabs}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={
                    "Configure Slab of '" + selectedContract.courseName + "'"
                }
                open={addUpdateSlabModalVisible}
                onOk={addOrUpdateSlab}
                confirmLoading={loading}
                onCancel={hideAddUpdateSlabModal}
                okText={selectedSlab.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={900}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Range Start"
                        name="rangeStart"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={1} autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="Range End"
                        name="rangeEnd"
                        rules={[
                            {
                                required: false,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Commission %"
                        name="commissionPercent"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1.0} />
                    </Form.Item>
                </Form>
                <div>
                    <u>Note</u>
                    <ol>
                        <li>Range End to 0 or blank to set it to MAX value</li>
                    </ol>
                </div>
            </Modal>
            <Modal
                title={
                    "Courses in contract '" + selectedContract.courseName + "'"
                }
                open={contractCoursesModalVisible}
                onCancel={hideContractCourses}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddContractCourseModal}
                        loading={loading}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={contractCourseTableColumns}
                    dataSource={contractCourses}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={
                    "Add course to contract of '" +
                    selectedContract.courseName +
                    "'"
                }
                open={addContractCourseModalVisible}
                onOk={addContractCourse}
                confirmLoading={loading}
                onCancel={hideAddContractCourseModal}
                okText={'Add'}
                destroyOnClose={true}
                width={900}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Courses"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {courseOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={modalTitle}
                open={showRelatedCoursesModalVisible}
                onCancel={hideRelatedCourses}
                footer={null}
                destroyOnClose={true}
                width={900}
            >
                <h4>
                    {selectedContract.courseName
                        ? selectedContract.courseName
                        : !showingMissingCoursesToMap ||
                          !showingMissingWeightagesToMap
                        ? 'All Contracts'
                        : ''}
                </h4>
                <ol>
                    {relatedCourses.map((relatedCourse) => {
                        return (
                            <li>
                                <Link
                                    href={
                                        '/course/configure/' +
                                        relatedCourse.courseId
                                    }
                                    onClick={(event) => {
                                        event.preventDefault()
                                        props.navigate(
                                            '/course/configure/' +
                                                relatedCourse.courseId
                                        )
                                    }}
                                    underline="none"
                                >
                                    <Button
                                        type="link"
                                        style={{
                                            padding: 0,
                                        }}
                                    >
                                        {relatedCourse.courseName}
                                    </Button>
                                </Link>
                            </li>
                        )
                    })}
                </ol>
            </Modal>
        </div>
    )
}

export default FacultyContract
