import React, { useState, useEffect } from 'react'
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import Autocomplete from '@mui/material/Autocomplete'
import { mapCourseCompletionStats } from './GraphMapping/MapCourseCompletionStats'
import { legentExtraSpace } from './Components/LegendExtendedMargin'
import StatsConstants from '../Stats/StatsConstants'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
)

const dashboardRightContentWidth = '83.4%'

const CourseCompletionAnalytics = (props) => {
    const { classes } = useStyles()
    const [loading, setLoading] = useState(false)
    const [attemptList, setAttemptList] = useState([])
    const [selectedAttempt, setSelectedAttempt] = useState('')
    const [selectedCourse, setSelectedCourse] = useState('')
    const [courseByAttemptList, setCourseByAttemptList] = useState([])
    const [isFetchingAnalyticsLoading, setIsFetchingAnalyticsLoading] =
        useState(false)
    const [barChartData, setBarChartData] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [barChartOptions, setBarChartOptions] = useState({})
    const [promptMessage, setPromptMessage] = useState('')
    const [viewingCourse, setViewingCourse] = useState('')
    const { userId } = props

    useEffect(() => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_COURSE_ATTEMPTS + '?userId=' + userId
        )
            .then((response) => {
                setAttemptList(response.data)
            })
            .finally(() => setLoading(false))
        //eslint-disable-next-line
    }, [])

    const getCourseByAttempt = (attempt) => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_COURSES_BY_ATTEMPT +
                `?attempt=${attempt}` +
                '&userId=' +
                userId
        )
            .then((response) => {
                setCourseByAttemptList(response.data)
            })
            .finally(() => setLoading(false))
    }

    const getCourseComlpetionStats = () => {
        setIsFetchingAnalyticsLoading(true)

        let payload = {
            courseName: selectedCourse,
            userId: userId,
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.ANALYTICS.USER.GET_COURSE_COMPLETION_DATA,
            formData
        )
            .then((response) => {
                setViewingCourse(selectedCourse)

                if (response.data.length) {
                    setPromptMessage('')
                    let data = mapCourseCompletionStats(response.data)
                    setBarChartData(data.courseCompletionStats)
                    setBarChartOptions(data.options)
                } else {
                    setPromptMessage('No Data Found')
                }
            })
            .finally(() => setIsFetchingAnalyticsLoading(false))
    }

    const handleAttemptChange = (value, newValue) => {
        setSelectedCourse('')
        setSelectedAttempt(newValue)
        getCourseByAttempt(newValue)
    }

    const handleCourseChange = (value, newValue) => {
        setSelectedCourse(newValue)
    }

    const AttemptFilter = () => {
        return (
            <Grid className={classes.filterContainer}>
                <Typography className={classes.filterHeadingText}>
                    Attempt:{' '}
                </Typography>
                <Autocomplete
                    options={attemptList}
                    color="primary"
                    size="small"
                    disableClearable
                    value={selectedAttempt}
                    style={{ width: 150 }}
                    onChange={handleAttemptChange}
                    className={classes.searchBuyGroup}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={'outlined'}
                            fullWidth={true}
                            placeholder={'Select Attempt'}
                        />
                    )}
                />
            </Grid>
        )
    }

    const CourseNameFilter = () => {
        return (
            <Grid className={classes.filterContainer}>
                <Typography className={classes.filterHeadingText}>
                    Course:
                </Typography>
                <Autocomplete
                    options={courseByAttemptList}
                    color="primary"
                    size="small"
                    disableClearable
                    value={selectedCourse}
                    style={{ width: 250 }}
                    onChange={handleCourseChange}
                    className={classes.searchBuyGroup}
                    disabled={!selectedAttempt || !courseByAttemptList.length}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant={'outlined'}
                            fullWidth={true}
                            placeholder={'select Course'}
                        />
                    )}
                />
            </Grid>
        )
    }

    return (
        <Grid className={classes.wrapperContainer} container>
            <Grid item xs={12} className={classes.rightContentContainer}>
                {loading ? <CircularProgress /> : null}
                <Grid className={classes.chartContainer}>
                    <Grid className={classes.filtersContainer}>
                        <AttemptFilter />
                        <CourseNameFilter />
                        <Button
                            className={classes.fetchButton}
                            size={'small'}
                            color="primary"
                            variant="contained"
                            disabled={
                                isFetchingAnalyticsLoading ||
                                !selectedAttempt ||
                                !selectedCourse
                            }
                            onClick={getCourseComlpetionStats}
                            startIcon={
                                isFetchingAnalyticsLoading ? (
                                    <CircularProgress
                                        color="primary"
                                        size={14}
                                    />
                                ) : null
                            }
                        >
                            Fetch
                        </Button>
                    </Grid>
                    <CourseCompletionStats
                        barChartData={barChartData}
                        barChartOptions={barChartOptions}
                        promptMessage={promptMessage}
                        selectedCourse={viewingCourse}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

const CourseCompletionStats = ({
    barChartData = [],
    barChartOptions = {},
    promptMessage = '',
    selectedCourse,
}) => {
    const { classes } = useStyles()

    return (
        <Grid>
            {selectedCourse ? (
                <Typography className={classes.selectedCourseHeading}>
                    {selectedCourse}
                </Typography>
            ) : null}
            {promptMessage ? (
                <Typography className={classes.promptMessage}>
                    {promptMessage}
                </Typography>
            ) : (
                <Grid className={classes.scrollableWindow}>
                    <Grid className={classes.barContainer}>
                        <Line
                            data={barChartData}
                            options={barChartOptions}
                            plugins={[legentExtraSpace]}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export const useStyles = makeStyles()((theme) => ({
    wrapperContainer: {
        display: 'flex',
        flexFlow: 'row',
        minHeight: 800,
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
        },
    },
    chartContainer: {
        display: 'flex',
        flexFlow: 'column',
        padding: '10px 20px 20px 20px',
        width: '70%',
        [theme.breakpoints.down('md')]: {
            padding: '10px 15px',
            width: '100%',
        },
    },
    appContainer: {
        paddingTop: 0,
    },
    rightContentContainer: {
        display: 'flex',
        flexFlow: 'column',
        width: dashboardRightContentWidth,
        [theme.breakpoints.down('md')]: {
            width: 'unset',
        },
    },
    searchBuyGroup: {
        marginRight: 10,
        '& .MuiAutocomplete-inputRoot': {
            padding: '4px 35px 4px 0px! important',
            fontSize: 14,
        },
        '& .MuiInputLabel-outlined': {
            fontSize: 14,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            cursor: 'not-allowed',
            backgroundColor: 'rgba(0,0,0,0.05)',
        },
        '& input:disabled': {
            cursor: 'not-allowed',
        },
    },
    filtersContainer: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: 20,
    },
    filterContainer: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        marginRight: 8,
        marginTop: 10,
    },
    filterHeadingText: {
        fontSize: 16,
        marginRight: 10,
        width: 65,
    },
    fetchButton: {
        textTransform: 'none',
        marginTop: 10,
    },
    promptMessage: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 20,
    },
    selectedCourseHeading: {
        fontSize: 18,
        textAlign: 'center',
        marginBottom: 10,
    },
    barContainer: {
        width: '100%',

        [theme.breakpoints.down('sm')]: {
            width: 650,
        },
    },
    scrollableWindow: {
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto',
        },
    },
}))

export default CourseCompletionAnalytics
