import React, { useEffect, useRef, useState } from 'react'
import GLOBAL from '../../Globals'
import { RestApi } from '../../RestApi'
import { Button, Radio } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

const LeadsNavigation = () => {
    const [leadsData, setLeadsData] = useState({})
    const [loading, setLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(true)
    const intervalRef = useRef(null)
    const navigate = useNavigate()
    const location = useLocation()

    const mergeLeads = (data) => {
        const mergedLeads = []
        const cartLead = 'Cart Lead'
        const transactionLead = 'Transaction'

        Object.keys(data).forEach((key) => {
            let route
            if (key.includes('internal')) {
                route = '/leads/internal'
            } else if (key.includes('external')) {
                route = '/leads/external'
            } else {
                route = ''
            }

            data[key].forEach((lead) => {
                var highPriority = false
                if (
                    lead.leadType === cartLead ||
                    lead.leadType === transactionLead
                ) {
                    highPriority = true
                }

                mergedLeads.push({
                    leadType: lead.leadType,
                    name: `${lead.leadType} (${key.slice(0, 1).toUpperCase()})`,
                    count: lead.count,
                    route: route,
                    highPriority: highPriority,
                })
            })
        })

        return mergedLeads
    }

    useEffect(() => {
        const handleFocus = () => setIsFocused(true)
        const handleBlur = () => setIsFocused(false)

        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                handleFocus()
            } else {
                handleBlur()
            }
        })

        window.addEventListener('focus', handleFocus)
        window.addEventListener('blur', handleBlur)

        return () => {
            window.removeEventListener('visibilitychange', () => {})
            window.removeEventListener('focus', handleFocus)
            window.removeEventListener('blur', handleBlur)
            clearInterval(intervalRef.current)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getAllActionableLeads()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isFocused) {
            intervalRef.current = setInterval(() => {
                getAllActionableLeads()
            }, 10000)
        } else {
            clearInterval(intervalRef.current)
        }
        // eslint-disable-next-line
    }, [isFocused])

    const getAllActionableLeads = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.LEADS.GET_ALL_OPEN_ACTIONABLE_LEADS)
            .then((res) => {
                const updatedData = mergeLeads(res.data)
                setLeadsData(updatedData)
            })
            .finally(() => setLoading(false))
    }

    const handleLeadTypeChange = (value, route) => {
        if (!route) {
            return
        }

        const currentPath = location.pathname
        if (currentPath === route) {
            const searchParams = new URLSearchParams(location.search)
            searchParams.set('leadType', value)
            searchParams.set('filter', 'open')
            navigate(
                {
                    pathname: route,
                    search: searchParams.toString(),
                },
                { replace: true }
            )
        } else {
            navigate(`${route}?filter=open&leadType=${value}`)
        }
    }

    return (
        <>
            {leadsData && leadsData.length ? (
                <div style={{ margin: '5px 0px 8px' }}>
                    <Radio.Group
                        onChange={(e) => {
                            const selectedItem = leadsData.find(
                                (item) => item.name === e.target.value
                            )
                            handleLeadTypeChange(
                                selectedItem.leadType,
                                selectedItem.route
                            )
                        }}
                    >
                        {leadsData.map((item, i) => (
                            <Radio.Button
                                value={item.name}
                                key={item.name + i}
                                style={{
                                    backgroundColor: item.highPriority
                                        ? 'red'
                                        : 'rgb(242, 237, 255)',
                                    color: item.highPriority
                                        ? 'white'
                                        : 'black',
                                }}
                            >
                                {item.name + ' - ' + item.count}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                    <Button type="link" onClick={getAllActionableLeads}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 15 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 15 }} />
                        )}
                    </Button>
                </div>
            ) : null}
        </>
    )
}

export default LeadsNavigation
