import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import { Table, Button } from 'antd'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

function UserForumTopics(props) {
    const userId = props.userId
    const [loadingForumTopics, setLoadingForumTopics] = useState(false)
    const [forumTopics, setForumTopics] = useState([])
    const [forumTopicsNextUrl, setForumTopicsNextUrl] = useState()

    const navigate = useNavigate()
    useEffect(() => {
        getForumTopics()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getForumTopics = () => {
        setLoadingForumTopics(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_TOPICS_USER + '?id=' + userId)
            .then((response) => {
                var res = JSON.parse(response.data)
                setForumTopics(res.data)
                setForumTopicsNextUrl(res.next)
            })
            .finally(() => {
                setLoadingForumTopics(false)
            })
    }

    const getMoreForumTopics = () => {
        if (!forumTopicsNextUrl) {
            return
        }

        setLoadingForumTopics(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + forumTopicsNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setForumTopics((preState) => {
                    return [...preState, ...res.data]
                })
                setForumTopicsNextUrl(res.next)
            })
            .finally(() => {
                setLoadingForumTopics(false)
            })
    }

    const forumTopicsColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 100,
            render: (text, record) =>
                record.enabled ? (
                    <CheckCircleTwoTone style={{ fontSize: 16 }} />
                ) : (
                    <CloseCircleTwoTone
                        twoToneColor="#eb2f96"
                        style={{ fontSize: 16 }}
                    />
                ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 180,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.date}</span>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        navigateToTopicDetails(record.id)
                    }}
                    style={{ padding: 0 }}
                >
                    {record.title}
                </Button>
            ),
        },
    ]

    const navigateToTopicDetails = (id) => {
        navigate('/forumtopicdetails/' + id)
    }

    return (
        <div>
            <Table
                loading={loadingForumTopics}
                columns={forumTopicsColumns}
                dataSource={forumTopics}
                pagination={false}
                style={{ marginTop: 8 }}
            />
            {forumTopicsNextUrl ? (
                <Button
                    type="primary"
                    onClick={getMoreForumTopics}
                    style={{ marginTop: 8 }}
                    loading={loadingForumTopics}
                >
                    More
                </Button>
            ) : null}
        </div>
    )
}

export default UserForumTopics
