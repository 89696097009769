import React, { useState } from 'react'
import { Input, Table, notification } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { useNavigate } from 'react-router-dom'

function SearchUser(props) {
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [query, setQuery] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const navigate = useNavigate()
    const searchUsers = () => {
        if (!query && !phone && !email && !firstName && !lastName) {
            notification['error']({
                message: 'Search query needed!',
            })
            return
        }

        setLoading(true)

        var payload = {}
        payload.query = query ? query : ''
        payload.email = email ? email : ''
        payload.phone = phone ? phone : ''
        payload.firstName = firstName ? firstName : ''
        payload.lastName = lastName ? lastName : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onRowClick = (record) => {
        navigate('/userdetails/' + record.id, { record: record })
    }

    const colums = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => (
                <span>
                    {record.phone ? record.isd + '-' + record.phone : ''}
                </span>
            ),
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    return (
        <div>
            <h2>Search Users</h2>
            <Search
                placeholder="Phone / Email / User ID"
                onSearch={searchUsers}
                onChange={(e) => setQuery(e.target.value)}
                style={{ width: 200 }}
                autoFocus
            />
            <Search
                placeholder="Phone"
                onSearch={searchUsers}
                onChange={(e) => setPhone(e.target.value)}
                style={{ width: 200, marginLeft: 20 }}
            />
            <Search
                placeholder="Email"
                onSearch={searchUsers}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: 200, marginLeft: 20 }}
            />
            <Search
                placeholder="First Name"
                onSearch={searchUsers}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ width: 200, marginLeft: 20 }}
            />
            <Search
                placeholder="Last Name"
                onSearch={searchUsers}
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: 200, marginLeft: 20 }}
            />
            <Table
                columns={colums}
                dataSource={data}
                pagination={false}
                style={{ marginTop: 8 }}
                loading={loading}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            onRowClick(record)
                        },
                    }
                }}
            />
        </div>
    )
}

export default SearchUser
