import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { DatePicker, Button, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Box } from '@mui/material'
import dayjs from 'dayjs'

ChartJS.register(ArcElement, Tooltip, ChartDataLabels)

class RevenueSplit extends React.Component {
    state = {
        loading: false,
        labelsOsSplit: [],
        countOsSplit: [],
        osSplitBackgroundColor: [],

        labelsStreamSplit: [],
        countStreamSplit: [],
        streamSplitBackgroundColor: [],

        labelsGroupSplit: [],
        countGroupSplit: [],
        groupSplitBackgroundColor: [],

        labelsRevenueSplitByAttempt: [],
        countRevenueSplitByAttempt: [],
        revenueSplitByAttemptBackgroundColor: [],

        labelsAgingTransactionsRevenue: [],
        countAgingTransactionsRevenue: [],
        agingTransactionsRevenueBackgroundColor: [],

        labelsAgingTransactionsCount: [],
        countAgingTransactionsCount: [],
        agingTransactionsCountBackgroundColor: [],

        startDate: '',
        endDate: '',

        labelsOsSplitSecond: [],
        countOsSplitSecond: [],
        osSplitBackgroundColorSecond: [],

        labelsStreamSplitSecond: [],
        countStreamSplitSecond: [],
        streamSplitBackgroundColorSecond: [],

        labelsGroupSplitSecond: [],
        countGroupSplitSecond: [],
        groupSplitBackgroundColorSecond: [],

        labelsRevenueSplitByAttemptSecond: [],
        countRevenueSplitByAttemptSecond: [],
        revenueSplitByAttemptBackgroundColorSecond: [],

        labelsAgingTransactionsRevenueSecond: [],
        countAgingTransactionsRevenueSecond: [],
        agingTransactionsRevenueBackgroundColorSecond: [],

        labelsAgingTransactionsCountSecond: [],
        countAgingTransactionsCountSecond: [],
        agingTransactionsCountBackgroundColorSecond: [],

        lastMonthStartDate: '',
        lastMonthEndDate: '',

        osSplitMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        osSplitMapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,

        streamSplitMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        streamSplitMapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,

        groupSplitMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        groupSplitMapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,

        attemptsSplitMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        attemptsSplitMapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,

        agingTransactionsRevenueMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        agingTransactionsRevenueMapDataSecond:
            StatsConstants.INITIAL_BAR_OPTIONS,

        agingTransactionsCountMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        agingTransactionsCountMapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,

        agingDataByLastLoginData: [],
        agingDataByLastLoginDataSecond: [],

        agingByLastLoginMrpMapData: {},
        agingByLastLoginMrpMapDataSecond: {},

        agingByLastLoginCountMapData: StatsConstants.INITIAL_BAR_OPTIONS,
        agingByLastLoginCountMapDataSecond: StatsConstants.INITIAL_BAR_OPTIONS,

        loadingLastLoginStats: false,
        loadingLastLoginStatsSecond: false,

        labelsAgingByLastLoginCount: [],
        countAgingByLastLoginCount: [],
        agingByLastLoginCountBackgroundColor: [],

        labelsAgingByLastLoginCountSecond: [],
        countAgingByLastLoginCountSecond: [],
        agingByLastLoginCountBackgroundColorSecond: [],

        agingByLastLoginCountSplitOptions: {},

        purchaseOrExtension: 'both',
    }

    componentDidMount() {
        this.getData(true)
        this.getData(false)
    }

    getData(currentMonth) {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.GET_REVENUE_SPLIT
        if (currentMonth && this.state.startDate) {
            url =
                url +
                '?startDate=' +
                this.state.startDate +
                '&endDate=' +
                this.state.endDate +
                '&purchaseOrExtension=' +
                this.state.purchaseOrExtension
        } else if (!currentMonth && this.state.lastMonthStartDate) {
            url =
                url +
                '?startDate=' +
                this.state.lastMonthStartDate +
                '&endDate=' +
                this.state.lastMonthEndDate +
                '&purchaseOrExtension=' +
                this.state.purchaseOrExtension
        } else if (!currentMonth && !this.state.lastMonthStartDate) {
            url =
                url +
                '?prevMonth=true' +
                '&purchaseOrExtension=' +
                this.state.purchaseOrExtension
        } else {
            url = url + '?purchaseOrExtension=' + this.state.purchaseOrExtension
        }

        RestApi.doGet(url)
            .then((res) => {
                var labelsOsSplit = []
                var countOsSplit = []
                var osSplitBackgroundColor = []

                var labelsStreamSplit = []
                var countStreamSplit = []
                var streamSplitBackgroundColor = []

                var labelsGroupSplit = []
                var countGroupSplit = []
                var groupSplitBackgroundColor = []

                var labelsRevenueSplitByAttempt = []
                var countRevenueSplitByAttempt = []
                var revenueSplitByAttemptBackgroundColor = []

                var labelsAgingTransactionsRevenue = []
                var countAgingTransactionsRevenue = []
                var agingTransactionsRevenueBackgroundColor = []

                var labelsAgingTransactionsCount = []
                var countAgingTransactionsCount = []
                var agingTransactionsCountBackgroundColor = []

                var response = JSON.parse(res.data)

                var cnt = 0
                response.osSplit.forEach((row) => {
                    labelsOsSplit.push(row.label)
                    countOsSplit.push(row.count)
                    osSplitBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                cnt = 0
                response.streamSplit.forEach((row) => {
                    labelsStreamSplit.push(row.label)
                    countStreamSplit.push(row.count)
                    streamSplitBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                cnt = 0
                response.groupSplit.forEach((row) => {
                    labelsGroupSplit.push(row.label)
                    countGroupSplit.push(row.count)
                    groupSplitBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                cnt = 0
                response.revenueSplitByAttempt.forEach((row) => {
                    labelsRevenueSplitByAttempt.push(row.label)
                    countRevenueSplitByAttempt.push(row.countPercent)
                    revenueSplitByAttemptBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                cnt = 0
                response.agingTransactionsRevenue.forEach((row) => {
                    labelsAgingTransactionsRevenue.push(row.label)
                    countAgingTransactionsRevenue.push(row.countPercent)
                    agingTransactionsRevenueBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                cnt = 0
                response.agingTransactionsCount.forEach((row) => {
                    labelsAgingTransactionsCount.push(row.label)
                    countAgingTransactionsCount.push(row.countPercent)
                    agingTransactionsCountBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                if (currentMonth) {
                    this.setState(
                        {
                            labelsOsSplit: labelsOsSplit,
                            countOsSplit: countOsSplit,
                            osSplitBackgroundColor: osSplitBackgroundColor,

                            labelsStreamSplit: labelsStreamSplit,
                            countStreamSplit: countStreamSplit,
                            streamSplitBackgroundColor:
                                streamSplitBackgroundColor,

                            labelsGroupSplit: labelsGroupSplit,
                            countGroupSplit: countGroupSplit,
                            groupSplitBackgroundColor:
                                groupSplitBackgroundColor,

                            labelsRevenueSplitByAttempt:
                                labelsRevenueSplitByAttempt,
                            countRevenueSplitByAttempt:
                                countRevenueSplitByAttempt,
                            revenueSplitByAttemptBackgroundColor:
                                revenueSplitByAttemptBackgroundColor,

                            labelsAgingTransactionsRevenue:
                                labelsAgingTransactionsRevenue,
                            countAgingTransactionsRevenue:
                                countAgingTransactionsRevenue,
                            agingTransactionsRevenueBackgroundColor:
                                agingTransactionsRevenueBackgroundColor,

                            labelsAgingTransactionsCount:
                                labelsAgingTransactionsCount,
                            countAgingTransactionsCount:
                                countAgingTransactionsCount,
                            agingTransactionsCountBackgroundColor:
                                agingTransactionsCountBackgroundColor,
                        },
                        () => this.drawChart(true)
                    )
                } else {
                    this.setState(
                        {
                            labelsOsSplitSecond: labelsOsSplit,
                            countOsSplitSecond: countOsSplit,
                            osSplitBackgroundColorSecond:
                                osSplitBackgroundColor,

                            labelsStreamSplitSecond: labelsStreamSplit,
                            countStreamSplitSecond: countStreamSplit,
                            streamSplitBackgroundColorSecond:
                                streamSplitBackgroundColor,

                            labelsGroupSplitSecond: labelsGroupSplit,
                            countGroupSplitSecond: countGroupSplit,
                            groupSplitBackgroundColorSecond:
                                groupSplitBackgroundColor,

                            labelsRevenueSplitByAttemptSecond:
                                labelsRevenueSplitByAttempt,
                            countRevenueSplitByAttemptSecond:
                                countRevenueSplitByAttempt,
                            revenueSplitByAttemptBackgroundColorSecond:
                                revenueSplitByAttemptBackgroundColor,

                            labelsAgingTransactionsRevenueSecond:
                                labelsAgingTransactionsRevenue,
                            countAgingTransactionsRevenueSecond:
                                countAgingTransactionsRevenue,
                            agingTransactionsRevenueBackgroundColorSecond:
                                agingTransactionsRevenueBackgroundColor,

                            labelsAgingTransactionsCountSecond:
                                labelsAgingTransactionsCount,
                            countAgingTransactionsCountSecond:
                                countAgingTransactionsCount,
                            agingTransactionsCountBackgroundColorSecond:
                                agingTransactionsCountBackgroundColor,
                        },
                        () => this.drawChart(false)
                    )
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart(currentMonth) {
        // OS Split
        var osSplitDataSet = {}
        osSplitDataSet.data = currentMonth
            ? this.state.countOsSplit
            : this.state.countOsSplitSecond
        osSplitDataSet.label = 'OS Split'
        osSplitDataSet.fill = true
        osSplitDataSet.backgroundColor = currentMonth
            ? this.state.osSplitBackgroundColor
            : this.state.osSplitBackgroundColorSecond

        var osSplitMapData = {}
        osSplitMapData.labels = currentMonth
            ? this.state.labelsOsSplit
            : this.state.labelsOsSplitSecond
        osSplitMapData.datasets = []
        osSplitMapData.datasets.push(osSplitDataSet)

        var osSplitOptions = clone(StatsConstants.PIE_CHART_OPTIONS)
        osSplitOptions.plugins.title.text = 'OS Split'

        // Stream split
        var streamSplitDataSet = {}
        streamSplitDataSet.data = currentMonth
            ? this.state.countStreamSplit
            : this.state.countStreamSplitSecond
        streamSplitDataSet.label = 'Stream Split'
        streamSplitDataSet.fill = true
        streamSplitDataSet.backgroundColor = currentMonth
            ? this.state.streamSplitBackgroundColor
            : this.state.streamSplitBackgroundColorSecond

        var streamSplitMapData = {}
        streamSplitMapData.labels = currentMonth
            ? this.state.labelsStreamSplit
            : this.state.labelsStreamSplitSecond
        streamSplitMapData.datasets = []
        streamSplitMapData.datasets.push(streamSplitDataSet)

        var streamSplitOptions = clone(StatsConstants.PIE_CHART_OPTIONS)
        streamSplitOptions.plugins.title.text = 'Stream Split'

        // Group split
        var groupSplitDataSet = {}
        groupSplitDataSet.data = currentMonth
            ? this.state.countGroupSplit
            : this.state.countGroupSplitSecond
        groupSplitDataSet.label = 'Group Split'
        groupSplitDataSet.fill = true
        groupSplitDataSet.backgroundColor = currentMonth
            ? this.state.groupSplitBackgroundColor
            : this.state.groupSplitBackgroundColorSecond

        var groupSplitMapData = {}
        groupSplitMapData.labels = currentMonth
            ? this.state.labelsGroupSplit
            : this.state.labelsGroupSplitSecond
        groupSplitMapData.datasets = []
        groupSplitMapData.datasets.push(groupSplitDataSet)

        var groupSplitOptions = clone(StatsConstants.PIE_CHART_OPTIONS)
        groupSplitOptions.plugins.title.text = 'Group Split'

        // Attempt split
        var attemptSplitDataSet = {}
        attemptSplitDataSet.data = currentMonth
            ? this.state.countRevenueSplitByAttempt
            : this.state.countRevenueSplitByAttemptSecond
        attemptSplitDataSet.label = 'Attempt Split'
        attemptSplitDataSet.fill = true
        attemptSplitDataSet.backgroundColor = currentMonth
            ? this.state.revenueSplitByAttemptBackgroundColor
            : this.state.revenueSplitByAttemptBackgroundColorSecond

        var attemptsSplitMapData = {}
        attemptsSplitMapData.labels = currentMonth
            ? this.state.labelsRevenueSplitByAttempt
            : this.state.labelsRevenueSplitByAttemptSecond
        attemptsSplitMapData.datasets = []
        attemptsSplitMapData.datasets.push(attemptSplitDataSet)

        var attemptSplitOptions = clone(StatsConstants.CHART_OPTIONS)
        attemptSplitOptions.plugins.title.text = 'Attempt Split'
        attemptSplitOptions.plugins.datalabels.font.size = 12
        attemptSplitOptions.indexAxis = 'y'

        // Aging Transactions Revenue
        var agingTransactionsRevenueDataSet = {}
        agingTransactionsRevenueDataSet.data = currentMonth
            ? this.state.countAgingTransactionsRevenue
            : this.state.countAgingTransactionsRevenueSecond
        agingTransactionsRevenueDataSet.label = 'Aging Revenue Split'
        agingTransactionsRevenueDataSet.fill = true
        agingTransactionsRevenueDataSet.backgroundColor = currentMonth
            ? this.state.agingTransactionsRevenueBackgroundColor
            : this.state.agingTransactionsRevenueBackgroundColorSecond

        var agingTransactionsRevenueMapData = {}
        agingTransactionsRevenueMapData.labels = currentMonth
            ? this.state.labelsAgingTransactionsRevenue
            : this.state.labelsAgingTransactionsRevenueSecond
        agingTransactionsRevenueMapData.datasets = []
        agingTransactionsRevenueMapData.datasets.push(
            agingTransactionsRevenueDataSet
        )

        var agingTransactionsRevenueSplitOptions = clone(
            StatsConstants.CHART_OPTIONS
        )
        agingTransactionsRevenueSplitOptions.plugins.title.text =
            'Aging Revenue Split'
        agingTransactionsRevenueSplitOptions.plugins.datalabels.font.size = 12
        agingTransactionsRevenueSplitOptions.indexAxis = 'y'

        // Aging Transactions Count
        var agingTransactionsCountDataSet = {}
        agingTransactionsCountDataSet.data = currentMonth
            ? this.state.countAgingTransactionsCount
            : this.state.countAgingTransactionsCountSecond
        agingTransactionsCountDataSet.label = 'Aging Transactions Count Split'
        agingTransactionsCountDataSet.fill = true
        agingTransactionsCountDataSet.backgroundColor = currentMonth
            ? this.state.agingTransactionsCountBackgroundColor
            : this.state.agingTransactionsCountBackgroundColorSecond

        var agingTransactionsCountMapData = {}
        agingTransactionsCountMapData.labels = currentMonth
            ? this.state.labelsAgingTransactionsCount
            : this.state.labelsAgingTransactionsCountSecond
        agingTransactionsCountMapData.datasets = []
        agingTransactionsCountMapData.datasets.push(
            agingTransactionsCountDataSet
        )

        var agingTransactionsCountSplitOptions = clone(
            StatsConstants.CHART_OPTIONS
        )
        agingTransactionsCountSplitOptions.plugins.title.text =
            'Aging Transactions Count Split'
        agingTransactionsCountSplitOptions.plugins.datalabels.font.size = 12
        agingTransactionsCountSplitOptions.indexAxis = 'y'

        this.setState({
            osSplitOptions: osSplitOptions,
            streamSplitOptions: streamSplitOptions,
            groupSplitOptions: groupSplitOptions,
            attemptSplitOptions: attemptSplitOptions,
            agingTransactionsRevenueSplitOptions:
                agingTransactionsRevenueSplitOptions,
            agingTransactionsCountSplitOptions:
                agingTransactionsCountSplitOptions,
        })

        if (currentMonth) {
            this.setState({
                osSplitMapData: osSplitMapData,
                streamSplitMapData: streamSplitMapData,
                groupSplitMapData: groupSplitMapData,
                attemptsSplitMapData: attemptsSplitMapData,
                agingTransactionsRevenueMapData:
                    agingTransactionsRevenueMapData,
                agingTransactionsCountMapData: agingTransactionsCountMapData,
            })
        } else {
            this.setState({
                osSplitMapDataSecond: osSplitMapData,
                streamSplitMapDataSecond: streamSplitMapData,
                groupSplitMapDataSecond: groupSplitMapData,
                attemptsSplitMapDataSecond: attemptsSplitMapData,
                agingTransactionsRevenueMapDataSecond:
                    agingTransactionsRevenueMapData,
                agingTransactionsCountMapDataSecond:
                    agingTransactionsCountMapData,
            })
        }
    }

    onDateChange = (value, dateString) => {
        this.setState(
            {
                startDate: dateString[0],
                endDate: dateString[1],
                agingDataByLastLoginData: [],
                agingByLastLoginCountMapData: {},
            },
            () => {
                this.getData(true)
            }
        )
    }

    onDateChangeLastMonth = (value, dateString) => {
        this.setState(
            {
                lastMonthStartDate: dateString[0],
                lastMonthEndDate: dateString[1],
                agingDataByLastLoginDataSecond: [],
                agingByLastLoginCountMapDataSecond: {},
            },
            () => {
                this.getData(false)
            }
        )
    }

    getCurrentMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month, 1)

        var range = [dayjs(firstDay), dayjs(date)]

        return range
    }

    getLastMonthRange = () => {
        var date = new Date(),
            year = date.getFullYear(),
            month = date.getMonth()

        var firstDay = new Date()
        firstDay.setFullYear(year, month - 1, 1)

        var lastDay = new Date()
        lastDay.setFullYear(year, month, 0)

        var range = [dayjs(firstDay), dayjs(lastDay)]

        return range
    }

    fetchAgingStatsByLastLogin = (prevMonth) => {
        if (!prevMonth) {
            this.setState({
                loadingLastLoginStats: true,
            })
        } else {
            this.setState({
                loadingLastLoginStatsSecond: true,
            })
        }

        var url =
            GLOBAL.URL.GET_REVENUE_SPLIT_BY_LAST_LOGIN_AGING +
            '?startDate=' +
            this.state.startDate +
            '&endDate=' +
            this.state.endDate +
            '&prevMonth=' +
            prevMonth

        RestApi.doGet(url)
            .then((res) => {
                if (!prevMonth) {
                    this.setState({
                        agingDataByLastLoginData: res.data,
                    })
                } else {
                    this.setState({
                        agingDataByLastLoginDataSecond: res.data,
                    })
                }

                var labelsAgingTransactionsCount = []
                var countAgingTransactionsCount = []
                var agingTransactionsCountBackgroundColor = []
                var cnt = 0
                res.data.forEach((row) => {
                    labelsAgingTransactionsCount.push(row.label)
                    countAgingTransactionsCount.push(
                        row.transactionsCountPercent
                    )
                    agingTransactionsCountBackgroundColor.push(
                        StatsConstants.COLORS[
                            cnt % (StatsConstants.COLORS.length - 1)
                        ]
                    )

                    cnt++
                })

                if (prevMonth) {
                    this.setState({
                        labelsAgingByLastLoginCountSecond:
                            labelsAgingTransactionsCount,
                        countAgingByLastLoginCountSecond:
                            countAgingTransactionsCount,
                        agingByLastLoginCountBackgroundColorSecond:
                            agingTransactionsCountBackgroundColor,
                    })
                } else {
                    this.setState({
                        labelsAgingByLastLoginCount:
                            labelsAgingTransactionsCount,
                        countAgingByLastLoginCount: countAgingTransactionsCount,
                        agingByLastLoginCountBackgroundColor:
                            agingTransactionsCountBackgroundColor,
                    })
                }

                this.drawAgingByLastLoginChart(prevMonth)
            })
            .finally(() => {
                if (!prevMonth) {
                    this.setState({
                        loadingLastLoginStats: false,
                    })
                } else {
                    this.setState({
                        loadingLastLoginStatsSecond: false,
                    })
                }
            })
    }

    drawAgingByLastLoginChart(prevMonth) {
        var agingLastLoginCountDataSet = {}
        agingLastLoginCountDataSet.data = prevMonth
            ? this.state.countAgingByLastLoginCountSecond
            : this.state.countAgingByLastLoginCount
        agingLastLoginCountDataSet.label =
            'Aging By Last Login Transaction Count Split'
        agingLastLoginCountDataSet.fill = true
        agingLastLoginCountDataSet.backgroundColor = prevMonth
            ? this.state.agingByLastLoginCountBackgroundColorSecond
            : this.state.agingByLastLoginCountBackgroundColor

        var agingTransactionsCountMapData = {}
        agingTransactionsCountMapData.labels = prevMonth
            ? this.state.labelsAgingByLastLoginCountSecond
            : this.state.labelsAgingByLastLoginCount
        agingTransactionsCountMapData.datasets = []
        agingTransactionsCountMapData.datasets.push(agingLastLoginCountDataSet)

        var agingByLastLoginCountSplitOptions = clone(
            StatsConstants.CHART_OPTIONS
        )
        agingByLastLoginCountSplitOptions.plugins.title.text =
            'Aging By Last Login Count Split'
        agingByLastLoginCountSplitOptions.plugins.datalabels.font.size = 12
        agingByLastLoginCountSplitOptions.indexAxis = 'y'

        this.setState({
            agingByLastLoginCountSplitOptions:
                agingByLastLoginCountSplitOptions,
        })

        if (prevMonth) {
            this.setState({
                agingByLastLoginCountMapDataSecond:
                    agingTransactionsCountMapData,
            })
        } else {
            this.setState({
                agingByLastLoginCountMapData: agingTransactionsCountMapData,
            })
        }
    }

    onChangePurchaseFilterType = (e) => {
        this.setState({ purchaseOrExtension: e.target.value }, () => {
            this.getData(true)
            this.getData(false)
        })
    }

    render() {
        const { RangePicker } = DatePicker

        return (
            <div>
                <h2>Revenue Split</h2>
                <div style={{ marginBottom: 10 }}>
                    <Radio.Group
                        onChange={this.onChangePurchaseFilterType}
                        value={this.state.purchaseOrExtension}
                    >
                        <Radio.Button value="both" key={'both'}>
                            {'Both'}
                        </Radio.Button>
                        <Radio.Button value="purchase" key={'purchase'}>
                            {'Purchase'}
                        </Radio.Button>
                        <Radio.Button value="extension" key={'extension'}>
                            {'Extension'}
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <span>Date range: </span>
                        <RangePicker
                            format="DD-MMM-YY"
                            placeholder={['Start Date', 'End Date']}
                            onChange={this.onDateChange}
                            style={{ marginLeft: 10 }}
                            defaultValue={this.getCurrentMonthRange()}
                        />
                        {this.state.loading ? (
                            <div style={{ marginTop: 10 }}>
                                Fetching data...
                            </div>
                        ) : null}
                        <Paper
                            style={{
                                marginBottom: 15,
                                paddingBottom: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '90%', maxWidth: '400px' }}>
                                <Doughnut
                                    data={this.state.osSplitMapData}
                                    options={this.state.osSplitOptions}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                        </Paper>
                        <Paper
                            style={{
                                marginBottom: 15,
                                paddingBottom: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '90%', maxWidth: '400px' }}>
                                <Doughnut
                                    data={this.state.streamSplitMapData}
                                    options={this.state.streamSplitOptions}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                        </Paper>
                        <Paper
                            style={{
                                marginBottom: 15,
                                paddingBottom: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '90%', maxWidth: '400px' }}>
                                <Doughnut
                                    data={this.state.groupSplitMapData}
                                    options={this.state.groupSplitOptions}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                        </Paper>
                        <Paper style={{ marginBottom: 15, paddingBottom: 15 }}>
                            <Bar
                                data={this.state.attemptsSplitMapData}
                                options={this.state.attemptSplitOptions}
                                style={{ width: '100%' }}
                            />
                        </Paper>
                        <Paper style={{ marginBottom: 15, paddingBottom: 15 }}>
                            <Bar
                                data={
                                    this.state.agingTransactionsRevenueMapData
                                }
                                options={
                                    this.state
                                        .agingTransactionsRevenueSplitOptions
                                }
                            />
                        </Paper>
                        <Paper style={{ marginBottom: 15, paddingBottom: 15 }}>
                            <Bar
                                data={this.state.agingTransactionsCountMapData}
                                options={
                                    this.state
                                        .agingTransactionsCountSplitOptions
                                }
                            />
                        </Paper>
                        {this.state.agingDataByLastLoginData.length ? (
                            <>
                                <Paper
                                    style={{
                                        marginBottom: 15,
                                        paddingBottom: 15,
                                    }}
                                >
                                    <Bar
                                        data={
                                            this.state
                                                .agingByLastLoginCountMapData
                                        }
                                        options={
                                            this.state
                                                .agingByLastLoginCountSplitOptions
                                        }
                                    />
                                </Paper>
                            </>
                        ) : (
                            <Button
                                style={{ marginTop: 20 }}
                                type="primary"
                                onClick={() =>
                                    this.fetchAgingStatsByLastLogin(false)
                                }
                                loading={this.state.loadingLastLoginStats}
                            >
                                Fetch Aging Stats By Last Login
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        <span>Date range: </span>
                        <RangePicker
                            format="DD-MMM-YY"
                            placeholder={['Start Date', 'End Date']}
                            onChange={this.onDateChangeLastMonth}
                            style={{ marginLeft: 10 }}
                            defaultValue={this.getLastMonthRange()}
                        />
                        {this.state.loading ? (
                            <div style={{ marginTop: 10 }}>
                                Fetching data...
                            </div>
                        ) : null}
                        <Paper
                            style={{
                                marginBottom: 15,
                                paddingBottom: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '90%', maxWidth: '400px' }}>
                                <Doughnut
                                    data={this.state.osSplitMapDataSecond}
                                    options={this.state.osSplitOptions}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                        </Paper>
                        <Paper
                            style={{
                                marginBottom: 15,
                                paddingBottom: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '90%', maxWidth: '400px' }}>
                                <Doughnut
                                    data={this.state.streamSplitMapDataSecond}
                                    options={this.state.streamSplitOptions}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                        </Paper>
                        <Paper
                            style={{
                                marginBottom: 15,
                                paddingBottom: 15,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box sx={{ width: '90%', maxWidth: '400px' }}>
                                <Doughnut
                                    data={this.state.groupSplitMapDataSecond}
                                    options={this.state.groupSplitOptions}
                                    style={{ width: '100%' }}
                                />
                            </Box>
                        </Paper>
                        <Paper style={{ marginBottom: 15, paddingBottom: 15 }}>
                            <Bar
                                data={this.state.attemptsSplitMapDataSecond}
                                options={this.state.attemptSplitOptions}
                                style={{ width: '100%' }}
                            />
                        </Paper>
                        <Paper style={{ marginBottom: 15, paddingBottom: 15 }}>
                            <Bar
                                data={
                                    this.state
                                        .agingTransactionsRevenueMapDataSecond
                                }
                                options={
                                    this.state
                                        .agingTransactionsRevenueSplitOptions
                                }
                            />
                        </Paper>
                        <Paper style={{ marginBottom: 15, paddingBottom: 15 }}>
                            <Bar
                                data={
                                    this.state
                                        .agingTransactionsCountMapDataSecond
                                }
                                options={
                                    this.state
                                        .agingTransactionsCountSplitOptions
                                }
                            />
                        </Paper>
                        {this.state.agingDataByLastLoginDataSecond.length ? (
                            <>
                                <Paper
                                    style={{
                                        marginBottom: 15,
                                        paddingBottom: 15,
                                    }}
                                >
                                    <Bar
                                        data={
                                            this.state
                                                .agingByLastLoginCountMapDataSecond
                                        }
                                        options={
                                            this.state
                                                .agingByLastLoginCountSplitOptions
                                        }
                                    />
                                </Paper>
                            </>
                        ) : (
                            <Button
                                style={{ marginTop: 20 }}
                                type="primary"
                                onClick={() =>
                                    this.fetchAgingStatsByLastLogin(true)
                                }
                                loading={this.state.loadingLastLoginStatsSecond}
                            >
                                Fetch Aging Stats By Last Login
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default RevenueSplit
