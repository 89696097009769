import React, { useState, useEffect, useRef } from 'react'
import { notification, Input, Button, Form, DatePicker } from 'antd'
import GLOBAL from '../../Globals'
import TinyMceEditor from '../Common/TinyMceEditor'
import UploadAndGetLink from '../Common/UploadAndGetLink'
import { CircularProgress } from '@mui/material'
import { RestApi } from '../../RestApi'
import MetaFormItems from '../Common/MetaFormItems'
import dayjs from 'dayjs'

function AddNews(props) {
    const [loading, setLoading] = useState(false)
    const [scheduledTime, setScheduledTime] = useState()
    const [content, setContent] = useState('')
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        checkEdit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [inputRef])

    const checkEdit = () => {
        if (props.id) {
            setLoading(true)
            RestApi.doGet(GLOBAL.URL.GET_NEWS_BY_ID + props.id)
                .then((response) => {
                    let responseData = response.data

                    setContent(responseData.content)
                    var scheduledTimeMomentObj = null
                    if (responseData.scheduledTime) {
                        setScheduledTime(responseData.scheduledTime)

                        scheduledTimeMomentObj = dayjs(
                            responseData.scheduledTime,
                            'DD-MMM-YY HH:mm'
                        )
                    }

                    setTimeout(() => {
                        form.setFieldsValue({
                            title: responseData.title,
                            scheduledTime: scheduledTimeMomentObj,
                        })
                        setFormFieldValues(responseData)
                    }, 100)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const setFormFieldValues = (record) => {
        var metaArrayFields = [0]
        var meta = []
        var length = 1
        var seoObj = null
        if (record.seo) {
            seoObj = JSON.parse(record.seo)
            if (seoObj.meta) {
                var filtered = seoObj.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })

                if (filtered.length) {
                    metaArrayFields = []
                    meta = filtered
                    length = filtered.length

                    for (var k = 0; k < length; k++) {
                        metaArrayFields.push(k)
                    }
                }
            }
        }

        form.setFieldsValue({
            meta: meta,
            seoTitle: seoObj ? seoObj.title : '',
        })
    }

    const addUpdateNews = () => {
        form.validateFields().then((values) => {
            if (!content) {
                notification['error']({
                    message: 'News content cannot be empty',
                })

                return
            }

            setLoading(true)
            let filtered = []
            if (values.meta) {
                filtered = values.meta.filter(function (el) {
                    return el != null && el.property && el.content
                })
            }

            var seo = {}
            seo.title = values.seoTitle ? values.seoTitle : ''
            seo.meta = filtered

            values.seo = seo
            values.content = content
            values.scheduledTime = scheduledTime

            var url = GLOBAL.URL.ADD_NEWS
            if (props.id) {
                url = GLOBAL.URL.UPDATE_NEWS
                values.id = props.id
            }

            var formData = new FormData()
            formData.append('payload', JSON.stringify(values))

            RestApi.doPost(url, formData)
                .then((response) => {
                    notification['success']({
                        message: response.data,
                    })

                    props.refreshData()
                    props.hideAddUpdateNews()
                })
                .catch((error) => {
                    setLoading(false)
                })
        })
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    const onChangeScheduleDate = (value, dateString) => {
        setScheduledTime(dateString)
    }

    return (
        <div>
            <div>
                <UploadAndGetLink />
            </div>
            {loading ? (
                <div style={{ marginTop: 10 }}>
                    <CircularProgress
                        style={{
                            height: 20,
                            width: 20,
                        }}
                    />
                </div>
            ) : null}
            <div style={{ marginTop: 10 }}>
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Title"
                        name="title"
                        style={{ padding: 0, margin: 0, marginBottom: 10 }}
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Schedule"
                        style={{ padding: 0, margin: 0 }}
                        name="scheduledTime"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            showTime
                            placeholder="Select Date &amp; Time"
                            format="DD-MMM-YY HH:mm"
                            onChange={onChangeScheduleDate}
                        />
                    </Form.Item>
                    {!loading ? (
                        <div style={{ marginTop: 10 }}>
                            <h4>News Content</h4>
                            <TinyMceEditor
                                height={400}
                                handleEditorChange={handleEditorChange}
                                initialValue={content}
                            />
                        </div>
                    ) : null}
                    <Form.Item
                        label="SEO: Title"
                        style={{ marginBottom: 0, marginTop: 10 }}
                        name="seoTitle"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input placeholder="SEO: Title" />
                    </Form.Item>
                    <h3 style={{ marginTop: 10 }}>SEO: Meta</h3>
                    <MetaFormItems />
                </Form>
                <div style={{ marginTop: 20 }}>
                    <Button type="primary" onClick={addUpdateNews}>
                        {props.id ? 'Update' : 'Add'}
                        {' News'}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AddNews
