import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    notification,
    DatePicker,
    Select,
    Radio,
    Checkbox,
    Form,
} from 'antd'
import { Utils } from '../JS/Utils'
import CircularProgress from '@mui/material/CircularProgress'

const REPORT_TYPE_SUMMARY = 'REPORT_TYPE_SUMMARY'
const REPORT_TYPE_DETAILED = 'REPORT_TYPE_DETAILED'

function ExternalSalesCallsReport() {
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker
    const { Option } = Select

    const dateFormat = 'DD-MMM-YYYY'

    const [loading, setLoading] = useState(false)
    const [currentDayReport, setCurrentDayReport] = useState()
    const [adminUsers, setAdminUsers] = useState([])
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [report, setReport] = useState()
    const [loadingCustomReport, setLoadingCustomReport] = useState(false)
    const [reportType, setReportType] = useState(REPORT_TYPE_SUMMARY)
    const [detailedReport, setDetailedReport] = useState()
    const [message, setMessage] = useState()
    const [teams, setTeams] = useState([])
    const [selectedTeam, setSelectedTeam] = useState()
    const [isUniqueUsers, setIsUniqueUsers] = useState(false)

    useEffect(() => {
        getAdminUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!Utils.userIsSalesMember()) {
            getCurrentDayReport()
        } else {
            if (!teams.length) {
                RestApi.doGet(
                    GLOBAL.URL.SALES.GET_EXTERNAL_SALES_COMPANY_LIST
                ).then((res) => {
                    setTeams(res.data)
                    if (res.data.length) {
                        setSelectedTeam(res.data[0].companyName)
                    }
                })
            } else {
                getCurrentDayReport()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUniqueUsers])

    useEffect(() => {
        if (selectedTeam) {
            getCurrentDayReport()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam])

    const getCurrentDayReport = () => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_CURRENT_DAY_CALLS_REPORT +
                '?teamName=' +
                selectedTeam +
                '&isUniqueUsers=' +
                isUniqueUsers
        )
            .then((res) => {
                setCurrentDayReport(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAdminUsers = () => {
        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_ASSIGNED_TO_FILTER_DATA
        ).then((res) => {
            setAdminUsers(res.data)
        })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const onChangeReportType = (event) => {
        setReportType(event.target.value)
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start date needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End date needed!',
                    })

                    return
                }

                setReport()
                setLoadingCustomReport(true)

                values.startDate = startDate
                values.endDate = endDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_GET_CALLS_MADE_REPORT,
                    formData
                )
                    .then((response) => {
                        setReport(response.data)
                    })
                    .finally(() => {
                        setLoadingCustomReport(false)
                    })
            })
            .catch((error) => {})
    }

    const generateDetailedReport = (isSendEmail) => {
        form.validateFields()
            .then((values) => {
                if (!startDate) {
                    notification['error']({
                        message: 'Start date needed!',
                    })

                    return
                }

                if (!endDate) {
                    notification['error']({
                        message: 'End date needed!',
                    })

                    return
                }

                setReport()
                setLoadingCustomReport(true)

                values.startDate = startDate
                values.endDate = endDate

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                var url =
                    GLOBAL.URL.SALES
                        .EXTERNAL_SALES_GET_CALLS_MADE_REPORT_DETAILED
                if (isSendEmail) {
                    url =
                        GLOBAL.URL.SALES
                            .EXTERNAL_SALES_DOWNLOAD_CALLS_MADE_REPORT_DETAILED
                }

                RestApi.doPost(url, formData)
                    .then((response) => {
                        if (isSendEmail) {
                            setMessage(response.data)
                        } else {
                            setDetailedReport(response.data)
                        }
                    })
                    .finally(() => {
                        setLoadingCustomReport(false)
                    })
            })
            .catch((error) => {})
    }

    const teamsOptions = () => {
        var options = []
        teams.forEach((team) => {
            options.push(
                <Option value={team.companyName} key={team.companyName}>
                    {team.companyName}
                </Option>
            )
        })

        return options
    }

    const onChangeTeam = (teamName) => {
        setSelectedTeam(teamName)
    }

    return (
        <div>
            <h2>Calls Report (current day)</h2>
            {Utils.userIsSalesMember() ? (
                <div style={{ marginBottom: 20 }}>
                    <Select
                        placeholder="Select Team"
                        style={{ width: 200 }}
                        onChange={onChangeTeam}
                        value={selectedTeam}
                    >
                        {teamsOptions()}
                    </Select>
                </div>
            ) : null}
            <Checkbox
                checked={isUniqueUsers}
                disabled={loading}
                onChange={(e) => setIsUniqueUsers(e.target.checked)}
            >
                {'Unique Users'}
            </Checkbox>
            {loading ? <CircularProgress /> : null}
            {currentDayReport ? (
                <div
                    style={{ marginTop: 20 }}
                    dangerouslySetInnerHTML={{ __html: currentDayReport }}
                />
            ) : null}
            <div style={{ marginTop: 20 }}>
                <h2>Generate Report</h2>
                <Radio.Group
                    onChange={onChangeReportType}
                    defaultValue={reportType}
                    style={{ marginBottom: 10 }}
                >
                    <Radio.Button value={REPORT_TYPE_SUMMARY}>
                        Summary
                    </Radio.Button>

                    <Radio.Button value={REPORT_TYPE_DETAILED}>
                        Detailed
                    </Radio.Button>
                </Radio.Group>

                {reportType === REPORT_TYPE_SUMMARY ? (
                    <>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="Date Range"
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required!',
                                    },
                                ]}
                            >
                                <RangePicker
                                    format={dateFormat}
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateChange}
                                />
                            </Form.Item>
                            {Utils.isUserExternalSalesManager ? (
                                <Form.Item
                                    label="Users"
                                    name="salesPersonIds"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select"
                                        showSearch
                                        mode="multiple"
                                        allowClear={true}
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {getAdminUserOptions()}
                                    </Select>
                                </Form.Item>
                            ) : null}
                            <Form.Item
                                label="Unique Users"
                                name="isUniqueUsers"
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Checkbox />
                            </Form.Item>
                        </Form>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="primary"
                                size="small"
                                loading={loadingCustomReport}
                                onClick={() => generateReport()}
                            >
                                Generate Report
                            </Button>
                        </div>
                        {report ? (
                            <div
                                style={{ marginTop: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: report,
                                }}
                            />
                        ) : null}
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="Date Range"
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <RangePicker
                                    format={dateFormat}
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateChange}
                                />
                            </Form.Item>
                            {Utils.isUserExternalSalesManager ? (
                                <Form.Item
                                    label="User"
                                    name="salesPersonId"
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {getAdminUserOptions()}
                                    </Select>
                                </Form.Item>
                            ) : null}
                        </Form>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="primary"
                                size="small"
                                loading={loadingCustomReport}
                                onClick={() => generateDetailedReport(false)}
                            >
                                Show Here
                            </Button>
                            <Button
                                type="default"
                                size="small"
                                loading={loadingCustomReport}
                                onClick={() => generateDetailedReport(true)}
                                style={{ marginLeft: 20 }}
                            >
                                Generate &amp; Send Email
                            </Button>
                            {message ? (
                                <h3 style={{ marginTop: 20 }}>{message}</h3>
                            ) : null}
                        </div>
                        {detailedReport ? (
                            <div
                                style={{ marginTop: 20 }}
                                dangerouslySetInnerHTML={{
                                    __html: detailedReport,
                                }}
                            />
                        ) : null}
                    </>
                )}
            </div>
        </div>
    )
}

export default ExternalSalesCallsReport
