import React from 'react'
import { searchStyles } from './styles'
import { Box, Typography, IconButton } from '@mui/material'
import { Close, History } from '@mui/icons-material'

const HistoryCard = ({ item, index, onClearSearchItem, onClickSearchItem }) => {
    const { classes } = searchStyles()
    return (
        <Box
            className={classes.historyContainer}
            onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClickSearchItem(item)
            }}
        >
            <Box className={classes.historyTxtContainer}>
                <History className={classes.historyIcn} />
                <Typography className={classes.historyItem}>{item}</Typography>
            </Box>
            <IconButton
                sx={{ p: '4px' }}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onClearSearchItem(index)
                }}
            >
                <Close className={classes.clearIcn} />
            </IconButton>
        </Box>
    )
}

export default HistoryCard
