import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

function LeadsStreamWiseDashboard() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        getDashboard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDashboard = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_LEADS_DASHBOARD_BY_STREAM)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>
                Leads Stream Wise Split Dashboard
                <Button type="link" onClick={getDashboard}>
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 20 }} />
                    )}
                </Button>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    )
}

export default LeadsStreamWiseDashboard
