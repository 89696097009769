import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Popconfirm, message, Modal, Input, Select } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

class VideoVendor extends React.Component {
    state = {
        loading: false,
        vendors: [],
        editRecord: {},
        modalVisible: false,
        courseModalVisible: false,
        selectedVendor: {},
        courseAccessList: [],
        courseNames: [],
        selectedCourse: '',
    }

    componentDidMount() {
        this.getVendors()
    }

    getVendors() {
        this.setState({ loading: true })

        RestApi.doGet(GLOBAL.URL.GET_ALL_VIDEO_VENDORS)
            .then((res) => {
                this.setState({ vendors: res.data })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisable = (record) => {
        this.setState({ loading: true })

        var url = GLOBAL.URL.ENABLE_VIDEO_VENDOR
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_VIDEO_VENDOR
        }

        var payload = {}
        payload.id = record.id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formData)
            .then((response) => {
                message.success(response.data)

                var vendors = this.state.vendors
                vendors.forEach((data) => {
                    if (record.id === data.id) {
                        data.enabled = !data.enabled
                        return
                    }
                })
                this.setState({ vendors: vendors })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    showModal = (record) => {
        this.setState({
            name: record ? record.name : '',
            editRecord: record ? record : {},
            modalVisible: true,
        })
    }

    hideModal = () => {
        this.setState({ modalVisible: false })
    }

    handleOk = () => {
        if (!this.state.name) {
            message.error('Name needed!')
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.name = this.state.name

        var url = GLOBAL.URL.ADD_VIDEO_VENDOR
        if (this.state.editRecord.id) {
            payload.id = this.state.editRecord.id
            url = GLOBAL.URL.UPDATE_VIDEO_VENDOR
        }

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(url, formData)
            .then((response) => {
                message.success('Success!')
                this.hideModal()
                this.getVendors()
            })
            .catch((response) => {
                this.setState({
                    loading: false,
                })
            })
    }

    showCoursesModal = (record) => {
        this.setState({
            selectedVendor: record,
            loading: true,
            selectedCourse: '',
        })

        this.getCourseAccess(record)

        if (this.state.courseNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
                this.setState({ courseNames: res.data })
            })
        }
    }

    getCourseAccess(record) {
        RestApi.doGet(GLOBAL.URL.GET_VIDEO_VENDOR_ACCESS_LIST + record.id)
            .then((res) => {
                this.setState({
                    courseAccessList: res.data,
                    courseModalVisible: true,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    hideCoursesModal = () => {
        this.setState({ courseModalVisible: false })
    }

    addCourseAccess = () => {
        if (!this.state.selectedCourse) {
            message.error('Select course first!')
            return
        }

        this.setState({ loading: true })

        var payload = {}
        payload.vendorId = this.state.selectedVendor.id
        payload.course = this.state.selectedCourse

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_COURSE_ACCESS_VIDEO_VENDOR, formData)
            .then((response) => {
                message.success('Added!')
                this.getCourseAccess(this.state.selectedVendor)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    deleteCourseAccess = (id) => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_ACCESS_VIDEO_VENDOR, formData)
            .then((response) => {
                message.success(response.data)
                this.getCourseAccess(this.state.selectedVendor)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        var columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Secret',
                dataIndex: 'secretKey',
                key: 'secretKey',
            },
            {
                title: 'Updated At',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <div>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => {
                                this.enableDisable(record)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            {record.enabled ? (
                                <Button type="primary" size="small">
                                    Enabled
                                </Button>
                            ) : (
                                <Button
                                    style={{
                                        background: 'red',
                                        color: 'white',
                                    }}
                                    size="small"
                                >
                                    Disabled
                                </Button>
                            )}
                        </Popconfirm>
                        <span> | </span>
                        <Button
                            type="link"
                            onClick={() => {
                                this.showModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            Edit
                        </Button>
                        <span> | </span>
                        <Button
                            type="link"
                            onClick={() => {
                                this.showCoursesModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            Courses
                        </Button>
                    </div>
                ),
            },
        ]

        var courseColumns = [
            {
                title: '',
                dataIndex: 'delete',
                key: 'delete',
                width: 40,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.deleteCourseAccess(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">
                            <DeleteOutlined
                                theme="twoTone"
                                style={{ fontSize: 20, color: '#eb2f96' }}
                            />
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'Course Name',
                dataIndex: 'courseName',
                key: 'courseName',
            },
        ]

        const { Option } = Select
        var courseDetails = []
        this.state.courseNames.forEach((item) => {
            courseDetails.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return (
            <div>
                <h3>Video Vendors</h3>
                <Button
                    type="primary"
                    onClick={() => this.showModal()}
                    size="small"
                >
                    Add New Vendor
                </Button>
                <Table
                    columns={columns}
                    dataSource={this.state.vendors}
                    pagination={false}
                    rowKey="name"
                    size="small"
                    loading={this.state.loading}
                    style={{ marginTop: 10 }}
                />
                <Modal
                    title="Vendor"
                    open={this.state.modalVisible}
                    onOk={this.handleOk}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideModal}
                    okText={this.state.editRecord.id ? 'Update' : 'Add'}
                >
                    <span>Vendor Name: </span>
                    <Input
                        placeholder="Enter Name"
                        onChange={(text) => {
                            this.setState({
                                name: text.target.value,
                            })
                        }}
                        value={this.state.name}
                        autoFocus
                        style={{ marginTop: 5 }}
                    />
                </Modal>

                <Modal
                    title={this.state.selectedVendor.name + ' course access'}
                    open={this.state.courseModalVisible}
                    footer={null}
                    onCancel={this.hideCoursesModal}
                >
                    <h4 style={{ marginBottom: 0 }}>
                        Select course to give access:
                    </h4>
                    <Select
                        placeholder="Select Course Name"
                        style={{ marginTop: 8, marginBottom: 8, width: '100%' }}
                        onChange={(option) => {
                            this.setState({ selectedCourse: option })
                        }}
                        showSearch
                    >
                        {courseDetails}
                    </Select>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => this.addCourseAccess()}
                    >
                        Add Access
                    </Button>
                    <h4 style={{ marginBottom: 0, marginTop: 15 }}>
                        Vendor has access to below courses:
                    </h4>
                    <Table
                        columns={courseColumns}
                        dataSource={this.state.courseAccessList}
                        pagination={false}
                        rowKey="courseName"
                        size="small"
                        loading={this.state.loading}
                        style={{ marginTop: 5 }}
                    />
                </Modal>
            </div>
        )
    }
}

export default VideoVendor
