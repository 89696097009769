import {
    message,
    Table,
    Row,
    Button,
    Col,
    Modal,
    Input,
    Form,
    Divider,
    Popconfirm,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Difficulty = () => {
    const [difficultyData, setDifficultyData] = useState([])
    const [addEditdifficultyModalVisible, setAddEditdifficultyModalVisible] =
        useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [editDifficultyRecord, setEditDifficultyRecord] = useState('')
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getDifficultyLevelData()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditdifficultyModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditdifficultyModalVisible, inputRef])

    const getDifficultyLevelData = () => {
        RestApi.doGet(GLOBAL.URL.GET_DIFFICULTY_LEVELS)
            .then((res) => {
                setDifficultyData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
    }
    const openAddEditDifficultyModal = (record) => {
        form.setFieldsValue(record)
        setAddEditdifficultyModalVisible(true)
        setEditDifficultyRecord(record)
    }
    const closeAddEditDifficultyModal = () => {
        form.resetFields()
        setAddEditdifficultyModalVisible(false)
        setEditDifficultyRecord(null)
    }
    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_LEVEL
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_LEVEL
                    values['id'] = editRecord.id
                }
                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyLevel)
                    .then((response) => {
                        var diffcultyJsonArrayObject = []
                        diffcultyJsonArrayObject.push(response.data)
                        if (editRecord) {
                            difficultyData.forEach((item) => {
                                if (item.id === editRecord.id) {
                                    item.name = values.name
                                }
                            })
                        } else {
                            setDifficultyData([
                                ...difficultyData,
                                ...diffcultyJsonArrayObject,
                            ])
                        }
                        closeAddEditDifficultyModal()
                        message.success(
                            editRecord
                                ? 'Level Edited Successfully'
                                : 'Level Added Successfully'
                        )
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch((error) => {
                //empty catch block
            })
    }
    const deleteItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_LEVEL, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = difficultyData
                    setDifficultyData(
                        successObject.filter((key) => key.id !== id)
                    )
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }

    let columns = []
    if (Object.keys(difficultyData).length > 0) {
        columns = [
            {
                title: 'Action',
                key: 'action',
                width: '200px',
                render: (text, record) => (
                    <span>
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => {
                                openAddEditDifficultyModal(record)
                            }}
                        >
                            <EditTwoTone style={{ fontSize: 14 }} />
                        </Button>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure want to delete ?"
                            onConfirm={() => {
                                deleteItem(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{ border: 'none', color: 'red' }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
            {
                title: 'Difficulty Level Name',
                dataIndex: 'difficulty',
                key: 'difficulty',
            },
            {
                title: 'points',
                dataIndex: 'points',
                key: 'points',
            },
        ]
    }

    return (
        <div>
            <Col>
                <Row>
                    <Row style={{ marginBottom: 8 }}>
                        <Col span={18}>
                            <h2>Difficulty</h2>
                        </Col>

                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    openAddEditDifficultyModal()
                                }}
                            >
                                Add Level
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Col>
            <Table
                columns={columns}
                dataSource={difficultyData}
                pagination={false}
            />
            <Modal
                title={editDifficultyRecord ? 'Edit Level' : 'Add Level'}
                open={addEditdifficultyModalVisible}
                onOk={() => {
                    handleSubmit(editDifficultyRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={closeAddEditDifficultyModal}
                okText={editDifficultyRecord ? 'Edit Level' : 'Add Level'}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Difficulty Level Name"
                        name="difficulty"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Level Name',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Please enter Level Name"
                            ref={inputRef}
                        />
                    </Form.Item>
                    <Form.Item
                        label="points"
                        name="points"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter points',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Please enter points"
                            type="number"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Difficulty
