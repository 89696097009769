import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Input,
    Modal,
    message,
    Table,
    Button,
    Popconfirm,
    InputNumber,
    Form,
    Radio,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Partners = () => {
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [modalVisible, setModalVisible] = useState(false)
    const [editRecord, setEditRecord] = useState({})
    const [form] = Form.useForm()
    const [archived, setArchived] = useState('no')
    const [query, setQuery] = useState()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [archived])

    const getData = () => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_PARTNERS +
                '?archived=' +
                archived +
                '&query=' +
                (query ? query : '')
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showModal = (record) => {
        setModalVisible(true)
        setEditRecord(record ? record : {})
        setTimeout(() => {
            if (record.name) {
                form.setFieldsValue({
                    name: record.name,
                    legalName: record.legalName,
                    netPercent: record.netPercent,
                    email: record.email,
                    gstNumber: record.gstNumber,
                    address: record.address,
                    pincode: record.pincode,
                })
            } else {
                form.resetFields()
            }
        }, 100)
    }

    const hideModal = () => {
        setModalVisible(false)
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_PARTNER
                if (editRecord.id) {
                    url = GLOBAL.URL.UPDATE_PARTNER
                    values['id'] = editRecord.id
                }

                let formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((res) => {
                        message.success(res.data)
                        hideModal()
                        getData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteItem = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_PARTNER, postBody)
            .then((res) => {
                message.success(res.data)
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const { TextArea } = Input

    const columns = [
        {
            title: 'Edit',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                </span>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name1',
            key: 'name1',
            render: (text, record) => (
                <span>
                    <span>{record.name}</span>
                    <br />
                    <br />
                    <b>{'Legal Name'}</b>
                    <br />
                    <span>{record.legalName}</span>
                    <br />
                    <br />
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            archiveUnarchive(record)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>
                            {record.archived ? 'Unarchive' : 'Archive'}
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
        {
            title: 'Net%',
            dataIndex: 'netPercent',
            key: 'netPercent',
        },
        {
            title: 'Data',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <span>
                    <b>Email</b>
                    <br />
                    <span>{record.email}</span>
                    <br />
                    <br />
                    <b>GST Number</b>
                    <br />
                    <span>{record.gstNumber}</span>
                    <br />
                    <br />
                    <b>Address</b>
                    <br />
                    <label style={{ whiteSpace: 'pre-wrap' }}>
                        {record.address}
                    </label>
                    <br />
                    <br />
                    <b>Pincode</b>
                    <br />
                    <span>{record.pincode}</span>
                </span>
            ),
        },
        {
            title: 'Action',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Popconfirm
                        title="Are you sure want to delete ?"
                        onConfirm={() => {
                            deleteItem(record.id)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                                fontSize: 12,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onChangeArchived = (e) => {
        setArchived(e.target.value)
    }

    const archiveUnarchive = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ARCHIVE_PARTNER
        if (record.archived) {
            url = GLOBAL.URL.UNARCHIVE_PARTNER
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        RestApi.doPost(url, postBody)
            .then((res) => {
                message.success(res.data)
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Partners</h2>
            <div>
                <Radio.Group
                    onChange={onChangeArchived}
                    defaultValue={archived}
                >
                    <Radio.Button value={'no'} key={'no'}>
                        Active
                    </Radio.Button>
                    <Radio.Button value={'yes'} key={'yes'}>
                        Archived
                    </Radio.Button>
                </Radio.Group>
                <Search
                    placeholder="Search"
                    onSearch={getData}
                    onChange={(e) => setQuery(e.target.value)}
                    style={{ width: 300, marginLeft: 10 }}
                />
                <Button
                    type="primary"
                    onClick={showModal}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                style={{ marginTop: 10 }}
                rowKey="name"
            />
            <Modal
                title={editRecord.id ? 'Update' : 'Add'}
                open={modalVisible}
                onOk={() => {
                    handleSubmit()
                }}
                confirmLoading={loading}
                onCancel={hideModal}
                okText={editRecord.id ? 'Update' : 'Add'}
                width={800}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter name',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Name" autoFocus />
                    </Form.Item>
                    <Form.Item
                        label="Legal Name"
                        name="legalName"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter legal name',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Legal Name" />
                    </Form.Item>
                    <Form.Item
                        label="Net Percent"
                        name="netPercent"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <InputNumber min={1} max={100} />
                    </Form.Item>
                    <Form.Item
                        label="Emails (, separated)"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter Email(s)" />
                    </Form.Item>
                    <Form.Item
                        label="GST Number"
                        name="gstNumber"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Input placeholder="GST Number" />
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <TextArea placeholder="Address" />
                    </Form.Item>
                    <Form.Item
                        label="Pincode"
                        name="pincode"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Partners
