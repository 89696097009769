import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Modal, Input, notification, Popconfirm } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { EditTwoTone } from '@ant-design/icons'
import { Link } from '@mui/material'

function Faculty({ navigate }) {
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [facultyData, setFacultyData] = useState([])
    const [linkedUserModalVisible, setLinkedUserModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [usersData, setUsersData] = useState([])
    const inputRef = useRef(null)

    useEffect(() => {
        getfacultyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (linkedUserModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [linkedUserModalVisible, inputRef])

    const getfacultyData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY)
            .then((res) => {
                setFacultyData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/addfaculty'}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate('/addfaculty', {
                                state: { record: record },
                            })
                        }}
                        underline="none"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <EditTwoTone />
                        </Button>
                    </Link>
                    <span style={{ marginLeft: 5 }}>
                        {record.firstName + ' ' + record.lastName}
                    </span>
                </span>
            ),
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (text, record) => (
                <>
                    <Link
                        href={'/facultycoursessummary/' + record.id}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate('/facultycoursessummary/' + record.id)
                        }}
                        underline="none"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            Courses Summary
                        </Button>
                    </Link>
                    <br />
                    <span style={{ fontSize: 12 }}>
                        GST Applicable: {record.gstApplicable ? 'Yes' : 'No'}
                    </span>
                </>
            ),
        },
        {
            title: 'Linked User',
            dataIndex: 'linkedUser',
            key: 'linkedUser',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => showLinkedUserModal(record)}
                    >
                        <EditTwoTone />
                    </Button>
                    {record.linkedUser.firstName ? (
                        <>
                            <Button
                                type="link"
                                style={{ padding: 0, marginLeft: 5 }}
                                onClick={() =>
                                    navigate(
                                        '/userdetails/' + record.linkedUser.id
                                    )
                                }
                            >
                                {record.linkedUser.firstName +
                                    ' ' +
                                    record.linkedUser.lastName}
                            </Button>
                            <br />
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => clearUserLink(record.id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        fontSize: 11,
                                        marginLeft: 20,
                                    }}
                                >
                                    clear link
                                </Button>
                            </Popconfirm>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 500,
            render: (text, record) => (
                <>
                    <div style={{ textAlign: 'justify' }}>
                        {record.description}
                    </div>
                    <br />
                    <a
                        href={record.linkedin}
                        style={{ display: 'table-cell' }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LinkedIn
                    </a>
                </>
            ),
        },
        {
            title: 'Picture',
            dataIndex: 'pictureUrl',
            key: 'pictureUrl',
            render: (text, record) => (
                <span>
                    <img width={100} alt="logo" src={record.pictureUrl} />
                </span>
            ),
        },
    ]

    const showLinkedUserModal = (record) => {
        setSelectedRecord(record)
        setUsersData([])
        setLinkedUserModalVisible(true)
    }

    const hideLinkedUserModal = () => {
        setLinkedUserModalVisible(false)
    }

    const searchUser = (query) => {
        setLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setUsersData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const userSearchColums = [
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => linkUser(record)}
                >
                    Link
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const linkUser = (record) => {
        setLoading(true)

        var payload = {}
        payload.facultyId = selectedRecord.id
        payload.userId = record.id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.FACULTY.LINK_USER, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideLinkedUserModal()
                getfacultyData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const clearUserLink = (facultyId) => {
        setLoading(true)

        var payload = {}
        payload.facultyId = facultyId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.FACULTY.CLEAR_USER_LINK, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getfacultyData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Faculty</h2>
            <Link
                href={'/addfaculty'}
                onClick={(event) => {
                    event.preventDefault()
                    navigate('/addfaculty', { state: { record: null } })
                }}
                underline="none"
            >
                <Button type="primary" size="small">
                    Add Faculty
                </Button>
            </Link>
            <Table
                columns={columns}
                dataSource={facultyData}
                pagination={false}
                rowKey="id"
                loading={loading}
                style={{ marginTop: 10 }}
                size="small"
            />
            <Modal
                title={'Link User'}
                open={linkedUserModalVisible}
                onCancel={hideLinkedUserModal}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <h4>
                    Link user to faculty:{' '}
                    {selectedRecord.firstName + ' ' + selectedRecord.lastName}
                </h4>
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    style={{ width: 600 }}
                    autoFocus
                    ref={inputRef}
                />
                <Table
                    columns={userSearchColums}
                    dataSource={usersData}
                    pagination={false}
                    style={{ marginTop: 10 }}
                    loading={loading}
                    rowKey="email"
                    size="small"
                />
            </Modal>
        </div>
    )
}

export default Faculty
