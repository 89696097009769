import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useStyles } from './CourseWatchTimeAnalyticsByMonth'
import { AllCoursesTable } from './AllCoursesTable'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

export const CourseWatchTimeAnalyticsByDay = ({
    data,
    options,
    id,
    tableDataByDay,
    monthLabel,
    day,
    tableCourseNameColors,
}) => {
    const { classes } = useStyles()
    return Object.keys(data).length > 0 ? (
        <Grid id={id} className={classes.byDayBarContainer}>
            <Typography varaitn="h2" className={classes.heading}>
                Watch Time Analytics By Day
            </Typography>
            <Grid className={classes.barChartTableContainer}>
                <Grid md={8} item className={classes.scrollableWindow}>
                    <Grid className={classes.barContainer}>
                        <Bar data={data} options={options} />
                    </Grid>
                </Grid>
                <Grid md={4} xs={12} item className={classes.rightPannelTable}>
                    <AllCoursesTable
                        tableData={tableDataByDay}
                        monthLabel={monthLabel}
                        day={day}
                        tableCourseNameColors={tableCourseNameColors}
                    />
                </Grid>
            </Grid>
        </Grid>
    ) : null
}
