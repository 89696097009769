import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Modal, Upload, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CloudUploadOutlined, CopyTwoTone } from '@ant-design/icons'

class UploadAndGetLink extends React.Component {
    state = {
        loading: false,
        addModalVisible: false,
        location: '',
        fileList: [],
        isMcqUpload: false,
    }

    componentDidMount() {
        if (this.props.isMcqUpload) {
            this.setState({ isMcqUpload: true })
        }
    }

    showAddModal = () => {
        this.setState({ addModalVisible: true })
    }

    hideAddModal = () => {
        this.setState({ addModalVisible: false })
    }

    upload = () => {
        if (this.state.fileList.length === 0) {
            message.error('Select file to upload!')
            return
        }

        this.setState({ loading: true })

        var formData = new FormData()
        formData.append('file', this.state.fileList[0])

        var url = GLOBAL.URL.UPLOAD_TO_S3
        if (this.state.isMcqUpload) {
            url = GLOBAL.URL.UPLOAD_TO_S3_MCQ
        }

        RestApi.doPost(url, formData)
            .then((response) => {
                var res = JSON.parse(response.data)
                this.setState({ location: res.location, fileList: [] })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    onCopy = () => {
        message.success('Link copied!')
    }

    render() {
        const { fileList } = this.state
        const uploadProps = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)
                    return {
                        fileList: newFileList,
                    }
                })
            },
            beforeUpload: (file) => {
                this.setState((state) => ({
                    fileList: [file],
                }))
                return false
            },
            fileList,
        }

        return (
            <span>
                <Button size="small" onClick={() => this.showAddModal()}>
                    Upload File and Get Link
                </Button>
                <Modal
                    title={'Upload And Get Link'}
                    open={this.state.addModalVisible}
                    confirmLoading={this.state.loading}
                    onCancel={() => this.hideAddModal()}
                    okText={'Upload'}
                    destroyOnClose={true}
                    footer={null}
                >
                    <h4>Select File:</h4>
                    <Upload {...uploadProps}>
                        <Button>
                            <CloudUploadOutlined /> Select
                        </Button>
                    </Upload>
                    {this.state.fileList.length ? (
                        <div style={{ marginTop: 20 }}>
                            <Button
                                type="primary"
                                size="small"
                                loading={this.state.loading}
                                onClick={() => this.upload()}
                            >
                                Upload
                            </Button>
                        </div>
                    ) : null}
                    {!this.state.loading && this.state.location ? (
                        <div style={{ marginTop: 20 }}>
                            <div>
                                <CopyToClipboard
                                    text={this.state.location}
                                    onCopy={this.onCopy}
                                >
                                    <span>
                                        <Button
                                            type="link"
                                            style={{ padding: 0 }}
                                        >
                                            <CopyTwoTone />
                                            {' Copy Link'}
                                        </Button>
                                    </span>
                                </CopyToClipboard>
                            </div>
                            <div>
                                <a
                                    href={this.state.location}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {this.state.location}
                                </a>
                            </div>
                        </div>
                    ) : null}
                </Modal>
            </span>
        )
    }
}

export default UploadAndGetLink
