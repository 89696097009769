import {
    message,
    Table,
    Descriptions,
    Tag,
    Button,
    Modal,
    Input,
    Spin,
    Select,
    InputNumber,
    Col,
    Row,
    Popconfirm,
    Form,
} from 'antd'
import { CopyOutlined, EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import TransactionConstants from '../Transactions/TransactionConstants'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Utils } from '../JS/Utils'
import { goBack } from '../../Util/ILUtil'
import { Link } from '@mui/material'
import { useParams } from 'react-router-dom'

const TransactionDetails = ({ navigate }) => {
    const [transactionData, setTransactionData] = useState({})
    const [coursesData, setCoursesData] = useState([])
    const [modalLoading, setModalLoading] = useState(false)
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [approveLoanModalVisible, setApproveLoanModalVisible] =
        useState(false)
    const [refundModalVisible, setRefundModalVisible] = useState(false)
    const [loanRejectedModalVisible, setLoanRejectedModalVisible] =
        useState(false)
    const [selectedCourseRecord, setSelectedCourseRecord] = useState({})
    const [
        updateCourseAttemptModalVisible,
        setUpdateCourseAttemptModalVisible,
    ] = useState(false)
    const [attempts, setAttempts] = useState([])
    const [updateTransactionModalVisible, setUpdateTransactionModalVisible] =
        useState(false)
    const [courseArrayFields, setCourseArrayFields] = useState([])
    const [form] = Form.useForm()
    const { id } = useParams()

    useEffect(() => {
        getTransactionDetails(id)
        //eslint-disable-next-line
    }, [])

    const getTransactionDetails = (transactionId) => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.GET_TRANSACTION_DETAILS + transactionId)
            .then((res) => {
                setTransactionData(res.data)
                setCoursesData(res.data.courses)
                window.scrollTo(0, 0)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const autoVerifyPaymentStatus = () => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionData.transactionId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.AUTO_VERIFY_TRANSACTION, formbodyLevel)
            .then((response) => {
                message.success(response.data)
                getTransactionDetails(transactionData.transactionId)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const refund = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setModalLoading(true)

                var payload = {}
                payload.transactionId = transactionData.transactionId
                payload.removeCourseAccess = true
                payload.comments = values.comments

                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.REFUND_TRANSACTION, formbodyLevel)
                    .then((response) => {
                        message.success(response.data)
                        hideRefundModal()
                        getTransactionDetails(transactionData.transactionId)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const generateInvoice = () => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionData.transactionId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.GENERATE_INVOICE_FOR_TRANSACTION,
            formbodyLevel
        )
            .then((response) => {
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const migrateToCourseAccess = () => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionData.transactionId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.MIGRATE_TRANSACTION_TO_COURSE_ACCESS,
            formbodyLevel
        )
            .then((response) => {
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const migrateToCourier = () => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionData.transactionId

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.MIGRATE_TRANSACTION_TO_COURIER, formbodyLevel)
            .then((response) => {
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showApproveLoanModal = () => {
        setApproveLoanModalVisible(true)
    }

    const hideApproveLoanModal = () => {
        setApproveLoanModalVisible(false)
    }

    const markAsLoanApproved = () => {
        form.validateFields(['referenceId'])
            .then((values) => {
                setModalLoading(true)

                values.transactionId = transactionData.transactionId

                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.LOAN_APPROVED, formbodyLevel)
                    .then((response) => {
                        message.success(response.data)
                        hideApproveLoanModal()
                        getTransactionDetails(transactionData.transactionId)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const markAsLoanRejected = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setModalLoading(true)

                values.transactionId = transactionData.transactionId

                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.LOAN_REJECTED, formbodyLevel)
                    .then((response) => {
                        message.success(response.data)
                        hideLoanRejectedModal()
                        getTransactionDetails(transactionData.transactionId)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showRefundModal = () => {
        setRefundModalVisible(true)
    }

    const hideRefundModal = () => {
        setRefundModalVisible(false)
    }

    const showLoanRejectedModal = () => {
        setLoanRejectedModalVisible(true)
    }

    const hideLoanRejectedModal = () => {
        setLoanRejectedModalVisible(false)
    }

    const openAddCommentModal = () => {
        setAddCommentModalVisible(true)
    }
    const closeAddCommentModal = () => {
        form.resetFields()
        setAddCommentModalVisible(false)
    }
    const addComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_COMMENT_IN_TRANSACTION
                values['transactionId'] = transactionData.transactionId
                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyLevel)
                    .then((response) => {
                        message.success('Comment Added')
                        getTransactionDetails(transactionData.transactionId)
                        closeAddCommentModal()
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }
    const returnTransactionColor = (status) => {
        var color = ''
        if (status === TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS) {
            color = GLOBAL.COLORAPP.EMARALD
        } else if (
            status === TransactionConstants.TRANSACTION_CONSTANTS.PENDING ||
            status ===
                TransactionConstants.TRANSACTION_CONSTANTS.LOAN_PROCESSING
        ) {
            color = GLOBAL.COLORAPP.SUNFLOWER
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }
        return color
    }
    const getCourseNames = (record) => {
        var names = record.map((item) => (
            <li key={item.courseName}>
                {item.attempt + ' | ' + item.courseName}
            </li>
        ))

        return names
    }

    const navigateToUserDetails = (user) => {
        navigate('/userdetails/' + user.id, { record: user })
    }

    const onCopy = () => {
        message.success('Email copied!')
    }

    const showEditAttemptModal = (record) => {
        if (attempts.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
                setAttempts(res.data)
            })
        }
        setSelectedCourseRecord(record)
        setUpdateCourseAttemptModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue(record)
        }, 100)
    }

    const hideEditAttemptModal = () => {
        setSelectedCourseRecord({})
        setUpdateCourseAttemptModalVisible(false)
    }

    const updateAttempt = () => {
        form.validateFields(['attempt'])
            .then((values) => {
                setModalLoading(true)

                values.transactionItemId = selectedCourseRecord.id

                let formbodyLevel = new FormData()
                formbodyLevel.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.UPDATE_ATTEMPT_IN_TRANSACTION_ITEM,
                    formbodyLevel
                )
                    .then((response) => {
                        message.success(response.data)
                        hideEditAttemptModal()
                        getTransactionDetails(transactionData.transactionId)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateTransactionModal = () => {
        setUpdateTransactionModalVisible(true)

        var courseArrayFields = []
        var courses = []
        coursesData.forEach((row, index) => {
            var course = {}
            course.id = row.id
            course.courseName = row.courseName
            course.mrp = row.mrpInt
            course.price = row.priceDouble
            course.comboDiscount = row.comboDiscount
            course.credits = row.creditsInt
            course.discount = row.discountDouble

            courses.push(course)
            courseArrayFields.push(index)
        })
        setCourseArrayFields(courseArrayFields)

        var transaction = {}
        transaction.mrp = transactionData.mrpInt
        transaction.price = transactionData.priceDouble
        transaction.comboDiscount = transactionData.comboDiscountDouble
        transaction.credits = transactionData.creditsInt
        transaction.discount = transactionData.discountDouble
        transaction.courses = courses

        setTimeout(() => {
            form.setFieldsValue(transaction)
        }, 100)
    }

    const hideUpdateTransactionModal = () => {
        setUpdateTransactionModalVisible(false)
    }

    const updateTransaction = () => {
        form.validateFields([
            'mrp',
            'price',
            'comboDiscount',
            'credits',
            'discount',
            'courses',
        ])
            .then((values) => {
                setModalLoading(true)

                values.transactionId = transactionData.transactionId

                let formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.UPDATE_TRANSACTION, formData)
                    .then((response) => {
                        message.success(response.data)
                        hideUpdateTransactionModal()
                        getTransactionDetails(transactionData.transactionId)
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteTransaction = () => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionData.transactionId

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_TRANSACTION, formData)
            .then((response) => {
                message.success(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const { Option } = Select
    const commentsTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '200px',
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <span>
                    {record.user.firstName + ' ' + record.user.lastName}
                </span>
            ),
        },
    ]
    const courseTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Mrp',
            dataIndex: 'mrp',
            key: 'mrp',
            width: 150,
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 150,
            render: (text, record) => (
                <span>
                    {transactionData.status ===
                    TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showEditAttemptModal(record)
                            }}
                            style={{ padding: 0, marginRight: 5 }}
                        >
                            <EditOutlined
                                theme="twoTone"
                                style={{ fontSize: 14 }}
                            />
                        </Button>
                    ) : null}
                    <span>{record.attempt}</span>
                </span>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const creditTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Reward',
            dataIndex: 'reward',
            key: 'reward',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
    ]
    const userTransactionsTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => (
                <span style={{ maxWidth: 150 }}>
                    <Link
                        href={
                            '/transactions/transactiondetails/' +
                            record.transactionId
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            navigate(
                                '/transactions/transactiondetails/' +
                                    record.transactionId
                            )
                        }}
                        underline="none"
                    >
                        <Button
                            type="link"
                            onClick={() =>
                                getTransactionDetails(record.transactionId)
                            }
                            style={{
                                whiteSpace: 'pre-wrap',
                                maxWidth: 150,
                                padding: 0,
                            }}
                        >
                            {record.transactionId}
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Tag color={returnTransactionColor(record.status)}>
                    {record.status.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'initiatedAt',
            key: 'initiatedAt',
        },
        {
            title: 'MRP | Price',
            dataIndex: 'mrp',
            key: 'mrp',
            render: (text, record) => (
                <span>
                    {record.mrp + ' | '}
                    <b>{record.price}</b>
                </span>
            ),
        },
        {
            title: 'Credits | Discount',
            dataIndex: 'credits',
            key: 'credits',
            render: (text, record) => (
                <span>
                    {record.credits} | {record.discount}{' '}
                    {record.couponName ? ' | ' + record.couponName : null}
                </span>
            ),
        },
        {
            title: 'Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => <ul>{getCourseNames(record.courses)}</ul>,
        },
    ]

    var attemptOptions = []
    attempts.forEach((attempt) => {
        attemptOptions.push(
            <Option value={attempt.attempt} key={attempt.attempt}>
                {attempt.attempt}
            </Option>
        )
    })

    const getFormCourses = courseArrayFields.map((k, index) => (
        <Row gutter={16} style={{ marginTop: 10 }}>
            <Col span={4}>
                <span>{coursesData[k].courseName}</span>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0, display: 'none' }}
                    name={[`courses`, index, 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber
                        min={1}
                        style={{ width: '100%' }}
                        disabled={true}
                    />
                </Form.Item>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0, display: 'none' }}
                    name={[`courses`, index, 'courseName']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Input disabled={true} />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0 }}
                    name={[`courses`, index, 'mrp']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber min={1} style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0 }}
                    name={[`courses`, index, 'comboDiscount']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0 }}
                    name={[`courses`, index, 'credits']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0 }}
                    name={[`courses`, index, 'discount']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item
                    label=""
                    key={k}
                    style={{ marginBottom: 0 }}
                    name={[`courses`, index, 'price']}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        precision={2}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            </Col>
        </Row>
    ))

    return (
        <div>
            <Spin spinning={loading}>
                {Object.keys(transactionData).length > 0 ? (
                    <>
                        <div>
                            <Button
                                onClick={() => goBack(navigate)}
                                size="small"
                            >
                                Back
                            </Button>
                            {transactionData.status ===
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .PENDING ||
                            transactionData.status ===
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .FAILURE ? (
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        autoVerifyPaymentStatus()
                                    }}
                                    size="small"
                                    style={{ marginLeft: 10 }}
                                >
                                    Verify Payment Status
                                </Button>
                            ) : null}
                            {transactionData.status ===
                            TransactionConstants.TRANSACTION_CONSTANTS
                                .SUCCESS ? (
                                <>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        size="small"
                                        onClick={() => showRefundModal()}
                                    >
                                        Refund
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        size="small"
                                        onClick={() => generateInvoice()}
                                    >
                                        Generate Invoice
                                    </Button>
                                    {Utils.isUserAdmin() ? (
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            size="small"
                                            onClick={() =>
                                                showUpdateTransactionModal()
                                            }
                                        >
                                            Update Transaction
                                        </Button>
                                    ) : null}
                                    {Utils.isUserAdmin() &&
                                    transactionData.status ===
                                        TransactionConstants
                                            .TRANSACTION_CONSTANTS.SUCCESS ? (
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            size="small"
                                            onClick={() =>
                                                migrateToCourseAccess()
                                            }
                                        >
                                            Migrate to Course Access
                                        </Button>
                                    ) : null}
                                    {Utils.isUserAdmin() &&
                                    transactionData.status ===
                                        TransactionConstants
                                            .TRANSACTION_CONSTANTS.SUCCESS ? (
                                        <Popconfirm
                                            title="Are you sure?"
                                            onConfirm={() => migrateToCourier()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                style={{ marginLeft: 10 }}
                                                size="small"
                                            >
                                                Migrate to Courier
                                            </Button>
                                        </Popconfirm>
                                    ) : null}
                                </>
                            ) : null}
                            {transactionData.status ===
                            TransactionConstants.TRANSACTION_CONSTANTS
                                .LOAN_PROCESSING ? (
                                <Button
                                    style={{ marginLeft: 10 }}
                                    size="small"
                                    type="primary"
                                    onClick={() => showApproveLoanModal()}
                                >
                                    Loan Approved
                                </Button>
                            ) : null}
                            {transactionData.status ===
                            TransactionConstants.TRANSACTION_CONSTANTS
                                .LOAN_PROCESSING ? (
                                <Button
                                    style={{ marginLeft: 10 }}
                                    size="small"
                                    type="primary"
                                    onClick={() => showLoanRejectedModal()}
                                >
                                    Loan Rejected
                                </Button>
                            ) : null}
                            {Utils.isSuperUserAdmin() &&
                            transactionData.status !==
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .SUCCESS ? (
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => deleteTransaction()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        size="small"
                                    >
                                        Delete Transaction
                                    </Button>
                                </Popconfirm>
                            ) : null}
                        </div>
                        <Descriptions
                            title="Transaction Details"
                            bordered
                            size="small"
                            style={{ marginTop: 10 }}
                        >
                            <Descriptions.Item label="Transaction Id">
                                <b>{transactionData.transactionId}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <Tag
                                    color={returnTransactionColor(
                                        transactionData.status
                                    )}
                                >
                                    {transactionData.status.toUpperCase()}
                                </Tag>
                            </Descriptions.Item>
                            <Descriptions.Item label="Initiated At | Verified At">
                                {transactionData.initiatedAt}
                                <br></br> {transactionData.verifiedAt}
                            </Descriptions.Item>
                            <Descriptions.Item label="MRP | Price">
                                {transactionData.mrp + ' | '}
                                <b>{transactionData.price}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Discount">
                                <span>
                                    {transactionData.creditsInt ? (
                                        <span>
                                            {'iCash: '}
                                            <strong>
                                                {transactionData.credits}
                                            </strong>
                                            <br />
                                        </span>
                                    ) : null}
                                </span>
                                <span>
                                    {transactionData.comboDiscountDouble ? (
                                        <span>
                                            {'Combo: '}
                                            <strong>
                                                {transactionData.comboDiscount}
                                            </strong>
                                            <br />
                                        </span>
                                    ) : null}
                                </span>
                                <span>
                                    {transactionData.couponName ? (
                                        <span>
                                            {'Coupon: '}
                                            <strong>
                                                {transactionData.discount}
                                            </strong>
                                            <br />
                                            <span>
                                                {'' +
                                                    transactionData.couponName +
                                                    ' | ' +
                                                    transactionData.couponValue +
                                                    ' | ' +
                                                    transactionData.couponType}
                                            </span>
                                        </span>
                                    ) : null}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Type">
                                {transactionData.type}
                            </Descriptions.Item>
                            <Descriptions.Item label="User Info">
                                {transactionData.user.firstName +
                                    ' ' +
                                    transactionData.user.lastName}
                                <br />
                                {transactionData.user.phone
                                    ? transactionData.user.isd +
                                      '-' +
                                      transactionData.user.phone
                                    : ''}
                                <br />
                                <Button
                                    type="link"
                                    style={{
                                        padding: 0,
                                        border: 'none',
                                        height: 0,
                                    }}
                                    onClick={() => {
                                        navigateToUserDetails(
                                            transactionData.user
                                        )
                                    }}
                                >
                                    {transactionData.user.email}
                                </Button>
                                <CopyToClipboard
                                    text={transactionData.user.email}
                                    onCopy={onCopy}
                                >
                                    <Button type="link">
                                        <CopyOutlined />
                                    </Button>
                                </CopyToClipboard>
                            </Descriptions.Item>
                            <Descriptions.Item label="OS">
                                {transactionData.os}
                            </Descriptions.Item>
                            <Descriptions.Item label="Payment Gateway">
                                {transactionData.paymentGateway}
                            </Descriptions.Item>
                            <Descriptions.Item label="Payment Gateway ID">
                                {transactionData.paymentGatewayId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Payment Gateway Order ID">
                                {transactionData.paymentGatewayOrderId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Pincode">
                                {transactionData.pincode} |{' '}
                                {transactionData.state}
                            </Descriptions.Item>
                            <Descriptions.Item label="Updated By">
                                {transactionData.updateBy
                                    ? transactionData.updateBy.firstName +
                                      ' ' +
                                      transactionData.updateBy.lastName
                                    : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Table
                            columns={courseTableColumns}
                            dataSource={
                                transactionData ? transactionData.courses : null
                            }
                            pagination={false}
                            style={{ marginTop: 8 }}
                            rowKey="courseName"
                        />
                        {transactionData &&
                        transactionData.creditsHistory.length > 0 ? (
                            <Table
                                columns={creditTableColumns}
                                dataSource={
                                    transactionData
                                        ? transactionData.creditsHistory
                                        : null
                                }
                                pagination={false}
                                style={{ marginTop: 8 }}
                                rowKey="id"
                            />
                        ) : null}
                        <Button
                            type="primary"
                            onClick={() => {
                                openAddCommentModal()
                            }}
                            style={{ marginLeft: 5, marginTop: 8 }}
                            size="small"
                        >
                            Add Comment
                        </Button>
                        {transactionData &&
                        transactionData.comments.length > 0 ? (
                            <Table
                                columns={commentsTableColumns}
                                dataSource={
                                    transactionData
                                        ? transactionData.comments
                                        : null
                                }
                                pagination={false}
                                style={{ marginTop: 8 }}
                                rowKey="id"
                            />
                        ) : null}
                        <Tag
                            color="geekblue"
                            style={{
                                marginTop: 8,
                                marginLeft: 5,
                                display: 'table',
                            }}
                        >
                            All Transactions
                        </Tag>
                        <Table
                            columns={userTransactionsTableColumns}
                            dataSource={
                                transactionData
                                    ? transactionData.userTransactions
                                    : null
                            }
                            pagination={false}
                            style={{ marginTop: 8 }}
                            size="small"
                            rowKey="id"
                        />
                    </>
                ) : null}
                <Modal
                    title={'Add Comment'}
                    open={addCommentModalVisible}
                    onOk={() => {
                        addComment()
                    }}
                    confirmLoading={modalLoading}
                    onCancel={closeAddCommentModal}
                    okText={'Add'}
                >
                    {addCommentModalVisible ? (
                        <Form form={form} layout="horizontal">
                            <Form.Item
                                label="Enter your comment"
                                name="comments"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Comment needed.',
                                    },
                                ]}
                            >
                                <Input placeholder="Comment" />
                            </Form.Item>
                        </Form>
                    ) : null}
                </Modal>
                <Modal
                    title={'Approve Loan'}
                    open={approveLoanModalVisible}
                    onOk={() => {
                        markAsLoanApproved()
                    }}
                    confirmLoading={modalLoading}
                    onCancel={hideApproveLoanModal}
                    okText={'Approve'}
                    destroyOnClose={true}
                >
                    {approveLoanModalVisible ? (
                        <Form form={form} layout="horizontal">
                            <Form.Item
                                label="Approval Reference ID"
                                name="referenceId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Input placeholder="Reference ID" autoFocus />
                            </Form.Item>
                        </Form>
                    ) : null}
                </Modal>
                <Modal
                    title={'Refund Transaction'}
                    open={refundModalVisible}
                    onOk={() => {
                        refund()
                    }}
                    confirmLoading={modalLoading}
                    onCancel={hideRefundModal}
                    okText={'Refund'}
                    destroyOnClose={true}
                >
                    {refundModalVisible ? (
                        <Form form={form} layout="horizontal">
                            <Form.Item
                                label="Comments"
                                name="comments"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Input placeholder="Comments" autoFocus />
                            </Form.Item>
                        </Form>
                    ) : null}
                </Modal>
                <Modal
                    title={'Reject Loan'}
                    open={loanRejectedModalVisible}
                    onOk={() => {
                        markAsLoanRejected()
                    }}
                    confirmLoading={modalLoading}
                    onCancel={hideLoanRejectedModal}
                    okText={'Reject'}
                    destroyOnClose={true}
                >
                    {loanRejectedModalVisible ? (
                        <Form form={form} layout="horizontal">
                            <Form.Item
                                label="Comments"
                                name="comments"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Input placeholder="Comments" autoFocus />
                            </Form.Item>
                        </Form>
                    ) : null}
                </Modal>
                <Modal
                    title={'Update Attempt'}
                    open={updateCourseAttemptModalVisible}
                    onOk={() => {
                        updateAttempt()
                    }}
                    confirmLoading={modalLoading}
                    onCancel={hideEditAttemptModal}
                    okText={'Update'}
                    destroyOnClose={true}
                >
                    <h3>{selectedCourseRecord.courseName}</h3>
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Update Attempt"
                            name="attempt"
                            rules={[
                                {
                                    required: true,
                                    message: 'Needed!',
                                },
                            ]}
                        >
                            <Select placeholder="Select attempts" showSearch>
                                {attemptOptions}
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </Spin>
            <Modal
                title={'Update Transaction'}
                open={updateTransactionModalVisible}
                onOk={() => {
                    updateTransaction()
                }}
                confirmLoading={modalLoading}
                onCancel={hideUpdateTransactionModal}
                okText={'Update'}
                destroyOnClose={true}
                width={1000}
            >
                <Row gutter={16}>
                    <Col span={4}>
                        <b>Particular</b>
                    </Col>
                    <Col span={4}>
                        <b>MRP</b>
                    </Col>
                    <Col span={4}>
                        <b>Combo Discount</b>
                    </Col>
                    <Col span={4}>
                        <b>iCash</b>
                    </Col>
                    <Col span={4}>
                        <b>Coupon Discount</b>
                    </Col>
                    <Col span={4}>
                        <b>Price</b>
                    </Col>
                </Row>
                {updateTransactionModalVisible ? (
                    <Form form={form} style={{ marginTop: 10 }}>
                        <Row gutter={16}>
                            <Col span={4}>
                                <span>Transaction</span>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label=""
                                    style={{ marginBottom: 0 }}
                                    name="mrp"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={1}
                                        autoFocus
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label=""
                                    style={{ marginBottom: 0 }}
                                    name="comboDiscount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label=""
                                    style={{ marginBottom: 0 }}
                                    name="credits"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label=""
                                    style={{ marginBottom: 0 }}
                                    name="discount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label=""
                                    style={{ marginBottom: 0 }}
                                    name="price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required!',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0}
                                        precision={2}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <h4>Courses</h4>
                        {getFormCourses}
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default TransactionDetails
