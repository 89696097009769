import {
    message,
    Table,
    Row,
    Button,
    Col,
    Modal,
    Form,
    Input,
    Popconfirm,
    Radio,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from '@mui/material'

const Subjects = (props) => {
    const [subjectData, setSubjectData] = useState([])
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [selectedLevel, setSelectedLevel] = useState('Select Level')
    const [addEditSubjectModalVisible, setAddEditSubjectModalVisible] =
        useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [editSubjectRecord, setEditSubjectRecord] = useState('')
    const [form] = Form.useForm()
    const inputRef = useRef()
    useEffect(() => {
        getRequiredData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditSubjectModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditSubjectModalVisible, inputRef])

    const getRequiredData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER)
            .then((res) => {
                var streamDataUpdated = JSON.parse(res.data)
                setStreamData(streamDataUpdated)
                setLevelData(streamDataUpdated[0].levels)
            })
            .catch((error) => {
                message.error(error)
            })
    }
    const getSubjectsData = (
        courseNew = selectedCourse,
        levelNew = selectedLevel
    ) => {
        RestApi.doGet(
            GLOBAL.URL.GET_SUBJECTS_BY_STREAM +
                '?course=' +
                courseNew +
                '&level=' +
                levelNew
        )
            .then((res) => {
                setSubjectData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
    }
    const selectCourse = (event) => {
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                setSelectedLevel('Select Level')
                setSelectedCourse(event.target.value)
                return
            }
        })
    }
    const selectLevel = (event) => {
        setSelectedLevel(event.target.value)
        getSubjectsData(selectedCourse, event.target.value)
    }
    const openAddEditSubjectModal = (record) => {
        form.setFieldsValue(record)
        setAddEditSubjectModalVisible(true)
        setEditSubjectRecord(record)
    }
    const closeAddEditSubjectModal = () => {
        setAddEditSubjectModalVisible(false)
        setEditSubjectRecord(null)
        form.resetFields()
    }
    const handleSubmit = (editRecord) => {
        form.validateFields()
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_SUBJECT
                values['course'] = selectedCourse
                values['level'] = selectedLevel
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_SUBJECT
                    values['id'] = editRecord.id
                }
                let formbodySubject = new FormData()
                formbodySubject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodySubject)
                    .then((response) => {
                        var subjectJsonArrayObject = []
                        subjectJsonArrayObject.push(response.data)
                        if (editRecord) {
                            subjectData.forEach((item) => {
                                if (item.id === editRecord.id) {
                                    item.name = values.name
                                }
                            })
                        } else {
                            setSubjectData([
                                ...subjectData,
                                ...subjectJsonArrayObject,
                            ])
                        }
                        closeAddEditSubjectModal()
                        message.success(
                            editRecord
                                ? 'Subject Edited Successfully'
                                : 'Subject Added Successfully'
                        )
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }
    const deleteItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_SUBJECT, postBody).then((response) => {
            if (response.code === 200) {
                var successObject = subjectData
                setSubjectData(successObject.filter((key) => key.id !== id))
                message.destroy()
                message.success(response.data)
            }
        })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: subjectData,
                updateUrl: GLOBAL.URL.REORDER_SUBJECT,
                columns: columns,
            },
        })
    }

    const enableDisable = (record) => {
        message.loading('Action in Progress...please Wait', 0)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))

        var url = GLOBAL.URL.ENABLE_SUBJECT
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_SUBJECT
        }

        RestApi.doPost(url, postBody).then((response) => {
            getSubjectsData()
            message.destroy()
            message.success(response.data)
        })
    }

    let columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 70,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure want to delete ?"
                    onConfirm={() => {
                        enableDisable(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            openAddEditSubjectModal(record)
                        }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <Link
                        href={'/mcq/chapters'}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/mcq/chapters', {
                                state: {
                                    subjectName: record.name,
                                },
                            })
                        }}
                        underline="none"
                    >
                        <Button type="link">{record.name}</Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure want to delete ?"
                    onConfirm={() => {
                        deleteItem(record.id)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]
    var streamDetails = []
    streamData.forEach((item) => {
        streamDetails.push(
            <Radio.Button value={item.course} key={item.course}>
                {item.course}
            </Radio.Button>
        )
    })
    var levelDetails = []
    levelData.forEach((item) => {
        levelDetails.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    return (
        <div>
            <Col>
                <Row>
                    <Row
                        style={{
                            marginBottom: 8,
                            justifyContent: 'space-between',
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <Col span={18}>
                            <h2>Subjects</h2>
                        </Col>

                        <Col
                            span={6}
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={() => {
                                    openAddEditSubjectModal()
                                }}
                                disabled={
                                    selectedLevel === 'Select Level'
                                        ? true
                                        : false
                                }
                            >
                                Add Subject
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    reorder()
                                }}
                                style={{ marginLeft: 10 }}
                                disabled={
                                    selectedLevel === 'Select Level'
                                        ? true
                                        : false
                                }
                            >
                                Reorder
                            </Button>
                        </Col>
                    </Row>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col span={18}>
                        <Radio.Group onChange={selectCourse}>
                            {streamDetails}
                        </Radio.Group>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col>
                        <Radio.Group
                            value={selectedLevel}
                            onChange={selectLevel}
                            placeholder="Select Level"
                            disabled={selectedCourse ? false : true}
                        >
                            {levelDetails}
                        </Radio.Group>
                    </Col>
                </Row>
            </Col>
            <Table
                columns={columns}
                dataSource={subjectData}
                pagination={false}
                rowKey="id"
                size="small"
            />
            <Modal
                title={editSubjectRecord ? 'Edit Subject' : 'Add Subject'}
                open={addEditSubjectModalVisible}
                onOk={() => {
                    handleSubmit(editSubjectRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={closeAddEditSubjectModal}
                okText={editSubjectRecord ? 'Edit Subject' : 'Add Subject'}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Subject Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Subject Name',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Please enter Subject Name"
                            ref={inputRef}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Icon Url"
                        name="iconUrl"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Icon Url',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter Icon Url" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Subjects
