import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, message, DatePicker, Radio, Select, Form } from 'antd'
import TransactionConstants from '../Transactions/TransactionConstants'

function TransactionItemsReport(props) {
    const { RangePicker } = DatePicker
    const { Option } = Select

    const dateFormat = 'DD-MMM-YY'

    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const [attempts, setAttempts] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getCourses()
        getAttempts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCourses = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then((res) => {
            setCourses(res.data)
        })
    }

    const getAttempts = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttempts(res.data)
        })
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.startDate = startDate
                values.endDate = endDate

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.GENERATE_TRANSACTION_ITEMS_REPORT,
                    body
                )
                    .then((res) => {
                        message.success(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getCourseOptions = () => {
        var courseOptions = []
        courses.forEach((item) => {
            courseOptions.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return courseOptions
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((attempt) => {
            options.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt +
                        ' (' +
                        (attempt.validityString
                            ? attempt.validityString
                            : attempt.allowedDays + ' Days') +
                        ')'}
                </Option>
            )
        })

        return options
    }

    return (
        <div>
            <h2>Generate Transaction Items Report</h2>
            <Form
                form={form}
                initialValues={{
                    status: TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS,
                }}
                layout="horizontal"
                {...formItemLayout}
            >
                <Form.Item
                    label="Transaction Status"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                    name="status"
                    getValueFromEvent={(e) => e.target.value}
                >
                    <Radio.Group>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .SUCCESS
                            }
                        >
                            Sucess
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .PENDING
                            }
                        >
                            Pending
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .FAILURE
                            }
                        >
                            Failed
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .REFUNDED
                            }
                        >
                            Refunded
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="Date Range"
                    style={{ marginBottom: 10 }}
                    name="dateRange"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Courses"
                    style={{ marginBottom: 10 }}
                    name="courseIds"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={true}
                        mode="multiple"
                    >
                        {getCourseOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Attempts"
                    style={{ marginBottom: 0 }}
                    name="attempts"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={true}
                        mode="multiple"
                    >
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                loading={loading}
                onClick={generateReport}
                style={{ marginTop: 20, marginLeft: 50 }}
            >
                Generate Report
            </Button>
        </div>
    )
}

export default TransactionItemsReport
