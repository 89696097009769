import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button, Select, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

class SubjectRevenue extends React.Component {
    state = {
        loading: false,
        streamData: [],
        levelsData: [],
        limit: 6,
        course: '',
        level: '',
        courseName: '',
        indOrFullSubject: 'both',
        purchaseOrExtension: 'both',
        data: [],
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        courseNames: [],
    }

    componentDidMount() {
        this.getStreamCourses()
        this.getCourseNames()
    }

    getStreamCourses() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            var streamData = JSON.parse(res.data)
            this.setState({
                streamData: streamData,
            })
        })
    }

    getCourseNames = () => {
        var url =
            GLOBAL.URL.GET_COURSE_NAMES +
            '?course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject

        RestApi.doGet(url).then((res) => {
            this.setState({
                courseNames: res.data,
            })
        })
    }

    getData = () => {
        this.setState({
            loading: true,
        })

        var url =
            GLOBAL.URL.GET_STREAM_REVENUE +
            '?limit=' +
            this.state.limit +
            '&course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&courseName=' +
            (this.state.courseName
                ? encodeURIComponent(this.state.courseName)
                : '') +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject +
            '&purchaseOrExtension=' +
            this.state.purchaseOrExtension

        RestApi.doGet(url)
            .then((res) => {
                this.setState(
                    {
                        data: res.data,
                    },
                    () => {
                        this.drawChart()
                    }
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart() {
        var data = []
        var count = []
        var labels = []

        this.state.data.forEach((row) => {
            labels.push(row.label)
            data.push(row.revenue)
            count.push(row.count)
        })

        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = data
        revenueDataSet.label = 'Revenue (lacs)'
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.datalabels = { display: true }

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = count
        countDataSet.label = 'Count'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'
        countDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = StatsConstants.CHART_OPTIONS_TWO_LEVELS
        options.plugins.title.text = 'Subject Revenue'

        this.setState({
            mapData: mapData,
            options: options,
        })
    }

    onChangeLimit = (value) => {
        this.setState({
            limit: value,
        })
    }

    onChangeStream = (e) => {
        var levels = []
        this.state.streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        this.setState(
            {
                course: e.target.value,
                levelsData: levels,
                level: '',
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeLevel = (e) => {
        this.setState(
            {
                level: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeIndOrFullSubject = (e) => {
        this.setState(
            {
                indOrFullSubject: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeCourseName = (value) => {
        this.setState({ courseName: value })
    }

    onClearCourseName = () => {
        this.setState({ courseName: '' })
    }

    onChangePurchaseOrExtension = (e) => {
        this.setState({
            purchaseOrExtension: e.target.value,
        })
    }

    render() {
        const { Option } = Select

        var streamOptions = []
        this.state.streamData.forEach((item) => {
            streamOptions.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })
        streamOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var levelOptions = []
        this.state.levelsData.forEach((item) => {
            levelOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })
        levelOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var courseOptions = []
        this.state.courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return (
            <div>
                <div style={{ marginTop: 10 }}>
                    {this.state.streamData.length > 0 ? (
                        <div>
                            <Radio.Group onChange={this.onChangeStream}>
                                {streamOptions}
                            </Radio.Group>
                            {this.state.course ? (
                                <Radio.Group
                                    onChange={this.onChangeLevel}
                                    value={this.state.level}
                                    style={{ marginLeft: 10 }}
                                >
                                    {levelOptions}
                                </Radio.Group>
                            ) : null}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 10, float: 'left' }}>
                        <Radio.Group
                            onChange={this.onChangeIndOrFullSubject}
                            value={this.state.indOrFullSubject}
                        >
                            <Radio.Button value="both" key={'both'}>
                                {'Both'}
                            </Radio.Button>
                            <Radio.Button value="full" key={'full'}>
                                {'Full Subjects'}
                            </Radio.Button>
                            <Radio.Button value="ind" key={'ind'}>
                                {'Individual Modules'}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Select
                        placeholder="Select Course"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 600 }}
                        allowClear={true}
                        onChange={this.onChangeCourseName}
                        onClear={this.onClearCourseName}
                    >
                        {courseOptions}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        onClick={this.getData}
                        loading={this.state.loading}
                    >
                        Fetch
                    </Button>
                    <span style={{ marginLeft: 10 }}># Months:</span>
                    <InputNumber
                        min={1}
                        defaultValue={6}
                        onChange={this.onChangeLimit}
                        style={{ marginLeft: 10 }}
                    />
                    <Radio.Group
                        onChange={this.onChangePurchaseOrExtension}
                        value={this.state.purchaseOrExtension}
                        style={{ marginLeft: 10 }}
                    >
                        <Radio.Button value="both" key={'both'}>
                            {'Both'}
                        </Radio.Button>
                        <Radio.Button value="purchase" key={'purchase'}>
                            {'Purchase'}
                        </Radio.Button>
                        <Radio.Button value="extension" key={'extension'}>
                            {'Extension'}
                        </Radio.Button>
                    </Radio.Group>
                </div>
                {this.state.mapData.labels ? (
                    <div>
                        <Bar
                            data={this.state.mapData}
                            width={800}
                            height={400}
                            options={this.state.options}
                        />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default SubjectRevenue
