import React, { useState, useEffect } from 'react'
import GLOBAL from '../../Globals'
import { RestApi } from '../../RestApi'
import { Select, Button } from 'antd'

export default function Dsr() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState('')
    const [year, setYear] = useState()
    const [month, setMonth] = useState()
    const { Option } = Select

    const fetchData = () => {
        setLoading(true)
        var url = GLOBAL.URL.GET_DSR
        if (year && month) {
            url = url + '?month=' + month + '&year=' + year
        }

        RestApi.doGet(url)
            .then((res) => {
                setData(res.data)

                if (!year) {
                    var date = new Date()
                    setYear(date.getFullYear())
                    setMonth(date.getMonth() + 1)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getMonthsOptions = () => {
        var options = []
        for (var i = 1; i <= 12; i++) {
            options.push(
                <Option value={i} key={i}>
                    {i}
                </Option>
            )
        }

        return options
    }

    const getYearOptions = () => {
        var options = []
        var currentYear = new Date().getFullYear()
        while (currentYear >= 2020) {
            options.push(
                <Option value={currentYear} key={currentYear}>
                    {currentYear}
                </Option>
            )
            currentYear = currentYear - 1
        }

        return options
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                <span>Select Month / Year: </span>
                <Select
                    placeholder="Month"
                    showSearch
                    style={{ width: 100 }}
                    onChange={(value) => setMonth(value)}
                    value={month}
                >
                    {getMonthsOptions()}
                </Select>
                <Select
                    placeholder="Year"
                    showSearch
                    style={{ width: 100, marginLeft: 10 }}
                    onChange={(value) => setYear(value)}
                    value={year}
                >
                    {getYearOptions()}
                </Select>
                <Button
                    type="primary"
                    style={{ marginLeft: 10 }}
                    loading={loading}
                    onClick={() => fetchData()}
                >
                    Fetch
                </Button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    )
}
