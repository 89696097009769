import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Tag,
    Button,
    Radio,
    Popconfirm,
    message,
    Select,
    Input,
    notification,
    Modal,
    DatePicker,
} from 'antd'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CopyOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faTimes,
    faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ILSearch from '../Search/ILSearch'
import { Link } from '@mui/material'

var QUERY_TYPE_OPEN = 'Open'
var QUERY_TYPE_UNANSWERED = 'Unanswered'

const FILTER_LEADERBOARD_CURRENT_MONTH = 'FILTER_LEADERBOARD_CURRENT_MONTH'
const FILTER_LEADERBOARD_CUSTOM = 'FILTER_LEADERBOARD_CUSTOM'

class ForumsHome extends React.Component {
    state = {
        loading: false,
        queryTypes: [],
        selectedQueryType: '',
        nextUrl: '',
        topics: [],
        topicsCount: {},
        groups: [],
        selectedGroup: '',
        query: '',
        leaderboardCurrentMonth: [],
        leaderboardCurrentMonthNextUrl: '',
        leaderboardYearly: [],
        leaderboardYearlyNextUrl: '',
        leaderboardModalVisible: false,
        loadingLeaderboard: false,
        searchByPrivateNoteModalVisible: false,
        searchByPrivateNoteResult: [],
        searchByPrivateNoteNextUrl: '',
        searchByPrivateNoteSelectedQueryType: 'Open',
        searchByPrivateNoteSearchQuery: '',
        searchByPrivateNoteLoading: false,
        openTopicsModalVisible: false,
        openTopicsHtml: '',
        selectedLeaderboardFilter: FILTER_LEADERBOARD_CURRENT_MONTH,
        leaderboardStartDate: '',
        leaderboardEndDate: '',
        loadingLeaderboarCustom: false,
        leaderboardCustomData: [],
        leaderboardCustomNextUrl: '',
        openTopicsCountByGroup: [],
    }

    componentDidMount() {
        this.getQueryTypes()
        this.getGroups()
    }

    getQueryTypes() {
        this.setState({
            loading: true,
        })

        RestApi.doGet(GLOBAL.URL.GET_FORUM_QUERY_TYPES)
            .then((res) => {
                this.setState(
                    {
                        queryTypes: res.data,
                        selectedQueryType: res.data[0],
                    },
                    () => {
                        this.getTopics()
                    }
                )
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    getGroups() {
        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUPS).then((res) => {
            this.setState({
                groups: JSON.parse(res.data),
            })
        })
    }

    getTopics = () => {
        this.setState({
            loading: true,
        })

        var url =
            GLOBAL.URL.GET_FORUM_POSTS +
            '?type=' +
            this.state.selectedQueryType +
            '&group=' +
            this.state.selectedGroup +
            '&query=' +
            this.state.query
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    topics: response.data,
                    nextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })

                this.getTopicsCount()
            })

        if (this.state.selectedQueryType === 'Open') {
            RestApi.doGet(GLOBAL.URL.GET_OPEN_TOPICS_COUNT_BY_GROUP).then(
                (res) => {
                    this.setState({
                        openTopicsCountByGroup: JSON.parse(res.data),
                    })
                }
            )
        }
    }

    getMoreTopics = () => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.BASE_URL + '/' + this.state.nextUrl
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    topics: [...this.state.topics, ...response.data],
                    nextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    getTopicsCount = () => {
        RestApi.doGet(GLOBAL.URL.GET_TOPICS_COUNT).then((res) => {
            this.setState({ topicsCount: JSON.parse(res.data) })
        })
    }

    onChangeSelection = (e) => {
        this.setState(
            {
                selectedQueryType: e.target.value,
                topics: [],
            },
            () => {
                this.getTopics()
            }
        )
    }

    updateAnswered = (id, answered) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.CLOSE_TOPIC
        if (answered) {
            url = GLOBAL.URL.OPEN_TOPIC
        }

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)

                var topics = this.state.topics
                topics.forEach((item) => {
                    if (id === item.id) {
                        item.answered = !answered
                        return
                    }
                })

                this.setState({
                    topics: topics,
                })

                this.getTopicsCount()
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    enableDisableTopic = (id, enabled) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.ENABLE_FORUM_TOPIC
        if (enabled) {
            url = GLOBAL.URL.DISABLE_FORUM_TOPIC
        }

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)

                var topics = this.state.topics
                topics.forEach((item) => {
                    if (id === item.id) {
                        item.enabled = !enabled
                        return
                    }
                })

                this.setState({
                    topics: topics,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    updatePublicTopic = (id, isPrivate) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.MARK_FORUM_TOPIC_PRIVATE
        if (isPrivate) {
            url = GLOBAL.URL.MARK_FORUM_TOPIC_PUBLIC
        }

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)

                var topics = this.state.topics
                topics.forEach((item) => {
                    if (id === item.id) {
                        item.private = !isPrivate
                        return
                    }
                })

                this.setState({
                    topics: topics,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    navigateToTopicDetails = (id) => {
        this.props.navigate('/forumtopicdetails/' + id)
    }

    onGroupChange = (group) => {
        this.setState({ selectedGroup: group ? group : '' }, () => {
            this.getTopics()
        })
    }

    onSearch = (query) => {
        this.setState({ query: query }, () => {
            this.getTopics()
        })
    }

    showLeaderboardModal = () => {
        this.setState({
            leaderboardModalVisible: true,
            selectedLeaderboardFilter: FILTER_LEADERBOARD_CURRENT_MONTH,
        })

        if (this.state.leaderboardCurrentMonth.length === 0) {
            RestApi.doGet(GLOBAL.URL.FORUMS.GET_LEADERBOARD_CURRENT).then(
                (response) => {
                    var res = JSON.parse(response.data)
                    this.setState({
                        leaderboardCurrentMonth: res.data,
                        leaderboardCurrentMonthNextUrl: res.next,
                    })
                }
            )

            RestApi.doGet(GLOBAL.URL.FORUMS.GET_LEADERBOARD_YEARLY).then(
                (response) => {
                    var res = JSON.parse(response.data)
                    this.setState({
                        leaderboardYearly: res.data,
                        leaderboardYearlyNextUrl: res.next,
                    })
                }
            )
        }
    }

    hideLeaderboardModal = () => {
        this.setState({ leaderboardModalVisible: false })
    }

    showMoreLeaderboardCurrentMonth = () => {
        if (!this.state.leaderboardCurrentMonthNextUrl) {
            return
        }

        this.setState({ loadingLeaderboard: true })

        var url =
            GLOBAL.URL.BASE_URL + this.state.leaderboardCurrentMonthNextUrl
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    leaderboardCurrentMonth: [
                        ...this.state.leaderboardCurrentMonth,
                        ...response.data,
                    ],
                    leaderboardCurrentMonthNextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({
                    loadingLeaderboard: false,
                })
            })
    }

    showMoreLeaderboardYearly = () => {
        if (!this.state.leaderboardYearlyNextUrl) {
            return
        }

        this.setState({ loadingLeaderboard: true })

        var url = GLOBAL.URL.BASE_URL + this.state.leaderboardYearlyNextUrl
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    leaderboardYearly: [
                        ...this.state.leaderboardYearly,
                        ...response.data,
                    ],
                    leaderboardYearlyNextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({
                    loadingLeaderboard: false,
                })
            })
    }

    showSearchByPrivateNoteModal = () => {
        this.setState({ searchByPrivateNoteModalVisible: true })
    }

    hideSearchByPrivateNoteModal = () => {
        this.setState({ searchByPrivateNoteModalVisible: false })
    }

    onChangeSearchByPrivateNoteSelection = (e) => {
        this.setState(
            {
                searchByPrivateNoteSelectedQueryType: e.target.value,
                searchByPrivateNoteResult: [],
                searchByPrivateNoteNextUrl: '',
            },
            () => {
                this.searchByPrivateNote()
            }
        )
    }

    onSearchByPrivateNote = (query) => {
        this.setState({ searchByPrivateNoteSearchQuery: query }, () => {
            this.searchByPrivateNote()
        })
    }

    searchByPrivateNote = () => {
        if (!this.state.searchByPrivateNoteSearchQuery) {
            return
        }

        this.setState({ searchByPrivateNoteLoading: true })

        var url =
            GLOBAL.URL.SEARCH_FORUM_BY_PRIVATE_NOTES +
            '?type=' +
            this.state.searchByPrivateNoteSelectedQueryType +
            '&query=' +
            this.state.searchByPrivateNoteSearchQuery
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    searchByPrivateNoteResult: response.data,
                    searchByPrivateNoteNextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({ searchByPrivateNoteLoading: false })
            })
    }

    showOpenTopics = () => {
        this.setState({
            loading: true,
            openTopicsHtml: '',
            openTopicsModalVisible: true,
        })

        RestApi.doGet(GLOBAL.URL.GET_FORUM_MY_OPEN_TOPICS)
            .then((res) => {
                var content = ''
                if (res.data) {
                    content = res.data
                } else {
                    content = '<p>Nothing to show</p>'
                }

                this.setState({
                    openTopicsHtml: content,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    hideOpenTopics = () => {
        this.setState({
            openTopicsHtml: '',
            openTopicsModalVisible: false,
        })
    }

    createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    onChangeLeaderboardFilter = (e) => {
        this.setState({ selectedLeaderboardFilter: e.target.value })
    }

    getLeaderboardCustomData = () => {
        if (!this.state.leaderboardStartDate) {
            notification['error']({
                message: 'Select start date!',
            })

            return
        }

        if (!this.state.leaderboardEndDate) {
            notification['error']({
                message: 'Select end date!',
            })

            return
        }

        this.setState({ loadingLeaderboarCustom: true })

        RestApi.doGet(
            GLOBAL.URL.FORUMS.GET_LEADERBOARD_CUSTOM +
                '?startDate=' +
                this.state.leaderboardStartDate +
                '&endDate=' +
                this.state.leaderboardEndDate
        )
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    leaderboardCustomData: response.data,
                    leaderboardCustomNextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({ loadingLeaderboarCustom: false })
            })
    }

    getLeaderboardCustomDataNextPage = () => {
        if (!this.state.leaderboardCustomNextUrl) {
            return
        }

        this.setState({
            loadingLeaderboarCustom: true,
        })

        var url =
            GLOBAL.URL.BASE_URL + '/' + this.state.leaderboardCustomNextUrl
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    leaderboardCustomData: [
                        ...this.state.leaderboardCustomData,
                        ...response.data,
                    ],
                    leaderboardCustomNextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({
                    loadingLeaderboarCustom: false,
                })
            })
    }

    onDateChangeLeaderboardCustom = (value, dateString) => {
        this.setState({
            leaderboardStartDate: dateString[0],
            leaderboardEndDate: dateString[1],
        })
    }

    goToResponseStats = () => {
        this.props.navigate('/forum-response-stats')
    }

    render() {
        const { Option } = Select
        const { Search } = Input
        const { RangePicker } = DatePicker

        var options = []
        this.state.queryTypes.forEach((query) => {
            options.push(
                <Radio.Button value={query}>
                    {query}
                    {query === QUERY_TYPE_OPEN ? (
                        <span> ({this.state.topicsCount.open})</span>
                    ) : null}
                    {query === QUERY_TYPE_UNANSWERED ? (
                        <span> ({this.state.topicsCount.unanswered})</span>
                    ) : null}
                </Radio.Button>
            )
        })

        var openTopicsGroupOptions = []
        this.state.openTopicsCountByGroup.forEach((entry) => {
            openTopicsGroupOptions.push(
                <Radio.Button value={entry.id} key={entry.id}>
                    {entry.name}
                    <span> ({entry.count})</span>
                </Radio.Button>
            )
        })

        var groupOptions = []
        this.state.groups.forEach((group) => {
            groupOptions.push(<Option value={group.id}>{group.name}</Option>)
        })

        const columns = [
            {
                title: 'Answered',
                dataIndex: 'id',
                key: 'id',
                width: 90,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.updateAnswered(record.id, record.answered)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                            }}
                        >
                            {record.answered ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'Enabled',
                dataIndex: 'enabled',
                key: 'enabled',
                width: 70,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            this.enableDisableTopic(record.id, record.enabled)
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                            }}
                        >
                            {record.enabled ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'Public',
                dataIndex: 'private',
                key: 'private',
                width: 70,
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() =>
                            this.updatePublicTopic(record.id, record.private)
                        }
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            tyle="link"
                            style={{
                                border: 'none',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 5,
                                paddingBottom: 5,
                            }}
                        >
                            {!record.private ? (
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    color={GLOBAL.COLORAPP.BLUE}
                                />
                            ) : (
                                <FontAwesomeIcon icon={faTimes} color="red" />
                            )}
                        </Button>
                    </Popconfirm>
                ),
            },
            {
                title: 'Details',
                dataIndex: 'date',
                key: 'date',
                width: 120,
                render: (text, record) => (
                    <span style={{ fontSize: 12 }}>
                        {record.user.firstName} {record.user.lastName}
                        <br></br>
                        {record.date}
                        {record.paidUser ? (
                            <Tag color="purple">Paid User</Tag>
                        ) : null}
                    </span>
                ),
            },
            {
                title: 'Group',
                dataIndex: 'group',
                key: 'group',
                width: 200,
                render: (text, record) => (
                    <span style={{ fontSize: 12 }}>
                        {record.forumGroup.name}
                        <br />
                        <span style={{ fontSize: 10 }}>
                            {'(' +
                                record.stream.course +
                                ' - ' +
                                record.stream.level +
                                ')'}
                        </span>
                    </span>
                ),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (
                    <span>
                        <Link
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(
                                    '/forumtopicdetails/' + record.id,
                                    '_blank'
                                )
                            }}
                            underline="none"
                        >
                            <Button
                                type="link"
                                style={{ padding: 0, marginRight: 5 }}
                            >
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </Button>
                        </Link>
                        <Button
                            type="link"
                            onClick={() => {
                                this.navigateToTopicDetails(record.id)
                            }}
                            style={{ padding: 0 }}
                        >
                            {record.title}
                        </Button>
                        <br />
                        <Tag>Latest Answer:</Tag>
                        <br />
                        {record.lastestAnswerBy ? (
                            <>
                                <b>
                                    {record.lastestAnswerBy.firstName +
                                        ' ' +
                                        record.lastestAnswerBy.lastName}
                                </b>
                                <br />
                            </>
                        ) : null}
                        {record.latestAnswer}
                        <div>
                            <CopyToClipboard
                                text={record.deepLink}
                                onCopy={() =>
                                    notification['success']({
                                        message: 'Link copied!',
                                    })
                                }
                            >
                                <span>
                                    <Button
                                        type="link"
                                        style={{ padding: 0, fontSize: 10 }}
                                    >
                                        <CopyOutlined />
                                        {' Deep Link'}
                                    </Button>
                                </span>
                            </CopyToClipboard>
                        </div>
                    </span>
                ),
            },
        ]

        const leaderboardTableColumns = [
            {
                title: 'Rank',
                dataIndex: 'rank',
                key: 'rank',
                width: 50,
            },
            {
                title: 'Points',
                dataIndex: 'points',
                key: 'points',
                width: 75,
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
            },
        ]

        const leaderboardCustomTableColumns = [
            {
                title: 'Rank',
                dataIndex: 'rank',
                key: 'rank',
                width: 50,
            },
            {
                title: 'Points',
                dataIndex: 'points',
                key: 'points',
                width: 75,
            },
            {
                title: 'Name',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (text, record) => (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() =>
                            window.open(
                                '/userdetails/' + record.userId,
                                '_blank'
                            )
                        }
                    >
                        {record.firstName + ' ' + record.lastName}
                    </Button>
                ),
            },
        ]

        const searchByPrivateNoteColumns = [
            {
                title: 'Enabled',
                dataIndex: 'enabled',
                key: 'enabled',
                width: 100,
                render: (text, record) =>
                    record.enabled ? (
                        <CheckCircleOutlined
                            theme="twoTone"
                            style={{ fontSize: 16 }}
                        />
                    ) : (
                        <CloseCircleOutlined
                            theme="twoTone"
                            twoToneColor="#eb2f96"
                            style={{ fontSize: 16 }}
                        />
                    ),
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                width: 180,
                render: (text, record) => (
                    <span style={{ fontSize: 12 }}>{record.date}</span>
                ),
            },
            {
                title: 'Reply',
                dataIndex: 'body',
                key: 'body',
            },
            {
                title: 'Title',
                dataIndex: 'topicTitle',
                key: 'topicTitle',
                render: (text, record) => (
                    <Button
                        type="link"
                        onClick={() => {
                            this.navigateToTopicDetails(record.topicId)
                        }}
                        style={{ padding: 0 }}
                    >
                        {record.topicTitle}
                    </Button>
                ),
            },
        ]

        return (
            <div>
                <h2 style={{ marginBottom: 0 }}>Forums</h2>
                <div>
                    <Select
                        placeholder="Filter By Group"
                        showSearch
                        style={{ width: 250 }}
                        onChange={this.onGroupChange}
                        value={this.state.selectedGroup}
                        allowClear
                    >
                        {groupOptions}
                    </Select>
                    <ILSearch />
                    <Button
                        type="default"
                        style={{ marginLeft: 10 }}
                        onClick={() => this.showSearchByPrivateNoteModal()}
                    >
                        Search by Private note
                    </Button>
                    <Button
                        type="default"
                        style={{ marginLeft: 10 }}
                        onClick={() => this.showLeaderboardModal()}
                    >
                        Leaderboard
                    </Button>
                    <Button
                        type="default"
                        style={{ marginLeft: 10 }}
                        onClick={() => this.goToResponseStats()}
                    >
                        Response Stats
                    </Button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Radio.Group
                        value={this.state.selectedQueryType}
                        onChange={this.onChangeSelection}
                    >
                        {options}
                    </Radio.Group>
                    <Button
                        style={{ marginLeft: 20 }}
                        type="primary"
                        loading={this.state.loading}
                        onClick={this.showOpenTopics}
                    >
                        My Open Topics
                    </Button>
                </div>
                {this.state.selectedQueryType === 'Open' ? (
                    <div style={{ marginTop: 8 }}>
                        <Radio.Group
                            onChange={(e) => this.onGroupChange(e.target.value)}
                            value={this.state.selectedGroup}
                        >
                            {openTopicsGroupOptions}
                        </Radio.Group>
                    </div>
                ) : null}
                <Table
                    columns={columns}
                    dataSource={this.state.topics ? this.state.topics : null}
                    pagination={false}
                    loading={this.state.loading}
                    size="small"
                    style={{ marginTop: 8 }}
                    rowKey="id"
                />
                {this.state.nextUrl ? (
                    <Button
                        type="primary"
                        onClick={() => this.getMoreTopics()}
                        style={{ marginTop: 10 }}
                        size="small"
                    >
                        More
                    </Button>
                ) : null}
                <Modal
                    title="Leaderboard"
                    open={this.state.leaderboardModalVisible}
                    onCancel={this.hideLeaderboardModal}
                    footer={null}
                    width={600}
                >
                    <Radio.Group
                        value={this.state.selectedLeaderboardFilter}
                        onChange={this.onChangeLeaderboardFilter}
                        style={{ marginBottom: 10 }}
                    >
                        <Radio.Button
                            value={FILTER_LEADERBOARD_CURRENT_MONTH}
                            key={FILTER_LEADERBOARD_CURRENT_MONTH}
                        >
                            {'Current Month'}
                        </Radio.Button>
                        <Radio.Button
                            value={FILTER_LEADERBOARD_CUSTOM}
                            key={FILTER_LEADERBOARD_CUSTOM}
                        >
                            {'Custom Date Range'}
                        </Radio.Button>
                    </Radio.Group>
                    {this.state.selectedLeaderboardFilter ===
                    FILTER_LEADERBOARD_CURRENT_MONTH ? (
                        <>
                            <h3>Current Month Leaderboard</h3>
                            <Table
                                columns={leaderboardTableColumns}
                                dataSource={this.state.leaderboardCurrentMonth}
                                pagination={false}
                                loading={this.state.loadingLeaderboard}
                                size="small"
                                rowKey="rank"
                                scroll={{ y: 250 }}
                            />
                            {this.state.leaderboardCurrentMonthNextUrl ? (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() =>
                                        this.showMoreLeaderboardCurrentMonth()
                                    }
                                    loading={this.state.loadingLeaderboard}
                                    style={{ padding: 0 }}
                                >
                                    Show More
                                </Button>
                            ) : null}
                            <h3 style={{ marginTop: 10 }}>
                                Last 1 Year Leaderboard
                            </h3>
                            <Table
                                columns={leaderboardTableColumns}
                                dataSource={this.state.leaderboardYearly}
                                pagination={false}
                                loading={this.state.loadingLeaderboard}
                                size="small"
                                rowKey="rank"
                                scroll={{ y: 250 }}
                            />
                            {this.state.leaderboardYearlyNextUrl ? (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={() =>
                                        this.showMoreLeaderboardYearly()
                                    }
                                    loading={this.state.loadingLeaderboard}
                                    style={{ padding: 0 }}
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                    {this.state.selectedLeaderboardFilter ===
                    FILTER_LEADERBOARD_CUSTOM ? (
                        <>
                            <div>
                                <RangePicker
                                    format="DD-MMM-YYYY"
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={
                                        this.onDateChangeLeaderboardCustom
                                    }
                                    style={{ width: 250 }}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginLeft: 10 }}
                                    loading={this.state.loadingLeaderboarCustom}
                                    onClick={this.getLeaderboardCustomData}
                                >
                                    Fetch
                                </Button>
                            </div>
                            <Table
                                columns={leaderboardCustomTableColumns}
                                dataSource={this.state.leaderboardCustomData}
                                pagination={false}
                                loading={this.state.loadingLeaderboarCustom}
                                size="small"
                                rowKey="rank"
                                scroll={{ y: 450 }}
                                style={{ marginTop: 10 }}
                            />
                            {this.state.leaderboardCustomNextUrl ? (
                                <Button
                                    type="link"
                                    size="small"
                                    onClick={
                                        this.getLeaderboardCustomDataNextPage
                                    }
                                    loading={this.state.loadingLeaderboarCustom}
                                    style={{ padding: 0 }}
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </>
                    ) : null}
                </Modal>
                <Modal
                    title="Search by Private Note"
                    open={this.state.searchByPrivateNoteModalVisible}
                    onCancel={this.hideSearchByPrivateNoteModal}
                    footer={null}
                    width={800}
                >
                    <div>
                        <Search
                            placeholder="Search by private note"
                            onSearch={(value) =>
                                this.onSearchByPrivateNote(value)
                            }
                            style={{ width: 400 }}
                        />
                    </div>
                    <div>
                        <Radio.Group
                            value={
                                this.state.searchByPrivateNoteSelectedQueryType
                            }
                            onChange={this.onChangeSearchByPrivateNoteSelection}
                            style={{ marginTop: 10 }}
                        >
                            <Radio.Button value={'Open'} key={'Open'}>
                                {'Open'}
                            </Radio.Button>
                            <Radio.Button value={'Closed'} key={'Closed'}>
                                {'Closed'}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Table
                        columns={searchByPrivateNoteColumns}
                        dataSource={this.state.searchByPrivateNoteResult}
                        pagination={false}
                        loading={this.state.searchByPrivateNoteLoading}
                        size="small"
                        style={{ marginTop: 8 }}
                        rowKey="id"
                    />
                </Modal>
                <Modal
                    title={'My Open Topics'}
                    open={this.state.openTopicsModalVisible}
                    confirmLoading={this.state.loading}
                    onCancel={this.hideOpenTopics}
                    destroyOnClose={true}
                    width={800}
                    footer={null}
                >
                    {this.state.loading ? (
                        <Button type="link">
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        </Button>
                    ) : (
                        <div
                            dangerouslySetInnerHTML={this.createMarkupWeb(
                                this.state.openTopicsHtml
                            )}
                        />
                    )}
                </Modal>
            </div>
        )
    }
}

export default ForumsHome
