module.exports = {
    COLORS: [
        '#2d8de6',
        '#40b5b3',
        '#fd8d32',
        '#fec345',
        '#fb4970',
        '#8747fe',
        '#bdbfc5',
    ],
    COLORS_BLUE: [
        // '#010e19',
        // '#031d31',
        '#042c4a',
        '#053a63',
        '#07497c',
        '#085794',
        '#0966ad',
        '#0a74c6',
        '#0c83de',
        '#259cf8',
        '#3da7f9',
        '#56b2f9',
        '#6ebdfa',
        '#86c8fb',
        '#9ed3fc',
        '#b6defd',
        '#cfe9fd',
        '#e7f4fe',
    ],
    PIE_CHART_OPTIONS: {
        plugins: {
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                formatter: (value, ctx) => {
                    let sum = 0
                    let dataArr = ctx.chart.data.datasets[0].data
                    // eslint-disable-next-line array-callback-return
                    dataArr.map((data) => {
                        sum += data
                    })
                    let percentage = ((value * 100) / sum).toFixed(2) + '%'
                    return percentage
                },
                color: '#000000',
            },
        },
        elements: {
            arc: {
                borderWidth: 1,
            },
        },
    },
    CHART_OPTIONS: {
        plugins: {
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                render: 'value',
                align: 'end',
                anchor: 'end',
                display: 'auto',
                color: 'black',
                font: {
                    size: 14,
                },
                rotation: 0,
            },
        },
        hover: {
            mode: 'nearest',
            intersect: false,
        },
        tooltips: {
            mode: 'label',
            intersect: false,
        },
        elements: {
            point: {
                pointStyle: 'circle',
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            },
        },
    },
    CHART_OPTIONS_TWO_LEVELS: {
        plugins: {
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                render: 'value',
                align: 'end',
                anchor: 'end',
                display: 'auto',
                color: 'black',
                font: {
                    size: 16,
                },
                rotation: 0,
            },
        },
        hover: {
            mode: 'nearest',
            intersect: false,
        },
        tooltips: {
            mode: 'label',
            intersect: false,
        },
        elements: {
            point: {
                pointStyle: 'circle',
            },
        },
        legend: {
            display: true,
            labels: {
                fontColor: '#000000',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            'y-1': {
                type: 'linear',
                display: true,
                position: 'left',
                id: 'y-1',
                ticks: {
                    beginAtZero: true,
                },
                grid: {
                    display: true,
                },
            },
            'y-2': {
                type: 'linear',
                display: true,
                position: 'right',
                id: 'y-2',
                ticks: {
                    beginAtZero: true,
                },
                grid: {
                    display: false,
                },
            },
        },
    },
    INITIAL_BAR_OPTIONS: {
        datasets: [{}],
    },
}
