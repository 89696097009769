import { Table, Button, Modal } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Link } from '@mui/material'
class Groups extends React.Component {
    state = {
        loading: false,
        courseGroupData: [],
        showImageModal: false,
        imageSrc: '',
    }

    closeImageModal = () => {
        this.setState({ showImageModal: false })
    }
    componentDidMount() {
        this.getcourseGroupData()
    }

    getcourseGroupData() {
        this.setState({ loading: true })
        RestApi.doGet(GLOBAL.URL.GET_COURSE_GROUP_LIST)
            .then((res) => {
                this.setState({ courseGroupData: res.data })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render() {
        let columns = []
        if (Object.keys(this.state.courseGroupData).length > 0) {
            columns = [
                {
                    title: 'Edit',
                    key: 'action',
                    width: '60px',
                    render: (text, record) => (
                        <span>
                            <Link
                                href={'/addgroup'}
                                onClick={(event) => {
                                    event.preventDefault()
                                    this.props.navigate('/addgroup', {
                                        state: { record: record },
                                    })
                                }}
                                underline="none"
                            >
                                <Button type="link" style={{ padding: 0 }}>
                                    <EditOutlined
                                        theme="twoTone"
                                        style={{ fontSize: 14 }}
                                    />
                                </Button>
                            </Link>
                        </span>
                    ),
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Stream',
                    dataIndex: 'stream',
                    key: 'stream',
                    render: (text, record) => (
                        <span>
                            <p>
                                {record.stream.course +
                                    '-' +
                                    record.stream.level}
                            </p>
                        </span>
                    ),
                },
                {
                    title: 'Picture',
                    dataIndex: 'pictureUrl',
                    key: 'pictureUrl',
                    render: (text, record) => (
                        <span>
                            {record.pictureUrl ? (
                                <Button
                                    type="link"
                                    style={{ padding: 0, margin: 0 }}
                                    onClick={() => {
                                        this.setState({
                                            showImageModal: true,
                                            imageSrc: record.pictureUrl,
                                        })
                                    }}
                                >
                                    View
                                </Button>
                            ) : null}
                        </span>
                    ),
                },
                {
                    title: 'Duration',
                    dataIndex: 'durationString',
                    key: 'durationString',
                },
            ]
        }

        return (
            <div>
                <h2>Course Groups </h2>
                <Link
                    href={'/addgroup'}
                    onClick={(event) => {
                        event.preventDefault()
                        this.props.navigate('/addgroup', {
                            state: { record: null },
                        })
                    }}
                    underline="none"
                >
                    <Button type="primary" size="small">
                        Add Group
                    </Button>
                </Link>
                <Table
                    columns={columns}
                    dataSource={this.state.courseGroupData}
                    pagination={false}
                    size="small"
                    style={{ marginTop: 10 }}
                    loading={this.state.loading}
                />
                <Modal
                    title="Image Preview"
                    open={this.state.showImageModal}
                    onCancel={this.closeImageModal}
                    footer={null}
                >
                    <img
                        src={this.state.imageSrc}
                        alt={this.state.imageSrc}
                        style={{ maxWidth: '100%' }}
                    />
                </Modal>
            </div>
        )
    }
}

export default Groups
