import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button, message, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)
const emptyBarData = {
    datasets: [{}],
}

class UnattendedLeadsStats extends React.Component {
    state = {
        loading: false,
        data: [],
        totalUsersData: [],
        labels: [],
        mapData: emptyBarData,
        options: {},
        dataDays: [],
        totalUsersDaysData: [],
        labelsDays: [],
        mapDataDays: emptyBarData,
        optionsDays: {},
        limit: 0,
        streamData: [],
        levelsData: [],
        selectedStream: '',
        selectedLevel: '',
    }

    componentDidMount() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            this.setState({ streamData: JSON.parse(res.data) })
        })
    }

    getData() {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.GET_UNATTENDED_NEW_USERS_BY_MONTH
        if (this.state.limit > 0) {
            url = url + this.state.limit
        }

        url =
            url +
            '&stream=' +
            this.state.selectedStream +
            '&level=' +
            this.state.selectedLevel

        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var data = []
                var totalUsersData = []
                res.data.forEach((row) => {
                    labels.push(row.label)
                    data.push(row.attendedUsers)
                    totalUsersData.push(row.totalUsers)
                })
                this.setState(
                    {
                        labels: labels,
                        data: data,
                        totalUsersData: totalUsersData,
                    },
                    () => this.drawChart()
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart() {
        var newSignupDataSet = {}
        newSignupDataSet.data = this.state.data
        newSignupDataSet.label = 'Attended Users'
        newSignupDataSet.backgroundColor = '#4bc0c0'
        newSignupDataSet.borderColor = '#4bc0c0'
        newSignupDataSet.fill = true

        var totalUsersDataSet = {}
        totalUsersDataSet.data = this.state.totalUsersData
        totalUsersDataSet.label = 'Total Users'
        totalUsersDataSet.backgroundColor = '#2d8de6'
        totalUsersDataSet.borderColor = '#2d8de6'
        totalUsersDataSet.fill = true

        var mapData = {}
        mapData.labels = this.state.labels
        mapData.datasets = []
        mapData.datasets.push(newSignupDataSet)
        mapData.datasets.push(totalUsersDataSet)

        var options = StatsConstants.CHART_OPTIONS
        options.plugins.title.text = 'Attended New Users By Month'
        options.scales.x.stacked = false
        options.scales.y.stacked = false
        options.onClick = (evt, item) => {
            this.getMonthSplit(item)
        }

        this.setState({
            mapData: mapData,
            options: options,
        })
    }

    onChange = (value) => {
        this.setState({
            limit: value,
        })
    }

    onClick = () => {
        this.getData()
    }

    getMonthSplit = (item) => {
        if (Object.keys(item).length === 0) {
            return
        }

        message.loading('Fetching data...', 0)
        var url =
            GLOBAL.URL.GET_UNATTENDED_NEW_USERS_BY_DAY +
            this.state.labels[item[0].index] +
            '&stream=' +
            this.state.selectedStream +
            '&level=' +
            this.state.selectedLevel
        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var data = []
                var totalUsersDaysData = []
                res.data.forEach((row) => {
                    labels.push(row.label)
                    data.push(row.attendedUsers)
                    totalUsersDaysData.push(row.totalUsers)
                })
                this.setState(
                    {
                        labelsDays: labels,
                        dataDays: data,
                        totalUsersDaysData: totalUsersDaysData,
                    },
                    () => this.drawDayChart()
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
                message.destroy()
            })
    }

    drawDayChart() {
        var newSignupDataSet = {}
        newSignupDataSet.data = this.state.dataDays
        newSignupDataSet.label = 'Attended Users'
        newSignupDataSet.backgroundColor = '#4bc0c0'
        newSignupDataSet.borderColor = '#4bc0c0'
        newSignupDataSet.fill = true

        var totalUsersDataSet = {}
        totalUsersDataSet.data = this.state.totalUsersDaysData
        totalUsersDataSet.label = 'Total Users'
        totalUsersDataSet.backgroundColor = '#2d8de6'
        totalUsersDataSet.borderColor = '#2d8de6'
        totalUsersDataSet.fill = true

        var mapData = {}
        mapData.labels = this.state.labelsDays
        mapData.datasets = []
        mapData.datasets.push(newSignupDataSet)
        mapData.datasets.push(totalUsersDataSet)

        var options = StatsConstants.CHART_OPTIONS
        options.plugins.title.text = 'Attended New Users By Day'
        options.scales.x.stacked = false
        options.scales.y.stacked = false

        this.setState({
            mapDataDays: mapData,
            optionsDays: options,
        })

        document
            .getElementById('dayChart')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    getStreamOptions = () => {
        var options = []
        this.state.streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    getLevelOptions = () => {
        var options = []
        this.state.levelsData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    onChangeStream = (e) => {
        this.setState({ selectedLevel: '' })

        var levels = []
        this.state.streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        this.setState({ selectedStream: e.target.value, levelsData: levels })
    }

    onChangeLevel = (e) => {
        this.setState({ selectedLevel: e.target.value })
    }

    render() {
        return (
            <div>
                <h2>Attended New Users Stats</h2>
                <div>
                    <div>
                        <Radio.Group onChange={this.onChangeStream}>
                            {this.getStreamOptions()}
                        </Radio.Group>
                    </div>
                    {this.state.selectedStream && (
                        <div style={{ marginTop: 10 }}>
                            <Radio.Group
                                value={this.state.selectedLevel}
                                onChange={this.onChangeLevel}
                            >
                                {this.getLevelOptions()}
                            </Radio.Group>
                        </div>
                    )}
                    <div style={{ marginTop: 10 }}>
                        <span># Months: </span>
                        <InputNumber
                            min={1}
                            defaultValue={6}
                            onChange={this.onChange}
                        />
                        <Button
                            type="primary"
                            onClick={this.onClick}
                            loading={this.state.loading}
                            style={{ marginLeft: 10 }}
                        >
                            Fetch
                        </Button>
                    </div>
                </div>
                {this.state.data.length ? (
                    <Bar
                        data={this.state.mapData}
                        width={800}
                        height={400}
                        options={this.state.options}
                    />
                ) : null}
                {this.state.dataDays.length ? (
                    <Bar
                        id="dayChart"
                        data={this.state.mapDataDays}
                        width={800}
                        height={400}
                        options={this.state.optionsDays}
                    />
                ) : null}
            </div>
        )
    }
}

export default UnattendedLeadsStats
