import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { ILCalendarViewConstants } from '../ILShareComponents/ILCalendar/SubComponents/CalendarViewConstants'
import dayjs from 'dayjs'
import { convertDateToDDMMMYYYY } from '../ILShareComponents/ILCalendar/calendarUtil'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Button,
    DatePicker,
    Form,
    Modal,
    Radio,
    TimePicker,
    notification,
} from 'antd'
import ILCalendarView from '../ILShareComponents/ILCalendar/ILCalendarView'
import ILEmployeeDayHours from './ILEmployeeDayHours'

const userCalendarId = 'STUDY_MAIN_CONTAINER'
const MONTH_VIEW = 'monthView',
    DATE_RANGE = 'dateRange'

const ILEmployeeCalendarView = (props) => {
    const { classes, cx } = useStyles()
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    const currentDay = currentDate.getDate()

    const [filter, setFilter] = useState(MONTH_VIEW)
    const [activeDate, setActiveDate] = useState(currentDate)
    const [viewingDate, setViewingDate] = useState(
        activeDate ? activeDate.getDate() : null
    )
    const [activeYear, setActiveYear] = useState(currentDate.getFullYear())
    const [activeMonth, setActiveMonth] = useState(currentDate.getMonth())
    const [employeeLoginData, setEmployeeLoginData] = useState({})
    const [datesPresent, setDatesPresent] = useState({})
    const [dataLoading, setDataLoading] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [loginDate, setLoginDate] = useState('')
    const [loginTime, setLoginTime] = useState()
    const [logoutTime, setLogoutTime] = useState()
    const [dateRangeStartDate, setDateRangeStartDate] = useState()
    const [dateRangeEndDate, setDateRangeEndDate] = useState()
    const [addLoginModalVisible, setAddLoginModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    const viewingMonthDays = new Date(activeYear, activeMonth + 1, 0).getDate()
    const selectedRecord = props.selectedRecord
    const [form] = Form.useForm()
    const { RangePicker } = DatePicker

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    useEffect(() => {
        getEmployeeLoginData()
        // eslint-disable-next-line
    }, [dateRangeStartDate, dateRangeEndDate])

    useEffect(() => {
        if (Object.keys(employeeLoginData).length > 0) {
            if (employeeLoginData.days.length > 0) {
                const dates = {}
                employeeLoginData.days.forEach((item) => {
                    const dateObj = dayjs(item.loginDate, 'DD-MMM-YYYY')
                    dates[dateObj.date()] = {
                        holiday: item.holiday,
                        leave: item.leave,
                        totalLoginDuration: item.totalLoginDuration,
                    }
                })
                setDatesPresent(dates)
            } else {
                setDatesPresent({})
            }
        }
    }, [employeeLoginData])

    const handleDateClick = (event) => {
        if (!anchorEl) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleDateClose = () => {
        setAnchorEl(null)
    }

    const getDateObject = (date = 1, month, year) => {
        const dateObj = new Date(year, month, date)
        return dateObj
    }

    const onClickDate = (day) => {
        const currentDay = day.toString().padStart(2, '0')
        const element = document.getElementById(
            `${currentDay}-${ILCalendarViewConstants.monthsList[activeMonth]}-${activeYear}`
        )

        if (element) {
            document.getElementById(userCalendarId).scrollTo({
                top: element.offsetTop + 100,
                behavior: 'smooth',
            })
        }
    }

    const getEmployeeLoginData = (
        month = activeMonth,
        year = activeYear,
        totalDays = viewingMonthDays
    ) => {
        setDataLoading(true)
        const startDate = dateRangeStartDate
            ? dateRangeStartDate
            : convertDateToDDMMMYYYY(getDateObject(1, month, year))
        const endDate = dateRangeEndDate
            ? dateRangeEndDate
            : convertDateToDDMMMYYYY(getDateObject(totalDays, month, year))

        var url =
            GLOBAL.URL.IL.GET_LOGIN_DATA +
            '?userId=' +
            selectedRecord.user.id +
            '&startDate=' +
            (startDate ? startDate : '') +
            '&endDate=' +
            (endDate ? endDate : '')

        RestApi.doGet(url)
            .then((response) => {
                setEmployeeLoginData(response.data)
            })
            .finally(() => setDataLoading(false))
    }

    const onClickDay = (day) => {
        const dateValue = new Date(activeYear, activeMonth, day)
        setActiveDate(dateValue)
        setViewingDate(day)
        onClickDate(day)
    }

    const getDay = (month, year, date) => {
        return new Date(year, month, date).getDay()
    }

    const getCurrentDay = (month, year) => {
        return month === currentMonth && year === currentYear ? currentDay : 1
    }

    const handlePrevious = () => {
        let newMonth = activeMonth - 1
        let newYear = activeYear

        if (newMonth === -1) {
            newMonth = 11
            newYear--
        }
        const dateValue = new Date(newYear, newMonth, 1)
        const numberOfDays = new Date(newYear, newMonth + 1, 0).getDate()
        setActiveDate(null)
        setActiveYear(dateValue.getFullYear())
        setActiveMonth(dateValue.getMonth())
        setViewingDate(null)
        getEmployeeLoginData(newMonth, newYear, numberOfDays)
    }

    const handleNext = () => {
        let newMonth = activeMonth + 1
        let newYear = activeYear

        if (newMonth === 12) {
            newMonth = 0
            newYear++
        }
        const dateValue = new Date(newYear, newMonth, 1)
        const numberOfDays = new Date(newYear, newMonth + 1, 0).getDate()
        setActiveDate(null)
        setActiveYear(dateValue.getFullYear())
        setActiveMonth(dateValue.getMonth())
        setViewingDate(null)
        getEmployeeLoginData(newMonth, newYear, numberOfDays)
    }

    const customStyles = () => {
        return {
            calendarContainer: classes.calendarContainer,
            wrapperStyles: classes.wrapperStyles,
        }
    }

    const onSelectMonth = (month, year) => {
        const dateValue = new Date(year, month, 1)
        const numberOfDays = new Date(year, month + 1, 0).getDate()
        setActiveDate(dateValue)
        setViewingDate(getCurrentDay(month, year))
        setActiveYear(dateValue.getFullYear())
        setActiveMonth(dateValue.getMonth())
        getEmployeeLoginData(month, year, numberOfDays)
        handleDateClose()
    }

    const RenderDot = ({ dayView, day }) => {
        const { classes } = useStyles()
        return (
            <Box
                className={cx(classes.dotsContainer, {
                    [classes.dayDotStyle]: dayView,
                })}
            >
                <Grid
                    className={cx(classes.dot, classes.greenDot, {
                        [classes.holidayDot]: datesPresent[day].holiday,
                        [classes.leaveDot]:
                            datesPresent[day].leave &&
                            !datesPresent[day].holiday,
                    })}
                />
                {(datesPresent[day].holiday || datesPresent[day].leave) &&
                datesPresent[day].totalLoginDuration ? (
                    <Grid className={cx(classes.dot, classes.greenDot)} />
                ) : null}
            </Box>
        )
    }

    const IndicatorsCalendar = ({ day, dayView }) => {
        return day === viewingDate && datesPresent[day] ? (
            <RenderDot dayView={dayView} day={day} />
        ) : null
    }

    const dayStyling = (day, dayView = false) => {
        if (datesPresent[day]) {
            return datesPresent[day].holiday
                ? classes.dateHoliday
                : datesPresent[day].leave
                ? classes.dateLeave
                : classes.datePresent
        } else {
            const selectedDate = new Date(activeYear, activeMonth, day)
            const dayOfWeekIndex = selectedDate.getDay()
            const selectedDateDayjs = dayjs(selectedDate)
            const currentDayDayjs = dayjs(currentDate)

            if (selectedDateDayjs.isBefore(currentDayDayjs, 'day')) {
                return dayOfWeekIndex !== 0 && dayOfWeekIndex !== 6
                    ? classes.dateMiss
                    : null
            }

            return null
        }
    }

    const showAddLoginModal = () => {
        setLoginDate('')
        setLoginTime('')
        setLogoutTime('')

        setAddLoginModalVisible(true)
    }

    const hideAddLoginModal = () => {
        setAddLoginModalVisible(false)
    }

    const onChangeLoginTime = (_, timeString) => {
        setLoginTime(timeString)
    }

    const onChangeLogoutTime = (_, timeString) => {
        setLogoutTime(timeString)
    }

    const onChangeLoginDate = (_, dateString) => {
        setLoginDate(dateString)
    }
    const onDateChange = (value, dateString) => {
        setDateRangeStartDate(dateString[0])
        setDateRangeEndDate(dateString[1])
    }
    const onChangeTabView = (e) => {
        if (e.target.value === MONTH_VIEW) {
            setDateRangeStartDate('')
            setDateRangeEndDate('')
        }

        setFilter(e.target.value)
    }

    const addLoginData = () => {
        form.validateFields()
            .then(() => {
                if (!loginDate) {
                    notification['error']({
                        message: 'Select Login Date!',
                    })
                    return
                }

                if (!loginTime) {
                    notification['error']({
                        message: 'Select Login Time!',
                    })
                    return
                }

                setLoading(true)

                let payload = {}
                payload.userId = selectedRecord.user.id
                payload.loginDate = loginDate
                payload.loginTime = loginTime
                payload.logoutTime = logoutTime ? logoutTime : ''

                let formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.IL.ADD_LOGIN_DATA, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddLoginModal()
                        form.resetFields()
                        getEmployeeLoginData()
                        setLoading(false)
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {})
    }

    return (
        <>
            <Modal
                title={
                    'Logins - ' +
                    selectedRecord.user.firstName +
                    ' ' +
                    selectedRecord.user.lastName
                }
                open={true}
                onCancel={() => props.hideLoginsModal()}
                destroyOnClose={true}
                footer={null}
                width={filter === MONTH_VIEW ? 1000 : 560}
            >
                {selectedRecord.user ? (
                    <>
                        <Box className={classes.tabView}>
                            <Radio.Group
                                onChange={onChangeTabView}
                                value={filter}
                            >
                                <Radio.Button
                                    value={MONTH_VIEW}
                                    key={MONTH_VIEW}
                                >
                                    {'MonthView'}
                                </Radio.Button>

                                <Radio.Button
                                    value={DATE_RANGE}
                                    key={DATE_RANGE}
                                >
                                    {'Date Range'}
                                </Radio.Button>
                            </Radio.Group>

                            <Button
                                type="default"
                                style={{ marginLeft: 8 }}
                                onClick={showAddLoginModal}
                            >
                                Add Entry
                            </Button>
                        </Box>

                        {filter === MONTH_VIEW ? (
                            <Box className={classes.wrapperContainer}>
                                <>
                                    <Box className={classes.wrapper}>
                                        <ILCalendarView
                                            getDay={getDay}
                                            currentMonth={activeMonth}
                                            currentYear={activeYear}
                                            viewingMonthDays={viewingMonthDays}
                                            viewingDate={viewingDate}
                                            onClickDay={onClickDay}
                                            showIndicators={true}
                                            showArrows={true}
                                            onClickNext={handleNext}
                                            onClickPrevious={handlePrevious}
                                            customStyles={customStyles}
                                            id={userCalendarId}
                                            Indicators={IndicatorsCalendar}
                                            onClickDate={handleDateClick}
                                            customStylesBackground={dayStyling}
                                            onSelectMonth={onSelectMonth}
                                            handleDateClose={handleDateClose}
                                            anchorEl={anchorEl}
                                        >
                                            <ILEmployeeDayHours
                                                employeeLoginData={
                                                    employeeLoginData || {}
                                                }
                                                loading={dataLoading}
                                                getEmployeeLoginData={
                                                    getEmployeeLoginData
                                                }
                                                activeDate={activeDate}
                                                setViewingDate={(day) =>
                                                    setViewingDate(day)
                                                }
                                            />
                                        </ILCalendarView>
                                    </Box>
                                </>
                            </Box>
                        ) : (
                            <>
                                <RangePicker
                                    format="DD-MMM-YYYY"
                                    placeholder={['Start Date', 'End Date']}
                                    onChange={onDateChange}
                                    value={
                                        dateRangeStartDate && dateRangeEndDate
                                            ? [
                                                  dayjs(dateRangeStartDate),
                                                  dayjs(dateRangeEndDate),
                                              ]
                                            : null
                                    }
                                    className={classes.datePicker}
                                />

                                <Grid className={classes.wrapperStyles}>
                                    <ILEmployeeDayHours
                                        employeeLoginData={
                                            employeeLoginData || {}
                                        }
                                        loading={dataLoading}
                                        getEmployeeLoginData={
                                            getEmployeeLoginData
                                        }
                                        activeDate={activeDate}
                                        setViewingDate={(day) =>
                                            setViewingDate(day)
                                        }
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                ) : null}
            </Modal>

            <Modal
                title={'Add Login Data'}
                open={addLoginModalVisible}
                onOk={addLoginData}
                confirmLoading={loading}
                onCancel={hideAddLoginModal}
                okText={'Add'}
                destroyOnClose={true}
                width={600}
            >
                {addLoginModalVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Login Date"
                            style={{ marginBottom: 10 }}
                            name="loginDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD-MMM-YYYY"
                                onChange={onChangeLoginDate}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Login Time"
                            style={{ marginBottom: 10 }}
                            name="loginTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeLoginTime}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Logout Time"
                            style={{ marginBottom: 10 }}
                            name="logoutTime"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeLogoutTime}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </>
    )
}

export default ILEmployeeCalendarView

const useStyles = makeStyles()((theme) => ({
    wrapperContainer: {
        display: 'flex',
        flexFlow: 'row',
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
        },
    },
    wrapper: {
        flex: 1,
        [theme.breakpoints.down('md')]: {
            padding: '0px 0px',
        },
    },
    loginContainer: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        background: '#fff',
        boxShadow:
            'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
    },
    dayDotStyle: {
        bottom: 2,
    },
    datePresent: {
        background: '#66d864',
        color: 'white',
    },
    calendarContainer: {
        top: '0px !important',
    },
    wrapperStyles: {
        height: '70vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            minHeight: '75vh',
            overflowY: 'unset',
        },
    },
    dotsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '4px',
        position: 'absolute',
        bottom: -2,
        [theme.breakpoints.down('md')]: {
            bottom: -7.5,
        },
    },
    dot: {
        width: '6px',
        height: '6px',
        borderRadius: '50%',
    },
    greenDot: {
        background: '#0ab77d',
    },
    holidayDot: {
        background: '#cd7722',
    },
    leaveDot: {
        background: '#3598db',
    },
    dateHoliday: {
        background: '#cd7722',
        color: '#fff',
    },
    dateLeave: {
        background: '#3598db',
        color: '#fff',
    },
    tabView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10px',
        marginTop: '20px',
        marginLeft: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
        },
    },
    datePicker: {
        marginLeft: 20,
        width: 250,
        marginBottom: 5,
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
        },
    },
    dateMiss: {
        background: 'red',
        color: '#fff',
    },
}))
