import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import { ChevronLeft, ChevronRight, East, West } from '@mui/icons-material'
import { ILCalendarViewConstants } from './SubComponents/CalendarViewConstants'
import { makeStyles } from 'tss-react/mui'
import SelectYearMonthModal from './SubComponents/SelectYearMonthModal'

const ILCalendarView = ({
    getDay,
    currentMonth,
    currentYear,
    viewingMonthDays,
    viewingDate,
    onClickDay,
    showArrows = false,
    onClickNext,
    onClickPrevious,
    customStyles,
    Indicators,
    dashBoardView,
    onClickDate,
    children,
    id,
    customStylesBackground,
    onSelectMonth,
    anchorEl,
    handleDateClose,
}) => {
    const { classes, cx } = useStyles()
    let styles = {}

    if (customStyles) {
        styles = customStyles()
    }
    let dayIndex = getDay(currentMonth, currentYear, 1)

    return (
        <Grid className={cx(classes.wrapper, styles.wrapperStyles)} id={id}>
            <Box
                className={cx(
                    classes.calendarTopContainer,
                    styles.calendarTopContainerStyles
                )}
            >
                <Grid
                    className={cx(
                        classes.calendarContainer,
                        styles.calendarContainer
                    )}
                >
                    {showArrows ? (
                        <Box className={classes.headingContainer}>
                            {dashBoardView ? (
                                <ChevronLeft
                                    fontSize="small"
                                    onClick={onClickPrevious}
                                    className={classes.smallArrows}
                                />
                            ) : (
                                <IconButton
                                    onClick={onClickPrevious}
                                    size="small"
                                >
                                    <West fontSize="small" />
                                </IconButton>
                            )}
                            <div>
                                <Typography
                                    className={classes.monthHeading}
                                    onClick={onClickDate ? onClickDate : null}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {
                                        ILCalendarViewConstants.months[
                                            currentMonth
                                        ]
                                    }
                                    , {currentYear}
                                </Typography>
                                {anchorEl && (
                                    <SelectYearMonthModal
                                        anchorEl={anchorEl}
                                        handleDateClose={handleDateClose}
                                        selectedYear={currentYear}
                                        selectedMonth={currentMonth}
                                        onClickSubmit={onSelectMonth}
                                    />
                                )}
                            </div>
                            {dashBoardView ? (
                                <ChevronRight
                                    fontSize="small"
                                    onClick={onClickNext}
                                    className={classes.smallArrows}
                                />
                            ) : (
                                <IconButton onClick={onClickNext} size="small">
                                    <East fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    ) : (
                        <Typography className={classes.monthHeading}>
                            {ILCalendarViewConstants.months[currentMonth]},{' '}
                            {currentYear}
                        </Typography>
                    )}
                    <Grid
                        className={cx(
                            classes.calendarGrid,
                            classes.backgroundColor,
                            styles.gridStyles
                        )}
                    >
                        {ILCalendarViewConstants.days.map((item, index) => (
                            <Grid
                                className={cx(
                                    classes.calendatTextContainer,
                                    classes.dayContainer,
                                    styles.textStyles
                                )}
                                key={index}
                            >
                                {index >= 0 &&
                                index !==
                                    ILCalendarViewConstants.days.length - 1
                                    ? dashBoardView
                                        ? ILCalendarViewConstants.days[
                                              index + 1
                                          ].charAt(0)
                                        : ILCalendarViewConstants.days[
                                              index + 1
                                          ]
                                    : dashBoardView
                                    ? ILCalendarViewConstants.days[0].charAt(0)
                                    : ILCalendarViewConstants.days[0]}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        className={cx(classes.calendarGrid, styles.gridStyles)}
                    >
                        {[...Array(dayIndex === 0 ? 6 : dayIndex - 1)].map(
                            (item, index) => (
                                <Grid
                                    className={cx(
                                        classes.calendatTextContainer,
                                        styles.textStyles
                                    )}
                                    key={index}
                                >
                                    <Typography></Typography>
                                </Grid>
                            )
                        )}
                        {[...Array(viewingMonthDays)].map((item, index) => {
                            return (
                                <Grid
                                    key={index}
                                    className={cx(
                                        classes.calendatTextContainer,
                                        styles.textStyles
                                    )}
                                >
                                    <Grid
                                        onClick={() => {
                                            onClickDay(index + 1)
                                        }}
                                        className={cx(
                                            classes.dateDataWrapper,
                                            styles.dayStyles
                                        )}
                                    >
                                        <Typography
                                            className={cx(
                                                classes.dateText,
                                                {
                                                    [classes.selectedDate]:
                                                        viewingDate ===
                                                        index + 1,
                                                    [classes.selectedDateSmall]:
                                                        viewingDate ===
                                                            index + 1 &&
                                                        dashBoardView,
                                                },
                                                styles.dateStyles,
                                                customStylesBackground &&
                                                    customStylesBackground(
                                                        index + 1
                                                    )
                                            )}
                                        >
                                            {index + 1}
                                        </Typography>
                                        {Indicators && (
                                            <Indicators day={index + 1} />
                                        )}
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Box>
            {children}
        </Grid>
    )
}

export default ILCalendarView

const useStyles = makeStyles()((theme) => ({
    wrapper: {
        display: 'flex',
        flexFlow: 'row',
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
        },
    },
    dayViewContainer: {
        borderBottom: 'none',
    },
    monthHeading: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 'normal',
        color: '#2a4d5d',
        padding: '7px 10px',
    },
    bar: {
        width: '20px',
        height: '2px',
        borderRadius: '4px',
        background: 'gray',
    },
    calendarGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(7,65px)',
        [theme.breakpoints.down('lg')]: {
            gridTemplateColumns: 'repeat(7,50px)',
        },
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(7,1fr)',
        },
    },
    dateText: {
        borderRadius: '50%',
        width: 35,
        height: 35,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: 32,
            height: 32,
        },
        '&:hover': {
            border: '0.8px solid #2a4d5d',
        },
    },
    calendatTextContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
        fontSize: 18,
        cursor: 'pointer',
        height: 60,
        [theme.breakpoints.down('md')]: {
            height: 40,
        },
    },
    calendarTopContainer: {
        position: 'sticky',
        top: '55px',
        background: '#fff',
        zIndex: 2,
        [theme.breakpoints.up('sm')]: {
            top: '0px !important',
        },
        borderRight: '0.8px solid #e0e0e0',
        [theme.breakpoints.down('md')]: {
            borderBottom: '0.6px solid #e0e0e0',
        },
    },
    arrowContainer: {
        position: 'absolute',
        bottom: -12,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '5px 24px',
        background: '#fff',
        borderRadius: '0px 0px 10px 10px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    },
    dayCalendarContainer: {
        width: '100vw',
        overflowX: 'hidden',
    },
    dateDataWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        borderRadius: 7,
        position: 'relative',
        background: 'none',
        border: 'none',
    },
    selectedDate: {
        background: '#2a4d5d !important',

        color: 'white',

        fontWeight: 'bold',
    },
    selectedDateSmall: {
        width: '24px',
        height: '24px',
    },
    backgroundColor: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },

    calendarContainer: {
        width: 'fit-content',
        marginRight: 20,
        height: 'fit-content',
        position: 'sticky',
        [theme.breakpoints.down('md')]: {
            position: 'unset',
            width: 'unset',
            marginRight: 0,
            top: 'unset',
            padding: '0px 20px',
        },
    },
    customDayViewData: {
        marginTop: 30,
        borderLeft: '1px solid #e0e0e0',
        width: '100%',
        boxShadow: 'none',
        [theme.breakpoints.down('lg')]: {
            borderLeft: 'none',
            marginTop: 20,
        },
    },
    dayContainer: {
        fontWeight: 'bold',
        color: '#2a4d5d',
        height: 40,
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            fontWeight: 500,
            fontSize: '14px',
        },
    },
    headingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 10px 0px',
    },
    smallArrows: {
        cursor: 'pointer',
    },
}))
