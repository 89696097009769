import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Popconfirm, Modal, Input, notification, Form } from 'antd'

function Webinar(props) {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [downloadMessage, setDownloadMessage] = useState()
    const [cfaPageDataVisible, setCfaPageDataVisible] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            if (cfaPageDataVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [cfaPageDataVisible, inputRef])

    const downloadLeads = () => {
        setLoading(true)

        var formData = new FormData()
        formData.append('payload', JSON.stringify({}))

        RestApi.doPost(GLOBAL.URL.WEBINAR.DOWNLOAD_WEBINAR_LEADS, formData)
            .then((response) => {
                setDownloadMessage(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showCfaPageData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.WEBINAR.GET_CFA_PAGE_DATA)
            .then((res) => {
                setCfaPageDataVisible(true)

                if (res.data) {
                    var data = JSON.parse(res.data)
                    setTimeout(() => {
                        form.setFieldsValue({
                            date: data.date,
                            time: data.time,
                            seoImage: data.seoImage,
                        })
                    }, 100)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideCfaPageData = () => {
        setCfaPageDataVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const updateCfaPageData = () => {
        form.validateFields().then((values) => {
            setLoading(true)

            var formData = new FormData()
            formData.append('payload', JSON.stringify(values))

            RestApi.doPost(GLOBAL.URL.WEBINAR.UPDATE_CFA_PAGE_DATA, formData)
                .then((response) => {
                    notification['success']({
                        message: response.data,
                    })

                    hideCfaPageData()
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    return (
        <div>
            <h2>Webinar</h2>
            <div style={{ marginTop: 10 }}>
                {downloadMessage ? (
                    <span style={{ color: 'blue' }}>{downloadMessage}</span>
                ) : (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={downloadLeads}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" loading={loading}>
                            Download Leads
                        </Button>
                    </Popconfirm>
                )}
            </div>
            <div style={{ marginTop: 20 }}>
                <Button
                    type="primary"
                    loading={loading}
                    onClick={showCfaPageData}
                >
                    Update CFA Webinar Page Data
                </Button>
            </div>
            <Modal
                title={'CFA Webinar Page Data'}
                open={cfaPageDataVisible}
                onOk={updateCfaPageData}
                confirmLoading={loading}
                onCancel={hideCfaPageData}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Time"
                        name="time"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="SEO Image"
                        name="seoImage"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Webinar
