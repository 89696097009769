import GLOBALS from '../src/Globals'
import axios from 'axios'
import { message } from 'antd'
import store from './store/store'
import { actionTypes } from './actions'

export const signOut = async () => {
    localStorage.removeItem('token')
    store.dispatch({
        type: actionTypes.SET_SHOW_LOG_IN_MODAL,
        showLoginModal: true,
    })
}

const APIHandler = axios.create({
    baseURL: GLOBALS.URL.BASE_URL,
})

APIHandler.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (
            error &&
            error.response &&
            (error.response.status === 401 || error.response.status === 402)
        ) {
            signOut()
        }
        return Promise.reject(error)
    }
)

const createHeaders = () => ({
    headers: {
        [GLOBALS.HEADER.AUTH]: localStorage.getItem('token'),
    },
})

export const RestApi = {
    doPost: function (URL, data, responseType, showErrorMessage = true) {
        const headerFinal = createHeaders()
        if (responseType) {
            headerFinal.responseType = responseType
        }
        return new Promise((resolve, reject) => {
            APIHandler.post(URL, data, headerFinal)
                .then((response) => {
                    if (
                        response.data.code === 400 ||
                        response.data.code === 401 ||
                        response.data.code === 500
                    ) {
                        if (showErrorMessage) {
                            message.destroy()
                            message.error(response.data.data)
                        }

                        reject(response.data)
                    } else if (response.data.code === 402) {
                        signOut()
                    } else {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.status &&
                        (error.response.status === 401 ||
                            error.response.status === 402)
                    ) {
                        signOut()
                    } else {
                        reject('API call failed')
                    }
                })
        })
    },

    doGet: function (URL) {
        const headerFinal = createHeaders()

        return new Promise((resolve, reject) => {
            APIHandler.get(URL, headerFinal)
                .then((response) => {
                    if (
                        response.data.code === 400 ||
                        response.data.code === 401 ||
                        response.data.code === 500
                    ) {
                        message.destroy()
                        message.error(response.data.data)
                        reject(response.data)
                    } else if (response.data.code === 402) {
                        signOut()
                    } else {
                        resolve(response.data)
                    }
                })
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.status &&
                        (error.response.status === 401 ||
                            error.response.status === 402)
                    ) {
                        signOut()
                    } else {
                        reject('API call failed')
                    }
                })
        })
    },

    download: function (URL) {
        const headerFinal = {
            ...createHeaders(),
            responseType: 'blob',
        }

        return new Promise((resolve, reject) => {
            APIHandler.get(URL, headerFinal)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (
                        error.response &&
                        error.response.status &&
                        (error.response.status === 401 ||
                            error.response.status === 402)
                    ) {
                        signOut()
                    } else {
                        reject('API call failed')
                    }
                })
        })
    },
}
