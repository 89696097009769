import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    TableCell,
    TableContainer,
    TableBody,
    TableHead,
    TableRow,
    Grid,
    Dialog,
    Button,
    Typography,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { goBack } from '../../Util/ILUtil'

function FacultyCoursesSummary(props) {
    const { classes, cx } = useStyles()

    const [loading, setLoading] = useState(false)
    const [courseTaughtData, setCourseTaughtData] = useState({ courses: [] })
    const [facultyId, setFacultyId] = useState()
    const [studentListPageNumber, setStudentListPageNumber] = useState(1)
    const [studentList, setStudentList] = useState([])
    const [studentListDetails, setStudentListDetails] = useState({
        courseId: '',
        attempt: '',
        courseName: '',
    })
    const [isMoreButtonVisible, setIsMoreButtonVisible] = useState(true)
    const { facultyIdInUrl } = useParams()

    useEffect(() => {
        setFacultyId(facultyIdInUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!facultyId) {
            return
        }

        getCoursesSummary()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facultyId])

    const getCoursesSummary = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.FACULTY.GET_COURSES_SUMMARY + facultyId)
            .then((res) => {
                setCourseTaughtData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getStudentsList = (courseId, attempt, page) => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.FACULTY.GET_STUDENTLIST +
                '?courseId=' +
                courseId +
                '&attempt=' +
                attempt +
                '&page=' +
                page
        )
            .then((response) => {
                setStudentList([...studentList, ...response.data])
                setStudentListPageNumber(page)
                if (response.data.length === 50) {
                    setIsMoreButtonVisible(true)
                } else {
                    setIsMoreButtonVisible(false)
                }
            })
            .finally(() => setLoading(false))
    }

    const closeActiveStudentListModal = () => {
        setStudentList([])
        setStudentListPageNumber(1)
        setIsMoreButtonVisible(true)
    }

    const SummaryTable = () => {
        return (
            <Grid item md={6} xs={12} className={classes.summaryTableContainer}>
                <TableContainer>
                    <Table
                        className={cx(
                            classes.tableBorder,
                            classes.tableWidthAuto
                        )}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="center">
                                    Total Students
                                </TableCell>
                                <TableCell align="center">Active</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.boldTableHead}>
                                    Full Course
                                </TableCell>
                                <TableCell align="center">
                                    {courseTaughtData.totalFullCourseStudents}
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        courseTaughtData.totalFullCourseActiveStudents
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.boldTableHead}>
                                    Individual Modules
                                </TableCell>
                                <TableCell align="center">
                                    {courseTaughtData.totalIndModulesStudents}
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        courseTaughtData.totalIndModulesActiveStudents
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.boldTableHead}>
                                    Total
                                </TableCell>
                                <TableCell align="center">
                                    {courseTaughtData.totalStudents}
                                </TableCell>
                                <TableCell align="center">
                                    {courseTaughtData.activeStudents}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        )
    }

    const CourseTaughtTable = () => {
        return (
            <TableContainer>
                <Table
                    className={cx(
                        classes.tableBorder,
                        classes.courseTaughtTable,
                        classes.tableWidthAuto
                    )}
                >
                    <TableHead className={classes.headerBackground}>
                        <TableRow>
                            <TableCell align="center">SN</TableCell>
                            <TableCell>Courses</TableCell>
                            <TableCell align="center">Total Students</TableCell>
                            <TableCell align="center">
                                Total Active Students
                            </TableCell>
                            <TableCell align="center">Attempt</TableCell>
                            <TableCell align="center">
                                Active Students
                            </TableCell>
                            <TableCell align="center">Full Course</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {courseTaughtData.courses.map((item, index) => (
                            <TableRow
                                key={index}
                                className={cx({
                                    [classes.tableRowStyling]: index % 2 !== 0,
                                })}
                            >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item.courseName}</TableCell>
                                <TableCell align="center">
                                    {item.totalStudents}
                                </TableCell>
                                <TableCell align="center">
                                    {item.activeStudents}
                                </TableCell>
                                <TableCell
                                    style={{ padding: 0 }}
                                    align="center"
                                >
                                    <AttemptData
                                        attemptData={item.attemtpSplitList}
                                        courseId={item.courseId}
                                        courseName={item.courseName}
                                    />
                                </TableCell>
                                <TableCell
                                    style={{ padding: 0 }}
                                    align="center"
                                >
                                    <ActiveStudents
                                        attemptData={item.attemtpSplitList}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {item.indModule ? 'No' : 'Yes'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const AttemptData = ({ attemptData, courseId, courseName }) => {
        return (
            <Table>
                <TableBody>
                    {attemptData.length > 0 ? (
                        attemptData.map((list, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    align="center"
                                    onClick={() =>
                                        onAttemptClick(
                                            courseId,
                                            list.attempt,
                                            courseName
                                        )
                                    }
                                    className={cx(classes.attemptCell, {
                                        [classes.hideBorder]:
                                            index + 1 === attemptData.length,
                                    })}
                                >
                                    {list.attempt}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                align="center"
                                className={classes.hideBorder}
                            >
                                {'-'}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        )
    }

    const ActiveStudents = ({ attemptData }) => {
        return (
            <Table>
                <TableBody>
                    {attemptData.length > 0 ? (
                        attemptData.map((list, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    align="center"
                                    className={cx(classes.activeStudentsCell, {
                                        [classes.hideBorder]:
                                            index + 1 === attemptData.length,
                                    })}
                                >
                                    {list.activeStudents}
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell
                                align="center"
                                className={classes.hideBorder}
                            >
                                {'-'}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        )
    }

    const onAttemptClick = (courseId, attempt, courseName) => {
        setStudentList([])
        setStudentListDetails({
            courseId: courseId,
            attempt: attempt,
            courseName: courseName,
        })
        getStudentsList(courseId, attempt, studentListPageNumber)
    }

    return (
        <div>
            <Button
                variant="outlined"
                size="small"
                // color="primary"
                onClick={() => goBack(props.navigate)}
            >
                {'<'} Back
            </Button>
            <h2>
                {'Faculty Courses Summary' +
                    (courseTaughtData.facultyName
                        ? ' - ' + courseTaughtData.facultyName
                        : '')}
                {loading ? (
                    <Button type="link">
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    </Button>
                ) : null}
            </h2>
            <SummaryTable />
            <CourseTaughtTable />
            <ActiveStudentsListDialog
                studentList={studentList}
                title={studentListDetails.courseName}
                attempt={studentListDetails.attempt}
                onMoreClick={() => {
                    getStudentsList(
                        studentListDetails.courseId,
                        studentListDetails.attempt,
                        studentListPageNumber + 1
                    )
                }}
                isMoreButtonopen={isMoreButtonVisible}
                onClose={() => closeActiveStudentListModal()}
                loading={loading}
            />
        </div>
    )
}

export const ActiveStudentsListDialog = ({
    title,
    attempt,
    studentList,
    onMoreClick,
    isMoreButtonVisible,
    onClose,
    loading,
}) => {
    const { classes } = useStyles()
    return (
        <Dialog open={studentList.length > 0} onClose={onClose}>
            <Grid style={{ padding: 20 }}>
                <Typography variant={'h6'}>{title}</Typography>
                <Typography variant={'h7'}>{'(' + attempt + ')'}</Typography>
                <br />
                <br />
                <Table className={classes.tableBorder}>
                    <TableHead>
                        <TableRow>
                            <TableCell>SN</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Enrolled Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {studentList.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    {item.firstName} {item.lastName}
                                </TableCell>
                                <TableCell>{item.enrolledAt}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {isMoreButtonVisible ? (
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.moreButton}
                        onClick={onMoreClick}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress
                                size={20}
                                style={{ marginRight: 10 }}
                            />
                        ) : null}
                        More
                    </Button>
                ) : null}
            </Grid>
        </Dialog>
    )
}

export default FacultyCoursesSummary

const useStyles = makeStyles()((theme) => ({
    summaryTableContainer: {
        marginTop: 25,
        marginBottom: 25,
        '& .MuiTableCell-root': {
            padding: 10,
        },
    },
    tableWidthAuto: {
        width: 'auto',
    },
    tableBorder: {
        border: '1px solid rgba(224, 224, 224, 1)',
        '& .MuiTableCell-head': {
            fontWeight: 'bold',
            background: '#f1f8fe',
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiTableCell-root': {
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    attemptCell: {
        borderLeft: 'none ! important',
        color: '-webkit-link',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        padding: 5,
    },
    hideBorder: {
        borderBottom: 'none',
        borderLeft: 'none!important',
    },
    activeStudentsCell: {
        padding: 5,
        borderLeft: 'none ! important',
    },
    boldTableHead: {
        fontWeight: 'bold',
    },

    tableRowStyling: {
        background: 'rgba(0, 0, 0, 0.08)',
    },
    courseTaughtTable: {
        marginBottom: 25,
    },
    moreButton: {
        marginTop: 10,
        textTransform: 'none',
    },
    boldText: {
        fontWeight: 'bold',
    },
}))
