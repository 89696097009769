import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Popconfirm,
    notification,
    Select,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'

function BlogSuper(props) {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        getSuperBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSuperBlogs = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_SUPER_BLOGS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 90,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisable(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Blog Title',
            dataIndex: 'blogTitle',
            key: 'blogTitle',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteSuper(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.BLOG.ENABLE_SUPER_BLOG
        if (record.enabled) {
            url = GLOBAL.URL.BLOG.DISABLE_SUPER_BLOG
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = clone(data)
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteSuper = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_SUPER_BLOG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getSuperBlogs()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const addSuperBlog = () => {
        form.validateFields(['blogId'])
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BLOG.ADD_SUPER_BLOG, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddModal()
                        getSuperBlogs()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showAddModal = () => {
        setAddModalVisible(true)

        if (blogs.length === 0) {
            setLoading(true)

            RestApi.doGet(GLOBAL.URL.BLOG.GET_PAGES_LIST)
                .then((res) => {
                    setBlogs(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const hideAddModal = () => {
        setAddModalVisible(false)
    }

    const getBlogOptions = () => {
        var options = []
        blogs.forEach((blog) => {
            options.push(
                <Option value={blog.blogId} key={blog.title}>
                    {blog.title}
                </Option>
            )
        })

        return options
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Blog Title',
                dataIndex: 'blogTitle',
                key: 'blogTitle',
            },
        ]

        props.navigate('/reorder', {
            state: {
                reorderData: data,
                updateUrl: GLOBAL.URL.BLOG.REORDER_SUPER_BLOG,
                columns: columns,
            },
        })
    }

    return (
        <div>
            <h2>Super Blog</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddModal}>
                    Add
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={reorder}
                    style={{ marginLeft: 10 }}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="key"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Add Super Blog'}
                open={addModalVisible}
                onOk={addSuperBlog}
                confirmLoading={loading}
                onCancel={hideAddModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                {addModalVisible ? (
                    <Form form={form} layout="horizontal">
                        <Form.Item
                            label="Select Blog"
                            name="blogId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Blog"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: '100%' }}
                            >
                                {getBlogOptions()}
                            </Select>
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default BlogSuper
