import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
    message,
    Table,
    Row,
    Button,
    Col,
    Modal,
    Input,
    Divider,
    Popconfirm,
    Breadcrumb,
    Form,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Link } from '@mui/material'

const Topics = (props) => {
    const [topicData, setTopicData] = useState([])
    const [addEditTopicModalVisible, setAddEditTopicModalVisible] =
        useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [editTopicRecord, setEditTopicRecord] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getTopicsData()
        //eslint-disable-next-line
    }, [])

    const getTopicsData = () => {
        const { subjectName, chapterName, sectionName } = props.location.state
        RestApi.doGet(
            GLOBAL.URL.GET_TOPICS +
                '?subject=' +
                encodeURIComponent(subjectName) +
                '&chapter=' +
                encodeURIComponent(chapterName) +
                '&section=' +
                encodeURIComponent(sectionName)
        )
            .then((res) => {
                setTopicData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
    }

    const openAddEditTopicModal = (record) => {
        form.setFieldsValue(record)
        setAddEditTopicModalVisible(true)
        setEditTopicRecord(record)
    }

    const closeAddEditTopicModal = () => {
        form.resetFields()
        setAddEditTopicModalVisible(false)
        setEditTopicRecord(null)
    }

    const handleSubmit = (editRecord) => {
        const { subjectName, chapterName, sectionName } = props.location.state
        form.validateFields()
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_TOPIC
                values['subject'] = subjectName
                values['chapter'] = chapterName
                values['section'] = sectionName
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_TOPIC
                    values['id'] = editRecord.id
                }
                let formbodyTopic = new FormData()
                formbodyTopic.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyTopic)
                    .then((response) => {
                        var topicJsonArrayObject = []
                        topicJsonArrayObject.push(response.data)
                        if (editRecord) {
                            topicData.forEach((item) => {
                                if (item.id === editRecord.id) {
                                    item.name = values.name
                                }
                            })
                        } else {
                            setTopicData([
                                ...topicData,
                                ...topicJsonArrayObject,
                            ])
                        }
                        closeAddEditTopicModal()
                        message.success(
                            editRecord
                                ? 'Topic Edited Successfully'
                                : 'Topic Added Successfully'
                        )
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_TOPIC, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = topicData
                    setTopicData(successObject.filter((key) => key.id !== id))
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }

    const { subjectName, chapterName, sectionName } = props.location.state
    let columns = []
    if (Object.keys(topicData).length > 0) {
        columns = [
            {
                title: 'Action',
                key: 'action',
                width: '200px',
                render: (text, record) => (
                    <span>
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            onClick={() => {
                                openAddEditTopicModal(record)
                            }}
                        />
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure want to delete ?"
                            onConfirm={() => {
                                deleteItem(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<DeleteOutlined />}
                                style={{
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                }}
                            />
                        </Popconfirm>
                    </span>
                ),
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]
    }

    return (
        <div>
            <Col>
                <Row>
                    <Row style={{ marginBottom: 8 }}>
                        <Col span={18}>
                            <h2>Topics</h2>
                        </Col>

                        <Col span={6} style={{ textAlign: 'right' }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    openAddEditTopicModal()
                                }}
                            >
                                Add Topic
                            </Button>
                        </Col>
                    </Row>
                </Row>
                <Row style={{ marginBottom: 8 }}>
                    <Col span={18}>
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link
                                    href={'/mcq/chapters'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        props.navigate('/mcq/chapters', {
                                            state: {
                                                subjectName: subjectName,
                                            },
                                        })
                                    }}
                                    underline="none"
                                >
                                    <Button type="link">{subjectName}</Button>
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link
                                    href={'/mcq/sections'}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        props.navigate('/mcq/sections', {
                                            state: {
                                                subjectName: subjectName,
                                                chapterName: chapterName,
                                            },
                                        })
                                    }}
                                    underline="none"
                                >
                                    <Button type="link">{chapterName}</Button>
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Button type="link">{sectionName}</Button>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
            </Col>
            <Table
                columns={columns}
                dataSource={topicData}
                pagination={false}
            />
            <Modal
                title={editTopicRecord ? 'Edit Topic' : 'Add Topic'}
                open={addEditTopicModalVisible}
                onOk={() => {
                    handleSubmit(editTopicRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={closeAddEditTopicModal}
                okText={editTopicRecord ? 'Edit Topic' : 'Add Topic'}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Topic Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Topic Name',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter Topic Name" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Topics
