import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Modal, Input, Popconfirm, notification } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

function ForumGroupUserMapping() {
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const [selectedUser, setSelectedUser] = useState({})
    const [selectUserModalVisible, setSelectUserModalVisible] = useState(false)
    const [searchUsers, setSearchUsers] = useState([])

    const [selectGroupModalVisible, setSelectGroupModalVisible] =
        useState(false)
    const [groups, setGroups] = useState([])
    const [selectedGroup, setSelectedGroup] = useState({})

    const [selectStreamModalVisible, setSelectStreamModalVisible] =
        useState(false)
    const [groupStreams, setGroupStreams] = useState([])

    const [openTopicsModalVisible, setOpenTopicsModalVisible] = useState(false)
    const [openTopicsHtml, setOpenTopicsHtml] = useState()
    const inputRef = useRef()

    useEffect(() => {
        getMappings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (selectUserModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [selectUserModalVisible, inputRef])

    const getMappings = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUP_USER_MAPPINGS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showSelectUserModal = () => {
        setSelectedUser({})
        setSelectUserModalVisible(true)
    }

    const hideSelectUserModal = () => {
        setSelectUserModalVisible(false)
    }

    const userColumns = [
        {
            title: 'Select',
            dataIndex: 'select',
            key: 'select',
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => onSelectUser(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => (
                <span>
                    {record.phone ? record.isd + '-' + record.phone : ''}
                </span>
            ),
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const searchUser = (query) => {
        setLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUsers(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onSelectUser = (user) => {
        showSelectGroupModal(user)
        hideSelectUserModal()
    }

    const showSelectGroupModal = (user) => {
        setSelectedUser(user)
        setSelectGroupModalVisible(true)

        if (groups.length === 0) {
            setLoading(true)
            RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUPS_ADMIN)
                .then((res) => {
                    setGroups(res.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const hideSelectGroupModal = () => {
        setSelectGroupModalVisible(false)
    }

    const groupTableColumns = [
        {
            title: 'Select',
            dataIndex: 'select',
            key: 'select',
            width: 100,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => showStreamModal(record)}
                    loading={loading}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    const showStreamModal = (group) => {
        setLoading(true)
        setSelectedGroup(group)
        setSelectStreamModalVisible(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUP_STREAM_MAPPINGS + group.id)
            .then((res) => {
                setGroupStreams(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideStreamModal = () => {
        setSelectStreamModalVisible(false)
    }

    const streamMappingTableColumns = [
        {
            title: 'Select',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => addMapping(record)}
                    loading={loading}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>{record.stream.course + ' ' + record.stream.level}</span>
            ),
        },
    ]

    const addMapping = (streamMapping) => {
        setLoading(true)

        var payload = {}
        payload.userId = selectedUser.id
        payload.forumGroupStreamMappingId = streamMapping.id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_FORUM_GROUP_USER_MAPPING, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getMappings()
                hideStreamModal()
                hideSelectGroupModal()

                setSelectedUser({})
                setSelectedGroup({})
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const mappingColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <>
                    <h4>
                        {record.user.firstName + ' ' + record.user.lastName}
                    </h4>
                    <Button
                        type="default"
                        size="small"
                        onClick={() => showSelectGroupModal(record.user)}
                        style={{ display: 'block' }}
                    >
                        Add Mapping
                    </Button>
                </>
            ),
        },
        {
            title: 'Group Mappings',
            dataIndex: 'mappings',
            key: 'mappings',
            render: (text, record) => (
                <Table
                    columns={userGroupMappingColumns}
                    dataSource={record.groupMappings}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="name"
                    style={{ marginTop: 10 }}
                />
            ),
        },
        {
            title: 'Open Topics',
            dataIndex: 'openTopics',
            key: 'openTopics',
            render: (text, record) => (
                <Button
                    type="default"
                    size="small"
                    onClick={() => showOpenTopics(record.user)}
                >
                    Get Open Topics
                </Button>
            ),
        },
    ]

    const userGroupMappingColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'WhatsApp',
            dataIndex: 'newTopicWhatsappNotification',
            key: 'newTopicWhatsappNotification',
            width: 90,
            render: (text, record) => (
                <Button
                    tyle="link"
                    style={{
                        border: 'none',
                        padding: 0,
                        width: '100%',
                    }}
                    onClick={() => enabledDisableWhatsAppNotification(record)}
                >
                    {record.newTopicWhatsappNotification ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                        />
                    ) : (
                        <FontAwesomeIcon icon={faTimes} color="red" />
                    )}
                </Button>
            ),
        },
        {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            render: (text, record) => (
                <span>{record.forumGroupStreamMapping.groupName}</span>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>
                    {record.forumGroupStreamMapping.stream.course +
                        ' ' +
                        record.forumGroupStreamMapping.stream.level}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteMapping(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteMapping = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_FORUM_GROUP_USER_MAPPING, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getMappings()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const enabledDisableWhatsAppNotification = (record) => {
        setLoading(true)

        var payload = {}
        payload.uid = record.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.ENABLE_NEW_TOPIC_WHATSAPP_NOTIFICATION
        if (record.newTopicWhatsappNotification) {
            url = GLOBAL.URL.DISABLE_NEW_TOPIC_WHATSAPP_NOTIFICATION
        }

        RestApi.doPost(url, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getMappings()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showOpenTopics = (user) => {
        setSelectedUser(user)
        setOpenTopicsHtml()
        setOpenTopicsModalVisible(true)
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_FORUM_GROUP_USER_MAPPING_OPEN_TOPICS + user.id
        )
            .then((res) => {
                if (res.data) {
                    setOpenTopicsHtml(res.data)
                } else {
                    setOpenTopicsHtml('<p>Nothing to show</p>')
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const hideOpenTopics = () => {
        setSelectedUser({})
        setOpenTopicsHtml()
        setOpenTopicsModalVisible(false)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    return (
        <div>
            <h2>Forum Group User Mapping</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showSelectUserModal}
                >
                    Add
                </Button>
            </div>
            <Table
                columns={mappingColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="name"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Select User'}
                open={selectUserModalVisible}
                confirmLoading={loading}
                onCancel={hideSelectUserModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    style={{ width: 600 }}
                    autoFocus
                    ref={inputRef}
                />
                <Table
                    columns={userColumns}
                    dataSource={searchUsers}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="email"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Select Group'}
                open={selectGroupModalVisible}
                confirmLoading={loading}
                onCancel={hideSelectGroupModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedUser.firstName + ' ' + selectedUser.lastName}</h3>
                <Table
                    columns={groupTableColumns}
                    dataSource={groups}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="name"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Select Stream'}
                open={selectStreamModalVisible}
                confirmLoading={loading}
                onCancel={hideStreamModal}
                destroyOnClose={true}
                width={700}
                footer={null}
            >
                <h3>{selectedGroup.name}</h3>
                <Table
                    columns={streamMappingTableColumns}
                    dataSource={groupStreams}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Open Topics'}
                open={openTopicsModalVisible}
                confirmLoading={loading}
                onCancel={hideOpenTopics}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h2>{selectedUser.firstName + ' ' + selectedUser.lastName}</h2>
                {loading ? (
                    <Button type="link">
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    </Button>
                ) : (
                    <div
                        dangerouslySetInnerHTML={createMarkupWeb(
                            openTopicsHtml
                        )}
                    />
                )}
            </Modal>
        </div>
    )
}

export default ForumGroupUserMapping
