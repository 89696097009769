import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, notification } from 'antd'

function FacutyCommissionReport() {
    const { MonthPicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState('')
    const [startMonth, setStartMonth] = useState(0)
    const [startYear, setStartYear] = useState(0)
    const [endMonth, setEndMonth] = useState(0)
    const [endYear, setEndYear] = useState(0)

    const onChangeStartDate = (date, dateString) => {
        const dateSplit = dateString.split('-')
        setStartMonth(parseInt(dateSplit[0]))
        setStartYear(parseInt(dateSplit[1]))
    }

    const onChangeEndDate = (date, dateString) => {
        const dateSplit = dateString.split('-')
        setEndMonth(parseInt(dateSplit[0]))
        setEndYear(parseInt(dateSplit[1]))
    }

    const getData = () => {
        if (startMonth === 0) {
            notification['error']({
                message: 'Select Start Date!',
            })

            return
        }

        setLoading(true)

        var payload = {}
        payload.startMonth = startMonth
        payload.startYear = startYear
        payload.endMonth = endMonth
        payload.endYear = endYear

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.FACULTY.GET_FACULTY_COMMISSION_REPORT,
            formData
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Faculty Commission Report</h2>
            <div style={{ marginTop: 10 }}>
                <MonthPicker
                    onChange={onChangeStartDate}
                    picker="month"
                    format="MM-YYYY"
                    placeholder="Start Date"
                />
                <span style={{ marginLeft: 5, marginRight: 5 }}>{'-'}</span>
                <MonthPicker
                    onChange={onChangeEndDate}
                    picker="month"
                    format="MM-YYYY"
                    placeholder="End Date"
                />
                <Button
                    type="primary"
                    onClick={getData}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch
                </Button>
            </div>
            <div
                style={{
                    marginTop: 20,
                    maxWidth: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
                dangerouslySetInnerHTML={{ __html: data }}
            />
        </div>
    )
}

export default FacutyCommissionReport
