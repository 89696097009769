import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function BlogAuthors(props) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [imageModalVisible, setImageModalVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState()
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getAuthors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [addEditModalVisible, inputRef])

    const getAuthors = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.BLOG.GET_AUTHORS)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const showImageModal = (image) => {
        setImageSrc(image)
        setImageModalVisible(true)
    }

    const hideImageModal = () => {
        setImageModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: 75,
            render: (text, record) => (
                <span>
                    {record.image ? (
                        <Button
                            type="link"
                            style={{ padding: 0, margin: 0 }}
                            onClick={() => {
                                showImageModal(record.image)
                            }}
                        >
                            View
                        </Button>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'LinkedIn',
            dataIndex: 'linkedIn',
            key: 'linkedIn',
            width: 75,
            render: (text, record) => (
                <span>
                    {record.linkedIn ? (
                        <a
                            href={record.linkedIn}
                            style={{ display: 'table-cell' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Visit
                        </a>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteAuthor(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateAuthor = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.BLOG.ADD_AUTHOR
                if (selectedRecord.id) {
                    url = GLOBAL.URL.BLOG.UPDATE_AUTHOR
                    values.id = selectedRecord.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getAuthors()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteAuthor = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BLOG.DELETE_AUTHOR, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getAuthors()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Blog Authors</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="key"
                style={{ marginTop: 10 }}
            />
            <Modal
                title="Image"
                open={imageModalVisible}
                onCancel={hideImageModal}
                footer={null}
            >
                <img
                    src={imageSrc}
                    alt={imageSrc}
                    style={{ maxWidth: '100%' }}
                />
            </Modal>
            <Modal
                title={'Configure Author'}
                open={addEditModalVisible}
                onOk={addOrUpdateAuthor}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.id ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Image URL"
                        name="image"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="LinkedIn"
                        name="linkedIn"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default BlogAuthors
