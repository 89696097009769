import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function Config() {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [keys, setKeys] = useState([])
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [form] = Form.useForm()
    const inputRef = useRef()

    useEffect(() => {
        getConfig()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (editModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [editModalVisible, inputRef])

    const getConfig = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_CONFIG)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddModal = () => {
        form.resetFields()
        setAddModalVisible(true)

        if (keys.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CONFIG_KEYS).then((res) => {
                setKeys(res.data)
            })
        }
    }

    const hideAddModal = () => {
        setAddModalVisible(false)
    }

    const showEditNameModal = (record) => {
        setEditModalVisible(true)
        setSelectedRecord(record)
        setTimeout(() => {
            form.setFieldsValue({
                value: record.value,
            })
        }, 100)
    }

    const hideEditNameModal = () => {
        setEditModalVisible(false)
        setSelectedRecord({})
    }

    const addConfig = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.ADD_CONFIG, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddModal()
                        getConfig()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const updateConfig = () => {
        form.validateFields(['value'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.id = selectedRecord.id
                payload.value = values.value

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.UPDATE_CONFIG, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideEditNameModal()
                        getConfig()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteConfig = (record) => {
        var payload = {}
        payload.id = record.id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_CONFIG, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getConfig()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showEditNameModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: 600,
            render: (text, record) => (
                <span
                    style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
                >
                    {record.value}
                </span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteConfig(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const keyOptions = () => {
        var options = []
        keys.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    return (
        <div>
            <h2>App Config</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="key"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Add Config'}
                open={addModalVisible}
                onOk={addConfig}
                confirmLoading={loading}
                onCancel={hideAddModal}
                okText={'Add'}
                destroyOnClose={true}
                width={800}
            >
                {addModalVisible ? (
                    <Form
                        form={form}
                        layout="horizontal"
                        {...formItemLayout}
                        style={{ marginTop: 20 }}
                    >
                        <Form.Item
                            label="Key"
                            name="key"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select key',
                                },
                            ]}
                        >
                            <Select placeholder="Select key" showSearch>
                                {keyOptions()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Value"
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter value',
                                },
                            ]}
                        >
                            <Input onPressEnter={addConfig} />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={'Update Config'}
                open={editModalVisible}
                onOk={updateConfig}
                confirmLoading={loading}
                onCancel={hideEditNameModal}
                okText={'Update'}
                destroyOnClose={true}
                width={800}
            >
                <div>
                    Key: <br />
                    <strong>{selectedRecord.key}</strong>
                </div>
                <div style={{ marginTop: 10 }}>
                    Current value: <br />
                    <strong>{selectedRecord.value}</strong>
                </div>
                {editModalVisible ? (
                    <Form
                        layout="horizontal"
                        {...formItemLayout}
                        style={{ marginTop: 20 }}
                        form={form}
                    >
                        <Form.Item
                            label="New Value"
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter value',
                                },
                            ]}
                        >
                            <Input
                                autoFocus
                                onPressEnter={updateConfig}
                                ref={inputRef}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </div>
    )
}

export default Config
