import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    notification,
    Form,
    Radio,
    Select,
} from 'antd'
import {
    EditOutlined,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import clone from 'clone'
import { Link } from '@mui/material'
import TinyMceEditor from '../Common/TinyMceEditor'
import ILAttachmentFileList from '../ILShareComponents/ILAttachmentFileList'

function Tickets() {
    const { Option } = Select
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [ticketStatusList, setTicketStatusList] = useState([])
    const [selectedStatus, setSelectedStatus] = useState()
    const [assignedToFilter, setAssignedToFilter] = useState()
    const [selectedRecord, setSelectedRecord] = useState({})
    const [addUpdateModalVisible, setAddUpdateModalVisible] = useState(false)
    const [employees, setEmployees] = useState([])
    const [updateStatusModalVisible, setUpdateStatusModalVisible] =
        useState(false)
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [description, setDescription] = useState('')
    const [attachmentFileList, setAttachmentFileList] = useState([])
    const [linkedUser, setLinkedUser] = useState({})
    const [linkUserModalVisible, setLinkUserModal] = useState(false)
    const [searchUserLoading, setSearchUserLoading] = useState(false)
    const [searchUserData, setSearchUserData] = useState([])

    useEffect(() => {
        getTicketStatusList()
        getEmployees()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedStatus) {
            return
        }

        getTickets()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStatus, assignedToFilter])

    const getTicketStatusList = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.TICKETS.GET_STATUS_LIST).then((res) => {
            setTicketStatusList(res.data)
            setSelectedStatus(res.data[0].keyName)
        })
    }

    const getEmployees = () => {
        RestApi.doGet(GLOBAL.URL.IL.GET_EMPLOYEES + 'active').then((res) => {
            setEmployees(res.data)
        })
    }

    const getTickets = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.TICKETS.GET +
                '?status=' +
                selectedStatus +
                '&assignedTo=' +
                (assignedToFilter ? assignedToFilter : '')
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageTickets = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeStatusFilter = (e) => {
        setSelectedStatus(e.target.value)
    }

    const getStatusFilterOptions = () => {
        var options = []

        ticketStatusList.forEach((row) => {
            options.push(
                <Radio.Button value={row.keyName} key={row.keyName}>
                    {row.value}
                </Radio.Button>
            )
        })

        return options
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Button
                    size={'small'}
                    onClick={() => showUpdateStatusModal(record)}
                >
                    {record.status.value}
                </Button>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showAddUpdateModal(record)
                        }}
                        style={{ padding: 0, marginRight: 5 }}
                    >
                        <EditOutlined theme="twoTone" style={{ fontSize: 9 }} />
                    </Button>
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open('/ticket/' + record.uid, '_blank')
                        }}
                        underline="none"
                    >
                        {record.title}
                    </Link>
                </span>
            ),
        },
        {
            title: 'Assigned To',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
            render: (text, record) => (
                <Button
                    type={'link'}
                    style={{ padding: 0 }}
                    onClick={() => showAssignToModal(record)}
                >
                    {record.assignedTo ? (
                        record.assignedTo.firstName
                    ) : (
                        <EditOutlined
                            theme="twoTone"
                            style={{ fontSize: 11 }}
                        />
                    )}
                </Button>
            ),
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <>
                    {record.linkedUser ? (
                        <Link
                            href={'/userdetails/' + record.linkedUser.id}
                            onClick={(event) => {
                                event.preventDefault()
                                window.open(
                                    '/userdetails/' + record.linkedUser.id,
                                    '_blank'
                                )
                            }}
                            underline="none"
                        >
                            {record.linkedUser.firstName +
                                ' ' +
                                record.linkedUser.lastName}
                        </Link>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>
                        {record.createdBy.firstName}
                        <br />
                        {record.createdAt}
                    </span>
                </span>
            ),
        },
    ]

    const showAddUpdateModal = (record = {}) => {
        setSelectedRecord(record)
        setAddUpdateModalVisible(true)

        if (record.uid) {
            setDescription(record.description)
            setTimeout(() => {
                form.setFieldsValue({
                    title: record.title,
                })
            }, 100)
        } else {
            setDescription('')
            form.resetFields()
        }
    }

    const hideAddUpdateModal = () => {
        setAddUpdateModalVisible(false)
        setSelectedRecord({})
        setAttachmentFileList([])
        setLinkedUser({})
    }

    const getEmployeeOptions = () => {
        var options = []
        employees.forEach((employee) => {
            options.push(
                <Option value={employee.user.id} key={employee.user.id}>
                    {employee.user.firstName + ' ' + employee.user.lastName}
                </Option>
            )
        })

        return options
    }

    const onSelectEmployee = (value) => {
        setAssignedToFilter(value)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addUpdateTicket = () => {
        form.validateFields(['title', 'assignedTo'])
            .then((values) => {
                if (!description) {
                    notification['error']({
                        message: 'Description needed',
                    })
                    return
                }

                setLoading(true)

                values.description = description

                if (linkedUser && linkedUser.id) {
                    values.linkedUserId = linkedUser.id
                }

                var url = GLOBAL.URL.TICKETS.ADD
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.TICKETS.UPDATE
                    values.uid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                attachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateModal()
                        getTickets()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showUpdateStatusModal = (record) => {
        setUpdateStatusModalVisible(true)
        setSelectedRecord(record)
        setTimeout(() => {
            form.setFieldsValue({ status: record.status.keyName })
        }, 100)
    }

    const hideUpdateStatusModal = () => {
        setUpdateStatusModalVisible(false)
        setSelectedRecord({})
    }

    const updateStatus = () => {
        form.validateFields(['status'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.TICKETS.UPDATE_STATUS, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateStatusModal()
                        getTicket(values.uid)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getTicket = (uid) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.TICKETS.GET_TICKET_BY_ID + uid)
            .then((res) => {
                var fullData = clone(data)
                fullData.forEach((item, index) => {
                    if (item.uid === uid) {
                        fullData[index] = res.data
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAssignToModal = (record) => {
        setSelectedRecord(record)
        setAssignToModalVisible(true)

        setTimeout(() => {
            if (record.assignedTo) {
                form.setFieldsValue({ assignedTo: record.assignedTo.id })
            } else {
                form.resetFields()
            }
        }, 100)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const assignTicket = () => {
        form.validateFields(['assignedTo'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.TICKETS.ASSIGN, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAssignToModal()
                        getTicket(values.uid)
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const handleDescriptionChange = (newValue, e) => {
        setDescription(newValue)
    }

    const showLinkedUserModal = () => {
        setLinkUserModal(true)
    }

    const hideLinkedUserModal = () => {
        setLinkUserModal(false)
    }

    const searchUser = (query) => {
        setSearchUserLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUserData(res.data)
            })
            .finally(() => {
                setSearchUserLoading(false)
            })
    }

    const selectUserInSearch = (record) => {
        setLinkedUser(record)
        hideLinkedUserModal()
    }

    const searchUserColumns = [
        {
            title: 'Add',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => selectUserInSearch(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    return (
        <div>
            <h2>Tickets</h2>
            <div>
                <Radio.Group
                    onChange={onChangeStatusFilter}
                    value={selectedStatus}
                    style={{ marginBottom: 10 }}
                >
                    {getStatusFilterOptions()}
                </Radio.Group>
                <Select
                    showSearch
                    allowClear={true}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 250, marginLeft: 10 }}
                    placeholder={'Select Agent'}
                    onChange={onSelectEmployee}
                    value={assignedToFilter}
                >
                    {getEmployeeOptions()}
                </Select>
                <Button
                    type="link"
                    style={{ padding: 0, marginLeft: 10 }}
                    onClick={() => getTickets()}
                >
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 16 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 16 }} />
                    )}
                </Button>
                <Button
                    type="primary"
                    style={{ marginLeft: 20 }}
                    onClick={showAddUpdateModal}
                >
                    Add Ticket
                </Button>
            </div>
            <div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageTickets}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
            <Modal
                title={(selectedRecord.uid ? 'Update' : 'Add') + ' Ticket'}
                open={addUpdateModalVisible}
                onOk={addUpdateTicket}
                confirmLoading={loading}
                onCancel={hideAddUpdateModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={900}
                maskClosable={false}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input />
                    </Form.Item>
                    {!selectedRecord.uid ? (
                        <Form.Item
                            label="Assign To"
                            name="assignedTo"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            style={{ marginTop: 10 }}
                        >
                            <Select
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: 350 }}
                            >
                                {getEmployeeOptions()}
                            </Select>
                        </Form.Item>
                    ) : null}
                    <div>
                        <span style={{ marginLeft: 18 }}>Linked User: </span>
                        <Button
                            type="link"
                            style={{ padding: 0, marginLeft: 5 }}
                            onClick={showLinkedUserModal}
                        >
                            {linkedUser.id
                                ? linkedUser.firstName +
                                  ' ' +
                                  linkedUser.lastName
                                : '--Select--'}
                        </Button>
                    </div>
                    <div>
                        <h4>Description</h4>
                        <TinyMceEditor
                            height={400}
                            handleEditorChange={handleDescriptionChange}
                            initialValue={description}
                            uploadGroupName={'tickets'}
                        />
                        <ILAttachmentFileList
                            attachmentFileList={attachmentFileList}
                            setAttachmentFileList={setAttachmentFileList}
                        />
                    </div>
                </Form>
            </Modal>
            <Modal
                title={'Update Status'}
                open={updateStatusModalVisible}
                onOk={updateStatus}
                confirmLoading={loading}
                onCancel={hideUpdateStatusModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <p>{selectedRecord.title}</p>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label=""
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{getStatusFilterOptions()}</Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Assign Ticket'}
                open={assignToModalVisible}
                onOk={assignTicket}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Update'}
                destroyOnClose={true}
                width={400}
            >
                <p>{selectedRecord.title}</p>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label=""
                        name="assignedTo"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getEmployeeOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Select User'}
                open={linkUserModalVisible}
                onCancel={hideLinkedUserModal}
                destroyOnClose={true}
                width={900}
                footer={null}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    autoFocus
                />
                <Table
                    columns={searchUserColumns}
                    dataSource={searchUserData}
                    pagination={false}
                    size="small"
                    loading={searchUserLoading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default Tickets
