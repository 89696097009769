import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import UserWatchTimeAnalyticsByMonth from './UserWatchTimeAnalyticsByMonth'
import WatchTimeAnalyticsByCourse from './WatchTimeAnalyticsByCourse'
import CourseCompletionAnalytics from './CourseCompletionAnalytics'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import UserAttemptWiseAnalytics from './UserAttemptWiseAnalytics'
import { useParams } from 'react-router-dom'

const FILTER_BY_MONTH = 'FILTER_BY_MONTH'
const FILTER_BY_COURSE = 'FILTER_BY_COURSE'
const FILTER_COURSE_COMPLETION = 'FILTER_COURSE_COMPLETION'
const FILTER_BY_ATTEMPT = 'FILTER_BY_ATTEMPT'

function UserWatchTimeAnalytics(props) {
    const { userId } = useParams()
    const [analyticsType, setAnalyticsType] = useState(FILTER_BY_ATTEMPT)
    const [userData, setUserData] = useState({})
    const [userDataLoading, setUserDataLoading] = useState(false)

    useEffect(() => {
        setUserDataLoading(true)
        RestApi.doGet(GLOBAL.URL.GET_USER_BY_ID + userId)
            .then((res) => {
                setUserData(res.data)
            })
            .finally(() => {
                setUserDataLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeAnalyticsType = (event) => {
        setAnalyticsType(event.target.value)
    }

    return (
        <div>
            <h2>
                Analytics
                {!userDataLoading ? (
                    <span style={{ color: 'blue' }}>
                        {' - ' + userData.firstName + ' ' + userData.lastName}
                    </span>
                ) : null}
            </h2>
            <div>
                <Radio.Group
                    onChange={onChangeAnalyticsType}
                    defaultValue={analyticsType}
                >
                    <Radio.Button
                        value={FILTER_BY_ATTEMPT}
                        key={FILTER_BY_ATTEMPT}
                    >
                        Attempt Progress
                    </Radio.Button>
                    <Radio.Button value={FILTER_BY_MONTH} key={FILTER_BY_MONTH}>
                        By Month
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_BY_COURSE}
                        key={FILTER_BY_COURSE}
                    >
                        By Course
                    </Radio.Button>
                    <Radio.Button
                        value={FILTER_COURSE_COMPLETION}
                        key={FILTER_COURSE_COMPLETION}
                    >
                        Course Completion
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div>
                {analyticsType === FILTER_BY_MONTH ? (
                    <UserWatchTimeAnalyticsByMonth userId={userId} />
                ) : analyticsType === FILTER_BY_COURSE ? (
                    <WatchTimeAnalyticsByCourse userId={userId} />
                ) : analyticsType === FILTER_COURSE_COMPLETION ? (
                    <CourseCompletionAnalytics userId={userId} />
                ) : (
                    <UserAttemptWiseAnalytics userId={userId} />
                )}
            </div>
        </div>
    )
}

export default UserWatchTimeAnalytics
