import { message, Button, Upload, Input, InputNumber } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'

class UploadQuestions extends React.Component {
    state = {
        loading: false,
        resourceFilesData: [],
        fileList: [],
        subject: '',
        folder: '',
        compTestDuration: 0,
    }

    uploadQuestions = () => {
        if (this.state.fileList.length === 0) {
            message.error('Select file to upload!')
            return
        }

        if (!this.state.subject) {
            message.error('Enter subject nam!')
            return
        }

        this.setState({ loading: true })

        var formData = new FormData()
        formData.append('file', this.state.fileList[0])
        formData.append('subject', this.state.subject)
        formData.append('folder', this.state.folder)
        formData.append('compTestDuration', this.state.compTestDuration)
        RestApi.doPost(GLOBAL.URL.UPLOAD_QUESTIONS, formData)
            .then((response) => {
                message.success(response.data)
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const { fileList } = this.state
        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)
                    return {
                        fileList: newFileList,
                    }
                })
            },
            beforeUpload: (file) => {
                this.setState((state) => ({
                    fileList: [...state.fileList, file],
                }))
                return false
            },
            fileList,
        }

        return (
            <div>
                <h2>Upload Questions </h2>
                <div>
                    <u>Note:</u>
                    <ol>
                        <li>
                            Subject is to be manually created if not already
                            present
                        </li>
                        <li>
                            This functionality cannot be used when the questions
                            / feedback have images in it
                        </li>
                    </ol>
                </div>
                <div style={{ width: 600 }}>
                    <div style={{ marginTop: 20 }}>
                        <h4>Select File:</h4>
                        <Upload {...props}>
                            <Button>
                                <UploadOutlined /> Select
                            </Button>
                        </Upload>
                        <div style={{ marginTop: 10 }}>
                            <h4>Subject:</h4>
                            <Input
                                placeholder="Subject Name"
                                onChange={(e) => {
                                    this.setState({ subject: e.target.value })
                                }}
                            />
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <h4>Comprehensive tests duration:</h4>
                            <InputNumber
                                min={0}
                                onChange={(value) =>
                                    this.setState({ compTestDuration: value })
                                }
                            />
                        </div>
                    </div>
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        this.uploadQuestions()
                    }}
                    style={{ marginTop: 15 }}
                    loading={this.state.loading}
                >
                    Upload Questions
                </Button>
            </div>
        )
    }
}

export default UploadQuestions
