import React, { useEffect, useState } from 'react'

function VdoCipherPlayer(props) {
    const [videoUrl, setVideoUrl] = useState(false)

    useEffect(() => {
        const url = `https://player.vdocipher.com/v2/?otp=${props.videoCreds.otp}&playbackInfo=${props.videoCreds.playbackInfo}&autoplay=true`
        setVideoUrl(url)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            style={{
                width: '100%',
                maxWidth: '700px',
                height: '400px',
                margin: '20px 0px',
                borderRadius: '5px',
            }}
        >
            <iframe
                title="Video player"
                src={videoUrl}
                style={{
                    width: '100%',
                    height: '100%',
                }}
                allow="autoplay; encrypted-media"
                allowFullScreen
                controls
            />
        </div>
    )
}

export default VdoCipherPlayer
