import React, { useEffect, useRef, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    message,
    Input,
    InputNumber,
    Popconfirm,
    Radio,
    Modal,
    Select,
    Upload,
    Form,
} from 'antd'
import { EditTwoTone, UploadOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import { PlayCircleTwoTone } from '@ant-design/icons'
import VdoCipherPlayer from '../Video/VdoCipherPlayer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faTimes,
    faTrashAlt,
    faAngleDoubleUp,
    faAngleUp,
    faAngleDown,
    faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from '@mui/material'

var CONTENT_TYPE_VIDEO = 'video'
var CONTENT_TYPE_YOUTUBE = 'youtube'
var CONTENT_TYPE_RESOURCE = 'resource'
var CONTENT_TYPE_MCQ = 'mcq'

var SEARCH_MCQ_TEST_TYPE_COURSE = 'SEARCH_MCQ_TEST_TYPE_COURSE'
var SEARCH_MCQ_TEST_TYPE_REGULAR = 'SEARCH_MCQ_TEST_TYPE_REGULAR'

const LINK_TYPE = 'link'

const Sections = ({ location, navigate }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [courseName, setCourseName] = useState('')
    const [courseId, setCourseId] = useState('')
    const [moduleName, setModuleName] = useState('')
    const [moduleId, setModuleId] = useState(null)
    const [showAddSection, setShowAddSection] = useState(false)
    const contentTypes = [
        CONTENT_TYPE_VIDEO,
        CONTENT_TYPE_YOUTUBE,
        CONTENT_TYPE_RESOURCE,
        CONTENT_TYPE_MCQ,
    ]
    const [selectedContentType, setSelectedContentType] =
        useState(CONTENT_TYPE_VIDEO)
    const [showAddVideo, setShowAddVideo] = useState(false)
    const [videoTitle, setVideoTitle] = useState('')
    const [hostVideoId, setHostVideoId] = useState('')
    const [editNameModalVisible, setEditNameModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({ video: {} })
    const [updatedSectionName, setUpdatedSectionName] = useState('')
    const [cloneModalVisible, setCloneModalVisible] = useState(false)
    const [searchModules, setSearchModules] = useState([])
    const [loadingSearchModules, setLoadingSearchModules] = useState(false)
    const [showModulesInCloneModal, setShowModulesInCloneModal] = useState(true)
    const [sectionsInModuleClone, setSectionsInModuleClone] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedResource, setSelectedResource] = useState({})
    const [resourcesModalVisible, setResourcesModalVisible] = useState(false)
    const [searchResourcesLoading, setSearchResourcesLoading] = useState(false)
    const [searchResources, setSearchResources] = useState([])
    const [searchResourcesNextUrl, setSearchResourcesNextUrl] = useState('')
    const [isNewVideo, setIsNewVideo] = useState('true')
    const [newVideoTitle, setNewVideoTitle] = useState('')
    const [fetchVdoCipherVideoIdLoading, setFetchVdoCipherVideoIdLoading] =
        useState(false)
    const [fetchGumletVideoIdLoading, setFetchGumletVideoIdLoading] =
        useState(false)
    const [
        updateVideoInSectionModalVisible,
        setUpdateVideoInSectionModalVisible,
    ] = useState(false)
    const [updatedSectionVideoName, setUpdatedSectionVideoName] = useState('')
    const [selectedMcq, setSelectedMcq] = useState({})
    const [mcqModalVisible, setMcqModalVisible] = useState(false)
    const [searchMcqs, setSearchMcqs] = useState([])
    const [searchMcqLoading, setSearchMcqLoading] = useState(false)
    const [
        updateMcqTestInSectionModalVisible,
        setUpdateMcqTestInSectionModalVisible,
    ] = useState(false)
    const [selectedSearchMcqType, setSelectedSearchMcqType] = useState(
        SEARCH_MCQ_TEST_TYPE_COURSE
    )
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [selectedStream, setSelectedStream] = useState('')
    const [selectedLevel, setSelectedLevel] = useState('')
    const [mcqSubjectData, setMcqSubjectData] = useState([])
    const [mcqChaptersData, setMcqChaptersData] = useState([])
    const [selectedMcqSubject, setSelectedMcqSubject] = useState('')
    const [testData, setTestData] = useState([])
    const [addResourceModalVisible, setAddResourceModalVisible] =
        useState(false)
    const [loadingAddFile, setLoadingAddFile] = useState(false)
    const [fileTypes, setFileTypes] = useState([])
    const [fileList, setFileList] = useState([])
    const [isDataType, setIsDataType] = useState(false)
    const [playVideoLoading, setPlayVideoLoading] = useState(false)
    const [showVdoCipherPlayer, setShowVdoCipherPlayer] = useState(false)
    const [videoCreds, setVideoCreds] = useState(null)
    const [uploadSectionsModalVisible, setUploadSectionsModalVisible] =
        useState(false)
    const [uploadSectionsLoading, setUploadSectionsLoading] = useState(false)
    const [form] = Form.useForm()
    const { moduleId: paramsModuleId } = useParams()
    const [updateTagModalVisible, setUpdateTagModalVisible] = useState(false)
    const [updatedTagName, setUpdatedTagName] = useState('')

    const inputRef = useRef()
    const tagInputRef = useRef()

    useEffect(() => {
        const { courseName, courseId, moduleName } = location.state
        setLoading(true)
        setCourseName(courseName)
        setModuleName(moduleName)
        setModuleId(paramsModuleId)
        setCourseId(courseId)
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (editNameModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [editNameModalVisible, inputRef])

    useEffect(() => {
        if (!moduleId) {
            return
        }

        getData()
        // eslint-disable-next-line
    }, [moduleId])

    const getData = (resetData = true) => {
        if (resetData) {
            setData([])
        }

        RestApi.doGet(GLOBAL.URL.GET_SECTIONS_IN_MODULE + moduleId)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getSectionsToClone = (moduleId) => {
        setLoadingSearchModules(true)
        RestApi.doGet(GLOBAL.URL.GET_SECTIONS_IN_MODULE + moduleId)
            .then((res) => {
                setSectionsInModuleClone(res.data)
                setShowModulesInCloneModal(false)
            })
            .finally(() => {
                setLoadingSearchModules(false)
            })
    }

    const cloneSections = () => {
        if (selectedRows.size === 0) {
            message.error('Select sections to clone!')
            return
        }

        setLoadingSearchModules(true)

        var sectionIds = []
        selectedRows.forEach((row) => {
            sectionIds.push(row.id)
        })

        var payload = {}
        payload.moduleId = moduleId
        payload.sectionIds = sectionIds

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.COPY_SECTIONS, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success(response.data)
                    hideCloneSection()
                    getData()
                }
            })
            .finally((error) => {
                setLoadingSearchModules(false)
            })
    }

    const navigateToCourses = () => {
        navigate('/courses')
    }

    const deleteSection = (id) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_SECTION, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success(response.data)
                    setData(data.filter((item) => item.id !== id))
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisableSection = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_SECTION
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_SECTION
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var dataNew = data
                    dataNew.forEach((section) => {
                        if (section.id === record.id) {
                            section.enabled = !record.enabled
                        }
                    })

                    setData(dataNew)

                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddSectionForm = () => {
        form.resetFields()
        setShowAddSection(true)
        setShowAddVideo(false)
        setSelectedResource({})
    }

    const showCloneSection = () => {
        setCloneModalVisible(true)
        setShowModulesInCloneModal(true)
        setSelectedRows([])
        setSearchModules([])
    }

    const hideCloneSection = () => {
        setCloneModalVisible(false)
    }

    const hideAddSectionForm = () => {
        setShowAddSection(false)
        setIsNewVideo('true')
        setNewVideoTitle('')
        setSelectedContentType(CONTENT_TYPE_VIDEO)
    }

    const hideAddVideoForm = () => {
        setShowAddVideo(false)
    }

    const handleContentTypeChange = (e) => {
        setSelectedContentType(e.target.value)
    }

    const addSection = () => {
        form.validateFields()
            .then((values) => {
                if (selectedContentType === CONTENT_TYPE_RESOURCE) {
                    if (!selectedResource.id) {
                        message.error('Select resource to link!')
                        return
                    }

                    values['resourceId'] = selectedResource.id
                }

                if (selectedContentType === CONTENT_TYPE_MCQ) {
                    if (!selectedMcq.testId) {
                        message.error('Select test to link!')
                        return
                    }

                    values['testId'] = selectedMcq.testId
                }

                setLoading(true)

                values['moduleId'] = moduleId

                let formbody = new FormData()
                formbody.append('payload', JSON.stringify(values))
                RestApi.doPost(GLOBAL.URL.ADD_SECTION_IN_MODULE, formbody)
                    .then((response) => {
                        message.success('Section Added')
                        hideAddSectionForm()
                        getData()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const onVideoTitleChange = (e) => {
        setVideoTitle(e.target.value)
    }

    const onHostVideoIdChange = (e) => {
        setHostVideoId(e.target.value)
    }

    const addVideo = () => {
        if (!videoTitle) {
            message.error('Video title is needed!')
            return
        }

        if (!hostVideoId) {
            message.error('Host video id is needed!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.title = videoTitle
        payload.hostVideoId = hostVideoId

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_VIDEO, formbody)
            .then((response) => {
                message.success('Video Added')
                hideAddVideoForm()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Video Title',
                dataIndex: 'title',
                key: 'title',
            },
        ]

        var reorderData = []
        data.forEach((item) => {
            var row = {}
            row.id = item.id
            row.name = item.name
            row.title = item.video.title

            reorderData.push(row)
        })

        navigate('/reorder', {
            state: {
                reorderData: reorderData,
                updateUrl: GLOBAL.URL.REORDER_SECTIONS_IN_MODULE,
                columns: columns,
            },
        })
    }

    const showEditNameModal = (record) => {
        setEditNameModalVisible(true)
        setSelectedRecord(record)
        setUpdatedSectionName(record.name)
    }

    const hideEditNameModal = (record) => {
        setEditNameModalVisible(false)
    }

    const updateSectionName = () => {
        setLoading(true)

        var payload = {}
        payload.id = selectedRecord.id
        payload.name = updatedSectionName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_SECTION_NAME, formbody)
            .then((response) => {
                message.success(response.data)
                hideEditNameModal()
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showUpdateVideoInSectionModal = (record) => {
        setUpdateVideoInSectionModalVisible(true)
        setSelectedRecord(record)
        setUpdatedSectionVideoName(record.video.title)
    }

    const hideUpdateVideoInSectionModal = () => {
        setUpdateVideoInSectionModalVisible(false)
    }

    const updateVideoInSection = () => {
        setLoading(true)

        var payload = {}
        payload.id = selectedRecord.id
        payload.videoTitle = updatedSectionVideoName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_VIDEO_IN_SECTION, formbody)
            .then((response) => {
                message.success(response.data)
                hideUpdateVideoInSectionModal()
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const searchModule = (value) => {
        if (!value) {
            message.error('Enter Module Name')
            return
        }

        setLoadingSearchModules(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_MODULE + encodeURIComponent(value))
            .then((res) => {
                setSearchModules(res.data)
            })
            .finally(() => {
                setLoadingSearchModules(false)
            })
    }

    const showResourcesModal = () => {
        setResourcesModalVisible(true)
        setSearchResources([])
    }

    const hideResourcesModal = () => {
        setResourcesModalVisible(false)
    }

    const onSearchResources = (query) => {
        if (!query) {
            return
        }

        setSearchResourcesLoading(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_FILES + encodeURIComponent(query))
            .then((response) => {
                var res = JSON.parse(response.data)
                setSearchResources(res.data)
                setSearchResourcesNextUrl(res.next)
            })
            .finally(() => {
                setSearchResourcesLoading(false)
            })
    }

    const getSearchResourcesNextPage = () => {
        if (!searchResourcesNextUrl) {
            return
        }

        setSearchResourcesLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + searchResourcesNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setSearchResources([...searchResources, ...res.data])
                setSearchResourcesNextUrl(res.next)
            })
            .finally(() => {
                setSearchResourcesLoading(false)
            })
    }

    const onSetSelectedResource = (record) => {
        setSelectedResource(record)
        hideResourcesModal()
    }

    const onNewVideoChange = (e) => {
        setIsNewVideo(e.target.value)
    }

    const fetchVdoCipherVideoId = () => {
        form.setFieldsValue({
            hostVdoCipherVideoId: '',
        })

        setFetchVdoCipherVideoIdLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_VDOCIPHER_ID + encodeURIComponent(newVideoTitle)
        )
            .then((res) => {
                form.setFieldsValue({
                    hostVdoCipherVideoId: res.data,
                })
            })
            .finally(() => {
                setFetchVdoCipherVideoIdLoading(false)
            })
    }

    const fetchGumletVideoId = () => {
        form.setFieldsValue({
            hostGumletId: '',
        })

        setFetchGumletVideoIdLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_GUMLET_ID + encodeURIComponent(newVideoTitle)
        )
            .then((res) => {
                form.setFieldsValue({
                    hostGumletId: res.data,
                })
            })
            .finally(() => {
                setFetchGumletVideoIdLoading(false)
            })
    }

    const navigateToModules = () => {
        navigate('/modules/' + courseId, { state: { courseId: courseId } })
    }

    const showMcqModal = () => {
        setMcqModalVisible(true)
        setSearchMcqs([])
        searchMcqTest('')
    }

    const hideMcqModal = () => {
        setMcqModalVisible(false)
        setUpdateMcqTestInSectionModalVisible(false)
        setSelectedMcqSubject('')
    }

    const searchMcqTest = (query) => {
        setSearchMcqLoading(true)

        RestApi.doGet(GLOBAL.URL.SEARCH_COURSE_TEST + encodeURIComponent(query))
            .then((res) => {
                var response = JSON.parse(res.data)
                setSearchMcqs(response.data)
            })
            .finally(() => {
                setSearchMcqLoading(false)
            })
    }

    const onSetSelectedMcq = (record) => {
        setSelectedMcq(record)
        hideMcqModal()
    }

    const showMcqTestInSectionModal = (record) => {
        setUpdateMcqTestInSectionModalVisible(true)
        setSelectedRecord(record)
        searchMcqTest('')
    }

    const hideMcqTestInSectionModal = () => {
        setMcqModalVisible(false)
        setUpdateMcqTestInSectionModalVisible(false)
    }

    const updateMcqTestInSection = (record) => {
        setLoading(true)
        setSearchMcqLoading(true)

        var payload = {}
        payload.id = selectedRecord.id
        payload.testId = record.testId

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_MCQ_TEST_IN_SECTION, formbody)
            .then((response) => {
                message.success(response.data)
                hideMcqTestInSectionModal()
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
            .finally(() => {
                setSearchMcqLoading(false)
            })
    }

    const handleSearchMcqTypeChange = (e) => {
        setSelectedSearchMcqType(e.target.value)
        setSelectedStream('')
        setSelectedLevel('')

        if (
            e.target.value === SEARCH_MCQ_TEST_TYPE_REGULAR &&
            streamData.length === 0
        ) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
                var streamDataUpdated = JSON.parse(res.data)
                setStreamData(streamDataUpdated)
            })
        }
    }

    const onChangeStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        setSelectedStream(e.target.value)
        setLevelsData(levels)
        setSelectedLevel('')
    }

    const onChangeLevel = (e) => {
        setSelectedLevel(e.target.value)
        setSearchMcqLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_SUBJECTS_BY_STREAM +
                '?course=' +
                selectedStream +
                '&level=' +
                e.target.value
        )
            .then((res) => {
                setMcqSubjectData(res.data)
            })
            .finally(() => {
                setSearchMcqLoading(false)
            })
    }

    const mcqSubjectSelected = (value) => {
        setTestData([])
        setSearchMcqLoading(true)
        setSelectedMcqSubject(value)

        RestApi.doGet(
            GLOBAL.URL.GET_CHAPTERS_BY_SUBJECT +
                '?subject=' +
                encodeURIComponent(value)
        ).then((res) => {
            setMcqChaptersData(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_TESTS + value)
            .then((res) => {
                setTestData(res.data)
            })
            .finally(() => {
                setSearchMcqLoading(false)
            })
    }

    const mcqChapterSelected = (value) => {
        setTestData([])
        setSearchMcqLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_TESTS +
                encodeURIComponent(selectedMcqSubject) +
                '&chapter=' +
                (typeof value !== 'undefined' ? encodeURIComponent(value) : '')
        )
            .then((res) => {
                setTestData(res.data)
            })
            .finally(() => {
                setSearchMcqLoading(false)
            })
    }

    const showAddResourceModal = () => {
        setAddResourceModalVisible(true)

        if (Object.keys(fileTypes).length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_FILE_TYPES).then((res) => {
                setFileTypes(res.data)
            })
        }
    }

    const hideAddResourceModal = () => {
        setAddResourceModalVisible(false)
        setIsDataType(false)
        setFileList([])
    }

    const changeSelectOption = (value) => {
        if (value === 'youtube' || value === LINK_TYPE) {
            setIsDataType(true)
        } else {
            setIsDataType(false)
        }
    }

    const addFile = () => {
        setLoadingAddFile(true)
        form.validateFields()
            .then((values) => {
                var formBodyFileObject = new FormData()
                formBodyFileObject.append('payload', JSON.stringify(values))
                if (Object.keys(fileList).length > 0) {
                    formBodyFileObject.append('file', fileList[0])
                }
                RestApi.doPost(GLOBAL.URL.ADD_FILES, formBodyFileObject)
                    .then((response) => {
                        hideAddResourceModal()
                        onSearchResources(values.name)
                    })
                    .finally((resp) => {
                        setLoadingAddFile(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const gotoManageVideos = () => {
        window.open('/videos', '_blank')
    }

    const playVideo = (record) => {
        if (
            !record.video.hostVideoCipher ||
            !record.video.hostVideoCipher.hostVideoId
        ) {
            message.error('Cannot play, not hosted on VdoCipher!')
            return
        }

        setSelectedRecord(record)
        setPlayVideoLoading(true)

        var payload = {}
        payload.hostVdoCipherId = record.video.hostVideoCipher.hostVideoId

        var formBodyFileObject = new FormData()
        formBodyFileObject.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.GET_VIDEO_CREDS, formBodyFileObject)
            .then((res) => {
                setVideoCreds(res.data)
                setShowVdoCipherPlayer(true)
            })
            .finally((resp) => {
                setPlayVideoLoading(false)
            })
    }

    const hideVdoCipherPlayer = () => {
        setShowVdoCipherPlayer(false)
    }

    const showUploadSectionsModal = () => {
        setUploadSectionsModalVisible(true)
        setUploadSectionsLoading(false)
        setFileList([])
    }

    const hideUploadSectionsModal = () => {
        setUploadSectionsModalVisible(false)
        setUploadSectionsLoading(false)
        setFileList([])
    }

    const uploadSections = () => {
        if (!fileList || !fileList.length) {
            message.error('Select the file!')
            return
        }

        setUploadSectionsLoading(true)
        var payload = {}
        payload.moduleId = moduleId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        formData.append('file', fileList[0])

        RestApi.doPost(GLOBAL.URL.UPLOAD_SECTIONS_IN_MODULE, formData)
            .then((response) => {
                message.success(response.data)
                hideUploadSectionsModal()
                setLoading(true)
                getData()
            })
            .finally(() => {
                setUploadSectionsLoading(false)
            })
    }

    const { Search } = Input
    const { Option } = Select
    const { Dragger } = Upload

    const colums = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => enableDisableSection(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            padding: 0,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Section Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showEditNameModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>
                        {index + 1}
                        {'. '}
                        {record.name}
                    </span>
                </span>
            ),
        },
        {
            title: 'Content Type',
            dataIndex: 'contentType',
            key: 'contentType',
            render: (text, record) => (
                <span>
                    {record.contentType === 'video' ? (
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => playVideo(record)}
                            loading={playVideoLoading}
                        >
                            <PlayCircleTwoTone
                                style={{ marginRight: 5, fontSize: 14 }}
                            />
                        </Button>
                    ) : null}
                    {record.contentType}
                </span>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.contentType === 'video'
                        ? record.video.duration
                        : record.durationString}
                </span>
            ),
        },
        {
            title: 'DATA',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <span>
                    {record.contentType === 'video' ? (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    showUpdateVideoInSectionModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditTwoTone style={{ fontSize: 11 }} />
                            </Button>
                            <span style={{ marginLeft: 5 }}>
                                {record.video.title}
                            </span>
                        </>
                    ) : record.contentType === CONTENT_TYPE_RESOURCE ? (
                        record.resource.name + ' (' + record.resource.type + ')'
                    ) : record.contentType === CONTENT_TYPE_MCQ ? (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    showMcqTestInSectionModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditTwoTone style={{ fontSize: 11 }} />
                            </Button>
                            <span style={{ marginLeft: 5 }}>
                                {record.mcqTest.testNameUnique
                                    ? record.mcqTest.testNameUnique
                                    : record.mcqTest.name}
                            </span>
                        </>
                    ) : (
                        <a
                            href={record.data}
                            style={{ display: 'table-cell' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {record.data}
                        </a>
                    )}
                </span>
            ),
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showUpdateTagModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 11 }} />
                    </Button>
                    <span style={{ marginLeft: 5 }}>{record.tag}</span>
                </span>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => moveTop(record.id)}
                    >
                        <FontAwesomeIcon
                            icon={faAngleDoubleUp}
                            style={{ marginRight: 5, color: 'gray' }}
                        />
                    </Button>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => moveUp(record.id)}
                    >
                        <FontAwesomeIcon
                            icon={faAngleUp}
                            style={{ marginRight: 5, color: 'gray' }}
                        />
                    </Button>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => moveDown(record.id)}
                    >
                        <FontAwesomeIcon
                            icon={faAngleDown}
                            style={{ marginRight: 5, color: 'gray' }}
                        />
                    </Button>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => moveBottom(record.id)}
                    >
                        <FontAwesomeIcon
                            icon={faAngleDoubleDown}
                            style={{ marginRight: 20, color: 'gray' }}
                        />
                    </Button>
                    <Popconfirm
                        title="Confirm delete action?"
                        onConfirm={() => deleteSection(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                padding: 0,
                                color: 'red',
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                </span>
            ),
        },
    ]

    const moveTop = (sectionId) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ sectionId: sectionId }))
        RestApi.doPost(GLOBAL.URL.MOVE_SECTION_TOP, postBody)
            .then((response) => {
                getData(false)

                message.destroy()
                message.success(response.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const moveUp = (sectionId) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ sectionId: sectionId }))
        RestApi.doPost(GLOBAL.URL.MOVE_SECTION_UP, postBody)
            .then((response) => {
                getData(false)

                message.destroy()
                message.success(response.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const moveDown = (sectionId) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ sectionId: sectionId }))
        RestApi.doPost(GLOBAL.URL.MOVE_SECTION_DOWN, postBody)
            .then((response) => {
                getData(false)

                message.destroy()
                message.success(response.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const moveBottom = (sectionId) => {
        setLoading(true)

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ sectionId: sectionId }))
        RestApi.doPost(GLOBAL.URL.MOVE_SECTION_BOTTOM, postBody)
            .then((response) => {
                getData(false)

                message.destroy()
                message.success(response.data)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const moduleSearchColums = [
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            width: 150,
            render: (text, record) => (
                <span>
                    <Button
                        type="primary"
                        onClick={() => getSectionsToClone(record.id)}
                        size="small"
                    >
                        Get Sections
                    </Button>
                </span>
            ),
        },
        {
            title: 'Module Name',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    const cloneSectionsColums = [
        {
            title: 'Section Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'CLIP',
            dataIndex: 'data',
            key: 'data',
            render: (text, record) => (
                <span>
                    {record.contentType === 'video' ? (
                        record.video.title
                    ) : (
                        <a
                            href={record.data}
                            style={{ display: 'table-cell' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {record.data}
                        </a>
                    )}
                </span>
            ),
        },
    ]

    const searchResourceColumns = [
        {
            title: 'Select',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            render: (text, record) => (
                <Button
                    size="small"
                    type="primary"
                    onClick={() => onSetSelectedResource(record)}
                >
                    Select
                </Button>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 100,
        },
    ]

    var contentTypeOptions = []
    contentTypes.forEach((item) => {
        contentTypeOptions.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
        },
    }

    const searchMcqColumns = [
        {
            title: 'Select',
            dataIndex: 'testId',
            key: 'testId',
            width: 80,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() =>
                        mcqModalVisible
                            ? setSelectedMcq(record)
                            : updateMcqTestInSection(record)
                    }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button size="small" type="primary">
                        Select
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'testNameUnique',
            key: 'testNameUnique',
        },
        {
            title: 'Timed',
            dataIndex: 'timed',
            key: 'timed',
            render: (text, record) => (
                <span>{record.timed ? 'Yes' : 'No'}</span>
            ),
        },
    ]

    const subjectMcqColumns = [
        {
            title: 'Select',
            dataIndex: 'testId',
            key: 'testId',
            width: 80,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() =>
                        mcqModalVisible
                            ? onSetSelectedMcq(record)
                            : updateMcqTestInSection(record)
                    }
                    okText="Yes"
                    cancelText="No"
                >
                    <Button size="small" type="primary">
                        Select
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 120,
            render: (text, record) => <span>{record.name}</span>,
        },
        {
            title: 'Chapter',
            dataIndex: 'chapter',
            key: 'chapter',
        },
        {
            title: 'Timed',
            key: 'createdBy',
            width: 100,
            render: (text, record) => (
                <span>
                    <p>
                        {record.timed ? 'Yes | ' + record.timeInMinutes : 'No'}
                    </p>
                </span>
            ),
        },
    ]

    var streamOptions = []
    streamData.forEach((item) => {
        streamOptions.push(
            <Radio.Button value={item.course} key={item.course}>
                {item.course}
            </Radio.Button>
        )
    })

    var levelOptions = []
    levelsData.forEach((item) => {
        levelOptions.push(
            <Radio.Button value={item} key={item}>
                {item}
            </Radio.Button>
        )
    })

    var mcqSubjectOptionsView = []
    mcqSubjectData.forEach((item) => {
        mcqSubjectOptionsView.push(
            <Option value={item.name} key={item.name}>
                {item.name}
            </Option>
        )
    })

    var mcqChapterOptionsView = []
    mcqChaptersData.forEach((item) => {
        mcqChapterOptionsView.push(
            <Option value={item.name} key={item.name}>
                {item.name}
            </Option>
        )
    })

    var fileListOptionArray = []
    fileTypes.forEach((item) => {
        fileListOptionArray.push(<Option value={item}>{item}</Option>)
    })

    const fileProps = {
        onRemove: (file) => {
            setFileList((prev) => {
                const index = prev.indexOf(file)
                const newFileList = prev.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file) => {
            setFileList((prev) => [...prev, file])
            return false
        },
        fileList,
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file) => {
            setFileList((prev) => {
                const index = prev.indexOf(file)
                const newFileList = prev.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file) => {
            setFileList([file])
            return false
        },
        fileList,
    }

    const showUpdateTagModal = (record) => {
        setSelectedRecord(record)
        setUpdateTagModalVisible(true)
        setUpdatedTagName(record.tag)

        setTimeout(() => {
            if (tagInputRef.current) {
                tagInputRef.current.focus()
            }
        }, 100)
    }

    const hideUpdateTagModal = () => {
        setUpdateTagModalVisible(false)
    }

    const updateTag = () => {
        setLoading(true)

        var payload = {}
        payload.sectionId = selectedRecord.id
        payload.tag = updatedTagName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_SECTION_TAG, formbody)
            .then((response) => {
                message.success(response.data)
                hideUpdateTagModal()
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <div>
                <Button type="default" onClick={navigateToCourses} size="small">
                    Courses
                </Button>
                {' - '}
                <Button
                    type="default"
                    size="small"
                    onClick={() => {
                        navigateToModules()
                    }}
                >
                    {courseName}
                </Button>
                {' - '}
                <Button size="small">{moduleName}</Button>
            </div>
            <div style={{ marginTop: 10 }}>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddSectionForm}
                    loading={loading}
                >
                    Add Section
                </Button>
                <Button
                    type="default"
                    size="small"
                    onClick={showCloneSection}
                    loading={loading}
                    style={{ marginLeft: 10 }}
                >
                    Clone Section
                </Button>
                <Button
                    type="default"
                    size="small"
                    style={{
                        marginLeft: 10,
                    }}
                    loading={loading}
                    onClick={reorder}
                >
                    Reorder
                </Button>
                <Button
                    type="default"
                    size="small"
                    style={{ marginLeft: 25 }}
                    loading={loading}
                    onClick={gotoManageVideos}
                >
                    Manage Videos
                </Button>

                <Button
                    type="default"
                    size="small"
                    style={{ marginLeft: 25 }}
                    loading={loading}
                    onClick={showUploadSectionsModal}
                >
                    Upload Sections
                </Button>
            </div>
            {showAddSection ? (
                <Paper elevation={2} style={{ padding: 10, marginTop: 10 }}>
                    <Form
                        form={form}
                        initialValues={{
                            contentType: CONTENT_TYPE_VIDEO,
                            newVideo: 'true',
                        }}
                        layout="horizontal"
                        {...formItemLayout}
                    >
                        <Form.Item
                            style={{ marginBottom: 5, width: '50%' }}
                            label="Section Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Enter section name!',
                                },
                            ]}
                        >
                            <Input placeholder="Section Name" autoFocus />
                        </Form.Item>
                        <Form.Item
                            style={{ marginBottom: 5, width: '50%' }}
                            label="Content Type"
                            name="contentType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select content type!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={handleContentTypeChange}>
                                {contentTypeOptions}
                            </Radio.Group>
                        </Form.Item>
                        {selectedContentType === CONTENT_TYPE_VIDEO ? (
                            <>
                                <Form.Item
                                    style={{
                                        marginBottom: 5,
                                        width: '50%',
                                    }}
                                    label="Video Title"
                                    name="videoTitle"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter video title!',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Video Title"
                                        onChange={(e) =>
                                            setNewVideoTitle(e.target.value)
                                        }
                                        onPressEnter={addSection}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginBottom: 5,
                                        width: '50%',
                                    }}
                                    label="New Video"
                                    name="newVideo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select video type!',
                                        },
                                    ]}
                                >
                                    <Radio.Group
                                        onChange={(e) => onNewVideoChange(e)}
                                    >
                                        <Radio.Button value="true" key="yes">
                                            Yes
                                        </Radio.Button>
                                        <Radio.Button value="false" key="no">
                                            No
                                        </Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                {isNewVideo === 'true' ? (
                                    <>
                                        <Form.Item
                                            style={{
                                                marginBottom: 5,
                                                width: '50%',
                                            }}
                                            label="VdoCipher ID"
                                            name="hostVdoCipherVideoId"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="VdoCipher ID" />
                                        </Form.Item>
                                        <Button
                                            type="default"
                                            size="small"
                                            style={{
                                                marginLeft: 132,
                                                marginTop: 5,
                                            }}
                                            onClick={() =>
                                                fetchVdoCipherVideoId()
                                            }
                                            loading={
                                                fetchVdoCipherVideoIdLoading
                                            }
                                        >
                                            Fetch VdoCipher ID
                                        </Button>
                                        <Form.Item
                                            style={{
                                                marginBottom: 5,
                                                width: '50%',
                                                marginTop: 5,
                                            }}
                                            label="Gumlet ID"
                                            name="hostGumletId"
                                            rules={[
                                                {
                                                    required: false,
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Gumlet ID" />
                                        </Form.Item>
                                        <Button
                                            type="default"
                                            size="small"
                                            style={{
                                                marginLeft: 132,
                                                marginTop: 5,
                                            }}
                                            onClick={() => fetchGumletVideoId()}
                                            loading={fetchGumletVideoIdLoading}
                                        >
                                            Fetch Gumlet ID
                                        </Button>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {selectedContentType === CONTENT_TYPE_YOUTUBE ? (
                            <>
                                <Form.Item
                                    style={{
                                        marginBottom: 5,
                                        width: '50%',
                                    }}
                                    label="Youtube Link"
                                    name="data"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter youtube link!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Youtube link" />
                                </Form.Item>
                                <Form.Item
                                    style={{
                                        marginBottom: 5,
                                        width: '50%',
                                    }}
                                    label="Duration"
                                    name="duration"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter duration!',
                                        },
                                    ]}
                                >
                                    <InputNumber placeholder="Duration" />
                                </Form.Item>
                            </>
                        ) : null}
                        {selectedContentType === CONTENT_TYPE_RESOURCE ? (
                            <div
                                className="ant-row ant-form-item"
                                style={{ marginBottom: 0, width: '27%' }}
                            >
                                <div
                                    className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8"
                                    style={{ marginLeft: 58 }}
                                >
                                    Resource:
                                    <strong style={{ marginLeft: 10 }}>
                                        {selectedResource.name}
                                    </strong>
                                    <Button
                                        type="primary"
                                        size="small"
                                        style={{
                                            marginTop: 5,
                                            marginLeft: 10,
                                        }}
                                        onClick={() => showResourcesModal()}
                                    >
                                        Select
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                        {selectedContentType === CONTENT_TYPE_MCQ ? (
                            <div
                                className="ant-row ant-form-item"
                                style={{ marginBottom: 0, width: '27%' }}
                            >
                                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8">
                                    Test Name:
                                </div>
                                <div
                                    className="ant-col ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16"
                                    style={{ paddingLeft: 10 }}
                                >
                                    <strong>
                                        {selectedMcq.testNameUnique
                                            ? selectedMcq.testNameUnique
                                            : selectedMcq.name}
                                    </strong>
                                    <Button
                                        type="primary"
                                        size="small"
                                        style={{
                                            marginTop: 5,
                                            marginLeft: 10,
                                        }}
                                        onClick={() => showMcqModal()}
                                    >
                                        Select
                                    </Button>
                                </div>
                            </div>
                        ) : null}
                    </Form>
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            onClick={addSection}
                            loading={loading}
                            size="small"
                        >
                            Add
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            onClick={hideAddSectionForm}
                            loading={loading}
                            size="small"
                        >
                            Cancel
                        </Button>
                    </div>
                </Paper>
            ) : null}
            {showAddVideo ? (
                <div>
                    <Input
                        placeholder="Video Title"
                        onChange={onVideoTitleChange}
                        style={{
                            width: '50%',
                            marginBottom: 10,
                            marginTop: 10,
                            display: 'block',
                        }}
                    />
                    <Input
                        placeholder="Host Video ID"
                        onChange={onHostVideoIdChange}
                        style={{
                            width: '50%',
                            marginBottom: 10,
                            display: 'block',
                        }}
                    />
                    <Button type="primary" onClick={addVideo} loading={loading}>
                        Add
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={hideAddVideoForm}
                        loading={loading}
                    >
                        Cancel
                    </Button>
                </div>
            ) : null}
            <Table
                columns={colums}
                dataSource={data ? data : null}
                pagination={false}
                style={{ marginTop: 8 }}
                loading={loading}
                rowKey="name"
            />
            <Modal
                title="Edit Section Name"
                open={editNameModalVisible}
                onOk={() => {
                    updateSectionName()
                }}
                confirmLoading={loading}
                onCancel={hideEditNameModal}
                okText="Update"
                destroyOnClose={true}
            >
                <h3>Current name:</h3>
                <span>{selectedRecord.name}</span>
                <h3 style={{ marginTop: 15 }}>Updated name:</h3>
                <Input
                    value={updatedSectionName}
                    autoFocus
                    onChange={(e) => {
                        setUpdatedSectionName(e.target.value)
                    }}
                    ref={inputRef}
                ></Input>
            </Modal>
            <Modal
                title="Clone Sections"
                open={cloneModalVisible}
                onCancel={hideCloneSection}
                footer={null}
                width={800}
                destroyOnClose={true}
            >
                {showModulesInCloneModal ? (
                    <span>
                        <Search
                            placeholder="Search Module"
                            onSearch={(value) => searchModule(value)}
                            autoFocus
                        />
                        <Table
                            columns={moduleSearchColums}
                            dataSource={searchModules ? searchModules : null}
                            pagination={false}
                            loading={loadingSearchModules}
                            scroll={{ y: 400 }}
                            style={{ marginTop: 10 }}
                            rowKey="id"
                        />
                    </span>
                ) : (
                    <span>
                        <Button
                            onClick={() => {
                                setShowModulesInCloneModal(true)
                                setSelectedRows([])
                            }}
                            size="small"
                        >
                            Back
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            disabled={selectedRows.length === 0}
                            style={{ marginLeft: 10 }}
                            onClick={() => cloneSections()}
                        >
                            Clone
                        </Button>
                        <Table
                            columns={cloneSectionsColums}
                            dataSource={
                                sectionsInModuleClone
                                    ? sectionsInModuleClone
                                    : null
                            }
                            pagination={false}
                            loading={loadingSearchModules}
                            scroll={{ y: 400 }}
                            style={{ marginTop: 10 }}
                            rowKey="name"
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                        />
                    </span>
                )}
            </Modal>
            <Modal
                title="Select Resource"
                open={resourcesModalVisible}
                onCancel={hideResourcesModal}
                footer={null}
                width={800}
                destroyOnClose={true}
            >
                <Search
                    placeholder="Search Resource"
                    onSearch={(value) => onSearchResources(value)}
                    style={{ width: 600 }}
                    autoFocus
                />
                <Button
                    type="primary"
                    style={{ marginLeft: 20 }}
                    onClick={showAddResourceModal}
                >
                    Add
                </Button>
                <Table
                    columns={searchResourceColumns}
                    dataSource={searchResources}
                    pagination={false}
                    loading={searchResourcesLoading}
                    scroll={{ y: 400 }}
                    style={{ marginTop: 10 }}
                    rowKey="name"
                />
                {searchResourcesNextUrl ? (
                    <Button
                        type="primary"
                        loading={searchResourcesLoading}
                        onClick={getSearchResourcesNextPage}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </Modal>
            <Modal
                title="Update Video In Section"
                open={updateVideoInSectionModalVisible}
                onOk={() => {
                    updateVideoInSection()
                }}
                confirmLoading={loading}
                onCancel={hideUpdateVideoInSectionModal}
                okText="Update"
                destroyOnClose={true}
            >
                <h3>Current Video:</h3>
                <span>{selectedRecord.video.title}</span>
                <h3 style={{ marginTop: 15 }}>
                    Update Video (title of video):
                </h3>
                <Input
                    value={updatedSectionVideoName}
                    autoFocus
                    onChange={(e) => {
                        setUpdatedSectionVideoName(e.target.value)
                    }}
                ></Input>
            </Modal>
            <Modal
                title="Select MCQ Test"
                open={mcqModalVisible || updateMcqTestInSectionModalVisible}
                onCancel={hideMcqModal}
                footer={null}
                width={1000}
                destroyOnClose={true}
            >
                <Radio.Group
                    onChange={handleSearchMcqTypeChange}
                    value={selectedSearchMcqType}
                >
                    <Radio.Button
                        value={SEARCH_MCQ_TEST_TYPE_COURSE}
                        key={SEARCH_MCQ_TEST_TYPE_COURSE}
                    >
                        {'Course Tests'}
                    </Radio.Button>
                    <Radio.Button
                        value={SEARCH_MCQ_TEST_TYPE_REGULAR}
                        key={SEARCH_MCQ_TEST_TYPE_REGULAR}
                    >
                        {'Regular Tests'}
                    </Radio.Button>
                </Radio.Group>
                {selectedSearchMcqType === SEARCH_MCQ_TEST_TYPE_COURSE ? (
                    <div style={{ marginTop: 10 }}>
                        <Search
                            placeholder="Search MCQ Test"
                            onSearch={(value) => searchMcqTest(value)}
                            style={{ width: 600 }}
                            autoFocus
                        />
                        <Link
                            onClick={(event) => {
                                event.preventDefault()
                                window.open('/mcq/course-tests', '_blank')
                            }}
                            underline="none"
                        >
                            <Button type="default" style={{ marginLeft: 10 }}>
                                Add Test
                            </Button>
                        </Link>
                        <Table
                            columns={searchMcqColumns}
                            dataSource={searchMcqs}
                            pagination={false}
                            loading={searchMcqLoading}
                            scroll={{ y: 400 }}
                            style={{ marginTop: 10 }}
                            rowKey="testId"
                        />
                    </div>
                ) : (
                    <div style={{ marginTop: 10 }}>
                        <div>
                            <Radio.Group onChange={onChangeStream}>
                                {streamOptions}
                            </Radio.Group>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Radio.Group
                                onChange={onChangeLevel}
                                value={selectedLevel}
                            >
                                {levelOptions}
                            </Radio.Group>
                        </div>
                        {selectedLevel ? (
                            <div style={{ marginTop: 10 }}>
                                <Select
                                    onChange={mcqSubjectSelected}
                                    placeholder="Subject"
                                    style={{ width: 450 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {mcqSubjectOptionsView}
                                </Select>
                                <Select
                                    onChange={mcqChapterSelected}
                                    placeholder="Chapter"
                                    style={{ width: 450, marginLeft: 20 }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    allowClear
                                >
                                    {mcqChapterOptionsView}
                                </Select>
                            </div>
                        ) : null}
                        {selectedMcqSubject ? (
                            <Table
                                columns={subjectMcqColumns}
                                dataSource={testData}
                                pagination={false}
                                loading={searchMcqLoading}
                                scroll={{ y: 400 }}
                                style={{ marginTop: 10 }}
                                rowKey="testId"
                            />
                        ) : null}
                    </div>
                )}
            </Modal>
            <Modal
                open={addResourceModalVisible}
                title={'Add File'}
                okText={'Add File'}
                onCancel={() => {
                    hideAddResourceModal()
                }}
                confirmLoading={loadingAddFile}
                onOk={() => {
                    addFile()
                }}
            >
                {addResourceModalVisible ? (
                    <>
                        <Form form={form} layout="vertical">
                            <Form.Item
                                label="File Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Input autoFocus />
                            </Form.Item>
                            <Form.Item
                                label="File Type"
                                name="fileType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Needed!',
                                    },
                                ]}
                            >
                                <Select onChange={changeSelectOption}>
                                    {fileListOptionArray}
                                </Select>
                            </Form.Item>
                            {isDataType ? (
                                <Form.Item
                                    label="Data"
                                    name="data"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Url',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter Url" />
                                </Form.Item>
                            ) : null}
                        </Form>
                        {!isDataType ? (
                            <Upload {...fileProps}>
                                <Button>
                                    <UploadOutlined /> Select File
                                </Button>
                            </Upload>
                        ) : null}
                    </>
                ) : null}
            </Modal>
            <Modal
                open={showVdoCipherPlayer}
                title={selectedRecord.name}
                onCancel={hideVdoCipherPlayer}
                footer={null}
                width={700}
                destroyOnClose={true}
            >
                <VdoCipherPlayer videoCreds={videoCreds} />
            </Modal>

            <Modal
                open={uploadSectionsModalVisible}
                title={'Upload Sections'}
                onCancel={hideUploadSectionsModal}
                confirmLoading={uploadSectionsLoading}
                onOk={uploadSections}
                destroyOnClose={true}
                okText={'Upload'}
            >
                <Dragger {...uploadProps}>
                    <Button>
                        <UploadOutlined /> Click or Drag files to this area to
                        Upload Sections
                    </Button>
                </Dragger>
            </Modal>
            <Modal
                open={updateTagModalVisible}
                title={'Upload Tag'}
                onCancel={hideUpdateTagModal}
                confirmLoading={loading}
                onOk={updateTag}
                destroyOnClose={true}
                okText={'Update'}
            >
                <h3>Current Tag:</h3>
                <span>
                    {selectedRecord.tag ? selectedRecord.tag : '--NA--'}
                </span>
                <h3 style={{ marginTop: 15 }}>Update Tag:</h3>
                <Input
                    value={updatedTagName}
                    autoFocus
                    onChange={(e) => {
                        setUpdatedTagName(e.target.value)
                    }}
                    ref={tagInputRef}
                    onPressEnter={updateTag}
                ></Input>
            </Modal>
        </div>
    )
}

export default Sections
