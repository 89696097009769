import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Radio,
    InputNumber,
    Dropdown,
    Menu,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import BookInventory from './BookInventory'

const SORT_FILTER_NAME = 'name'
const SORT_FILTER_INVENTORY_ASC = 'inventory_asc'
const SORT_FILTER_INVENTORY_DESC = 'inventory_desc'

const COURSE_FILTER_ALL = 'All'
const COURSE_FILTER_ARCHIVED = 'Archived'
const COURSE_FILTER_UNARCHIVED = 'Unarchived'

function Books(props) {
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [nextUrl, setNextUrl] = useState()
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [query, setQuery] = useState('')
    const [selectedSortFilter, setSelectedSortFilter] =
        useState(SORT_FILTER_NAME)
    const [inventoryModalVisible, setInventoryModalVisible] = useState(false)

    const [loadingLinkedCourses, setLoadingLinkedCourses] = useState(false)
    const [linkedCourses, setLinkedCourses] = useState([])
    const [linkedCoursesModalVisible, setLinkedCoursesModalVisible] =
        useState(false)
    const [filter, setFilter] = useState(COURSE_FILTER_UNARCHIVED)
    const inputRef = useRef()
    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            setStreamData(JSON.parse(res.data))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getBooks()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, selectedSortFilter, filter])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditModalVisible, inputRef])

    const getBooks = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.BOOKS.GET_BOOKS +
                query +
                '&sort=' +
                selectedSortFilter +
                '&filter=' +
                filter
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageBooks = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        if (record.uid) {
            handleCourseChange(record.stream.course)
        }

        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({
                    name: record.name,
                    code: record.code,
                    mrp: record.mrp,
                    weightInGrams: record.weightInGrams,
                    imageUrl: record.imageUrl,
                    stream: record.stream.course,
                    level: record.stream.level,
                })
            }, 200)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'uid',
            key: 'uid',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Inventory',
            dataIndex: 'inventoryTotal',
            key: 'inventoryTotal',
            render: (text, record) => (
                <>
                    <span
                        style={{
                            background:
                                record.inventoryTotal - record.inventorySold <=
                                0
                                    ? 'red'
                                    : 'none',
                        }}
                    >
                        {record.inventoryTotal -
                            record.inventorySold +
                            ' / ' +
                            record.inventoryTotal}
                    </span>
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showInventoryModal(record)}
                    >
                        list
                    </Button>
                </>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    {record.name}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {'(' + record.code + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'MRP',
            dataIndex: 'mrp',
            key: 'mrp',
        },
        {
            title: 'Weight (gms)',
            dataIndex: 'weightInGrams',
            key: 'weightInGrams',
        },
        {
            title: 'Data',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 10 }}
                        onClick={() => showLinkedCourses(record)}
                    >
                        Linked Courses
                    </Button>
                    {record.imageUrl ? (
                        <>
                            <br />
                            <a
                                href={record.imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: 10 }}
                            >
                                Image
                            </a>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.stream
                        ? record.stream.course + ' - ' + record.stream.level
                        : ''}
                </span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Options',
            dataIndex: 'options',
            key: 'options',
            render: (text, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item>
                                <Popconfirm
                                    title={
                                        (record.archived
                                            ? 'Unarchive'
                                            : 'Archive') +
                                        ' - ' +
                                        record.name
                                    }
                                    onConfirm={() =>
                                        archiveUnarchiveBook(record)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link" style={{ padding: 0 }}>
                                        {record.archived
                                            ? 'Unarchive'
                                            : 'Archive'}
                                    </Button>
                                </Popconfirm>
                            </Menu.Item>
                            <Menu.Item>
                                <Popconfirm
                                    title={'Confirm delete - ' + record.name}
                                    onConfirm={() => deleteBook(record.uid)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link" style={{ padding: 0 }}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </Menu.Item>
                        </Menu>
                    }
                    placement="bottomCenter"
                    arrow
                >
                    <Button type="link" style={{ padding: 0, fontSize: 12 }}>
                        {'Options'}
                    </Button>
                </Dropdown>
            ),
        },
    ]

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const handleCourseChange = (value) => {
        setSelectedCourse(value)

        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
            }
        })
    }

    const addOrUpdateBook = () => {
        form.validateFields([
            'code',
            'name',
            'mrp',
            'weightInGrams',
            'imageUrl',
            'stream',
            'level',
        ])
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.BOOKS.ADD_BOOK
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.BOOKS.UPDATE_BOOK
                    values.uid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditModal()
                        getBooks()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {})
    }

    const deleteBook = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.DELETE_BOOK, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getBooks()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showInventoryModal = (record) => {
        setSelectedRecord(record)
        setInventoryModalVisible(true)
    }

    const hideInventoryModal = () => {
        setInventoryModalVisible(false)
    }

    const getSortOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={SORT_FILTER_NAME} key={SORT_FILTER_NAME}>
                {'Name'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button
                value={SORT_FILTER_INVENTORY_DESC}
                key={SORT_FILTER_INVENTORY_DESC}
            >
                {'Inventory ↑'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button
                value={SORT_FILTER_INVENTORY_ASC}
                key={SORT_FILTER_INVENTORY_ASC}
            >
                {'Inventory ↓'}
            </Radio.Button>
        )

        return options
    }

    const onChangeSortFilter = (event) => {
        setSelectedSortFilter(event.target.value)
    }

    const goToInventoryReport = () => {
        props.navigate('/book-inventory-report')
    }

    const showLinkedCourses = (record) => {
        setLinkedCourses([])
        setLinkedCoursesModalVisible(true)
        setLoadingLinkedCourses(true)
        setSelectedRecord(record)

        RestApi.doGet(GLOBAL.URL.BOOKS.GET_BOOK_LINKED_COURSES + record.uid)
            .then((res) => {
                setLinkedCourses(res.data)
            })
            .finally(() => {
                setLoadingLinkedCourses(false)
            })
    }

    const hideLinkedCourses = () => {
        setLinkedCoursesModalVisible(false)
    }

    const linkedCoursesColumns = [
        {
            title: '#',
            dataIndex: 'courseId',
            key: 'courseId',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => openCourse(record.courseId)}
                >
                    {record.name}
                </Button>
            ),
        },
    ]

    const openCourse = (courseId) => {
        window.open('/course/configure/' + courseId, '_blank')
    }

    const getFilterOptions = () => {
        var options = []
        options.push(
            <Radio.Button
                value={COURSE_FILTER_UNARCHIVED}
                key={COURSE_FILTER_UNARCHIVED}
            >
                {'Active'}
            </Radio.Button>
        )
        options.push(
            <Radio.Button
                value={COURSE_FILTER_ARCHIVED}
                key={COURSE_FILTER_ARCHIVED}
            >
                {COURSE_FILTER_ARCHIVED}
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={COURSE_FILTER_ALL} key={COURSE_FILTER_ALL}>
                {COURSE_FILTER_ALL}
            </Radio.Button>
        )

        return options
    }

    const onChangeFilter = (event) => {
        setFilter(event.target.value)
    }

    const archiveUnarchiveBook = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.BOOKS.ARCHIVE_BOOK
        if (record.archived) {
            url = GLOBAL.URL.BOOKS.UNARCHIVE_BOOK
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ uid: record.uid }))
        RestApi.doPost(url, postBody)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getBooks()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Books</h2>
            <div>
                <Button type="primary" onClick={showAddEditModal}>
                    Add
                </Button>
                <Search
                    placeholder="Search Book"
                    onSearch={(value) => setQuery(value)}
                    style={{ width: 300, marginLeft: 10 }}
                    autoFocus
                    allowClear
                />
                <Radio.Group
                    onChange={onChangeSortFilter}
                    value={selectedSortFilter}
                    style={{ marginLeft: 10 }}
                >
                    {getSortOptions()}
                </Radio.Group>
                <Button
                    type="default"
                    style={{ marginLeft: 10 }}
                    onClick={goToInventoryReport}
                >
                    Inventory Report
                </Button>
                <Radio.Group
                    onChange={onChangeFilter}
                    value={filter}
                    style={{ marginLeft: 10 }}
                >
                    {getFilterOptions()}
                </Radio.Group>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageBooks}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Configure Book'}
                open={addEditModalVisible}
                onOk={addOrUpdateBook}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Book Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Book Code"
                        name="code"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="MRP"
                        name="mrp"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Weight (grams)"
                        name="weightInGrams"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Image URL"
                        name="imageUrl"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Course Stream"
                        name="stream"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group placeholder="Select Level">
                                {levelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                </Form>
            </Modal>
            {inventoryModalVisible ? (
                <BookInventory
                    record={selectedRecord}
                    hideInventoryModal={hideInventoryModal}
                    getBooks={getBooks}
                />
            ) : null}
            <Modal
                title={'Linked Courses'}
                open={linkedCoursesModalVisible}
                onCancel={hideLinkedCourses}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedRecord.name}</h3>
                <Table
                    columns={linkedCoursesColumns}
                    dataSource={linkedCourses}
                    pagination={false}
                    size="small"
                    loading={loadingLinkedCourses}
                    rowKey="name"
                />
            </Modal>
        </div>
    )
}

export default Books
