import React, { useState, useEffect } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import {
    Table,
    Button,
    message,
    Modal,
    InputNumber,
    DatePicker,
    Tag,
    Input,
} from 'antd'
import { Utils } from '../../JS/Utils'

function UseriCash(props) {
    const userId = props.userId
    const [credits, setCredits] = useState(0)
    const [showAwardCreditsModal, setShowAwardCreditsModal] = useState(false)
    const [isAwardCredits, setIsAwardCredits] = useState(false)
    const [awardCreditsLoading, setAwardCreditsLoading] = useState(false)
    const [loadingCredits, setLoadingCredits] = useState(false)
    const [creditsHistory, setCreditsHistory] = useState([])
    const [creditsNexUrl, setCreditsNexUrl] = useState()
    const [loadingCreditsMore, setLoadingCreditsMore] = useState(false)
    const [awardCreditsDescription, setAwardCreditsDescription] = useState()
    const [awardCreditsExpiry, setAwardCreditsExpiry] = useState()
    const [awardCreditsNum, setAwardCreditsNum] = useState(0)

    useEffect(() => {
        getCredits()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCredits = () => {
        setLoadingCredits(true)

        RestApi.doGet(GLOBAL.URL.GET_USER_CREDITS + userId).then((res) => {
            setCredits(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_USER_CREDITS_HISTORY + userId)
            .then((res) => {
                var response = JSON.parse(res.data)

                setCreditsHistory(response.data)
                setCreditsNexUrl(response.next)
            })
            .finally(() => {
                setLoadingCredits(false)
            })
    }

    const getNextCreditsHistory = () => {
        if (!creditsNexUrl) {
            return
        }

        setLoadingCreditsMore(true)

        RestApi.doGet(GLOBAL.URL.BASE_URL + creditsNexUrl)
            .then((res) => {
                var response = JSON.parse(res.data)

                setCreditsHistory((preState) => {
                    return [...preState, ...response.data]
                })
                setCreditsNexUrl(response.next)
            })
            .finally(() => {
                setLoadingCreditsMore(false)
            })
    }

    const userCreditsHistoryTableColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Reward',
            dataIndex: 'reward',
            key: 'reward',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Expiry',
            dataIndex: 'expiry',
            key: 'expiry',
        },
    ]

    const hideAwardCreditsModal = () => {
        setShowAwardCreditsModal(false)
    }

    const awardCredits = () => {
        setAwardCreditsLoading(true)

        var payload = {}
        payload.userId = userId
        payload.credits = awardCreditsNum
        payload.description = awardCreditsDescription
        payload.expiry = awardCreditsExpiry

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))

        var url = GLOBAL.URL.AWARD_CREDITS_TO_USER
        if (!isAwardCredits) {
            url = GLOBAL.URL.REDEEM_CREDITS_FROM_USER
        }

        RestApi.doPost(url, postBody)
            .then((response) => {
                hideAwardCreditsModal()

                message.destroy()
                message.success(response.data)

                getCredits()
            })
            .finally(() => {
                setAwardCreditsLoading(false)
            })
    }

    return (
        <div>
            <Tag color="geekblue" style={{ marginTop: 8 }}>
                Balance: {credits}
            </Tag>
            {Utils.isUserAdmin() ? (
                <Button
                    type="default"
                    style={{ marginLeft: 10 }}
                    size="small"
                    onClick={() => {
                        setShowAwardCreditsModal(true)
                        setIsAwardCredits(true)
                    }}
                    loading={awardCreditsLoading}
                >
                    Award iCash
                </Button>
            ) : null}
            {Utils.isUserAdmin() ? (
                <Button
                    type="default"
                    style={{ marginLeft: 10 }}
                    size="small"
                    onClick={() => {
                        setShowAwardCreditsModal(true)
                        setIsAwardCredits(false)
                    }}
                    loading={awardCreditsLoading}
                >
                    Redeem iCash
                </Button>
            ) : null}
            <Table
                loading={loadingCredits}
                columns={userCreditsHistoryTableColumns}
                dataSource={creditsHistory}
                pagination={false}
                style={{ marginTop: 8 }}
            />
            {creditsNexUrl ? (
                <Button
                    type="primary"
                    onClick={getNextCreditsHistory}
                    style={{ marginTop: 8 }}
                    loading={loadingCreditsMore}
                >
                    More
                </Button>
            ) : null}
            <Modal
                open={showAwardCreditsModal}
                title={(isAwardCredits ? 'Award' : 'Redeem') + ' Credits'}
                okText={isAwardCredits ? 'Award' : 'Redeem'}
                onCancel={hideAwardCreditsModal}
                confirmLoading={awardCreditsLoading}
                onOk={awardCredits}
                destroyOnClose={true}
            >
                <h4>Credits:</h4>
                <InputNumber
                    autoFocus
                    min={1}
                    onChange={(number) => setAwardCreditsNum(number)}
                />
                <h4 style={{ marginTop: 5 }}>Description:</h4>
                <Input
                    placeholder="Description"
                    onChange={(e) => setAwardCreditsDescription(e.target.value)}
                />
                {isAwardCredits ? (
                    <>
                        <h4 style={{ marginTop: 5 }}>Expiry:</h4>
                        <DatePicker
                            showTime
                            placeholder="Select Date"
                            onChange={(value, dateString) => {
                                setAwardCreditsExpiry(dateString)
                            }}
                            format="DD-MMM-YY HH:mm"
                        />
                    </>
                ) : null}
            </Modal>
        </div>
    )
}

export default UseriCash
