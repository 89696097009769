import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, message, Modal, Input, Popconfirm } from 'antd'
import { PlusCircleTwoTone } from '@ant-design/icons'
import { goBack } from '../../Util/ILUtil'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class CouponUserMapping extends React.Component {
    state = {
        id: '',
        loading: false,
        showModal: false,
        searchUserData: [],
        data: [],
        couponName: '',
        nextUrl: '',
        userFilter: '',
    }

    componentDidMount() {
        const { id, couponName } = this.props.location.state
        this.setState({ id: id, couponName: couponName }, () => {
            this.getData()
        })
    }

    getData = () => {
        this.setState({
            loading: true,
        })

        RestApi.doGet(
            GLOBAL.URL.GET_COUPON_USER_MAPPING +
                '?couponId=' +
                this.state.id +
                '&userFilter=' +
                (this.state.userFilter ? this.state.userFilter : '')
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                this.setState({
                    data: res.data,
                    nextUrl: res.next,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    getNextPage = () => {
        if (!this.state.nextUrl) {
            return
        }

        this.setState({
            loading: true,
        })
        RestApi.doGet(GLOBAL.URL.BASE_URL + this.state.nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                this.setState({
                    data: [...this.state.data, ...res.data],
                    nextUrl: res.next,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    showSelectModal = () => {
        this.setState({ showModal: true })
    }

    hideSelectModal = () => {
        this.setState({ showModal: false, searchUserData: [] })
    }

    searchUser = (query) => {
        this.setState({
            loading: true,
        })

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                this.setState({
                    searchUserData: res.data,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    addUser = (record) => {
        this.setState({ loading: true })

        var userIds = []
        userIds.push(record.id)

        var payload = {}
        payload.id = this.state.id
        payload.userIds = userIds

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_USER_TO_COUPON, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.hideSelectModal()
                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    removeUser = (id) => {
        this.setState({ loading: true })

        var payload = {}
        payload.id = id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.REMOVE_USER_FROM_COUPON, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    removeAllUsers = () => {
        var payload = {}
        payload.id = this.state.id

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.CLEAR_USERS_FROM_COUPON, postBody)
            .then((response) => {
                message.destroy()
                message.success(response.data)

                this.getData()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            })
    }

    searchByFilter = (query) => {
        this.setState(
            {
                userFilter: query,
            },
            () => {
                this.getData()
            }
        )
    }

    render() {
        const { Search } = Input

        const columns = [
            {
                title: '#',
                dataIndex: 'name',
                key: 'name',
                width: 50,
                render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                render: (text, record) => <span>{record.user.firstName}</span>,
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                render: (text, record) => <span>{record.user.lastName}</span>,
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
                render: (text, record) => <span>{record.user.phone}</span>,
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
                render: (text, record) => <span>{record.user.email}</span>,
            },
            {
                title: 'Delete',
                dataIndex: 'delete',
                key: 'delete',
                render: (text, record) => (
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.removeUser(record.id, true)
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="link"
                            style={{
                                border: 'none',
                                color: 'red',
                                padding: 0,
                            }}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                    </Popconfirm>
                ),
            },
        ]

        const searchColumns = [
            {
                title: 'Add',
                dataIndex: 'add',
                key: 'add',
                width: 50,
                render: (text, record) => (
                    <Button
                        type="link"
                        onClick={() => {
                            this.addUser(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <PlusCircleTwoTone style={{ fontSize: 20 }} />
                    </Button>
                ),
            },
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
            },
        ]

        return (
            <div>
                <h3>
                    Coupon: '<strong>{this.state.couponName}</strong>'
                </h3>
                <div>
                    <Button
                        onClick={() => {
                            goBack(this.props.navigate)
                        }}
                        size="small"
                    >
                        Go Back
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => this.showSelectModal()}
                        size="small"
                        style={{ marginLeft: 10 }}
                    >
                        Add User
                    </Button>
                    <Popconfirm
                        title="Are you sure?"
                        onConfirm={() => {
                            this.removeAllUsers()
                        }}
                        type="danger"
                        okText="Yes"
                        cancelText="No"
                        disabled={this.state.data.length === 0}
                    >
                        <Button
                            size="small"
                            style={{ marginLeft: 10 }}
                            disabled={this.state.data.length === 0}
                        >
                            Remove All Users
                        </Button>
                    </Popconfirm>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Search
                        placeholder="Search User"
                        onSearch={(value) => this.searchByFilter(value)}
                        autoFocus
                        style={{ width: 300 }}
                    />
                </div>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={false}
                    loading={this.state.loading}
                    size="small"
                    style={{ marginTop: 10 }}
                />

                {this.state.nextUrl ? (
                    <div style={{ marginTop: 10 }}>
                        <Button
                            type="primary"
                            loading={this.state.loading}
                            onClick={this.getNextPage}
                            size="small"
                        >
                            Show More
                        </Button>
                    </div>
                ) : null}
                <Modal
                    title="Add Users"
                    open={this.state.showModal}
                    onCancel={this.hideSelectModal}
                    destroyOnClose={true}
                    width={800}
                    footer={null}
                >
                    <Search
                        placeholder="Search User"
                        onSearch={(value) => this.searchUser(value)}
                        autoFocus
                    />
                    {this.state.searchUserData.length !== 0 ? (
                        <Table
                            columns={searchColumns}
                            dataSource={this.state.searchUserData}
                            pagination={false}
                            loading={this.state.loading}
                            size="small"
                            style={{ marginTop: 8 }}
                        />
                    ) : null}
                </Modal>
            </div>
        )
    }
}

export default CouponUserMapping
