import React from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Space } from 'antd'

const MetaFormItems = () => {
    return (
        <Form.List name="meta">
            {(fields, { add, remove }) => (
                <>
                    {fields.length === 0 && add()}
                    {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                            key={key}
                            style={{
                                display: 'flex',
                                marginBottom: 0,
                            }}
                            align="baseline"
                        >
                            <Form.Item
                                label={''}
                                {...restField}
                                name={[name, 'property']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Enter property!',
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '240px' }}
                                    placeholder="Enter Property"
                                />
                            </Form.Item>
                            <Form.Item
                                label={''}
                                {...restField}
                                name={[name, 'content']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: false,
                                        message: 'Enter content!',
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '400px' }}
                                    placeholder="Enter Content"
                                />
                            </Form.Item>
                            <Form.Item label={''}>
                                {fields.length > 1 && (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(name)}
                                    />
                                )}
                            </Form.Item>
                        </Space>
                    ))}

                    <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '60%' }}
                        >
                            Add Meta
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    )
}

export default MetaFormItems
