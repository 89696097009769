import React, { useState, useEffect, useRef } from 'react'
import { message, Button, Modal, Input, Popconfirm, Radio } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useParams } from 'react-router-dom'
import { goBack } from '../../Util/ILUtil'

function NewsWithOpenComments(props) {
    const { TextArea } = Input
    const FILTER_OPEN = 'open'
    const FILTER_ALL = 'all'
    const { newsId: paramsNewsId } = useParams()

    const [newsId] = useState(paramsNewsId)
    const [data, setData] = useState([])
    const [comments, setComments] = useState([])
    const [replyModalVisible, setReplyModalVisible] = useState(false)
    const [parentId, setParentId] = useState(0)
    const [reply, setReply] = useState('')
    const [loading, setLoading] = useState(false)
    const [filter, setFilter] = useState(FILTER_OPEN)
    const inputRef = useRef()
    useEffect(() => {
        if (!newsId) {
            return
        }

        getNewsWithOpenComments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsId, filter])

    useEffect(() => {
        setTimeout(() => {
            if (replyModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [replyModalVisible, inputRef])

    const getNewsWithOpenComments = () => {
        message.loading('Updating Data', 0)

        RestApi.doGet(
            GLOBAL.URL.GET_NEWS_WITH_OPEN_COMMENTS +
                newsId +
                '?filter=' +
                filter
        )
            .then((res) => {
                setData(res.data)
                setComments(res.data.comments)
            })
            .finally(() => {
                message.destroy()
            })
    }

    const deleteComment = (id) => {
        message.loading('Deleting Comment', 0)

        var payload = {}
        payload.id = id

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_NEWS_COMMENT, body).then(
            (response) => {
                message.destroy()
                message.success(response.data)

                getNewsWithOpenComments()
            }
        )
    }

    const updateAnswered = (id, answered) => {
        message.loading('Updating Answered Status', 0)

        var url = GLOBAL.URL.MARK_NEWS_COMMENT_ANSWERED
        if (answered) {
            url = GLOBAL.URL.MARK_NEWS_COMMENT_UNANSWERED
        }

        var payload = {}
        payload.id = id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel).then((res) => {
            message.destroy()
            getNewsWithOpenComments()
        })
    }

    const showReplyModal = (parentId) => {
        setParentId(parentId)
        setReplyModalVisible(true)
        setReply('')
    }

    const hideReplyModal = () => {
        setReplyModalVisible(false)
    }

    const getCommentCard = (record, parent) => {
        return (
            <Card style={{ marginBottom: parent ? 15 : 5 }} variant="outlined">
                <CardContent style={{ padding: 8 }}>
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() =>
                            updateAnswered(record.id, record.answered)
                        }
                    >
                        {record.answered ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                    {record.comment}
                    <div style={{ marginLeft: 29 }}>
                        <a
                            href={'/userdetails/' + record.user.id}
                            style={{
                                display: 'table-cell',
                                fontSize: 12,
                                padding: 0,
                            }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {record.user.firstName} {record.user.lastName}
                        </a>
                        {' ('}
                        <span style={{ fontSize: 11 }}>{record.date}</span>
                        {')'}
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => deleteComment(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    fontSize: 9,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    </div>
                    {record.children.length > 0
                        ? record.children.map((comment) =>
                              getCommentCard(comment, false)
                          )
                        : null}
                    {parent ? (
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="primary"
                                onClick={() => showReplyModal(record.id)}
                                size="small"
                            >
                                Reply
                            </Button>
                        </div>
                    ) : null}
                </CardContent>
            </Card>
        )
    }

    const onReplyChange = (e) => {
        setReply(e.target.value)
    }

    const addComment = () => {
        message.loading('Posting your reply', 0)
        setLoading(true)

        var payload = {}
        payload.newsId = newsId
        payload.comment = reply
        payload.parentId = parentId

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.ADD_NEWS_COMMENT, body)
            .then(() => {
                message.destroy()
                hideReplyModal()
                getNewsWithOpenComments()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const markAllCommentsAnswered = () => {
        message.loading('Marking all comments as answered', 0)
        setLoading(true)

        var payload = {}
        payload.id = newsId

        let body = new FormData()
        body.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.MARK_ALL_COMMENTS_IN_NEWS_ANSWERED, body)
            .then(() => {
                message.destroy()
                getNewsWithOpenComments()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeFilter = (e) => {
        setFilter(e.target.value)
    }

    return (
        <div>
            <div>
                <Button type="default" onClick={() => goBack(props.navigate)}>
                    {'<'} Back
                </Button>
                <Radio.Group
                    onChange={onChangeFilter}
                    defaultValue={filter}
                    style={{ marginLeft: 20 }}
                >
                    <Radio.Button value={FILTER_OPEN} key={FILTER_OPEN}>
                        Open Comments
                    </Radio.Button>
                    <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                        All Comments
                    </Radio.Button>
                </Radio.Group>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => markAllCommentsAnswered()}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" style={{ marginLeft: 20 }}>
                        Mark All Comments Answered
                    </Button>
                </Popconfirm>
            </div>
            <h2 style={{ marginTop: 5 }}>{data.title}</h2>
            <h4>Comments</h4>
            {comments.map((comment) => getCommentCard(comment, true))}
            <Modal
                title="Your Reply"
                open={replyModalVisible}
                confirmLoading={loading}
                onCancel={hideReplyModal}
                onOk={addComment}
                okText="Post"
                destroyOnClose={true}
            >
                <TextArea
                    rows={4}
                    onChange={onReplyChange}
                    autoFocus
                    ref={inputRef}
                />
            </Modal>
        </div>
    )
}

export default NewsWithOpenComments
