import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Popconfirm, notification, Radio, Tag } from 'antd'
import {
    CopyOutlined,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function InAppNotifications({ routeToPage }) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [filter, setFilter] = useState('')

    useEffect(() => {
        getNotications()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const getNotications = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.NOTIFICATION.GET_NOTIFICATIONS + '?filter=' + filter
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageNotifications = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const copyDataAndCreate = (record = {}) => {
        var copiedData = {}
        if (record.title) {
            copiedData.title = record.title
            copiedData.body = record.body
            copiedData.notificationType = record.notificationType
        }

        routeToPage('/notification/add', { record: copiedData })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <span>
                    {record.status === 'Sent' ? (
                        <span style={{ fontSize: 12 }}>{record.status}</span>
                    ) : (
                        <Tag
                            color={
                                record.status === 'Open' ? 'geekblue' : 'purple'
                            }
                        >
                            {record.status}
                        </Tag>
                    )}
                </span>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 14, fontWeight: 500 }}>
                        {record.title}
                    </span>
                    <br />
                    <span style={{ fontSize: 12 }}>
                        <span>{record.body}</span>
                        <br />
                        {record.image ? (
                            <span>
                                <a
                                    href={record.image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ fontSize: 12 }}
                                >
                                    Image
                                </a>
                                <br />
                            </span>
                        ) : null}
                    </span>
                    <hr align="left" style={{ width: '25%' }} />
                    <span style={{ fontSize: 13 }}>
                        <span>
                            {'Type: '}
                            <b>{record.notificationType}</b>
                            {record.course
                                ? ' | ' +
                                  record.course +
                                  (record.level ? ' / ' + record.level : null)
                                : null}
                            {record.courseNames.length ? (
                                <>
                                    <br />
                                    {'Courses: ' + record.courseNames}
                                </>
                            ) : null}
                            {record.attemptNames.length ? (
                                <>
                                    <br />
                                    {'Attempts: ' + record.attemptNames}
                                </>
                            ) : null}
                            <br />
                            {'Route: '}
                            {record.routeData}
                        </span>
                    </span>
                </span>
            ),
        },
        {
            title: 'Scheduled Time',
            dataIndex: 'scheduledTime',
            key: 'scheduledTime',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>{record.scheduledTime}</span>
                </span>
            ),
        },
        {
            title: 'Push Only',
            dataIndex: 'pushOnly',
            key: 'pushOnly',
            width: 85,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>
                        {record.pushOnly ? 'Yes' : 'No'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Sent Time',
            dataIndex: 'sentStartTime',
            key: 'sentStartTime',
            width: 125,
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>
                    {record.sentStartTime}
                    <br />
                    {record.sentEndTime}
                </span>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: 125,
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 11 }}>
                        {record.updatedBy.firstName}
                        <br />
                        {record.updatedAt}
                    </span>
                </span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'notificationId',
            key: 'notificationId',
            render: (text, record) => (
                <>
                    {record.status === 'Open' ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteNotification(record.notificationId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                    <br />
                    <Button
                        type="link"
                        onClick={() => copyDataAndCreate(record)}
                        style={{ padding: 0 }}
                    >
                        <CopyOutlined />
                    </Button>
                </>
            ),
        },
    ]

    const deleteNotification = (notificationId) => {
        setLoading(true)

        var payload = {}
        payload.notificationId = notificationId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.NOTIFICATION.DELETE_NOTIFICATIONS, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getNotications()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const onChangeFilter = (event) => {
        setFilter(event.target.value)
    }

    return (
        <div>
            <div>
                <Button type="primary" onClick={copyDataAndCreate}>
                    Create
                </Button>
                <Radio.Group
                    onChange={onChangeFilter}
                    defaultValue={filter}
                    style={{ marginLeft: 20 }}
                >
                    <Radio.Button value={''}>All</Radio.Button>
                    <Radio.Button value={'Open'}>Open</Radio.Button>
                </Radio.Group>
                <span>
                    <Button type="link" onClick={getNotications}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                </span>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="notificationId"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageNotifications}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
        </div>
    )
}

export default InAppNotifications
