import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker } from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

function LeadsDashboardByStreamAndLeadType() {
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const dateFormat = 'DD-MMM-YYYY'

    useEffect(() => {
        getDashboard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDashboard = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.GET_LEADS_DASHBOARD_BY_STREAM_AND_LEAD_TYPE +
                '?startDate=' +
                startDate +
                '&endDate=' +
                endDate
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    return (
        <div>
            <h2>
                {'Calls Dashboard - Lead Type / Stream Split'}
                <Button type="link" onClick={getDashboard}>
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 20 }} />
                    )}
                </Button>
            </h2>
            <div style={{ marginTop: 10 }}>
                <RangePicker
                    format={dateFormat}
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateChange}
                />
            </div>
            <div
                style={{ marginTop: 10 }}
                dangerouslySetInnerHTML={{ __html: data }}
            />
        </div>
    )
}

export default LeadsDashboardByStreamAndLeadType
