import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Popconfirm,
    notification,
    Radio,
    Select,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const COMP_OFF_STATUS_PENDING_APPROVAL =
    'il_leave_comp_off_pending_for_approval'
const COMP_OFF_STATUS_APPROVED = 'il_leave_comp_off_approved'

function IlEmployeeCompOff(props) {
    const { Option } = Select

    const employees = props.employees
    const [userId, setUserId] = useState(props.userId)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [nextUrl, setNextUrl] = useState()
    const [statusFilters, setStatusFilters] = useState([])
    const [selectedCompOffStatusType, setSelectedCompOffStatusType] = useState(
        COMP_OFF_STATUS_PENDING_APPROVAL
    )

    useEffect(() => {
        getStatusFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCompOffs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompOffStatusType, userId])

    const getStatusFilters = () => {
        RestApi.doGet(GLOBAL.URL.IL.GET_COMP_OFF_STATUS_FILTERS).then(
            (response) => {
                setStatusFilters(response.data)
            }
        )
    }

    const getCompOffs = () => {
        setLoading(true)

        var url =
            GLOBAL.URL.IL.GET_COMP_OFFS +
            '?userId=' +
            (userId ? userId : '') +
            '&statusFilter=' +
            selectedCompOffStatusType

        RestApi.doGet(url)
            .then((response) => {
                var resObj = JSON.parse(response.data)
                setData(resObj.data)
                setNextUrl(resObj.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <span>
                    {record.status.keyName !== COMP_OFF_STATUS_APPROVED ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => approveCompOff(record.uid)}
                            okText="Approve"
                            cancelText="No"
                        >
                            <Button size="small" type="default">
                                Approve
                            </Button>
                        </Popconfirm>
                    ) : null}
                    {record.status.keyName ===
                    COMP_OFF_STATUS_PENDING_APPROVAL ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => rejectCompOff(record.uid)}
                            okText="Reject"
                            cancelText="No"
                        >
                            <Button
                                size="small"
                                type="default"
                                style={{ marginLeft: 10 }}
                            >
                                Reject
                            </Button>
                        </Popconfirm>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Employee',
            dataIndex: 'employee',
            key: 'employee',
            render: (text, record) => (
                <span>
                    {record.user.firstName + ' ' + record.user.lastName}
                </span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'startDate',
            key: 'startDate',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <span>{record.status.value}</span>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <>
                    {record.status.keyName !== COMP_OFF_STATUS_APPROVED ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => deleteCompOff(record.uid)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ]

    const approveCompOff = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.APPROVE_COMP_OFF, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCompOffs()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const rejectCompOff = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.REJECT_COMP_OFF, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCompOffs()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const deleteCompOff = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.DELETE_COMP_OFF, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCompOffs()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const getEmployeeOptions = () => {
        var options = []
        employees.forEach((employee) => {
            options.push(
                <Option value={employee.user.id} key={employee.user.id}>
                    {employee.user.firstName + ' ' + employee.user.lastName}
                </Option>
            )
        })

        return options
    }

    const onSelectEmployee = (value) => {
        setUserId(value)
    }

    const getFilterOptions = () => {
        var options = []

        statusFilters.forEach((row) => {
            options.push(
                <Radio.Button value={row.keyName} key={row.keyName}>
                    {row.value}
                </Radio.Button>
            )
        })

        return options
    }

    return (
        <>
            <Modal
                title={'Comp Offs'}
                open={true}
                onCancel={() => props.hideCompOffModal()}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                <div>
                    <Radio.Group
                        onChange={(e) =>
                            setSelectedCompOffStatusType(e.target.value)
                        }
                        value={selectedCompOffStatusType}
                    >
                        {getFilterOptions()}
                    </Radio.Group>
                    <Select
                        showSearch
                        allowClear={true}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 350, marginLeft: 10 }}
                        placeholder={'Select Employee'}
                        onChange={onSelectEmployee}
                        value={userId}
                    >
                        {getEmployeeOptions()}
                    </Select>
                </div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageData}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </Modal>
        </>
    )
}

export default IlEmployeeCompOff
