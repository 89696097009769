import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Table, Button, Popconfirm, Descriptions, notification } from 'antd'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

function TransactionQueue({ navigate }) {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        getQueue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getQueue = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.TRANSACTION_QUEUE.GET_TRANSACTIONS_IN_QUEUE)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const courseTableColumns = [
        {
            title: 'MRP',
            dataIndex: 'mrp',
            key: 'mrp',
            width: 100,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 100,
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 100,
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const getQueueCard = (queue, index) => {
        return (
            <Card style={{ marginBottom: 20 }} variant="outlined">
                <CardContent style={{ padding: 8 }}>
                    <h4># {index + 1}</h4>
                    <Descriptions title="" bordered size="small">
                        <Descriptions.Item label="Name">
                            {queue.user.firstName + ' ' + queue.user.lastName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                            {queue.user.email}
                        </Descriptions.Item>
                        <Descriptions.Item label="Transaction Date">
                            {queue.transactionDateString}
                        </Descriptions.Item>
                        <Descriptions.Item label="Created On">
                            {queue.createdAt}
                        </Descriptions.Item>
                        <Descriptions.Item label="Transaction ID">
                            {queue.transactionId}
                        </Descriptions.Item>
                        <Descriptions.Item label="Added By">
                            {queue.addedBy.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Updated By">
                            {queue.updatedBy.firstName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Pincode">
                            {queue.pincode.pincode +
                                ' | ' +
                                queue.pincode.state}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            {queue.type}
                        </Descriptions.Item>
                        <Descriptions.Item label="Payment Gateway" span={2}>
                            {queue.paymentGateway}
                            {queue.paymentGatewayId
                                ? ' (' + queue.paymentGatewayId + ')'
                                : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="MRP">
                            {queue.mrp}
                        </Descriptions.Item>
                        <Descriptions.Item label="Price">
                            {queue.price}
                        </Descriptions.Item>
                        <Descriptions.Item label="Discount">
                            {queue.discount} | {queue.couponName} |{' '}
                            {queue.couponValue} | {queue.couponType}
                        </Descriptions.Item>
                    </Descriptions>
                    <Table
                        columns={courseTableColumns}
                        dataSource={queue.courses}
                        pagination={false}
                        size="small"
                        loading={loading}
                        rowKey="courseName"
                        style={{ marginTop: 10 }}
                    />
                    <div style={{ marginTop: 10 }}>
                        <Button
                            size="small"
                            onClick={() =>
                                navigate('/addtransactiontoqueue', {
                                    state: { record: queue },
                                })
                            }
                        >
                            Update
                        </Button>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => {
                                approve(queue.id)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                size="small"
                                style={{ marginLeft: 20 }}
                            >
                                Approve
                            </Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => {
                                deleteTransaction(queue.id)
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="danger"
                                size="small"
                                style={{ marginLeft: 20 }}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </div>
                </CardContent>
            </Card>
        )
    }

    const approve = (id) => {
        setLoading(true)

        let payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.TRANSACTION_QUEUE.APPROVE_TRANSACTION_TO_QUEUE,
            formData
        )
            .then((res) => {
                notification['success']({
                    message: res.data,
                })

                getQueue()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const deleteTransaction = (id) => {
        setLoading(true)

        let payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.TRANSACTION_QUEUE.DELETE_TRANSACTION_TO_QUEUE,
            formData
        )
            .then((res) => {
                notification['success']({
                    message: res.data,
                })

                getQueue()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Manual Transactions Queue</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                        navigate('/addtransactiontoqueue')
                    }}
                    loading={loading}
                >
                    Add
                </Button>
            </div>
            <div style={{ marginTop: 10 }}>
                {data.map((queue, index) => getQueueCard(queue, index))}
            </div>
        </div>
    )
}

export default TransactionQueue
