import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    InputNumber,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function BookInventory(props) {
    const [form] = Form.useForm()

    const [selectedRecord] = useState(props.record)
    const [inventoryLoading, setInventoryLoading] = useState(false)
    const [inventoryData, setInventoryData] = useState([])
    const [inventoryNextUrl, setInventoryNextUrl] = useState()
    const [addEditInventoryModalVisible, setAddEditInventoryModalVisible] =
        useState(false)
    const [selectedInventoryRecord, setSelectedInventoryRecord] = useState({})
    const inputRef = useRef()

    useEffect(() => {
        getInventoryData(props.record.uid)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditInventoryModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addEditInventoryModalVisible, inputRef])

    const getInventoryData = (uid) => {
        setInventoryLoading(true)
        RestApi.doGet(GLOBAL.URL.BOOKS.GET_BOOK_INVENTORY + uid)
            .then((response) => {
                var res = JSON.parse(response.data)
                setInventoryData(res.data)
                setInventoryNextUrl(res.next)
            })
            .finally(() => {
                setInventoryLoading(false)
            })
    }

    const getNextPageInventory = () => {
        if (!inventoryNextUrl) {
            return
        }

        setInventoryLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + inventoryNextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setInventoryNextUrl(res.next)
                setInventoryData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setInventoryLoading(false)
            })
    }

    const inventoryTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 75,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditInventoryModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Cost/unit',
            dataIndex: 'pricePerUnit',
            key: 'pricePerUnit',
        },
        {
            title: 'Date',
            dataIndex: 'addedBy',
            key: 'addedBy',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.createdAt}
                    <br />
                    {record.addedBy.firstName}
                </span>
            ),
        },
        {
            title: 'Updated On',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.updatedAt}
                    <br />
                    {record.updatedBy.firstName}
                </span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteBookInventory(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddEditInventoryModal = (record = {}) => {
        setSelectedInventoryRecord(record)
        setAddEditInventoryModalVisible(true)

        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({
                    quantity: record.quantity,
                    pricePerUnit: record.pricePerUnit,
                    comments: record.comments,
                })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditInventoryModal = () => {
        setAddEditInventoryModalVisible(false)
    }

    const addOrUpdateInventory = () => {
        form.validateFields(['quantity', 'pricePerUnit', 'comments'])
            .then((values) => {
                setInventoryLoading(true)

                values.bookId = selectedRecord.uid

                var url = GLOBAL.URL.BOOKS.ADD_BOOK_INVENTORY
                if (selectedInventoryRecord.uid) {
                    url = GLOBAL.URL.BOOKS.UPDATE_BOOK_INVENTORY
                    values.uid = selectedInventoryRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddEditInventoryModal()
                        getInventoryData(selectedRecord.uid)
                        props.getBooks()
                    })
                    .catch((error) => {
                        setInventoryLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteBookInventory = (uid) => {
        setInventoryLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.DELETE_BOOK_INVENTORY, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getInventoryData(selectedRecord.uid)
            })
            .catch((error) => {
                setInventoryLoading(false)
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <>
            <Modal
                title={'Inventory Entries'}
                open={true}
                onCancel={props.hideInventoryModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h3>{selectedRecord.name}</h3>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddEditInventoryModal}
                >
                    Add
                </Button>
                <Table
                    columns={inventoryTableColumns}
                    dataSource={inventoryData}
                    pagination={false}
                    size="small"
                    loading={inventoryLoading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
                {inventoryNextUrl ? (
                    <Button
                        type="primary"
                        loading={inventoryLoading}
                        onClick={getNextPageInventory}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </Modal>
            <Modal
                title={'Configure Inventory Entry'}
                open={addEditInventoryModalVisible}
                onOk={addOrUpdateInventory}
                confirmLoading={inventoryLoading}
                onCancel={hideAddEditInventoryModal}
                okText={selectedInventoryRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Quantity"
                        name="quantity"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber autoFocus ref={inputRef} />
                    </Form.Item>
                    <Form.Item
                        label="Cost per Unit"
                        name="pricePerUnit"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default BookInventory
