import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

function Colleges() {
    const [form] = Form.useForm()
    const { Search } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [addEditModalVisible, setAddEditModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})

    const [loadingAdmins, setLoadingAdmins] = useState(false)
    const [admins, setAdmins] = useState([])
    const [adminsModalVisible, setAdminsModalVisible] = useState(false)
    const [addAdminModalVisible, setAddAdminModalVisible] = useState(false)

    const [searchUserLoading, setSearchUserLoading] = useState(false)
    const [searchUserData, setSearchUserData] = useState([])
    const inputRef = useRef()

    useEffect(() => {
        getColleges()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addEditModalVisible && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
    }, [addEditModalVisible, inputRef])

    const getColleges = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.COLLEGE.GET_COLLEGES)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddEditModal = (record = {}) => {
        setSelectedRecord(record)
        setAddEditModalVisible(true)
        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({ name: record.name })
            }, 100)
        } else {
            form.resetFields()
        }
    }

    const hideAddEditModal = () => {
        setAddEditModalVisible(false)
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            width: 60,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddEditModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditOutlined theme="twoTone" style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'College Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Admins',
            dataIndex: 'admins',
            key: 'admins',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showAdminsModal(record)}
                    loading={loadingAdmins}
                >
                    Admins
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCollege(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateCollege = () => {
        form.validateFields().then((values) => {
            setLoading(true)

            var url = GLOBAL.URL.COLLEGE.ADD_COLLEGE
            if (selectedRecord.uid) {
                url = GLOBAL.URL.COLLEGE.UPDATE_COLLEGE
                values.uid = selectedRecord.uid
            }

            var formData = new FormData()
            formData.append('payload', JSON.stringify(values))

            RestApi.doPost(url, formData)
                .then((response) => {
                    notification['success']({
                        message: response.data,
                    })

                    hideAddEditModal()
                    getColleges()
                })
                .catch((error) => {
                    setLoading(false)
                })
        })
    }

    const deleteCollege = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COLLEGE.DELETE_COLLEGE, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getColleges()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAdminsModal = (record) => {
        setSelectedRecord(record)
        setAdmins([])
        setLoadingAdmins(true)
        getAdmins(record.uid)
    }

    const hideAdminsModal = () => {
        setSelectedRecord({})
        setAdminsModalVisible(false)
        setAdmins([])
    }

    const getAdmins = (collegeUid) => {
        setLoadingAdmins(true)

        RestApi.doGet(GLOBAL.URL.COLLEGE.GET_COLLGE_ADMINS + collegeUid)
            .then((res) => {
                setAdmins(res.data)
                setAdminsModalVisible(true)
            })
            .finally(() => {
                setLoadingAdmins(false)
            })
    }

    const adminTableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() =>
                        window.open('/userdetails/' + record.user.id, '_blank')
                    }
                >
                    {record.user.firstName + ' ' + record.user.lastName}
                </Button>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Delete',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteAdmin(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddAdminModal = () => {
        setAddAdminModalVisible(true)
    }

    const hideAddAdminModal = () => {
        setAddAdminModalVisible(false)
    }

    const searchUser = (query) => {
        setSearchUserLoading(true)

        var payload = {}
        payload.query = query ? query : ''

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.SEARCH_USER, formData)
            .then((res) => {
                setSearchUserData(res.data)
            })
            .finally(() => {
                setSearchUserLoading(false)
            })
    }

    const searchUserColumns = [
        {
            title: 'Add',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => addAdmin(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="primary" size="small" loading={loadingAdmins}>
                        Add
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
    ]

    const addAdmin = (userId) => {
        setLoadingAdmins(true)

        var payload = {}
        payload.collegeUid = selectedRecord.uid
        payload.userId = userId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COLLEGE.ADD_COLLEGE_ADMIN, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideAddAdminModal()
                getAdmins(selectedRecord.uid)
            })
            .catch((error) => {
                setLoadingAdmins(false)
            })
    }

    const deleteAdmin = (uid) => {
        setLoadingAdmins(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COLLEGE.DELETE_COLLEGE_ADMIN, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getAdmins(selectedRecord.uid)
            })
            .catch((error) => {
                setLoadingAdmins(false)
            })
    }

    return (
        <div>
            <h2>Colleges</h2>
            <div>
                <Button type="primary" size="small" onClick={showAddEditModal}>
                    Add
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="name"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure College'}
                open={addEditModalVisible}
                onOk={addOrUpdateCollege}
                confirmLoading={loading}
                onCancel={hideAddEditModal}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="College Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Input autoFocus ref={inputRef} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'College Admins'}
                open={adminsModalVisible}
                destroyOnClose={true}
                width={800}
                footer={null}
                onCancel={hideAdminsModal}
            >
                <h3>{selectedRecord.name}</h3>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddAdminModal}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={adminTableColumns}
                    dataSource={admins}
                    pagination={false}
                    size="small"
                    loading={loadingAdmins}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Add Admins'}
                open={addAdminModalVisible}
                destroyOnClose={true}
                width={700}
                footer={null}
                onCancel={hideAddAdminModal}
            >
                <Search
                    placeholder="Search User"
                    onSearch={(value) => searchUser(value)}
                    autoFocus
                />
                <Table
                    columns={searchUserColumns}
                    dataSource={searchUserData}
                    pagination={false}
                    size="small"
                    loading={searchUserLoading}
                    rowKey="id"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default Colleges
