import React, { useEffect, useState, useRef } from 'react'
import { RestApi } from '../../../RestApi'
import GLOBAL from '../../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Form,
    Radio,
    Select,
    InputNumber,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { EditTwoTone } from '@ant-design/icons'
import Linkify from 'linkify-react'
import ILAttachmentFileList, {
    processClipboardAttachment,
} from '../../ILShareComponents/ILAttachmentFileList'

function UserResults(props) {
    const [form] = Form.useForm()
    const { Option } = Select
    const inputRef = useRef()
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [selectedStream, setSelectedStream] = useState()
    const [selectedLevel, setSelectedLevel] = useState()
    const [addUpdateResultsVisible, setAddUpdateResultsVisible] =
        useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [params, setParams] = useState()
    const [attempts, setAttempts] = useState([])
    const [commentsList, setCommentsList] = useState([])
    const [commentsVisible, setCommentsVisible] = useState(false)
    const [configureCommentVisible, setConfigureCommentVisible] =
        useState(false)
    const [selectedComment, setSelectedComment] = useState({})
    const [commentAttachmentFileList, setCommentAttachmentFileList] = useState(
        []
    )

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedLevel) {
            return
        }

        getResultParams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLevel])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.RESULTS.GET_RESULTS_BY_USER + props.userId)
            .then((response) => {
                setData(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Edit',
            dataIndex: 'edit',
            key: 'edit',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showConigureResult(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12 }} />
                </Button>
            ),
        },
        {
            title: 'Pass',
            dataIndex: 'pass',
            key: 'pass',
            render: (text, record) => (
                <>
                    {record.pass ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                            style={{ fontSize: 16 }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="red"
                            style={{ fontSize: 16 }}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'G1',
            dataIndex: 'passGroup1',
            key: 'passGroup1',
            render: (text, record) => (
                <>
                    {record.passGroup1 ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                            style={{ fontSize: 12 }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="red"
                            style={{ fontSize: 12 }}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'G2',
            dataIndex: 'passGroup2',
            key: 'passGroup2',
            render: (text, record) => (
                <>
                    {record.passGroup2 ? (
                        <FontAwesomeIcon
                            icon={faCheck}
                            color={GLOBAL.COLORAPP.BLUE}
                            style={{ fontSize: 12 }}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={faTimes}
                            color="red"
                            style={{ fontSize: 12 }}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>
                    {record.stream.course + ' - ' + record.stream.level}
                </span>
            ),
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (text, record) => (
                <ul style={{ paddingLeft: 10 }}>{getScores(record.scores)}</ul>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => showComments(record)}
                >
                    Comments
                </Button>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.updatedBy && record.updatedBy.firstName}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteResult(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const getScores = (record) => {
        var names = record.map((item) => (
            <li key={item.courseName}>
                {item.score + ' ~ ' + item.courseName}
            </li>
        ))

        return names
    }

    const showConigureResult = (record = {}) => {
        if (!streamData.length) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
                var streamData = JSON.parse(res.data)
                setStreamData(streamData)
            })
        }

        if (!attempts.length) {
            RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
                setAttempts(res.data)
            })
        }

        setSelectedRecord(record)
        setAddUpdateResultsVisible(true)

        if (record.uid) {
            setSelectedStream(record.stream.course)
            setSelectedLevel(record.stream.level)
            setTimeout(() => {
                form.setFieldsValue({
                    attempt: record.attempt,
                    pass: record.pass,
                    passGroup1: record.passGroup1,
                    passGroup2: record.passGroup2,
                })
            }, 100)
        } else {
            setSelectedStream()
            setSelectedLevel()
            setParams()
            setTimeout(() => {
                form.resetFields()
            }, 100)
        }
    }

    const hideConigureResult = () => {
        setAddUpdateResultsVisible(false)
    }

    const getStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getLevelOptions = () => {
        var options = []
        levelsData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeStream = (e) => {
        setSelectedLevel()

        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })

        setSelectedStream(e.target.value)
        setLevelsData(levels)
    }

    const getResultParams = () => {
        setParams()
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.RESULTS.GET_ADD_RESULT_PARAMS +
                '?course=' +
                selectedStream +
                '&level=' +
                selectedLevel
        )
            .then((response) => {
                setParams(response.data)
                setTimeout(() => {
                    var scores = {}
                    var existingScores = {}
                    if (selectedRecord.uid) {
                        selectedRecord.scores.forEach((score) => {
                            existingScores[score.courseUid] = score.score
                        })
                    }

                    response.data.courses.forEach((course) => {
                        scores[course.uid] = existingScores[course.uid]
                            ? existingScores[course.uid]
                            : 0
                    })

                    form.setFieldsValue(scores)
                }, 100)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((attempt) => {
            options.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt}
                </Option>
            )
        })

        return options
    }

    const getPassOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={true} key={'yes'}>
                Yes
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'no'}>
                No
            </Radio.Button>
        )

        return options
    }

    const getFormCourses = () => {
        const items = []

        if (!params) {
            return items
        }

        params.courses.forEach((course) => {
            items.push(
                <Form.Item
                    label={course.courseName}
                    style={{ marginBottom: 10 }}
                    name={course.uid}
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <InputNumber min={0} />
                </Form.Item>
            )
        })

        return items
    }

    const addOrUpdateResult = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var scores = []
                params.courses.forEach((course) => {
                    var courseUid = course.uid

                    var score = {}
                    score.courseUid = courseUid
                    score.score = values[courseUid]

                    scores.push(score)
                })

                values.scores = scores

                var url = GLOBAL.URL.RESULTS.ADD_RESULT
                if (selectedRecord.uid) {
                    url = GLOBAL.URL.RESULTS.UPDATE_RESULT
                    values.uid = selectedRecord.uid
                } else {
                    values.userId = props.userId
                    values.course = selectedStream
                    values.level = selectedLevel
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideConigureResult()
                        getData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteResult = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.RESULTS.DELETE_RESULT, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showComments = (record) => {
        setCommentsVisible(true)
        setSelectedRecord(record)
        getComments(record.uid)
    }

    const hidecomments = () => {
        setCommentsVisible(false)
    }

    const getComments = (uid) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.RESULTS.GET_COMMENTS + uid)
            .then((response) => {
                setCommentsList(response.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const commentsColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showConfigureComment(record)
                        }}
                        style={{ padding: 0, marginRight: 10 }}
                    >
                        <EditTwoTone style={{ fontSize: 12 }} />
                    </Button>
                    <Linkify
                        as="p"
                        options={{
                            target: '_blank',
                            rel: 'noopener noreferrer',
                        }}
                        style={{
                            marginBlockStart: '0.4em',
                            marginBlockEnd: '0.4em',
                            whiteSpace: 'pre-wrap',
                            display: 'contents',
                        }}
                    >
                        {record.comments}
                    </Linkify>
                </>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteComment(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteComment = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.RESULTS.DELETE_COMMENTS, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getComments(selectedRecord.uid)
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showConfigureComment = (record = {}) => {
        setCommentAttachmentFileList([])
        setConfigureCommentVisible(true)
        setSelectedComment(record)
        if (record.uid) {
            setTimeout(() => {
                form.setFieldsValue({ comments: record.comments })
            }, 100)
        } else {
            form.resetFields()
        }

        setTimeout(() => {
            inputRef.current.focus()
        }, 120)
    }

    const hideConfigureComment = () => {
        setConfigureCommentVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addOrUpdateComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.comments = values.comments

                var url = GLOBAL.URL.RESULTS.ADD_COMMENTS
                if (selectedComment.uid) {
                    url = GLOBAL.URL.RESULTS.UPDATE_COMMENTS
                    payload.uid = selectedComment.uid
                } else {
                    payload.resultUid = selectedRecord.uid
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                commentAttachmentFileList.forEach((file) => {
                    formData.append('file', file.originFileObj)
                })

                RestApi.doPost(url, formData)
                    .then((response) => {
                        hideConfigureComment()
                        getComments(selectedRecord.uid)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showConigureResult}
                >
                    Add Results
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="uid"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={(selectedRecord.uid ? 'Update' : 'Add') + ' Result'}
                open={addUpdateResultsVisible}
                onOk={addOrUpdateResult}
                confirmLoading={loading}
                onCancel={hideConigureResult}
                okText={selectedRecord.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal">
                    {!selectedRecord.uid && (
                        <Form.Item
                            label="Stream"
                            style={{ marginBottom: 10 }}
                            name="course"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeStream}>
                                {getStreamOptions()}
                            </Radio.Group>
                        </Form.Item>
                    )}
                    {!selectedRecord.uid &&
                    selectedStream &&
                    levelsData.length ? (
                        <Form.Item
                            label="Level"
                            style={{ marginBottom: 10 }}
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <Radio.Group
                                onChange={(e) => {
                                    setSelectedLevel(e.target.value)
                                }}
                            >
                                {getLevelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Attempt"
                        style={{ marginBottom: 10 }}
                        name="attempt"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Attempt"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            allowClear={true}
                            style={{ width: '50%' }}
                        >
                            {getAttemptOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Pass"
                        style={{ marginBottom: 10 }}
                        name="pass"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{getPassOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Pass Group1"
                        style={{ marginBottom: 10 }}
                        name="passGroup1"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{getPassOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Pass Group2"
                        style={{ marginBottom: 10 }}
                        name="passGroup2"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group>{getPassOptions()}</Radio.Group>
                    </Form.Item>
                    {getFormCourses()}
                </Form>
            </Modal>
            <Modal
                title={'Comments ~ ' + selectedRecord.attempt}
                open={commentsVisible}
                width={1000}
                onCancel={hidecomments}
                destroyOnClose={true}
                footer={null}
            >
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showConfigureComment}
                    >
                        Add Comments
                    </Button>
                </div>
                <Table
                    columns={commentsColumns}
                    dataSource={commentsList}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={(selectedComment.uid ? 'Update' : 'Add') + ' Comments'}
                open={configureCommentVisible}
                onOk={addOrUpdateComment}
                confirmLoading={loading}
                onCancel={hideConfigureComment}
                okText={selectedComment.uid ? 'Update' : 'Add'}
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="Comments"
                            autoFocus
                            onPaste={(e) =>
                                processClipboardAttachment(
                                    e,
                                    setCommentAttachmentFileList
                                )
                            }
                            ref={inputRef}
                        />
                    </Form.Item>
                </Form>
                <ILAttachmentFileList
                    attachmentFileList={commentAttachmentFileList}
                    setAttachmentFileList={setCommentAttachmentFileList}
                />
            </Modal>
        </div>
    )
}

export default UserResults
