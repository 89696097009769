import React from 'react'
import './index.css'
import './App.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store/store'
import PageRoutes from './Router/Routes'
import { BrowserRouter } from 'react-router-dom'

class App extends React.Component {
    render() {
        const { PUBLIC_URL } = process.env
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter basename={PUBLIC_URL}>
                        <PageRoutes />
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        )
    }
}

export default App
