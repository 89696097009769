import { actionTypes } from '../actions'

export const userFavoriteMenus = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_USER_FAVORITE_MENU:
            return action.payload
        default:
            return state
    }
}
