import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import CircularProgress from '@mui/material/CircularProgress'
import { Radio } from 'antd'

const FILTER_ALL = 'all'
const FILTER_OPEN = 'open'
const FILTER_CLOSED = 'closed'

function LeadsDashboard() {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [filter, setFilter] = useState(FILTER_OPEN)

    useEffect(() => {
        getDashboard()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const getDashboard = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_CART_LEADS_DASHBOARD + '?filter=' + filter)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeFilterType = (event) => {
        setFilter(event.target.value)
    }

    return (
        <div>
            <div style={{ marginBottom: 10, marginTop: 10 }}>
                <Radio.Group
                    onChange={onChangeFilterType}
                    defaultValue={filter}
                    disabled={loading}
                >
                    <Radio.Button value={FILTER_ALL} key={FILTER_ALL}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value={FILTER_OPEN} key={FILTER_OPEN}>
                        {'Open'}
                    </Radio.Button>
                    <Radio.Button value={FILTER_CLOSED} key={FILTER_CLOSED}>
                        {'Closed'}
                    </Radio.Button>
                </Radio.Group>
            </div>
            {loading ? <CircularProgress /> : null}
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    )
}

export default LeadsDashboard
