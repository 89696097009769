import React, { useState } from 'react'
import SearchModal from './components/SearchModal'
import { Button } from 'antd'

const ILSearch = () => {
    const [showSearchModal, setShowSearchModal] = useState(false)

    const onCloseSearchModal = () => {
        setShowSearchModal(false)
    }

    const onClickOpenSearch = () => {
        setShowSearchModal(true)
    }

    return (
        <>
            <Button
                type="default"
                style={{ marginLeft: 10 }}
                onClick={() => onClickOpenSearch()}
            >
                Search...
            </Button>
            <SearchModal
                open={showSearchModal}
                onCloseSearchModal={onCloseSearchModal}
            />
        </>
    )
}

export default ILSearch
