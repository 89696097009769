import React, { useState, useEffect } from 'react'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Popconfirm,
    notification,
    DatePicker,
    TimePicker,
    Form,
} from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { RestApi } from '../../RestApi'
import { EditTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'

function IlAllLogins(props) {
    const [form] = Form.useForm()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [loginDate, setLoginDate] = useState('')
    const [loginTime, setLoginTime] = useState()
    const [logoutTime, setLogoutTime] = useState()
    const [showUpdateLoginModal, setShowUpdateLoginModal] = useState(false)
    const [selectedLoginRecord, setSelectedLoginRecord] = useState()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginDate])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.IL.GET_ALL_LOGINS_BY_DATE + loginDate)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Employee',
            dataIndex: 'employee',
            key: 'employee',
            render: (text, record) => <span>{record.user.firstName}</span>,
        },
        {
            title: 'Login Duration',
            dataIndex: 'totalLoginDuration',
            key: 'totalLoginDuration',
        },
        {
            title: 'Logins',
            dataIndex: 'logins',
            key: 'logins',
            render: (text, record) => (
                <span>
                    {record.logins && record.logins.length ? (
                        <Table
                            columns={loginDayColumns}
                            dataSource={record.logins}
                            pagination={false}
                            size="small"
                            rowKey="uid"
                            showHeader={false}
                        />
                    ) : null}
                </span>
            ),
        },
    ]

    const loginDayColumns = [
        {
            title: 'Update',
            dataIndex: 'update',
            key: 'update',
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showUpdateLogingModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 12, marginLeft: 5 }} />
                </Button>
            ),
        },
        {
            title: 'Login',
            dataIndex: 'loginTime',
            key: 'loginTime',
        },
        {
            title: 'Logout',
            dataIndex: 'logoutTime',
            key: 'logoutTime',
        },
        {
            title: 'Duration',
            dataIndex: 'loginDuration',
            key: 'loginDuration',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteLoginEntry(record.uid)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showUpdateLogingModal = (record) => {
        setLoginTime(record.loginTime)
        setLogoutTime(record.logoutTime)
        setSelectedLoginRecord(record)
        setShowUpdateLoginModal(true)

        setTimeout(() => {
            var loginTimeObj = dayjs(record.loginTime, 'HH:mm')
            var logoutTimeObj = null
            if (record.logoutTime) {
                logoutTimeObj = dayjs(record.logoutTime, 'HH:mm')
            }

            setTimeout(() => {
                form.setFieldsValue({
                    loginTime: loginTimeObj,
                    logoutTime: logoutTimeObj,
                })
            }, 100)
        }, 100)
    }

    const hideUpdateLogingModal = () => {
        setShowUpdateLoginModal(false)
        setSelectedLoginRecord(null)
    }

    const deleteLoginEntry = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.DELETE_LOGIN_DATA, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const updateLoginData = () => {
        form.validateFields()
            .then((values) => {
                if (!loginTime) {
                    notification['error']({
                        message: 'Select Login Time!',
                    })
                    return
                }

                setLoading(true)

                var payload = {}
                payload.uid = selectedLoginRecord.uid
                payload.loginTime = loginTime
                payload.logoutTime = logoutTime ? logoutTime : ''

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.IL.UPDATE_LOGIN_DATA, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateLogingModal()
                        getData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const onChangeLoginTime = (time, timeString) => {
        setLoginTime(timeString)
    }

    const onChangeLogoutTime = (time, timeString) => {
        setLogoutTime(timeString)
    }

    const onChangeLoginDate = (value, dateString) => {
        setLoginDate(dateString)
    }

    return (
        <>
            <Modal
                title={'Logins'}
                open={true}
                onCancel={() => props.hideModal()}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                <DatePicker format="DD-MMM-YYYY" onChange={onChangeLoginDate} />
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Update Login Data'}
                open={showUpdateLoginModal}
                onOk={updateLoginData}
                confirmLoading={loading}
                onCancel={hideUpdateLogingModal}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                {showUpdateLoginModal ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Login Time"
                            style={{ marginBottom: 10 }}
                            name="loginTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeLoginTime}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Logout Time"
                            style={{ marginBottom: 10 }}
                            name="logoutTime"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeLogoutTime}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </>
    )
}

export default IlAllLogins
