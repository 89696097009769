import React, { useState } from 'react'
import {
    Grid,
    Typography,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Autocomplete from '@mui/material/Autocomplete'
import { AllCoursesTable } from './AllCoursesTable'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

export const Filter = ({ onFetchClick, courseFilterData, loading }) => {
    const { classes, cx } = useStyles()
    const [selectedMonth, setSelectedMonth] = useState(6)
    const [selectedCourse, setSelectedCourse] = useState([])

    const handleCourseChange = (event, newValue) => {
        setSelectedCourse(newValue)
    }

    return (
        <Grid className={classes.filterContainer}>
            <Grid
                container
                alignItems="center"
                className={classes.monthFilterContainer}
            >
                <Typography>#Months:</Typography>
                <TextField
                    variant="outlined"
                    color="primary"
                    type="number"
                    value={selectedMonth}
                    size="small"
                    onChange={(event) => setSelectedMonth(event.target.value)}
                    className={cx(
                        classes.monthFilter,
                        classes.filterRightMargin
                    )}
                    InputProps={{ inputProps: { min: 1 } }}
                />
            </Grid>
            <Autocomplete
                options={courseFilterData}
                color="primary"
                size="small"
                style={{ width: 300 }}
                getOptionLabel={(option) => option.courseName}
                onChange={handleCourseChange}
                value={selectedCourse || []}
                onInputChange={(event, newValue) => {
                    if (newValue === '') {
                        setSelectedCourse([])
                    }
                }}
                multiple={true}
                className={classes.searchBuyCourse}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Filter By Course"
                        variant={'outlined'}
                        fullWidth={true}
                    />
                )}
            />
            <Button
                onClick={() => onFetchClick(selectedMonth, selectedCourse)}
                variant="contained"
                color="primary"
                size="small"
                className={classes.fetchButton}
                startIcon={loading ? <CircularProgress size={14} /> : null}
                disabled={loading}
            >
                Fetch
            </Button>
        </Grid>
    )
}

export const CourseWatchTimeAnalyticsByMonth = ({
    data,
    fetchData,
    courseFilterData,
    options,
    tableData,
    monthLabel,
    day = null,
    loading,
    tableCourseNameColors,
}) => {
    const { classes } = useStyles()

    return Object.keys(data).length > 0 ? (
        <Grid>
            <Typography varaitn="h1" className={classes.heading}>
                Watch Time Analytics By Month
            </Typography>
            <Filter
                onFetchClick={(selectedMonth, selectedCourse) =>
                    fetchData(selectedMonth, selectedCourse)
                }
                courseFilterData={courseFilterData}
                loading={loading}
            />
            <Grid className={classes.barChartTableContainer}>
                <Grid md={8} item className={classes.scrollableWindow}>
                    <Grid className={classes.barContainer}>
                        <Bar data={data} options={options} />
                        <Typography
                            variant="subtitle2"
                            className={classes.chartNote}
                        >
                            <span className={classes.noteText}>Note</span>:
                            Click on a month to get day wise analytics
                        </Typography>
                    </Grid>
                </Grid>
                <Grid md={4} xs={12} item className={classes.rightPannelTable}>
                    <AllCoursesTable
                        tableData={tableData}
                        monthLabel={monthLabel}
                        day={day}
                        tableCourseNameColors={tableCourseNameColors}
                    />
                </Grid>
            </Grid>
        </Grid>
    ) : null
}

export const useStyles = makeStyles()((theme) => ({
    container: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    monthFilter: {
        width: 60,
        marginLeft: 7,
        '& .MuiInputBase-input': {
            padding: 7,
        },
    },
    searchBuyCourse: {
        marginRight: 10,
        '& .MuiAutocomplete-inputRoot': {
            padding: '4px 65px 4px 0px! important',
            fontSize: 14,
        },
        '& .MuiInputLabel-outlined': {
            fontSize: 14,
        },
        [theme.breakpoints.down('sm')]: {
            margin: '8px 0px',
        },
    },
    filterContainer: {
        display: 'flex',
        flexFlow: 'row',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column',
        },
    },
    filterRightMargin: {
        marginRight: 10,
    },
    monthFilterContainer: {
        width: 'fit-content',
    },
    fetchButton: {
        fontSize: 13,
        padding: '2px 8px',
        minWidth: 10,
        textTransform: 'none',
        width: 'fit-content',
        height: 'fit-content',
    },
    barContainer: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '650px!important',
            maxWidth: '650px!important',
        },
    },
    byDayBarContainer: {
        marginTop: 30,
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    scrollableWindow: {
        display: 'flex',
        flexFlow: 'row',
        [theme.breakpoints.down('sm')]: {
            overflow: 'auto',
        },
    },
    rightPannelTable: {
        marginLeft: 20,
        [theme.breakpoints.down('md')]: {
            margin: 0,
        },
    },
    chartNote: {
        marginTop: 10,
    },
    noteText: {
        fontSize: 'inherit',
        textDecoration: 'underline',
    },
    barChartTableContainer: {
        display: 'flex',
        flexFlow: 'row',
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
        },
    },
}))
