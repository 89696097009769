import { message, Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'

class UploadQuestions extends React.Component {
    state = {
        loading: false,
        resourceFilesData: [],
        fileList: [],
        subject: '',
        folder: '',
        compTestDuration: 0,
        message: '',
    }

    replaceQuestions = () => {
        if (this.state.fileList.length === 0) {
            message.error('Select file!')
            return
        }

        this.setState({ loading: true })

        var formData = new FormData()
        formData.append('file', this.state.fileList[0])
        RestApi.doPost(GLOBAL.URL.REPLACE_QUESTIONS, formData, null, false)
            .then((response) => {
                this.setState({ message: response.data })
            })
            .catch((error) => {
                this.setState({ message: error.data })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const { fileList } = this.state
        const props = {
            onRemove: (file) => {
                this.setState((state) => {
                    const index = state.fileList.indexOf(file)
                    const newFileList = state.fileList.slice()
                    newFileList.splice(index, 1)
                    return {
                        fileList: newFileList,
                    }
                })
            },
            beforeUpload: (file) => {
                this.setState({
                    fileList: [file],
                })
                return false
            },
            fileList,
        }

        return (
            <div>
                <h2>Replace Questions</h2>
                <div>
                    <u>Mandatory Columns:</u>
                    <ul>
                        <li>Question ID</li>
                        <li>Question</li>
                        <li>Difficulty</li>
                        <li>Option 1</li>
                        <li>Option 2</li>
                        <li>Option 3</li>
                        <li>Option 4</li>
                        <li>Correct Option</li>
                        <li>Feedback</li>
                    </ul>
                </div>
                <div style={{ width: 600 }}>
                    <div style={{ marginTop: 20 }}>
                        <h4>Select File:</h4>
                        <Upload {...props}>
                            <Button>
                                <UploadOutlined /> Select
                            </Button>
                        </Upload>
                    </div>
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        this.replaceQuestions()
                    }}
                    style={{ marginTop: 15 }}
                    loading={this.state.loading}
                >
                    Replace Questions
                </Button>
                {this.state.message ? (
                    <div>
                        <p style={{ color: 'blue' }}>{this.state.message}</p>
                    </div>
                ) : null}
            </div>
        )
    }
}

export default UploadQuestions
