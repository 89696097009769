import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Input,
    notification,
    Radio,
    Tag,
    Modal,
    Popconfirm,
    Select,
    InputNumber,
    Form,
} from 'antd'
import BookInventory from '../Books/BookInventory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faTimes,
    faTrashAlt,
    faCodeBranch,
} from '@fortawesome/free-solid-svg-icons'
import clone from 'clone'
import { Utils } from '../JS/Utils'
import Paper from '@mui/material/Paper'
import {
    BookTwoTone,
    EditTwoTone,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'

function Courier(props) {
    const [form] = Form.useForm()
    const { Search } = Input
    const { Option } = Select
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()
    const [statusCount, setStatusCount] = useState({})
    const [filterQuery, setFilterQuery] = useState('')
    const [filterStatus, setFilterStatus] = useState('all_open')
    const [statusList, setStatusList] = useState([])
    const [selectedRecord, setSelectedRecord] = useState({})

    const [itemsModalVisible, setItemsModalVisible] = useState(false)
    const [items, setItems] = useState([])
    const [loadingItems, setLoadingItems] = useState(false)

    const [selectedItemsRows, setSelectedItemsRows] = useState([])
    const [selectedItemsRowKeys, setSelectedItemsRowKeys] = useState([])

    const [booksInventoryModalVisible, setBooksInventoryModalVisible] =
        useState(false)
    const [labelPrinting, setLabelPrinting] = useState(false)

    const [updatePackageTypeModalVisible, setUpdatePackageTypeModalVisible] =
        useState(false)
    const [packageTypeList, setPackageTypeList] = useState([])
    const [addressModalVisible, setAddressModalVisible] = useState(false)
    const [updateAddressVisible, setUpdateAddressVisible] = useState(false)

    const [statusEnumList, setStatusEnumList] = useState([])
    const [updateStatusModalVisible, setUpdateStatusModalVisible] =
        useState(false)

    const [
        loadingUpdateCourierDetailsFromPartner,
        setLoadingUpdateCourierDetailsFromPartner,
    ] = useState(false)

    const [openItemsModalVisible, setOpenItemsModalVisible] = useState(false)
    const [openItemsList, setOpenItemsList] = useState([])
    const [openItemsLoading, setOpenItemsLoading] = useState(false)

    const [
        labelGeneratedPartnerSplitModalVisible,
        setLabelGeneratedPartnerSplitModalVisible,
    ] = useState(false)
    const [labelGeneratedPartnerSplitData, setLabelGeneratedPartnerSplitData] =
        useState()
    const [
        labelGeneratedPartnerSplitLoading,
        setLabelGeneratedPartnerSplitLoading,
    ] = useState(false)

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCouriers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterQuery, filterStatus])

    const getFilters = () => {
        RestApi.doGet(GLOBAL.URL.COURIER.GET_STATUS_LIST).then((res) => {
            setStatusList(res.data)
        })
    }

    const getCouriers = () => {
        setData([])
        setNextUrl('')

        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.COURIER.GET_COURIERS +
                '?query=' +
                filterQuery +
                '&status=' +
                filterStatus
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
                setStatusCount(JSON.parse(res.statusCount))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageCouriers = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const returnTransactionColor = (statusKey, status, packable) => {
        var color = ''
        if (statusKey === 'courier_packing') {
            if (packable) {
                color = GLOBAL.COLORAPP.DARKBLUE
            } else {
                color = GLOBAL.COLORAPP.ALIZARIN
            }
        } else if (statusKey === 'courier_pickup') {
            color = GLOBAL.COLORAPP.JAMAORANGE
        } else if (statusKey === 'courier_shipped') {
            color = GLOBAL.COLORAPP.MOONSHADOW
        } else if (statusKey === 'courier_delivered') {
            color = GLOBAL.COLORAPP.EMARALD
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }

        return (
            <Tag color={color}>
                <span style={{ fontSize: 10 }}>{status.toUpperCase()}</span>
            </Tag>
        )
    }

    const onChangeStatusFilter = (e) => {
        setFilterStatus(e.target.value)
    }

    const getStatusFilters = () => {
        var options = []
        statusList.forEach((record) => {
            options.push(
                <Radio.Button value={record.keyName} key={record.keyName}>
                    <span>{record.value}</span>
                    {statusCount[record.keyName] ? (
                        <span style={{ fontSize: 10 }}>
                            {' (' + statusCount[record.keyName] + ')'}
                        </span>
                    ) : null}
                </Radio.Button>
            )
        })

        options.push(
            <Radio.Button value="" key="all">
                <span>All</span>
                {statusCount.all ? (
                    <span style={{ fontSize: 10 }}>
                        {' (' + statusCount.all + ')'}
                    </span>
                ) : null}
            </Radio.Button>
        )

        return options
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => showUpdateStatusModal(record)}
                    >
                        {returnTransactionColor(
                            record.statusKey,
                            record.status.toUpperCase(),
                            record.packable
                        )}
                    </Button>
                )
            },
        },
        {
            title: 'ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => {
                return (
                    <span>
                        <span>
                            {record.transactionId + '-' + record.sequence}
                        </span>
                        <br />
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 12 }}
                            onClick={() => showItemsModal(record)}
                        >
                            Items List
                        </Button>
                        <Button
                            style={{ marginLeft: 10, padding: 0 }}
                            type="link"
                            onClick={() => showAddressModal(record)}
                        >
                            <BookTwoTone style={{ fontSize: 10 }} />
                        </Button>
                        {record.statusKey === 'courier_packing' ? (
                            <>
                                {Utils.isUserAdmin() ? (
                                    <Popconfirm
                                        title="Are you sure?"
                                        onConfirm={() =>
                                            deleteCourier(record.uid)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            type="link"
                                            style={{
                                                border: 'none',
                                                color: 'red',
                                                padding: 0,
                                                marginLeft: 10,
                                                fontSize: 10,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashAlt}
                                            />
                                        </Button>
                                    </Popconfirm>
                                ) : null}
                                {Utils.isCourierManager() ? (
                                    <Popconfirm
                                        title="Merge open couriers?"
                                        onConfirm={() =>
                                            mergeOpenCouriers(record.uid)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            type="link"
                                            style={{
                                                border: 'none',
                                                padding: 0,
                                                marginLeft: 10,
                                                fontSize: 10,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCodeBranch}
                                            />
                                        </Button>
                                    </Popconfirm>
                                ) : null}
                            </>
                        ) : null}
                    </span>
                )
            },
        },
        {
            title: 'Packing',
            dataIndex: 'packingLabel',
            key: 'packingLabel',
            width: 70,
            render: (text, record) => {
                return (
                    <span>
                        <Popconfirm
                            title="Click yes to print packing label"
                            onConfirm={() => printPackingLabel(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{ padding: 0, fontSize: 12 }}
                                loading={labelPrinting}
                            >
                                {record.itemsListPrinted ? (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color={GLOBAL.COLORAPP.BLUE}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color="red"
                                    />
                                )}
                                <span style={{ marginLeft: 4 }}>Packing</span>
                            </Button>
                        </Popconfirm>
                    </span>
                )
            },
        },
        {
            title: 'Type (gms)',
            dataIndex: 'packageType',
            key: 'packageType',
            width: 100,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.statusKey === 'courier_packing' ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showUpdatePackageTypeModal(record)
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone style={{ fontSize: 12 }} />
                        </Button>
                    ) : null}
                    <span style={{ marginLeft: 5 }}>
                        {record.packageType}
                        <br />
                        {'(' + record.totalWeightInGrams + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Student',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <Button
                    type="link"
                    style={{ padding: 0, fontSize: 12, textAlign: 'left' }}
                    onClick={() => {
                        props.navigate('/userdetails/' + record.user.id)
                    }}
                >
                    <span>
                        {record.user.firstName + ' ' + record.user.lastName}
                        <br />
                        {record.user.isd + '-' + record.user.phone}
                    </span>
                </Button>
            ),
        },
        {
            title: 'Shipping',
            dataIndex: 'courierLabelPrinted',
            key: 'courierLabelPrinted',
            width: 70,
            render: (text, record) => {
                return (
                    <span>
                        <Popconfirm
                            title="Click yes to print shipping label"
                            onConfirm={() => printShippingLabel(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{ padding: 0, fontSize: 12 }}
                                loading={labelPrinting}
                            >
                                {record.courierLabelPrinted ? (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color={GLOBAL.COLORAPP.BLUE}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color="red"
                                    />
                                )}
                                <span style={{ marginLeft: 4 }}>Shipping</span>
                            </Button>
                        </Popconfirm>
                    </span>
                )
            },
        },
        {
            title: 'Partner',
            dataIndex: 'courierPartner',
            key: 'courierPartner',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    <Button
                        type="link"
                        onClick={() => updateCourierDetailsFromPartner(record)}
                        style={{ padding: 0 }}
                    >
                        {loadingUpdateCourierDetailsFromPartner ? (
                            <LoadingOutlined style={{ fontSize: 10 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 10 }} />
                        )}
                    </Button>
                    {record.courierPartner}
                    <br />
                    {record.courierName}
                </span>
            ),
        },
        {
            title: 'AWB',
            dataIndex: 'awbCode',
            key: 'awbCode',
            render: (text, record) => (
                <>
                    {record.awbCode ? (
                        <span>
                            <a
                                href={
                                    'https://shiprocket.co/tracking/' +
                                    record.awbCode
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: 12 }}
                            >
                                {record.awbCode}
                            </a>
                            <br />
                            {record.freightCharges +
                                ' / ' +
                                record.freightAppliedWeight}
                        </span>
                    ) : null}
                </>
            ),
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            render: (text, record) => (
                <>
                    {record.city ? (
                        <span style={{ fontSize: 12 }}>
                            {record.city}
                            <br />
                            {record.state}
                        </span>
                    ) : null}
                </>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.createdAt}
                    <br />
                    {record.updatedAt}
                </span>
            ),
        },
    ]

    const showItemsModal = (record) => {
        setSelectedRecord(record)
        setItemsModalVisible(true)
        setItems([])
        getItems(record)
    }

    const getItems = (record) => {
        if (record.courierItems && record.courierItems.length) {
            setItems(record.courierItems)
        } else {
            setLoadingItems(true)
            RestApi.doGet(GLOBAL.URL.COURIER.GET_ITEMS + record.uid)
                .then((res) => {
                    setItems(res.data)
                })
                .finally(() => {
                    setLoadingItems(false)
                })
        }
    }

    const hideItemsModal = () => {
        setItemsModalVisible(false)
        setSelectedItemsRows([])
        setSelectedItemsRowKeys([])
    }

    const itemsTableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    {record.book.name}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {'(' + record.book.code + ')'}
                    </span>
                </span>
            ),
        },
        {
            title: 'Inventory',
            dataIndex: 'inventoryTotal',
            key: 'inventoryTotal',
            render: (text, record) => (
                <span>
                    <span
                        style={{
                            background:
                                record.book.inventoryTotal -
                                    record.book.inventorySold <=
                                0
                                    ? 'red'
                                    : 'none',
                        }}
                    >
                        {record.book.inventoryTotal -
                            record.book.inventorySold +
                            ' / ' +
                            record.book.inventoryTotal}
                    </span>
                    <br />
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12 }}
                        onClick={() => showBooksInventoryModal(record)}
                    >
                        list
                    </Button>
                </span>
            ),
        },
        {
            title: 'Weight (gms)',
            dataIndex: 'weightInGrams',
            key: 'weightInGrams',
            render: (text, record) => <span>{record.book.weightInGrams}</span>,
        },
    ]

    const showBooksInventoryModal = (record) => {
        setSelectedRecord(record)
        setBooksInventoryModalVisible(true)
    }

    const hideBooksInventoryModal = () => {
        setBooksInventoryModalVisible(false)
    }

    // eslint-disable-next-line no-unused-vars
    const downloadFile = (blobData, fileName) => {
        var blob = new Blob([blobData], {
            type: 'application/pdf',
        })
        var url = window.URL.createObjectURL(blob)

        // Download file
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }

    // eslint-disable-next-line no-unused-vars
    const printFile = (blobData) => {
        var blob = new Blob([blobData], {
            type: 'application/pdf',
        })
        var url = window.URL.createObjectURL(blob)

        // Print file
        document.querySelector('#pdf-frame').src = ''
        document.querySelector('#pdf-frame').src = url
        url = window.URL.revokeObjectURL(blob)

        window.setTimeout(function () {
            document.querySelector('#pdf-frame').contentWindow.print()
        }, 1000)
    }

    const printPackingLabel = (record) => {
        setLabelPrinting(true)

        RestApi.download(GLOBAL.URL.COURIER.GET_ITEMS_LIST_LABEL + record.uid)
            .then((response) => {
                if (response.type === 'application/json') {
                    response.text().then((res) => {
                        var resObj = JSON.parse(res)
                        console.log(res)
                        notification['error']({
                            message: resObj.data,
                        })
                    })

                    return
                }

                printFile(response)

                notification['success']({
                    message: 'Items list label printed!',
                })

                var fullData = clone(data)
                fullData.forEach((item) => {
                    if (item.uid === record.uid && !item.itemsListPrinted) {
                        item.itemsListPrinted = !item.itemsListPrinted
                        return
                    }
                })

                setData(fullData)
            })
            .finally(() => {
                setLabelPrinting(false)
            })
    }

    const printShippingLabel = (record) => {
        setLabelPrinting(true)

        RestApi.download(GLOBAL.URL.COURIER.GET_SHIPPING_LABEL + record.uid)
            .then((response) => {
                if (response.type === 'application/json') {
                    response.text().then((res) => {
                        var resObj = JSON.parse(res)
                        console.log(res)
                        notification['error']({
                            message: resObj.data,
                        })
                    })

                    return
                }

                printFile(response)

                notification['success']({
                    message: 'Shipping label printed!',
                })

                RestApi.doGet(GLOBAL.URL.COURIER.GET_COURIER + record.uid).then(
                    (res) => {
                        var fullData = clone(data)
                        fullData.forEach((item, index) => {
                            if (item.uid === record.uid) {
                                fullData[index] = res.data
                                return
                            }
                        })

                        setData(fullData)
                    }
                )
            })
            .finally(() => {
                setLabelPrinting(false)
            })
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showUpdatePackageTypeModal = (record) => {
        if (packageTypeList.length === 0) {
            RestApi.doGet(
                GLOBAL.URL.COURIER.GET_COURIER_PACKAGE_TYPE_LIST
            ).then((res) => {
                setPackageTypeList(res.data)
            })
        }

        setSelectedRecord(record)
        setUpdatePackageTypeModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                packageType: record.packageTypeEnumName,
            })
        }, 200)
    }

    const hideUpdatePackageTypeModal = () => {
        setUpdatePackageTypeModalVisible(false)
    }

    const getPackageTypeOptions = () => {
        var options = []
        packageTypeList.forEach((item) => {
            options.push(
                <Radio.Button value={item.key} key={item.key}>
                    {item.displayName}
                </Radio.Button>
            )
        })

        return options
    }

    const updatePackageType = () => {
        form.validateFields(['packageType'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.COURIER.UPDATE_COURIER_PACKAGET_TYPE,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdatePackageTypeModal()

                        RestApi.doGet(
                            GLOBAL.URL.COURIER.GET_COURIER + selectedRecord.uid
                        ).then((res) => {
                            var fullData = clone(data)
                            fullData.forEach((item, index) => {
                                if (item.uid === selectedRecord.uid) {
                                    fullData[index] = res.data
                                    return
                                }
                            })

                            setData(fullData)
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showAddressModal = (record) => {
        setSelectedRecord(record)
        setAddressModalVisible(true)
    }

    const hideAddressModal = () => {
        setAddressModalVisible(false)
        hideUpdateAddress()
    }

    const itemsRowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedItemsRows(selectedRows)
            setSelectedItemsRowKeys(selectedRowKeys)
        },
    }

    const splitCourier = () => {
        if (selectedItemsRows.length === 0) {
            notification['error']({
                message: 'Select items to proceed!',
            })

            return
        }

        setLoadingItems(true)

        var itemUids = []
        selectedItemsRows.forEach((record) => {
            itemUids.push(record.uid)
        })

        var payload = {}
        payload.courierUid = selectedRecord.uid
        payload.itemUids = itemUids

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURIER.SPLIT_COURIER, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideItemsModal()

                RestApi.doGet(
                    GLOBAL.URL.COURIER.GET_COURIER + selectedRecord.uid
                ).then((res) => {
                    var fullData = clone(data)
                    fullData.forEach((item, index) => {
                        if (item.uid === selectedRecord.uid) {
                            fullData[index] = res.data
                            return
                        }
                    })

                    setData(fullData)
                })
            })
            .finally(() => {
                setLoadingItems(false)
            })
    }

    const updateCourierDetailsFromPartner = (record) => {
        setLoadingUpdateCourierDetailsFromPartner(true)

        var payload = {}
        payload.uid = record.uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.COURIER.UPDATE_ALL_DETAILS_FROM_PARTNER,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                RestApi.doGet(GLOBAL.URL.COURIER.GET_COURIER + record.uid)
                    .then((res) => {
                        var fullData = clone(data)
                        fullData.forEach((item, index) => {
                            if (item.uid === record.uid) {
                                fullData[index] = res.data
                                return
                            }
                        })

                        setData(fullData)
                    })
                    .finally(() => {
                        setLoadingUpdateCourierDetailsFromPartner(false)
                    })
            })
            .catch(() => {
                setLoadingUpdateCourierDetailsFromPartner(false)
            })
    }

    const showUpdateStatusModal = (record) => {
        if (!Utils.isUserAdmin()) {
            return
        }

        if (statusEnumList.length === 0) {
            RestApi.doGet(GLOBAL.URL.COURIER.GET_STATUS_ENUM_LIST).then(
                (res) => {
                    setStatusEnumList(res.data)
                }
            )
        }

        setSelectedRecord(record)
        setUpdateStatusModalVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                status: record.statusKey,
            })
        }, 100)
    }

    const hideUpdateStatusModal = () => {
        setSelectedRecord({})
        setUpdateStatusModalVisible(false)
    }

    const statusOptions = () => {
        var options = []
        statusEnumList.forEach((record) => {
            options.push(
                <Option value={record.keyName} key={record.keyName}>
                    {record.value}
                </Option>
            )
        })

        return options
    }

    const updateStatus = () => {
        form.validateFields(['status'])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.COURIER.UPDATE_COURIER_STATUS,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateStatusModal()

                        RestApi.doGet(
                            GLOBAL.URL.COURIER.GET_COURIER + selectedRecord.uid
                        ).then((res) => {
                            var fullData = clone(data)
                            fullData.forEach((item, index) => {
                                if (item.uid === selectedRecord.uid) {
                                    fullData[index] = res.data
                                    return
                                }
                            })

                            setData(fullData)
                        })
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const deleteCourier = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURIER.DELETE_COURIER, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCouriers()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const mergeOpenCouriers = (uid) => {
        setLoading(true)

        var payload = {}
        payload.uid = uid

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.COURIER.MERGE_OPEN_COURIERS, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getCouriers()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const formUpdateAddressItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showUpdateAddress = () => {
        setUpdateAddressVisible(true)

        setTimeout(() => {
            form.setFieldsValue({
                firstName: selectedRecord.firstName,
                lastName: selectedRecord.lastName,
                address: selectedRecord.address,
                city: selectedRecord.city,
                pincode: selectedRecord.pincode,
                phone: selectedRecord.phone,
            })
        }, 100)
    }

    const hideUpdateAddress = () => {
        setUpdateAddressVisible(false)
    }

    const updateAddress = () => {
        form.validateFields([
            'firstName',
            'lastName',
            'address',
            'city',
            'pincode',
            'phone',
        ])
            .then((values) => {
                setLoading(true)

                values.uid = selectedRecord.uid

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.COURIER.UPDATE_COURIER_ADDRESS,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        RestApi.doGet(
                            GLOBAL.URL.COURIER.GET_COURIER + selectedRecord.uid
                        ).then((res) => {
                            var fullData = clone(data)
                            fullData.forEach((item, index) => {
                                if (item.uid === selectedRecord.uid) {
                                    fullData[index] = res.data
                                    return
                                }
                            })

                            setData(fullData)
                            setSelectedRecord(res.data)
                        })

                        hideUpdateAddress()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const showOpenItemsList = () => {
        setOpenItemsLoading(true)

        RestApi.doGet(GLOBAL.URL.COURIER.GET_OPEN_ITEMS_LIST)
            .then((res) => {
                setOpenItemsList(res.data)
                setOpenItemsModalVisible(true)
            })
            .finally(() => {
                setOpenItemsLoading(false)
            })
    }

    const hideOpenItemsList = () => {
        setOpenItemsList([])
        setOpenItemsModalVisible(false)
    }

    const openItemsTableColumn = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Open',
            dataIndex: 'count',
            key: 'count',
            width: 60,
        },
        {
            title: 'Left',
            dataIndex: 'inventoryLeft',
            key: 'inventoryLeft',
            width: 60,
            render: (text, record) => (
                <span
                    style={{
                        background:
                            record.inventoryLeft <= 0 ? 'lightcoral' : 'none',
                    }}
                >
                    {record.inventoryLeft}
                </span>
            ),
        },
        {
            title: 'Net Left',
            dataIndex: 'netInventoryLeft',
            key: 'netInventoryLeft',
            width: 60,
            render: (text, record) => (
                <span
                    style={{
                        background:
                            record.netInventoryLeft <= 0
                                ? 'lightcoral'
                                : 'none',
                    }}
                >
                    {record.netInventoryLeft}
                </span>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'inventoryTotal',
            key: 'inventoryTotal',
            width: 60,
        },
    ]

    const navigateToBooks = () => {
        props.navigate('/books')
    }

    const getLabelGeneratedPartnerSplitData = () => {
        setLabelGeneratedPartnerSplitLoading(true)

        RestApi.doGet(GLOBAL.URL.COURIER.GET_LABEL_GENERATED_PARTNER_SPLIT)
            .then((res) => {
                setLabelGeneratedPartnerSplitData(res.data)
                setLabelGeneratedPartnerSplitModalVisible(true)
            })
            .finally(() => {
                setLabelGeneratedPartnerSplitLoading(false)
            })
    }

    const hideLabelGeneratedPartnerSplitModal = () => {
        setLabelGeneratedPartnerSplitModalVisible(false)
    }

    const createMarkupWeb = (htmlContent) => {
        return { __html: htmlContent }
    }

    return (
        <div>
            <h2>Couriers</h2>
            <div>
                <Search
                    placeholder="Search"
                    onSearch={(value) => setFilterQuery(value)}
                    enterButton
                    allowClear={true}
                    style={{ width: 400 }}
                />
                <Button
                    type="default"
                    style={{ marginLeft: 20 }}
                    onClick={showOpenItemsList}
                    loading={openItemsLoading}
                >
                    Open Items
                </Button>
                <Button
                    type="default"
                    style={{ marginLeft: 20 }}
                    onClick={getLabelGeneratedPartnerSplitData}
                    loading={labelGeneratedPartnerSplitLoading}
                >
                    Label Generated Partner Split
                </Button>
                <Button
                    type="default"
                    style={{ marginLeft: 20 }}
                    onClick={navigateToBooks}
                    loading={openItemsLoading}
                >
                    Books
                </Button>
            </div>
            <Radio.Group
                onChange={onChangeStatusFilter}
                defaultValue={filterStatus}
                style={{ marginTop: 10 }}
            >
                {getStatusFilters()}
            </Radio.Group>
            <Button type="link" onClick={getCouriers}>
                {loading ? (
                    <LoadingOutlined style={{ fontSize: 20 }} />
                ) : (
                    <ReloadOutlined style={{ fontSize: 20 }} />
                )}
            </Button>
            <div>
                <Table
                    columns={tableColumns}
                    dataSource={data}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
                {nextUrl ? (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={getNextPageCouriers}
                        size="small"
                        style={{ marginTop: 10 }}
                    >
                        Show More
                    </Button>
                ) : null}
            </div>
            <Modal
                title={
                    'Items List: ' +
                    selectedRecord.transactionId +
                    '-' +
                    selectedRecord.sequence
                }
                open={itemsModalVisible}
                onCancel={hideItemsModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <h4>
                    {selectedRecord.user
                        ? selectedRecord.user.firstName +
                          ' ' +
                          selectedRecord.user.lastName
                        : null}
                </h4>
                <Table
                    columns={itemsTableColumns}
                    dataSource={items}
                    pagination={false}
                    size="small"
                    loading={loadingItems}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                    rowSelection={itemsRowSelection}
                    selectedRowKeys={selectedItemsRowKeys}
                    width={900}
                />
                {Utils.isCourierManager() ? (
                    <div style={{ marginTop: 10 }}>
                        <Popconfirm
                            title="Click yes to confirm"
                            onConfirm={splitCourier}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                tyle="outline"
                                size="small"
                                disabled={!selectedItemsRows.length}
                                loading={loadingItems}
                            >
                                Split Courier
                            </Button>
                        </Popconfirm>
                    </div>
                ) : null}
            </Modal>
            {booksInventoryModalVisible ? (
                <BookInventory
                    record={selectedRecord.book}
                    hideInventoryModal={hideBooksInventoryModal}
                />
            ) : null}
            <div>
                <iframe
                    id="pdf-frame"
                    style={{ visibility: 'hidden' }}
                    title="Printing Labels"
                ></iframe>
            </div>
            <Modal
                title={'Update Package Type'}
                open={updatePackageTypeModalVisible}
                onOk={updatePackageType}
                confirmLoading={loading}
                onCancel={hideUpdatePackageTypeModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Package Type"
                        name="packageType"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group placeholder="Select Type">
                            {getPackageTypeOptions()}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={
                    'Address: ' +
                    selectedRecord.transactionId +
                    '-' +
                    selectedRecord.sequence
                }
                open={addressModalVisible}
                onCancel={hideAddressModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <span>
                    <b>
                        {selectedRecord.firstName +
                            ' ' +
                            selectedRecord.lastName}
                    </b>
                </span>
                <br />
                <span style={{ whiteSpace: 'pre-wrap' }}>
                    {selectedRecord.address}
                </span>
                <br />
                <span>{selectedRecord.city}</span>
                <br />
                <span>
                    {selectedRecord.state + ' - ' + selectedRecord.pincode}
                </span>
                <br />
                <span>{selectedRecord.phone}</span>
                {Utils.isCourierAddressUpdate() ? (
                    <div style={{ marginTop: 10 }}>
                        {!updateAddressVisible ? (
                            <Button
                                type="default"
                                size="small"
                                onClick={showUpdateAddress}
                            >
                                Update Address
                            </Button>
                        ) : (
                            <Paper
                                elevation={2}
                                style={{ padding: 10, marginTop: 10 }}
                            >
                                <h3>Update Address</h3>
                                <Form
                                    form={form}
                                    layout="horizontal"
                                    {...formUpdateAddressItemLayout}
                                >
                                    <Form.Item
                                        label="First Name"
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <Input autoFocus />
                                    </Form.Item>
                                    <Form.Item
                                        label="Last Name"
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <TextArea rows={3} />
                                    </Form.Item>
                                    <Form.Item
                                        label="City"
                                        name="city"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Pincode"
                                        name="pincode"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            max={999999}
                                            style={{ width: 140 }}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Phone"
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Required!',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            max={9999999999}
                                            style={{ width: 140 }}
                                        />
                                    </Form.Item>
                                </Form>
                                <div style={{ marginTop: 10, marginLeft: 90 }}>
                                    <Button
                                        type="primary"
                                        onClick={updateAddress}
                                        loading={loading}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="default"
                                        style={{ marginLeft: 10 }}
                                        onClick={hideUpdateAddress}
                                        loading={loading}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Paper>
                        )}
                    </div>
                ) : null}
            </Modal>
            <Modal
                title={'Update Courier Status'}
                open={updateStatusModalVisible}
                onOk={updateStatus}
                confirmLoading={loading}
                onCancel={hideUpdateStatusModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <h4>
                    {selectedRecord.transactionId +
                        '-' +
                        selectedRecord.sequence}
                </h4>
                <h4>
                    {selectedRecord.firstName + ' ' + selectedRecord.lastName}
                </h4>
                <Form form={form}>
                    <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select placeholder="Select Status" showSearch>
                            {statusOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Open Items'}
                open={openItemsModalVisible}
                onCancel={hideOpenItemsList}
                destroyOnClose={true}
                footer={null}
            >
                <Table
                    columns={openItemsTableColumn}
                    dataSource={openItemsList}
                    pagination={false}
                    size="small"
                    rowKey="name"
                />
            </Modal>
            <Modal
                title={'Label Generated Partner Split'}
                open={labelGeneratedPartnerSplitModalVisible}
                onCancel={hideLabelGeneratedPartnerSplitModal}
                destroyOnClose={true}
                footer={null}
                width={800}
            >
                <div
                    dangerouslySetInnerHTML={createMarkupWeb(
                        labelGeneratedPartnerSplitData
                    )}
                />
            </Modal>
        </div>
    )
}

export default Courier
