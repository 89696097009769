import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    DatePicker,
    Radio,
    Input,
    Select,
    Tag,
    Modal,
    notification,
    InputNumber,
    Form,
} from 'antd'
import { EditTwoTone, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import clone from 'clone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { Link } from '@mui/material'

const OPEN = 'open'
const UNATTENDED = 'unattended'
const FOLLOW_UP = 'followup'
const CLOSED = 'closed'

function ExternalSalesOldLeads(props) {
    const [form] = Form.useForm()
    const { TextArea } = Input
    const { Option } = Select
    const { RangePicker } = DatePicker

    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState()
    const [data, setData] = useState([])
    const [count, setCount] = useState(0)
    const [adminUsers, setAdminUsers] = useState([])
    const [openStatusTypes, setOpenStatusTypes] = useState([])
    const [closedStatusTypes, setClosedStatusTypes] = useState([])
    const [leadSources, setLeadSources] = useState([])
    const [filterLeadType, setFilterLeadType] = useState('')
    const [filter, setFilter] = useState('open')
    const [selectedAsignee, setSelectedAsignee] = useState('')
    const [openOrCloseFilterType, setOpenOrCloseFilterType] = useState(true)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [updateModalVisible, setUpdateModalVisible] = useState(false)
    const [reminderDate, setReminderDate] = useState('')
    const [openOrClose, setOpenOrClose] = useState(true)
    const [leadSubTypes, setLeadSubTypes] = useState([])
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [addCallEntryModalVisible, setAddCallEntryModalVisible] =
        useState(false)
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false)
    const [allCommentsModalVisible, setAllCommentsModalVisible] =
        useState(false)
    const [allComments, setAllComments] = useState([])
    const [editReminderVisible, setEditReminderVisible] = useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')
    const [filterLevelData, setFilterLevelData] = useState([])
    const [streamCourseFilter, setStreamCourseFilter] = useState('')
    const [streamLevelFilter, setStreamLevelFilter] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [attempts, setAttempts] = useState([])
    const [selectedAttempt, setSelectedAttempt] = useState('')

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filter,
        selectedAsignee,
        filterLeadType,
        streamCourseFilter,
        streamLevelFilter,
        startDate,
        endDate,
        selectedAttempt,
    ])

    useEffect(() => {
        getFiltersData()

        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            setStreamData(JSON.parse(res.data))
        })

        RestApi.doGet(
            GLOBAL.URL.GET_UNIQUE_ATTEMPT_NAMES_FROM_USER_PROFILE
        ).then((res) => {
            setAttempts(res.data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFiltersData = () => {
        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_ASSIGNED_TO_FILTER_DATA
        ).then((res) => {
            setAdminUsers(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_OPEN_STATUS_TYPES).then(
            (res) => {
                setOpenStatusTypes(res.data)
            }
        )

        RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_CLOSED_STATUS_TYPES).then(
            (res) => {
                setClosedStatusTypes(res.data)
            }
        )

        RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_SOURCES).then((res) => {
            setLeadSources(res.data)
        })
    }

    const fetchData = () => {
        setLoading(true)

        var url =
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_OLD_LEADS +
            '?filter=' +
            filter +
            '&assignToUserId=' +
            selectedAsignee +
            '&leadType=' +
            filterLeadType +
            '&stream=' +
            streamCourseFilter +
            '&level=' +
            streamLevelFilter +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&attempt=' +
            selectedAttempt
        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData(res.data)
                setCount(res.count)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
                setCount(res.count)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeFilter = (event) => {
        setFilter(event.target.value)

        if (event.target.value === CLOSED) {
            setOpenOrCloseFilterType(false)
        } else {
            setOpenOrCloseFilterType(true)
        }
    }

    const openStatusTypeOptions = () => {
        var options = []
        openStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const closedStatusTypeOptions = () => {
        var options = []
        closedStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.id}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const onChangeFilterLeadType = (value) => {
        setFilterLeadType(value ? value : '')
    }

    const onChangeAsignee = (value) => {
        setSelectedAsignee(value ? value : '')
    }

    const showUpdateModal = (record) => {
        setSelectedRecord(record)
        setUpdateModalVisible(true)
        setReminderDate('')
        setOpenOrClose(record.open)

        if (record.stream) {
            handleCourseChange(record.stream)
        } else {
            setSelectedCourse('')
        }

        setTimeout(() => {
            var recordClone = clone(record)
            if (recordClone.open) {
                recordClone.type = recordClone.openType
                recordClone.subType = recordClone.openSubType
            } else {
                recordClone.type = recordClone.closedType
                recordClone.subType = recordClone.closedSubType
            }

            getLeadSubTypes(recordClone.type)

            if (recordClone.reminderDate) {
                setReminderDate(record.reminderDate)
                var reminderDate = dayjs(
                    recordClone.reminderDate,
                    'DD-MMM-YY HH:mm'
                )
                recordClone.reminderDate = reminderDate
            }

            if (recordClone.assignedTo && recordClone.assignedTo.id) {
                recordClone.assignToUserId = recordClone.assignedTo.id
            }

            setTimeout(() => {
                form.setFieldsValue({
                    assignToUserId: recordClone.assignToUserId,
                    type: recordClone.type,
                    subType: recordClone.subType,
                    comment: recordClone.comment,
                    reminderDate: recordClone.reminderDate,
                    source: recordClone.source,
                    stream: recordClone.stream,
                    level: recordClone.level,
                })
            }, 100)
        }, 100)
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const filterStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Option value={item.course} key={item.course}>
                    {item.course}
                </Option>
            )
        })

        return options
    }

    const filterLevelOptions = () => {
        var options = []
        filterLevelData.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const handleFilterCourseChange = (value) => {
        if (!value) {
            setFilterLevelData([])
            setStreamCourseFilter('')
            setStreamLevelFilter('')
        }

        streamData.forEach((item) => {
            if (item.course === value) {
                setFilterLevelData(item.levels)
                setStreamCourseFilter(value)
                setStreamLevelFilter('')
            }
        })
    }

    const hideUpdateModal = () => {
        setUpdateModalVisible(false)
    }

    const showAssignToModal = (record) => {
        setSelectedRecord(record)
        setAssignToModalVisible(true)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const showUpdateReminderDateModal = (record) => {
        setSelectedRecord(record)
        setEditReminderVisible(true)
        setReminderDate('')
    }

    const hideUpdateReminderDateModal = () => {
        setEditReminderVisible(false)
        setReminderDate('')
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'userId',
            key: 'userId',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            render: (text, record) => (
                <span>
                    <Button
                        type={record.open ? 'primary' : 'outline'}
                        onClick={() => {
                            showUpdateModal(record)
                        }}
                        size="small"
                        style={{ fontSize: 11 }}
                    >
                        {record.open ? 'Open' : 'Closed'}
                    </Button>
                </span>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => (
                <span>
                    <span style={{ fontSize: 12 }}>{record.date}</span>
                    <br />
                    {record.assignedTo && record.assignedTo.id ? (
                        <>
                            <Button
                                type="link"
                                onClick={() => {
                                    showAssignToModal(record)
                                }}
                                style={{ padding: 0 }}
                            >
                                <EditTwoTone style={{ fontSize: 10 }} />
                            </Button>
                            <span style={{ marginLeft: 5 }}>
                                {record.assignedTo
                                    ? record.assignedTo.firstName
                                    : ''}
                            </span>
                        </>
                    ) : (
                        <Button
                            size="small"
                            onClick={() => {
                                showAssignToModal(record)
                            }}
                            style={{ fontSize: 10, padding: 0 }}
                            type="link"
                        >
                            Assign
                        </Button>
                    )}
                </span>
            ),
        },
        {
            title: 'Cart Value',
            dataIndex: 'totalMrp',
            key: 'totalMrp',
            render: (text, record) => (
                <span>
                    {record.totalMrp}
                    <br />
                    <span style={{ fontSize: 10 }}>
                        {record.coupon && record.coupon.couponName
                            ? record.coupon.couponName +
                              ' (' +
                              record.coupon.discount +
                              record.coupon.type +
                              ')'
                            : '(No Coupon)'}
                    </span>
                </span>
            ),
        },
        {
            title: 'User',
            key: 'user',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/external-sales-lead-details/' + record.userId}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate(
                                '/external-sales-lead-details/' + record.userId
                            )
                        }}
                        underline="none"
                        style={{ fontSize: 12 }}
                    >
                        {record.name}
                        <br />
                        {record.phone ? record.isd + '-' + record.phone : ''}
                    </Link>
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/external-sales-lead-details/' + record.userId,
                                '_blank'
                            )
                        }}
                        underline="none"
                        style={{ marginLeft: 10 }}
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 10 }}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Type',
            key: 'type',
            render: (text, record) => (
                <span>
                    {record.openType || record.closedType ? (
                        <>
                            <Tag color="purple">
                                {record.open
                                    ? record.openType
                                    : record.closedType}
                            </Tag>
                            <br />
                            <Button
                                type="link"
                                style={{ padding: 0, fontSize: 11 }}
                                onClick={() => showAddCallEntryModal(record)}
                            >
                                Add Call Entry
                            </Button>
                        </>
                    ) : null}
                </span>
            ),
        },
        {
            title: 'Comment',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => (
                <>
                    {record.latestComment && record.latestComment.comments ? (
                        <div>
                            {record.latestComment &&
                            record.latestComment.comments ? (
                                <>
                                    {record.latestComment.comments}
                                    <br />
                                    <span style={{ fontSize: 10 }}>
                                        {' ('}
                                        {
                                            record.latestComment.commentedBy
                                                .firstName
                                        }
                                        {', '}
                                        {record.latestComment.date}
                                        {')'}
                                    </span>
                                </>
                            ) : null}
                        </div>
                    ) : null}
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                openAddCommentModal(record)
                            }}
                            size="small"
                            style={{ fontSize: 10, padding: 0 }}
                        >
                            Add Comment
                        </Button>
                        {record.latestComment &&
                        record.latestComment.comments ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    openAllCommentsModal(record)
                                }}
                                size="small"
                                style={{
                                    fontSize: 10,
                                    padding: 0,
                                    marginLeft: 10,
                                }}
                            >
                                All Comments
                            </Button>
                        ) : null}
                    </div>
                </>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span style={{ fontSize: 10 }}>
                    {record.stream ? record.stream + ' - ' + record.level : ''}
                    {record.attempt ? (
                        <>
                            <br />
                            {record.attempt}
                        </>
                    ) : (
                        ''
                    )}
                    {record.source ? (
                        <>
                            <br /> {'(' + record.source + ')'}
                        </>
                    ) : (
                        ''
                    )}
                </span>
            ),
        },
        {
            title: 'Follow Up',
            dataIndex: 'reminderDate',
            key: 'reminderDate',
            render: (text, record) => (
                <span>
                    {record.reminderDate ? (
                        <div style={{ marginBottom: 10, fontSize: 11 }}>
                            {record.reminderDate}
                        </div>
                    ) : null}
                    <div>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => showUpdateReminderDateModal(record)}
                            style={{ fontSize: 10, padding: 0 }}
                        >
                            Update
                        </Button>
                    </div>
                </span>
            ),
        },
    ]

    const getLeadSubTypes = (parentType) => {
        setLeadSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                parentType +
                '&parentStatusType=' +
                (openOrClose ? 'Open' : 'Closed')
        ).then((res) => {
            setLeadSubTypes(res.data)
        })
    }

    const showAddCallEntryModal = (record) => {
        setSelectedRecord(record)
        setAddCallEntryModalVisible(true)
    }

    const hideAddCallEntryModal = () => {
        setAddCallEntryModalVisible(false)
    }

    const openAddCommentModal = (record) => {
        setAddCommentModalVisible(true)
        setSelectedRecord(record)
    }

    const closeAddCommentModal = () => {
        setAddCommentModalVisible(false)
        setSelectedRecord({})
    }

    const openAllCommentsModal = (record) => {
        setLoading(true)
        setSelectedRecord(record)
        getCartLeadAllComments(record)
    }

    const getCartLeadAllComments = (record) => {
        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_LEAD_GET_ALL_COMMENTS +
                record.userId
        )
            .then((res) => {
                setAllComments(res.data)
                setAllCommentsModalVisible(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateReminderDate = () => {
        if (!reminderDate) {
            notification['error']({
                message: 'Select Date!',
            })

            return
        }

        setLoading(true)

        var payload = {}
        payload.userId = selectedRecord.userId
        payload.reminderDate = reminderDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.SALES.EXTERNAL_SALES_UPDATE_LEAD_REMINDER_DATE,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                var fullData = data
                fullData.forEach((item) => {
                    if (item.userId === selectedRecord.userId) {
                        item.reminderDate = reminderDate
                        return
                    }
                })

                setData(fullData)
                hideUpdateReminderDateModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeReminderDate = (value, dateString) => {
        setReminderDate(dateString)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addCallEntry = () => {
        form.validateFields(['callDurationMins', 'callDurationSecs'])
            .then((values) => {
                setLoading(true)

                values.userId = selectedRecord.userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_LEAD_ADD_CALL_ENTRY,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.SALES
                                .EXTERNAL_SALES_LEAD_GET_LATEST_COMMENTS +
                                selectedRecord.userId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.latestComment = latestComment.data
                                        return
                                    }
                                })

                                setData(fullData)
                                hideAddCallEntryModal()
                            })
                            .finally(() => {
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const addComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = selectedRecord.userId
                payload.comments = values.comments

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_ADD_LEAD_COMMENTS,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.SALES
                                .EXTERNAL_SALES_LEAD_GET_LATEST_COMMENTS +
                                selectedRecord.userId
                        )
                            .then((latestComment) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.latestComment = latestComment.data
                                        return
                                    }
                                })

                                setData(fullData)
                            })
                            .finally(() => {
                                closeAddCommentModal()
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const allCommentsColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.callDurationMins + ':' + record.callDurationSecs}
                </span>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
    ]

    const hideAllCommentsModal = (record) => {
        setAllCommentsModalVisible(false)
        setAllComments([])
    }

    const addCommentFromAllCommentsModal = () => {
        hideAllCommentsModal()
        openAddCommentModal(selectedRecord)
    }

    const assignLead = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                setLoading(true)

                values.userId = selectedRecord.userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_ASSIGN_LEAD,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_DETAILS +
                                selectedRecord.userId
                        )
                            .then((res) => {
                                var fullData = data
                                fullData.forEach((item) => {
                                    if (item.userId === selectedRecord.userId) {
                                        item.assignedTo =
                                            res.data.cart.assignedTo
                                        return
                                    }
                                })

                                setData(fullData)
                                hideAssignToModal()
                                notification['success']({
                                    message: response.data,
                                })
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const onOpenCloseChange = (e) => {
        setOpenOrClose(e.target.value)
    }

    const openCloseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={true} key={'open'}>
                Open
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'close'}>
                Close
            </Radio.Button>
        )

        return options
    }

    const onChangeLeadType = (e) => {
        getLeadSubTypes(e.target.value)
    }

    const leadSubTypeOptions = () => {
        var options = []
        leadSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const leadSourceOptions = () => {
        var options = []
        leadSources.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const updateLead = () => {
        form.validateFields([
            'type',
            'subType',
            'assignToUserId',
            'comment',
            'source',
            'callDurationMins',
            'callDurationSecs',
            'stream',
            'level',
        ])
            .then((values) => {
                setLoading(true)

                if (!values.subType) {
                    values.subType = ''
                }

                values.userId = selectedRecord.userId
                values.open = openOrClose
                values.reminderDate = reminderDate

                if (!values.stream) {
                    values.stream = ''
                    values.level = ''
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_UPDATE_LEAD,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateModal()

                        RestApi.doGet(
                            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_DETAILS +
                                selectedRecord.userId
                        )
                            .then((res) => {
                                var fullData = clone(data)
                                fullData.forEach((item, index) => {
                                    if (item.userId === selectedRecord.userId) {
                                        fullData[index] = res.data.cart
                                        return
                                    }
                                })

                                setData(fullData)
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const onChangeAttemptFilter = (value) => {
        setSelectedAttempt(value ? value : '')
    }

    return (
        <div>
            <div>
                <Radio.Group onChange={onChangeFilter} defaultValue={filter}>
                    <Radio.Button value={OPEN} key={OPEN}>
                        Open
                    </Radio.Button>
                    <Radio.Button value={UNATTENDED} key={UNATTENDED}>
                        Unattended
                    </Radio.Button>
                    <Radio.Button value={FOLLOW_UP} key={FOLLOW_UP}>
                        Follow Up
                    </Radio.Button>
                    <Radio.Button value={CLOSED} key={CLOSED}>
                        Closed
                    </Radio.Button>
                </Radio.Group>
                <Button type="link" onClick={fetchData}>
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 20 }} />
                    )}
                </Button>
                <span style={{ marginLeft: 20 }}>Leads Count: {count}</span>
            </div>
            <div style={{ marginTop: 10 }}>
                <Select
                    placeholder="Lead Type"
                    showSearch
                    allowClear={true}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 200 }}
                    onChange={onChangeFilterLeadType}
                >
                    {openOrCloseFilterType
                        ? openStatusTypeOptions()
                        : closedStatusTypeOptions()}
                </Select>
                <Select
                    placeholder="Filter By Asignee"
                    showSearch
                    allowClear={true}
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 200, marginLeft: 10 }}
                    onChange={onChangeAsignee}
                >
                    {getAdminUserOptions()}
                </Select>
                <Select
                    placeholder="Stream Course"
                    onChange={(value) => handleFilterCourseChange(value)}
                    showSearch
                    style={{ width: 200, marginLeft: 10 }}
                    allowClear={true}
                >
                    {filterStreamOptions()}
                </Select>
                {streamCourseFilter ? (
                    <Select
                        placeholder="Stream level"
                        showSearch
                        style={{ width: 200, marginLeft: 10 }}
                        value={streamLevelFilter}
                        onChange={(value) =>
                            setStreamLevelFilter(value ? value : '')
                        }
                        allowClear={true}
                    >
                        {filterLevelOptions()}
                    </Select>
                ) : null}
                <RangePicker
                    format="DD-MMM-YY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateFilterChange}
                    style={{ marginLeft: 10, width: 250 }}
                />
                <span style={{ marginLeft: 20 }}>
                    <Select
                        placeholder="Attempt"
                        showSearch
                        style={{ width: 150 }}
                        allowClear={true}
                        onChange={onChangeAttemptFilter}
                    >
                        {getAttemptOptions()}
                    </Select>
                </span>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="userId"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    More Data
                </Button>
            ) : null}
            <Modal
                title={'Set Follow Up Date'}
                open={editReminderVisible}
                onOk={updateReminderDate}
                confirmLoading={loading}
                onCancel={hideUpdateReminderDateModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <div>
                    Student: <strong>{selectedRecord.name}</strong>
                    {selectedRecord.reminderDate ? (
                        <div style={{ marginTop: 10 }}>
                            Current follow up:{' '}
                            <strong>{selectedRecord.reminderDate}</strong>
                        </div>
                    ) : null}
                </div>
                <div style={{ marginTop: 20 }}>
                    <span style={{ marginRight: 10 }}>Select date: </span>
                    <DatePicker
                        placeholder="Select Date"
                        format="DD-MMM-YY HH:mm"
                        onChange={onChangeReminderDate}
                    />
                </div>
            </Modal>
            <Modal
                title={'Add Call Entry'}
                open={addCallEntryModalVisible}
                onOk={addCallEntry}
                confirmLoading={loading}
                onCancel={hideAddCallEntryModal}
                okText={'Add'}
                destroyOnClose={true}
            >
                <h4>{selectedRecord.name}</h4>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Call Mins"
                        name="callDurationMins"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        name="callDurationSecs"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Comment'}
                open={addCommentModalVisible}
                onOk={addComment}
                confirmLoading={loading}
                onCancel={closeAddCommentModal}
                okText={'Add Comment'}
                destroyOnClose={true}
                width={800}
            >
                <span>
                    Student: <strong>{selectedRecord.name}</strong>
                </span>
                <Form
                    form={form}
                    layout="horizontal"
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <TextArea placeholder="Comments" autoFocus />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Comments'}
                open={allCommentsModalVisible}
                onOk={() => {
                    addComment()
                }}
                onCancel={hideAllCommentsModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <span>
                    Student: <strong>{selectedRecord.name}</strong>
                </span>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        style={{ marginTop: 10 }}
                        onClick={addCommentFromAllCommentsModal}
                    >
                        Add Comment
                    </Button>
                </div>
                <Table
                    columns={allCommentsColumns}
                    dataSource={allComments}
                    pagination={false}
                    size="small"
                    style={{ marginTop: 10 }}
                    rowKey="id"
                    loading={loading}
                />
            </Modal>
            <Modal
                title={'Assign Lead'}
                open={assignToModalVisible}
                onOk={assignLead}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                {selectedRecord.assignedTo ? (
                    <div style={{ marginBottom: 10 }}>
                        Current assignee:{' '}
                        <strong>{selectedRecord.assignedTo.firstName}</strong>
                    </div>
                ) : null}
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Lead'}
                open={updateModalVisible}
                onOk={updateLead}
                confirmLoading={loading}
                onCancel={hideUpdateModal}
                okText={'Update'}
                destroyOnClose={true}
                width={1000}
            >
                <div style={{ marginLeft: 113, marginBottom: 24 }}>
                    <span>Lead Status: </span>
                    <Radio.Group
                        onChange={onOpenCloseChange}
                        value={openOrClose}
                    >
                        {openCloseOptions()}
                    </Radio.Group>
                </div>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Lead Type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeLeadType}>
                            {openOrClose
                                ? openStatusTypeOptions()
                                : closedStatusTypeOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {leadSubTypes.length > 0 ? (
                        <Form.Item
                            label="Lead Sub Type"
                            name="subType"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{leadSubTypeOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Reminder"
                        name="reminderDate"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            showTime
                            format="DD-MMM-YY HH:mm"
                            onChange={onChangeReminderDate}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Source"
                        name="source"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{leadSourceOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Course Stream"
                        name="stream"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group placeholder="Select Level">
                                {levelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Call Mins"
                        name="callDurationMins"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        name="callDurationSecs"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={59} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Comment"
                        name="comment"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Comment" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ExternalSalesOldLeads
