import React, { useRef } from 'react'
import { searchStyles } from './styles'
import { Box, InputBase, IconButton, CircularProgress } from '@mui/material'
import { Close, Search } from '@mui/icons-material'

const SearchHeadingContainer = ({
    searchInput,
    handleSearchInputChange,
    searchResultsLength,
    handleOnClearInput,
    resultsLoading,
    handleSearchSubmit,
    moreResultsLoading,
    onClickClose,
}) => {
    const { classes } = searchStyles()
    const inputRef = useRef(null)

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            inputRef.current.blur()
            handleSearchSubmit()
        }
    }
    return (
        <Box className={classes.dialogHeadContainer}>
            <InputBase
                sx={{ flex: 1 }}
                placeholder="What are you looking for?"
                onChange={handleSearchInputChange}
                value={searchInput}
                autoFocus={!searchResultsLength}
                inputRef={inputRef}
                onKeyDown={handleKeyPress}
                className={classes.input}
            />
            {searchResultsLength > 0 ? (
                <IconButton sx={{ p: 1 }} onClick={handleOnClearInput}>
                    <Close className={classes.clearIcn} />
                </IconButton>
            ) : null}
            <IconButton
                type="button"
                sx={{ p: '10px' }}
                aria-label="search"
                onClick={() => handleSearchSubmit()}
                disabled={resultsLoading || moreResultsLoading}
            >
                {resultsLoading ? (
                    <CircularProgress size={16} />
                ) : (
                    <Search className={classes.modalSearchIcn} />
                )}
            </IconButton>
        </Box>
    )
}

export default SearchHeadingContainer
