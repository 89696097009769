import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Radio,
    Tag,
    Popconfirm,
    notification,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Link } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faExternalLinkAlt,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Utils } from '../JS/Utils'

function BookTransactions(props) {
    const { Search } = Input

    const [filterQuery, setFilterQuery] = useState('')
    const [filterStatus, setFilterStatus] = useState(
        'book_transaction_address_confirmation'
    )
    const [transactionStatusList, setTransactionStatusList] = useState([])

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [nextUrl, setNextUrl] = useState()

    const [transactionItemsLoading, setTransactionItemsLoading] =
        useState(false)
    const [transactionItemsModalVisible, setTransactionItemsModalVisible] =
        useState(false)
    const [transactionItems, setTransactionItems] = useState([])
    const [selectedTransactionId, setSelectedTransactionId] = useState()
    const [selectedRecord, setSelectedRecord] = useState({ user: {} })
    const [updateStatusModalVisible, setUpdateStatusModalVisible] =
        useState(false)

    useEffect(() => {
        getFilters()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getTransactions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterQuery, filterStatus])

    const getFilters = () => {
        RestApi.doGet(GLOBAL.URL.BOOKS.GET_BOOK_TRANSACTION_STATUS_LIST).then(
            (res) => {
                setTransactionStatusList(res.data)
            }
        )
    }

    const getTransactions = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.BOOKS.GET_BOOK_TRANSACTIONS +
                '?query=' +
                filterQuery +
                '&status=' +
                filterStatus
        )
            .then((response) => {
                var res = JSON.parse(response.data)
                setData(res.data)
                setNextUrl(res.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageTransactions = () => {
        if (!nextUrl) {
            return
        }

        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const returnTransactionColor = (statusKey, status) => {
        var color = ''
        if (statusKey === 'book_transaction_address_confirmation') {
            color = GLOBAL.COLORAPP.SUNFLOWER
        } else if (statusKey === 'book_transaction_open') {
            color = GLOBAL.COLORAPP.JAMAORANGE
        } else if (statusKey === 'book_transaction_shipping') {
            color = GLOBAL.COLORAPP.APPBLUE
        } else if (statusKey === 'book_transaction_done') {
            color = GLOBAL.COLORAPP.EMARALD
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }

        return (
            <Tag color={color}>
                <span style={{ fontSize: 10 }}>{status.toUpperCase()}</span>
            </Tag>
        )
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <>
                        {Utils.isUserAdmin() &&
                        record.statusKey === 'book_transaction_done' ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    showUpdateStatusModal(record)
                                }}
                                style={{ padding: 0, marginRight: 5 }}
                            >
                                <EditOutlined
                                    theme="twoTone"
                                    style={{ fontSize: 12 }}
                                />
                            </Button>
                        ) : null}
                        <Button type="link" style={{ padding: 0 }}>
                            {returnTransactionColor(
                                record.statusKey,
                                record.status.toUpperCase()
                            )}
                        </Button>
                    </>
                )
            },
        },
        {
            title: 'Transactions Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => (
                <span>
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/transactions/transactiondetails/' +
                                    record.transactionId,
                                '_blank'
                            )
                        }}
                        underline="none"
                    >
                        <Button type="link" style={{ padding: 0 }}>
                            <span>
                                {record.transactionId}
                                <br />
                                <span style={{ fontSize: 12 }}>
                                    {record.createdAt}
                                </span>
                            </span>
                        </Button>
                    </Link>
                    <Link
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/transactions/transactiondetails/' +
                                    record.transactionId,
                                '_blank'
                            )
                        }}
                        underline="none"
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 10 }}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Books List',
            dataIndex: 'books',
            key: 'books',
            render: (text, record) => {
                return (
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() =>
                            showTransactionsItemsModal(record.transactionId)
                        }
                        loading={transactionItemsLoading}
                    >
                        {'Books List'}
                    </Button>
                )
            },
        },
        {
            title: 'Student',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => (
                <Link
                    href={'/userdetails/' + record.user.id}
                    onClick={(event) => {
                        event.preventDefault()
                        props.navigate('/userdetails/' + record.user.id)
                    }}
                    underline="none"
                >
                    <Button
                        type="link"
                        style={{ padding: 0, fontSize: 12, textAlign: 'left' }}
                    >
                        {record.user.firstName + ' ' + record.user.lastName}
                        <br />
                        {record.user.isd + '-' + record.user.phone}
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Last Update',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.updatedAt}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <>
                    {Utils.isUserAdmin() ? (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteTransaction(record.transactionId)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                style={{
                                    border: 'none',
                                    color: 'red',
                                    padding: 0,
                                }}
                            >
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    ) : null}
                </>
            ),
        },
    ]

    const onChangeStatusFilter = (e) => {
        setFilterStatus(e.target.value)
    }

    const getStatusFilters = () => {
        var options = []
        options.push(
            <Radio.Button value="" key="all">
                All
            </Radio.Button>
        )

        transactionStatusList.forEach((record) => {
            options.push(
                <Radio.Button value={record.keyName} key={record.keyName}>
                    {record.value}
                </Radio.Button>
            )
        })

        return options
    }

    const showTransactionsItemsModal = (transactionId) => {
        setSelectedTransactionId(transactionId)
        getTransactionItems(transactionId)
    }

    const hideTransactionsItemsModal = () => {
        setTransactionItemsModalVisible(false)
    }

    const getTransactionItems = (transactionId) => {
        setTransactionItemsLoading(true)
        setTransactionItems([])

        RestApi.doGet(
            GLOBAL.URL.BOOKS.GET_BOOK_TRANSACTION_ITEMS + transactionId
        )
            .then((res) => {
                setTransactionItems(res.data)
                setTransactionItemsModalVisible(true)
            })
            .finally(() => {
                setTransactionItemsLoading(false)
            })
    }

    const deleteTransactionItem = (bookName) => {
        setTransactionItemsLoading(true)

        var payload = {}
        payload.transactionId = selectedTransactionId
        payload.bookName = bookName

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.DELETE_BOOK_TRANSACTION_ITEM, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTransactionItems(selectedTransactionId)
            })
            .catch((error) => {
                setTransactionItemsLoading(false)
            })
    }

    const transactionItemsColumns = [
        {
            title: '#',
            dataIndex: 'num',
            key: 'num',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Book Name',
            dataIndex: 'bookName',
            key: 'bookName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteTransactionItem(record.bookName)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const deleteTransaction = (transactionId) => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.BOOKS.DELETE_BOOK_TRANSACTION, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getTransactions()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showUpdateStatusModal = (record) => {
        setSelectedRecord(record)
        setUpdateStatusModalVisible(true)
    }

    const hideUpdateStatusModal = () => {
        setSelectedRecord({ user: {} })
        setUpdateStatusModalVisible(false)
    }

    const updateStatusToAddressConfirmation = (transactionId) => {
        setLoading(true)

        var payload = {}
        payload.transactionId = transactionId

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.BOOKS
                .UPDATE_BOOK_TRANSACTION_STATUS_TO_ADDRESS_CONFIRMATION,
            formData
        )
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                hideUpdateStatusModal()
                getTransactions()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Book Transactions</h2>
            <div>
                <Search
                    placeholder="Search By Email / Phone / Transaction ID"
                    onSearch={(value) => setFilterQuery(value)}
                    enterButton
                    allowClear={true}
                    style={{ width: 400 }}
                />
            </div>
            <Radio.Group
                onChange={onChangeStatusFilter}
                defaultValue={filterStatus}
                style={{ marginTop: 10 }}
            >
                {getStatusFilters()}
            </Radio.Group>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="transactionId"
                style={{ marginTop: 10 }}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageTransactions}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    Show More
                </Button>
            ) : null}
            <Modal
                title={'Books List'}
                open={transactionItemsModalVisible}
                onCancel={hideTransactionsItemsModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                <Table
                    columns={transactionItemsColumns}
                    dataSource={transactionItems}
                    pagination={false}
                    size="small"
                    loading={transactionItemsLoading}
                    rowKey="uid"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={'Update Satus'}
                open={updateStatusModalVisible}
                onCancel={hideUpdateStatusModal}
                destroyOnClose={true}
                width={800}
                footer={null}
            >
                {updateStatusModalVisible ? (
                    <>
                        <h4>{selectedRecord.transactionId}</h4>
                        <h4>
                            {selectedRecord.user.firstName +
                                ' ' +
                                selectedRecord.user.lastName}
                        </h4>
                        <h4>
                            {selectedRecord.user.isd +
                                '-' +
                                selectedRecord.user.phone}
                        </h4>
                        <div style={{ marginTop: 10 }}>
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() =>
                                    updateStatusToAddressConfirmation(
                                        selectedRecord.transactionId
                                    )
                                }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" loading={loading}>
                                    Change to Address Confirmation
                                </Button>
                            </Popconfirm>
                        </div>
                    </>
                ) : null}
            </Modal>
        </div>
    )
}

export default BookTransactions
