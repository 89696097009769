import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button, Select, Radio } from 'antd'
import StatsConstants from './StatsConstants'
import clone from 'clone'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ChartDataLabels
)

const FILTER_REVENUE_MRP = 'mrp'
const FILTER_REVENUE_NET_AFTER_GST = 'net_after_gst'
const FILTER_REVENUE_BEFORE_AFTER_GST = 'net_before_gst'
const FILTER_REVENUE_DISCOUNT = 'discount'

class FacultyWiseStats extends React.Component {
    state = {
        loading: false,
        streamData: [],
        levelsData: [],
        limit: 6,
        course: '',
        level: '',
        indOrFullSubject: 'both',
        purchaseOrExtension: 'both',
        data: [],
        mapData: StatsConstants.INITIAL_BAR_OPTIONS,
        options: {},
        courseNames: [],
        selectedCourseNames: [],
        attempts: [],
        selectedAttempts: [],
        facultyData: [],
        selectedFacultyId: '',
        filterRevenue: FILTER_REVENUE_MRP,
    }

    componentDidMount() {
        this.getStreamCourses()
        this.getCourseNames()
        this.getAttempts()
        this.getFaculties()
    }

    getStreamCourses() {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            var streamData = JSON.parse(res.data)
            this.setState({
                streamData: streamData,
            })
        })
    }

    getCourseNames = () => {
        var url =
            GLOBAL.URL.GET_COURSE_NAMES +
            '?course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject

        RestApi.doGet(url).then((res) => {
            this.setState({
                courseNames: res.data,
            })
        })
    }

    getAttempts = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            this.setState({
                attempts: res.data,
            })
        })
    }

    getEncodedSelectedCourseNames = () => {
        var response = ''
        this.state.selectedCourseNames.forEach((course) => {
            if (response) {
                response = response + ','
            }

            response = response + encodeURIComponent(course)
        })

        return response
    }

    getEncodedSelectedAttemptNames = () => {
        var response = ''
        this.state.selectedAttempts.forEach((attempt) => {
            if (response) {
                response = response + ','
            }

            response = response + encodeURIComponent(attempt)
        })

        return response
    }

    getFaculties = () => {
        RestApi.doGet(GLOBAL.URL.FACULTY.GET_ALL_FACULTY).then((res) => {
            this.setState({
                facultyData: res.data,
            })
        })
    }

    getData = () => {
        this.setState({
            loading: true,
        })

        var url =
            GLOBAL.URL.GET_FACULTY_SALES_MONTH_WISE +
            '?limit=' +
            this.state.limit +
            '&facultyId=' +
            this.state.selectedFacultyId +
            '&course=' +
            this.state.course +
            '&level=' +
            this.state.level +
            '&indOrFullSubject=' +
            this.state.indOrFullSubject +
            '&courseNames=' +
            this.getEncodedSelectedCourseNames() +
            '&attemptNames=' +
            this.getEncodedSelectedAttemptNames() +
            '&purchaseOrExtension=' +
            this.state.purchaseOrExtension

        RestApi.doGet(url)
            .then((res) => {
                this.setState(
                    {
                        data: res.data,
                    },
                    () => {
                        this.drawChart()
                    }
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart() {
        var labels = []
        var count = []
        var totalMrp = []
        var netAfterGST = []
        var netBeforeGST = []
        var discount = []

        this.state.data.forEach((row) => {
            labels.push(row.label)
            count.push(row.count)
            totalMrp.push(row.totalMrp)
            netAfterGST.push(row.netAfterGST)
            netBeforeGST.push(row.netBeforeGST)
            discount.push(row.discount)
        })

        var data = []
        var dataLabel = ''
        if (this.state.filterRevenue === FILTER_REVENUE_MRP) {
            data = totalMrp
            dataLabel = 'Revenue'
        } else if (this.state.filterRevenue === FILTER_REVENUE_NET_AFTER_GST) {
            data = netAfterGST
            dataLabel = 'Net After GST'
        } else if (
            this.state.filterRevenue === FILTER_REVENUE_BEFORE_AFTER_GST
        ) {
            data = netBeforeGST
            dataLabel = 'Net Before GST'
        } else if (this.state.filterRevenue === FILTER_REVENUE_DISCOUNT) {
            data = discount
            dataLabel = 'Discount'
        }

        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = data
        revenueDataSet.label = dataLabel
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.datalabels = { display: true }

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = count
        countDataSet.label = 'Count'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'
        countDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = clone(StatsConstants.CHART_OPTIONS_TWO_LEVELS)
        options.plugins.title.text = 'Revenue'

        this.setState({
            mapData: mapData,
            options: options,
        })

        document
            .getElementById('facultyChart')
            .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    onChangeLimit = (value) => {
        this.setState({
            limit: value,
        })
    }

    onChangeStream = (e) => {
        var levels = []
        this.state.streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        this.setState(
            {
                course: e.target.value,
                levelsData: levels,
                level: '',
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeLevel = (e) => {
        this.setState(
            {
                level: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangeIndOrFullSubject = (e) => {
        this.setState(
            {
                indOrFullSubject: e.target.value,
            },
            () => {
                this.getCourseNames()
            }
        )
    }

    onChangePurchaseOrExtension = (e) => {
        this.setState({
            purchaseOrExtension: e.target.value,
        })
    }

    onChangeCourseName = (value) => {
        this.setState({ selectedCourseNames: value })
    }

    onClearCourseName = () => {
        this.setState({ selectedCourseNames: [] })
    }

    onChangeAttemptsName = (value) => {
        this.setState({ selectedAttempts: value })
    }

    onChangeFaculty = (value) => {
        this.setState({ selectedFacultyId: value })
    }

    onClearAttempts = () => {
        this.setState({ selectedAttempts: [] })
    }

    handleRevenueFilterChange = (event) => {
        this.setState({ filterRevenue: event.target.value }, () => {
            this.drawChart()
        })
    }

    render() {
        const { Option } = Select

        var streamOptions = []
        this.state.streamData.forEach((item) => {
            streamOptions.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        streamOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var levelOptions = []
        this.state.levelsData.forEach((item) => {
            levelOptions.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        levelOptions.push(
            <Radio.Button value="" key={'None'}>
                {'None'}
            </Radio.Button>
        )

        var courseOptions = []
        this.state.courseNames.forEach((item) => {
            courseOptions.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        var attemptOptions = []
        this.state.attempts.forEach((attempt) => {
            attemptOptions.push(
                <Option value={attempt.attempt} key={attempt.attempt}>
                    {attempt.attempt +
                        ' (' +
                        (attempt.validityString
                            ? attempt.validityString
                            : attempt.allowedDays + ' Days') +
                        ')'}
                </Option>
            )
        })

        var facultyOptions = []
        this.state.facultyData.forEach((item) => {
            facultyOptions.push(
                <Option
                    value={item.id}
                    key={item.firstName + ' ' + item.lastName}
                >
                    {item.firstName + ' ' + item.lastName}
                </Option>
            )
        })

        return (
            <div>
                <h3>Faculty Wise Revenue</h3>
                <div style={{ marginTop: 10 }}>
                    <Select
                        showSearch
                        placeholder="Select Faculty"
                        style={{ width: 500 }}
                        onChange={this.onChangeFaculty}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {facultyOptions}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    {this.state.streamData.length > 0 ? (
                        <div>
                            <Radio.Group onChange={this.onChangeStream}>
                                {streamOptions}
                            </Radio.Group>
                            {this.state.course ? (
                                <Radio.Group
                                    onChange={this.onChangeLevel}
                                    value={this.state.level}
                                    style={{ marginLeft: 10 }}
                                >
                                    {levelOptions}
                                </Radio.Group>
                            ) : null}
                        </div>
                    ) : null}
                    <div style={{ marginTop: 10, float: 'left' }}>
                        <Radio.Group
                            onChange={this.onChangeIndOrFullSubject}
                            value={this.state.indOrFullSubject}
                        >
                            <Radio.Button value="both" key={'both'}>
                                {'Both'}
                            </Radio.Button>
                            <Radio.Button value="full" key={'full'}>
                                {'Full Subjects'}
                            </Radio.Button>
                            <Radio.Button value="ind" key={'ind'}>
                                {'Individual Modules'}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 600 }}
                        allowClear={true}
                        onChange={this.onChangeCourseName}
                        onClear={this.onClearCourseName}
                        mode="multiple"
                    >
                        {courseOptions}
                    </Select>
                </div>
                <div>
                    <span># Months:</span>
                    <InputNumber
                        min={1}
                        defaultValue={6}
                        onChange={this.onChangeLimit}
                    />
                    <Radio.Group
                        onChange={this.onChangePurchaseOrExtension}
                        value={this.state.purchaseOrExtension}
                        style={{ marginLeft: 10 }}
                    >
                        <Radio.Button value="both" key={'both'}>
                            {'Both'}
                        </Radio.Button>
                        <Radio.Button value="purchase" key={'purchase'}>
                            {'Purchase'}
                        </Radio.Button>
                        <Radio.Button value="extension" key={'extension'}>
                            {'Extension'}
                        </Radio.Button>
                    </Radio.Group>
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        style={{ marginTop: 10, marginLeft: 10, width: 450 }}
                        allowClear={true}
                        onChange={this.onChangeAttemptsName}
                        onClear={this.onClearAttempts}
                        mode="multiple"
                    >
                        {attemptOptions}
                    </Select>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Button
                        type="primary"
                        onClick={this.getData}
                        loading={this.state.loading}
                        style={{ marginRight: 10 }}
                    >
                        Fetch
                    </Button>
                    {this.state.data.length > 0 && !this.state.loading ? (
                        <Radio.Group
                            onChange={this.handleRevenueFilterChange}
                            defaultValue={this.state.filterRevenue}
                        >
                            <Radio.Button value={FILTER_REVENUE_MRP}>
                                Gross
                            </Radio.Button>
                            <Radio.Button value={FILTER_REVENUE_NET_AFTER_GST}>
                                Net After GST
                            </Radio.Button>
                            <Radio.Button
                                value={FILTER_REVENUE_BEFORE_AFTER_GST}
                            >
                                Net Before GST
                            </Radio.Button>
                            <Radio.Button value={FILTER_REVENUE_DISCOUNT}>
                                Discount
                            </Radio.Button>
                        </Radio.Group>
                    ) : null}
                </div>
                <div style={{ marginTop: 10 }}>
                    <Bar
                        id="facultyChart"
                        data={this.state.mapData}
                        width={800}
                        height={400}
                        options={this.state.options}
                    />
                </div>
            </div>
        )
    }
}

export default FacultyWiseStats
