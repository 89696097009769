import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Radio,
    DatePicker,
    Switch,
    Select,
    Modal,
    notification,
    Input,
    Form,
} from 'antd'
import {
    CheckOutlined,
    CloseOutlined,
    LoadingOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { Link } from '@mui/material'

function SignUpLeads(props) {
    const { RangePicker } = DatePicker
    const { Option } = Select
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const streamType = params.get('stream')
    const levelType = params.get('level')
    const startDateQuery = params.get('startDate')
    const endDateQuery = params.get('endDate')
    const withoutPhone = params.get('includeWithoutPhone')
    const osType = params.get('os')
    const existingLeads = params.get('includeExistingLeads')
    const mcqTests = params.get('includeMcqTests')
    const includeMcqTestsQuery = mcqTests === 'true' ? true : false
    const freeCourseMins = params.get('includeFreeCourseMins')
    const freeCoursesWatched = freeCourseMins === 'true' ? true : false
    const attemptType = params.get('attempt')

    const [loading, setLoading] = useState(false)
    const [nextUrl, setNextUrl] = useState()
    const [data, setData] = useState([])
    const [stream, setStream] = useState(streamType || '')
    const [level, setLevel] = useState(levelType || '')
    const [startDate, setStartDate] = useState(startDateQuery || '')
    const [endDate, setEndDate] = useState(endDateQuery || '')
    const [includeWithoutPhone, setIncludeWithoutPhone] = useState(
        Boolean(withoutPhone) || false
    )
    const [os, setOs] = useState(osType || '')
    const [includeExistingLeads, setIncludeExistingLeads] = useState(
        Boolean(existingLeads) || false
    )
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [includeMcqTests, setIncludeMcqTests] = useState(
        includeMcqTestsQuery || false
    )
    const [includeFreeCourseMins, setIncludeFreeCourseMins] = useState(
        freeCoursesWatched || false
    )
    const [attempts, setAttempts] = useState([])
    const [attempt, setAttempt] = useState(attemptType || '')
    const [selectedRecord, setSelectedRecord] = useState({})
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [adminUsers, setAdminUsers] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [bulkAssignModalVisible, setBulkAssignModalVisible] = useState(false)
    const [phoneEndingWith, setPhoneEndingWith] = useState('')
    const [emailStartingWith, setEmailStartingWith] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getFiltersData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (stream && streamData) {
            streamData.forEach((item) => {
                if (item.course === stream) {
                    setLevelData(item.levels)
                    return
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamData])

    useEffect(() => {
        if (location.pathname !== '/leads/signup') return
        const queryParams = new URLSearchParams()
        queryParams.set('stream', stream)
        if (stream) queryParams.set('level', level)
        queryParams.set('includeWithoutPhone', includeWithoutPhone)
        queryParams.set('os', os)
        queryParams.set('includeExistingLeads', includeExistingLeads)
        if (startDate && endDate.length > 0)
            queryParams.set('startDate', startDate)
        if (endDate && endDate.length > 0) queryParams.set('endDate', endDate)
        queryParams.set('includeMcqTests', includeMcqTests)
        queryParams.set('includeFreeCourseMins', includeFreeCourseMins)
        if (attempt) queryParams.set('attempt', attempt)
        navigate({ search: queryParams.toString() })
        // eslint-disable-next-line
    }, [
        stream,
        level,
        includeWithoutPhone,
        includeExistingLeads,
        os,
        endDate,
        startDate,
        includeMcqTests,
        includeFreeCourseMins,
        attempt,
        navigate,
    ])

    const getFiltersData = () => {
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })

        RestApi.doGet(
            GLOBAL.URL.GET_UNIQUE_ATTEMPT_NAMES_FROM_USER_PROFILE
        ).then((res) => {
            setAttempts(res.data)
        })
    }

    const fetchData = () => {
        setLoading(true)
        setData([])

        var url =
            GLOBAL.URL.LEADS.GET_SIGN_UP_LEADS +
            '?stream=' +
            stream +
            '&level=' +
            level +
            '&startDate=' +
            startDate +
            '&endDate=' +
            endDate +
            '&includeWithoutPhone=' +
            includeWithoutPhone +
            '&os=' +
            os +
            '&includeExistingLeads=' +
            includeExistingLeads +
            '&includeMcqTests=' +
            includeMcqTests +
            '&includeFreeCourseMins=' +
            includeFreeCourseMins +
            '&attempt=' +
            attempt +
            '&phoneEndingWith=' +
            phoneEndingWith +
            '&emailStartingWith=' +
            emailStartingWith

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getNextPageData = () => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.BASE_URL + nextUrl)
            .then((response) => {
                var res = JSON.parse(response.data)
                setNextUrl(res.next)
                setData((preState) => {
                    return [...preState, ...res.data]
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAssignToModal = (record) => {
        setSelectedRecord(record)
        setAssignToModalVisible(true)

        if (adminUsers.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
                setAdminUsers(res.data)
            })
        }
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const assignLead = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                setLoading(true)

                values.userId = selectedRecord.userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.ASSIGN_CART_LEAD, formData)
                    .then((response) => {
                        fetchData()
                        hideAssignToModal()
                        notification['success']({
                            message: response.data,
                        })

                        var url = '/userdetails/' + selectedRecord.userId
                        window.open(url, '_blank')
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const tableColumns = [
        {
            title: '#',
            dataIndex: 'index',
            key: 'index',
            render: (text, record, index) => (
                <span style={{ fontSize: 12 }}>{index + 1}</span>
            ),
        },
        {
            title: 'User',
            dataIndex: 'userId',
            key: 'userId',
            render: (text, record) => (
                <span>
                    <Link
                        href={'/userdetails/' + record.userId}
                        onClick={(event) => {
                            event.preventDefault()
                            navigate('/userdetails/' + record.userId)
                        }}
                        underline="none"
                    >
                        {record.firstName + ' ' + record.lastName}
                        <br />
                        {record.phone ? record.isd + '-' + record.phone : ''}
                    </Link>
                    <Link
                        href={'/userdetails/' + record.userId}
                        onClick={(event) => {
                            event.preventDefault()
                            window.open(
                                '/userdetails/' + record.userId,
                                '_blank'
                            )
                        }}
                        underline="none"
                        target="_blank"
                        style={{ marginLeft: 10 }}
                    >
                        <Button
                            type="link"
                            style={{ padding: 0, fontSize: 10 }}
                        >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Assign',
            dataIndex: 'assign',
            key: 'assign',
            render: (text, record) => (
                <Button
                    size="small"
                    onClick={() => {
                        showAssignToModal(record)
                    }}
                    style={{ fontSize: 10, padding: 0 }}
                    type="link"
                >
                    Assign
                </Button>
            ),
        },
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {record.stream.course
                        ? record.stream.course + ' - ' + record.stream.level
                        : ''}
                    <br />
                    {record.attempt}
                </span>
            ),
        },
        {
            title: 'OS',
            dataIndex: 'os',
            key: 'os',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.os}</span>
            ),
        },
        {
            title: 'MCQ Tests',
            dataIndex: 'mcqTestsStarted',
            key: 'mcqTestsStarted',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {includeMcqTests
                        ? record.mcqTestsStarted
                            ? record.mcqTestsCompleted +
                              ' / ' +
                              record.mcqTestsStarted
                            : '-'
                        : ''}
                </span>
            ),
        },
        {
            title: 'Free Course Mins',
            dataIndex: 'freeCourseMinsWatched',
            key: 'freeCourseMinsWatched',
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>
                    {includeFreeCourseMins
                        ? record.freeCourseMinsWatched
                            ? record.freeCourseMinsWatched
                            : '-'
                        : ''}
                </span>
            ),
        },
        {
            title: 'Joined At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <>
                    <span style={{ fontSize: 12 }}>{record.createdAt}</span>
                    <br />
                    <span style={{ fontSize: 10 }}>{record.email}</span>
                </>
            ),
        },
    ]

    const onChangeStreamCourse = (event) => {
        setStream(event.target.value)
        setLevelData([])
        streamData.forEach((item) => {
            if (item.course === event.target.value) {
                setLevelData(item.levels)
                return
            }
        })
    }

    const onChangeStreamLevel = (event) => {
        setLevel(event.target.value)
    }

    const getStreamCourseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value="" key={'all'}>
                {'All'}
            </Radio.Button>
        )
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const getStreamLevelOptions = () => {
        var options = []
        if (!stream) {
            return options
        }

        options.push(
            <Radio.Button value="" key={'all'}>
                {'All'}
            </Radio.Button>
        )
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onDateFilterChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const onChangeOs = (event) => {
        setOs(event.target.value)
    }

    const onChangeIncludeWithoutPhone = (event) => {
        setIncludeWithoutPhone(event.target.value)
    }

    const onChangeIncludeExistingLeads = (event) => {
        setIncludeExistingLeads(event.target.value)
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        return options
    }

    const onChangeAttemptFilter = (value) => {
        setAttempt(value ? value : '')
    }

    const getAdminUserOptions = () => {
        var options = []
        adminUsers.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows)
            setSelectedRowKeys(selectedRowKeys)
        },
    }

    const showBulkAssignModal = () => {
        setBulkAssignModalVisible(true)

        if (adminUsers.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_ADMIN_USERS).then((res) => {
                setAdminUsers(res.data)
            })
        }
    }

    const hideBulkAssignModal = () => {
        setBulkAssignModalVisible(false)
    }

    const bulkAssignLeads = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                if (selectedRows.length === 0) {
                    notification['error']({
                        message: 'No leads selected',
                    })
                    return
                }

                setLoading(true)

                var userIds = []
                selectedRows.forEach((item) => {
                    userIds.push(item.userId)
                })

                values.userIds = userIds

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.BULK_ASSIGN_CART_LEAD, formData)
                    .then((response) => {
                        fetchData()
                        hideBulkAssignModal()
                        notification['success']({
                            message: response.data,
                        })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <div>
                <Radio.Group onChange={onChangeStreamCourse} value={stream}>
                    {getStreamCourseOptions()}
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeStreamLevel}
                    style={{ marginLeft: 20 }}
                    value={level}
                >
                    {getStreamLevelOptions()}
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <RangePicker
                    format="DD-MMM-YY"
                    placeholder={['Start Date', 'End Date']}
                    onChange={onDateFilterChange}
                    value={
                        startDate && endDate
                            ? [dayjs(startDate), dayjs(endDate)]
                            : null
                    }
                    style={{ width: 410 }}
                />
                <Radio.Group
                    onChange={onChangeOs}
                    value={os}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value="" key={'all'}>
                        {'All'}
                    </Radio.Button>
                    <Radio.Button value="android" key={'android'}>
                        {'Android'}
                    </Radio.Button>
                    <Radio.Button value="web" key={'web'}>
                        {'Web'}
                    </Radio.Button>
                    <Radio.Button value="ios" key={'ios'}>
                        {'iOS'}
                    </Radio.Button>
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeIncludeWithoutPhone}
                    value={includeWithoutPhone}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={false} key={'hasphone'}>
                        {'Has Phone'}
                    </Radio.Button>
                    <Radio.Button value={true} key={'all'}>
                        {'All'}
                    </Radio.Button>
                </Radio.Group>
                <Radio.Group
                    onChange={onChangeIncludeExistingLeads}
                    value={includeExistingLeads}
                    style={{ marginLeft: 10 }}
                >
                    <Radio.Button value={false} key={'newleads'}>
                        {'New Leads'}
                    </Radio.Button>
                    <Radio.Button value={true} key={'all'}>
                        {'All'}
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>
                    <Input
                        style={{ width: 200 }}
                        placeholder="Phone Ending (, separated)"
                        onChange={(e) => setPhoneEndingWith(e.target.value)}
                        onPressEnter={fetchData}
                    />
                </span>
                <span style={{ marginLeft: 10 }}>
                    <Input
                        style={{ width: 200 }}
                        placeholder="Email Starting (, separated)"
                        onChange={(e) => setEmailStartingWith(e.target.value)}
                        onPressEnter={fetchData}
                    />
                </span>
                <span>
                    <span style={{ marginLeft: 10 }}>MCQs Taken</span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onClick={(enabled) => setIncludeMcqTests(enabled)}
                        disabled={loading}
                        checked={includeMcqTests}
                        style={{ marginLeft: 10 }}
                    />
                </span>
                <span style={{ marginLeft: 10 }}>
                    <span>Free Courses</span>
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onClick={(enabled) => setIncludeFreeCourseMins(enabled)}
                        disabled={loading}
                        checked={includeFreeCourseMins}
                        style={{ marginLeft: 10 }}
                    />
                </span>
                <span style={{ marginLeft: 10 }}>
                    <Select
                        placeholder="Attempt"
                        showSearch
                        style={{ width: 150 }}
                        allowClear={true}
                        onChange={onChangeAttemptFilter}
                        value={attempt || undefined}
                    >
                        {getAttemptOptions()}
                    </Select>
                </span>
            </div>
            <div style={{ marginTop: 10 }}>
                <Button
                    type="default"
                    disabled={selectedRows.length === 0}
                    onClick={showBulkAssignModal}
                >
                    Bulk Assign
                </Button>
                <span style={{ marginLeft: 10 }}>
                    <Button type="link" onClick={fetchData}>
                        {loading ? (
                            <LoadingOutlined style={{ fontSize: 20 }} />
                        ) : (
                            <ReloadOutlined style={{ fontSize: 20 }} />
                        )}
                    </Button>
                </span>
            </div>
            <Table
                columns={tableColumns}
                dataSource={data}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="userId"
                style={{ marginTop: 10 }}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
            />
            {nextUrl ? (
                <Button
                    type="primary"
                    loading={loading}
                    onClick={getNextPageData}
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    More Data
                </Button>
            ) : null}
            <Modal
                title={'Assign Lead'}
                open={assignToModalVisible}
                onOk={assignLead}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Bulk Assign'}
                open={bulkAssignModalVisible}
                onOk={bulkAssignLeads}
                confirmLoading={loading}
                onCancel={hideBulkAssignModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                <Form layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAdminUserOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default SignUpLeads
