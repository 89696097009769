import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Radio, Select, Form, Popconfirm } from 'antd'
import { makeStyles } from 'tss-react/mui'

function AttemptStats(props) {
    const [form] = Form.useForm()
    const { Option } = Select
    const { classes } = useStyles()

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()
    const [streamData, setStreamData] = useState([])
    const [attempts, setAttempts] = useState([])

    const [targetStream, setTargetStream] = useState('')
    const [targetLevel, setTargetLevel] = useState('')
    const [targetLevels, setTargetLevels] = useState([])
    const [lastStream, setLastStream] = useState('')
    const [lastLevel, setLastLevel] = useState('')
    const [lastLevels, setLastLevels] = useState([])
    const [nextStream, setNextStream] = useState('')
    const [nextLevel, setNextLevel] = useState('')
    const [nextLevels, setNextLevels] = useState([])
    const [nextAttempt, setNextAttempt] = useState('')
    const [message, setMessage] = useState()

    useEffect(() => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttempts(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE_USER).then((res) => {
            var streamData = JSON.parse(res.data)
            setStreamData(streamData)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getStreamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeTargetStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        setTargetStream(e.target.value)
        setTargetLevels(levels)
    }

    const getTargetLevelOptions = () => {
        var options = []
        targetLevels.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const getAttemptOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item.attempt} key={item.attempt}>
                    {item.attempt}
                </Option>
            )
        })

        return options
    }

    const onChangeLastStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        setLastStream(e.target.value)
        setLastLevels(levels)
    }

    const getLastLevelOptions = () => {
        var options = []
        lastLevels.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const onChangeNextStream = (e) => {
        var levels = []
        streamData.forEach((row) => {
            if (row.course === e.target.value) {
                levels = row.levels
            }
        })
        setNextStream(e.target.value)
        setNextLevels(levels)
    }

    const getNextLevelOptions = () => {
        var options = []
        nextLevels.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const fetchStats = () => {
        form.validateFields()
            .then((values) => {
                setMessage('')
                setLoading(true)

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.STATS.GET_PURCHASE_STATS_BY_ATTEMPT,
                    body
                )
                    .then((res) => {
                        setTargetLevel(values.level)
                        setLastLevel(values.lastLevel)
                        setNextAttempt(values.nextAttempt)
                        setNextLevel(values.nextLevel)
                        setData(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const getHeader = (col1, col2, col3, col4) => {
        return (
            <tr>
                <th className={classes.cell}>{col1}</th>
                <th className={classes.cell}>{col2}</th>
                <th className={classes.cell}>{col3}</th>
                <th className={classes.cell}>{col4}</th>
            </tr>
        )
    }

    const getRow = (col1, col2, col3, col4) => {
        return (
            <tr>
                <td className={classes.cell}>{col1}</td>
                <td className={classes.cell}>{col2}</td>
                <td className={classes.cell}>{col3}</td>
                <td className={classes.cell}>{col4}</td>
            </tr>
        )
    }

    const showStats = () => {
        if (!data) {
            return null
        }

        return (
            <table className={classes.table}>
                <thead>
                    {getHeader('Type', 'Purchases', 'Students', 'Revenue')}
                </thead>
                <tbody>
                    <tr>
                        <th className={classes.cell} colSpan="4">
                            Target Stream / Attempt Split
                        </th>
                    </tr>
                    {getRow(
                        'Extensions',
                        data.extensionCount,
                        data.extensionStudentsCount,
                        data.extensionMrp
                    )}
                    {getRow(
                        'Re-Purchase',
                        data.rePurchaseCount,
                        data.rePurchaseStudentsCount,
                        data.rePurchaseMrp
                    )}
                    {getRow(
                        lastStream + ' ' + lastLevel,
                        data.lastStreamCount,
                        data.lastStreamSutdentsCount,
                        data.lastStreamMrp
                    )}
                    {getRow(
                        'Joined & Purchased <= 31 days',
                        data.lessThan1MonthOldUsersCount,
                        data.lessThan1MonthOldUsersStudentsCount,
                        data.lessThan1MonthOldUsersMrp
                    )}
                    {getRow(
                        'Joined & Purchased > 31 days',
                        data.moreThan1MonthOldUsersCount,
                        data.moreThan1MonthOldUsersStudentsCount,
                        data.moreThan1MonthOldUsersMrp
                    )}
                    {getHeader(
                        'Total',
                        data.totalPurchasesCount,
                        data.totalPurchasesStudentsCount,
                        data.totalMrp
                    )}
                    <tr>
                        <th className={classes.cell} colSpan="4">
                            {'----'}
                        </th>
                    </tr>
                    <tr>
                        <th className={classes.cell} colSpan="4">
                            Next Journey
                        </th>
                    </tr>
                    {getRow(
                        'Extended To ' + nextAttempt,
                        data.nextAttempExtensionCount,
                        data.nextAttempExtensionStudentsCount,
                        data.nextAttempExtensionMrp
                    )}
                    {getRow(
                        'Not Purchased Subsequently',
                        '-',
                        data.notPurchasedSubsequentlyStudentsCount,
                        '-'
                    )}
                    {getRow(
                        nextStream + ' ' + nextLevel,
                        data.nextStreamCount,
                        data.nextStreamStudentsCount,
                        data.nextStreamMrp
                    )}
                    {getRow(
                        targetStream + ' ' + targetLevel + ' Again',
                        data.sameStreamCount,
                        data.sameStreamStudentsCount,
                        data.sameStreamMrp
                    )}
                    {getRow(
                        'Other Courses Purchased',
                        data.otherCoursesCount,
                        data.otherCoursesStudentsCount,
                        data.otherCoursesMrp
                    )}
                    {getHeader(
                        'Total',
                        data.nextJourneyCount,
                        data.nextJourneyStudentsCount,
                        data.nextJourneyMrp
                    )}
                </tbody>
            </table>
        )
    }

    const downloadNotPurchasedSubsequentlyUsers = () => {
        const values = form.getFieldsValue(true)
        setLoading(true)

        let body = new FormData()
        body.append('payload', JSON.stringify(values))

        RestApi.doPost(
            GLOBAL.URL.REPORTS.DOWNLOAD_NOT_PURCHASED_SUBSEQUENLTY_UESRS,
            body
        )
            .then((res) => {
                setMessage(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>Attempt Stats</h2>
            <Form form={form} layout="horizontal" {...formItemLayout}>
                <Form.Item
                    label="Target Attempt"
                    style={{ marginBottom: 10 }}
                    name="attempt"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select"
                        showSearch
                        style={{ width: 200 }}
                    >
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Target Stream"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="stream"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeTargetStream}>
                        {getStreamOptions()}
                    </Radio.Group>
                </Form.Item>
                {targetStream ? (
                    <Form.Item
                        label="Target Level"
                        style={{ marginBottom: 10, lineHeight: 0 }}
                        name="level"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Radio.Group>{getTargetLevelOptions()}</Radio.Group>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label="Previous Stream"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="lastStream"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeLastStream}>
                        {getStreamOptions()}
                    </Radio.Group>
                </Form.Item>
                {lastStream ? (
                    <Form.Item
                        label="Previous Level"
                        style={{ marginBottom: 10, lineHeight: 0 }}
                        name="lastLevel"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Radio.Group>{getLastLevelOptions()}</Radio.Group>
                    </Form.Item>
                ) : null}
                <Form.Item
                    label="Extension Attempt"
                    style={{ marginBottom: 10 }}
                    name="nextAttempt"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select"
                        showSearch
                        style={{ width: 200 }}
                    >
                        {getAttemptOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Next Stream"
                    style={{ marginBottom: 10, lineHeight: 0 }}
                    name="nextStream"
                    rules={[
                        {
                            required: true,
                            message: 'Needed!',
                        },
                    ]}
                >
                    <Radio.Group onChange={onChangeNextStream}>
                        {getStreamOptions()}
                    </Radio.Group>
                </Form.Item>
                {nextStream ? (
                    <Form.Item
                        label="Next Level"
                        style={{ marginBottom: 10, lineHeight: 0 }}
                        name="nextLevel"
                        rules={[
                            {
                                required: true,
                                message: 'Needed!',
                            },
                        ]}
                    >
                        <Radio.Group>{getNextLevelOptions()}</Radio.Group>
                    </Form.Item>
                ) : null}
            </Form>
            <div style={{ marginLeft: 160, marginTop: 20 }}>
                <Button type="primary" loading={loading} onClick={fetchStats}>
                    Fetch Stats
                </Button>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={downloadNotPurchasedSubsequentlyUsers}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="primary"
                        loading={loading}
                        style={{ marginLeft: 20 }}
                    >
                        Download Not Purchased Subsequently Users
                    </Button>
                </Popconfirm>
            </div>
            {message ? <h3 style={{ marginTop: 10 }}>{message}</h3> : null}
            <div style={{ marginTop: 30 }}>{showStats()}</div>
        </div>
    )
}

export const useStyles = makeStyles()((theme) => ({
    table: {
        border: '1px solid lightGray',
        borderCollapse: 'collapse',
        width: 'auto',
    },
    cell: {
        border: '1px solid lightGray',
        borderCollapse: 'collapse',
        padding: 5,
        textAlign: 'center',
    },
}))

export default AttemptStats
