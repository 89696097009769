import React, { useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, DatePicker, message, Radio, Form } from 'antd'
import TransactionConstants from '../Transactions/TransactionConstants'

const TransactionReport = () => {
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [form] = Form.useForm()

    const onDateChange = (value, dateString) => {
        setStartDate(dateString[0])
        setEndDate(dateString[1])
    }

    const generateReport = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.startDate = startDate
                values.endDate = endDate

                let body = new FormData()
                body.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.GENERATE_TRANSACTIONS_REPORT, body)
                    .then((res) => {
                        message.success(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const { RangePicker } = DatePicker

    const dateFormat = 'DD-MMM-YY'

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    return (
        <div>
            <h2>Generate Transactions Report</h2>
            <Form
                initialValues={{
                    status: TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS,
                }}
                form={form}
                layout="horizontal"
                {...formItemLayout}
            >
                <Form.Item
                    label="Date Range"
                    name="dateRange"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <RangePicker
                        format={dateFormat}
                        placeholder={['Start Date', 'End Date']}
                        onChange={onDateChange}
                    />
                </Form.Item>
                <Form.Item
                    label="Transaction Status"
                    style={{ marginBottom: 0, lineHeight: 0 }}
                    name="status"
                    getValueFromEvent={(e) => e.target.value}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio.Button value="">All</Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .SUCCESS
                            }
                        >
                            Sucess
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .PENDING
                            }
                        >
                            Pending
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .FAILURE
                            }
                        >
                            Failed
                        </Radio.Button>
                        <Radio.Button
                            value={
                                TransactionConstants.TRANSACTION_CONSTANTS
                                    .REFUNDED
                            }
                        >
                            Refunded
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
            <Button
                type="primary"
                loading={loading}
                onClick={() => generateReport()}
                style={{ marginTop: 20, marginLeft: 50 }}
            >
                Generate Report
            </Button>
        </div>
    )
}

export default TransactionReport
