import React, { useEffect, useRef, useState } from 'react'
import {
    message,
    Table,
    Row,
    Button,
    Breadcrumb,
    Col,
    Modal,
    Select,
    Divider,
    Popconfirm,
    Radio,
    Input,
    Upload,
    notification,
    Form,
} from 'antd'
import { CopyOutlined, EditTwoTone, UploadOutlined } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useLocation, useNavigate } from 'react-router-dom'

var ENABLE_CONSTANT = 'enable'
const { Option } = Select
const LINK_TYPE = 'link'

const FreeResourceCatlog = (props) => {
    const [freeResourceCatlogData, setFreeResourceCatlogData] = useState([])
    const [breadCrumbData, setBreadCrumbData] = useState([])
    const [folderModalVisible, setFolderModalVisible] = useState(false)
    const [fileModalVisible, setFileModalVisible] = useState(false)
    const [loadingExistingFolders, setLoadingExistingFolders] = useState(false)
    const [searchFiles, setSearchFiles] = useState([])
    const [addNewFile, setAddNewFile] = useState(true)
    const [loadingExistingFiles, setLoadingExistingFiles] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileTypes, setFileTypes] = useState([])
    const [fileList, setFileList] = useState([])
    const [editNameModalVisible, setEditNameModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [isDataType, setIsDataType] = useState(false)
    const [updatedName, setUpdatedName] = useState('')
    const [id, setId] = useState(null)
    const [form] = Form.useForm()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const catalogId = params.get('catalogId')
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search)
    const inputRef = useRef()

    useEffect(() => {
        getFileTypes()
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (catalogId) {
            getFolderData({ id: catalogId })
        } else {
            getCatlogData()
        }
        //eslint-disable-next-line
    }, [catalogId])

    useEffect(() => {
        setTimeout(() => {
            if (
                (editNameModalVisible ||
                    fileModalVisible ||
                    folderModalVisible) &&
                inputRef.current
            ) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [editNameModalVisible, fileModalVisible, folderModalVisible, inputRef])

    const getCatlogData = () => {
        setBreadCrumbData([])
        setLoading(true)
        removeCatalogId()
        RestApi.doGet(GLOBAL.URL.GET_ROOT_CATLOG)
            .then((res) => {
                const updatedResponse = JSON.parse(res.data)
                setFreeResourceCatlogData(updatedResponse.data)
            })
            .catch((error) => {
                message.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const updateCatalogId = (tempId) => {
        queryParams.set('catalogId', tempId)
        navigate({ search: queryParams.toString() })
    }

    const removeCatalogId = () => {
        queryParams.delete('catalogId')
        navigate({ search: queryParams.toString() })
    }

    const getFolderData = (record) => {
        setLoading(true)

        setId(record.id)

        RestApi.doGet(GLOBAL.URL.GET_ROOT_CATLOG + '/' + record.id)
            .then((res) => {
                const updatedResponse = JSON.parse(res.data)
                const tempBreadCrumbData = updatedResponse.menu
                    .reverse()
                    .map((item) => ({
                        id: item.id,
                        name: item.displayName,
                    }))
                setBreadCrumbData(tempBreadCrumbData)
                setFreeResourceCatlogData(updatedResponse.data)
            })
            .catch((error) => {
                message.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const refreshFolderData = () => {
        RestApi.doGet(`${GLOBAL.URL.GET_ROOT_CATLOG}${id ? `/${id}` : ''}`)
            .then((res) => {
                setFreeResourceCatlogData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleFolderModalVisible = () => {
        setFolderModalVisible(true)
    }

    const closeFolderModal = () => {
        setFolderModalVisible(false)
    }
    const handleFileModalVisible = () => {
        setFileModalVisible(true)
    }

    const closeFileModal = () => {
        setFileModalVisible(false)
        setFileList([])
    }

    const enableDisableCatlogItem = (id, action) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        let url = ''
        if (action === ENABLE_CONSTANT) {
            url = GLOBAL.URL.ENABLE_FREE_RESOURSE
        } else {
            url = GLOBAL.URL.DISABLE_FREE_RESOURCE
        }
        RestApi.doPost(url, postBody).then((response) => {
            if (response.code === 200) {
                setFreeResourceCatlogData((prevState) => {
                    return prevState.map((data) => {
                        if (id === data.id) {
                            return {
                                ...data,
                                enabled:
                                    action === ENABLE_CONSTANT ? true : false,
                            }
                        }
                        return data
                    })
                })
                message.destroy()
                message.success(response.data)
            }
        })
    }

    const unLinkItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_FROM_CATLOG, postBody).then(
            (response) => {
                if (response.code === 200) {
                    var successObject = freeResourceCatlogData
                    setFreeResourceCatlogData(
                        successObject.filter((key) => key.id !== id)
                    )
                    message.destroy()
                    message.success(response.data)
                }
            }
        )
    }

    const onChangeAddFileSelection = (e) => {
        setAddNewFile(e.target.value)
    }

    const addFolderToCatalog = (folderName) => {
        setLoadingExistingFolders(true)

        var payload = {}
        payload.name = folderName
        payload.parentCatalogId = catalogId ? catalogId : null

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_FOLDER_TO_RESOURCES_CATALOG, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success('Added!')
                    closeFolderModal()
                    if (!catalogId) {
                        getCatlogData()
                    } else {
                        getFolderData({ id: catalogId })
                    }
                }
            })
            .finally(() => {
                setLoadingExistingFolders(false)
            })
    }

    const searchExistingFile = (value) => {
        if (!value) {
            message.error('Enter File Name')
            return
        }

        setLoadingExistingFiles(true)

        RestApi.doGet(GLOBAL.URL.GET_FILES_LIKE + encodeURIComponent(value))
            .then((res) => {
                var response = JSON.parse(res.data)
                setSearchFiles(response.data)
            })
            .finally(() => {
                setLoadingExistingFiles(false)
            })
    }

    const addExistingFileToCatalog = (id) => {
        setLoadingExistingFiles(true)

        var payload = {}
        payload.isNew = false
        payload.id = id
        payload.parentCatalogId =
            Object.keys(breadCrumbData).length > 0
                ? breadCrumbData[breadCrumbData.length - 1].id
                : null

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.ADD_FILE_TO_RESOURCES_CATALOG, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success('Added!')
                    closeFileModal()
                    if (breadCrumbData.length === 0) {
                        getCatlogData()
                    } else {
                        getFolderData(breadCrumbData[breadCrumbData.length - 1])
                    }
                }
            })
            .finally(() => {
                setLoadingExistingFiles(false)
            })
    }

    const getFileTypes = () => {
        RestApi.doGet(GLOBAL.URL.GET_FILE_TYPES).then((res) => {
            setFileTypes(res.data)
        })
    }

    const changeSelectOption = (value) => {
        if (value === 'youtube' || value === LINK_TYPE) {
            setIsDataType(true)
        } else {
            setIsDataType(false)
        }
    }

    const addNewFileToCatalog = () => {
        form.validateFields()
            .then((values) => {
                setLoadingExistingFiles(true)
                values.isNew = true
                values.parentCatalogId = catalogId ? catalogId : null

                var formBodyFileObject = new FormData()
                formBodyFileObject.append('payload', JSON.stringify(values))
                if (Object.keys(fileList).length > 0) {
                    formBodyFileObject.append('file', fileList[0])
                }
                RestApi.doPost(
                    GLOBAL.URL.ADD_FILE_TO_RESOURCES_CATALOG,
                    formBodyFileObject
                )
                    .then((response) => {
                        if (response.code === 200) {
                            message.destroy()
                            message.success('Added!')
                            closeFileModal()
                            if (!catalogId) {
                                getCatlogData()
                            } else {
                                getFolderData({ id: catalogId })
                            }
                        }
                    })
                    .finally(() => {
                        setLoadingExistingFiles(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'displayName',
                key: 'displayName',
            },
        ]

        navigate('/reorder', {
            state: {
                reorderData: freeResourceCatlogData,
                updateUrl: GLOBAL.URL.REORDER_FREE_RESOURCES,
                columns: columns,
            },
        })
    }

    const showEditNameModal = (record) => {
        setEditNameModalVisible(true)
        setSelectedRecord(record)
        setUpdatedName(record.displayName)
    }

    const hideEditNameModal = (record) => {
        setEditNameModalVisible(false)
    }

    const updateName = () => {
        setLoading(true)

        var payload = {}
        payload.id = selectedRecord.id
        payload.name = updatedName

        let formbody = new FormData()
        formbody.append('payload', JSON.stringify(payload))
        RestApi.doPost(
            GLOBAL.URL.UPDATE_FILE_OR_FOLDER_NAME_FREE_RESOURCE,
            formbody
        )
            .then((response) => {
                message.success(response.data)
                hideEditNameModal()
                refreshFolderData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const { Search } = Input
    let columns = []
    if (Object.keys(freeResourceCatlogData).length > 0) {
        columns = [
            {
                title: 'Edit',
                dataIndex: 'editName',
                key: 'editName',
                width: 50,
                render: (text, record) => (
                    <Button
                        type="link"
                        onClick={() => {
                            showEditNameModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                ),
            },
            {
                title: 'Name',
                dataIndex: 'displayName',
                key: 'displayName',
                render: (text, record) => (
                    <span>
                        {record.folder ? (
                            <Button
                                type="link"
                                onClick={() => {
                                    updateCatalogId(record.id)
                                }}
                                style={{ paddingLeft: 0 }}
                            >
                                {record.displayName}
                            </Button>
                        ) : (
                            record.displayName
                        )}
                    </span>
                ),
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                render: (text, record) =>
                    record.type ? record.type : 'Folder',
            },
            {
                title: 'Resource Name',
                dataIndex: 'resourceName',
                key: 'resourceName',
                render: (text, record) =>
                    !record.folder ? (
                        record.type === 'youtube' || record.type === 'link' ? (
                            <a
                                href={record.data}
                                style={{ display: 'table-cell' }}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {record.data}
                            </a>
                        ) : (
                            record.resourceName
                        )
                    ) : (
                        record.catalogId
                    ),
            },
            {
                title: 'Deep Link',
                dataIndex: 'deepLink',
                key: 'deepLink',
                render: (text, record) => (
                    <span>
                        {record.deepLink ? (
                            <CopyToClipboard
                                text={record.deepLink}
                                onCopy={() =>
                                    notification['success']({
                                        message: 'Link copied!',
                                    })
                                }
                            >
                                <span>
                                    <Button
                                        type="link"
                                        style={{ padding: 0, fontSize: 10 }}
                                    >
                                        <CopyOutlined />
                                        {'Copy Link'}
                                    </Button>
                                </span>
                            </CopyToClipboard>
                        ) : null}
                    </span>
                ),
            },
            {
                title: 'Action',
                key: 'active',
                render: (text, record) => (
                    <span>
                        {!record.enabled ? (
                            <Button
                                style={{
                                    background: 'red',
                                    color: 'white',
                                }}
                                onClick={() => {
                                    enableDisableCatlogItem(
                                        record.id,
                                        ENABLE_CONSTANT
                                    )
                                }}
                                size="small"
                            >
                                Disabled
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() => {
                                    enableDisableCatlogItem(
                                        record.id,
                                        'disable'
                                    )
                                }}
                                size="small"
                            >
                                Enabled
                            </Button>
                        )}
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure want to unlink ?"
                            onConfirm={() => {
                                unLinkItem(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" onClick={() => {}}>
                                Unlink
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]
    }

    var breadCrumbView = []

    breadCrumbData.forEach((item, i) => {
        breadCrumbView.push(
            <Breadcrumb.Item key={i}>
                <Button
                    type="link"
                    onClick={() => {
                        updateCatalogId(item.id)
                        var tempArray = breadCrumbData
                        breadCrumbData.forEach((value, index) => {
                            if (value.id === item.id) {
                                tempArray.length = index + 1
                            }
                        })
                    }}
                >
                    {item.name}
                </Button>
            </Breadcrumb.Item>
        )
    })

    const filesSearchColums = [
        {
            title: 'Action',
            dataIndex: 'add',
            key: 'add',
            render: (text, record) => (
                <span>
                    <Button
                        type="primary"
                        onClick={() => addExistingFileToCatalog(record.id)}
                    >
                        Add
                    </Button>
                </span>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    var fileListOptionArray = []

    fileTypes.forEach((item) => {
        fileListOptionArray.push(<Option value={item}>{item}</Option>)
    })

    const uploadProps = {
        onRemove: (file) => {
            setFileList((prev) => {
                const index = prev.indexOf(file)
                const newFileList = prev.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file) => {
            setFileList((prev) => [...prev, file])
            return false
        },
        fileList,
    }

    return (
        <div>
            <Row gutter={12} style={{ marginBottom: 9 }}>
                <Col span={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Button
                                type="link"
                                onClick={() => {
                                    removeCatalogId()
                                }}
                            >
                                Catlog
                            </Button>
                        </Breadcrumb.Item>
                        {breadCrumbView}
                    </Breadcrumb>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                        type="primary"
                        size="small"
                        style={{ marginRight: 5 }}
                        onClick={() => {
                            handleFolderModalVisible()
                        }}
                    >
                        Add Folder
                    </Button>

                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            handleFileModalVisible()
                        }}
                    >
                        Add File
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        style={{
                            marginLeft: 10,
                        }}
                        loading={loading}
                        onClick={reorder}
                    >
                        Reorder
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={freeResourceCatlogData}
                pagination={false}
                loading={loading}
                size="small"
                rowKey="displayName"
            />
            <Modal
                title="Add Folder"
                open={folderModalVisible}
                onCancel={closeFolderModal}
                okText="Add Folder"
                footer={null}
                destroyOnClose={true}
            >
                <h4>Enter folder name:</h4>
                <Input
                    placeholder="Folder Name"
                    onPressEnter={(e) => addFolderToCatalog(e.target.value)}
                    style={{ marginTop: 10 }}
                    loading={loadingExistingFolders}
                    autoFocus
                    ref={inputRef}
                />
            </Modal>
            <Modal
                title="Add File"
                open={fileModalVisible}
                onOk={() => addNewFileToCatalog()}
                confirmLoading={loadingExistingFiles}
                onCancel={closeFileModal}
                okText="Add File"
                destroyOnClose={true}
            >
                <Radio.Group
                    value={addNewFile}
                    onChange={onChangeAddFileSelection}
                >
                    <Radio.Button value="true">New</Radio.Button>
                    <Radio.Button value="false">Existing</Radio.Button>
                </Radio.Group>

                {addNewFile === 'true' ? (
                    <div style={{ marginTop: 10 }}>
                        <Form
                            form={form}
                            initialValues={{ fileType: 'pdf' }}
                            layout="horizontal"
                            {...formItemLayout}
                        >
                            <Form.Item
                                label="File Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter File Name',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Please enter file name"
                                    autoFocus
                                    ref={inputRef}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Select File Type"
                                name="fileType"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select File type',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Please select File type"
                                    onChange={changeSelectOption}
                                >
                                    {fileListOptionArray}
                                </Select>
                            </Form.Item>
                            {isDataType ? (
                                <Form.Item
                                    label="Data"
                                    name="data"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter Url',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter Url"
                                        ref={inputRef}
                                    />
                                </Form.Item>
                            ) : null}
                            {!isDataType ? (
                                <Upload {...uploadProps}>
                                    <Button>
                                        <UploadOutlined /> Select File
                                    </Button>
                                </Upload>
                            ) : null}
                        </Form>
                    </div>
                ) : (
                    <div style={{ marginTop: 10 }}>
                        <Search
                            placeholder="Search File"
                            onSearch={(value) => searchExistingFile(value)}
                            autoFocus
                            ref={inputRef}
                        />
                        <Table
                            columns={filesSearchColums}
                            dataSource={searchFiles ? searchFiles : null}
                            pagination={false}
                            loading={loadingExistingFiles}
                            scroll={{ y: 400 }}
                            style={{ marginTop: 10 }}
                        />
                    </div>
                )}
            </Modal>
            <Modal
                title="Edit Name"
                open={editNameModalVisible}
                onOk={() => {
                    updateName()
                }}
                confirmLoading={loading}
                onCancel={hideEditNameModal}
                okText="Update"
                destroyOnClose={true}
            >
                <h3>Current name:</h3>
                <span>{selectedRecord.displayName}</span>
                <h3 style={{ marginTop: 15 }}>Updated name:</h3>
                <Input
                    value={updatedName}
                    autoFocus
                    onChange={(e) => {
                        setUpdatedName(e.target.value)
                    }}
                    ref={inputRef}
                ></Input>
            </Modal>
        </div>
    )
}

export default FreeResourceCatlog
