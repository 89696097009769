import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { InputNumber, Button } from 'antd'
import StatsConstants from './StatsConstants'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ChartDataLabels
)
const emptyBarData = {
    datasets: [{}],
}

class VideoRequestStats extends React.Component {
    state = {
        loading: false,
        requests: [],
        users: [],
        labels: [],
        mapData: emptyBarData,
        options: {},
        requestsDays: [],
        usersDays: [],
        labelsDays: [],
        mapDataDays: emptyBarData,
        optionsDays: {},
        limit: 0,
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.GET_VIDEO_REQUESTS_COUNT_BY_MONTH
        if (this.state.limit > 0) {
            url = url + '?limit=' + this.state.limit
        }

        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var requests = []
                var users = []
                var response = JSON.parse(res.data)
                response.forEach((row) => {
                    labels.push(row.label)
                    requests.push(row.requests)
                    users.push(row.users)
                })
                this.setState(
                    {
                        labels: labels,
                        requests: requests,
                        users: users,
                    },
                    () => this.drawChart()
                )
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawChart() {
        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = this.state.requests
        revenueDataSet.label = 'Requests'
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.datalabels = { display: true }
        revenueDataSet.tension = 0.3

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = this.state.users
        countDataSet.label = 'Users'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'
        countDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = this.state.labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = StatsConstants.CHART_OPTIONS_TWO_LEVELS
        options.plugins.title.text = 'Requests by Month'
        options.onClick = (evt, item) => {
            this.getMonthSplit(item)
        }

        this.setState({
            mapData: mapData,
            options: options,
        })
    }

    onChange = (value) => {
        this.setState({
            limit: value,
        })
    }

    onClick = () => {
        this.getData()
    }

    getMonthSplit = (item) => {
        if (item.length === 0) {
            return
        }

        var label = this.state.labels[item[0].index]
        if (!label && item.length === 1) {
            return
        } else if (!label) {
            label = this.state.labels[item[1].index]
        }

        if (!label) {
            return
        }

        var url = GLOBAL.URL.GET_VIDEO_REQUESTS_COUNT_BY_MONTH + '/' + label
        RestApi.doGet(url)
            .then((res) => {
                var labels = []
                var requestsDays = []
                var usersDays = []
                var response = JSON.parse(res.data)
                response.forEach((row) => {
                    labels.push(row.label)
                    requestsDays.push(row.requests)
                    usersDays.push(row.users)
                })
                this.setState({
                    labelsDays: labels,
                    requestsDays: requestsDays,
                    usersDays: usersDays,
                })
                this.drawDayChart(labels, requestsDays, usersDays)
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    drawDayChart(
        labels = this.state.labelsDays,
        requestsDays = this.state.requestsDays,
        usersDays = this.state.usersDays
    ) {
        var revenueDataSet = {}
        revenueDataSet.type = 'bar'
        revenueDataSet.data = requestsDays
        revenueDataSet.label = 'Requests'
        revenueDataSet.backgroundColor = '#4bc0c0'
        revenueDataSet.borderColor = '#4bc0c0'
        revenueDataSet.fill = true
        revenueDataSet.yAxisID = 'y-1'
        revenueDataSet.tension = 0.3

        var countDataSet = {}
        countDataSet.type = 'line'
        countDataSet.data = usersDays
        countDataSet.label = 'Users'
        countDataSet.borderColor = '#396ab1'
        countDataSet.fill = false
        countDataSet.yAxisID = 'y-2'
        countDataSet.tension = 0.3

        var mapData = {}
        mapData.labels = labels
        mapData.datasets = []
        mapData.datasets.push(countDataSet)
        mapData.datasets.push(revenueDataSet)

        var options = StatsConstants.CHART_OPTIONS_TWO_LEVELS
        options.plugins.title.text = 'Requests By Day'

        this.setState({
            mapDataDays: mapData,
            optionsDays: options,
        })

        const dayChart = document.getElementById('dayChart')
        if (dayChart) {
            dayChart.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }

    render() {
        return (
            <div>
                <InputNumber
                    min={1}
                    defaultValue={6}
                    onChange={this.onChange}
                />
                <Button
                    type="primary"
                    onClick={this.onClick}
                    loading={this.state.loading}
                    style={{ marginLeft: 10 }}
                >
                    Fetch
                </Button>
                <Bar
                    data={this.state.mapData}
                    width={800}
                    height={400}
                    options={this.state.options}
                />
                <Bar
                    id="dayChart"
                    data={this.state.mapDataDays}
                    width={800}
                    height={400}
                    options={this.state.optionsDays}
                />
            </div>
        )
    }
}

export default VideoRequestStats
