import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { Button, Popconfirm, Select, Form } from 'antd'

function AddCoursesByPurchase() {
    const [form] = Form.useForm()
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [attempts, setAttempts] = useState([])
    const [allCourses, setAllCourses] = useState([])
    const [allNonBundleCourses, setAllNonBundleCourses] = useState([])
    const [message, setMessage] = useState()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        RestApi.doGet(GLOBAL.URL.GET_ALL_COURSES_LIST_WITH_IDS).then((res) => {
            setAllCourses(res.data)
        })

        RestApi.doGet(
            GLOBAL.URL.GET_ALL_NON_BUNDLE_FULL_COURSE_LIST_WITH_IDS
        ).then((res) => {
            setAllNonBundleCourses(res.data)
        })

        RestApi.doGet(GLOBAL.URL.GET_ALL_ATTEMPTS_ADMIN).then((res) => {
            setAttempts(res.data)
        })
    }

    const allCourseOptions = () => {
        var options = []
        allCourses.forEach((item) => {
            options.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const allNonBundleCourseOptions = () => {
        var options = []
        allNonBundleCourses.forEach((item) => {
            options.push(
                <Option value={item.courseId} key={item.courseId}>
                    {item.name}
                </Option>
            )
        })

        return options
    }

    const attemptOptions = () => {
        var options = []
        attempts.forEach((item) => {
            options.push(
                <Option value={item.attempt} key={item.attempt}>
                    {item.attempt}
                </Option>
            )
        })

        return options
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const addCourses = () => {
        form.validateFields()
            .then((values) => {
                setMessage('')
                setLoading(true)

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.USER.ADD_COURSES_BY_PURCHASE,
                    formData
                )
                    .then((response) => {
                        setMessage(response.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    return (
        <div>
            <h2>Add Courses by Purchases</h2>
            <Form
                form={form}
                layout="horizontal"
                {...formItemLayout}
                style={{ marginTop: 20 }}
            >
                <Form.Item
                    label="Courses Purchased"
                    name="purchaseCourseIds"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        allowClear={true}
                    >
                        {allCourseOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Attempts"
                    name="attempts"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Attempts"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        allowClear={true}
                    >
                        {attemptOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Add Courses"
                    name="addCourseIds"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Courses"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        allowClear={true}
                    >
                        {allNonBundleCourseOptions()}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Custom Target Attempt"
                    name="targetAttemptToEnroll"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select
                        placeholder="Select Attempt"
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={true}
                    >
                        {attemptOptions()}
                    </Select>
                </Form.Item>
            </Form>
            <Popconfirm
                title="Are you sure?"
                onConfirm={addCourses}
                okText="Yes"
                cancelText="No"
            >
                <Button
                    loading={loading}
                    style={{ marginLeft: 215 }}
                    type="primary"
                >
                    Add Courses
                </Button>
            </Popconfirm>
            {message ? (
                <h3 style={{ marginTop: 20, marginLeft: 215 }}>{message}</h3>
            ) : null}
        </div>
    )
}

export default AddCoursesByPurchase
