import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { RestApi } from '../../RestApi'
import { UserWatchTimeAnalyticsByMonthDetail } from './UserWatchTimeAnalyticsByMonthDetail'
import { UserWatchTimeAnalyticsByDay } from './UserWatchTimeAnalyticsByDay'
import { mapByMonth } from './GraphMapping/MapByMonth'
import { mapByDay } from './GraphMapping/MapByDay'
import GLOBAL from '../../Globals'
import CircularProgress from '@mui/material/CircularProgress'
import StatsConstants from '../Stats/StatsConstants'

const dashboardRightContentWidth = '83.4%'

const WatchTimeAnalytics = (props) => {
    const analyticsDataByday =
        props.location && props.location.state
            ? props.location.state
            : undefined
    const [watchTimeDataByMonth, setWatchTimeDataByMonth] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [bymonthOptions, setByMonthOptions] = useState({})
    const [watchTimeDataByDay, setWatchTimeDataByDay] = useState(
        StatsConstants.INITIAL_BAR_OPTIONS
    )
    const [byDayOptions, setByDayOptions] = useState({})
    const initialMonths = 6
    const [loading, setLoading] = useState(false)
    const [selectedMonthLabel, setSelectedMonthLabel] = useState('')
    const { classes } = useStyles()
    const { userId } = props

    useEffect(() => {
        if (Object.keys(watchTimeDataByMonth).length > 0) {
            return
        }
        getWatchTimeDataByMonth(initialMonths, true, analyticsDataByday)

        // eslint-disable-next-line
    }, [])

    const getWatchTimeDataByMonth = (months, isLoading, analyticsDataByday) => {
        const calculateAnalyticsDataOptions = (analyticsDataByday) => {
            let date = new Date()
            let monthLabel = `${date.getMonth() + 1}-${date.getFullYear()}`
            let data = mapByDay(analyticsDataByday)
            setWatchTimeDataByDay(data.watchTimeData)
            setByDayOptions(data.options)
            setSelectedMonthLabel(monthLabel)
        }

        setLoading(true)

        if (typeof analyticsDataByday !== 'undefined') {
            calculateAnalyticsDataOptions(analyticsDataByday)
        }

        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_WATCH_TIME_BY_MONTH +
                months +
                '&userId=' +
                userId
        )
            .then((response) => {
                let data = mapByMonth(response.data, getMonthSplit)
                setWatchTimeDataByMonth(data.watchTimeData)
                setByMonthOptions(data.options)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getMonthSplit = (monthLabel) => {
        setLoading(true)
        setSelectedMonthLabel(monthLabel)

        RestApi.doGet(
            GLOBAL.URL.ANALYTICS.USER.GET_WATCH_TIME_BY_DAY +
                monthLabel +
                '&userId=' +
                userId
        )
            .then((response) => {
                let data = mapByDay(response.data)
                setWatchTimeDataByDay(data.watchTimeData)
                setByDayOptions(data.options)
                let doc = document.getElementById('analyticsByDay')
                window.scrollTo({
                    top: doc.offsetTop - 70,
                    behavior: 'smooth',
                })
            })
            .finally(() => setLoading(false))
    }

    const fetchData = (selectedMonth) => {
        getWatchTimeDataByMonth(selectedMonth)
    }

    return (
        <>
            <Grid className={classes.wrapperContainer} container>
                <Grid item xs={12} className={classes.rightContentContainer}>
                    <Grid className={classes.chartContainer}>
                        {loading ? <CircularProgress /> : null}
                        <UserWatchTimeAnalyticsByMonthDetail
                            data={watchTimeDataByMonth}
                            options={bymonthOptions}
                            fetchData={(selectedMonth) =>
                                fetchData(selectedMonth)
                            }
                            loading={loading}
                        />
                        <UserWatchTimeAnalyticsByDay
                            id={'analyticsByDay'}
                            data={watchTimeDataByDay}
                            options={byDayOptions}
                            selectedMonthLabel={selectedMonthLabel}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default WatchTimeAnalytics

export const useStyles = makeStyles()((theme) => ({
    wrapperContainer: {
        display: 'flex',
        flexFlow: 'row',
        minHeight: 800,
        [theme.breakpoints.down('md')]: {
            flexFlow: 'column',
        },
    },
    chartContainer: {
        display: 'flex',
        flexFlow: 'column',
        padding: 20,
        width: '70%',
        [theme.breakpoints.down('md')]: {
            padding: '20px 15px',
            width: '100%',
        },
    },
    appContainer: {
        paddingTop: 0,
    },
    rightContentContainer: {
        display: 'flex',
        flexFlow: 'column',
        width: dashboardRightContentWidth,
        [theme.breakpoints.down('md')]: {
            width: 'unset',
        },
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
        },
    },
}))
