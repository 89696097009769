import { jwtDecode } from 'jwt-decode'

const ROLE_ADMIN = 'admin'
const ROLE_ANALYTICS_REVENUE = 'analytics_revenue'
const ROLE_ANALYTICS_USERS = 'analytics_users'
const ROLE_BLOG_MANAGER = 'blog_manager'
const ROLE_COURIER_ADDRESS_UPDATE = 'courier_address_update'
const ROLE_COURIER_ANALYTICS = 'courier_analytics'
const ROLE_COURIER_READ_ONLY = 'courier_read_only'
const ROLE_COURIER_MANAGER = 'courier_manager'
const ROLE_COURSE_REVIEWS = 'course_reviews'
const ROLE_DEMO_VIDEOS_MANAGER = 'demo_videos_manager'
const ROLE_EXTERNAL_SALES_MEMBER = 'external_sales_member'
const ROLE_EXTERNAL_SALES_MANAGER = 'external_sales_manager'
const ROLE_FORUMS_ADMIN = 'forums_admin'
const ROLE_IL_EMPLOYEE = 'il_employee'
const ROLE_IL_MANAGER = 'il_manager'
const ROLE_LIVE_CLASSES_MANAGER = 'live_classes_mnager'
const ROLE_MARKETING_MEMBER = 'marketing_member'
const ROLE_MARKETING_AGENCY = 'marketing_agency'
const ROLE_NEWS_MANAGER = 'news_manager'
const ROLE_SALES_MANAGER = 'sales_manager'
const ROLE_SALES_MEMBER = 'sales_member'
const ROLE_SEO_EXTERNAL_TEAM = 'seo_external_team'
const ROLE_SITE_MANAGER = 'site_manager'
const ROLE_STREAM_PAGE_MANAGER = 'stream_page_manager'
const ROLE_SUPER_ADMIN = 'superadmin'
const ROLE_SUPPORT_MANAGER = 'support_manager'
const ROLE_TESTIMONIALS_MANAGER = 'testimonials_manager'
const ROLE_UPLOAD_TO_S3 = 'upload_to_s3'
const ROLE_USER_READ_ONLY = 'user_read_only'

export const Utils = {
    getAuthToken: function () {
        return localStorage.getItem('token')
    },
    isSuperUserAdmin: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (role === 'superadmin') {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    isUserAdmin: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (role === 'superadmin' || role === 'admin') {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasTransactionsAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (
                role === 'superadmin' ||
                role === 'admin' ||
                role === 'transactions'
            ) {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasForumsAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (
                role === 'superadmin' ||
                role === 'admin' ||
                role === 'forums'
            ) {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasForumsAdminAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_FORUMS_ADMIN
            ) {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasReportsAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (
                role === 'superadmin' ||
                role === 'admin' ||
                role === 'reports'
            ) {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasFlagAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (role === 'superadmin' || role === 'admin' || role === 'flag') {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasMCQAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (role === 'superadmin' || role === 'admin' || role === 'mcq') {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasCourseReadOnlyAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var isAdmin = false
        decoded.roles.forEach((role) => {
            if (
                role === 'superadmin' ||
                role === 'admin' ||
                role === 'course_read_only'
            ) {
                isAdmin = true
                return
            }
        })

        return isAdmin
    },
    userHasResourceCatalogAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === 'superadmin' ||
                role === 'admin' ||
                role === 'resources_catalog_readonly'
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userHasAddToManualTransactionQueueAccess: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === 'superadmin' ||
                role === 'admin' ||
                role === 'add_to_manual_transaction_queue'
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userIsSupportManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SUPPORT_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userIsSalesManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SALES_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userIsSalesMember: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SALES_MEMBER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userIsBlogManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_BLOG_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userIsStreamPageManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_STREAM_PAGE_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userHasAnalyticsRevenueRole: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_ANALYTICS_REVENUE
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userHasAnalyticsUsersRole: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_ANALYTICS_USERS
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userHasCourseReviewsRole: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_COURSE_REVIEWS
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    userHasLiveClassesManagerRole: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_LIVE_CLASSES_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserExternalSalesMember: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_EXTERNAL_SALES_MEMBER ||
                role === ROLE_EXTERNAL_SALES_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserExternalSalesManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_EXTERNAL_SALES_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserNewsManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_NEWS_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserSiteManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SITE_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isCourierReadOnly: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_COURIER_READ_ONLY ||
                role === ROLE_COURIER_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isCourierManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_COURIER_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isCourierAnalytics: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_COURIER_ANALYTICS
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUploadToS3: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SITE_MANAGER ||
                role === ROLE_UPLOAD_TO_S3
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isCourierAddressUpdate: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_COURIER_ADDRESS_UPDATE
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserMarketingMember: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SITE_MANAGER ||
                role === ROLE_MARKETING_MEMBER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserMarketingAgency: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (role === ROLE_MARKETING_AGENCY) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isTestimonialsManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SITE_MANAGER ||
                role === ROLE_TESTIMONIALS_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isDemoVideosManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SITE_MANAGER ||
                role === ROLE_DEMO_VIDEOS_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isSeoExternalTeam: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (role === ROLE_SEO_EXTERNAL_TEAM) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isUserReadOnly: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_SITE_MANAGER ||
                role === ROLE_USER_READ_ONLY
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isIlEmployee: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_IL_EMPLOYEE
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
    isIlManager: function () {
        var token = this.getAuthToken()
        if (!token) {
            return false
        }

        var decoded = jwtDecode(token)

        var hasAccess = false
        decoded.roles.forEach((role) => {
            if (
                role === ROLE_SUPER_ADMIN ||
                role === ROLE_ADMIN ||
                role === ROLE_IL_MANAGER
            ) {
                hasAccess = true
                return
            }
        })

        return hasAccess
    },
}
