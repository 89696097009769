import React, { useEffect, useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import dayjs from 'dayjs'
import { convertDateToDDMMMYYYY } from '../ILShareComponents/ILCalendar/calendarUtil'
import {
    Box,
    Collapse,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    keyframes,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { EditTwoTone } from '@ant-design/icons'
import { Button, Form, Modal, Popconfirm, TimePicker, notification } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'

const ILEmployeeTableRow = ({
    row,
    activeDate,
    setViewingDate,
    lastIndex,
    getEmployeeLoginData,
}) => {
    const [open, setOpen] = useState(false)
    const [showUpdateLoginModal, setShowUpdateLoginModal] = useState(false)
    const [selectedLoginRecord, setSelectedLoginRecord] = useState()
    const [loading, setLoading] = useState(false)
    const [loginTime, setLoginTime] = useState()
    const [logoutTime, setLogoutTime] = useState()

    const [form] = Form.useForm()
    const { classes, cx } = useStyles()
    const firstLoginTime = row.logins[0]
    const lastLogoutTime = row.logins[row.logins.length - 1]
    const isSingleLoginLogout = row.logins.length === 1
    const currentDate = convertDateToDDMMMYYYY(new Date())

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    useEffect(() => {
        if (activeDate) {
            const newActiveDate = convertDateToDDMMMYYYY(activeDate)

            if (newActiveDate === row.loginDate && row.logins.length > 1) {
                setOpen(true)
            }
        }
        //eslint-disable-next-line
    }, [activeDate])

    const onClickExpandHistory = () => {
        const date = dayjs(row.loginDate, 'DD-MMM-YYYY')
        const day = date.date()
        setViewingDate(day)
        if (row.logins.length > 1) {
            setOpen(!open)
        }
    }

    const deleteLoginEntry = (uid) => {
        setLoading(true)

        let payload = {}
        payload.uid = uid

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.IL.DELETE_LOGIN_DATA, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                getEmployeeLoginData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }
    const showUpdateLogingModal = (record) => {
        if (!record?.loginTime) {
            return
        }

        let loginTime = record?.loginTime
        let logoutTime = record?.logoutTime

        setLoginTime(loginTime)
        setLogoutTime(loginTime)
        setSelectedLoginRecord(record)
        setShowUpdateLoginModal(true)

        setTimeout(() => {
            let loginTimeObj = dayjs(loginTime, 'HH:mm')
            let logoutTimeObj = null
            if (logoutTime) {
                logoutTimeObj = dayjs(logoutTime, 'HH:mm')
            }

            setTimeout(() => {
                form.setFieldsValue({
                    loginTime: loginTimeObj,
                    logoutTime: logoutTimeObj,
                })
            }, 100)
        }, 100)
    }

    const updateLoginData = () => {
        form.validateFields()
            .then((values) => {
                if (!loginTime) {
                    notification['error']({
                        message: 'Select Login Time!',
                    })
                    return
                }

                setLoading(true)

                let payload = {}
                payload.uid = selectedLoginRecord.uid
                payload.loginTime = loginTime
                payload.logoutTime = logoutTime ? logoutTime : ''

                let formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(GLOBAL.URL.IL.UPDATE_LOGIN_DATA, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideUpdateLoginModal()
                        getEmployeeLoginData()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const hideUpdateLoginModal = () => {
        setShowUpdateLoginModal(false)
        setSelectedLoginRecord(null)
    }

    const onChangeLoginTime = (_, timeString) => {
        setLoginTime(timeString)
    }

    const onChangeLogoutTime = (_, timeString) => {
        setLogoutTime(timeString)
    }

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    cursor: row.logins.length > 1 ? 'pointer' : 'unset',
                }}
                id={`${row.loginDate}`}
                onClick={() => onClickExpandHistory()}
                className={cx({
                    [classes.active]: open,
                })}
            >
                <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    className={cx(classes.tableRow, classes.tableRowDate, {
                        [classes.lastRow]: !lastIndex,
                        [classes.holiday]: row.holiday,
                        [classes.leave]: row.leave && !row.holiday,
                    })}
                >
                    {row.logins.length > 1 ? (
                        <>
                            {open ? (
                                <KeyboardArrowUp className={classes.arrow} />
                            ) : (
                                <KeyboardArrowDown className={classes.arrow} />
                            )}
                        </>
                    ) : isSingleLoginLogout ? (
                        <Button
                            type="link"
                            onClick={() => {
                                showUpdateLogingModal(row.logins[0])
                            }}
                            style={{ padding: 0 }}
                        >
                            <EditTwoTone className={classes.arrow} />
                        </Button>
                    ) : (
                        <Box className={classes.arrow} />
                    )}
                    {row.loginDate}
                    {row.holiday ? ' (H)' : row.leave ? ' (L)' : null}
                </TableCell>
                <TableCell
                    align={'center'}
                    className={cx(classes.tableRow, {
                        [classes.lastRow]: !lastIndex,
                    })}
                >
                    {row.totalLoginDuration
                        ? row.totalLoginDuration
                        : currentDate !== row.loginDate && '--'}
                </TableCell>
                <TableCell
                    align={'center'}
                    className={cx(classes.tableRow, {
                        [classes.lastRow]: !lastIndex,
                    })}
                >
                    {firstLoginTime ? firstLoginTime.loginTime : '--'}
                </TableCell>
                <TableCell
                    align={'center'}
                    className={cx(classes.tableRow, {
                        [classes.lastRow]: !lastIndex,
                        [classes.textAlignLeft]:
                            !isSingleLoginLogout && lastLogoutTime,
                    })}
                >
                    {lastLogoutTime?.logoutTime
                        ? lastLogoutTime.logoutTime
                        : '--'}
                    {isSingleLoginLogout && (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                deleteLoginEntry(row.logins[0].uid)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="link" className={classes.deleteBtn}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </Popconfirm>
                    )}
                </TableCell>
            </TableRow>

            {row.logins.length > 1 && (
                <TableRow>
                    <TableCell className={classes.nestedTable} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box className={classes.tableContainer}>
                                <Table
                                    size="small"
                                    aria-label="purchases"
                                    className={classes.historyTable}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="center"
                                                className={classes.tableHead}
                                            >
                                                #
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={classes.tableHead}
                                            >
                                                Duration
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                className={classes.tableHead}
                                            >
                                                Login
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableHead}
                                                align="center"
                                            >
                                                Logout
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {row.logins.map((historyRow, i) => (
                                            <TableRow key={i}>
                                                <TableCell
                                                    className={cx(
                                                        classes.nestedTableRow,
                                                        {
                                                            [classes.lastRow]:
                                                                i ===
                                                                row.logins
                                                                    .length -
                                                                    1,
                                                        }
                                                    )}
                                                    align="center"
                                                >
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            showUpdateLogingModal(
                                                                historyRow
                                                            )
                                                        }}
                                                        style={{ padding: 0 }}
                                                    >
                                                        <EditTwoTone
                                                            className={
                                                                classes.arrow
                                                            }
                                                        />
                                                    </Button>
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell
                                                    className={cx(
                                                        classes.nestedTableRow,
                                                        {
                                                            [classes.lastRow]:
                                                                i ===
                                                                row.logins
                                                                    .length -
                                                                    1,
                                                        }
                                                    )}
                                                    align="center"
                                                >
                                                    {historyRow.loginDuration
                                                        ? historyRow.loginDuration
                                                        : '-'}
                                                </TableCell>
                                                <TableCell
                                                    className={cx(
                                                        classes.nestedTableRow,
                                                        {
                                                            [classes.lastRow]:
                                                                i ===
                                                                row.logins
                                                                    .length -
                                                                    1,
                                                        }
                                                    )}
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                >
                                                    {historyRow.loginTime}
                                                </TableCell>
                                                <TableCell
                                                    className={cx(
                                                        classes.nestedTableRow,
                                                        {
                                                            [classes.lastRow]:
                                                                i ===
                                                                row.logins
                                                                    .length -
                                                                    1,
                                                        }
                                                    )}
                                                    align={
                                                        historyRow.logoutTime
                                                            ? 'center'
                                                            : 'right'
                                                    }
                                                >
                                                    {historyRow.logoutTime
                                                        ? historyRow.logoutTime
                                                        : '-'}
                                                    <Popconfirm
                                                        title="Are you sure?"
                                                        onConfirm={() =>
                                                            deleteLoginEntry(
                                                                historyRow.uid
                                                            )
                                                        }
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button
                                                            type="link"
                                                            className={
                                                                classes.deleteBtn
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTrashAlt
                                                                }
                                                            />
                                                        </Button>
                                                    </Popconfirm>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            <Modal
                title={'Update Login Data'}
                open={showUpdateLoginModal}
                onOk={updateLoginData}
                confirmLoading={loading}
                onCancel={hideUpdateLoginModal}
                okText={'Update'}
                destroyOnClose={true}
                width={600}
            >
                {showUpdateLoginModal ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Login Time"
                            style={{ marginBottom: 10 }}
                            name="loginTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required!',
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeLoginTime}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Logout Time"
                            style={{ marginBottom: 10 }}
                            name="logoutTime"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <TimePicker
                                placeholder="Select Time"
                                format="HH:mm"
                                onChange={onChangeLogoutTime}
                            />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
        </React.Fragment>
    )
}

export default ILEmployeeTableRow

const useStyles = makeStyles()((theme) => ({
    tableHead: {
        fontSize: '15px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
        },
    },
    tableRow: {
        fontSize: '16px',
        whiteSpace: 'nowrap',
        borderBottom: '0px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    nestedTableRow: {
        fontSize: '14px',
        whiteSpace: 'nowrap',
        padding: '6px 11px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
        },
    },
    lastRow: {
        borderBottom: 'none',
    },
    nestedTable: {
        paddingBottom: 0,
        paddingTop: 0,
        background: '#f5f6f8',
        '&.MuiTableCell-root': {
            padding: 0,
            borderBottom: 'none',
        },
    },
    historyTable: {
        '& .MuiTableCell-root': {
            paddingLeft: '30px !important',
        },
    },
    arrow: {
        width: '20px',
        height: '20px',
        marginRight: '3px',
    },
    tableContainer: {
        margin: 1,
        width: 'fit-content',
        marginLeft: 'auto',
    },
    tableRowDate: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '0px',
    },
    active: {
        animation: `${keyframes`
      0% { background: #99ccff; }
      100% { background: #fff; }
    `} 1.5s ease-in-out`,
    },
    holiday: {
        color: '#cd7722',
    },
    leave: {
        color: '#3598db',
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    deleteBtn: {
        border: 'none',
        color: 'red',
        padding: '0px 10px',
    },
}))
