import React, { useEffect, useState } from 'react'
import {
    Input,
    Button,
    Row,
    Col,
    message,
    Select,
    Radio,
    Form,
    Checkbox,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { RestApi } from '../../RestApi'
import Stream from './SubComponents/Stream'
import GLOBAL from '../../Globals'
import TinyMceEditor from '../Common/TinyMceEditor'
import { goBack } from '../../Util/ILUtil'
const { Option } = Select
const { TextArea } = Input

const AddQuestion = (props) => {
    const [optionGroupFields, setOptionGroupFields] = useState([0])
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedChapter, setSelectedChapter] = useState('')
    const [selectedSection, setSelectedSection] = useState('')
    const [subjectData, setSubjectData] = useState([])
    const [chapterData, setChapterData] = useState({})
    const [sectionData, setSectionData] = useState({})
    const [topicData, setTopicData] = useState({})
    const [difficultyData, setDifficultyData] = useState([])
    const [isFeedbackPlainText, setIsFeedbackPlainText] = useState(true)
    const [isPlainText, setIsPlainText] = useState(true)
    const [questionDetails, setQuestionDetails] = useState({})
    const [questionHtml, setQuestionHtml] = useState('')
    const [feedbackHtml, setFeedbackHtml] = useState('')
    const [loading, setLoading] = useState(false)
    const [courseTest, setCourseTest] = useState(false)
    const [form] = Form.useForm()
    let question = {}
    let testId = ''
    if (props.location.state) {
        if (props.location.state.question) {
            question = props.location.state.question
        }
        testId = props.location.state.testId
    }
    const isQuestionPresent = Object.keys(question).length > 0
    const [course, setCourse] = useState(
        isQuestionPresent && question.stream ? question.stream.course : null
    )
    const [level, setLevel] = useState(
        isQuestionPresent && question.stream ? question.stream.level : null
    )

    useEffect(() => {
        getRequiredData()
        checkEdit()
        //eslint-disable-next-line
    }, [])

    const checkEdit = () => {
        if (testId) {
            RestApi.doGet(
                GLOBAL.URL.GET_QUESTION_DETAILS_BY_TEST_ID + testId
            ).then((response) => {
                setQuestionDetails(response.data)
                handleSetQuestionDetails(response.data)
            })
        }
        if (isQuestionPresent) {
            var length = question.options.length
            var arrayFields = []
            for (var i = 0; i < length; i++) {
                arrayFields.push(i)
            }
            setIsFeedbackPlainText(question.feedbackPlainText)
            setIsPlainText(question.plainText)
            if (!question.plainText) {
                setQuestionHtml(question.question)
            }
            if (!question.feedbackPlainText && question.feedback) {
                setFeedbackHtml(question.feedback)
            }
            setOptionGroupFields(arrayFields)
            form.setFieldsValue({
                ...question,
                isQuestionPlainText: question.plainText,
                isFeedbackPlainText: question.feedbackPlainText,
            })
            handleSetQuestionDetails(question)
        }
    }
    const handleSetQuestionDetails = (question) => {
        if (question.test.testNameUnique) {
            setCourseTest(true)
        }

        if (!question.stream) {
            return
        }

        setCourse(question.stream.course)
        setLevel(question.stream.level)
        form.setFieldsValue({ subjectId: question.subjectId })
        getSubjectsData(question.stream.course, question.stream.level).then(
            (response) => {
                selectSubject(question.subjectId, null, response).then(
                    (res) => {
                        if (question.chapterId) {
                            selectChapter(
                                question.chapterId,
                                null,
                                response,
                                res
                            ).then((chapterRes) => {
                                if (question.sectionId) {
                                    selectSection(
                                        question.sectionId,
                                        null,
                                        res,
                                        chapterRes
                                    )
                                }
                            })
                        }
                    }
                )
            }
        )
    }

    const handleEditorQuestionChange = (newValue, e) => {
        setQuestionHtml(newValue)
    }

    const handleFeedbackQuestionChange = (newValue, e) => {
        setFeedbackHtml(newValue)
    }

    const getRequiredData = () => {
        RestApi.doGet(GLOBAL.URL.GET_DIFFICULTY_LEVELS).then((res) => {
            setDifficultyData(res.data)
        })
    }

    const handleSubmit = (editQuestionID) => {
        form.validateFields()
            .then((values) => {
                setLoading(true)
                values.options.forEach((item) => {
                    if (item.correct === undefined) {
                        item.correct = false
                    }
                })
                if (testId) {
                    values['testId'] = testId
                }

                if (!isPlainText) {
                    values['question'] = questionHtml
                }
                if (!isFeedbackPlainText) {
                    values['feedback'] = feedbackHtml
                }
                var url = ''
                if (editQuestionID) {
                    url = GLOBAL.URL.UPDATE_QUESTION
                    if (courseTest) {
                        url = GLOBAL.URL.UPDATE_COURSE_TEST_QUESTION
                    }
                    values['id'] = editQuestionID
                } else {
                    url = GLOBAL.URL.ADD_QUESTION
                    if (courseTest) {
                        url = GLOBAL.URL.ADD_COURSE_TEST_QUESTION
                        values['testId'] = questionDetails.test.testId
                    }
                }

                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodyCourseObject)
                    .then((response) => {
                        message.success(response.data)
                        goBack(props.navigate)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {
                //empty catch block
            })
    }

    const getSubjectsData = async (course, level) => {
        return new Promise((resolve, reject) => {
            RestApi.doGet(
                GLOBAL.URL.GET_SUBJECTS_BY_STREAM +
                    '?course=' +
                    course +
                    '&level=' +
                    level
            )
                .then((res) => {
                    setSubjectData(res.data)
                    resolve(res.data)
                })
                .catch((error) => {
                    message.error(error)
                })
        })
    }

    const selectSubject = (value, option, subjectDataNew = subjectData) => {
        return new Promise((resolve, reject) => {
            setSelectedSubject(value)
            getChapterData(value, subjectDataNew)
                .then(() => {
                    resolve(value)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    const selectChapter = (
        value,
        optionVal,
        subjectDataNew = subjectData,
        selectedSubjectNew = selectedSubject
    ) => {
        return new Promise((resolve, reject) => {
            setSelectedChapter(value)
            getSectionsData(value, subjectDataNew, selectedSubjectNew).then(
                () => {
                    resolve(value)
                }
            )
        })
    }
    const selectSection = (value, sectionVal, subjectNew, chapterNew) => {
        if (!value) {
            setTopicData([])
            return
        }
        setSelectedSection(value)
        getTopicsData(value, subjectNew, chapterNew)
    }

    const getTopicsData = (
        selectedSectionVal = selectedSection,
        subjectNew = selectedSubject,
        chapterNew = selectedChapter
    ) => {
        if (!topicData[selectedSectionVal]) {
            var sectionName = sectionData[chapterNew].filter((item) => {
                return item.id === selectedSectionVal
            })
            var chapterName = chapterData[subjectNew].filter((item) => {
                return item.id === chapterNew
            })
            var subjectName = subjectData.filter((item) => {
                return item.id === subjectNew
            })

            if (!subjectName[0] || chapterName[0] || sectionName[0]) {
                return
            }

            RestApi.doGet(
                GLOBAL.URL.GET_TOPICS +
                    '?subject=' +
                    encodeURIComponent(subjectName[0].name) +
                    '&chapter=' +
                    encodeURIComponent(chapterName[0].name) +
                    '&section=' +
                    encodeURIComponent(sectionName[0].name)
            )
                .then((res) => {
                    var existingState = topicData
                    existingState[selectedSectionVal] = res.data
                    setTopicData(existingState)
                })
                .catch((error) => {
                    message.error(error.toString())
                })
        }
    }
    const getSectionsData = (
        selectedChapterVal = selectedChapter,
        subjectDataNew = subjectData,
        selectedSubjectNew = selectedSubject
    ) => {
        return new Promise((resolve, reject) => {
            if (!sectionData[selectedChapterVal]) {
                var chapterName = chapterData[selectedSubjectNew].filter(
                    (item) => {
                        return item.id === selectedChapterVal
                    }
                )
                var subjectName = subjectDataNew.filter((item) => {
                    return item.id === selectedSubjectNew
                })
                RestApi.doGet(
                    GLOBAL.URL.GET_SECTIONS +
                        '?subject=' +
                        encodeURIComponent(subjectName[0].name) +
                        '&chapter=' +
                        encodeURIComponent(chapterName[0].name)
                )
                    .then((res) => {
                        var existingState = sectionData
                        existingState[selectedChapterVal] = res.data
                        setSectionData(existingState)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                resolve()
            }
        })
    }

    const getChapterData = (
        selectedSubjectValue = selectedSubject,
        subjectDataNew = subjectData
    ) => {
        return new Promise((resolve, reject) => {
            if (!chapterData[selectedSubjectValue]) {
                var subjectName = subjectDataNew.filter((item) => {
                    return item.id === selectedSubjectValue
                })
                RestApi.doGet(
                    GLOBAL.URL.GET_CHAPTERS_BY_SUBJECT +
                        '?subject=' +
                        encodeURIComponent(subjectName[0].name)
                )
                    .then((res) => {
                        var existingState = chapterData
                        existingState[selectedSubjectValue] = res.data
                        setChapterData(existingState)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                resolve()
            }
        })
    }

    const onChangeQuestionPlainText = (e) => {
        setIsPlainText(e.target.value)
    }

    const onChangeFeedbackRadio = (e) => {
        setIsFeedbackPlainText(e.target.value)
    }

    const formItems = () => {
        return (
            <Form.List name="options">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div
                                key={key}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    gap: '8px',
                                }}
                            >
                                <Form.Item
                                    {...restField}
                                    label={index === 0 ? 'is Correct' : ''}
                                    name={[name, 'correct']}
                                    valuePropName="checked"
                                    style={{ width: '200px' }}
                                >
                                    <Checkbox defaultChecked={false}>
                                        is Correct
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    label={index === 0 ? 'option' : ''}
                                    name={[name, 'option']}
                                    style={{ width: '450px' }}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input option or delete this field.',
                                        },
                                    ]}
                                >
                                    <TextArea
                                        rows={1}
                                        placeholder="Enter Option"
                                        autoSize
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={''}
                                    style={{ marginTop: index === 0 ? 30 : 0 }}
                                >
                                    {fields.length > 1 && (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    style={{ display: 'none' }}
                                    name={[name, 'id']}
                                ></Form.Item>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                icon={<PlusOutlined />}
                                style={{ width: '60%' }}
                            >
                                Add Option
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        )
    }

    var difficultyOptionsView = []
    difficultyData.forEach((item) => {
        difficultyOptionsView.push(
            <Option value={item.id}>{item.difficulty}</Option>
        )
    })
    var subjectOptionsView = []
    subjectData.forEach((item) => {
        subjectOptionsView.push(<Option value={item.id}>{item.name}</Option>)
    })
    var chapterOptionsView = []
    if (chapterData[selectedSubject]) {
        chapterData[selectedSubject].forEach((item) => {
            chapterOptionsView.push(
                <Option value={item.id}>{item.name}</Option>
            )
        })
    }

    var sectionOptionsView = []
    if (sectionData[selectedChapter]) {
        sectionData[selectedChapter].forEach((item) => {
            sectionOptionsView.push(
                <Option value={item.id}>{item.name}</Option>
            )
        })
    }
    var topicOptionsView = []
    if (topicData[selectedSection]) {
        topicData[selectedSection].forEach((item) => {
            topicOptionsView.push(<Option value={item.id}>{item.name}</Option>)
        })
    }

    return (
        <div>
            <h2>{isQuestionPresent ? 'Update ' : 'Add '} Question</h2>
            {!courseTest ? (
                <Col>
                    <Row style={{ marginBottom: 5 }}>
                        <Stream
                            course={course}
                            level={level}
                            selectedCourse={(streamData) => {
                                if (streamData) {
                                    setCourse(streamData[0])
                                    setLevel(streamData[1])
                                    getSubjectsData(
                                        streamData[0],
                                        streamData[1]
                                    )
                                }
                            }}
                        />
                    </Row>
                </Col>
            ) : null}
            <Form
                form={form}
                initialValues={{
                    subjectId: selectedSubject,
                    isQuestionPlainText: isPlainText,
                    isFeedbackPlainText: isFeedbackPlainText,
                    ...optionGroupFields.reduce(
                        (acc, k) => ({
                            ...acc,
                            [`options[${k}].option`]: false,
                        }),
                        {}
                    ),
                }}
                layout="vertical"
            >
                {!courseTest ? (
                    <Row gutter={16} style={{ marginTop: 20 }}>
                        <Col span={12}>
                            <Form.Item
                                label="Subject"
                                name="subjectId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select subject',
                                    },
                                ]}
                                valuePropName="value"
                            >
                                <Select
                                    onChange={selectSubject}
                                    placeholder="Select Subject"
                                >
                                    {subjectOptionsView}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Chapter"
                                name="chapterId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Chapter',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={selectChapter}
                                    placeholder="Select Chapter"
                                >
                                    {chapterOptionsView}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                ) : null}
                <Row gutter={16}>
                    {!courseTest ? (
                        <>
                            <Col span={10}>
                                <Form.Item label="Section" name="sectionId">
                                    <Select
                                        placeholder="Please select section"
                                        onChange={selectSection}
                                        allowClear
                                    >
                                        {sectionOptionsView}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="Topic">
                                    <Select
                                        placeholder="Please select topic"
                                        allowClear
                                    >
                                        {topicOptionsView}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>
                    ) : null}

                    <Col span={4}>
                        <Form.Item
                            label="Difficulty"
                            name="difficultyId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Difficulty',
                                },
                            ]}
                        >
                            <Select placeholder="Please select difficulty">
                                {difficultyOptionsView}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Is Question Plain Text"
                            name="isQuestionPlainText"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Difficulty',
                                },
                            ]}
                            valuePropName="value"
                        >
                            <Radio.Group onChange={onChangeQuestionPlainText}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                {isPlainText ? (
                    <Form.Item
                        label={'Question'}
                        name="question"
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Question',
                            },
                        ]}
                    >
                        <TextArea rows={1} placeholder="Enter Question" />
                    </Form.Item>
                ) : (
                    <Form.Item>
                        <TinyMceEditor
                            handleEditorChange={handleEditorQuestionChange}
                            initialValue={questionHtml}
                        ></TinyMceEditor>
                    </Form.Item>
                )}
                {formItems()}
                <Row gutter={16}>
                    <Col span={6}>
                        <Form.Item
                            label="Is Feedback Plain Text"
                            name="isFeedbackPlainText"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Difficulty',
                                },
                            ]}
                            valuePropName="value"
                        >
                            <Radio.Group onChange={onChangeFeedbackRadio}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                {isFeedbackPlainText ? (
                    <Form.Item
                        label={'feedback'}
                        name="feedback"
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <TextArea rows={1} placeholder="Enter feedback" />
                    </Form.Item>
                ) : (
                    <Form.Item>
                        <TinyMceEditor
                            handleEditorChange={handleFeedbackQuestionChange}
                            initialValue={feedbackHtml}
                        ></TinyMceEditor>
                    </Form.Item>
                )}
                <Form.Item>
                    {isQuestionPresent ? (
                        <Button
                            type="primary"
                            onClick={() => {
                                handleSubmit(question.id)
                            }}
                            loading={loading}
                        >
                            Update
                        </Button>
                    ) : (
                        <Button
                            type="primary"
                            onClick={() => {
                                handleSubmit()
                            }}
                            loading={loading}
                        >
                            Add
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddQuestion
