import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Descriptions,
    Radio,
    Button,
    Tag,
    Select,
    Modal,
    DatePicker,
    message,
    Table,
    Input,
    InputNumber,
    notification,
    Form,
} from 'antd'
import clone from 'clone'
import TransactionConstants from '../Transactions/TransactionConstants'
import SendWhatsAppToLead from '../Sales/SendWhatsAppToLead'
import { EditTwoTone } from '@ant-design/icons'
import dayjs from 'dayjs'

const DATA_TYPE_LEAD = 'lead'
const DATA_TYPE_TRANSACTIONS = 'transactions'
const DATA_TYPE_ICASH = 'icash'
const DATA_TYPE_COURSES = 'courses'

const COURSE_FILTER_ACTIVE = 'active'
const COURSE_FILTER_FREE = 'free'
const COURSE_FILTER_EXPIRED = 'expired'

function ExternalSalesLeadDetails(props) {
    const [form] = Form.useForm()
    const { Option } = Select
    const { TextArea } = Input

    const [loading, setLoading] = useState(false)
    const [userId, setUserId] = useState()
    const [userData, setUserData] = useState({})
    const [dataType, setDataType] = useState(DATA_TYPE_LEAD)
    const [updateLeadModalVisible, setUpdateLeadModalVisible] = useState(false)
    const [assignToFilterData, setAssignToFilterData] = useState([])
    const [assignToModalVisible, setAssignToModalVisible] = useState(false)
    const [cart, setCart] = useState({})
    const [updateReminderDateModalVisible, setUpdateReminderDateModalVisible] =
        useState(false)
    const [reminderDate, setReminderDate] = useState()
    const [addCartCommentModalVisible, setAddCartCommentModalVisible] =
        useState(false)
    const [openOrClose, setOpenOrClose] = useState(true)
    const [leadSubTypes, setLeadSubTypes] = useState([])
    const [openStatusTypes, setOpenStatusTypes] = useState([])
    const [closedStatusTypes, setClosedStatusTypes] = useState([])
    const [leadSources, setLeadSources] = useState([])
    const [transactions, setTransactions] = useState([])
    const [addCallEntryModalVisible, setAddCallEntryModalVisible] =
        useState(false)
    const [courseFilter, setCourseFilter] = useState(COURSE_FILTER_ACTIVE)
    const [courses, setCourses] = useState([])
    const [iCash, setICash] = useState(0)
    const [iCashHistory, setICashHistory] = useState([])
    const [iCashNextUrl, setICashNextUrl] = useState('')
    const [streamData, setStreamData] = useState([])
    const [levelData, setLevelData] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('')

    useEffect(() => {
        const { id } = props.match.params
        if (id) {
            setLoading(true)
            setUserId(id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!userId) {
            return
        }

        getUserDetails()
        RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
            setStreamData(JSON.parse(res.data))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])

    useEffect(() => {
        if (dataType !== DATA_TYPE_COURSES || !courseFilter) {
            return
        }

        getCourses()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseFilter])

    const getUserDetails = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_DETAILS + userId)
            .then((res) => {
                setUserData(res.data)
                setCart(res.data.cart ? res.data.cart : {})
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const onChangeDataTypeSelection = (e) => {
        setDataType(e.target.value)

        if (e.target.value === DATA_TYPE_TRANSACTIONS) {
            if (transactions.length === 0) {
                setLoading(true)

                RestApi.doGet(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_TRANSACTIONS +
                        userId
                )
                    .then((res) => {
                        setTransactions(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        } else if (e.target.value === DATA_TYPE_COURSES) {
            getCourses()
        } else if (e.target.value === DATA_TYPE_ICASH) {
            getIcashData()
        }
    }

    const onChangeCourseFilter = (e) => {
        setCourseFilter(e.target.value)
    }

    const getCourses = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_COURSE_DETAILS +
                userId +
                '&type=' +
                courseFilter
        )
            .then((res) => {
                setCourses(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getIcashData = () => {
        setLoading(true)

        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_ICASH_BALANCE + userId
        ).then((res) => {
            setICash(res.data)
        })

        RestApi.doGet(
            GLOBAL.URL.SALES.EXTERNAL_SALES_GET_LEAD_ICASH_HISTORY + userId
        )
            .then((res) => {
                var response = JSON.parse(res.data)
                setICashHistory(response.data)
                setICashNextUrl(response.next)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showUpdateLeadModal = () => {
        if (cart.stream) {
            handleCourseChange(cart.stream)
        } else {
            setSelectedCourse('')
        }

        if (openStatusTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_OPEN_STATUS_TYPES).then(
                (res) => {
                    setOpenStatusTypes(res.data)
                }
            )
        }

        if (closedStatusTypes.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_CART_LEAD_CLOSED_STATUS_TYPES).then(
                (res) => {
                    setClosedStatusTypes(res.data)
                }
            )
        }

        if (assignToFilterData.length === 0) {
            RestApi.doGet(
                GLOBAL.URL.SALES.EXTERNAL_SALES_GET_ASSIGNED_TO_FILTER_DATA
            ).then((res) => {
                setAssignToFilterData(res.data)
            })
        }

        if (leadSources.length === 0) {
            RestApi.doGet(GLOBAL.URL.LEADS.GET_LEAD_SOURCES).then((res) => {
                setLeadSources(res.data)
            })
        }

        setUpdateLeadModalVisible(true)

        var recordClone = clone(cart)
        setTimeout(() => {
            if (recordClone.open) {
                recordClone.type = recordClone.openType
                recordClone.subType = recordClone.openSubType
            } else {
                recordClone.type = recordClone.closedType
                recordClone.subType = recordClone.closedSubType
            }

            getLeadSubTypes(recordClone.type)

            if (recordClone.reminderDate) {
                setReminderDate(cart.reminderDate)
                var reminderDate = dayjs(
                    recordClone.reminderDate,
                    'DD-MMM-YY HH:mm'
                )
                recordClone.reminderDate = reminderDate
            }

            if (recordClone.assignedTo && recordClone.assignedTo.id) {
                recordClone.assignToUserId = recordClone.assignedTo.id
            }

            setTimeout(() => {
                form.setFieldsValue({
                    assignToUserId: recordClone.assignToUserId,
                    type: recordClone.type,
                    subType: recordClone.subType,
                    comment: recordClone.comment,
                    reminderDate: recordClone.reminderDate,
                    source: recordClone.source,
                    stream: recordClone.stream,
                    level: recordClone.level,
                })
            }, 100)
        }, 100)
    }

    const hideUpdateLeadModal = () => {
        setUpdateLeadModalVisible(false)
    }

    const showAssignToModal = () => {
        if (assignToFilterData.length === 0) {
            RestApi.doGet(
                GLOBAL.URL.SALES.EXTERNAL_SALES_GET_ASSIGNED_TO_FILTER_DATA
            ).then((res) => {
                setAssignToFilterData(res.data)
            })
        }

        setAssignToModalVisible(true)
    }

    const hideAssignToModal = () => {
        setAssignToModalVisible(false)
    }

    const showUpdateReminderDateModal = () => {
        setUpdateReminderDateModalVisible(true)
        setReminderDate('')
    }

    const hideUpdateReminderDateModal = () => {
        setUpdateReminderDateModalVisible(false)
        setReminderDate('')
    }

    const cartItemsTableColumns = [
        {
            title: '#',
            dataIndex: 'cnt',
            key: 'cnt',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: 100,
        },
        {
            title: 'Attempt',
            dataIndex: 'attempt',
            key: 'attempt',
            width: 100,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
    ]

    const leadExternalCommentsColumns = [
        {
            title: '#',
            dataIndex: 'uid',
            key: 'uid',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 120,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.createdAt}</span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
    ]

    const leadExternalCourseColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => (
                <span style={{ fontSize: 11 }}>{record.createdAt}</span>
            ),
        },
    ]

    const LeadDetailsTab = () => {
        return (
            <div style={{ marginTop: 10 }}>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showUpdateLeadModal}
                    >
                        Update
                    </Button>
                    {cart.date ? (
                        <Button
                            type="default"
                            style={{ marginLeft: 20 }}
                            size="small"
                            onClick={showAddCallEntryModal}
                        >
                            Add Call Entry
                        </Button>
                    ) : null}
                    <SendWhatsAppToLead userData={userData} cartData={cart} />
                </div>
                <Descriptions
                    title=""
                    bordered
                    size="small"
                    style={{ marginTop: 10 }}
                >
                    <Descriptions.Item label="Status">
                        {cart ? (
                            <span>
                                {cart.open ? 'Open' : 'Closed'}
                                <span style={{ marginLeft: 20 }}>
                                    <Tag
                                        color="purple"
                                        style={{ marginLeft: 5 }}
                                    >
                                        {cart.open
                                            ? cart.openType
                                            : cart.closedType}
                                    </Tag>
                                </span>
                            </span>
                        ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Assigned To">
                        {cart && cart.assignedTo && cart.assignedTo.id ? (
                            <>
                                <Button
                                    type="link"
                                    onClick={showAssignToModal}
                                    style={{ padding: 0 }}
                                >
                                    <EditTwoTone style={{ fontSize: 11 }} />
                                </Button>
                                <b style={{ marginLeft: 5 }}>
                                    {cart.assignedTo
                                        ? cart.assignedTo.firstName
                                        : ''}
                                </b>
                            </>
                        ) : cart ? (
                            <Button size="small" onClick={showAssignToModal}>
                                Assign
                            </Button>
                        ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date">
                        {cart.date}
                    </Descriptions.Item>
                    <Descriptions.Item label="Coupon">
                        {cart.coupon
                            ? cart.coupon.couponName +
                              ' (' +
                              cart.coupon.discount +
                              cart.coupon.type +
                              ')'
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Source">
                        {cart.source}
                    </Descriptions.Item>
                    <Descriptions.Item label="Follow Up">
                        <span>
                            {cart.reminderDate ? cart.reminderDate : '-'}
                        </span>
                        {cart.date ? (
                            <Button
                                type="default"
                                size="small"
                                onClick={showUpdateReminderDateModal}
                                style={{ marginLeft: 10 }}
                            >
                                Update
                            </Button>
                        ) : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Course Stream">
                        {cart.stream ? cart.stream + ' - ' + cart.level : ''}
                    </Descriptions.Item>
                </Descriptions>
                <h4 style={{ marginTop: 10 }}>
                    Total Cart Value: {cart.totalMrp}
                </h4>
                <Table
                    loading={loading}
                    columns={cartItemsTableColumns}
                    dataSource={cart.cartItems}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    size="small"
                    rowKey="cnt"
                />
                <div>
                    <h4 style={{ marginTop: 10 }}>Comments</h4>
                    {cart.date ? (
                        <Button
                            type="primary"
                            size="small"
                            onClick={showAddCartCommentModal}
                        >
                            Add
                        </Button>
                    ) : null}
                    <Table
                        loading={loading}
                        columns={cartCommentsColumns}
                        dataSource={userData.leadComments}
                        pagination={false}
                        style={{ marginTop: 8 }}
                        size="small"
                        rowKey="id"
                    />
                </div>
                {cart && cart.leadExternal && cart.leadExternal.leadId ? (
                    <>
                        <h4 style={{ marginTop: 10 }}>
                            Lead External Comments
                        </h4>
                        <Table
                            columns={leadExternalCommentsColumns}
                            dataSource={userData.leadExternalComments}
                            pagination={false}
                            style={{ marginTop: 8 }}
                            size="small"
                            rowKey="uid"
                        />
                        {cart &&
                        cart.leadExternalCourses &&
                        cart.leadExternalCourses.length === 0 ? null : (
                            <>
                                <h4 style={{ marginTop: 10 }}>
                                    Lead External Interested Courses
                                </h4>
                                <Table
                                    columns={leadExternalCourseColumns}
                                    dataSource={userData.leadCoursesDTO}
                                    pagination={false}
                                    size="small"
                                    style={{ marginTop: 10 }}
                                    rowKey="id"
                                />
                            </>
                        )}
                    </>
                ) : null}
            </div>
        )
    }

    const cartCommentsColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 120,
            render: (text, record) => (
                <span style={{ fontSize: 12 }}>{record.date}</span>
            ),
        },
        {
            title: 'Comments',
            dataIndex: 'comments',
            key: 'comments',
            render: (text, record) => (
                <span
                    style={{
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    {record.comments}
                </span>
            ),
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => (
                <span>
                    {record.callDurationMins + ':' + record.callDurationSecs}
                </span>
            ),
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
            render: (text, record) => (
                <span>{record.commentedBy.firstName}</span>
            ),
        },
    ]

    const showAddCartCommentModal = () => {
        setAddCartCommentModalVisible(true)
    }

    const hideAddCartCommentModal = () => {
        setAddCartCommentModalVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const getAssignToFilterDataOptions = () => {
        var options = []
        assignToFilterData.forEach((user) => {
            options.push(
                <Option
                    value={user.id}
                    key={user.firstName + ' ' + user.lastName}
                >
                    {user.firstName + ' ' + user.lastName}
                </Option>
            )
        })

        return options
    }

    const assignLead = () => {
        form.validateFields(['assignToUserId'])
            .then((values) => {
                setLoading(true)

                values.userId = userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_ASSIGN_LEAD,
                    formData
                )
                    .then((response) => {
                        getUserDetails()
                        hideAssignToModal()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const updateReminderDate = () => {
        if (!reminderDate) {
            message.error('Select Date!')
            return
        }

        setLoading(true)

        var payload = {}
        payload.userId = userId
        payload.reminderDate = reminderDate

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.SALES.EXTERNAL_SALES_UPDATE_LEAD_REMINDER_DATE,
            formData
        )
            .then((response) => {
                var cartClone = clone(cart)
                cartClone.reminderDate = reminderDate
                setCart(cartClone)
                hideUpdateReminderDateModal()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const addCartComment = () => {
        form.validateFields(['comments'])
            .then((values) => {
                setLoading(true)

                var payload = {}
                payload.userId = userId
                payload.comments = values.comments

                var formData = new FormData()
                formData.append('payload', JSON.stringify(payload))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_ADD_LEAD_COMMENTS,
                    formData
                )
                    .then((response) => {
                        hideAddCartCommentModal()
                        getUserDetails()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const openCloseOptions = () => {
        var options = []
        options.push(
            <Radio.Button value={true} key={'open'}>
                Open
            </Radio.Button>
        )
        options.push(
            <Radio.Button value={false} key={'close'}>
                Close
            </Radio.Button>
        )

        return options
    }

    const onOpenCloseChange = (e) => {
        setOpenOrClose(e.target.value)
    }

    const onChangeLeadType = (e) => {
        getLeadSubTypes(e.target.value)
    }

    const getLeadSubTypes = (parentType) => {
        setLeadSubTypes([])

        RestApi.doGet(
            GLOBAL.URL.LEADS.GET_LEAD_SUB_TYPES_ENABLED +
                'parentType=' +
                parentType +
                '&parentStatusType=' +
                (openOrClose ? 'Open' : 'Closed')
        ).then((res) => {
            setLeadSubTypes(res.data)
        })
    }

    const openStatusTypeOptions = () => {
        var options = []
        openStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const closedStatusTypeOptions = () => {
        var options = []
        closedStatusTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const leadSubTypeOptions = () => {
        var options = []
        leadSubTypes.forEach((item) => {
            options.push(
                <Radio.Button value={item.type} key={item.type}>
                    {item.type}
                </Radio.Button>
            )
        })

        return options
    }

    const leadSourceOptions = () => {
        var options = []
        leadSources.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const updateLead = () => {
        form.validateFields([
            'type',
            'subType',
            'assignToUserId',
            'comment',
            'source',
            'callDurationMins',
            'callDurationSecs',
            'stream',
            'level',
        ])
            .then((values) => {
                setLoading(true)

                if (!values.subType) {
                    values.subType = ''
                }

                values.userId = userId
                values.open = openOrClose
                values.reminderDate = reminderDate

                if (!values.source) {
                    values.source = ''
                }

                if (!values.stream) {
                    values.stream = ''
                    values.level = ''
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_UPDATE_LEAD,
                    formData
                )
                    .then((response) => {
                        message.success(response.data)
                        hideUpdateLeadModal()
                        getUserDetails()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const userTransactionsTableColumns = [
        {
            title: 'ID',
            dataIndex: 'transactionId',
            key: 'transactionId',
            render: (text, record) => (
                <span style={{ maxWidth: 150 }}>{record.transactionId}</span>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Tag color={returnTransactionColor(record.status)}>
                    {record.status}
                </Tag>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'initiatedAt',
            key: 'initiatedAt',
        },
        {
            title: 'MRP | Pricce',
            dataIndex: 'mrp',
            key: 'mrp',
            render: (text, record) => (
                <span>
                    {record.mrp + ' | '}
                    <b>{record.price}</b>
                </span>
            ),
        },
        {
            title: 'Credits | Discount',
            dataIndex: 'credits',
            key: 'credits',
            render: (text, record) => (
                <span>
                    {record.credits} | {record.discount}{' '}
                    {record.couponName ? ' | ' + record.couponName : null}
                </span>
            ),
        },
        {
            title: 'Courses',
            dataIndex: 'courses',
            key: 'courses',
            render: (text, record) => <ul>{getCourseNames(record.items)}</ul>,
        },
    ]

    const getCourseNames = (record) => {
        var names = record.map((item) => (
            <li key={item.courseName}>
                {item.attempt + ' | ' + item.courseName}
            </li>
        ))

        return names
    }

    const returnTransactionColor = (status) => {
        var color = ''
        if (status === TransactionConstants.TRANSACTION_CONSTANTS.SUCCESS) {
            color = GLOBAL.COLORAPP.EMARALD
        } else if (
            status === TransactionConstants.TRANSACTION_CONSTANTS.PENDING
        ) {
            color = GLOBAL.COLORAPP.SUNFLOWER
        } else {
            color = GLOBAL.COLORAPP.ALIZARIN
        }
        return color
    }

    const LeadTransactionsTab = () => {
        return (
            <div>
                <Table
                    loading={loading}
                    columns={userTransactionsTableColumns}
                    dataSource={transactions}
                    pagination={false}
                    style={{ marginTop: 8 }}
                    rowKey="transactionId"
                />
            </div>
        )
    }

    const LeadCoursesTab = () => {
        const coursesTableColumns = [
            {
                title: '#',
                dataIndex: 'id',
                key: 'id',
                width: 50,
                render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
                title: 'Course Name',
                dataIndex: 'courseName',
                key: 'courseName',
            },
            {
                title: 'Enrolled',
                dataIndex: 'enrolledAt',
                key: 'enrolledAt',
            },
            {
                title: 'Attempt',
                dataIndex: 'attempt',
                key: 'attempt',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Completed || Started %',
                dataIndex: 'totalSections',
                key: 'totalSections',
                render: (text, record) => (
                    <span>
                        {Math.floor(
                            (record.completedSections / record.totalSections) *
                                100
                        )}
                        {' || '}
                        {Math.floor(
                            (record.startedSections / record.totalSections) *
                                100
                        )}
                    </span>
                ),
            },
        ]

        return (
            <div style={{ marginTop: 10 }}>
                <div>
                    <Radio.Group
                        value={courseFilter}
                        onChange={onChangeCourseFilter}
                    >
                        <Radio.Button
                            value={COURSE_FILTER_FREE}
                            key={COURSE_FILTER_FREE}
                        >
                            Free
                        </Radio.Button>
                        <Radio.Button
                            value={COURSE_FILTER_ACTIVE}
                            key={COURSE_FILTER_ACTIVE}
                        >
                            Active
                        </Radio.Button>
                        <Radio.Button
                            value={COURSE_FILTER_EXPIRED}
                            key={COURSE_FILTER_EXPIRED}
                        >
                            Expired
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <Table
                    loading={loading}
                    columns={coursesTableColumns}
                    dataSource={courses}
                    pagination={false}
                    rowKey="id"
                />
            </div>
        )
    }

    const getNextiCashHistory = () => {
        if (iCashNextUrl) {
            setLoading(true)

            RestApi.doGet(GLOBAL.URL.BASE_URL + iCashNextUrl)
                .then((res) => {
                    var response = JSON.parse(res.data)
                    setICashNextUrl(response.next)
                    setICashHistory([...iCashHistory, ...response.data])
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const LeadIcashTab = () => {
        const icashHistoryTableColumns = [
            {
                title: 'Reward',
                dataIndex: 'reward',
                key: 'reward',
            },
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: 'Expiry',
                dataIndex: 'expiry',
                key: 'expiry',
            },
        ]

        return (
            <div style={{ marginTop: 10 }}>
                <Tag color="geekblue" style={{ marginTop: 5 }}>
                    Balance: {iCash}
                </Tag>
                <Table
                    loading={loading}
                    columns={icashHistoryTableColumns}
                    dataSource={iCashHistory}
                    pagination={false}
                    style={{ marginTop: 8 }}
                />
                {iCashNextUrl ? (
                    <Button
                        type="primary"
                        onClick={getNextiCashHistory}
                        style={{ marginTop: 8 }}
                        loading={loading}
                    >
                        More
                    </Button>
                ) : null}
            </div>
        )
    }

    const showAddCallEntryModal = () => {
        setAddCallEntryModalVisible(true)
    }

    const hideAddCallEntryModal = () => {
        setAddCallEntryModalVisible(false)
    }

    const addCallEntry = () => {
        form.validateFields(['callDurationMins', 'callDurationSecs'])
            .then((values) => {
                setLoading(true)

                values.userId = userId

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.SALES.EXTERNAL_SALES_LEAD_ADD_CALL_ENTRY,
                    formData
                )
                    .then((response) => {
                        RestApi.doGet(
                            GLOBAL.URL.SALES
                                .EXTERNAL_SALES_LEAD_GET_ALL_COMMENTS + userId
                        )
                            .then((res) => {
                                var fullData = clone(userData)
                                fullData.leadComments = res.data

                                setUserData(fullData)
                                hideAddCallEntryModal()
                            })
                            .finally(() => {
                                notification['success']({
                                    message: response.data,
                                })
                                setLoading(false)
                            })
                    })
                    .catch(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(
                <Radio.Button value={item.course} key={item.course}>
                    {item.course}
                </Radio.Button>
            )
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelData.forEach((item) => {
            options.push(
                <Radio.Button value={item} key={item}>
                    {item}
                </Radio.Button>
            )
        })

        return options
    }

    const handleCourseChange = (value) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelData(item.levels)
                setSelectedCourse(value)
            }
        })
    }

    return (
        <div>
            <div style={{ marginTop: 10 }}>
                <Descriptions title="User Details" bordered size="small">
                    <Descriptions.Item label="Name">
                        {userData.firstName
                            ? userData.firstName + ' ' + userData.lastName
                            : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                        {userData.phone
                            ? userData.isd + '-' + userData.phone
                            : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                        {userData.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Joined At">
                        {userData.joinedAt}
                    </Descriptions.Item>
                    <Descriptions.Item label="Stream">
                        {userData.stream
                            ? userData.stream + '-' + userData.level
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="Attempt">
                        {userData.attempt}
                    </Descriptions.Item>
                </Descriptions>
                <Radio.Group
                    value={dataType}
                    onChange={onChangeDataTypeSelection}
                    style={{ marginTop: 15 }}
                >
                    <Radio.Button value={DATA_TYPE_LEAD} key={DATA_TYPE_LEAD}>
                        Lead
                    </Radio.Button>
                    <Radio.Button
                        value={DATA_TYPE_TRANSACTIONS}
                        key={DATA_TYPE_TRANSACTIONS}
                    >
                        Transactions
                    </Radio.Button>
                    <Radio.Button
                        value={DATA_TYPE_COURSES}
                        key={DATA_TYPE_COURSES}
                    >
                        Courses
                    </Radio.Button>
                    <Radio.Button value={DATA_TYPE_ICASH} key={DATA_TYPE_ICASH}>
                        iCash
                    </Radio.Button>
                </Radio.Group>
                {dataType === DATA_TYPE_LEAD ? (
                    <LeadDetailsTab />
                ) : dataType === DATA_TYPE_TRANSACTIONS ? (
                    <LeadTransactionsTab />
                ) : dataType === DATA_TYPE_COURSES ? (
                    <LeadCoursesTab />
                ) : dataType === DATA_TYPE_ICASH ? (
                    <LeadIcashTab />
                ) : null}
            </div>
            <Modal
                title={'Assign Lead'}
                open={assignToModalVisible}
                onOk={assignLead}
                confirmLoading={loading}
                onCancel={hideAssignToModal}
                okText={'Assign'}
                destroyOnClose={true}
            >
                {cart && cart.assignedTo ? (
                    <div style={{ marginBottom: 10 }}>
                        Current assignee:{' '}
                        <strong>{cart.assignedTo.firstName}</strong>
                    </div>
                ) : null}
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Filter"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAssignToFilterDataOptions()}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Set Follow Up Date'}
                open={updateReminderDateModalVisible}
                onOk={updateReminderDate}
                confirmLoading={loading}
                onCancel={hideUpdateReminderDateModal}
                okText={'Update'}
                destroyOnClose={true}
            >
                <div>
                    <div>
                        Current follow up:{' '}
                        <strong>
                            {cart.userId && cart.reminderDate
                                ? cart.reminderDate
                                : '-NA-'}
                        </strong>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <span style={{ marginRight: 10 }}>Select date: </span>
                        <DatePicker
                            placeholder="Select Date"
                            format="DD-MMM-YY HH:mm"
                            onChange={(value, dateString) => {
                                setReminderDate(dateString)
                            }}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                title={'Add Comment'}
                open={addCartCommentModalVisible}
                onOk={addCartComment}
                confirmLoading={loading}
                onCancel={hideAddCartCommentModal}
                okText={'Add Comment'}
                destroyOnClose={true}
                width={800}
            >
                <Form
                    layout="horizontal"
                    {...formItemLayout}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item
                        label="Comments"
                        name="comments"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <TextArea placeholder="Comments" autoFocus />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Update Lead'}
                open={updateLeadModalVisible}
                onOk={updateLead}
                confirmLoading={loading}
                onCancel={hideUpdateLeadModal}
                okText={'Update'}
                destroyOnClose={true}
                width={1000}
            >
                <div style={{ marginLeft: 75, marginBottom: 24 }}>
                    <span>Lead Status: </span>
                    <Radio.Group
                        onChange={onOpenCloseChange}
                        value={openOrClose}
                    >
                        {openCloseOptions()}
                    </Radio.Group>
                </div>
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Lead Type"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Radio.Group onChange={onChangeLeadType}>
                            {openOrClose
                                ? openStatusTypeOptions()
                                : closedStatusTypeOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {leadSubTypes.length > 0 ? (
                        <Form.Item
                            label="Lead Sub Type"
                            name="subType"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group>{leadSubTypeOptions()}</Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Assign To"
                        name="assignToUserId"
                        rules={[
                            {
                                required: true,
                                message: 'Required!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Asignee"
                            showSearch
                            allowClear={true}
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 350 }}
                        >
                            {getAssignToFilterDataOptions()}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Reminder"
                        name="reminderDate"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="Select Date"
                            showTime
                            format="DD-MMM-YY HH:mm"
                            onChange={(value, dateString) => {
                                setReminderDate(dateString)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Source"
                        name="source"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group>{leadSourceOptions()}</Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Course Stream"
                        name="stream"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Radio.Group
                            onChange={(e) => handleCourseChange(e.target.value)}
                        >
                            {streamOptions()}
                        </Radio.Group>
                    </Form.Item>
                    {selectedCourse ? (
                        <Form.Item
                            label="Course Level"
                            name="level"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                        >
                            <Radio.Group placeholder="Select Level">
                                {levelOptions()}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Form.Item
                        label="Call Mins"
                        name="callDurationMins"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        name="callDurationSecs"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={59} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Comment"
                        name="comment"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <TextArea placeholder="Comment" />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={'Add Call Entry'}
                open={addCallEntryModalVisible}
                onOk={addCallEntry}
                confirmLoading={loading}
                onCancel={hideAddCallEntryModal}
                okText={'Add'}
                destroyOnClose={true}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Call Mins"
                        name="callDurationMins"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={100} step={1} />
                    </Form.Item>
                    <Form.Item
                        label="Call Secs"
                        name="callDurationSecs"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <InputNumber min={0} max={59} step={1} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ExternalSalesLeadDetails
