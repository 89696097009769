import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import ILEmployeeDayDataTable from './ILEmployeeDayDataTable'

const ILEmployeeDayHours = ({
    employeeLoginData = {},
    loading,
    getEmployeeLoginData,
    activeDate,
    setViewingDate,
}) => {
    const { classes } = useStyles()
    return (
        <Box className={classes.mainContainer}>
            {loading ? (
                <Box className={classes.noViewContainer}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    {employeeLoginData &&
                    Object.keys(employeeLoginData).length > 0 ? (
                        <Box>
                            <Box className={classes.statsContainer}>
                                {employeeLoginData.days.length > 0 && (
                                    <StatContainer
                                        text="# Days"
                                        count={employeeLoginData.totalLoginDays}
                                    />
                                )}
                                {employeeLoginData.avgLoginDuration && (
                                    <StatContainer
                                        text="Avg Login"
                                        count={
                                            employeeLoginData.avgLoginDuration
                                        }
                                    />
                                )}
                                {employeeLoginData.totalLoginDuration && (
                                    <StatContainer
                                        text="Total Login"
                                        count={
                                            employeeLoginData.totalLoginDuration
                                        }
                                    />
                                )}
                            </Box>
                            {employeeLoginData.days.length > 0 ? (
                                <ILEmployeeDayDataTable
                                    rows={employeeLoginData.days}
                                    getEmployeeLoginData={getEmployeeLoginData}
                                    activeDate={activeDate}
                                    setViewingDate={setViewingDate}
                                />
                            ) : (
                                <Box className={classes.noViewContainer}>
                                    <Typography className={classes.noDataText}>
                                        No data To display
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <Box className={classes.noViewContainer}>
                            <Typography className={classes.noDataText}>
                                No data To display
                            </Typography>
                        </Box>
                    )}
                </>
            )}
        </Box>
    )
}

export default ILEmployeeDayHours

const StatContainer = ({ text, count }) => {
    const { classes } = useStyles()
    return (
        <Box className={classes.statContainer}>
            <Typography className={classes.textItem}>{text}</Typography>
            <Typography className={classes.countItem}>{count}</Typography>
        </Box>
    )
}

const useStyles = makeStyles()((theme) => ({
    mainContainer: {
        padding: '5px 20px 20px',
        width: 'fit-content',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
        [theme.breakpoints.down('md')]: {
            padding: '10px 0px 20px',
            borderLeft: 'none',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    statsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            gap: '10px',
        },
    },
    noViewContainer: {
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    noDataText: {
        fontWeight: 'bold',
        color: '#606e94',
        fontSize: '22px',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
            marginTop: '12px',
            textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: '50px',
        },
    },
    statContainer: {
        display: 'flex',
        alignItems: 'center',
        border: '0.4px solid #99ccff',
        borderRadius: '8px',
        background: '#ebf5ff',
        color: '#32117A',
    },
    textItem: {
        padding: '8px',
        background: '#fff',
        color: '#000',
        borderRadius: '7px 0px 0px 7px',
        whiteSpace: 'nowrap',
    },
    countItem: {
        padding: '8px 12px',
        fontWeight: 600,
    },
}))
