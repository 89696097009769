import React, { useState, useEffect, useRef } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    Modal,
    Input,
    Popconfirm,
    notification,
    Select,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

function ForumGroup({ navigate }) {
    const { Option } = Select

    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])
    const [addUpdateCategoryModal, setAddUpdateCategoryModal] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState({})
    const [streamMappingModalVisible, setStreamMappingModalVisible] =
        useState(false)
    const [streamMappings, setStreamMappings] = useState([])
    const [addStreamMappingFormVisible, setAddStreamMappingFormVisible] =
        useState(false)
    const [streamData, setStreamData] = useState([])
    const [levelsData, setLevelsData] = useState([])
    const [courseMappingModalVisible, setCourseMappingModalVisible] =
        useState(false)
    const [courseMappings, setCourseMappings] = useState([])
    const [addCourseMappingFormVisible, setAddCourseMappingFormVisible] =
        useState(false)
    const [courseData, setCourseData] = useState([])
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (addUpdateCategoryModal && inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [addUpdateCategoryModal, inputRef])

    const getCategories = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUPS_ADMIN)
            .then((res) => {
                setCategories(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.ENABLE_FORUM_GROUP
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_FORUM_GROUP
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                var fullData = categories
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setCategories(fullData)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddUpdateCategoryModal = (record = {}) => {
        setSelectedCategory(record)
        setAddUpdateCategoryModal(true)

        if (record.id) {
            setTimeout(() => {
                form.setFieldsValue(record)
            }, 100)
        }
    }

    const hideAddUpdateCategoryModal = () => {
        setAddUpdateCategoryModal(false)
    }

    const addUpdateGroup = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                var url = GLOBAL.URL.ADD_FORUM_GROUP
                if (selectedCategory.id) {
                    url = GLOBAL.URL.UPDATE_FORUM_GROUP
                    values.id = selectedCategory.id
                }

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(url, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        hideAddUpdateCategoryModal()
                        getCategories()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const reorderGroups = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        navigate('/reorder', {
            state: {
                reorderData: categories,
                updateUrl: GLOBAL.URL.REORDER_FORUM_GROUPS,
                columns: columns,
            },
        })
    }

    const showStreamMappingsModal = (record) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUP_STREAM_MAPPINGS + record.id)
            .then((res) => {
                setStreamMappings(res.data)
            })
            .finally(() => {
                setLoading(false)
            })

        setStreamMappingModalVisible(true)
        setSelectedCategory(record)
    }

    const hideStreamMappingsModal = () => {
        setStreamMappingModalVisible(false)
    }

    const showCourseMappingsModal = (record) => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_FORUM_GROUP_COURSE_MAPPINGS + record.id)
            .then((res) => {
                setCourseMappings(res.data)
            })
            .finally(() => {
                setLoading(false)
            })

        setCourseMappingModalVisible(true)
        setSelectedCategory(record)
    }

    const hideCourseMappingsModal = () => {
        setCourseMappingModalVisible(false)
    }

    const tableColumns = [
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            render: (text, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        showAddUpdateCategoryModal(record)
                    }}
                    style={{ padding: 0 }}
                >
                    <EditTwoTone style={{ fontSize: 14 }} />
                </Button>
            ),
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 80,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        enableDisable(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mappings',
            dataIndex: 'mappings',
            key: 'mappings',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            showStreamMappingsModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        Stream
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            showCourseMappingsModal(record)
                        }}
                        style={{ padding: 0, marginLeft: 10 }}
                    >
                        Courses
                    </Button>
                </>
            ),
        },
    ]

    const deleteStreamMapping = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_FORUM_GROUP_STREAM_MAPPING, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                RestApi.doGet(
                    GLOBAL.URL.GET_FORUM_GROUP_STREAM_MAPPINGS +
                        selectedCategory.id
                ).then((res) => {
                    setStreamMappings(res.data)
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const streamMappingTableColumns = [
        {
            title: 'Stream',
            dataIndex: 'stream',
            key: 'stream',
            render: (text, record) => (
                <span>{record.stream.course + ' ' + record.stream.level}</span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteStreamMapping(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 3 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }

    const showAddStreamMappingForm = () => {
        setAddStreamMappingFormVisible(true)
        if (streamData.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_STREAM_COURSE).then((res) => {
                setStreamData(JSON.parse(res.data))
            })
        }
    }

    const hideAddStreamMappingForm = () => {
        setAddStreamMappingFormVisible(false)
    }

    const courseMappingTableColumns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 80,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        enableDisableCourseMapping(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Course',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCourseMapping(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const showAddCourseMappingForm = () => {
        setAddCourseMappingFormVisible(true)

        if (courseData.length === 0) {
            RestApi.doGet(
                GLOBAL.URL.GET_COURSE_ACCESS_COURSE_NAMES_WITH_IDS
            ).then((res) => {
                setCourseData(res.data)
            })
        }
    }

    const hideAddCourseMappingForm = () => {
        setAddCourseMappingFormVisible(false)
    }

    const getCourseOptions = () => {
        var courseOptions = []
        courseData.forEach((item) => {
            courseOptions.push(
                <Option value={item.id} key={item.id}>
                    {item.name}
                </Option>
            )
        })

        return courseOptions
    }

    const addCourseMappingForm = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.groupId = selectedCategory.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ADD_FORUM_GROUP_COURSE_MAPPING,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        RestApi.doGet(
                            GLOBAL.URL.GET_FORUM_GROUP_COURSE_MAPPINGS +
                                selectedCategory.id
                        )
                            .then((res) => {
                                setCourseMappings(res.data)
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const enableDisableCourseMapping = (record) => {
        setLoading(true)

        let url = GLOBAL.URL.ENABLE_FORUM_GROUP_COURSE_MAPPING
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_FORUM_GROUP_COURSE_MAPPING
        }

        var formData = new FormData()
        formData.append('payload', JSON.stringify({ id: record.id }))

        RestApi.doPost(url, formData)
            .then((response) => {
                var fullData = courseMappings
                fullData.forEach((item) => {
                    if (item.id === record.id) {
                        item.enabled = !item.enabled
                        return
                    }
                })

                setCourseMappings(fullData)
                notification['success']({
                    message: response.data,
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteCourseMapping = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        var formData = new FormData()
        formData.append('payload', JSON.stringify(payload))

        RestApi.doPost(GLOBAL.URL.DELETE_FORUM_GROUP_COURSE_MAPPING, formData)
            .then((response) => {
                notification['success']({
                    message: response.data,
                })

                RestApi.doGet(
                    GLOBAL.URL.GET_FORUM_GROUP_COURSE_MAPPINGS +
                        selectedCategory.id
                )
                    .then((res) => {
                        setCourseMappings(res.data)
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const handleCourseChange = (value, level) => {
        streamData.forEach((item) => {
            if (item.course === value) {
                setLevelsData(item.levels)
                return
            }
        })

        form.setFieldsValue({
            level: '',
        })
    }

    const streamOptions = () => {
        var options = []
        streamData.forEach((item) => {
            options.push(<Option value={item.course}>{item.course}</Option>)
        })

        return options
    }

    const levelOptions = () => {
        var options = []
        levelsData.forEach((item) => {
            options.push(<Option value={item}>{item}</Option>)
        })

        return options
    }

    const addStreamMapping = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.groupId = selectedCategory.id

                var formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ADD_FORUM_GROUP_STREAM_MAPPING,
                    formData
                )
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        RestApi.doGet(
                            GLOBAL.URL.GET_FORUM_GROUP_STREAM_MAPPINGS +
                                selectedCategory.id
                        )
                            .then((res) => {
                                setStreamMappings(res.data)
                            })
                            .finally(() => {
                                setLoading(false)
                            })
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    return (
        <div>
            <h2>Forum Groups</h2>
            <div>
                <Button
                    type="primary"
                    size="small"
                    onClick={showAddUpdateCategoryModal}
                    loading={loading}
                >
                    Add
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={reorderGroups}
                    loading={loading}
                    style={{ marginLeft: 20 }}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={tableColumns}
                dataSource={categories}
                pagination={false}
                size="small"
                loading={loading}
                rowKey="id"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={'Configure Group'}
                open={addUpdateCategoryModal}
                onOk={addUpdateGroup}
                confirmLoading={loading}
                onCancel={hideAddUpdateCategoryModal}
                okText={selectedCategory.id ? 'Update' : 'Add'}
                destroyOnClose={true}
            >
                {addUpdateCategoryModal ? (
                    <Form
                        form={form}
                        layout="horizontal"
                        {...formItemLayout}
                        style={{ marginTop: 20 }}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter name',
                                },
                            ]}
                        >
                            <Input autoFocus ref={inputRef} />
                        </Form.Item>
                    </Form>
                ) : null}
            </Modal>
            <Modal
                title={selectedCategory.name + ' - Stream Mapping'}
                open={streamMappingModalVisible}
                confirmLoading={loading}
                onCancel={hideStreamMappingsModal}
                footer={null}
                destroyOnClose={true}
            >
                {addStreamMappingFormVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Course"
                            name="course"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                onChange={(value) => handleCourseChange(value)}
                                showSearch
                            >
                                {streamOptions()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Level"
                            style={{ marginBottom: 0 }}
                            name="level"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select level',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select level"
                                showSearch
                            >
                                {levelOptions()}
                            </Select>
                        </Form.Item>
                        <div style={{ marginTop: 10 }}>
                            <Button
                                type="primary"
                                size="small"
                                onClick={addStreamMapping}
                                loading={loading}
                            >
                                Add
                            </Button>
                            <Button
                                type="primary"
                                size="small"
                                onClick={hideAddStreamMappingForm}
                                loading={loading}
                                style={{ marginLeft: 10 }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddStreamMappingForm}
                        loading={loading}
                    >
                        Add
                    </Button>
                )}
                <Table
                    columns={streamMappingTableColumns}
                    dataSource={streamMappings}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="stream"
                    style={{ marginTop: 10 }}
                />
            </Modal>
            <Modal
                title={selectedCategory.name + ' - Course Mapping'}
                open={courseMappingModalVisible}
                confirmLoading={loading}
                onCancel={hideCourseMappingsModal}
                footer={null}
                destroyOnClose={true}
                width={800}
            >
                {addCourseMappingFormVisible ? (
                    <Form form={form} layout="horizontal" {...formItemLayout}>
                        <Form.Item
                            label="Course"
                            name="courseId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Select course',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select course"
                                showSearch
                                allowClear={true}
                                filterOption={(input, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getCourseOptions()}
                            </Select>
                        </Form.Item>
                        <Button
                            type="primary"
                            size="small"
                            onClick={addCourseMappingForm}
                            loading={loading}
                        >
                            Add
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={hideAddCourseMappingForm}
                            loading={loading}
                            style={{ marginLeft: 10 }}
                        >
                            Cancel
                        </Button>
                    </Form>
                ) : (
                    <Button
                        type="primary"
                        size="small"
                        onClick={showAddCourseMappingForm}
                        loading={loading}
                    >
                        Add
                    </Button>
                )}

                <Table
                    columns={courseMappingTableColumns}
                    dataSource={courseMappings}
                    pagination={false}
                    size="small"
                    loading={loading}
                    rowKey="stream"
                    style={{ marginTop: 10 }}
                />
            </Modal>
        </div>
    )
}

export default ForumGroup
