import { Box, Typography } from '@mui/material'
import { Modal } from 'antd'
import React from 'react'
import Login from '../Login'
import { useSelector } from 'react-redux'

const ILLoginModal = () => {
    const showLoginModal = useSelector((state) => state.isLoginModal)

    return (
        <Modal open={showLoginModal} footer={null} closable={false}>
            <Box
                sx={{
                    padding: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <Typography style={{ textAlign: 'center', fontWeight: '600' }}>
                    Session expired, please sign in again.
                </Typography>
                <Box sx={{ margin: '20px 0px' }}>
                    <img
                        width={200}
                        alt="logo"
                        src={
                            'https://il-app.s3.ap-south-1.amazonaws.com/images/sign-in-02-d427ab2dc8b842308ead0dbde6459eb6.png'
                        }
                    />
                </Box>
                <Box style={{ alignSelf: 'center' }}>
                    <Login isLoginModal={true} />
                </Box>
            </Box>
        </Modal>
    )
}

export default ILLoginModal
