import { actionTypes } from '../actions'

export const isLoginScreen = (state = false, action) => {
    switch (action.type) {
        case actionTypes.SET_IS_LOG_IN:
            const isLogIn = action.isLogIn
            return isLogIn
        default:
            return state
    }
}
