module.exports = {
    COLORS: [
        '#Fed049',
        '#51c4d3',
        '#ff971d',
        '#04009a',
        '#f33535',
        '#9ede73',
        '#8ac4d0',
        '#438a5e',
        '#888888',
    ],
    CHART_OPTIONS: {
        responsive: true,
        plugins: {
            datalabels: {
                render: 'value',
                align: 'end',
                anchor: 'end',
                display: function (context) {
                    if (context.dataset.hideDataPoints) {
                        return false
                    } else {
                        return context.dataset.data[context.dataIndex] !== 0
                    }
                },
                color: 'black',
                font: {
                    size: 14,
                },
                rotation: 0,
            },
        },
        legend: {
            display: true,
            labels: {
                fontColor: '#000000',
            },
        },
        interaction: {
            intersect: false,
        },
        hover: {
            mode: 'nearest',
            intersect: false,
        },
        tooltips: {
            mode: 'label',
            intersect: false,
            callbacks: {
                label: function (context, value) {
                    if (context.value !== '0') {
                        return `${value.datasets[context.datasetIndex].label}:${
                            context.value
                        }`
                    }
                },
            },
        },
        elements: {
            point: {
                pointStyle: 'circle',
            },
        },
        scales: {
            x: 
                {
                    grid: {
                        display: false,
                    },
                },
            y: 
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
        },
    },
}
