import React from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import {
    Table,
    Button,
    message,
    Radio,
    Select,
    Popconfirm,
    Divider,
} from 'antd'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

class CourseReviews extends React.Component {
    state = {
        data: [],
        loading: false,
        filterType: '',
        filterTypes: [],
        courseName: '',
        nextUrl: '',
        courseNames: [],
    }

    componentDidMount() {
        this.init()
    }

    init() {
        this.setState({ loading: true })
        this.getFilterTypes()
        this.getCourseName()
    }

    getFilterTypes() {
        RestApi.doGet(GLOBAL.URL.GET_COURSE_REVIEW_TYPES)
            .then((res) => {
                this.setState(
                    { filterTypes: res.data, filterType: res.data[0] },
                    () => {
                        this.getData()
                    }
                )
            })
            .catch((error) => {
                this.setState({ loading: false })
            })
    }

    getCourseName() {
        RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
            this.setState({ courseNames: res.data })
        })
    }

    getData() {
        this.setState({ loading: true })

        var url =
            GLOBAL.URL.GET_COURSE_REVIEWS +
            '?filterType=' +
            this.state.filterType +
            '&courseName=' +
            encodeURIComponent(this.state.courseName)

        RestApi.doGet(url)
            .then((response) => {
                var res = JSON.parse(response.data)
                this.setState({ data: res.data, nextUrl: res.next })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    getMoreData = () => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.BASE_URL + this.state.nextUrl
        RestApi.doGet(url)
            .then((res) => {
                var response = JSON.parse(res.data)

                this.setState({
                    data: [...this.state.data, ...response.data],
                    nextUrl: response.next,
                })
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    onChangeSelection = (e) => {
        this.setState(
            {
                filterType: e.target.value,
                data: [],
            },
            () => {
                this.getData()
            }
        )
    }

    courseNameSelected = (courseName) => {
        this.setState({ courseName: courseName ? courseName : '' }, () => {
            this.getData()
        })
    }

    enableDisable = (record) => {
        this.setState({
            loading: true,
        })

        var url = GLOBAL.URL.ENABLE_COURSE_REVIEW
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_COURSE_REVIEW
        }

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: record.id }))
        RestApi.doPost(url, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var data = this.state.data
                    data.forEach((section) => {
                        if (section.id === record.id) {
                            section.enabled = !record.enabled
                        }
                    })

                    this.setState({
                        data: data,
                    })

                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    delete = (id) => {
        this.setState({
            loading: true,
        })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_COURSE_REVIEW, postBody)
            .then((response) => {
                if (response.code === 200) {
                    message.destroy()
                    message.success(response.data)

                    this.getData()
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    clearComments = (id) => {
        this.setState({
            loading: true,
        })

        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.CLEAR_COURSE_REVIEW_COMMENTS, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var data = this.state.data
                    data.forEach((section) => {
                        if (section.id === id) {
                            section.review = ''
                        }
                    })

                    this.setState({
                        data: data,
                    })

                    message.destroy()
                    message.success(response.data)
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            })
    }

    navigateToUserDetails = (user) => {
        this.props.navigate('/userdetails/' + user.id, {
            state: { record: user },
        })
    }

    render() {
        const { Option } = Select

        var options = []
        this.state.filterTypes.forEach((filterType) => {
            options.push(
                <Radio.Button value={filterType}>{filterType}</Radio.Button>
            )
        })

        var courseDetails = []
        this.state.courseNames.forEach((item) => {
            courseDetails.push(
                <Option value={item} key={item}>
                    {item}
                </Option>
            )
        })

        const columns = [
            {
                title: 'Enabled',
                dataIndex: 'id',
                key: 'id',
                width: 100,
                render: (text, record) => (
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                        onClick={() => {
                            this.enableDisable(record)
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                ),
            },
            {
                title: 'Rating',
                dataIndex: 'rating',
                key: 'rating',
                width: 100,
            },
            {
                title: 'Review',
                dataIndex: 'review',
                key: 'review',
            },
            {
                title: 'Course',
                dataIndex: 'courseName',
                key: 'courseName',
            },
            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
                render: (text, record) => (
                    <Button
                        type="link"
                        style={{ margin: 0, padding: 0 }}
                        onClick={() => this.navigateToUserDetails(record.user)}
                    >
                        {record.user.firstName} {record.user.lastName}
                    </Button>
                ),
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Popconfirm
                            title="Are you sure to delete?"
                            onConfirm={() => {
                                this.delete(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                onClick={() => {}}
                                style={{ padding: 0 }}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure want to clear comments?"
                            onConfirm={() => {
                                this.clearComments(record.id)
                            }}
                            type="danger"
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="link"
                                onClick={() => {}}
                                style={{ padding: 0 }}
                            >
                                Clear Comments
                            </Button>
                        </Popconfirm>
                    </span>
                ),
            },
        ]

        return (
            <div>
                <h3>Course Reviews</h3>
                <Radio.Group
                    value={this.state.filterType}
                    onChange={this.onChangeSelection}
                    style={{ marginTop: 15 }}
                >
                    {options}
                </Radio.Group>
                <Button type="link" onClick={() => this.getData()}>
                    {this.state.loading ? (
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 20 }} />
                    )}
                </Button>
                <Select
                    placeholder="Filter by Course"
                    showSearch
                    style={{ width: 500, marginLeft: 10 }}
                    onChange={this.courseNameSelected}
                    allowClear={true}
                >
                    {courseDetails}
                </Select>
                <Table
                    columns={columns}
                    dataSource={this.state.data ? this.state.data : null}
                    pagination={false}
                    loading={this.state.loading}
                    size="small"
                    style={{ marginTop: 8 }}
                    rowKey="id"
                />
                {this.state.nextUrl ? (
                    <Button
                        type="primary"
                        onClick={() => this.getMoreData()}
                        style={{ marginTop: 10 }}
                        size="small"
                    >
                        More
                    </Button>
                ) : null}
            </div>
        )
    }
}

export default CourseReviews
