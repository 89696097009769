import React, { useState, useEffect } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const CourseWiseStudents = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = () => {
        setLoading(true)

        RestApi.doGet(GLOBAL.URL.GET_ACTIVE_USERS_BY_COURSE)
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <h2>
                Course Wise Students{' '}
                <Button
                    type="link"
                    style={{ padding: 0, marginLeft: 10 }}
                    onClick={getData}
                >
                    {loading ? (
                        <LoadingOutlined style={{ fontSize: 16 }} />
                    ) : (
                        <ReloadOutlined style={{ fontSize: 16 }} />
                    )}
                </Button>
            </h2>
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    )
}

export default CourseWiseStudents
