import React, { useState } from 'react'
import { Table, Button } from 'antd'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

let draggingIndex = -1

const BodyRow = ({ index, moveRow, ...restProps }) => {
    const [, dragRef] = useDrag({
        type: 'row',
        item: { index },
    })

    const [, dropRef] = useDrop({
        accept: 'row',
        drop: () => moveRow(index),
    })

    const style = {
        ...restProps.style,
        cursor: 'move',
    }

    let { className } = restProps
    if (restProps.isOver) {
        if (restProps.index > draggingIndex) {
            className += ' drop-over-downward'
        }
        if (restProps.index < draggingIndex) {
            className += ' drop-over-upward'
        }
    }

    return (
        <tr
            ref={(node) => {
                dragRef(dropRef(node))
            }}
            className={className}
            style={style}
            {...restProps}
        />
    )
}

const ReorderTable = (props) => {
    const [state, setState] = useState({
        data: props.reorderData || [],
        columns: props.columns || [],
        loading: false,
    })

    const moveRow = (dragIndex, hoverIndex) => {
        const { data } = state
        const dragRow = data[dragIndex]

        setState({
            ...state,
            data: update(state.data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            }),
        })
    }

    return (
        <div>
            <DndProvider backend={HTML5Backend}>
                <Table
                    columns={state.columns}
                    dataSource={state.data}
                    components={{
                        body: {
                            row: BodyRow,
                        },
                    }}
                    onRow={(record, index) => ({
                        index,
                        moveRow,
                    })}
                    pagination={false}
                    loading={state.loading}
                    size="small"
                />
            </DndProvider>
            <div style={{ marginTop: 10 }}>
                <Button
                    type="primary"
                    size="small"
                    onClick={() => props.onOk(state.data)}
                >
                    Save
                </Button>
            </div>
        </div>
    )
}

export default ReorderTable
