import {
    Button,
    Table,
    Popconfirm,
    message,
    Modal,
    InputNumber,
    Select,
    Form,
} from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom'

const ExtensionPrice = (props) => {
    const [attemptMappingId, setAttemptMappingId] = useState('')
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [addModalVisible, setAddModalVisible] = useState(false)
    const [editPriceModalVisible, setEditPriceModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState({})
    const [courseNames, setCourseNames] = useState([])
    const [updatedPrice, setUpdatedPrice] = useState(0)
    const [attemptMappingData, setAttemptMappingData] = useState({})
    const [customEnrollmentModalVisible, setCustomEnrollmentModalVisible] =
        useState(false)
    const [customEnrollmentList, setCustomEnrollmentList] = useState([])
    const [
        addCustomEnrollmentModalVisible,
        setAddCustomEnrollmentModalVisible,
    ] = useState(false)
    const [form] = Form.useForm()
    const { attemptMappingId: paramsAttemptMappingId } = useParams()

    useEffect(() => {
        const { attemptMappingData } = props.location

        setAttemptMappingId(paramsAttemptMappingId)
        setAttemptMappingData(attemptMappingData)
        getData(paramsAttemptMappingId)
        //eslint-disable-next-line
    }, [])

    const getData = (attemptMappingIdNew = attemptMappingId) => {
        setLoading(true)
        RestApi.doGet(
            GLOBAL.URL.GET_EXTENSION_PRICES +
                '?attemptMappingId=' +
                attemptMappingIdNew
        )
            .then((res) => {
                setData(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const enableDisable = (record) => {
        setLoading(true)

        var url = GLOBAL.URL.ENABLE_EXTENSION_PRICE
        if (record.enabled) {
            url = GLOBAL.URL.DISABLE_EXTENSION_PRICE
        }

        var payload = {}
        payload.id = record.id

        let formbodyLevel = new FormData()
        formbodyLevel.append('payload', JSON.stringify(payload))

        RestApi.doPost(url, formbodyLevel)
            .then((res) => {
                message.success(res.data)

                var dataNew = data
                dataNew.forEach((item) => {
                    if (record.id === item.id) {
                        item.enabled = !record.enabled
                        return
                    }
                })

                setData(dataNew)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const deleteItem = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.DELETE_EXTENSION_PRICE, formData)
            .then((response) => {
                message.success(response.data)
                getData()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const showAddModal = () => {
        if (courseNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
                setCourseNames(res.data)
            })
        }

        setAddModalVisible(true)
    }

    const hideAddModal = () => {
        setAddModalVisible(false)
    }

    const addPrice = () => {
        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.attemptMappingId = attemptMappingId

                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))
                RestApi.doPost(
                    GLOBAL.URL.ADD_EXTENSION_PRICE,
                    formbodyCourseObject
                )
                    .then((response) => {
                        message.success(response.data)
                        hideAddModal()
                        getData()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const showEditPriceModal = (record) => {
        setEditPriceModalVisible(true)
        setSelectedRecord(record)
        setUpdatedPrice(0)
    }

    const hideEditPriceModal = () => {
        setEditPriceModalVisible(false)
    }

    const updatePrice = () => {
        if (updatedPrice < 1) {
            message.error('Price should be greater than 0!')
            return
        }

        setLoading(true)
        var payload = {}
        payload.id = selectedRecord.id
        payload.price = updatedPrice

        let formData = new FormData()
        formData.append('payload', JSON.stringify(payload))
        RestApi.doPost(GLOBAL.URL.UPDATE_EXTENSION_PRICE, formData)
            .then((response) => {
                message.success(response.data)
                hideEditPriceModal()
                getData()
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showCustomEnrollmentMoal = (record) => {
        setSelectedRecord(record)
        setCustomEnrollmentModalVisible(true)
        getCustomEnrollmentList(record)
    }

    const hideCustomEnrollmentMoal = () => {
        setCustomEnrollmentModalVisible(false)
    }

    const getCustomEnrollmentList = (recordNew = selectedRecord) => {
        setLoading(true)
        RestApi.doGet(GLOBAL.URL.GET_EXTENSION_CUSTOM_ENROLLMENT + recordNew.id)
            .then((res) => {
                setCustomEnrollmentList(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const showAddCustomEnrollmentModal = () => {
        if (courseNames.length === 0) {
            RestApi.doGet(GLOBAL.URL.GET_COURSE_NAMES).then((res) => {
                setCourseNames(res.data)
            })
        }

        setAddCustomEnrollmentModalVisible(true)
    }

    const hideAddCustomEnrollmentModal = () => {
        setAddCustomEnrollmentModalVisible(false)
    }

    const addCustomEnrollment = () => {
        form.validateFields(['courseName'])
            .then((values) => {
                setLoading(true)

                values.extensionPriceId = selectedRecord.id

                let formbodyCourseObject = new FormData()
                formbodyCourseObject.append('payload', JSON.stringify(values))

                RestApi.doPost(
                    GLOBAL.URL.ADD_EXTENSION_CUSTOM_ENROLLMENT,
                    formbodyCourseObject
                )
                    .then((response) => {
                        message.success(response.data)
                        hideAddCustomEnrollmentModal()
                        getCustomEnrollmentList()
                    })
                    .catch((error) => {
                        setLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }

    const deleteCustomEnrollment = (id) => {
        setLoading(true)

        var payload = {}
        payload.id = id

        let formbodyCourseObject = new FormData()
        formbodyCourseObject.append('payload', JSON.stringify(payload))

        RestApi.doPost(
            GLOBAL.URL.DELETE_EXTENSION_CUSTOM_ENROLLMENT,
            formbodyCourseObject
        )
            .then((response) => {
                message.success(response.data)
                getCustomEnrollmentList()
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const columns = [
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            width: 100,
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => {
                        enableDisable(record)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{
                            border: 'none',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                        }}
                    >
                        {record.enabled ? (
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={GLOBAL.COLORAPP.BLUE}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faTimes} color="red" />
                        )}
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        onClick={() => {
                            showEditPriceModal(record)
                        }}
                        style={{ padding: 0 }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>
                    <span style={{ marginLeft: 10 }}>{record.price}</span>
                </span>
            ),
        },
        {
            title: 'Course Name',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Custom Enrollment',
            dataIndex: 'customEnrollment',
            key: 'customEnrollment',
            render: (text, record) => (
                <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={() => showCustomEnrollmentMoal(record)}
                >
                    View
                </Button>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'Delete',
            key: 'Delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteItem(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const customEnrollmentColumns = [
        {
            title: '#',
            dataIndex: 'slnum',
            key: 'slnum',
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Courses',
            dataIndex: 'courseName',
            key: 'courseName',
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={() => deleteCustomEnrollment(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="link"
                        style={{
                            border: 'none',
                            color: 'red',
                            padding: 0,
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    const formItemLayout = {
        labelCol: {
            xs: { span: 8 },
            sm: { span: 2 },
        },
        wrapperCol: {
            xs: { span: 16 },
            sm: { span: 20 },
        },
    }

    const { Option } = Select
    var courseDetails = []
    courseNames.forEach((item) => {
        courseDetails.push(
            <Option value={item} key={item}>
                {item}
            </Option>
        )
    })

    return (
        <div>
            <h2>
                Course Extension Prices
                {attemptMappingData ? (
                    <span>
                        {' ('}
                        {attemptMappingData.attempt} to{' '}
                        {attemptMappingData.targetAttempt}
                        {')'}
                    </span>
                ) : null}
            </h2>
            <Button
                type="primary"
                size="small"
                onClick={() => showAddModal()}
                loading={loading}
            >
                Add
            </Button>
            <Button
                size="small"
                onClick={() => props.navigate('/extension/attempt')}
                loading={loading}
                style={{ marginLeft: 10 }}
            >
                Back
            </Button>
            <Table
                columns={columns}
                dataSource={data ? data : null}
                pagination={false}
                loading={loading}
                size="small"
                style={{ marginTop: 8 }}
                rowKey="courseName"
            />
            <Modal
                title="Course Extension Price"
                open={addModalVisible}
                onOk={() => {
                    addPrice()
                }}
                confirmLoading={loading}
                onCancel={hideAddModal}
                okText="Add"
                destroyOnClose={true}
                width={800}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseName"
                        rules={[
                            {
                                required: true,
                                message: 'Please select course!',
                            },
                        ]}
                    >
                        <Select placeholder="Select Course Name" showSearch>
                            {courseDetails}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Price"
                        name="price"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter price!',
                            },
                        ]}
                    >
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Update Extension Price"
                open={editPriceModalVisible}
                onOk={() => {
                    updatePrice()
                }}
                confirmLoading={loading}
                onCancel={hideEditPriceModal}
                okText="Update"
                destroyOnClose={true}
            >
                <h3>Current price:</h3>
                <span>{selectedRecord.price}</span>
                <h3 style={{ marginTop: 15 }}>New price:</h3>
                <InputNumber
                    min={1}
                    autoFocus
                    onChange={(price) => {
                        setUpdatedPrice(price)
                    }}
                />
            </Modal>
            <Modal
                title="Custom Enrollment"
                open={customEnrollmentModalVisible}
                footer={null}
                confirmLoading={loading}
                onCancel={hideCustomEnrollmentMoal}
                destroyOnClose={true}
                width={800}
            >
                <h3>{selectedRecord.courseName}</h3>
                <div>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => showAddCustomEnrollmentModal()}
                        loading={loading}
                    >
                        Add
                    </Button>
                </div>
                <Table
                    columns={customEnrollmentColumns}
                    dataSource={customEnrollmentList}
                    pagination={false}
                    loading={loading}
                    size="small"
                    style={{ marginTop: 10 }}
                    rowKey="slnum"
                />
            </Modal>
            <Modal
                title="Add Custom Enrollment"
                open={addCustomEnrollmentModalVisible}
                onOk={() => {
                    addCustomEnrollment()
                }}
                confirmLoading={loading}
                onCancel={hideAddCustomEnrollmentModal}
                okText="Add"
                destroyOnClose={true}
                width={1000}
            >
                <Form form={form} layout="horizontal" {...formItemLayout}>
                    <Form.Item
                        label="Course"
                        name="courseName"
                        rules={[
                            {
                                required: true,
                                message: 'Please select course!',
                            },
                        ]}
                    >
                        <Select placeholder="Select Course Name" showSearch>
                            {courseDetails}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default ExtensionPrice
