import { message, Table, Button, Modal, Input, Popconfirm, Form } from 'antd'
import { EditTwoTone } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from '@mui/material'

const Chapters = (props) => {
    const [chapterData, setChapterData] = useState([])
    const [addEditChapterModalVisible, setAddEditChapterModalVisible] =
        useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [editChapterRecord, setEditChapterRecord] = useState('')
    const [form] = Form.useForm()

    useEffect(() => {
        getChaptersData()
        //eslint-disable-next-line
    }, [])

    const getChaptersData = () => {
        const { subjectName } = props.location.state || {}
        RestApi.doGet(
            GLOBAL.URL.GET_CHAPTERS_BY_SUBJECT +
                '?subject=' +
                encodeURIComponent(subjectName)
        )
            .then((res) => {
                setChapterData(res.data)
            })
            .catch((error) => {
                message.error(error)
            })
    }
    const openAddEditChapterModal = (record) => {
        form.setFieldsValue(record)
        setAddEditChapterModalVisible(true)
        setEditChapterRecord(record)
    }
    const closeAddEditChapterModal = () => {
        form.resetFields()
        setAddEditChapterModalVisible(false)
        setEditChapterRecord(null)
    }
    const handleSubmit = (editRecord) => {
        const { subjectName } = props.location.state || {}
        form.validateFields()
            .then((values) => {
                setModalLoading(true)
                var url = GLOBAL.URL.ADD_CHAPTER
                values['subject'] = subjectName
                if (editRecord) {
                    url = GLOBAL.URL.UPDATE_CHAPTER
                    values['id'] = editRecord.id
                }
                let formbodySubject = new FormData()
                formbodySubject.append('payload', JSON.stringify(values))
                RestApi.doPost(url, formbodySubject)
                    .then((response) => {
                        var subjectJsonArrayObject = []
                        subjectJsonArrayObject.push(response.data)
                        if (editRecord) {
                            chapterData.forEach((item) => {
                                if (item.id === editRecord.id) {
                                    item.name = values.name
                                }
                            })
                        } else {
                            setChapterData([
                                ...chapterData,
                                ...subjectJsonArrayObject,
                            ])
                        }
                        closeAddEditChapterModal()
                        message.success(
                            editRecord
                                ? 'Chapter Edited Successfully'
                                : 'Chapter Added Successfully'
                        )
                    })
                    .finally(() => {
                        setModalLoading(false)
                    })
            })
            .catch(() => {
                //empty catch block
            })
    }
    const deleteItem = (id) => {
        message.loading('Action in Progress...please Wait', 0)
        var postBody = new FormData()
        postBody.append('payload', JSON.stringify({ id: id }))
        RestApi.doPost(GLOBAL.URL.DELETE_CHAPTER, postBody)
            .then((response) => {
                if (response.code === 200) {
                    var successObject = chapterData
                    setChapterData(successObject.filter((key) => key.id !== id))
                    message.destroy()
                    message.success(response.data)
                }
            })
            .catch((response) => {})
    }

    const reorder = () => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
        ]

        var payload = {}
        payload.subject = props.location.state.subjectName

        props.navigate('/reorder', {
            state: {
                reorderData: chapterData,
                updateUrl: GLOBAL.URL.REORDER_CHAPTERS,
                columns: columns,
                payload: payload,
            },
        })
    }

    const { subjectName } = props.location.state || {}

    let columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            width: 50,
            render: (text, record, index) => <span>{index + 1}</span>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                            openAddEditChapterModal(record)
                        }}
                    >
                        <EditTwoTone style={{ fontSize: 14 }} />
                    </Button>

                    <Link
                        href={'/mcq/sections'}
                        onClick={(event) => {
                            event.preventDefault()
                            props.navigate('/mcq/sections', {
                                state: {
                                    subjectName: subjectName,
                                    chapterName: record.name,
                                },
                            })
                        }}
                        underline="none"
                    >
                        <Button type="link">{record.name}</Button>
                    </Link>
                </span>
            ),
        },
        {
            title: 'Delete',
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => (
                <Popconfirm
                    title="Are you sure want to delete ?"
                    onConfirm={() => {
                        deleteItem(record.id)
                    }}
                    type="danger"
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        tyle="link"
                        style={{ border: 'none', color: 'red' }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                </Popconfirm>
            ),
        },
    ]

    return (
        <div>
            <h2>{subjectName} (Chapters)</h2>
            <div>
                <Button
                    type="primary"
                    onClick={() => {
                        openAddEditChapterModal()
                    }}
                >
                    Add Chapter
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        reorder()
                    }}
                    style={{ marginLeft: 10 }}
                >
                    Reorder
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={chapterData}
                pagination={false}
                key="id"
                size="small"
                style={{ marginTop: 10 }}
            />
            <Modal
                title={editChapterRecord ? 'Edit Chapter' : 'Add Chapter'}
                open={addEditChapterModalVisible}
                onOk={() => {
                    handleSubmit(editChapterRecord)
                }}
                confirmLoading={modalLoading}
                onCancel={closeAddEditChapterModal}
                okText={editChapterRecord ? 'Edit Chapter' : 'Add Chapter'}
            >
                <Form form={form} layout="horizontal">
                    <Form.Item
                        label="Chapter Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Chapter Name',
                            },
                        ]}
                    >
                        <Input placeholder="Please enter Chapter Name" />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Chapters
