import React, { useEffect, useRef, useState } from 'react'
import { Input, Button, notification, Form } from 'antd'
import { RestApi } from '../../RestApi'
import GLOBAL from '../../Globals'
import TinyMceEditor from '../Common/TinyMceEditor'

const EmailToUser = (props) => {
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState('')
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }, 10)
        // eslint-disable-next-line
    }, [inputRef])

    const sendEmail = () => {
        if (!content) {
            notification['error']({
                message: 'Email body needed!',
            })

            return
        }

        form.validateFields()
            .then((values) => {
                setLoading(true)

                values.body = content
                values.userId = props.userId

                let formData = new FormData()
                formData.append('payload', JSON.stringify(values))

                RestApi.doPost(GLOBAL.URL.SEND_EMAIL_USER, formData)
                    .then((response) => {
                        notification['success']({
                            message: response.data,
                        })

                        if (props.onSuccess) {
                            props.onSuccess()
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            })
            .catch((error) => {})
    }

    const handleEditorChange = (newValue, e) => {
        setContent(newValue)
    }

    return (
        <div>
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Subject"
                    style={{ marginBottom: 5 }}
                    name="subject"
                    rules={[
                        {
                            required: true,
                            message: 'Required!',
                        },
                    ]}
                >
                    <Input autoFocus ref={inputRef} />
                </Form.Item>

                <h4>Email Body</h4>
                <TinyMceEditor
                    height={400}
                    handleEditorChange={handleEditorChange}
                />
            </Form>
            <div style={{ marginTop: 10 }}>
                <Button type="primary" onClick={sendEmail} loading={loading}>
                    Send Email
                </Button>
            </div>
        </div>
    )
}

export default EmailToUser
